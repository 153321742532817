<template>
  <div class="selection-box">
    <!--提示信息-->
    <section v-if="enterFlag == 0">
      <el-card class="box-card">
        <el-result
          icon="success"
          title=""
          sub-title="即将开始企业认证，认证服务由国家认证CA机构“e签宝”提供，按照提示操作即可" />
      </el-card>
      <el-card class="box-card">
        <el-result
          icon="warning"
          title=""
          sub-title="若您不是企业法人，请登记企业授权人信息，法人将在流程中对您的操作行为进行授权" />
      </el-card>
    </section>
    <!--授权人信息-->
    <section v-if="enterFlag == 9">
      <el-form
        ref="cForm"
        :model="cForm"
        :rules="vRules"
        :label-position="'left'"
        label-width="150px"
        size="small"
        class="cForm mt20">
        <div class="mt10 inner-box">
          <el-alert title="请上传授权人身份证正反面照片" :closable="false" type="warning" show-icon />
          <el-form-item label="身份证（人像面）" prop="authFilePeopleId" class="mt20">
            <dict-upload-info
              v-model="cForm.authFilePeopleId"
              :disabled="disabled"
              type="image"
              :limit="1"
              type-code="F0204"
              biz-id="123456"
              url="cust/enter/upload"
              @change="onFileChange($event, 'authFilePeopleId')"
              @getOcrInfo="handleSetOcrInfo" />
          </el-form-item>
          <el-form-item label="身份证（国徽面）" prop="authFileReverseId">
            <dict-upload-info
              v-model="cForm.authFileReverseId"
              :disabled="disabled"
              type="image"
              :limit="1"
              type-code="F0203"
              biz-id="123456"
              url="cust/enter/upload"
              @change="onFileChange($event, 'authFileReverseId')" />
          </el-form-item>
          <el-alert title="授权人身份信息确认" :closable="false" type="info" show-icon />
          <el-form-item label="授权人姓名：" prop="authName" class="mt20">
            <el-input
              v-model="cForm.authName"
              maxlength="20"
              :disabled="disabled"
              placeholder="请输入授权人姓名"
              style="width: 350px" />
          </el-form-item>
          <el-form-item label="授权人身份证号：" prop="authIdCard">
            <el-input
              v-model="cForm.authIdCard"
              maxlength="18"
              :disabled="disabled"
              placeholder="一般为18/15位数字或字母组合"
              style="width: 350px" />
          </el-form-item>
          <el-form-item label="授权人民族：" prop="authNation">
            <el-input
              v-model="cForm.authNation"
              :disabled="disabled"
              placeholder="请输入授权人民族"
              style="width: 350px" />
          </el-form-item>
          <el-alert title="授权人联系方式" :closable="false" type="info" show-icon />
          <el-form-item label="手机号码：" prop="authPhone" class="mt20">
            <el-input
              v-model="cForm.authPhone"
              maxlength="11"
              :disabled="disabled"
              placeholder="请输入授权人手机号码"
              style="width: 350px" />
          </el-form-item>
          <el-form-item label="电子邮箱：" prop="authEmail">
            <el-input
              v-model="cForm.authEmail"
              maxlength="30"
              :disabled="disabled"
              placeholder="请输入授权人电子邮箱"
              style="width: 350px" />
          </el-form-item>
        </div>
      </el-form>
    </section>
    <!--e签宝认证-->
    <section v-if="enterFlag == 1 || enterFlag == 2">
      <div class="smrz-section">
        <div class="sign_company">
          <img src="@/assets/icon/icon_elogo.png" width="100" class="mr20" />
          认证服务由国家认证CA机构“e签宝”提供
        </div>
        <div class="result">
          <div class="result-left">
            <i :class="[authDTO.status !== '2' ? 'el-icon-error fc-red' : 'el-icon-success fc-green', 'result-icon']" />
            <div class="result-list-item mt30">
              <div class="result-list-item-value f18 fb">{{ authDTO.enterName }}</div>
            </div>
            <div class="result-text">
              {{ authDTO.retMsg }}
              <span style="color: #f00" v-if="isFromAuthCert && authDTO.status == '2'">（{{ reckonTime }}s）</span>
            </div>
            <div class="result-btns mt30" />
            <el-button @click="goAuthCert" type="primary" v-if="authDTO.authUrl && authDTO.status == '1'">
              直接去认证
            </el-button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import DictUploadInfo from '@/views/components/DictUploadInfo.vue'
import { doGet, doPost } from '@/router/axios'
import { setDateFormat } from '@/utils/date'

export default defineComponent({
  name: 'enterpriseCert',
  components: { DictUploadInfo },
  props: {
    eId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      enterId: '',
      disabled: false,
      cForm: {
        authFilePeopleId: [],
        authFileReverseId: [],
        authName: '',
        authIdCard: '',
        authDateAttr: [],
        nationality: '',
        authPhone: '',
        authEmail: '',
      },
      ocrInfo: {},
      vRules: {
        authFilePeopleId: [{ required: true, trigger: 'blur', message: '请上传授权人身份证人像页' }],
        authFileReverseId: [{ required: true, trigger: 'blur', message: '请上传授权人身份证国徽页' }],
        authName: [{ required: true, trigger: 'blur', message: '请填写授权人姓名' }],
        authIdCard: [{ required: true, trigger: 'blur', message: '请填写授权人身份证号' }],
        authPhone: [{ required: true, trigger: 'blur', message: '请填写授权人手机号' }],
        authEmail: [{ required: true, trigger: 'blur', message: '请填写授权人邮箱' }],
        nationality: [{ required: true, trigger: 'blur', message: '请填写授权人民族' }],
      },
      enterFlag: 0, // 0:提示 1：授权人
      authDTO: { status: '2' },

      isEnterApp: false, // 是否进入当前路由组件
      taskTimer: null,
      isFromAuthCert: false, //不是认证详情，从认证页
      reckonTime: 3, //计时跳转
    }
  },
  watch: {
    'cForm.authFilePeopleId': function (newVal, oldVal) {
      // 授权人姓名
      if (this.validatenull(this.cForm.authName)) {
        this.cForm.authName = this.ocrInfo.name
        this.$refs['cForm'].clearValidate('authName')
      }
      // 授权人身份证号
      if (this.validatenull(this.cForm.authIdCard)) {
        this.cForm.authIdCard = this.ocrInfo.num
        this.$refs['cForm'].clearValidate('authIdCard')
      }
      // 授权人民族
      if (this.validatenull(this.cForm.authNation)) {
        this.cForm.authNation = this.ocrInfo.nationality
        this.$refs['cForm'].clearValidate('authNation')
      }
    },
  },
  created() {
    const { path } = this.$route
    this.isFromAuthCert = path.indexOf('authInfoIndex') > -1
    this.enterId = this.eId || ''
    this.isEnterApp = true
    this.initData()
    this.initAuth()
  },
  destroyed() {
    this.isEnterApp = false
    this.clearTaskTimer()
  },
  methods: {
    initData() {
      if (this.enterId) {
        doGet('/cust/enter/getEnterprise/' + this.enterId).then(({ data }) => {
          if (data.code === 200) {
            this.cForm = data.data || {}
            if (this.cForm.isAuth === '1') {
              this.disabled = true
            }
          }
        })
      }
    },
    initAuth() {
      doGet('/esgin/queryAuth/' + this.enterId).then(({ data }) => {
        if (data.code == 200) {
          const { authDto, signetDto } = data.data
          this.authDTO = authDto || {}
          this.enterFlag = authDto ? authDto.status : 0
          this.tableData = signetDto
          if (this.enterFlag == 2 || this.enterFlag == 1) {
            this.$parent.controlBtnFlag = 9
          }
          // 没有离开当前路由页面
          if (this.isEnterApp) {
            // 轮询,3s
            this.taskTimer = setTimeout(() => {
              const { authUrl, status } = this.authDTO
              if (authUrl && status == '1') {
                this.initAuth()
              } else {
                this.clearTaskTimer()
                // 认证成功，且是从列表点击“企业认证”按钮进来的
                if (status == '2' && this.isFromAuthCert) {
                  this.reckonByTime()
                }
              }
            }, 3 * 1000)
          }
        }
      })
    },
    /** 结束轮训 */
    clearTaskTimer() {
      if (this.taskTimer) {
        clearTimeout(this.taskTimer)
        this.taskTimer = null
      }
    },
    /** 计时跳转 */
    reckonByTime() {
      this.reckonTime--
      if (this.reckonTime > 0) {
        setTimeout(() => {
          this.reckonByTime()
        }, 1000)
      } else {
        this.$parent.active = 3
      }
    },

    onFileChange(ids = [], key) {
      this.$refs.cForm.validateField(key)
    },
    handleSetOcrInfo(val) {
      this.ocrInfo = { ...val }
    },
    addAuth() {
      this.enterFlag = 9
      this.$parent.controlBtnFlag = -1
    },
    goAuthCert() {
      const { authUrl } = this.authDTO
      window.open(authUrl, '_blank')
    },
    authEnter() {
      const that = this
      this.loading = true
      doGet('/esgin/authEnter/' + this.enterId).then((res) => {
        if (res.data.code == 200) {
          this.loading = false
          that.initAuth()
        } else {
          this.loading = false
          that.initAuth()
          this.$message.error(res.data.msg)
        }
      })
    },
    saveCompanyInfo() {
      this.$refs.cForm.validate((valid) => {
        if (valid) {
          this.loading = true
          const data = {
            ...this.cForm,
            legalIdcardAddress: this.ocrInfo.address || '',
          }
          data.id = this.enterId
          data.operType = 'auth'
          doPost('/cust/enter/saveEnterprise', data)
            .then((res) => {
              this.loading = false
              if (res.data.code == 200) {
                this.$emit('change', res.data.data.id)
                this.$parent.controlBtnFlag = 0
                this.enterFlag = 0
              } else {
                this.$notify({
                  title: res.data.msg,
                  type: 'error',
                  duration: 2500,
                })
              }
            })
            .catch((e) => {
              this.loading = false
            })
        } else {
          return false
        }
      })
    },
  },
})
</script>

<style scoped lang="scss">
.selection-box {
  border: 1px solid #f2f4f7;
  /deep/ .el-alert__title {
    color: #333;
    font-weight: 700;
  }
  .cForm {
    margin: 0 30px;
  }
}
.inner-box {
  margin-left: auto;
  margin-right: auto;
}
.sub_title {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  &:before {
    content: '';
    margin-right: 10px;
    width: 4px;
    height: 18px;
    background: #2d8cf0;
    border-radius: 10px;
  }
}
.smrz-section {
  border-collapse: collapse;
  vertical-align: top;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  .sign_company {
    display: flex;
    padding-bottom: 20px;
    align-items: flex-end;
    border-bottom: 1px solid #efefef;
  }
  .result {
    padding: 30px 0 40px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    .result-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px 0;
      .result-icon {
        font-size: 60px;
      }
      .result-text {
        font-size: 18px;
        line-height: 24px;
        color: #333;
        margin-top: 30px;
      }
    }
    .result-right {
      flex: 1;
      padding: 10px 0 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .result-list-item {
        font-weight: 400;
        font-size: 16px;
        line-height: 60px;
        display: flex;
        .result-list-item-label {
          width: 125px;
          color: #666;
        }
        .result-list-item-value {
          color: #333;
          font-weight: 600;
          text-align: center;
          flex: 1;
        }
      }
    }
  }
}
/deep/ .el-divider--horizontal {
  width: auto;
}
/deep/ .el-form--label-left .el-form-item__label {
  padding-left: 15px;
}
/deep/ .el-result {
  .el-result__subtitle {
    margin-top: 50px;
  }
}
</style>
