var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "cursor",
          on: {
            click: function ($event) {
              return _vm.onPreview()
            },
          },
        },
        [_vm._t("default")],
        2
      ),
      _vm.showViewer
        ? _c("el-image-viewer", {
            attrs: { "on-close": _vm.closeViewer, "url-list": [_vm.srcList] },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }