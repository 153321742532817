<template>
  <el-dialog
    title="修改手机号"
    width="680px"
    :visible.sync="visible"
  >
    <el-form ref="form" :model="form" :rules="rules" class="dialog-form pl30 pr30" label-width="100px">
      <el-form-item prop="phone" label="手机号码">
        <el-input v-model="form.phone" type="text" auto-complete="off" placeholder="请输入手机号码">
          <i slot="prefix" class="el-icon-user" style="font-size: 16px"/>
        </el-input>
      </el-form-item>
      <el-form-item prop="vcode" label="验证码">
        <el-input v-model="form.vcode" type="text" auto-complete="off" placeholder="请输入验证码">
          <i slot="prefix" class="icon-yanzhengma" />
          <div slot="suffix" class="input-suffix img-vcode">
            <img :src="codeUrl" width="100%" height="31" @click="getCode">
          </div>
        </el-input>
      </el-form-item>
      <el-form-item prop="pcode" label="手机验证码">
        <el-input v-model="form.pcode" type="text" auto-complete="off" placeholder="手机验证码">
          <i slot="prefix" class="icon-yanzhengma" />
          <div slot="suffix" class="input-suffix phone-code">
            <el-button type="primary" :disabled="pCode.disabled" @click="onPhoneCode">
              {{ pCode.text }}
            </el-button>
          </div>
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button :loading="loading" type="primary" @click="onSave">修改</el-button>
      <el-button :disabled="loading" @click="visible = false">取消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { doGet, doPost } from '@/router/axios'
import { getCodeImg } from '@/api/login'
export default {
  name: 'ChangePhone',
  props: {
    value: Boolean
  },
  model: {
    event: 'change',
    prop: 'value'
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('change', val)
      }
    }
  },
  watch: {
    value(visible) {
      this.$refs['form'] && this.$refs['form'].resetFields()
      if (visible) {
        this.getCode()
      }
    }
  },
  data() {
    return {
      codeUrl: '',
      form: {
        uuid: '',
        phone: '',
        pcode: '',
        vcode: '',
      },
      rules: {
        phone: [{ required: true, trigger: 'blur', message: '手机号不能为空' }],
        pcode: [{ required: true, trigger: 'blur', message: '请输入手机验证码' }],
        vcode: [{ required: true, trigger: 'blur', message: '验证码不能为空' }],
      },
      loading: false,
      pCode: {
        disabled: false,
        text: '获取验证码'
      }
    }
  },
  methods: {
    getCode() {
      getCodeImg().then(res => {
        this.codeUrl = 'data:image/gif;base64,' + res.data.data.img
        this.form.uuid = res.data.data.uuid
      })
    },
    onSave() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const data = { ...this.form }
          doPost('/cust/reg/reg', data).then(res => {
            if (res.code == 200) {
              this.$notify({
                title: '修改成功',
                type: 'success',
                duration: 2500
              })
              this.loading = false
              this.visible = false
            } else {
              this.getCode()
              this.loading = false
              this.$notify({
                title: res.msg,
                type: 'error',
                duration: 2500
              })
            }
          }
          ).catch(e => {
            this.loading = false
            // this.$notify({
            //   title: '失败',
            //   type: 'error',
            //   duration: 2500
            // })
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    onPhoneCode() {
      const user = {
        loginName: this.form.phone,
        vcode: this.form.vcode,
        uuid: this.form.uuid,
        type: 1
      }
      this.pCode.disabled = true
      doPost('/cust/reg/sendCode', user).then(res => {
        this.loading = false
        if (res.code == 200) {
          this.setTimer()
        } else {
          this.getCode()
          this.$notify({
            title: res.msg,
            type: 'error',
            duration: 2500
          })
          this.pCode.disabled = false
        }
      }).catch(e => {
        this.getCode()
        this.$notify({
          title: e.msg,
          type: 'error',
          duration: 2500
        })
        this.loading = false
        this.pCode.disabled = false
      })
    },
    setTimer() {
      this.timer && clearInterval(this.timer)
      let count = 119
      this.timer = setInterval(() => {
        count--
        this.pCode.text = count + 's'
        if (count === 0) {
          this.pCode.text = '获取验证码'
          this.pCode.disabled = false
          clearInterval(this.timer)
        }
      }, 1000)
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
