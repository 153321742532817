var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "selection-box" }, [
    _vm.enterFlag == 0
      ? _c(
          "section",
          [
            _c(
              "el-card",
              { staticClass: "box-card" },
              [
                _c("el-result", {
                  attrs: {
                    icon: "success",
                    title: "",
                    "sub-title":
                      "即将开始企业认证，认证服务由国家认证CA机构“e签宝”提供，按照提示操作即可",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-card",
              { staticClass: "box-card" },
              [
                _c("el-result", {
                  attrs: {
                    icon: "warning",
                    title: "",
                    "sub-title":
                      "若您不是企业法人，请登记企业授权人信息，法人将在流程中对您的操作行为进行授权",
                  },
                }),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.enterFlag == 9
      ? _c(
          "section",
          [
            _c(
              "el-form",
              {
                ref: "cForm",
                staticClass: "cForm mt20",
                attrs: {
                  model: _vm.cForm,
                  rules: _vm.vRules,
                  "label-position": "left",
                  "label-width": "150px",
                  size: "small",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "mt10 inner-box" },
                  [
                    _c("el-alert", {
                      attrs: {
                        title: "请上传授权人身份证正反面照片",
                        closable: false,
                        type: "warning",
                        "show-icon": "",
                      },
                    }),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "mt20",
                        attrs: {
                          label: "身份证（人像面）",
                          prop: "authFilePeopleId",
                        },
                      },
                      [
                        _c("dict-upload-info", {
                          attrs: {
                            disabled: _vm.disabled,
                            type: "image",
                            limit: 1,
                            "type-code": "F0204",
                            "biz-id": "123456",
                            url: "cust/enter/upload",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onFileChange(
                                $event,
                                "authFilePeopleId"
                              )
                            },
                            getOcrInfo: _vm.handleSetOcrInfo,
                          },
                          model: {
                            value: _vm.cForm.authFilePeopleId,
                            callback: function ($$v) {
                              _vm.$set(_vm.cForm, "authFilePeopleId", $$v)
                            },
                            expression: "cForm.authFilePeopleId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "身份证（国徽面）",
                          prop: "authFileReverseId",
                        },
                      },
                      [
                        _c("dict-upload-info", {
                          attrs: {
                            disabled: _vm.disabled,
                            type: "image",
                            limit: 1,
                            "type-code": "F0203",
                            "biz-id": "123456",
                            url: "cust/enter/upload",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onFileChange(
                                $event,
                                "authFileReverseId"
                              )
                            },
                          },
                          model: {
                            value: _vm.cForm.authFileReverseId,
                            callback: function ($$v) {
                              _vm.$set(_vm.cForm, "authFileReverseId", $$v)
                            },
                            expression: "cForm.authFileReverseId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("el-alert", {
                      attrs: {
                        title: "授权人身份信息确认",
                        closable: false,
                        type: "info",
                        "show-icon": "",
                      },
                    }),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "mt20",
                        attrs: { label: "授权人姓名：", prop: "authName" },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "350px" },
                          attrs: {
                            maxlength: "20",
                            disabled: _vm.disabled,
                            placeholder: "请输入授权人姓名",
                          },
                          model: {
                            value: _vm.cForm.authName,
                            callback: function ($$v) {
                              _vm.$set(_vm.cForm, "authName", $$v)
                            },
                            expression: "cForm.authName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "授权人身份证号：",
                          prop: "authIdCard",
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "350px" },
                          attrs: {
                            maxlength: "18",
                            disabled: _vm.disabled,
                            placeholder: "一般为18/15位数字或字母组合",
                          },
                          model: {
                            value: _vm.cForm.authIdCard,
                            callback: function ($$v) {
                              _vm.$set(_vm.cForm, "authIdCard", $$v)
                            },
                            expression: "cForm.authIdCard",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "授权人民族：", prop: "authNation" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "350px" },
                          attrs: {
                            disabled: _vm.disabled,
                            placeholder: "请输入授权人民族",
                          },
                          model: {
                            value: _vm.cForm.authNation,
                            callback: function ($$v) {
                              _vm.$set(_vm.cForm, "authNation", $$v)
                            },
                            expression: "cForm.authNation",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("el-alert", {
                      attrs: {
                        title: "授权人联系方式",
                        closable: false,
                        type: "info",
                        "show-icon": "",
                      },
                    }),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "mt20",
                        attrs: { label: "手机号码：", prop: "authPhone" },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "350px" },
                          attrs: {
                            maxlength: "11",
                            disabled: _vm.disabled,
                            placeholder: "请输入授权人手机号码",
                          },
                          model: {
                            value: _vm.cForm.authPhone,
                            callback: function ($$v) {
                              _vm.$set(_vm.cForm, "authPhone", $$v)
                            },
                            expression: "cForm.authPhone",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "电子邮箱：", prop: "authEmail" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "350px" },
                          attrs: {
                            maxlength: "30",
                            disabled: _vm.disabled,
                            placeholder: "请输入授权人电子邮箱",
                          },
                          model: {
                            value: _vm.cForm.authEmail,
                            callback: function ($$v) {
                              _vm.$set(_vm.cForm, "authEmail", $$v)
                            },
                            expression: "cForm.authEmail",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.enterFlag == 1 || _vm.enterFlag == 2
      ? _c("section", [
          _c("div", { staticClass: "smrz-section" }, [
            _vm._m(0),
            _c("div", { staticClass: "result" }, [
              _c(
                "div",
                { staticClass: "result-left" },
                [
                  _c("i", {
                    class: [
                      _vm.authDTO.status !== "2"
                        ? "el-icon-error fc-red"
                        : "el-icon-success fc-green",
                      "result-icon",
                    ],
                  }),
                  _c("div", { staticClass: "result-list-item mt30" }, [
                    _c(
                      "div",
                      { staticClass: "result-list-item-value f18 fb" },
                      [_vm._v(_vm._s(_vm.authDTO.enterName))]
                    ),
                  ]),
                  _c("div", { staticClass: "result-text" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.authDTO.retMsg) +
                        "\n            "
                    ),
                    _vm.isFromAuthCert && _vm.authDTO.status == "2"
                      ? _c("span", { staticStyle: { color: "#f00" } }, [
                          _vm._v("（" + _vm._s(_vm.reckonTime) + "s）"),
                        ])
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "result-btns mt30" }),
                  _vm.authDTO.authUrl && _vm.authDTO.status == "1"
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.goAuthCert },
                        },
                        [_vm._v("\n            直接去认证\n          ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "sign_company" }, [
      _c("img", {
        staticClass: "mr20",
        attrs: { src: require("@/assets/icon/icon_elogo.png"), width: "100" },
      }),
      _vm._v("\n        认证服务由国家认证CA机构“e签宝”提供\n      "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }