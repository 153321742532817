var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "section",
      {
        staticStyle: { "text-justify-trim": "punctuation" },
        attrs: { lang: "ZH-CN" },
      },
      [
        _c(
          "div",
          {
            staticClass: "WordSection1",
            staticStyle: { "layout-grid": "15.6pt" },
          },
          [
            _c("h2", [_vm._v("个人信息采集及使用授权协议")]),
            _c("p", { staticClass: "MsoNormal" }, [_vm._v("重要提示：")]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _vm._v(
                "为了保障您的合法权益，您应当阅读并遵守本授权书，请您务必审慎阅读、充分理解本授权书条款内容，特别是免除或者减轻"
              ),
              _c("b", [
                _vm._v("北京联动世纪科技发展有限公司（以下简称“必米供应链”）"),
              ]),
              _vm._v(
                "等被授权主体责任，或限制您权利的条款，其中免除或者减轻责任条款可能以加粗形式提示您注意。"
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [_vm._v("授权人：")]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _vm._v("授权人身份证号码："),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _vm._v("授权日期："),
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("    ")]),
              _vm._v("年"),
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("   ")]),
              _vm._v("月"),
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("   ")]),
              _vm._v("日"),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _vm._v("因"),
              _c("b", [_vm._v("北京联动世纪科技发展有限公司")]),
              _vm._v(
                "通过必米供应链金融服务平台（以下简称“金融服务平台”）为本人向融资机构申请融资提供信息咨询服务，故本人自愿作出下述授权，本授权书是本人真实的意思表示，本人同意承担由此带来的一切法律后果。"
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [_vm._v("一、信息采集授权")]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _vm._v(
                "本人在此不可撤销地授权并同意金融服务的提供者和技术提供者"
              ),
              _c("u", [_vm._v("北京联动世纪科技发展有限公司")]),
              _vm._v(
                "等相关主体（以下统称“被授权方”）以为本人提供资信评估服务、整体、全面判断本人的履约能力及意愿之目的，按照以下方式、范围获取本人信息："
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _vm._v("（"),
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("1")]),
              _vm._v(
                "）本人在申请或使用融资时向融资机构提供的信息，包括姓名、身份证号、联系电话、联系地址、联系人信息等融资申请相关信息；"
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _vm._v("（"),
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("2")]),
              _vm._v(
                "）收集本人在被授权方的合作方留存或形成的与融资相关的必要信息，包括本人的资产信息、履约信息等；"
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _vm._v("（"),
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("3")]),
              _vm._v(
                "）向合法保存有本人信息的第三方，采集本人的各类信息，包括本人个人身份信息、信用信息、交易信息、工商注册信息、诉讼信息、社保信息、资产信息以及通过资产评估机构查询、评估本人财产价值等与融资相关的必要信息；同时授权该第三方可直接向必米供应链提供本人的信息而不需要本人再次授权。本人确认，可直接向被授权方提供本人信息的第三方包括但不限于：第三方征信机构、资信评估机构或有关法律、监管机构许可的类似机构以及依法成立的合法采集机构或认证机构、电信运营商、公安部公民身份信息数据库、政府机构、司法机关及公共事业单位（如公积金管理中心、人力资源和社会保障部门等机构）、被授权方的关联公司；"
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _vm._v(
                "被授权方采集信息时，本人授权融资机构将与融资相关的本人信息（包含但不限于融资金额、期限、利率、状态、还款计划、账户信息等）提供给被授权方。"
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [_vm._v("二、信息使用授权")]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _vm._v(
                "本人同意并不可撤销地授权被授权方以为本人提供信息咨询服务、为融资机构的风险防范之目的，保存、整理、加工前述第一条所述的、通过合法途径采集的本人信息，用于评价本人信用情况或核实本人信息的真实性，并基于以下用途向融资机构以及其他经本人授权同意的中国境内的其他信息使用者提供："
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _vm._v("（一）审核本人或配偶提出的融资申请以及其他业务需求；"),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _vm._v(
                "（二）接受融资机构方委托，对本人的经营状况、资产情况、信用等级进行合理评估；"
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _vm._v(
                "（三）接受融资机构方委托处理贷后管理事务（包括催收）或提供给合作的催收机构用于贷后催收；"
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _vm._v("（四）依法或经有权部门要求；"),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _vm._v(
                "（五）以为本人提供再融资服务为目的，将本人个人信息（包括但不限于身份信息、财产信息、融资信息、信用信息等）提供给合作的其他第三方（包括融资机构、信息服务中介等）；"
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _vm._v("（六）其他本人同意的用途。"),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [_vm._v("三、信息共享授权")]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _vm._v(
                "本人授权被授权方的关联方，除法律另有规定之外，将本人提供给被授权方的关联方的信息、享受被授权方的关联方服务产生的信息（包括本条款签署之前提供和产生的信息）以及被授权方的关联方根据本条款约定查询、收集的信息，用于被授权方的关联方及其因服务必要委托的合作伙伴为本人提供服务、推荐产品、开展市场调查与信息数据分析。"
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _vm._v(
                "本人授权被授权方的关联方，除法律另有规定之外，基于为本人提供更优质服务和产品的目的，向被授权方的关联方因服务必要开展合作的伙伴提供、查询、收集本人的信息。"
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _vm._v(
                "前述“信息”可能包括但不限于本人的身份信息、财产信息、账户信息、信用信息、交易信息、行踪轨迹信息、电子设备信息、电子设备操作日志及其他与本人相关的信息。为确保本人信息的安全，被授权方的关联方对上述信息负有保密义务，并尽最大努力采取各种措施保证信息安全。"
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _vm._v(
                "本条款自本人签署时生效，具有独立法律效力，不受本条款所属合同或协议成立与否及效力状态变化的影响。"
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _vm._v(
                "本条款所称被授权方的关联方是指北京联动世纪科技发展有限公司及其直接或间接控股的公司，北京联动世纪科技发展有限公司直接或间接作为其单一最大股东的公司，以及北京联动世纪科技发展有限公司以其他方式直接或间接控制或有重大影响的主体。"
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _vm._v("注：您可通过电话"),
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("/")]),
              _vm._v("邮件方式取消或变更上述授权。"),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [_vm._v("四、风险揭示")]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _vm._v(
                "本人知悉：包含本人身份信息、财产信息、账户信息、信用信息、交易信息、行踪轨迹信息、电子设备信息、电子设备操作日志等信息为本人重要且私密的个人信息。本人充分了解并明确知悉被授权方向第三方采集并在法律法规许可范围内向信息使用者提供这些信息后，信息被其他主体对外提供和使用的风险，包括但不限于：这些信息对您的信用评级、信用报告等结果可能产生不利影响；该等信息被被授权方依法提供给第三方后被他人不当利用的风险；第三方向您推销产品或服务而打扰您等风险。此情况下，本人同意并不可撤销地授权被授权方依照本授权书约定向其他机构提供本人个人信息。"
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [_vm._v("授权人声明：")]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _vm._v(
                "本授权书系授权方对被授权方做出的单方承诺，效力具有独立性，不因其他合同的任何条款无效而失效。"
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _vm._v(
                "以上授权期限为本人作出本授权承诺之日起至本人在被授权方处所有业务终结之日止。以上授权截止后，被授权方仍有权向征信机构查询、上报本人的信用信息。"
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _vm._v(
                "若本人与被授权人发生任何纠纷或争议，首先应友好协商解决；协商不成的，本人同意将纠纷或争议提交上海国际经济贸易仲裁委员会仲裁解决。本授权书的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。"
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _vm._v(
                "本人已知悉本授权书所有内容（特别是加粗字体内容）的意义以及由此产生的法律效力，自愿作出上述授权，本授权书是本人真实的意思表示，本人同意承担由此带来的一切法律后果。"
              ),
            ]),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }