<template>
  <a class="download-file" :href="href" download target="_blank">
    <slot />
  </a>
</template>
<script>
import { getImageUrl } from '@/api/upload'
export default {
  name: 'DownloadFile',
  props: {
    fileId: String
  },
  computed: {
    href() {
      const ids = this.fileId;
      return getImageUrl(this.fileId)
    }
  }
}
</script>
<style lang="scss" scoped>
  @import '~@/styles/variables.scss';

  .download-file {
    color: $pendingColor;
    position: relative;
    font-weight: 600;
    &:after {
      content: "";
      display: block;
      height: 1px;
      background: $subMenuBg;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: -7px;
    }
  }
</style>
