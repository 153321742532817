import { render, staticRenderFns } from "./index.vue?vue&type=template&id=117a9b35"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/bium-ops-web_2zId/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('117a9b35')) {
      api.createRecord('117a9b35', component.options)
    } else {
      api.reload('117a9b35', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=117a9b35", function () {
      api.rerender('117a9b35', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/monitor/server/index.vue"
export default component.exports