<template>
  <div v-loading="isLoading">
    <div class="mz-layout-section">
      <div class="panel-tips">
        <div>1、可用余额解释性信息</div>
        <div>2、可提取金额的解释性信息。</div>
      </div>
    </div>

    <div class="mz-layout-section mt20">
      <div class="flex-infos">
        <section class="item">
          <div class="item-header">
            <span>可用金额</span>
          </div>
          <div class="item-main">
            <span class="money fc-red">￥ {{ this.form.balanceAmt }}</span>
          </div>
        </section>
        <section class="item" style="flex: 3">
          <div class="item-header">
            <span>还款金额</span>
          </div>
          <div class="item-main">
            <span class="money fc_333">￥ {{ withdrawalAmount }}</span>
          </div>
        </section>
      </div>
    </div>

    <div class="mz-layout-section mt20">
      <h3 class="details-section-h3"><span>还款计划表</span></h3>
      <repayment-schedule :billLines="form.lines" @change="setWithdrawalAmount" />
    </div>

    <div class="mt30 tc">
      <el-button type="primary" size="medium" v-loading="isSubmiting" @click="handleSave()">
        提交
      </el-button>
    </div>
  </div>
</template>

<script>
import { doGet, doPost } from '@/router/axios'
import repaymentSchedule from './repaymentSchedule.vue'

export default {
  name: 'RepaymentDetails',
  components: { repaymentSchedule },
  data() {
    return {
      isLoading: true,
      isSubmiting: false,
      form: { balanceAmt: 0, lines: [], linesId: [], billId: '' },
      withdrawalAmount: 0,
    }
  },
  created() {
    const { billId } = this.$route.query
    this.form.billId = billId
    this.initData(billId)
  },
  methods: {
    initData(id) {
      this.isLoading = true
      doGet(`/rent/bill/repay/` + id)
        .then(({ data }) => {
          if (data && data.data) {
            Object.assign(this.form, data.data)
          }
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
        })
    },

    setWithdrawalAmount(val, data) {
      this.withdrawalAmount = val.toFixed(2)
      this.form.linesId = data
    },
    handleSave() {
      if (!this.isSubmiting) {
        if (this.form.linesId.length <= 0) {
          this.$notify.error('请选择还款期数')
          return
        }
        this.isSubmiting = true
        doPost('/repay/doPay', this.form)
          .then(({ data }) => {
            if (data.code === 200) {
              this.$notify.success('申请成功')
              this.$store.commit('SET_BREADCRUMB', ['极速收款', '主动还款'])
              this.$router.push({ path: '/repayment/activeRepay' })
            }
          })
          .catch(() => {
            this.isSubmiting = false
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
