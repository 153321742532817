var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "acc-add-jg" },
    [
      _c(
        "el-alert",
        { attrs: { type: "alert-blue", "show-icon": "", closable: false } },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v("温馨提示："),
          ]),
          _c("div", { staticClass: "fc-blue-t" }, [
            _vm._v(
              "\n\t\t\t您需要开通平台监管账户！请填写您的\n\t\t\t实体账户\n\t\t\t作为验证账户。\n\t\t"
            ),
          ]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          staticClass: "selection-box",
        },
        [
          _c(
            "el-form",
            {
              ref: "formRef",
              attrs: {
                model: _vm.dataForm.model,
                rules: _vm.dataForm.rules,
                "label-position": "right",
                "label-width": "120px",
                size: "small",
              },
            },
            [
              _c("el-button", { attrs: { type: "primary" } }, [
                _vm._v("使用提现账户作为验证账户"),
              ]),
              _c("el-alert", {
                staticClass: "mt10",
                attrs: { title: "监管账户绑定", closable: false, type: "info" },
              }),
              _c(
                "div",
                { staticClass: "mt20 inner-box" },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "pr20",
                      attrs: { label: "选择账户类型", prop: "cardNature" },
                    },
                    [
                      _c("dict-select", {
                        attrs: { no: "D000110", placeholder: "选择账户类型" },
                        on: { change: _vm.changeCardNature },
                        model: {
                          value: _vm.dataForm.model.cardNature,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataForm.model, "cardNature", $$v)
                          },
                          expression: "dataForm.model.cardNature",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "bk_gray pt20 pr20 pb10" }, [
                    _c("div", { staticClass: "flex-c-s" }, [
                      _c("div", { staticClass: "fc-blue-t pl50" }, [
                        _vm._v("开户验证账户信息"),
                      ]),
                      _c(
                        "div",
                        [
                          _c("dict-upload", {
                            attrs: {
                              limit: 1,
                              "type-code": "F0301",
                              "biz-id": "123456",
                              type: "text",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.onFileChange($event, "fileIds")
                              },
                            },
                            model: {
                              value: _vm.dataForm.fileIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "fileIds", $$v)
                              },
                              expression: "dataForm.fileIds",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "bk_gray pt5 pr20 pb20" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "账户名称", prop: "bankUserName" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入户名" },
                            model: {
                              value: _vm.dataForm.model.bankUserName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.dataForm.model,
                                  "bankUserName",
                                  $$v
                                )
                              },
                              expression: "dataForm.model.bankUserName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "账户号", prop: "bankCard" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "识别后自动填入可修改" },
                            model: {
                              value: _vm.dataForm.model.bankCard,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm.model, "bankCard", $$v)
                              },
                              expression: "dataForm.model.bankCard",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "所属银行", prop: "bankCode" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "识别后自动填入可修改" },
                            model: {
                              value: _vm.dataForm.model.bankCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm.model, "bankCode", $$v)
                              },
                              expression: "dataForm.model.bankCode",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "选择支行", prop: "provinceCode" } },
                        [
                          _c("DictSelectSingle", {
                            staticStyle: { width: "120px" },
                            attrs: {
                              clearable: false,
                              url: "/cap/capBankCustInfo/province",
                              placeholder: "请选择省份",
                            },
                            on: { change: _vm.changeProvince },
                            model: {
                              value: _vm.dataForm.model.provinceCode,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.dataForm.model,
                                  "provinceCode",
                                  $$v
                                )
                              },
                              expression: "dataForm.model.provinceCode",
                            },
                          }),
                          _vm._v("\n\t\t\t\t\t\t \n\t\t\t\t\t\t"),
                          _vm.dataForm.model.provinceCode
                            ? _c("DictSelectSingle", {
                                key: _vm.refreshCityDom,
                                staticStyle: { width: "120px" },
                                attrs: {
                                  clearable: false,
                                  url: _vm.cityUrl,
                                  placeholder: "请选择城市",
                                },
                                on: { change: _vm.changeCity },
                                model: {
                                  value: _vm.dataForm.model.cityCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataForm.model,
                                      "cityCode",
                                      $$v
                                    )
                                  },
                                  expression: "dataForm.model.cityCode",
                                },
                              })
                            : _vm._e(),
                          _vm._v("\n\t\t\t\t\t\t \n\t\t\t\t\t\t"),
                          _vm.dataForm.model.bankCode &&
                          _vm.dataForm.model.cityCode
                            ? _c("DictSelectSingle", {
                                key: _vm.refreshBankDom,
                                staticStyle: { width: "200px" },
                                attrs: {
                                  clearable: false,
                                  url: _vm.bankUrl,
                                  placeholder: "请选择支行",
                                },
                                on: { change: _vm.changeBank },
                                model: {
                                  value: _vm.dataForm.model.bankSubNo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataForm.model,
                                      "bankSubNo",
                                      $$v
                                    )
                                  },
                                  expression: "dataForm.model.bankSubNo",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  this.jianSheType == 1
                    ? _c(
                        "span",
                        { staticStyle: { "font-size": "12px", color: "red" } },
                        [_vm._v("为了更优质的融资服务，请填写建设银行账户！")]
                      )
                    : _vm._e(),
                  _c(
                    "el-checkbox",
                    {
                      staticClass: "agreement-check",
                      model: {
                        value: _vm.isAgreement,
                        callback: function ($$v) {
                          _vm.isAgreement = $$v
                        },
                        expression: "isAgreement",
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t本人已仔细阅读并完全理解、接受如下文件，接受该等文件的约束\n\t\t\t\t\t"
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "link-able",
                          on: {
                            click: function ($event) {
                              return _vm.jumpToUrl("F0304")
                            },
                          },
                        },
                        [_vm._v("《资金监管服务协议》")]
                      ),
                      this.jianSheType != 1
                        ? _c("span", [
                            _vm._v("\n\t\t\t\t\t\t、\n\t\t\t\t\t\t"),
                            _c(
                              "a",
                              {
                                staticClass: "link-able",
                                on: {
                                  click: function ($event) {
                                    return _vm.jumpToUrl("F0305")
                                  },
                                },
                              },
                              [_vm._v("《保理合同》")]
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "mt20",
                      staticStyle: { "text-align": "center" },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            size: "medium",
                            disabled: !_vm.isAgreement,
                            loading: _vm.isSubmiting,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.saveCard()
                            },
                          },
                        },
                        [_vm._v("\n\t\t\t\t\t\t确认提交\n\t\t\t\t\t")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }