var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user" },
    [
      _c(
        "basic-container",
        [
          _c(
            "avue-crud",
            {
              ref: "crud",
              attrs: {
                option: _vm.option,
                page: _vm.page,
                "table-loading": _vm.listLoading,
                "before-open": _vm.handleOpenBefore,
                data: _vm.list,
              },
              on: {
                "update:page": function ($event) {
                  _vm.page = $event
                },
                "on-load": _vm.getList,
                "size-change": _vm.sizeChange,
                "current-change": _vm.currentChange,
                "search-change": _vm.handleFilter,
                "refresh-change": _vm.handleRefreshChange,
                "row-update": _vm.update,
                "row-save": _vm.create,
              },
              scopedSlots: _vm._u([
                {
                  key: "menu",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", icon: "el-icon-edit" },
                          on: {
                            click: function ($event) {
                              return _vm.handleUpdate(scope.row, scope.index)
                            },
                          },
                        },
                        [_vm._v("编辑\n        ")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { color: "#f00" },
                          attrs: { type: "text", icon: "el-icon-delete" },
                          on: {
                            click: function ($event) {
                              return _vm.deletes(scope.row, scope.index)
                            },
                          },
                        },
                        [_vm._v("删除\n        ")]
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.form,
                callback: function ($$v) {
                  _vm.form = $$v
                },
                expression: "form",
              },
            },
            [
              _c(
                "template",
                { slot: "menuLeft" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      attrs: { type: "primary", icon: "el-icon-plus" },
                      on: {
                        click: function ($event) {
                          return _vm.$refs.crud.rowAdd()
                        },
                      },
                    },
                    [_vm._v("添加\n        ")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }