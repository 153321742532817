var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.title, width: "680px", visible: _vm.visible },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "dialog-form pl30 pr30",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "100px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "oldPass", label: "旧密码" } },
            [
              _c(
                "el-input",
                {
                  attrs: {
                    type: "password",
                    "auto-complete": "off",
                    maxlength: "30",
                    placeholder: "请输入旧密码，初次设置可不填",
                  },
                  model: {
                    value: _vm.form.oldPass,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "oldPass", $$v)
                    },
                    expression: "form.oldPass",
                  },
                },
                [
                  _c("i", {
                    staticClass: "icon-mima",
                    attrs: { slot: "prefix" },
                    slot: "prefix",
                  }),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "password", label: "设置新密码" } },
            [
              _c(
                "el-input",
                {
                  attrs: {
                    type: "password",
                    maxlength: "20",
                    "auto-complete": "off",
                    placeholder: "请设置新密码",
                  },
                  model: {
                    value: _vm.form.password,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "password", $$v)
                    },
                    expression: "form.password",
                  },
                },
                [
                  _c("i", {
                    staticClass: "icon-mima",
                    attrs: { slot: "prefix" },
                    slot: "prefix",
                  }),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "checkPass", label: "确认新密码" } },
            [
              _c(
                "el-input",
                {
                  attrs: {
                    type: "password",
                    maxlength: "20",
                    "auto-complete": "off",
                    placeholder: "请确认新密码",
                  },
                  model: {
                    value: _vm.form.checkPass,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "checkPass", $$v)
                    },
                    expression: "form.checkPass",
                  },
                },
                [
                  _c("i", {
                    staticClass: "icon-mima",
                    attrs: { slot: "prefix" },
                    slot: "prefix",
                  }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { loading: _vm.loading, type: "primary" },
              on: { click: _vm.onSave },
            },
            [_vm._v("修改")]
          ),
          _c(
            "el-button",
            {
              attrs: { disabled: _vm.loading },
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }