var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mz-layout-content" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 30 } },
        [
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "grid-card bg-blue" }, [
              _c("div", { staticClass: "tag" }, [_vm._v("累计放款")]),
              _c("div", { staticClass: "title" }, [
                _vm._v("累计放款金额 (万元)"),
              ]),
              _c("div", { staticClass: "number" }, [
                _vm._v(_vm._s(_vm._f("numberToCurrency")(_vm.bill.totalAmt))),
              ]),
              _c("div", { staticClass: "tip" }),
            ]),
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "grid-card bg-orange" }, [
              _c("div", { staticClass: "tag" }, [_vm._v("累计回款")]),
              _c("div", { staticClass: "title" }, [
                _vm._v("累计回款金额 (万元)"),
              ]),
              _c("div", { staticClass: "number" }, [
                _vm._v(
                  _vm._s(_vm._f("numberToCurrency")(_vm.bill.totalRepayAmt))
                ),
              ]),
              _c("div", { staticClass: "tip" }),
            ]),
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "grid-card bg-red" }, [
              _c("div", { staticClass: "tag" }, [_vm._v("已请款")]),
              _c("div", { staticClass: "title" }, [_vm._v("当日请款金额(元)")]),
              _c("div", { staticClass: "number" }, [
                _vm._v(
                  _vm._s(_vm._f("numberToCurrency")(_vm.bill.dayOrderAmt))
                ),
              ]),
              _c("div", { staticClass: "tip" }),
            ]),
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "grid-card bg-blue" }, [
              _c("div", { staticClass: "tag" }, [_vm._v("已放款")]),
              _c("div", { staticClass: "title" }, [_vm._v("当日放款金额(元)")]),
              _c("div", { staticClass: "number" }, [
                _vm._v(_vm._s(_vm._f("numberToCurrency")(_vm.bill.dayLoanAmt))),
              ]),
              _c("div", { staticClass: "tip" }),
            ]),
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "grid-card bg-orange" }, [
              _c("div", { staticClass: "tag" }, [_vm._v("已回款")]),
              _c("div", { staticClass: "title" }, [_vm._v("当日回款本金(元)")]),
              _c("div", { staticClass: "number" }, [
                _vm._v(
                  _vm._s(_vm._f("numberToCurrency")(_vm.bill.dayRepayAmt))
                ),
              ]),
              _c("div", { staticClass: "tip" }),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "mt20", attrs: { gutter: 30 } },
        _vm._l(_vm.list, function (item, index) {
          return _c("el-col", { key: index, attrs: { span: 8 } }, [
            _c("div", { staticClass: "grid-content" }, [
              item.status == "01" || item.status == "00"
                ? _c(
                    "div",
                    { staticClass: "grid-title blue" },
                    [
                      _c("div", [
                        _c("div", { staticClass: "g-t-key" }, [
                          _vm._v("待还金额(元)"),
                        ]),
                        _c("div", { staticClass: "g-t-value" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("numberToCurrency")(item.surpTotalAmount)
                            )
                          ),
                        ]),
                      ]),
                      item.status == "01" || item.status == "00"
                        ? _c(
                            "el-tag",
                            {
                              staticClass: "grid-title-tag",
                              attrs: { size: "mini" },
                            },
                            [_vm._v("还款中")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              item.status == "02"
                ? _c(
                    "div",
                    { staticClass: "grid-title red" },
                    [
                      _c("div", [
                        _c("div", { staticClass: "g-t-key" }, [
                          _vm._v("待还金额(元)"),
                        ]),
                        _c("div", { staticClass: "g-t-value" }, [
                          _vm._v(
                            _vm._s(_vm._f("numberToCurrency")(item.surpAmount))
                          ),
                        ]),
                      ]),
                      _c(
                        "el-tag",
                        {
                          staticClass: "grid-title-tag",
                          attrs: { size: "mini", type: "danger" },
                          on: {
                            click: function ($event) {
                              return _vm.handleDetails(item, index)
                            },
                          },
                        },
                        [_vm._v("已逾期")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "grid-item-group",
                  on: {
                    click: function ($event) {
                      return _vm.handleDetails(item, index)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "grid-item" }, [
                    _c("div", [_vm._v("企业")]),
                    _c("div", [_vm._v(_vm._s(item.enterName))]),
                  ]),
                  _c("div", { staticClass: "grid-item" }, [
                    _c("div", [_vm._v("店铺")]),
                    _c("div", [_vm._v(_vm._s(item.shopName))]),
                  ]),
                  _c("div", { staticClass: "grid-item" }, [
                    _c("div", [_vm._v("平台")]),
                    _c("div", [_vm._v(_vm._s(item.liveStreamPlat))]),
                  ]),
                  _c("div", { staticClass: "grid-item" }, [
                    _c("div", [_vm._v("融资单号")]),
                    _c("div", [_vm._v(_vm._s(item.billNo))]),
                  ]),
                  _c("div", { staticClass: "grid-item" }, [
                    _c("div", [_vm._v("总笔数")]),
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm._f("numberToCurrency")(item.totalNum, 0))
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "grid-item" }, [
                    _c("div", [_vm._v("实际请款金额")]),
                    _c("div", [
                      _vm._v(
                        "¥ " +
                          _vm._s(_vm._f("numberToCurrency")(item.billAmount))
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "grid-item" }, [
                    _c("div", [_vm._v("综合服务费（息费打包）")]),
                    _c("div", [
                      _vm._v(
                        "¥ " +
                          _vm._s(_vm._f("numberToCurrency")(item.billInterest))
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "grid-item" }, [
                    _c("div", [_vm._v("订单最长等待天数")]),
                    _c("div", [_vm._v(_vm._s(item.maxDay))]),
                  ]),
                  _c("el-divider"),
                  _c("div", { staticClass: "grid-item" }, [
                    _c("div", [_vm._v("剩余待还本金")]),
                    _c("div", [
                      _vm._v(
                        "¥ " +
                          _vm._s(_vm._f("numberToCurrency")(item.surpAmount))
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "grid-split" }),
              _c(
                "div",
                {
                  staticClass: "grid-btn btn-blue mt10",
                  on: {
                    click: function ($event) {
                      return _vm.handleDetails(item, index)
                    },
                  },
                },
                [_vm._v("还款详情")]
              ),
            ]),
          ])
        }),
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "还款详情",
            visible: _vm.dialogVisible,
            width: "98%",
            top: "30px",
            "custom-class": "pub_dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.fileId
            ? _c("page-details", { attrs: { "modal-data": _vm.fileId } })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }