import Vue from 'vue'
import EntityUtils from '@/utils/entity/EntityUtils.js'

// 权限指令
const enumeration = Vue.directive('enumeration', {
  bind: function (el, binding, vnode) {
    console.log('======enumeration-bind')
    setTagContent(el, binding)
  },
  inserted: function (el, binding, vnode, oldVnode) {},
  update(el, binding, vnode, oldVnode) {
    console.log('======enumeration-update')
    setTagContent(el, binding)
  },
  componentUpdated: function (el, binding, vnode, oldVnode) {},
})

const setTagContent = (el, binding) => {
  // 指令绑定到元素时触发
  const innerText = el.innerText || binding.value.length > 0 ? binding.value[0] : binding.value
  const value = binding.value.length > 0 ? binding.value[1] : binding.value
  if (innerText && value) {
    const newsInnerText = EntityUtils.translation(innerText, value) || innerText
    // 获取一个颜色值
    const color = EntityUtils.obtainColor(innerText, value) || '#333'
    el.innerText = newsInnerText
    // 设定一个颜色
    el.style.color = color
  }
}

export default enumeration
