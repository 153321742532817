var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "shop-loan" },
    [
      _c(
        "div",
        { staticClass: "mz-layout-content" },
        [
          _c(
            "div",
            { staticClass: "bg-grey" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 30 } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "grid-card" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v("总订单金额 (元)："),
                      ]),
                      _c("div", { staticClass: "number" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("numberToCurrency")(_vm.bill.totalGoodsAmt)
                          )
                        ),
                      ]),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "grid-card" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v("总可申请最大金额 (元)："),
                      ]),
                      _c("div", { staticClass: "number" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("numberToCurrency")(_vm.bill.totalApplyAmt)
                          )
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "mt20",
              attrs: { gutter: 30 },
            },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    data: _vm.dataList,
                    stripe: "",
                    "header-row-class-name": "header_row_className",
                    "header-cell-style": {
                      "background-color": "#FAFAFA",
                    },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "item",
                      label: "名称",
                      fixed: "",
                      width: "120px",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.shopName || "-") +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "item",
                      label: "今日可申请金额(元)",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm._f("numberToCurrency")(
                                    scope.row.maxApplyAmt
                                  )
                                ) +
                                "\n            "
                            ),
                            _c(
                              "el-tag",
                              {
                                staticClass: "grid-title-tag",
                                attrs: { size: "mini" },
                              },
                              [_vm._v(_vm._s(scope.row.applyDate || "-"))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "item",
                      label: "待还金额(元)",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.status === "02"
                              ? _c(
                                  "div",
                                  { staticClass: "grid-title red" },
                                  [
                                    _c("div", [
                                      _c("div", { staticClass: "g-t-key" }, [
                                        _vm._v("待还金额(元)"),
                                      ]),
                                      _c("div", { staticClass: "g-t-value" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("numberToCurrency")(
                                              scope.row.unpayAmount
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "el-tag",
                                      {
                                        staticClass: "grid-title-tag",
                                        attrs: { size: "mini", type: "danger" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleDetails(
                                              scope.row,
                                              _vm.index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                已逾期\n              "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _c("div", [_vm._v("0")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "enterName",
                      label: "所属企业名称",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "liveStreamPlatName",
                      label: "所属平台",
                      align: "center",
                    },
                  }),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        prop: "item",
                        label: "费率",
                        align: "center",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", { staticClass: "grid-item" }, [
                                scope.row.feeRate > 0
                                  ? _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("numberToCurrency")(
                                            scope.row.feeRate
                                          )
                                        ) + "%/单笔"
                                      ),
                                    ])
                                  : _c("div", [_vm._v("N/A")]),
                              ]),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "template",
                        { slot: "header" },
                        [
                          _vm._v("费率\n            "),
                          _c(
                            "el-tooltip",
                            { attrs: { placement: "top", effect: "dark" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "w300",
                                  attrs: { slot: "content" },
                                  slot: "content",
                                },
                                [
                                  _c("p", [_vm._v("小帮手:")]),
                                  _c("p", [
                                    _vm._v(
                                      "\n                  可支用金额与店铺待结算金额的比值，折扣率会根据您的经营情况变化，店铺经营情况越好折扣率越高。\n                "
                                    ),
                                  ]),
                                ]
                              ),
                              _c("i", {
                                staticClass:
                                  "iconfont icon-a-yiwenwenhaobangzhu f12 fc_888",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        prop: "item",
                        label: "用款周期",
                        align: "center",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", { staticClass: "grid-item" }, [
                                scope.row.maxDays > 0
                                  ? _c("div", [
                                      _vm._v(_vm._s(scope.row.maxDays) + "天"),
                                    ])
                                  : _c("div", [_vm._v("N/A")]),
                              ]),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "template",
                        { slot: "header" },
                        [
                          _vm._v("用款周期\n            "),
                          _c(
                            "el-tooltip",
                            { attrs: { placement: "top", effect: "dark" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "w300",
                                  attrs: { slot: "content" },
                                  slot: "content",
                                },
                                [
                                  _c("p", [_vm._v("小帮手:")]),
                                  _c("p", [
                                    _vm._v(
                                      "\n                  用款周期会随着您店铺经营情况变化，您的店铺回款周期越长，用款周期越长，超期未回款视作逾期，您可以主动还款\n                "
                                    ),
                                  ]),
                                ]
                              ),
                              _c("i", {
                                staticClass:
                                  "iconfont icon-a-yiwenwenhaobangzhu f12 fc_888",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        prop: "item",
                        label: "折扣率",
                        align: "center",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", { staticClass: "grid-item" }, [
                                scope.row.discountRate > 0
                                  ? _c("div", [
                                      _vm._v(
                                        _vm._s(scope.row.discountRate) + "%"
                                      ),
                                    ])
                                  : _c("div", [_vm._v("N/A")]),
                              ]),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "template",
                        { slot: "header" },
                        [
                          _vm._v("折扣率\n            "),
                          _c(
                            "el-tooltip",
                            { attrs: { placement: "top", effect: "dark" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "w300",
                                  attrs: { slot: "content" },
                                  slot: "content",
                                },
                                [
                                  _c("p", [_vm._v("小帮手:")]),
                                  _c("p", [
                                    _vm._v(
                                      "\n                  可支用金额与店铺待结算金额的比值，折扣率会根据您的经营情况变化，店铺经营情况越好折扣率越高。\n                "
                                    ),
                                  ]),
                                ]
                              ),
                              _c("i", {
                                staticClass:
                                  "iconfont icon-a-yiwenwenhaobangzhu f12 fc_888",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        prop: "item",
                        label: "订单回溯周期",
                        align: "center",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", { staticClass: "grid-item" }, [
                                _c("div", [
                                  _vm._v(_vm._s(scope.row.shopDays) + "天"),
                                ]),
                              ]),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "template",
                        { slot: "header" },
                        [
                          _vm._v("订单回溯周期\n            "),
                          _c(
                            "el-tooltip",
                            { attrs: { placement: "top", effect: "dark" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "w300",
                                  attrs: { slot: "content" },
                                  slot: "content",
                                },
                                [
                                  _c("p", [_vm._v("小帮手:")]),
                                  _c("p", [
                                    _vm._v(
                                      "\n                  平台会以该周期内的待结算订单为标准，每日计算您的可支用金额，店铺经营状态越好天数越长。\n                "
                                    ),
                                  ]),
                                ]
                              ),
                              _c("i", {
                                staticClass:
                                  "iconfont icon-a-yiwenwenhaobangzhu f12 fc_888",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c("el-table-column", {
                    attrs: {
                      prop: "item",
                      label: "待结算订单金额",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            ¥ " +
                                _vm._s(
                                  _vm._f("numberToCurrency")(scope.row.orderAmt)
                                ) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "item",
                      label: "待结算订单笔数",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm._f("numberToCurrency")(
                                    scope.row.orderNum,
                                    0
                                  )
                                ) +
                                "笔\n            "
                            ),
                            scope.row.shopId
                              ? _c(
                                  "el-link",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showOrderList(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("详情")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "item",
                      label: "申请",
                      align: "center",
                      fixed: "right",
                      width: "120px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.status == 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "grid-btn btn-blue mt10 chand",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDetails(
                                          scope.row,
                                          _vm.index
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              申请融资\n            "
                                    ),
                                  ]
                                )
                              : scope.row.status == 2
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "grid-btn btn-gray mt10 chand",
                                  },
                                  [_vm._v("签约中")]
                                )
                              : scope.row.status == 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "grid-btn btn-gray mt10 chand",
                                  },
                                  [_vm._v("已申请")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "支用信息确认",
            visible: _vm.dialogVisibleSure,
            "close-on-click-modal": false,
            "show-close": false,
            "custom-class": "pub_dialog",
            width: "35%",
            top: "50px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisibleSure = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dFormRef",
              attrs: {
                model: _vm.dForm,
                "label-position": "left",
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "本次融资金额:" } },
                [
                  _c("el-input", {
                    staticClass: "rongziInput",
                    staticStyle: { width: "90%" },
                    attrs: { placeholder: "请填写融资金额", size: "medium" },
                    model: {
                      value: _vm.dForm.money,
                      callback: function ($$v) {
                        _vm.$set(_vm.dForm, "money", $$v)
                      },
                      expression: "dForm.money",
                    },
                  }),
                  _vm._v("\n        元\n      "),
                ],
                1
              ),
              _c("el-form-item", { attrs: { label: "店铺名称:" } }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.shopLoan.shopName) + "\n      "
                ),
              ]),
              _c("el-form-item", { attrs: { label: "企业名称:" } }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.shopLoan.enterName) + "\n      "
                ),
              ]),
              _c("el-form-item", { attrs: { label: "所属平台:" } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.shopLoan.liveStreamPlatName) +
                    "\n      "
                ),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "本次费率:" } },
                [
                  _vm.shopLoan.feeRate > 0
                    ? [
                        _vm._v(
                          _vm._s(
                            _vm._f("numberToCurrency")(_vm.shopLoan.feeRate)
                          ) + "%/单笔"
                        ),
                      ]
                    : [_vm._v("N/A")],
                ],
                2
              ),
              _c(
                "el-form-item",
                { attrs: { label: "用款周期:" } },
                [
                  _vm.shopLoan.maxDays > 0
                    ? [_vm._v(_vm._s(_vm.shopLoan.maxDays) + "天")]
                    : [_vm._v("N/A")],
                ],
                2
              ),
              _c(
                "el-form-item",
                { attrs: { label: "折扣率:" } },
                [
                  _vm.shopLoan.discountRate > 0
                    ? [_vm._v(_vm._s(_vm.shopLoan.discountRate) + "%")]
                    : [_vm._v("N/A")],
                ],
                2
              ),
              _c(
                "el-form-item",
                { attrs: { label: "收款银行卡账户:" } },
                [
                  _c("DictSelectSingle", {
                    attrs: {
                      url: "/cap/account/querySkBank/" + _vm.shopLoan.enterId,
                      placeholder: "请选择收款银行卡账户",
                    },
                    on: { change: _vm.handleChangeCompany },
                    model: {
                      value: _vm.dForm.accountId,
                      callback: function ($$v) {
                        _vm.$set(_vm.dForm, "accountId", $$v)
                      },
                      expression: "dForm.accountId",
                    },
                  }),
                ],
                1
              ),
              _c("el-divider"),
              _c("el-form-item", { attrs: { label: "待结算订单金额:" } }, [
                _vm._v(
                  _vm._s(_vm._f("numberToCurrency")(_vm.shopLoan.orderAmt)) +
                    "元"
                ),
              ]),
              _c("el-form-item", { attrs: { label: "待结算订单笔数:" } }, [
                _vm._v(
                  _vm._s(_vm._f("numberToCurrency")(_vm.shopLoan.orderNum, 0)) +
                    "笔"
                ),
              ]),
              _c(
                "el-form-item",
                [
                  _c("el-checkbox", {
                    model: {
                      value: _vm.agreeChecked,
                      callback: function ($$v) {
                        _vm.agreeChecked = $$v
                      },
                      expression: "agreeChecked",
                    },
                  }),
                  _vm._v(
                    "\n        本人已仔细阅读并完全理解、接受如下文件，接受该等文件的约束\n        "
                  ),
                  _c(
                    "a",
                    { staticClass: "link-able", on: { click: _vm.jumpToUrl } },
                    [_vm._v("《应收账款转让通知书》")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.btnLoading,
                      expression: "!btnLoading",
                    },
                  ],
                  on: { click: _vm.handleSureClose },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.btnLoading,
                      expression: "btnLoading",
                    },
                  ],
                  attrs: { type: "primary", disabled: !_vm.agreeChecked },
                  on: {
                    click: function ($event) {
                      return _vm.applyOrder()
                    },
                  },
                },
                [_vm._v("\n        " + _vm._s(_vm.btnMsg) + "\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisibleSure2,
            title: "订单明细",
            width: "95%",
            "before-close": _vm.handleSureClose,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisibleSure2 = $event
            },
          },
        },
        [
          _vm.dialogVisibleSure2
            ? _c("shop-goods", { attrs: { "modal-data": _vm.shopLoan } })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }