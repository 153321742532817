<template>
  <div class="user">
    <basic-container>
      <avue-crud
        ref="crud"
        v-model="form"
        :option="option"
        :page.sync="page"
        :table-loading="listLoading"
        :before-open="handleOpenBefore"
        :data="list"
        :cell-class-name="handleCellClassName"
        :search.sync="search"
        @on-load="getList"
        @size-change="sizeChange"
        @current-change="currentChange"
        @search-change="handleFilter"
        @refresh-change="handleRefreshChange"
        @row-update="update"
        @row-save="create"
        @selection-change="selectionChange"
      >
        <template slot="menuLeft">
          <el-button
            class="filter-item"
            type="primary"
            icon="el-icon-add"
            @click="$refs.crud.rowAdd()"
          >手工录入
          </el-button>
          <el-button type="primary" icon="el-icon-upload" @click="handleExport">导出</el-button>
          <el-button
            class="filter-item"
            type="success"
            icon="el-icon-message"
            @click="handleTemplate"
          >模板下载
          </el-button>
          <dict-upload-process type-code="F0403" biz-id="123456" btn-name="上传流水" req-url="/cap/accountFlow/upload" @change="onFileChange($event, 'fileIds')" />
          <el-button
            type="success"
            icon="el-icon-postcard"
            class="light-blue"
            @click="approve('AL001')"
          >项目投放</el-button>
          <el-button
            type="success"
            icon="el-icon-postcard"
            class="light-blue"
            @click="approve('AL002')"
          >项目回款</el-button>
          <el-button
            type="success"
            icon="el-icon-bank-card"
            class="light-yellow"
            @click="approve('AL003')"
          >银行借款</el-button>
          <el-button
            type="success"
            icon="el-icon-bank-card"
            class="light-yellow"
            @click="approve('AL004')"
          >银行借款还本付息</el-button>
          <el-button
            type="danger"
            icon="el-icon-edit"
            @click="approve('AL005')"
          >废止</el-button>
          <el-button
            type="danger"
            icon="el-icon-edit"
            @click="synTrade()"
          >同步流水</el-button>
          <el-button
            type="success"
            icon="el-icon-postcard"
            class="light-blue"
            @click="mergeTrade()"
          >合并流水</el-button>
        </template>
        <template slot="flowTimeSearch" slot-scope="scope">
          <el-date-picker
            v-model="scope.search.flowTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          />
        </template>
        <template
          slot="menu"
          slot-scope="scope"
        >
          <el-button
            v-if="scope.row.status==1"
            type="primary"
            icon="el-icon-edit"
            @click="resetTrade(scope.row,scope.index)"
          >重置</el-button>
          <el-button
            v-if="scope.row.status==0"
            type="primary"
            icon="el-icon-edit"
            @click="handleUpdate(scope.row,scope.index)"
          />
          <el-button
            v-if="scope.row.status==0"
            type="danger"
            icon="el-icon-delete"
            @click="deletes(scope.row,scope.index)"
          />
        </template>
        <template slot="inOut" slot-scope="scope">
          <el-tag effect="dark" :type="scope.row.inOut === '1' ? 'success' : 'danger'">{{ scope.row.inOut === '1' ? '收入' : '支出' }}</el-tag>
        </template>
        <template slot="status" slot-scope="scope">
          <el-tag :effect="scope.row.status === '1' ? 'dark' : 'plain'" type="warning">{{ scope.row.status === '1' ? '确认' : '未确认' }}</el-tag>
        </template>
      </avue-crud>
    </basic-container>
  </div>
</template>
<script>
import { doGet, doPost, doDelet, doPut, doExportExcel, downExcel, doExportPostExcel } from '@/router/axios';
import { mapGetters } from 'vuex';
import DictUploadProcess from '@/components/form-upload/DictUploadProcess';
export default {
  name: 'TableUser',
  components: {
    DictUploadProcess
  },
  data() {
    return {
      option: tableOption,
      defaultProps: {
        label: 'name',
        value: 'id'
      },
      search: {},
      dialogVisible: false,
      page: {
        total: 0, // 总页数
        current: 1, // 当前页数
        currentPage: 1,
        pageSize: 10,
        size: 10, // 每页显示多少条,
        isAsc: false// 是否倒序
      },
      list: [],
      selectedList: [],
      listLoading: true,
      form: {
        status: '0'
      }
    };
  },
  computed: {

  },
  watch: {

  },
  created() {

  },
  methods: {
    selectionChange(list) {
      this.selectedList = list;
    },
    getNodeData(node) {
    },
    synTrade() {
      const params = {};
      if (this.search.flowTime !== '') {
        const stt = this.search.flowTime;
        params.flowTime1 = stt[0];
        params.flowTime2 = stt[1];
        params.flowTime = null;
        this.listLoading = true;
        doGet('/cap/accountFlow/synTrade', params).then(res => {
          this.listLoading = false;
        });
      } else {
        this.$message.error('请选择交易时间');
      }
    },
    mergeTrade() {
      if (this.selectedList.length <= 1) {
        this.$notify.error('至少选择两条流水');
        return;
      }
      if (this.selectedList.length > 3) {
        this.$notify.error('最多选择3条流水');
        return;
      }
      const params = this.selectedList;
      this.listLoading = true;
      doPost('/cap/accountFlow/mergeTrade', params).then(res => {
        this.listLoading = false;
        if (res.data.code === 200) {
          this.getList(this.page);
          this.$notify.success('合并成功');
          return false;
        } else {
          const retMs = res.data.msg;
          this.$notify.error(retMs);
          return;
        }
      }).catch(() => {
      });
    },
    handleDelete() {
      if (this.selectedList.length == 0) {
        this.$notify.error('请选择删除数据');
        return;
      }
      this.$confirm('此操作将永久删除选择数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消', type: 'warning'
      }
      ).then(() => {
        this.listLoading = true;
        const ids = [];
        for (let i = 0; i < this.selectedList.length; i++) {
          ids.push(this.selectedList[i].id);
        }
        doDelet('/cap/accountFlow/delete/' + ids.join(',')).then(res => {
          if (res.data.code === 200) {
            this.getList(this.page);
            this.$notify.success('删除成功');
          } else {
            const retMs = res.data.msg;
            this.listLoading = false;
            this.$notify.error(retMs);
            return;
          }
        }).catch(() => {
          this.$notify.error('服务异常');
        });
      });
    },
    resetTrade(row) {
      this.listLoading = true;
      doGet('/cap/accountFlow/resetTrade/' + row.id).then(res => {
        this.getList();
        this.listLoading = false;
      });
    },
    onFileChange(ids = [], key) {
      this.getList(this.page);
    },
    getList(page, params) {
      if (params == undefined || params == null || params == '') {
        params = {};
      }
      this.listLoading = true;
      params = { ...this.search };
      params.current = page.currentPage - 1;
      params.size = page.pageSize;
      if (params.flowTime != null) {
        const stt = params.flowTime;
        params.flowTime1 = stt[0];
        params.flowTime2 = stt[1];
        params.flowTime = null;
      }
      doGet('/cap/accountFlow/queryPage', params).then(res => {
        this.list = res.data.data.dataList;
        this.page.total = res.data.data.totalElements;
        this.listLoading = false;
      });
    },
    sizeChange(size) {
      this.page.size = size;
      this.page.pageSize = size;
    },
    currentChange(current) {
      this.page.current = current;
      this.page.currentPage = current;
    },
    handleFilter(param, done) {
      this.page.current = 1;
      this.page.currentPage = 1;
      this.getList(this.page, param);
      done();
    },
    copyAdd() {
      if (this.selectedList.length > 1 || this.selectedList.length == 0) {
        this.$notify.error('请选择复制数据');
        return;
      }
      this.form = this.selectedList[0];
      this.form.id = null;
      this.$refs.crud.rowAdd();
    },
    handleRefreshChange() {
      this.getList(this.page);
    },
    handleOpenBefore(show, type) {
      window.boxType = type;
      // 若是编辑、查看回显名称
      if (['edit', 'views'].includes(type)) {

      } else if (type === 'add') {

      }
      show();
    },
    handleUpdate(row, index) {
      this.$refs.crud.rowEdit(row, index);
    },
    approve(ioCode) {
      if (this.selectedList.length == 0) {
        this.$notify.error('请选择数据');
        return;
      }
      const params = {};
      params.ioCode = ioCode;
      params.data = this.selectedList;
      doPost('/cap/accountFlow/approve', params).then(res => {
        if (res.data.code === 200) {
          this.getList(this.page);
          done();
          this.$notify.success('创建成功');
          return false;
        } else {
          const retMs = res.data.msg;
          this.listLoading = false;
          this.$notify.error(retMs);
          return;
        }
        loading();
      }).catch(() => {
        loading();
      });
    },
    handleExport() {
      this.listLoading = true;
      this.listLoading = true;
      let params = { ...this.search };
      params.current = page.currentPage - 1;
      params.size = page.pageSize;
      if (params.flowTime != null) {
        const stt = params.flowTime;
        params.flowTime1 = stt[0];
        params.flowTime2 = stt[1];
        params.flowTime = null;
      }
      doExportPostExcel('/cap/accountFlow/export', params).then(res => {
      // 获取headers中的filename文件名
        downExcel(res);
        this.listLoading = false;
      });
    },
    handleTemplate() {
      this.listLoading = true;
      doExportExcel('/cap/accountFlow/exportTemplate', this.form).then(res => {
        downExcel(res);
        this.listLoading = false;
      });
    },
    create(row, done, loading) {
      this.listLoading = true;
      const params = {
        ...this.form
      };
      doPost('/cap/accountFlow/save', params).then(res => {
        if (res.data.code === 200) {
          this.getList(this.page);
          done();
          this.$notify.success('创建成功');
          return false;
        } else {
          const retMs = res.data.msg;
          this.listLoading = false;
          this.$notify.error(retMs);
          return;
        }
        loading();
      }).catch(() => {
        loading();
      });
    },
    update(row, index, done, loading) {
      const params = {
        ...this.form
      };

      doPut('/cap/accountFlow/update', params).then(res => {
        if (res.data.code === 200) {
          this.getList(this.page);
          done();
          this.$notify.success('更新成功');
          return;
        } else {
          const retMs = res.data.msg;
          this.listLoading = false;
          this.$notify.error(retMs);
          return;
        }
        loading();
      }).catch(() => {
        loading();
      });
    },
    deletes(row) {
      this.$confirm('此操作将永久删除选择数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消', type: 'warning'
      }
      ).then(() => {
        this.listLoading = true;
        doDelet('/cap/accountFlow/delete/' + row.id).then(res => {
          if (res.data.code === 200) {
            this.getList(this.page);
            this.$notify.success('删除成功');
          } else {
            const retMs = res.data.msg;
            this.listLoading = false;
            this.$notify.error(retMs);
            return;
          }
        }).catch(() => {
          this.$notify.error('服务异常');
        });
      });
    },
    handleCellClassName({ row, column, rowIndex, columnIndex }) {
      if ([7].includes(columnIndex)) {
        return 'yellow-cell';
      }
      return '';
    }
  }
};

export const tableOption = {
  border: false,
  selection: true,
  showSummary: true,
  sumColumnList: [{
    name: 'transAmount',
    type: 'sum'
  }],
  index: false,
  indexLabel: '序号',
  stripe: true,
  menualign: 'left',
  menuWidth: 160,
  searchSpan: 8,
  searchMenuSpan: 6,
  searchLabelWidth: 100,
  searchIndex: 3,
  searchIcon: true,
  searchShowBtn: false,
  labelWidth: 130,
  editBtn: false,
  delBtn: false,
  align: 'left',
  addBtn: false,
 searchShowBtn:false,
 column: [{
    fixed: true,
    label: 'id',
    prop: 'id',
    span: 24,
    hide: true,
    editDisplay: false,
    addDisplay: false
  },

  {
    label: '账户',
    prop: 'accountId',
    editDisplay: true,
    addDisplay: true,
    type: 'select',
    filterable: true,
    dicUrl: '/dict/type/D000161',
    rules: [{
      required: true,
      message: '请输入账户'
    }
    ],
    hide: true,
    span: 24
  },
  {
    label: '交易时间',
    prop: 'flowTime',
    type: 'datetime',
    format: 'yyyy-MM-dd HH:mm:ss',
    valueFormat: 'yyyy-MM-dd HH:mm:ss',
    editDisplay: true,
    addDisplay: true,
    search: true,
    hide: true,
    rules: [{
      required: true,
      message: '请输入交易时间'
    }],
    span: 12,
    searchSpan: 8
  },

  {
    label: '收支',
    prop: 'inOut',
    type: 'select',
    dicUrl: '/dict/type/D000130',
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: true,
      message: '请输入收支'
    }],
    search: true,
    hide: true,
    span: 12,
    searchSpan: 4,
    slot: true
  },
  {
    label: '公司',
    prop: 'companyId',
    editDisplay: false,
    addDisplay: false,
    type: 'select',
    filterable: true,
    search: true,
    dicUrl: '/dict/type/D000109',
    span: 24
  },
  {
    label: '状态',
    prop: 'status',
    type: 'select',
    dicUrl: '/dict/type/D000128',
    editDisplay: false,
    addDisplay: false,
    rules: [{
      required: false,
      message: '请输入状态'
    }
    ],
    search: true,
    span: 12,
    searchSpan: 4
  },
  {
    label: '流水类型',
    prop: 'flowType',
    type: 'select',
    dicUrl: '/dict/type/D000166',
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: true,
      message: '请输入收支'
    }],
    search: true,
    span: 12,
    searchSpan: 4,
    slot: true
  },
  {
    label: '科目',
    prop: 'ioCode',
    type: 'select',
    dicUrl: '/dict/type/D000140',
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: true,
      message: '请输入收支'
    }
    ],
    search: true,
    span: 24,
    slot: true
  },
  {
    label: '摘要',
    prop: 'remark',
    type: 'textarea',
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: false,
      message: '请输入备注'
    }
    ],
    search: true,
    span: 24
  },
  {
    label: '对方账号',
    prop: 'rivalAccountNo',
    editDisplay: false,
    addDisplay: false,
    rules: [{
      required: false,
      message: '请输入本方户名'
    }
    ],
    span: 12
  }, {
    label: '对方户名',
    prop: 'rivalAccountName',
    editDisplay: false,
    addDisplay: false,
    rules: [{
      required: false,
      message: '请输入本方户名'
    }
    ],
    search: true,
    span: 12
  },
  {
    label: '交易金额',
    prop: 'transAmount',
    editDisplay: true,
    addDisplay: true,
    type: 'number',
    rules: [{
      required: true,
      message: '请输入交易金额'
    }],
    span: 12,
    html: true,
    formatter: (row, value, label, column) => {
      if (row.inOut == '2') {
        return '<span class="fc-green fb">- ' + label.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') + '</span>';
      } else {
        return '<span class="fc-red fb">+ ' + label.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') + '</span>';
      }
    }
  },
  {
    label: '账户余额',
    prop: 'balanceAmt',
    type: 'number',
    editDisplay: true,
    addDisplay: true,
    hide: true,
    rules: [{
      required: false,
      message: '请输入余额'
    }
    ],
    span: 12
  },
  {
    label: '交易流水号',
    prop: 'tradeNo',
    editDisplay: true,
    addDisplay: true,
    search: true,
    hide: true,
    rules: [{
    }
    ],
    span: 12
  },
  {
    label: '交易时间',
    prop: 'flowTimeShow',
    type: 'datetime',
    format: 'yyyy-MM-dd HH:mm:ss',
    valueFormat: 'yyyy-MM-dd HH:mm:ss',
    editDisplay: false,
    addDisplay: false,
    rules: [{
      required: true,
      message: '请输入交易时间'
    }],
    span: 12,
    searchSpan: 24
  },
  {
    label: '本方账号',
    prop: 'ownAccountNo',
    editDisplay: false,
    addDisplay: false,
    rules: [{
      required: true,
      message: '请输入本方账号'
    }
    ],
    span: 12
  },
  {
    label: '本方户名',
    prop: 'ownAccountName',
    editDisplay: false,
    addDisplay: false,
    rules: [{
      required: true,
      message: '请输入本方户名'
    }
    ],
    span: 12
  },
  {
    label: '币种',
    prop: 'currencyType',
    type: 'select',
    dicUrl: '/dict/type/D000124',
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: true,
      message: '请输入币种'
    }
    ],
    search: true,
    span: 12
  },
  {
    label: '是否NC生成',
    prop: 'isNc',
    type: 'select',
    dicUrl: '/dict/type/D000131',
    editDisplay: false,
    addDisplay: false,
    hide: true,
    rules: [{
      required: true,
      message: '请输入币种'
    }
    ],
    span: 12
  },

  {
    label: '付方余额',
    prop: 'outBalanceAmt',
    type: 'number',
    editDisplay: false,
    addDisplay: false,
    hide: true,
    rules: [{
      required: false,
      message: '请输入币种'
    }
    ],
    span: 12
  },
  {
    label: '收方余额',
    prop: 'inBalacnceAmt',
    type: 'number',
    editDisplay: false,
    addDisplay: false,
    hide: true,
    rules: [{
      required: false,
      message: '请输入币种'
    }
    ],
    span: 12
  },
  {
    label: '付款余额',
    prop: 'payAmt',
    type: 'number',
    editDisplay: false,
    addDisplay: false,
    hide: true,
    rules: [{
      required: false,
      message: '请输入币种'
    }
    ],
    span: 12
  },
  {
    label: '收款金额',
    prop: 'incomeAmt',
    type: 'number',
    editDisplay: false,
    addDisplay: false,
    hide: true,
    rules: [{
      required: false,
      message: '请输入币种'
    }
    ],
    span: 12
  },
  {
    label: '用途',
    prop: 'purpose',
    type: 'textarea',
    editDisplay: false,
    addDisplay: false,
    hide: true,
    rules: [{
      required: false,
      message: '请输入备注'
    }
    ],
    span: 24
  },
  {
    label: '原金额',
    prop: 'bakTotleAmount',
    editDisplay: false,
    addDisplay: false,
    hide: true,
    rules: [{
      required: false,
      message: '请输入原金额'
    }
    ],
    span: 12
  },
  {
    label: '新金额',
    prop: 'newTotleAmount',
    editDisplay: false,
    addDisplay: false,
    hide: true,
    rules: [{
      required: false,
      message: '请输入新金额'
    }
    ],
    span: 12
  },
  {
    label: '是否检查',
    prop: 'isCheck',
    editDisplay: false,
    addDisplay: false,
    rules: [{
      required: false,
      message: '请输入是否检查'
    }
    ],
    hide: true,
    span: 12
  },

  {
    label: '创建时间',
    prop: 'createTime',
    editDisplay: false,
    addDisplay: false,
    rules: [{
      required: false,
      message: '请输入创建时间'
    }
    ],
    hide: true,
    span: 12
  },
  {
    label: '更新时间',
    prop: 'updateTime',
    editDisplay: false,
    addDisplay: false,
    rules: [{
      required: false,
      message: '请输入更新时间'
    }
    ],
    hide: true,
    span: 12
  }

  ]
};

</script>

<style lang="scss" scoped>

.title {
  margin: 30px auto;
  font: 600 24px/100% "Microsoft YaHei UI";
  color: #333;
  text-align: center;
}
.searchFlex {
  display: flex;
  align-items: center;
  .filedName {
    width: 120px;
    text-align: right;
    padding-right: 10px;
  }
}
.date-month-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
.text-unit {
  padding: 0 10px;
  box-sizing: border-box;
  font: 14px/100% "Microsoft YaHei UI";
  color: #666;
  text-align: right;
}
}
::v-deep .el-table {
  font-size: 14px;
}
::v-deep .el-table .record-table-header {
  background-color: #2455A4;
  color: #fff;
}
::v-deep .el-table .warning-row {
  background: #92d051 !important;;
  color: #000;
  font-weight: 600;
}
::v-deep .el-table .success-row {
  background: #FFBD66 !important;;
  color: #000;
  font-weight: 600;
}
::v-deep .el-table .yellow-cell {
  background: #fffadf !important;;
  color: #000;
  font-weight: 600;
}
::v-deep .el-table .el-table__cell {
  /*border-bottom: 1px solid #999;*/
}
.tip-info {
  margin-top: 20px;
p {
  font-size: 12px/24px "Microsoft yahei" !important;
  color: #666 !important;
}
}
::v-deep .el-form-item__label {
  color: #999;
}
::v-deep .light-blue{
  background-color: #41B7AC;
  border-color: #41B7AC;
}
::v-deep .light-yellow{
  background-color: #FFA510;
  border-color: #FFA510;
}
/*::v-deep .el-date-editor .el-range-separator {*/
  /*padding: 0;*/
/*}*/
</style>
