var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "selection-box" },
    [
      _c("el-alert", {
        attrs: { title: "营业执照信息确认", closable: false, type: "info" },
      }),
      _c(
        "el-form",
        {
          ref: "cForm",
          staticClass: "cForm",
          attrs: {
            model: _vm.cForm,
            rules: _vm.vRules,
            "label-position": "left",
            "label-width": "150px",
            size: "small",
          },
        },
        [
          _c(
            "div",
            { staticClass: "mt10 inner-box" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "营业执照", prop: "fileBusinessId" } },
                [
                  _c("dict-upload-info", {
                    attrs: {
                      type: "image",
                      limit: 1,
                      "type-code": "F0202",
                      "biz-id": "123456",
                      url: "cust/enter/upload",
                      tips: "提示：请上传营业执照原件或加盖公章的复印件",
                      disabled: _vm.disabled,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.onFileChange($event, "fileBusinessId")
                      },
                      getOcrInfo: _vm.handleSetOcrInfo,
                    },
                    model: {
                      value: _vm.cForm.fileBusinessId,
                      callback: function ($$v) {
                        _vm.$set(_vm.cForm, "fileBusinessId", $$v)
                      },
                      expression: "cForm.fileBusinessId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "营业执照名称", prop: "name" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "350px" },
                    attrs: {
                      maxlength: "40",
                      placeholder: "请输入公司名称",
                      disabled: _vm.disabled,
                    },
                    model: {
                      value: _vm.cForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.cForm, "name", $$v)
                      },
                      expression: "cForm.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "营业执照地址", prop: "regitAddress" } },
                [
                  _c("el-cascader", {
                    staticClass: "fl",
                    attrs: {
                      disabled: _vm.disabled,
                      options: _vm.options,
                      placeholder: "请选择省市区",
                    },
                    model: {
                      value: _vm.cForm.provinces,
                      callback: function ($$v) {
                        _vm.$set(_vm.cForm, "provinces", $$v)
                      },
                      expression: "cForm.provinces",
                    },
                  }),
                  _c("el-input", {
                    staticClass: "ml10 fl",
                    staticStyle: { width: "60%" },
                    attrs: {
                      maxlength: "120",
                      disabled: _vm.disabled,
                      "aria-placeholder": "请输入详细地址",
                    },
                    model: {
                      value: _vm.cForm.regitAddress,
                      callback: function ($$v) {
                        _vm.$set(_vm.cForm, "regitAddress", $$v)
                      },
                      expression: "cForm.regitAddress",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "社会统一信用代码", prop: "creditCode" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "350px" },
                    attrs: {
                      maxlength: "20",
                      placeholder: "一般为18/15位数字或字母组合",
                      disabled: _vm.disabled,
                    },
                    model: {
                      value: _vm.cForm.creditCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.cForm, "creditCode", $$v)
                      },
                      expression: "cForm.creditCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "营业期限", prop: "expireDateAttr" } },
                [
                  [
                    !_vm.cForm.expireDateAttrFlag
                      ? _c("el-date-picker", {
                          staticClass: "mr20",
                          attrs: {
                            disabled: _vm.disabled,
                            "value-format": "yyyy-MM-dd",
                            type: "daterange",
                            "range-separator": "至",
                            "start-placeholder": "起始日期",
                            "end-placeholder": "结束日期",
                          },
                          model: {
                            value: _vm.cForm.expireDateAttr,
                            callback: function ($$v) {
                              _vm.$set(_vm.cForm, "expireDateAttr", $$v)
                            },
                            expression: "cForm.expireDateAttr",
                          },
                        })
                      : _vm._e(),
                  ],
                ],
                2
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }