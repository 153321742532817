<template>
  <div class="user">
    <basic-container>
      <el-form ref="form" :model="form" :rules="rules" label-width="150px">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="转出钱包：" prop="upyztBookNo">
              <dict-select v-model="form.upyztBookNo" no="D000040" :is-get-label="true" placeholder="请选择结算账户" style="width: 100%" @change="accountId_change" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="可转出金额(元)" prop="nowMoney">
              <span class="fc-red fb">￥ {{ form.nowMoney }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="转入钱包：" prop="bankSubName">
              <dict-select ref="bankSubNameRef" v-model="form.bankSubName" no="D000040" :is-get-label="true" placeholder="请选择转入钱包" style="width: 100%" @change="bankCustId_change" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="分账金额" prop="totalAmt">
              <el-input v-model="form.totalAmt" style="width: 50%" type="number" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="分账原因">
              <el-input v-model="form.reason" type="textarea" style="width: 50%" />
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="">
              <dict-upload
                v-model="form.fileList"
                type-code="F0601"
                biz-id="1"
                :limit="100"
                :show-file-list="true"
                :is-disabled="false"
                type="text"
                btn-name="上传附件"
                req-url="/common/upload"
                @change="onFileChange($event, 'fileList')"
              />
            </el-form-item>
          </el-col>

        </el-row>

        <el-form-item>
          <el-button type="primary" :loading="btnLoading" @click="handlerWithdraw('form')">分账</el-button>
          <el-button @click="handleCancle('form')">取消</el-button>
        </el-form-item>
      </el-form>
    </basic-container>
  </div>
</template>

<script>
import BtnCountdown from '@/components/form-design/btn-countdown';
import DictSelect from '@/components/form-upload/DictSelect';
import { doPost } from '@/router/axios';
import DictUpload from '@/components/form-upload/DictUpload';
export default {
  name: 'WithdrawPage',
  components: { DictSelect, BtnCountdown, DictUpload },
  data() {
    return {
      btnLoading: false,
      form: {
        nowMoney: '0',
        totalAmt: 0,
        bankSubName: '',
        upyztBookNo: '',
        accountName: ''

      },
      rules: {
        money: [
          { required: true, message: '请输入提现金额', trigger: 'blur' }
        ],
        upyztBookNo: [
          { required: true, message: '请选择结算账户', trigger: 'change' }
        ],
        bankSubName: [
          { required: true, message: '请提现银行卡', trigger: 'change' }
        ],
        accountName: [
          { required: true, message: '请填写收款人', trigger: 'change' }
        ],
        totalAmt: [
          { required: true, message: '请填写收款金额', trigger: 'change' }
        ]
      }
    };
  },
  methods: {
    handlerWithdraw(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          doPost('/order/transferInfo/apply', this.form).then(res => {
            this.btnLoading = false;
            if (res.data.code == 200) {
              this.$router.$avueRouter.closeTag();
              this.$router.push({ name: '操作结果', params: { status: true, content: '分账申请成功' }});
            } else {
              this.$notify.error(res.data.msg);
            }
          }).catch((err) => {
            this.btnLoading = false;
          }).finally(() => {
            this.btnLoading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    onFileChange(res, key) {

    },
    handleCancle() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    accountId_change(val, label) {
      let temp = val.split('-');
      this.$set(this.form, 'upyztBookNo', label);
      this.form.outAccountId = temp[0];
      this.form.nowMoney = temp[1];
      this.$refs.bankSubNameRef.handleSetDisabled(val);
    },
    bankCustId_change(val, name) {
      let temp = val.split('-');
      this.$set(this.form, 'bankSubName', name);
      this.form.inAccountId = temp[0];
    }

  }
};
</script>

<style scoped>

</style>
