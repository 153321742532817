<template>
  <div class="user">
    <basic-container>
      <el-form ref="form" :model="form" :rules="rules" label-width="150px">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="结算账户：" prop="upyztBookNo">
              <dict-select
                v-model="form.upyztBookNo"
                no="D000040"
                :is-get-label="true"
                placeholder="请选择结算账户"
                style="width: 100%"
                @change="accountId_change"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="可提现金额(元)" prop="nowMoney">
              <span class="fc-red fb">￥ {{ form.nowMoney }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="提现银行卡：" prop="bankSubName">
              <dict-select ref="bankSubNameRef" v-model="form.bankSubName" :no="'/cap/account/queryAcctBank/' + accId" :is-get-label="true" placeholder="请选择提现银行卡" style="width: 100%" @change="bankCustId_change" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="收款人" prop="accountName">
              <el-input v-model="form.accountName" style="width: 50%" :readonly="true" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="收款银行卡:">
              <h3 style="color:red">{{ cardNo }}</h3>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="提现金额" prop="totalAmt">
              <el-input v-model="form.totalAmt" style="width: 50%" type="number" />
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="银行附言">
              <el-input v-model="form.bankMemo" style="width: 50%" />
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="申请原因">
              <el-input v-model="form.reason" type="textarea" style="width: 50%" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item>
          <el-button type="primary" :loading="btnLoading" @click="handlerWithdraw('form')">提现</el-button>
          <el-button @click="handleCancle('form')">取消</el-button>
        </el-form-item>
      </el-form>
    </basic-container>
  </div>
</template>

<script>
import BtnCountdown from '@/components/form-design/btn-countdown';
import DictSelect from '@/components/form-upload/DictSelect';
import { doPost } from '@/router/axios';
export default {
  name: 'WithdrawPage',
  components: { DictSelect, BtnCountdown },
  data() {
    return {
      accId: '1',
      cardNo: '',
      btnLoading: false,
      form: {
        nowMoney: '0',
        totalAmt: 0,
        bankSubName: '',
        upyztBookNo: '',
        accountName: ''
      },
      rules: {
        money: [
          { required: true, message: '请输入提现金额', trigger: 'blur' }
        ],
        upyztBookNo: [
          { required: true, message: '请选择结算账户', trigger: 'change' }
        ],
        bankSubName: [
          { required: true, message: '请提现银行卡', trigger: 'change' }
        ],
        accountName: [
          { required: true, message: '请填写收款人', trigger: 'change' }
        ],
        totalAmt: [
          { required: true, message: '请填写收款金额', trigger: 'change' }
        ]
      }
    };
  },
  methods: {
    handlerWithdraw(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          doPost('/order/widthdrawalInfo/apply', this.form).then(res => {
            this.btnLoading = false;
            if (res.data.code == 200) {
              this.$router.$avueRouter.closeTag();
              // status-- true： 成功， false：失败
              this.$router.push({ name: '操作结果', params: { status: false, content: '提现申请成功' }});
            } else {
              this.$notify.error(res.data.msg);
            }
          }).catch((err) => {
            this.btnLoading = false;
          }).finally(() => {
            this.btnLoading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleCancle() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    accountId_change(val, label) {
      let temp = val.split('-');
      this.$set(this.form, 'upyztBookNo', label);
      this.form.accountId = temp[0];
      this.accId = temp[0];
      this.form.nowMoney = temp[1];
      this.form.bankSubName = '';
      // this.$refs.bankSubNameRef.getTree();
    },
    bankCustId_change(val, name) {
      let temp = name.split('-');
      let temVal = val.split('-');
      this.$set(this.form, 'bankSubName', name);
      this.form.bankCustId = temVal[0];
      this.cardNo = temVal[1];
      this.form.accountName = temp[1];
    }

  }
};
</script>

<style scoped>

</style>
