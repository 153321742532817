<template>
  <div class="mz-layout-section">
    <div class="tableList_title">融资订单列表</div>
    <el-divider />

    <avue-crud
      ref="crud"
      :option="option"
      :page.sync="page"
      :table-loading="listLoading"
      :data="list"
      @on-load="getList"
      @size-change="sizeChange"
      @current-change="currentChange"
      @search-change="handleFilter"
      @search-reset="resetFilter">
      <template slot="menuLeft">
        <el-button class="filter-item" type="primary" icon="el-icon-tickets" @click="toFinancApplica()">
          融资申请
        </el-button>
      </template>
      <template slot="menu" slot-scope="scope">
        <el-button type="text" icon="el-icon-view" @click="handleDetails(scope.row, scope.index)">详情</el-button>
      </template>
    </avue-crud>

    <!--详情-->
    <el-dialog title="融资单" :visible.sync="dialogVisible" width="95%" custom-class="pub_dialog">
      <page-details v-if="fileId" :modal-data="fileId" />
    </el-dialog>
  </div>
</template>

<script>
import { doGet } from '@/router/axios'
import pageDetails from './components/details'
const tableOption = {
  searchMenuSpan: 12,
  searchLabelWidth: 80,
  searchMenuPosition: 'left',
  border: false,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menualign: 'left',
  menuWidth: 100,
  refreshBtn: false,
  columnBtn: false,
  editBtn: false,
  delBtn: false,
  align: 'left',
  addBtn: false,
 searchShowBtn:false,
 column: [
    {
      label: '订单号',
      prop: 'orderNo',
      width: 180,
      fixed: true,
      editDisplay: true,
      addDisplay: true,
      search: true,
      searchPlaceholder: '请输入订单号',
      maxlength: 20,
      searchSpan: 12,
      span: 12,
    },
    {
      label: '产品',
      prop: 'productId',
      editDisplay: true,
      addDisplay: true,
      type: 'select',
      dicUrl: '/common/type/D000066',
      span: 12,
    },
    {
      label: '申请金额',
      prop: 'applyAmt',
      editDisplay: true,
      addDisplay: true,
      span: 12,
    },
    {
      label: '订单金额',
      prop: 'orderAmt',
      editDisplay: true,
      addDisplay: true,
      span: 12,
    },
    {
      label: '收款户名',
      prop: 'reciveName',
      minWidth: 200,
      editDisplay: true,
      addDisplay: true,
      span: 12,
    },
    {
      label: '收款账号',
      prop: 'reciveAcc',
      width: 160,
      editDisplay: true,
      addDisplay: true,
      span: 12,
    },
    {
      label: '开户行',
      prop: 'reciveBankSub',
      editDisplay: true,
      width: 150,
      addDisplay: true,
      span: 12,
    },
    {
      label: '电子回单号',
      prop: 'receiptNo',
      editDisplay: true,
      width: 150,
      addDisplay: true,
      span: 12,
    },
    {
      label: '状态',
      prop: 'status',
      editDisplay: true,
      addDisplay: true,
      type: 'select',
      dicUrl: '/common/type/D000070',
      span: 12,
    },
    {
      label: '创建时间',
      prop: 'createTime',
      width: 160,
      editDisplay: false,
      addDisplay: false,
      span: 12,
    },
  ],
}

export default {
  name: 'LoanIndex',
  components: { pageDetails },
  data() {
    return {
      option: tableOption,
      searchItmes: {},
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条,
      },
      list: [],
      listLoading: true,
      dialogVisible: false,
      fileId: '',
    }
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    getList(page, params) {
      params = params ? params : { ...this.searchItmes }
      params.current = page.currentPage - 1
      params.size = page.pageSize
      this.listLoading = true
      doGet('/rent/order/page', params).then(({ data }) => {
        if (data) {
          const { dataList, totalElements } = data.data || {}
          this.list = dataList || []
          this.page.total = totalElements || 0
        }
        this.listLoading = false
      })
    },

    sizeChange(size) {
      this.page.pageSize = size
    },
    currentChange(current) {
      this.page.currentPage = current
    },
    handleFilter(param, done) {
      this.page.currentPage = 1
      this.searchItmes = param ? param : {}
      this.getList(this.page, param)
      done()
    },
    resetFilter() {
      this.page.currentPage = 1
      this.searchItmes = {}
      this.getList(this.page, this.searchItmes)
    },

    handleDetails(row, index) {
      console.log('row.id' + row.id)
      this.fileId = row.id
      this.dialogVisible = true
    },
    toFinancApplica() {
      this.$router.push({ path: '/loan/shoploan' })
    },
  },
}
</script>
<style lang="scss" scoped>
/deep/ .el-tabs__nav-scroll {
  padding-left: 10px;
}
/deep/ .el-divider {
  background-color: #ecedef;
}
/deep/ .el-dialog__body {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
</style>
