<template>
  <div>
    <!-- {{isquanxian}} -->
    <div v-if="isquanxian">
     
    <div v-loading="isLoading" class="data-board">
    <div style="display: flex; justify-content: space-between">
      <div>
        <el-form :label-position="'right'" :model="queryFrom" label-width="100px" size="small">
          <div class="mt10 inner-box">
            <el-form-item label="平台产品:" prop="liveStreamPlat">
              <el-select
                v-model="queryFrom.productType"
                placeholder="请选择"
                style="width: 350px"
                @change="selectChange">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </div>
        </el-form>
      </div>
      
      <div v-if="hostname=='pre.fin-ops.biumdigits.com' || hostname == 'fin-ops.biumdigits.com'">
        <el-button type="primary" size="small" style="text-align: right" @click="goToScreen">数据看板</el-button>
      </div>
    </div>
    <div class="container">
      <div class="mb20">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="itemBox">
              <img
                src="https://bium-prod.oss-cn-hangzhou.aliyuncs.com/front_file/backEnd/icon1.png"
                class="itemBox_icon" />
              <div class="itemBox_info">
                <p class="itemBox_info_title">平台企业数量</p>
                <p class="itemBox_info_tip">{{ detail.enterpriseCount || 0 }}</p>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="itemBox">
              <img
                src="https://bium-prod.oss-cn-hangzhou.aliyuncs.com/front_file/backEnd/icon2.png"
                class="itemBox_icon" />
              <div class="itemBox_info">
                <p class="itemBox_info_title">一般企业</p>
                <p class="itemBox_info_tip">{{ detail.generalEnterpriseCount || 0 }}</p>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="itemBox">
              <img
                src="https://bium-prod.oss-cn-hangzhou.aliyuncs.com/front_file/backEnd/icon3.png"
                class="itemBox_icon" />
              <div class="itemBox_info">
                <p class="itemBox_info_title">个体工商户</p>
                <p class="itemBox_info_tip">{{ detail.individualEnterpriseCount || 0 }}</p>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="mb20">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="box platform_shop">
              <div class="title">平台店铺数</div>
              <div v-for="item in platformStoreCount" :key="item.id" class="platform">
                <img :src="platformIcon[item.id]" class="platformIcon" />
                {{ platformText[item.id] }}
                <label>{{ item.value || 0 }}</label>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="box">
              <div class="title">店铺各平台占比</div>
              <div ref="shopPlatformRef" class="canvas-container"></div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="box platform_num">
              <div class="title">平台店铺数</div>
              <div style="margin-bottom: 32px; margin-top: 18px">
                <p class="subtitle">平台已服务店铺数</p>
                <p class="value">
                  <span>{{ detail.servicedStoresCount || 0 }}</span>
                  家
                </p>
              </div>
              <div style="margin-bottom: 32px">
                <p class="subtitle">当前融资店铺数</p>
                <p class="value">
                  <span>{{ detail.repaymentStoresCount || 0 }}</span>
                  家
                </p>
              </div>
              <div>
                <p class="subtitle">当前店铺平均在贷</p>
                <p class="value">
                  <span>{{ formatNumber(detail.avgAmount) }}</span>
                  万
                </p>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="mb20">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="box">
              <div class="title" style="height: 30px">
                <div>
                  平台每日贷款余额
                  <span style="font-size: 14px; margin-left: 6px">(万元)</span>
                </div>
                <div class="title-action">
                  <div
                    class="title-action-item"
                    :class="loanAmountType == 7 ? 'active' : ''"
                    @click="onChangeLoanAmount(7)">
                    7天
                  </div>
                  <div
                    class="title-action-item"
                    :class="loanAmountType == 30 ? 'active' : ''"
                    @click="onChangeLoanAmount(30)">
                    30天
                  </div>
                </div>
              </div>
              <div ref="loanAmountRef" class="canvas-container"></div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="box">
              <div class="title" style="height: 30px">
                <div>
                  平台每日放款金额
                  <span style="font-size: 14px; margin-left: 6px">(元)</span>
                </div>
                <div class="title-action">
                  <div
                    class="title-action-item"
                    :class="paymentAmountType == 7 ? 'active' : ''"
                    @click="onChangePaymentAmount(7)">
                    7天
                  </div>
                  <div
                    class="title-action-item"
                    :class="paymentAmountType == 30 ? 'active' : ''"
                    @click="onChangePaymentAmount(30)">
                    30天
                  </div>
                </div>
              </div>
              <div ref="paymentAmountRef" class="canvas-container"></div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="mb20">
        <el-row :gutter="20">
          <el-col :span="12">
            <div style="background: #ffffff">
              <div class="boxInfo">
                <p class="subtitle">平台累计信用总额</p>
                <p class="values">
                  {{ detail.creditAmount || 0 }}
                  <span style="font-size: 14px; margin-left: 6px">元</span>
                </p>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div style="background: #ffffff">
              <div class="boxInfoPrice">
                <p class="subtitle">平台当前在贷</p>
                <p class="values">
                  {{ formatNumber(detail.repaymentAmount) || 0 }}
                  <span style="font-size: 14px; margin-left: 6px">万元</span>
                </p>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="mb20">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="bg1" style="margin-bottom: 14px">
              <p class="subtitle">今日放款金额</p>
              <p class="values">
                {{ detail.todayAmount || 0 }}
                <span style="font-size: 14px; margin-left: 6px">元</span>
              </p>
            </div>
            <div class="bg2">
              <p class="subtitle">平台累计放款金额</p>
              <p class="values">
                {{ formatNumber(detail.totalAmount) }}
                <span style="font-size: 14px; margin-left: 6px">万元</span>
              </p>
            </div>
          </el-col>
          <el-col :span="16">
            <div class="box">
              <div class="title" style="height: 30px">
                平台收入
                <div class="title-action">
                  <div class="title-action-item" :class="type == 'all' ? 'active' : ''" @click="onChangeType('all')">
                    全部
                  </div>
                  <div class="title-action-item" :class="type == 0 ? 'active' : ''" @click="onChangeType(0)">今天</div>
                  <div class="title-action-item" :class="type == 7 ? 'active' : ''" @click="onChangeType(7)">7天</div>
                  <div class="title-action-item" :class="type == 30 ? 'active' : ''" @click="onChangeType(30)">
                    30天
                  </div>
                </div>
              </div>
              <div style="display: flex">
                <div ref="shopPlateRef" class="canvas-container" style="width: 50%"></div>
                <div style="width: 50%; padding-left: 20px; box-sizing: border-box">
                  <div style="padding: 35px 0; border-bottom: 1px solid #eeeeee">
                    <p class="full_title">息费总收入</p>
                    <p class="full_desc">
                      {{ statisticsFeeInfo.totalFee || '0' }}
                      <span style="font-size: 14px; margin-left: 6px">元</span>
                    </p>
                  </div>
                  <div class="flexBox">
                    <div class="flexBoxItem">
                      <p class="full_title">服务费收入留存</p>
                      <p class="full_desc">
                        {{ statisticsFeeInfo.serviceFee || '0' }}
                        <span style="font-size: 14px; margin-left: 6px">元</span>
                      </p>
                    </div>
                    <div class="flexBoxItem">
                      <p class="full_title">利息收入</p>
                      <p class="full_desc">
                        {{ statisticsFeeInfo.interestFee || '0' }}
                        <span style="font-size: 14px; margin-left: 6px">元</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="mb20 infos">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="full" style="padding-left: 18px">
              <p class="full_title">当前总息费IRR</p>
              <p class="full_desc">{{ databoard.interestFeeAnnualizedIrr || 0 }}%</p>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="full" style="padding-left: 18px">
              <p class="full_title">当前服务费IRR</p>
              <p class="full_desc">{{ databoard.serviceFeeAnnualizedIrr || 0 }}%</p>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="full" style="padding-left: 18px">
              <p class="full_title">当前利息IRR</p>
              <p class="full_desc">{{ databoard.interestAnnualizedIrr || 0 }}%</p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
  </div>
  <div v-else>
    <h1>抱歉！您没有任何权限</h1>
  </div>
  </div>

</template>

<script>
import echarts from 'echarts'
import dayjs from 'dayjs'
export default {
  name: 'DataBoard',
  props: {},
  data() {
    return {
      isquanxian:this.$store.state.user.isquanxian,
      sassname:this.$store.state.user.domain.platformName,
      hostname:window.location.hostname,
      isLoading: false,
      queryFrom: {
        productType: 0,
      },
      options: [
        {
          value: 0,
          label: '1A-极速收款',
        },
        {
          value: 1,
          label: '2A-极速付款',
        },
        {
          value: 2,
          label: '1A-玖源万江',
        },
        {
          value: 3,
          label: '2A-玖源万江',
        },
      ],
      databoard: {},
      platformText: {
        10: '抖音',
        11: '快手',
        12: '拼多多',
        13: '视频号',
        14: '美团',
        15: '抖音小时达',
      },
      platformStoreCount: [],
      platformIcon: {
        10: 'https://bium-prod.oss-cn-hangzhou.aliyuncs.com/front_file/miniApp/shop/douyin.png',
        11: 'https://bium-prod.oss-cn-hangzhou.aliyuncs.com/front_file/miniApp/shop/kuaishou.png',
        12: 'https://bium-prod.oss-cn-hangzhou.aliyuncs.com/front_file/miniApp/shop/pdd.png',
        13: 'https://bium-prod.oss-cn-hangzhou.aliyuncs.com/front_file/miniApp/shop/video.png',
        14: 'https://bium-prod.oss-cn-hangzhou.aliyuncs.com/front_file/miniApp/shop/meituan.png',
        15: 'https://bium-prod.oss-cn-hangzhou.aliyuncs.com/front_file/miniApp/shop/douyin.png',
      },
      type: 'all',
      statisticsFee: {},
      statisticsFeeInfo: {},
      today: dayjs().format('YYYY-MM-DD'),
      loanAmount: {},
      paymentAmount: {},
      loanAmountType: 7,
      paymentAmountType: 30,
      detail: {},
    }
  },
  watch: {
    
    // 监听store中的isquanxian变化
    '$store.state.user.isquanxian': function(newVal) {
      console.log(111);
      this.isquanxian = newVal;
    }
  },
  created() {},
  mounted() {
    this.getViewData()
    this.init()
  },
  methods: {
    goToScreen() {
      // this.$router.push({ path: '/screen' })
      this.$router.push({ path: '/dataScreen' })
    },
    getViewData() {
      this.isLoading = true
      let self = this
      let params = {
        productType: this.queryFrom.productType,
      }
      if (this.queryFrom.productType == 2) {
        params.productType = 0
      }
      if (this.queryFrom.productType == 3) {
        params.productType = 1
      }
      this.doPost('bium-portal/controller/fin-ops/databoard/databoard', params).then(({ data }) => {
        if (data.errCode == 1000) {
          self.databoard = data.result
          self.isLoading = false
        }
      })
    },
    selectChange() {
      this.getViewData()
      this.init()
    },
    init() {
      let self = this
      let params = {
        productType: this.queryFrom.productType,
      }
      if (this.queryFrom.productType == 2) {
        params.productType = 0
        params.jiuyuan = 6
      }
      if (this.queryFrom.productType == 3) {
        params.productType = 1
        params.jiuyuan = 4
      }
      self.doPost('/bium-portal/controller/fin-ops/statistics/info', params).then((res) => {
        console.log('res', res)
        let result = res.data.result
        self.detail = result
        const dom = self.$refs.shopPlatformRef
        let platformStoreCount = []
        let keys = Object.keys(result.platformStoreCount)
        keys.forEach((item) => {
          platformStoreCount.push({
            value: result.platformStoreCount[item],
            name: self.platformText[item],
            id: item,
          })
        })
        self.platformStoreCount = platformStoreCount
        console.log('platformStoreCount', platformStoreCount)
        self.onLoadPie(dom, platformStoreCount)
        self.statisticsFee = result.statisticsFeeDTOMap
        self.onChangeType('all')
        self.loanAmount = result.loanAmountMap
        self.paymentAmount = result.paymentAmountMap
        self.onChangePaymentAmount(7)
        self.onChangeLoanAmount(7)
      })
    },
    onChangePaymentAmount(key) {
      let self = this
      self.paymentAmountType = key
      let paymentAmountX = []
      let paymentAmountY = []
      let paymentAmountDom = self.$refs.paymentAmountRef
      let paymentAmountMapKeys = Object.keys(self.paymentAmount)
      let keys = []
      paymentAmountMapKeys.forEach((item) => {
        keys.push(new Date(item).getTime())
      })
      keys.sort()
      let val = []
      keys.forEach((item) => {
        if (dayjs(self.today).diff(dayjs(item).format('YYYY-MM-DD'), 'day') <= self.paymentAmountType) {
          val.push(dayjs(item).format('YYYY-MM-DD'))
        }
      })
      val.forEach((item) => {
        paymentAmountX.push(dayjs(self.paymentAmount[item].days).format('MM.DD'))
        paymentAmountY.push(self.paymentAmount[item].amount)
      })
      self.onLoadLine(paymentAmountDom, paymentAmountX, paymentAmountY)
    },
    onChangeLoanAmount(key) {
      let self = this
      self.loanAmountType = key
      let loanAmountDom = self.$refs.loanAmountRef
      let loanAmountMapKeys = Object.keys(self.loanAmount)
      let loanAmountX = []
      let loanAmountY = []
      let keys = []
      loanAmountMapKeys.forEach((item) => {
        keys.push(new Date(item).getTime())
      })
      keys.sort()
      let val = []
      keys.forEach((item) => {
        if (dayjs(self.today).diff(dayjs(item).format('YYYY-MM-DD'), 'day') <= self.loanAmountType) {
          val.push(dayjs(item).format('YYYY-MM-DD'))
        }
      })
      val.forEach((item) => {
        loanAmountX.push(dayjs(self.loanAmount[item].days).format('MM.DD'))
        loanAmountY.push(`${self.div(self.loanAmount[item].amount, 10000)}`)
      })
      self.onLoadLine(loanAmountDom, loanAmountX, loanAmountY)
    },
    onChangeType(key) {
      let self = this
      self.type = key
      const plate = self.$refs.shopPlateRef
      let data = []
      let statisticsFee = self.statisticsFee[self.type]
      self.statisticsFeeInfo = statisticsFee
      let serviceFee = [
        {
          value: statisticsFee.serviceFee,
          name: '服务费收入',
        },
      ]
      let interestFee = [
        {
          value: statisticsFee.interestFee,
          name: '利息收入',
        },
      ]
      data = data.concat(serviceFee).concat(interestFee)
      self.onLoadPie(plate, data)
    },
    // onChangePaymentAmount() {},
    onLoadPie(dom, data) {
      console.log(data);
      
      this.$nextTick(() => {
        let option = {
          tooltip: {
            trigger: 'item',
          },
          legend: {
            top: '0%',
            right: '0',
            orient: 'vertical',
          },
          label: {
            formatter: '{b}\n{d}%',
          },
          series: [
            {
              type: 'pie',
              color: ['#4B9EF8', '#9CDD78', '#5FCAFA', '#806FC8', '#3E70FF'],
              radius: ['40%', '70%'],
              avoidLabelOverlap: false,
              padAngle: 15,
              itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2,
              },
              data: data,
            },
          ],
        }
        echarts.init(dom).dispose()
        const Chart = echarts.init(dom)
        Chart.setOption(option)
        window.addEventListener('resize', () => {
          Chart.resize()
        })
      })
    },
    onLoadLine(dom, x, y) {
      let series = {}
      if (this.loanAmountType == 7 || this.paymentAmountType == 7) {
        series = {
          data: y,
          type: 'line',
          smooth: true,
          label: {
            show: true,
            position: 'top',
          },
          color: ['#3E70FF'],
        }
      } else {
        series = {
          data: y,
          type: 'line',
          smooth: true,
          color: ['#3E70FF'],
        }
      }
      this.$nextTick(() => {
        let option = {
          tooltip: {
            trigger: 'axis',
            formatter: function (params) {
              let json = params[0]
              return json.data
            },
            axisPointer: {
              animation: false,
            },
          },
          xAxis: {
            type: 'category',
            data: x,
          },
          yAxis: {
            type: 'value',
          },
          series: [{ ...series }],
        }
        echarts.init(dom).dispose()
        const Chart = echarts.init(dom)
        Chart.setOption(option)
        window.addEventListener('resize', () => {
          Chart.resize()
        })
      })
    },
    formatNumber(num) {
      if (num && num > 0) {
        num = Number(num)
        return (num / 10000).toFixed(2)
      } else {
        return 0
      }
    },
    // 浮点型减
    sub(a, b) {
      var c, d, e
      try {
        c = a.toString().split('.')[1].length
      } catch (f) {
        c = 0
      }
      try {
        d = b.toString().split('.')[1].length
      } catch (f) {
        d = 0
      }
      return (e = Math.pow(10, Math.max(c, d))), (this.mul(a, e) - this.mul(b, e)) / e
    },

    // 浮点型除法
    div(a, b) {
      var c,
        d,
        e = 0,
        f = 0
      try {
        e = a.toString().split('.')[1].length
      } catch (g) {}
      try {
        f = b.toString().split('.')[1].length
      } catch (g) {}
      return (
        (c = Number(a.toString().replace('.', ''))),
        (d = Number(b.toString().replace('.', ''))),
        this.mul(c / d, Math.pow(10, f - e))
      )
    },

    // 浮点型加法函数
    accAdd(arg1, arg2) {
      var r1, r2, m
      try {
        r1 = arg1.toString().split('.')[1].length
      } catch (e) {
        r1 = 0
      }
      try {
        r2 = arg2.toString().split('.')[1].length
      } catch (e) {
        r2 = 0
      }
      m = Math.pow(10, Math.max(r1, r2))
      return ((arg1 * m + arg2 * m) / m).toFixed(2)
    },

    // 浮点型乘法
    mul(a, b) {
      var c = 0,
        d = a.toString(),
        e = b.toString()
      try {
        c += d.split('.')[1].length
      } catch (f) {}
      try {
        c += e.split('.')[1].length
      } catch (f) {}
      return (Number(d.replace('.', '')) * Number(e.replace('.', ''))) / Math.pow(10, c)
    },
  },
}
</script>

<style lang="scss" scoped>
@import url('./digital.scss');
</style>
