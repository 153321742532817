var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mz-layout-section company-auth-list" },
    [
      _c("div", { staticClass: "tableList_title" }, [_vm._v("企业权限列表")]),
      _c("el-divider"),
      _c("avue-crud", {
        ref: "crud",
        attrs: {
          option: _vm.option,
          page: _vm.page,
          "table-loading": _vm.listLoading,
          data: _vm.list,
        },
        on: {
          "update:page": function ($event) {
            _vm.page = $event
          },
          "on-load": _vm.getList,
          "size-change": _vm.sizeChange,
          "current-change": _vm.currentChange,
          "search-change": _vm.handleFilter,
          "search-reset": _vm.resetFilter,
          "row-update": _vm.updateForm,
        },
        scopedSlots: _vm._u([
          {
            key: "menu",
            fn: function (scope) {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text", icon: "el-icon-edit" },
                    on: {
                      click: function ($event) {
                        return _vm.handleUpdate(scope.row, scope.index)
                      },
                    },
                  },
                  [_vm._v("操作员设置")]
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.form,
          callback: function ($$v) {
            _vm.form = $$v
          },
          expression: "form",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }