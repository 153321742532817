var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "repay-section mt15 pl15 pr15 mb15" },
      [
        _c("avue-crud", {
          attrs: { data: _vm.order.files, option: _vm.tableOption },
          scopedSlots: _vm._u([
            {
              key: "menu",
              fn: function ({ row }) {
                return [
                  _c("DownloadFile", { attrs: { "file-id": row.fileId } }, [
                    _c("i", {
                      staticClass: "el-icon-download",
                      attrs: { title: "下载" },
                    }),
                  ]),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }