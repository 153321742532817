var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "details-warp" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            "label-width": "120px",
            "label-position": "left",
          },
        },
        [
          _c(
            "div",
            { staticClass: "details-section" },
            [
              _c("h3", { staticClass: "details-section-h3" }, [
                _c("span", [_vm._v("授信机构信息")]),
              ]),
              _c(
                "el-row",
                { staticClass: "pl20 pr20", attrs: { gutter: 40 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "授信机构：" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.creditInfo.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.creditInfo, "name", $$v)
                              },
                              expression: "form.creditInfo.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("h3", { staticClass: "details-section-h3" }, [
                _c("span", [_vm._v("额度详情")]),
              ]),
              _c(
                "el-row",
                { staticClass: "pl20 pr20", attrs: { gutter: 40 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "授信编号：" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.creditNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "creditNo", $$v)
                              },
                              expression: "form.creditNo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "用户名称：" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.enterpriseName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "enterpriseName", $$v)
                              },
                              expression: "form.enterpriseName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "企业Id：" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.enterpriseCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "enterpriseCode", $$v)
                              },
                              expression: "form.enterpriseCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "额度类型：" } },
                        [
                          _c("dict-select", {
                            attrs: { no: "D000055", disabled: "" },
                            model: {
                              value: _vm.form.pro.creditType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.pro, "creditType", $$v)
                              },
                              expression: "form.pro.creditType",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "计息方式：" } },
                        [
                          _c("dict-select", {
                            attrs: { no: "D000047", disabled: "" },
                            model: {
                              value: _vm.form.pro.repayType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.pro, "repayType", $$v)
                              },
                              expression: "form.pro.repayType",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "信贷类型：" } },
                        [
                          _c("dict-select", {
                            attrs: { no: "D000054", disabled: "" },
                            model: {
                              value: _vm.form.pro.productType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.pro, "productType", $$v)
                              },
                              expression: "form.pro.productType",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "可用额度：" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.balanceAmt,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "balanceAmt", $$v)
                              },
                              expression: "form.balanceAmt",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "授信金额：" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.totalAmt,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "totalAmt", $$v)
                              },
                              expression: "form.totalAmt",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "额度期限：" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.expireDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "expireDate", $$v)
                              },
                              expression: "form.expireDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "授信利率：" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.form.yearRate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "yearRate", $$v)
                                },
                                expression: "form.yearRate",
                              },
                            },
                            [_c("template", { slot: "suffix" }, [_vm._v("%")])],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "额度说明：" } },
                        [
                          _c("el-input", {
                            attrs: { type: "textarea", row: "2", disabled: "" },
                            model: {
                              value: _vm.form.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "remark", $$v)
                              },
                              expression: "form.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "details-section mt15 ml10 mr20" },
                [
                  _c("h3", { staticClass: "details-section-h3" }, [
                    _c("span", [_vm._v("额度项下借款")]),
                  ]),
                  _c("credit-order", { attrs: { billForm: _vm.form } }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }