<template>
  <div class="repay-section mt20">
    <avue-crud :data="billForm.lines" :option="tableOption">
      <template slot="status" slot-scope="{ row }">
        <span v-if="row.status === '09' || row.status === '10' || row.status === '02' || row.status === '05'">
          <el-tag effect="dark" type="danger">{{ row.statusName }}</el-tag>
        </span>
        <span v-else-if="row.status === '06' || row.status === '08' || row.status === '00' || row.status === '01'">
          <el-tag effect="dark">{{ row.statusName }}</el-tag>
        </span>
        <span v-else>
          <el-tag effect="dark" type="success">{{ row.statusName }}</el-tag>
        </span>
      </template>
    </avue-crud>
  </div>
</template>

<script>
const tableOption = {
  page: false,
  indexLabel: '序号',
  stripe: true,
  align: 'left',
  addBtn: false,
  editBtn: false,
  delBtn: false,
  menuWidth: 80,
  refreshBtn: false,
  header: false,
  menu: false,
  menuBtn: false,
 searchShowBtn:false,
 column: [
    {
      label: '商品',
      width: 300,
      prop: 'goodsName',
    },
    {
      label: '状态',
      prop: 'status',
      width: 150,
    },
    {
      label: '起息日',
      type: 'date',
      width: 120,
      format: 'yyyy-MM-dd',
      prop: 'periodStartDate',
    },
    {
      label: '本金',
      prop: 'periodAmount',
    },
    {
      label: '利息',
      prop: 'periodIntAmt',
    },
    {
      label: '费用',
      prop: 'periodFeeAmt',
    },
    {
      label: '待还本金',
      prop: 'unpayAmount',
    },
    {
      label: '待还利息',
      prop: 'unpayIntAmt',
    },
    {
      label: '待还费用',
      prop: 'unpayFeeAmt',
    },
    {
      label: '待还罚息',
      prop: 'unpayFineAmount',
    },
    {
      label: '已还本金',
      prop: 'repayAmount',
    },
    {
      label: '已还利息',
      prop: 'repayIntAmt',
    },
    {
      label: '已还费用',
      prop: 'repayFeeAmt',
    },
    {
      label: '已还罚息',
      prop: 'repayFineAmount',
    },
    {
      label: '计息天数',
      prop: 'intervestDays',
    },
  ],
}

export default {
  name: 'RepaymentSchedule',
  props: {
    billForm: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      tableOption: tableOption,
    }
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.repay-section {
  overflow: hidden;
}
</style>
