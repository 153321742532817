<template>
  <div>
    <el-button
      type="primary"
      :size="size"
      :disabled="disable"
      @click="iphone"
    >{{ buttonName }}</el-button>
    <span v-show="disable" class="ml10 fc-red"><slot></slot></span>
  </div>
</template>

<script>
import { doGet, doPost, doDelet, doPut } from '@/router/axios';
export default {
  name: 'BtnCountdown',
  props: {
    size: {
      type: String,
      default: 'mini'
    },
    count: {
      type: Number,
      default: 120
    },
    url: {
      type: String,
      default: '/'
    }
  },
  data() {
    return {
      buttonName: '获取验证码',
      disable: false
    };
  },
  methods: {
    iphone() {
      const that = this;
      let sCount = that.count;

      doGet(that.url).then(res => {
        if (res.data.code === 200) {
          alert('验证码发送成功！');
        } else {
          this.$notify.error(res.data.msg);
        }
      }).catch((err) => {
        this.$notify.error(err);
      }).finally(() => {
      });

      var timeout = setInterval(() => {
        if (this.count < 1) {
          that.disable = false;
          that.buttonName = '获取验证码';
          that.count = sCount;
          clearInterval(timeout);
        } else {
          that.disable = true;
          that.buttonName = that.count-- + 's后重发';
        }
      }, 1000);
    }
  }
};
</script>

<style scoped>

</style>
