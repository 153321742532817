<template>
  <div class="dict-upload" :class="type === 'text' ? 'text' : ''">
    <el-upload
      action="http-request"
      :limit="limit"
      :multiple="multiple"
      :list-type="'image' == type ? 'picture-card' : 'text'"
      :class="{uoloadSty:showBtnImg, disUoloadSty:noneBtnImg}"
      :file-list="fileList"
      :on-change="onChange"
      :http-request="httpRequest"
      :on-success="onSuccess"
      :on-exceed="onExceed"
      :on-error="onError"
      :on-remove="onRemove"
    >
      <template v-if="'image' == type">
        <i class="el-icon-plus" />
      </template>
      <template v-else>
        <div class="el-icon-text">
          <i class="el-icon-upload2" />
          立即上传
        </div>
      </template>
      <template slot="file" slot-scope="{ file }">
        <div class="file-item" :class="[type]">
          <el-image
            v-if="type === 'image'"
            style="width: 100px; height: 100px;"
            :src="file.url"
            fit="cover"
            :preview-src-list="[file.url]"
          />
          <template v-else>
            <div class="text-box">
              <i class="el-icon-document" />
              <span>{{ file.name }}</span>
            </div>
          </template>
          <el-button v-if="!disabled" :class="[`${type}-delete-btn`]" :type="type === 'image' ? 'warning' : 'text'" icon="el-icon-delete" @click.stop="onRemove(file)" />
        </div>
      </template>
      <div v-if="tips" slot="tip" class="el-upload__tip">{{ tips }}</div>
    </el-upload>

    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import { upload, deleteFile, getImageUrl } from '@/api/upload';

export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    bizId: {
      type: String,
      default: ''
    },
    typeCode: {
      type: String,
      default: ''
    },
    tips: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'image'
    },
    limit: {
      type: Number,
      default: 9999
    },
    limitMsg: {
      type: String,
      default: '文件数量不能超过{limit}个'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      fileList: [],
      dialogVisible: false,
      dialogImageUrl: '',
      showBtnImg: true,
      noneBtnImg: false
    };
  },
  computed: {
    fileIds: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('change', val);
        this.$emit('input', val);
      }
    },
    fileListStr() {
      return this.fileIds.join(', ');
    }
  },
  watch: {
    value(fileIds) {
      const existFileIds = this.fileList.map(v => v.fileId);
      fileIds = fileIds.filter(fileId => !existFileIds.includes(fileId));
      this.fileList = this.fileList.concat(this.getFileList(fileIds));
      this.noneBtnImg = this.fileList.length >= this.limit;
    }
  },
  mounted() {
    if (this.fileIds) {
      this.fileList = this.fileList.concat(this.getFileList(this.fileIds));
      this.noneBtnImg = this.fileList.length >= this.limit;
    }
  },
  methods: {
    httpRequest(req) {
      return upload({
        file: req.file,
        bizId: this.bizId,
        typeCode: this.typeCode
      });
    },
    onSuccess(res, file, fileList) {
      this.fileList = fileList || [];
      const fileId = res.data.data.fileId;
      file.fileId = fileId;
      const type = file.name.split('.')[1];
      file.type = ['jpg', 'jpeg', 'png', 'git'].includes(type) ? 'image' : type;
      file.url = getImageUrl(fileId);
      this.fileIds = this.fileIds.concat([fileId]);
    },
    onError({ msg }) {
      if (msg) {
        this.$notify({
          title: msg,
          type: 'error',
          duration: 2500
        });
      }
    },
    onExceed() {
      this.$notify({
        title: this.limitMsg.replace('{limit}', this.limit),
        type: 'error',
        duration: 2500
      });
    },
    onRemove(file, fileList) {
      const mm = file;
      deleteFile(file.fileId).then(res => {
        const index = this.fileIds.indexOf(file.fileId);
        if (~index) {
          this.fileList.splice(index, 1);
          this.fileIds.splice(index, 1);
          this.fileIds = this.fileIds;
        }
        this.noneBtnImg = this.fileList.length >= this.limit;
        this.$emit('input', this.fileIds);
        this.$emit('change', this.fileIds);
      }).catch(err => {
        this.$notify({
          title: '删除成功',
          type: 'error',
          duration: 2500
        });
      });
    },
    getFileList(fileIds) {
      return fileIds.map(fileId => ({
        fileId: fileId,
        uid: fileId,
        name: fileId,
        url: getImageUrl(fileId)
      }));
    },
    onChange(file, fileList) {
      this.noneBtnImg = fileList.length >= this.limit;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';
.dict-upload {
  position: relative;
  display: inline-block;
  text-align: left;
  &.text {
    width: 100%;
    /*/deep/ .el-upload {*/
    /*width: 100%;*/
    /*}*/
    .el-icon-text {
      display: inline-block;
      width: 150px;
      padding: 8px 0;
      text-align: center;
      background-color: transparent;
      color: $pendingColor;
      border: 1px solid $pendingColor;
      margin-right: 10px;
      border-radius: 4px;
    }
    .el-icon-upload2 {
      font-size: 18px;
    }
  }
  /deep/ .el-upload__tip{
    display: inline-block;
    margin-top: 0;
    margin-left: 10px;
    vertical-align: middle;
  }

  $card-size: 100px;
  /deep/ .el-upload-list--picture-card{
    .el-upload-list__item{
      width: $card-size;
      height: $card-size;
      line-height: $card-size;
    }
    .el-upload-list__item-status-label i{
      position: absolute;
      top: 0;
      left: 14px;
    }
  }
  .el-upload--picture-card {
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: $card-size;
    height: $card-size;
    line-height: $card-size;
    vertical-align: top;
    cursor: pointer;
  }
  .file-item {
    position: relative;
    &.text {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .image-delete-btn {
      display: none;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 100;
      padding: 3px;
      background: rgba(black, .5);
      border-color: transparent;
    }
    &.image {
      &:hover {
        .image-delete-btn {
          display: block;
        }
      }
    }
  }

  /*/deep/ .el-upload-list__item {*/
  /*padding: 0 5px;*/
  /*}*/
  .uoloadSty {
    display: flex;
    align-items: flex-end;
  }
  /deep/ .uoloadSty .el-upload--picture-card{
    width:100px;
    height:100px;
    line-height:100px;
  }
  /deep/ .disUoloadSty .el-upload--picture-card{
    display:none;   /* 上传按钮隐藏 */
  }
  // .file-item {
  //   display: inline-block;
  //   justify-content: center;
  //   align-items: center;
  //   position: relative;
  //   background-color: #fbfdff;
  //   border: 1px solid #c0ccda;
  //   width: 80px;
  //   height: 80px;
  //   .el-button {
  //     position: absolute;
  //     right: 3px;
  //     top: 30px;
  //   }
  // }
}
</style>
