<template>
  <div class="ml30 mr30">
    <avue-crud
      ref="crud"
      v-model="form"
      :option="option"
      :page.sync="page"
      :table-loading="listLoading"
      :before-open="handleOpenBefore"
      :data="list"
      @on-load="getList"
      @size-change="sizeChange"
      @current-change="currentChange"
      @search-change="handleFilter"
      @selection-change="selectionChange"
      @refresh-change="handleRefreshChange"
    >
      <template slot="menuLeft" slot-scope="scope">
        <el-button type="primary" icon="el-icon-plus" size="small" plain @click.stop="handleAdd()">新增凭证</el-button>
      </template>
    </avue-crud>

    <div class="mt20 tc">
      <el-button icon="el-icon-arrow-left" size="medium" @click.stop="handleGoPrepStep()">上一步</el-button>
      <el-button type="primary" icon="el-icon-mouse" size="medium" @click.stop="handleGoStep()">下一步</el-button>
    </div>

    <!--新增凭证-->
  </div>
</template>

<script>
import { doGet, doPost, doDelet, doPut } from '@/router/axios'
import { mapGetters } from 'vuex'
import DictUpload from '@/views/components/DictUpload'

export default {
  name: 'AddVoucher',
  components: { DictUpload },
  props: {
    proId: {
      type: String,
      default: ''
    },
    custCreditDeatilId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      option: tableOption,
      defaultProps: {
        label: 'name',
        value: 'id'
      },
      credit: {
        totalCredit: 0,
        frozenCredit: 0,
        balanceCredit: 0,
        usedCredit: 0
      },
      page: {
        total: 0,       // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10,   // 每页显示多少条,
        isAsc: false    // 是否倒序
      },
      seleData: [],
      list: [],
      listLoading: true,
      rules: {
        protocolFileIds: [{ required: true, trigger: 'blur', message: '请上传业务协议' }],
        fpFileIds: [{ required: true, trigger: 'blur', message: '请上传发票' }]
      },
      dialogFormVisible: false,
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      }

    }
  },
  created() {
    localStorage.removeItem('voucherSelectedObj');
    this.getList();
  },
  methods: {
    getList(page, params) {
      if (params == undefined || params == null || params == '') {
        params = {}
      }
      this.listLoading = true
      params.current = page.currentPage - 1
      params.size = page.pageSize
      params.orderStatus = '0'
      doGet('/voucher/page', params).then(res => {
        this.list = res.data.data.dataList
        this.page.total = res.data.data.totalElements
        this.listLoading = false
      })
    },
    sizeChange(size) {
      this.page.pageSize = size
    },
    currentChange(current) {
      this.page.currentPage = current
    },
    handleFilter(param, done) {
      this.page.currentPage = 1
      this.getList(this.page, param)
      done()
    },
    handleRefreshChange() {
      this.getList(this.page)
    },
    handleOpenBefore(show, type) {
      window.boxType = type
      // 若是编辑、查看回显名称
      if (['edit', 'views'].includes(type)) {

      } else if (type === 'add') {

      }
      show()
    },
    handleUpdate(row, index) {
      this.$refs.crud.rowEdit(row, index)
    },
    selectionChange(list) {
      // this.$message.success('选中的数据' + JSON.stringify(list))
      localStorage.setItem("voucherSelectedObj",JSON.stringify(list));
    },
    handleAdd() {
      this.$router.push({ path: '/voucher/add' })
    },
    handleDialogClosed(params) {
      this.dialogFormVisible = false
    },
	  handleGoPrepStep() {
		  this.$router.push({ path: '/product/index' })
	  },
    handleGoStep() {
      if (localStorage.getItem("voucherSelectedObj") === null  || localStorage.getItem("voucherSelectedObj").length === 2 ||  localStorage.getItem("voucherSelectedObj") === '') {
        this.$message.error('请选择凭证!')
        return false;
      }else {
        this.$emit('handleGoToStep', 2)
      }
    }
  }
}

export const tableOption = {
  border: false,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menualign: 'left',
  searchMenuSpan: 6,
  selection: true,
  menu: false,
  editBtn: false,
  delBtn: false,
  align: 'left',
  addBtn: false,
 searchShowBtn:false,
 column: [{
    fixed: true,
    label: 'id',
    prop: 'id',
    span: 24,
    hide: true,
    editDisplay: false,
    addDisplay: false
  },
  {
    fixed: true,
    label: '编号',
    prop: 'no',
    editDisplay: true,
    addDisplay: true,
    search: true,
    searchSpan: 12,
    span: 12,
    minWidth: 180
  },
  {
    label: '交易时间',
    prop: 'startDate',
    editDisplay: true,
    addDisplay: true,
    span: 12,
    width:200
  },
  {
    label: '结算时间',
    prop: 'endDate',
    editDisplay: true,
    addDisplay: true,
    span: 12,
    width:200
  },
  {
    label: '期限',
    prop: 'dealine',
    editDisplay: true,
    addDisplay: true,
    span: 12,
    width:150
  },
  {
    label: '金额(元)',
    prop: 'tradeTotalAmt',
    editDisplay: true,
    addDisplay: true,
    span: 12,
    width:150
  },
  {
    label: '创建时间',
    prop: 'createTime',
    editDisplay: false,
    addDisplay: false,
    span: 12,
    width:200
  }
  ]
}
</script>

<style lang="scss" scoped>

</style>
