var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { ref: "editor", staticStyle: { "text-align": "left", margin: "5px" } },
      [
        _c("h3", { staticStyle: { "text-align": "center" } }, [
          _vm._v("欢迎使用 wangEditor 富文本编辑器!"),
        ]),
        _vm._m(0),
      ]
    ),
    _c(
      "div",
      {
        staticStyle: {
          margin: "12px 5px",
          "font-size": "16px",
          "font-weight": "bold",
          color: "#696969",
        },
      },
      [_vm._v("HTML渲染如下：")]
    ),
    _c("div", {
      staticClass: "editor-content",
      domProps: { innerHTML: _vm._s(_vm.editorContent) },
    }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _vm._v("富文本中图片上传使用的是sm.ms图床，支持上传到七牛云："),
        _c(
          "a",
          {
            staticStyle: { color: "#42b983" },
            attrs: { target: "_blank", href: "https://sm.ms/" },
          },
          [_vm._v("sm.ms")]
        ),
      ]),
      _c("li", [
        _vm._v("更多帮助请查看官方文档："),
        _c(
          "a",
          {
            staticStyle: { color: "#42b983" },
            attrs: {
              target: "_blank",
              href: "https://www.kancloud.cn/wangfupeng/wangeditor3/332599",
            },
          },
          [_vm._v("wangEditor")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }