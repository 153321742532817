import Cookies from 'js-cookie'

const TokenKey = 'roc-web-Token'
const UserKey = 'roc-web-user-key'


export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token, rememberMe) {
  if (rememberMe) {
    return Cookies.set(TokenKey, token, { expires: 1 })
  } else return Cookies.set(TokenKey, token)
}

export function setUserInfo(userInfo) {
  Cookies.set(UserKey, userInfo)
}

export function getUserInfo(userInfo) {
  Cookies.get(UserKey)
}
export function removeToken() {
  return Cookies.remove(TokenKey)
}
