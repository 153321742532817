<template>
  <div class="user">
    <basic-container>
      <avue-crud
        ref="crud"
        v-model="form"
        :option="option"
        :page.sync="page"
        :search.sync="search"
        :table-loading="listLoading"
        :before-open="handleOpenBefore"
        :data="list"
        @on-load="getList"
        @size-change="sizeChange"
        @current-change="currentChange"
        @search-change="handleFilter"
        @refresh-change="handleRefreshChange"
        @row-update="update"
        @row-save="create"
        @selection-change="selectionChange"
      >
        <template slot="menuLeft" />
        <template
          slot="menu"
          slot-scope="scope"
        >
          <el-button
            type="primary"
            icon="el-icon-view"
            @click="handleDetails(scope.row,scope.index)"
          />
        </template>
      </avue-crud>
    </basic-container>

    <el-dialog
      :visible.sync="dialogVisible"
      width="98%"
      custom-class="pub_dialog"
    >
      <page-dea v-if="objId" :modal-data="objId" />
    </el-dialog>
  </div>
</template>

<script>
import { doGet, doPost, doDelet, doPut, doExportExcel, downExcel } from '@/router/axios'
import { mapGetters } from 'vuex'
import { fetchTree } from '@/api/admin/dept'
import pageDea from './goodsOrderView'
import fileDownload from 'js-file-download'
export default {
  name: 'ShopGoodsOrder',
  components: {
    pageDea
  },
  props: {
    shopId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      option: tableOption,
      search: {},
      objId: '',
      defaultProps: {
        label: 'name',
        value: 'id'
      },
      dialogVisible: false,
      page: {
        total: 0, // 总页数
        current: 1, // 当前页数
        currentPage: 1,
        pageSize: 20,
        size: 20, // 每页显示多少条,
        isAsc: false// 是否倒序
      },
      list: [],
      selectedList: [],
      listLoading: true,
      form: {
      }
    }
  },
  computed: {

  },
  watch: {

  },
  created() {
    console.log('--------shopId', this.shopId)
  },
  methods: {
    selectionChange(list) {
      this.selectedList = list
    },
    handleDetails(row, index) {
      this.objId = row.id
      this.dialogVisible = true
    },
    getNodeData(node) {

    },
    makeData() {
      const params = { ...this.search }
      doPost('/shop/goodsOrder/makeData', params).then(res => {
        this.$notify.success(res.data.msg)
        this.getList()
      })
    },
    intAmt() {
      doGet('/shop/goodsOrder/testInt').then(res => {
        this.$notify.success(res.data.msg)
      })
    },
    reapyAmt() {
      doGet('/shop/goodsOrder/repayAmt').then(res => {
        this.$notify.success(res.data.msg)
      })
    },
    updateStatus() {
      const params = { ...this.search }
      if (params.orderNo == null || params.orderNo == '') {
        this.$notify.error('请选择填写订单')
      }
      params.remark = 0
      doPost('/shop/goodsOrder/updateStatus', params).then(res => {
        this.$notify.success(res.data.msg)
        this.getList()
      })
    },
    updateBatch() {
      const params = { ...this.search }

      if (params.shopId == null || params.shopId == '') {
        this.$notify.error('请选择店铺')
      }
      params.remark = 1
      doPost('/shop/goodsOrder/updateStatus', params).then(res => {
        this.$notify.success(res.data.msg)
        this.getList()
      })
    },
    refund() {
      const params = { ...this.search }
      if (params.orderNo == null || params.orderNo == '') {
        this.$notify.error('请选择填写订单')
      }
      params.remark = 0
      doPost('/shop/goodsOrder/refundGoods', params).then(res => {
        this.$notify.success(res.data.msg)
        this.getList()
      })
    },
    refundDedut() {
      doGet('/shop/goodsOrder/refundDeduct').then(res => {
        this.$notify.success(res.data.msg)
        this.getList()
      })
    },
    queryEnterStatus() {
      doGet('/upyzt/cusapp/queryStatus').then(res => {
        this.$notify.success(res.data.msg)
      })
    },
    getList(page, params) {
      if (params == undefined || params == null || params == '') {
        params = {}
      }
      this.listLoading = true
      params.current = page.currentPage - 1
      params.size = page.pageSize
      params.shopId = this.shopId
      doPost('/shop/goodsOrder/queryPage', params).then(res => {
        this.list = res.data.data.dataList
        this.page.total = res.data.data.totalElements
        this.listLoading = false
      })
    },
    sizeChange(size) {
      this.page.size = size
      this.page.pageSize = size
    },
    currentChange(current) {
      this.page.current = current
      this.page.currentPage = current
    },
    handleFilter(param, done) {
      this.page.current = 1
      this.page.currentPage = 1
      this.getList(this.page, param)
      done()
    },
    copyAdd() {
      if (this.selectedList.length > 1 || this.selectedList.length == 0) {
        this.$notify.error('请选择复制数据')
        return
      }
      this.form = this.selectedList[0]
      this.form.id = null
      this.$refs.crud.rowAdd()
    },
    handleRefreshChange() {
      this.getList(this.page)
    },
    handleOpenBefore(show, type) {
      window.boxType = type
      // 若是编辑、查看回显名称
      if (['edit', 'views'].includes(type)) {

      } else if (type === 'add') {

      }
      show()
    },
    handleUpdate(row, index) {
      this.$refs.crud.rowEdit(row, index)
    },
    handleExport() {
      this.listLoading = true
      const params = { ...this.search }
      doExportExcel('/shop/goodsOrder/export', params).then(res => {
        downExcel(res)
        this.listLoading = false
      })
    },
    handleTemplate() {
      this.listLoading = true
      doExportExcel('/shop/goodsOrder/exportTemplate', this.form).then(res => {
        downExcel(res)
        this.listLoading = false
      })
    },
    create(row, done, loading) {
      this.listLoading = true
      const params = {
        ...this.form
      }
      doPost('/shop/goodsOrder/save', params).then(res => {
        if (res.data.code === 200) {
          this.getList(this.page)
          done()
          this.$notify.success('创建成功')
          return false
        } else {
          const retMs = res.data.msg
          this.listLoading = false
          this.$notify.error(retMs)
          return
        }
        loading()
      }).catch(() => {
        loading()
      })
    },
    update(row, index, done, loading) {
      const params = {
        ...this.form
      }
      doPut('/shop/goodsOrder/update', params).then(res => {
        if (res.data.code === 200) {
          this.getList(this.page)
          done()
          this.$notify.success('更新成功')
          return
        } else {
          const retMs = res.data.msg
          this.listLoading = false
          this.$notify.error(retMs)
          return
        }
        loading()
      }).catch(() => {
        loading()
      })
    },
    deletes(row) {
      this.$confirm('此操作将永久删除选择数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消', type: 'warning'
      }
      ).then(() => {
        this.listLoading = true
        doDelet('/shop/goodsOrder/delete/' + row.id).then(res => {
          if (res.data.code === 200) {
            this.getList(this.page)
            this.$notify.success('删除成功')
          } else {
            const retMs = res.data.msg
            this.listLoading = false
            this.$notify.error(retMs)
            return
          }
        }).catch(() => {
          this.$notify.error('服务异常')
        })
      })
    }
  }
}

export const tableOption = {
  border: false,
  showSummary: false,
  sumColumnList: [
  ],
  printBtn: true,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menualign: 'left',
  menuWidth: 200,
  searchMenuSpan: 8,
  searchLabelWidth: 100,
  searchIndex: 3,
  menu: false,
  searchIcon: true,
  searchShowBtn: false,
  labelWidth: 100,
  editBtn: false,
  delBtn: false,
  align: 'left',
  addBtn: false,
 searchShowBtn:false,
 column: [{
    label: 'id',
    prop: 'id',
    span: 24,
    hide: true,
    editDisplay: false,
    addDisplay: false
  },
  {
    label: '店铺',
    prop: 'shopName',
    editDisplay: true,
    addDisplay: true,
    hide: true,
    span: 12
  },
  {
    label: '店铺',
    prop: 'shopId',
    editDisplay: true,
    type: 'select',
    dicUrl: '/dict/type/D000115',
    addDisplay: true,
    span: 12
  },
  {
    label: '订单编号',
    prop: 'orderNo',
    editDisplay: true,
    addDisplay: true,
    search: true,
    span: 12,
    searchSpan: 8,
  },
  {
    label: '订单状态',
    prop: 'orderStatus',
    type: 'select',
    dicUrl: '/dict/type/D000103',
    editDisplay: true,
    addDisplay: true,
    search: true,
    span: 12,
    searchSpan: 8,
  },
  {
    label: '借款状态',
    prop: 'loanStatus',
    type: 'select',
    fixed: true,
    dicUrl: '/dict/type/D000104',
    editDisplay: true,
    addDisplay: true,
    search: true,
    span: 12,
    searchSpan: 8,
  },
  {
    label: '实付金额',
    prop: 'payAmt',
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: false,
      message: '请输入实付金额'
    }
    ],
    span: 12
  },
  {
    label: '预计结算金额',
    prop: 'preSettleAmt',
    editDisplay: true,
    addDisplay: true,
    span: 12
  },
  {
    label: '实际结算金额',
    prop: 'settleAmt',
    editDisplay: true,
    addDisplay: true,
    span: 12
  },
  {
    label: '商品ID',
    prop: 'goodsId',
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: false,
      message: '请输入商品ID'
    }
    ],
    span: 12,
  },
  {
    label: '商品名称',
    prop: 'goodsName',
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: false,
      message: '请输入商品名称'
    }
    ],
    search: true,
    span: 12,
    searchSpan: 8,
  },
  {
    label: '商品单价',
    prop: 'goodsPrice',
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: false,
      message: '请输入商品单价'
    }
    ],
    span: 12
  },
  {
    label: '商品数量',
    prop: 'goodsNum',
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: false,
      message: '请输入商品数量'
    }
    ],
    span: 12
  },
  {
    label: '购买时间',
    prop: 'buyTime',
    type: 'datetime',
    search: true,
    searchRange: true,
    startPlaceholder: '开始日期',
    endPlaceholder: '结束日期',
    format: 'yyyy-MM-dd HH:mm:ss',
    valueFormat: 'yyyy-MM-dd HH:mm:ss',
    editDisplay: true,
    addDisplay: true,
    span: 12,
    searchSpan: 8,
  },
  {
    label: '发货时间',
    prop: 'shipmentsTime',
    type: 'datetime',
    format: 'yyyy-MM-dd HH:mm:ss',
    valueFormat: 'yyyy-MM-dd HH:mm:ss',
    editDisplay: true,
    addDisplay: true,
    span: 12
  },
  {
    label: '确认收款时间',
    prop: 'receiptTime',
    type: 'datetime',
    format: 'yyyy-MM-dd HH:mm:ss',
    valueFormat: 'yyyy-MM-dd HH:mm:ss',
    editDisplay: true,
    addDisplay: true,
    span: 12
  },
  {
    label: '创建时间',
    prop: 'createTime',
    editDisplay: false,
    addDisplay: false,
    span: 12
  },
  {
    label: '更新时间',
    prop: 'updateTime',
    editDisplay: false,
    addDisplay: false,
    hide: true,
    span: 12
  }
  ]
}

</script>
