<template>
  <div class="avue-sidebar">
    <logo />
    <el-scrollbar style="height:100%">
      <div v-if="validatenull(menu)" class="avue-sidebar--tip">没有发现菜单</div>
      <el-menu
        unique-opened
        :default-active="nowTagValue"
        mode="vertical"
        :show-timeout="200"
        :collapse="keyCollapse"
      >
        <sidebar-item
          :menu="menu"
          :screen="screen"
          first
          :collapse="keyCollapse"
          :props="website.menu.props"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import logo from '../logo';
import sidebarItem from './sidebarItem';

export default {
  name: 'Sidebar',
  components: { sidebarItem, logo },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['website', 'menu', 'tag', 'keyCollapse', 'screen']),
    nowTagValue: function() {
      return this.$router.$avueRouter.getValue(this.$route);
    }
  },
  created() {
    // this.$store.dispatch('GetMenu', { type: true, id: -1 }).then((data) => {
    //   if (data.length === 0) return
    //   this.$router.$avueRouter.formatRoutes(data, true)
    // })
  },

  mounted() {},
  methods: {}
};
</script>
<style lang="scss" scoped>
</style>

