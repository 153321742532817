var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("basic-container", [
        _vm.$route.query.src
          ? _c("iframe", {
              ref: "iframe",
              staticClass: "iframe",
              attrs: { src: _vm.$route.query.src },
            })
          : _c("iframe", {
              ref: "iframe",
              staticClass: "iframe",
              attrs: { src: _vm.urlPath },
            }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }