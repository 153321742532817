<template>
  <div>
    <div class="mz-layout-section">
      <div class="tableList_title">主动还款列表</div>
      <el-divider />

      <avue-crud
        ref="crud"
        :option="option"
        :page.sync="page"
        :table-loading="listLoading"
        :data="list"
        @on-load="getList"
        @size-change="sizeChange"
        @current-change="currentChange"
        @search-change="handleFilter"
        @search-reset="resetFilter">
        <template slot="menu" slot-scope="scope">
          <el-button type="text" icon="el-icon-view" @click="handleDetails(scope.row, scope.index)">详情</el-button>
        </template>
      </avue-crud>
    </div>

    <el-dialog title="还款单" :visible.sync="dialogVisible" width="80%" top="100px" custom-class="pub_dialog">
      <page-details v-if="fileId" :modal-data="fileId" />
    </el-dialog>
  </div>
</template>

<script>
import { doGet } from '@/router/axios'
import pageDetails from './components/details'
const tableOption = {
  searchMenuSpan: 8,
  searchLabelWidth: 80,
  searchMenuPosition: 'left',
  border: false,
  index: false,
  indexLabel: '序号',
  stripe: true,
  header: false,
  menualign: 'left',
  menuWidth: 120,
  refreshBtn: false,
  columnBtn: false,
  editBtn: false,
  delBtn: false,
  align: 'left',
  addBtn: false,
 searchShowBtn:false,
 column: [
    {
      label: '企业',
      prop: 'custName',
      minWidth: 200,
      editDisplay: true,
      addDisplay: true,
      search: true,
      searchSpan: 8,
      searchPlaceholder: '请输入企业',
      span: 12,
    },
    {
      label: '单号',
      prop: 'no',
      width: 165,
      editDisplay: true,
      addDisplay: true,
      search: true,
      searchSpan: 8,
      searchPlaceholder: '请输入单号',
      span: 12,
    },
    {
      label: '还款金额',
      prop: 'applyAmt',
      editDisplay: true,
      width: 120,
      addDisplay: true,
      span: 12,
    },
    {
      label: '状态',
      prop: 'status',
      type: 'select',
      dicUrl: '/common/type/D000050',
      span: 12,
      width: 120,
    },
    {
      label: '创建时间',
      prop: 'createTime',
      editDisplay: false,
      width: 160,
      span: 12,
    },
  ],
}

export default {
  name: 'RepayList',
  components: { pageDetails },
  data() {
    return {
      option: tableOption,
      searchItmes: {},
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条,
      },
      list: [],
      listLoading: true,
      dialogVisible: false,
      fileId: '',
    }
  },
  methods: {
    getList(page, params) {
      params = params ? params : { ...this.searchItmes }
      params.current = page.currentPage - 1
      params.size = page.pageSize
      this.listLoading = true
      doGet('/repay/page', params).then(({ data }) => {
        if (data) {
          const { dataList, totalElements } = data.data || {}
          this.list = dataList || []
          this.page.total = totalElements || 0
        }
        this.listLoading = false
      })
    },

    sizeChange(size) {
      this.page.pageSize = size
    },
    currentChange(current) {
      this.page.currentPage = current
    },
    handleFilter(param, done) {
      this.page.currentPage = 1
      this.searchItmes = param ? param : {}
      this.getList(this.page, param)
      done()
    },
    resetFilter() {
      this.page.currentPage = 1
      this.searchItmes = {}
      this.getList(this.page, this.searchItmes)
    },

    handleDetails(row, index) {
      console.log('row.id' + row.id)
      this.fileId = row.id
      this.dialogVisible = true
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/ .el-tabs__nav-scroll {
  padding-left: 10px;
}
/deep/ .el-divider {
  background-color: #ecedef;
}
</style>
