var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "icon-body" },
    [
      _c(
        "el-input",
        {
          staticStyle: { position: "relative" },
          attrs: { clearable: "", placeholder: "请输入图标名称" },
          on: { clear: _vm.filterIcons },
          nativeOn: {
            input: function ($event) {
              return _vm.filterIcons.apply(null, arguments)
            },
          },
          model: {
            value: _vm.name,
            callback: function ($$v) {
              _vm.name = $$v
            },
            expression: "name",
          },
        },
        [
          _c("i", {
            staticClass: "el-icon-search el-input__icon",
            attrs: { slot: "suffix" },
            slot: "suffix",
          }),
        ]
      ),
      _c(
        "div",
        { staticClass: "icon-list" },
        _vm._l(_vm.iconList, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              on: {
                click: function ($event) {
                  return _vm.selectedIcon(item)
                },
              },
            },
            [
              _c("svg-icon", {
                staticStyle: { height: "30px", width: "16px" },
                attrs: { "icon-class": item },
              }),
              _c("span", [_vm._v(_vm._s(item))]),
            ],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }