var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "step2-form-wrap" },
    [
      _c(
        "el-form",
        {
          ref: "vForm",
          attrs: {
            model: _vm.vForm,
            rules: _vm.vRules,
            "label-position": "right",
            "label-width": "180px",
            size: "small",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "姓名", prop: "legalPerson" } },
            [
              _c("el-input", {
                attrs: { placeholder: "以身份证姓名为准" },
                model: {
                  value: _vm.vForm.legalPerson,
                  callback: function ($$v) {
                    _vm.$set(_vm.vForm, "legalPerson", $$v)
                  },
                  expression: "vForm.legalPerson",
                },
              }),
            ],
            1
          ),
          _vm.type !== 2
            ? _c(
                "el-form-item",
                { attrs: { label: "身份", prop: "personType" } },
                [
                  _c("dict-select", {
                    attrs: { no: "Y00131", type: "checkbox", "is-slot": "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ zones }) {
                            return _vm._l(
                              _vm.getPersonList(zones),
                              function (item, i) {
                                return _c(
                                  "el-checkbox",
                                  {
                                    key: item.id,
                                    attrs: { disabled: "", label: item.id },
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(item.label) +
                                        "\n          "
                                    ),
                                  ]
                                )
                              }
                            )
                          },
                        },
                      ],
                      null,
                      false,
                      961932903
                    ),
                    model: {
                      value: _vm.vForm.personType,
                      callback: function ($$v) {
                        _vm.$set(_vm.vForm, "personType", $$v)
                      },
                      expression: "vForm.personType",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "成员所属地区", prop: "location", hidden: "" } },
            [_vm._v("\n      " + _vm._s(_vm.vForm.location) + "\n    ")]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "主体类型", prop: "cardType" } },
            [
              _c("dict-select", {
                attrs: { type: "radio", no: "Y00145" },
                model: {
                  value: _vm.vForm.cardType,
                  callback: function ($$v) {
                    _vm.$set(_vm.vForm, "cardType", $$v)
                  },
                  expression: "vForm.cardType",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: `手持${_vm.cardLabel}(个人信息页)`,
                prop: "fileCardFrontId",
              },
            },
            [
              _c("dict-upload", {
                attrs: {
                  type: "text",
                  limit: 1,
                  "type-code": "F001003",
                  bizId: "123456",
                },
                on: {
                  change: function ($event) {
                    return _vm.onFileChange($event, "fileCardFrontId")
                  },
                },
                model: {
                  value: _vm.vForm.fileCardFrontId,
                  callback: function ($$v) {
                    _vm.$set(_vm.vForm, "fileCardFrontId", $$v)
                  },
                  expression: "vForm.fileCardFrontId",
                },
              }),
              _c(
                "el-link",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.vForm.cardType == 1,
                      expression: "vForm.cardType == 1",
                    },
                  ],
                  attrs: {
                    href: require("@/assets/auth/idcard_with_hand.jpg"),
                    target: "_blank",
                  },
                },
                [_vm._v("\n        手持身份证样图\n      ")]
              ),
              _c(
                "div",
                {
                  staticClass: "form-item-tips",
                  staticStyle: { "line-height": "1.6", "padding-top": "5px" },
                },
                [
                  _vm._v(
                    "\n        请手持" +
                      _vm._s(_vm.cardLabel) +
                      "正面并拍照上传，请注意以下事项： "
                  ),
                  _c("br"),
                  _vm._v("\n        ① 手臂完整可见 "),
                  _c("br"),
                  _vm._v("\n        ② 信息清晰可见 "),
                  _c("br"),
                  _vm._v("\n        ③ 尽量把人拍大\n      "),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: `${_vm.cardLabel}${
                  _vm.vForm.cardType == 1 ? "(头像页)" : "(首页)"
                }`,
                prop: "fileCardPeopleId",
              },
            },
            [
              _c("dict-upload", {
                attrs: {
                  type: "text",
                  limit: 1,
                  "type-code": "F001008",
                  bizId: "123456",
                },
                on: {
                  change: function ($event) {
                    return _vm.onFileChange($event, "fileCardPeopleId")
                  },
                },
                model: {
                  value: _vm.vForm.fileCardPeopleId,
                  callback: function ($$v) {
                    _vm.$set(_vm.vForm, "fileCardPeopleId", $$v)
                  },
                  expression: "vForm.fileCardPeopleId",
                },
              }),
              _c(
                "el-link",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.vForm.cardType == 1,
                      expression: "vForm.cardType == 1",
                    },
                  ],
                  attrs: {
                    href: require("@/assets/auth/idcard_with_hand_next.jpg"),
                    target: "_blank",
                  },
                },
                [_vm._v("\n        身份证正面样图\n      ")]
              ),
              _c("div", { staticClass: "form-item-tips" }, [
                _vm._v("\n        仅需上传证件照片\n      "),
              ]),
            ],
            1
          ),
          _vm.vForm.cardType == 1
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "身份证背面(国徽页)",
                    prop: "fileCardReverseId",
                  },
                },
                [
                  _c("dict-upload", {
                    attrs: {
                      type: "text",
                      limit: 1,
                      "type-code": "F001004",
                      bizId: "123456",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.onFileChange($event, "fileCardReverseId")
                      },
                    },
                    model: {
                      value: _vm.vForm.fileCardReverseId,
                      callback: function ($$v) {
                        _vm.$set(_vm.vForm, "fileCardReverseId", $$v)
                      },
                      expression: "vForm.fileCardReverseId",
                    },
                  }),
                  _c(
                    "el-link",
                    {
                      attrs: {
                        href: require("@/assets/auth/idcard_emblem.jpg"),
                        target: "_blank",
                      },
                    },
                    [_vm._v("\n        身份证背面样图\n      ")]
                  ),
                  _c("div", { staticClass: "form-item-tips" }, [
                    _vm._v("\n        仅需上传证件照片\n      "),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: `${_vm.cardLabel}号码`, prop: "idCard" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: `请输入${_vm.cardLabel}号码`,
                  maxlength: "18",
                },
                model: {
                  value: _vm.vForm.idCard,
                  callback: function ($$v) {
                    _vm.$set(_vm.vForm, "idCard", $$v)
                  },
                  expression: "vForm.idCard",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: `${_vm.cardLabel}派发日期`,
                prop: "cardStartTime",
              },
            },
            [
              _c("el-date-picker", {
                attrs: {
                  "value-format": "yyyy-MM-dd",
                  placeholder: `请输入${_vm.cardLabel}派发日期`,
                },
                model: {
                  value: _vm.vForm.cardStartTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.vForm, "cardStartTime", $$v)
                  },
                  expression: "vForm.cardStartTime",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: { label: `${_vm.cardLabel}到期日期`, prop: "cardEndTime" },
            },
            [
              !_vm.vForm.cardEndTimeForever
                ? [
                    _c("el-date-picker", {
                      attrs: {
                        "value-format": "yyyy-MM-dd",
                        placeholder: `请输入${_vm.cardLabel}到期日期`,
                      },
                      model: {
                        value: _vm.vForm.cardEndTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.vForm, "cardEndTime", $$v)
                        },
                        expression: "vForm.cardEndTime",
                      },
                    }),
                    _c("span", { staticStyle: { "margin-right": "10px" } }),
                  ]
                : _vm._e(),
              _c(
                "el-checkbox",
                {
                  staticStyle: { display: "inline-block" },
                  on: { change: _vm.oncardEndTimeForeverChange },
                  model: {
                    value: _vm.vForm.cardEndTimeForever,
                    callback: function ($$v) {
                      _vm.$set(_vm.vForm, "cardEndTimeForever", $$v)
                    },
                    expression: "vForm.cardEndTimeForever",
                  },
                },
                [_vm._v("\n        证件长期有效\n      ")]
              ),
            ],
            2
          ),
          _c(
            "el-form-item",
            { attrs: { label: "持股比例", prop: "shareHolding" } },
            [
              _c("el-input-number", {
                attrs: { placeholder: "请输入持股比例百分数", maxlength: "3" },
                model: {
                  value: _vm.vForm.shareHolding,
                  callback: function ($$v) {
                    _vm.$set(_vm.vForm, "shareHolding", $$v)
                  },
                  expression: "vForm.shareHolding",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "实际居住地址", prop: "liveAddress" } },
            [
              _c("addr-select", {
                attrs: { internation: "" },
                model: {
                  value: _vm.vForm.liveAddressPre,
                  callback: function ($$v) {
                    _vm.$set(_vm.vForm, "liveAddressPre", $$v)
                  },
                  expression: "vForm.liveAddressPre",
                },
              }),
              _c("div", { staticStyle: { "margin-bottom": "10px" } }),
              _c("el-input", {
                attrs: {
                  placeholder: "请输入详细区县街道门牌号地址",
                  maxlength: "200",
                },
                model: {
                  value: _vm.vForm.liveAddress,
                  callback: function ($$v) {
                    _vm.$set(_vm.vForm, "liveAddress", $$v)
                  },
                  expression: "vForm.liveAddress",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "居住地址证明(选填)", prop: "fileLiveProveId" } },
            [
              _c("dict-upload", {
                attrs: {
                  type: "text",
                  limit: 1,
                  "type-code": "F001007",
                  bizId: "123456",
                },
                on: {
                  change: function ($event) {
                    return _vm.onFileChange($event, "fileLiveProveId")
                  },
                },
                model: {
                  value: _vm.vForm.fileLiveProveId,
                  callback: function ($$v) {
                    _vm.$set(_vm.vForm, "fileLiveProveId", $$v)
                  },
                  expression: "vForm.fileLiveProveId",
                },
              }),
              _c("div", { staticClass: "form-item-tips" }, [
                _vm._v(
                  "\n        近3个月包含实际居住地址与姓名的水电煤单据、信用卡账单等资料\n      "
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }