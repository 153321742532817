var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bk_white" }, [
    _c(
      "div",
      { staticClass: "ml30 mr30 pb50", staticStyle: { "margin-top": "80px" } },
      [
        _vm._m(0),
        _c("p", { staticClass: "mt30 tc fb fc_333 f18" }, [_vm._v("提交成功")]),
        _c("p", { staticClass: "pt20 pb20 f16 tc" }, [_vm._v(_vm._s(_vm.msg))]),
        _c(
          "div",
          { staticClass: "success-info-box" },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { staticClass: "title_h3 mb20", attrs: { span: 24 } },
                  [_vm._v("项目信息")]
                ),
                _c("el-col", { attrs: { span: 12 } }, [
                  _vm._v("申请订单编号：" + _vm._s(_vm.orderNo)),
                ]),
                _c("el-col", { attrs: { span: 12 } }, [
                  _vm._v("借款金额：" + _vm._s(_vm.applyAmt) + "元"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt40 tc" },
          [
            _c(
              "el-button",
              {
                attrs: { size: "medium", type: "primary" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.$router.push({ path: "/" })
                  },
                },
              },
              [_vm._v("返回首页")]
            ),
            _c(
              "el-button",
              {
                attrs: { size: "medium" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.$router.push({ path: "/loan/index" })
                  },
                },
              },
              [_vm._v("查看订单")]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon-success-box tc" }, [
      _c("i", { staticClass: "el-icon-success" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }