var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "selection-box" },
    [
      _c("el-alert", {
        attrs: { title: "企业信息", closable: false, type: "info" },
      }),
      _c(
        "el-form",
        {
          ref: "cForm",
          staticClass: "cForm",
          attrs: {
            model: _vm.cForm,
            rules: _vm.vRules,
            "label-position": "left",
            "label-width": "150px",
            size: "small",
          },
        },
        [
          _c(
            "div",
            { staticClass: "mt10 inner-box" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "营业执照", prop: "fileBusinessId" } },
                [
                  _c("dict-upload-info", {
                    attrs: {
                      type: "image",
                      limit: 1,
                      "type-code": "F0202",
                      "biz-id": "123456",
                      url: "cust/enter/upload",
                      tips: "提示：请上传.jpg,jpeg,.png,.webp格式图片，大小不超过2M",
                      disabled: _vm.disabled,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.onFileChange($event, "fileBusinessId")
                      },
                      getOcrInfo: _vm.handleSetOcrInfo,
                    },
                    model: {
                      value: _vm.cForm.fileBusinessId,
                      callback: function ($$v) {
                        _vm.$set(_vm.cForm, "fileBusinessId", $$v)
                      },
                      expression: "cForm.fileBusinessId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "企业类型", prop: "enterType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.disabled },
                      model: {
                        value: _vm.cForm.enterType,
                        callback: function ($$v) {
                          _vm.$set(_vm.cForm, "enterType", $$v)
                        },
                        expression: "cForm.enterType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("普通企业"),
                      ]),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("个体工商户"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "公司名称", prop: "name" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "350px" },
                    attrs: {
                      maxlength: "40",
                      placeholder: "请输入公司名称",
                      disabled: _vm.disabled,
                    },
                    model: {
                      value: _vm.cForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.cForm, "name", $$v)
                      },
                      expression: "cForm.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "营业执照号", prop: "creditCode" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "350px" },
                    attrs: {
                      maxlength: "20",
                      placeholder: "一般为18/15位数字或字母组合",
                      disabled: _vm.disabled,
                    },
                    model: {
                      value: _vm.cForm.creditCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.cForm, "creditCode", $$v)
                      },
                      expression: "cForm.creditCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "营业期限", prop: "expireDateAttr" } },
                [
                  [
                    !_vm.cForm.expireDateAttrFlag
                      ? _c("el-date-picker", {
                          staticClass: "mr20",
                          attrs: {
                            disabled: _vm.disabled,
                            "value-format": "yyyy-MM-dd",
                            type: "daterange",
                            "range-separator": "至",
                            "start-placeholder": "起始日期",
                            "end-placeholder": "结束日期",
                          },
                          model: {
                            value: _vm.cForm.expireDateAttr,
                            callback: function ($$v) {
                              _vm.$set(_vm.cForm, "expireDateAttr", $$v)
                            },
                            expression: "cForm.expireDateAttr",
                          },
                        })
                      : _vm._e(),
                    _c(
                      "el-checkbox",
                      {
                        attrs: { disabled: _vm.disabled },
                        on: { change: _vm.handleChangeCompanyExpire },
                        model: {
                          value: _vm.cForm.expireDateAttrFlag,
                          callback: function ($$v) {
                            _vm.$set(_vm.cForm, "expireDateAttrFlag", $$v)
                          },
                          expression: "cForm.expireDateAttrFlag",
                        },
                      },
                      [_vm._v("无固定期限")]
                    ),
                  ],
                ],
                2
              ),
              _c(
                "el-form-item",
                { attrs: { label: "注册地址", prop: "regitAddress" } },
                [
                  _c("el-cascader", {
                    staticClass: "fl",
                    attrs: {
                      disabled: _vm.disabled,
                      options: _vm.options,
                      placeholder: "请选择省市区",
                    },
                    model: {
                      value: _vm.cForm.provinces,
                      callback: function ($$v) {
                        _vm.$set(_vm.cForm, "provinces", $$v)
                      },
                      expression: "cForm.provinces",
                    },
                  }),
                  _c("el-input", {
                    staticClass: "ml10 fl",
                    staticStyle: { width: "60%" },
                    attrs: {
                      maxlength: "120",
                      disabled: _vm.disabled,
                      "aria-placeholder": "请输入详细地址",
                    },
                    model: {
                      value: _vm.cForm.regitAddress,
                      callback: function ($$v) {
                        _vm.$set(_vm.cForm, "regitAddress", $$v)
                      },
                      expression: "cForm.regitAddress",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider", { staticClass: "ml15 mr15" }),
          _c("div", { staticClass: "sub_title" }, [_vm._v("法人信息")]),
          _c("div", { staticClass: "ml15 mb25" }, [
            _c("i", { staticClass: "el-icon-warning fc-blue-t f16" }),
            _vm._v(" 请正确填写法人信息"),
          ]),
          _c(
            "div",
            { staticClass: "mt10 inner-box" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "身份证（人像面）",
                    prop: "legalFilePeopleId",
                  },
                },
                [
                  _c("dict-upload-info", {
                    attrs: {
                      disabled: _vm.disabled,
                      type: "image",
                      limit: 1,
                      "type-code": "F0204",
                      "biz-id": "123456",
                      url: "cust/enter/upload",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.onFileChange($event, "legalFilePeopleId")
                      },
                      getOcrInfo: _vm.handleSetOcrInfo,
                    },
                    model: {
                      value: _vm.cForm.legalFilePeopleId,
                      callback: function ($$v) {
                        _vm.$set(_vm.cForm, "legalFilePeopleId", $$v)
                      },
                      expression: "cForm.legalFilePeopleId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "身份证（国徽面）",
                    prop: "legalFileReverseId",
                  },
                },
                [
                  _c("dict-upload-info", {
                    attrs: {
                      disabled: _vm.disabled,
                      type: "image",
                      limit: 1,
                      "type-code": "F0203",
                      "biz-id": "123456",
                      url: "cust/enter/upload",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.onFileChange($event, "legalFileReverseId")
                      },
                    },
                    model: {
                      value: _vm.cForm.legalFileReverseId,
                      callback: function ($$v) {
                        _vm.$set(_vm.cForm, "legalFileReverseId", $$v)
                      },
                      expression: "cForm.legalFileReverseId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "法人姓名：", prop: "legalName" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "350px" },
                    attrs: {
                      maxlength: "20",
                      disabled: _vm.disabled,
                      placeholder: "请输入法人姓名",
                    },
                    model: {
                      value: _vm.cForm.legalName,
                      callback: function ($$v) {
                        _vm.$set(_vm.cForm, "legalName", $$v)
                      },
                      expression: "cForm.legalName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "法人身份证号：", prop: "legalIdCard" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "350px" },
                    attrs: {
                      maxlength: "18",
                      disabled: _vm.disabled,
                      placeholder: "一般为18/15位数字或字母组合",
                    },
                    model: {
                      value: _vm.cForm.legalIdCard,
                      callback: function ($$v) {
                        _vm.$set(_vm.cForm, "legalIdCard", $$v)
                      },
                      expression: "cForm.legalIdCard",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "法人手机号：", prop: "legalPhone" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "350px" },
                    attrs: {
                      maxlength: "11",
                      disabled: _vm.disabled,
                      placeholder: "请输入法人手机号",
                    },
                    model: {
                      value: _vm.cForm.legalPhone,
                      callback: function ($$v) {
                        _vm.$set(_vm.cForm, "legalPhone", $$v)
                      },
                      expression: "cForm.legalPhone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "法人邮箱：", prop: "legalEmail" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "350px" },
                    attrs: {
                      maxlength: "30",
                      disabled: _vm.disabled,
                      placeholder: "请输入法人邮箱",
                    },
                    model: {
                      value: _vm.cForm.legalEmail,
                      callback: function ($$v) {
                        _vm.$set(_vm.cForm, "legalEmail", $$v)
                      },
                      expression: "cForm.legalEmail",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "法人身份证有效日期",
                    prop: "leagalDateAttr",
                  },
                },
                [
                  [
                    _c("el-date-picker", {
                      attrs: {
                        disabled: _vm.disabled,
                        "value-format": "yyyy-MM-dd",
                        type: "daterange",
                        "start-placeholder": "起始日期",
                        "end-placeholder": "结束日期",
                      },
                      model: {
                        value: _vm.cForm.leagalDateAttr,
                        callback: function ($$v) {
                          _vm.$set(_vm.cForm, "leagalDateAttr", $$v)
                        },
                        expression: "cForm.leagalDateAttr",
                      },
                    }),
                    _c("span", { staticStyle: { "margin-right": "30px" } }),
                  ],
                ],
                2
              ),
            ],
            1
          ),
          _c("el-divider", { staticClass: "ml15 mr15" }),
          _c(
            "div",
            { staticClass: "sub_title" },
            [
              _vm._v("授权人信息 "),
              _c("el-checkbox", {
                staticClass: "ml10",
                attrs: { disabled: _vm.disabled, label: "(和法人为同一人)" },
                on: {
                  change: function ($event) {
                    return _vm.handleChangeSamePerson(1)
                  },
                },
                model: {
                  value: _vm.checkedSameAuth,
                  callback: function ($$v) {
                    _vm.checkedSameAuth = $$v
                  },
                  expression: "checkedSameAuth",
                },
              }),
            ],
            1
          ),
          !_vm.checkedSameAuth
            ? _c("div", [
                _c("div", { staticClass: "ml15 mb25" }, [
                  _c("i", { staticClass: "el-icon-warning fc-blue-t f16" }),
                  _vm._v(" 请正确填写授权人信息"),
                ]),
                _c(
                  "div",
                  { staticClass: "mt10 inner-box" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "身份证（人像面）",
                          prop: "authFilePeopleId",
                        },
                      },
                      [
                        _c("dict-upload-info", {
                          attrs: {
                            disabled: _vm.disabled,
                            type: "image",
                            limit: 1,
                            "type-code": "F0206",
                            "biz-id": "123456",
                            url: "cust/enter/upload",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onFileChange(
                                $event,
                                "authFilePeopleId"
                              )
                            },
                            getOcrInfo: _vm.handleSetOcrInfo,
                          },
                          model: {
                            value: _vm.cForm.authFilePeopleId,
                            callback: function ($$v) {
                              _vm.$set(_vm.cForm, "authFilePeopleId", $$v)
                            },
                            expression: "cForm.authFilePeopleId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "身份证（国徽面）",
                          prop: "authFileReverseId",
                        },
                      },
                      [
                        _c("dict-upload-info", {
                          attrs: {
                            disabled: _vm.disabled,
                            type: "image",
                            limit: 1,
                            "type-code": "F0205",
                            "biz-id": "123456",
                            url: "cust/enter/upload",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onFileChange(
                                $event,
                                "authFileReverseId"
                              )
                            },
                          },
                          model: {
                            value: _vm.cForm.authFileReverseId,
                            callback: function ($$v) {
                              _vm.$set(_vm.cForm, "authFileReverseId", $$v)
                            },
                            expression: "cForm.authFileReverseId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "授权人姓名：", prop: "authName" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "350px" },
                          attrs: {
                            maxlength: "30",
                            disabled: _vm.disabled,
                            placeholder: "请输入授权人姓名",
                          },
                          model: {
                            value: _vm.cForm.authName,
                            callback: function ($$v) {
                              _vm.$set(_vm.cForm, "authName", $$v)
                            },
                            expression: "cForm.authName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "授权人身份证号：",
                          prop: "authIdCard",
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "350px" },
                          attrs: {
                            maxlength: "18",
                            disabled: _vm.disabled,
                            placeholder: "一般为18/15位数字或字母组合",
                          },
                          model: {
                            value: _vm.cForm.authIdCard,
                            callback: function ($$v) {
                              _vm.$set(_vm.cForm, "authIdCard", $$v)
                            },
                            expression: "cForm.authIdCard",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "授权人手机号：", prop: "authPhone" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "350px" },
                          attrs: {
                            maxlength: "11",
                            disabled: _vm.disabled,
                            placeholder: "请输入授权人手机号",
                          },
                          model: {
                            value: _vm.cForm.authPhone,
                            callback: function ($$v) {
                              _vm.$set(_vm.cForm, "authPhone", $$v)
                            },
                            expression: "cForm.authPhone",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "授权人邮箱：", prop: "authEmail" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "350px" },
                          attrs: {
                            maxlength: "30",
                            disabled: _vm.disabled,
                            placeholder: "请输入授权人邮箱",
                          },
                          model: {
                            value: _vm.cForm.authEmail,
                            callback: function ($$v) {
                              _vm.$set(_vm.cForm, "authEmail", $$v)
                            },
                            expression: "cForm.authEmail",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c("el-divider", { staticClass: "ml15 mr15" }),
          _c(
            "div",
            { staticClass: "sub_title" },
            [
              _vm._v("实控人信息 "),
              _c("el-checkbox", {
                staticClass: "ml10",
                attrs: { disabled: _vm.disabled, label: "(和法人为同一人)" },
                on: {
                  change: function ($event) {
                    return _vm.handleChangeSamePerson(2)
                  },
                },
                model: {
                  value: _vm.checkedSame,
                  callback: function ($$v) {
                    _vm.checkedSame = $$v
                  },
                  expression: "checkedSame",
                },
              }),
            ],
            1
          ),
          !_vm.checkedSame
            ? _c("div", [
                _c("div", { staticClass: "ml15 mb25" }, [
                  _c("i", { staticClass: "el-icon-warning fc-blue-t f16" }),
                  _vm._v(" 请正确填写实控人信息"),
                ]),
                _c(
                  "div",
                  { staticClass: "mt10 inner-box" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "身份证（人像面）",
                          prop: "actualFilePeopleId",
                        },
                      },
                      [
                        _c("dict-upload-info", {
                          attrs: {
                            disabled: _vm.disabled,
                            type: "image",
                            limit: 1,
                            "type-code": "F0207",
                            "biz-id": "123456",
                            url: "cust/enter/upload",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onFileChange(
                                $event,
                                "actualFilePeopleId"
                              )
                            },
                            getOcrInfo: _vm.handleSetOcrInfo,
                          },
                          model: {
                            value: _vm.cForm.actualFilePeopleId,
                            callback: function ($$v) {
                              _vm.$set(_vm.cForm, "actualFilePeopleId", $$v)
                            },
                            expression: "cForm.actualFilePeopleId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "身份证（国徽面）",
                          prop: "actualFileReverseId",
                        },
                      },
                      [
                        _c("dict-upload-info", {
                          attrs: {
                            disabled: _vm.disabled,
                            type: "image",
                            limit: 1,
                            "type-code": "F0208",
                            "biz-id": "123456",
                            url: "cust/enter/upload",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onFileChange(
                                $event,
                                "actualFileReverseId"
                              )
                            },
                          },
                          model: {
                            value: _vm.cForm.actualFileReverseId,
                            callback: function ($$v) {
                              _vm.$set(_vm.cForm, "actualFileReverseId", $$v)
                            },
                            expression: "cForm.actualFileReverseId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "实控人姓名：", prop: "actualName" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "350px" },
                          attrs: {
                            maxlength: "20",
                            disabled: _vm.disabled,
                            placeholder: "请输入法人姓名",
                          },
                          model: {
                            value: _vm.cForm.actualName,
                            callback: function ($$v) {
                              _vm.$set(_vm.cForm, "actualName", $$v)
                            },
                            expression: "cForm.actualName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "实控人身份证号：",
                          prop: "actualIdCard",
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "350px" },
                          attrs: {
                            maxlength: "18",
                            disabled: _vm.disabled,
                            placeholder: "一般为18/15位数字或字母组合",
                          },
                          model: {
                            value: _vm.cForm.actualIdCard,
                            callback: function ($$v) {
                              _vm.$set(_vm.cForm, "actualIdCard", $$v)
                            },
                            expression: "cForm.actualIdCard",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "实控人手机号：", prop: "actualPhone" },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "350px" },
                          attrs: {
                            maxlength: "11",
                            disabled: _vm.disabled,
                            placeholder: "请输入法人手机号",
                          },
                          model: {
                            value: _vm.cForm.actualPhone,
                            callback: function ($$v) {
                              _vm.$set(_vm.cForm, "actualPhone", $$v)
                            },
                            expression: "cForm.actualPhone",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }