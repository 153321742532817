<template>
  <div>
    <div class="selection-box" style="margin-top: 0; padding: 0 20px;">
      <el-alert
        title="附件仅支持 JPG、JPEG、PNG、PDF 四种格式，单个文件大小不能超过2.0 MB"
        :closable="false"
        type="warning"
        show-icon
      />
    </div>

    <div class="selection-box" style="margin-top: 0; padding: 0 20px;">
      <el-form ref="cForm" :model="cForm" :rules="vRules" :label-position="'right'" label-width="120px" size="small">
        <el-alert title="银行卡绑定" :closable="false" type="info" class="mt10" />
        <div class="mt10 inner-box">

          <el-form-item label="账户类型" prop="accType">
            <dict-select v-model="cForm.accType" no="D000111" placeholder="请选择账户类型" @change="accType_change" />
          </el-form-item>

          <el-form-item label="所属银行" prop="bankCode">
            <dict-select v-model="cForm.bankCode" no="D000109" placeholder="请选择所属银行" @change="bankCode_change" />
          </el-form-item>
          <el-form-item label="选择支行：" prop="provinceCode">
            <div class="fl" style="width: 120px"><DictSelectSingle v-model="cForm.provinceCode" :clearable="false" url="/cap/capBankCustInfo/province" placeholder="请选择省份" @change="province_change" /></div>
            <div class="fl ml10 mr10" style="width: 120px"><DictSelectSingle v-if="cForm.cityCode || !cityFlag" :key="timer" v-model="cForm.cityCode" :url="cForm.cityCode ? `/cap/capBankCustInfo/city/${cForm.provinceCode}` : cityUrl" :clearable="false" placeholder="请选择市" @change="city_change" /></div>
            <div class="fl" style="width: 260px"><DictSelectSingle v-if="cForm.bankSubNo || !bankFlag" :key="timer2" v-model="cForm.bankSubNo" :url="cForm.bankSubNo ? `/cap/capBankCustInfo/bank/${cForm.cityCode}/${cForm.bankCode}` : bankUrl" placeholder="请选择支行" :clearable="false" @change="bank_change" /></div>
          </el-form-item>
          <el-form-item label="户名" prop="bankUserName">
            <el-input v-model="cForm.bankUserName" placeholder="请输入户名" />
          </el-form-item>
          <el-form-item label="银行卡号" prop="bankCard">
            <el-input v-model="cForm.bankCard" placeholder="请输入银行卡号" />
          </el-form-item>
          <el-form-item label="银行支行号" prop="bankCard">
            <el-input v-model="cForm.bankSubNo" placeholder="请输入银行支行号" readonly />
          </el-form-item>
          <el-form-item label="银行手机号" prop="mobilePhone">
            <el-input v-model="cForm.mobilePhone" placeholder="请输入银行所留手机号" />
          </el-form-item>
          <el-form-item label="银行卡性质" prop="cardNature">
            <dict-select v-model="cForm.cardNature" no="D000110" placeholder="请选择银行卡性质" @change="cardNature_change" />
          </el-form-item>
          <el-form-item label="银行卡照片" prop="fileUploadIds">
            <dict-upload v-model="cForm.fileUploadIds" type="image" :limit="5" type-code="F0103" biz-id="123456" @change="onFileChange($event, 'fileUploadIds')" />
          </el-form-item>
        </div>
        <div class="tc">
          <el-button type="primary" size="medium" :loading="loading" @click="saveCard()">绑定</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import DictUpload from '@/views/components/DictUpload.vue';
import DictSelect from '@/views/components/DictSelect.vue';
import DictSelectSingle from '@/views/components/DictSelectSingle';
import AddrSelect from '@/components/AddrSelect/index.vue';
import { doGet, doPost } from '@/router/axios';

export default {
  name: 'CompanyIndex',
  components: {
    DictUpload,
    DictSelect,
    AddrSelect,
    DictSelectSingle
  },
  props: {
    eId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      enterId: '',
      bankCustId: '',
      cForm: {
        id: '',
        shopkeeperId: '', // 营业期限
        name: '',
        shopCode: '',
        liveStreamPlat: '',
        shopkeeperPhone: '',
        fileUploadIds: []
      },
      cityFlag: true,
      cityUrl: '',
      bankUrl: '',
      bankFlag: true,
      timer: '',
      timer2: '',
      vRules: {
        accType: [{ required: true, trigger: 'change', message: '请选择账户类型' }],
        bankCode: [{ required: true, trigger: 'blur', message: '请选择所属银行' }],
        bankUserName: [{ required: true, trigger: 'blur', message: '请填写户名' }],
        bankCard: [{ required: true, trigger: 'blur', message: '请填写银行卡号' }],
        mobilePhone: [{ required: true, trigger: 'blur', message: '请填写银行所留手机' }],
        cardNature: [{ required: true, trigger: 'blur', message: '请选择银行卡性质' }],
        bankSubNo: [{ required: true, trigger: 'blur', message: '请输入支行号' }],
        provinceCode: [{ required: true, trigger: 'change', message: '请选择省份' }],
        fileUploadIds: [{ required: true, trigger: 'blur', message: '请上传银行卡正反面' }]
      }
    };
  },
  mounted() {
  },
  created() {
    this.bankCustId = this.eId;
    this.initData();
  },
  methods: {
    accType_change(val) {
      this.cForm.accType = val;
    },
    cardNature_change(val) {
      this.cForm.cardNature = val;
    },
    bankCode_change(val, name) {
      this.cForm.bankCode = val;
      this.cForm.bankName = name;
    },
    onFileChange(ids = [], key) {
      this.$refs.cForm.validateField(key);
    },
    onEndupDateFovereverChange(checked) {
      this.cForm.endupDate = checked ? '2099-12-31' : '';
      this.$refs.cForm.validateField('endupDate');
    },
    province_change(val) {
      this.cForm.provinceCode = val;
      this.cForm.cityCode = '';
      this.cForm.bankSubNo = '';
      this.cityFlag = false;
      this.cityUrl = `/cap/capBankCustInfo/city/${val}`;
      this.timer = new Date().getTime();
      this.timer2 = new Date().getTime();
    },
    city_change(val) {
      this.cForm.cityCode = val;
      this.cForm.bankSubNo = '';
      this.bankFlag = false;
      this.bankUrl = `/cap/capBankCustInfo/bank/${val}/${this.cForm.bankCode}`;
      this.timer2 = new Date().getTime();
    },
    bank_change(val) {
      this.cForm.bankSubNo = val;
    },
    initData() {
      const that = this;
      doGet('/cap/account/getCustBank/' + this.bankCustId).then(res => {
        this.loading = false;
        if (res.data.code === 200) {
          that.cForm = res.data.data;
          console.info('🚀 ~ file:accEdit method: line:159 -----', JSON.stringify(this.cForm));
          this.enterId = that.cForm.enterId;
          // that.province_change(that.cForm.provinceCode);
          // that.city_change(that.cForm.cityCode);
        }
      }
      );
    },
    saveCard() {
      this.$refs.cForm.validate(valid => {
        if (valid) {
          this.loading = true;
          const data = {
            ...this.cForm,
            ...{
              enterId: this.enterId
            }
          };
          doPost('/cap/account/bindCard', data).then(res => {
            this.loading = false;

            if (res.data.code == 200) {
              this.$notify({
                title: '修改成功',
                type: 'success',
                duration: 2500
              });
              this.$parent.handleClose();
            } else {
            }
          }
          ).catch(e => {
            this.loading = false;
          });
        } else {
          return false;
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.tips {
  font-size: 13px;
  line-height: 1.2;
  color: #666;

  /deep/ .el-link {
    font-size: 13px;
  }
}

.selection-box {
  margin-top: 10px;
}

.inner-box {
  width: 720px;
  margin-left: auto;
  margin-right: auto;
}
</style>
