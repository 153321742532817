<template>
    <div>
        <div class="repay-section mt15 pl15 pr15 mb15">
            <avue-crud :data="attachmentList" :option="tableOption">
                <template slot="menu" slot-scope="{row}">
                    <DownloadFile :file-id="row.fileId">
                        <i class="el-icon-download" title="下载" />
                    </DownloadFile>
                </template>
            </avue-crud>
        </div>
    </div>
</template>

<script>
    import DownloadFile from '@/views/components/DownloadFile'
    export default {
        name: 'AttachmentCurd',
        components: { DownloadFile },
        props: {
            attachmentList: {
                type: Array,
                default() {
                    return []
                }
            }
        },
        data() {
            return {
                tableOption: tableOption
            }
        }
    }
    const tableOption = {
        page: false,
        indexLabel: '序号',
        stripe: true,
        align: 'left',
        addBtn: false,
        refreshBtn: false,
        header: false,
        menu: false,
        menuBtn: true,
        border: false,
       searchShowBtn:false,
 column: [{
            fixed: true,
            label: 'fileId',
            prop: 'fileId',
            hide: true,
            editDisplay: false,
            addDisplay: false
        },
            {
                label: '附件名称',
                prop: 'fileName'
            }, {
                label: '类型',
                prop: 'typeName',
                width: 180
            }, {
                label: '操作',
                prop: 'menu',
                width: 150
            }
        ]
    }
</script>

<style lang="scss" scoped>
    .repay-section {
        overflow: hidden;
        .repay-box {
            padding: 20px;
            box-sizing: border-box;
            border: 1px solid #dfdfdf;
            box-shadow: 0 0 5px rgba(0,0,0,0.1);
            min-height: 218px;
            overflow: hidden;
            .repay-ul {
                overflow: hidden;
                .repay-li {
                    float: left;
                    padding: 5px 0;
                    width: 50%;
                }
            }
        }
    }
</style>
