var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { staticClass: "card-box", attrs: { span: 24 } },
            [
              _c("el-card", [
                _c(
                  "div",
                  { attrs: { slot: "header" }, slot: "header" },
                  [
                    _c("span", [_vm._v("基本信息")]),
                    _c(
                      "el-button",
                      {
                        staticStyle: { float: "right" },
                        attrs: {
                          type: "danger",
                          icon: "el-icon",
                          size: "small",
                          plain: "",
                          "controls-position": "right",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleClean()
                          },
                        },
                      },
                      [_vm._v("清空缓存")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "el-table el-table--enable-row-hover el-table--medium",
                  },
                  [
                    _c(
                      "table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { cellspacing: "0" },
                      },
                      [
                        _c("tbody", [
                          _c("tr", [
                            _c("td", [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("Redis版本"),
                              ]),
                            ]),
                            _c("td", [
                              _vm.cache.info
                                ? _c("div", { staticClass: "cell" }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.cache.info.redis_version) +
                                        "\n                  "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("td", [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("运行模式"),
                              ]),
                            ]),
                            _c("td", [
                              _vm.cache.info
                                ? _c("div", { staticClass: "cell" }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.cache.info.redis_mode ==
                                            "standalone"
                                            ? "单机"
                                            : "集群"
                                        ) +
                                        "\n                  "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("td", [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("端口"),
                              ]),
                            ]),
                            _c("td", [
                              _vm.cache.info
                                ? _c("div", { staticClass: "cell" }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.cache.info.tcp_port) +
                                        "\n                  "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("td", [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("客户端数"),
                              ]),
                            ]),
                            _c("td", [
                              _vm.cache.info
                                ? _c("div", { staticClass: "cell" }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.cache.info.connected_clients
                                        ) +
                                        "\n                  "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                          _c("tr", [
                            _c("td", [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("运行时间(天)"),
                              ]),
                            ]),
                            _c("td", [
                              _vm.cache.info
                                ? _c("div", { staticClass: "cell" }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.cache.info.uptime_in_days) +
                                        "\n                  "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("td", [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("使用内存"),
                              ]),
                            ]),
                            _c("td", [
                              _vm.cache.info
                                ? _c("div", { staticClass: "cell" }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.cache.info.used_memory_human
                                        ) +
                                        "\n                  "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("td", [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("使用CPU"),
                              ]),
                            ]),
                            _c("td", [
                              _vm.cache.info
                                ? _c("div", { staticClass: "cell" }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          parseFloat(
                                            _vm.cache.info
                                              .used_cpu_user_children
                                          ).toFixed(2)
                                        ) +
                                        "\n                  "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("td", [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("内存配置"),
                              ]),
                            ]),
                            _c("td", [
                              _vm.cache.info
                                ? _c("div", { staticClass: "cell" }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.cache.info.maxmemory_human) +
                                        "\n                  "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                          _c("tr", [
                            _c("td", [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("AOF是否开启"),
                              ]),
                            ]),
                            _c("td", [
                              _vm.cache.info
                                ? _c("div", { staticClass: "cell" }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.cache.info.aof_enabled == "0"
                                            ? "否"
                                            : "是"
                                        ) +
                                        "\n                  "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("td", [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("RDB是否成功"),
                              ]),
                            ]),
                            _c("td", [
                              _vm.cache.info
                                ? _c("div", { staticClass: "cell" }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.cache.info.rdb_last_bgsave_status
                                        ) +
                                        "\n                  "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("td", [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("Key数量"),
                              ]),
                            ]),
                            _c(
                              "td",
                              [
                                _vm.cache.dbSize
                                  ? _c("div", { staticClass: "cell" }, [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(_vm.cache.dbSize) +
                                          "\n                  "
                                      ),
                                    ])
                                  : _c("dir", { staticClass: "cell" }, [
                                      _vm._v("0"),
                                    ]),
                              ],
                              1
                            ),
                            _c("td", [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("网络入口/出口"),
                              ]),
                            ]),
                            _c("td", [
                              _vm.cache.info
                                ? _c("div", { staticClass: "cell" }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.cache.info
                                            .instantaneous_input_kbps
                                        ) +
                                        "kps/" +
                                        _vm._s(
                                          _vm.cache.info
                                            .instantaneous_output_kbps
                                        ) +
                                        "kps\n                  "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "card-box", attrs: { span: 12 } },
            [
              _c("el-card", [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _c("span", [_vm._v("命令统计")]),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "el-table el-table--enable-row-hover el-table--medium",
                  },
                  [
                    _c("div", {
                      ref: "commandstats",
                      staticStyle: { height: "420px" },
                    }),
                  ]
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "card-box", attrs: { span: 12 } },
            [
              _c("el-card", [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _c("span", [_vm._v("内存信息")]),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "el-table el-table--enable-row-hover el-table--medium",
                  },
                  [
                    _c("div", {
                      ref: "usedmemory",
                      staticStyle: { height: "420px" },
                    }),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }