<template>
  <div class="user">
    <basic-container>
      <avue-crud
        ref="crud"
        v-model="form"
        :option="option"
        :page.sync="page"
        :table-loading="listLoading"
        :before-open="handleOpenBefore"
        :data="list"
        @on-load="getList"
        @size-change="sizeChange"
        @current-change="currentChange"
        @search-change="handleFilter"
        @refresh-change="handleRefreshChange"
        @row-update="update"
        @row-save="create"
      >
        <template slot="menuLeft" />
        <template
          slot="menu"
          slot-scope="scope"
        />
      </avue-crud>
    </basic-container>
  </div>

</template>

<script>
import { doGet, doPost, doDelet, doPut } from '@/router/axios'
import { mapGetters } from 'vuex'

export default {
  name: 'TableUser',
  data() {
    return {
      option: tableOption,
      defaultProps: {
        label: 'name',
        value: 'id'
      },
      page: {
        total: 0, 		// 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, 	// 每页显示多少条,
        isAsc: false	// 是否倒序
      },
      list: [],
      listLoading: true,
      form: {}
    }
  },
  computed: {

  },
  watch: {

  },
  created() {

  },
  methods: {
    getList(page, params) {
      if (params == undefined || params == null || params == '') {
        params = {}
      }
      this.listLoading = true
      params.current = page.currentPage - 1
      params.size = page.pageSize
      doGet('/wf/wfTaskInfo/page', params).then(res => {
        this.list = res.data.data.dataList
        this.page.total = res.data.data.totalElements
        this.listLoading = false
      })
    },
    sizeChange(size) {
      this.page.pageSize = size
    },
    currentChange(current) {
      this.page.currentPage = current
    },
    handleFilter(param, done) {
      this.page.currentPage = 1
      this.getList(this.page, param)
      done()
    },
    handleRefreshChange() {
      this.getList(this.page)
    },
    handleOpenBefore(show, type) {
      window.boxType = type
      // 若是编辑、查看回显名称
      if (['edit', 'views'].includes(type)) {

      } else if (type === 'add') {

      }
      show()
    },
    handleUpdate(row, index) {
      this.$refs.crud.rowEdit(row, index)
    }
  }
}

export const tableOption = {
  border: false,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menualign: 'left',
  searchMenuSpan: 6,
  menuWidth: 120,
  editBtn: false,
  viewBtn: true,
  delBtn: false,
  align: 'left',
  addBtn: false,
 searchShowBtn:false,
 column: [{
    fixed: true,
    label: 'id',
    prop: 'id',
    span: 24,
    hide: true,
    viewDisplay: false,
    editDisplay: false,
    addDisplay: false
  },
  {
    fixed: true,
    label: '流程',
    prop: 'procId',
    hide: true,
    type: 'select',
    dicUrl: '/dict/type/D000060',
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: true,
      trigger: 'blur',
      message: '请输入流程'
    }
    ],
    search: true,
    span: 12
  },
  {
    fixed: true,
    label: '实例',
    prop: 'title',
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: true,
      trigger: 'blur',
      message: '请输入实例ID'
    },
    {
      min: '0',
      max: '32',
      message: '长度在 0 到 32 个字符',
      trigger: 'blur'
    }
    ],
    search: true,
    span: 12
  },
  {
    fixed: true,
    label: '节点ID',
    prop: 'nodeId',
    hide: true,
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: true,
      trigger: 'blur',
      message: '请输入节点ID'
    },
    {
      min: '0',
      max: '32',
      message: '长度在 0 到 32 个字符',
      trigger: 'blur'
    }
    ],
    span: 12
  },
  {
    fixed: true,
    label: '任务',
    prop: 'taskName',
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: true,
      trigger: 'blur',
      message: '请输入任务名称'
    },
    {
      min: '0',
      max: '150',
      message: '长度在 0 到 150 个字符',
      trigger: 'blur'
    }
    ],
    search: true,
    span: 12
  },
  {
    fixed: true,
    label: '任务编码',
    prop: 'taskCode',
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: true,
      trigger: 'blur',
      message: '请输入任务编码'
    },
    {
      min: '0',
      max: '20',
      message: '长度在 0 到 20 个字符',
      trigger: 'blur'
    }
    ],
    span: 12
  },
  {
    fixed: true,
    label: '提交人',
    prop: 'submitUserName',
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: true,
      trigger: 'blur',
      message: '请输入提交人'
    },
    {
      min: '0',
      max: '32',
      message: '长度在 0 到 32 个字符',
      trigger: 'blur'
    }
    ],
    span: 12
  },
  {
    fixed: true,
    label: '审批人',
    prop: 'approveUserName',
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: true,
      trigger: 'blur',
      message: '请输入审批人'
    },
    {
      min: '0',
      max: '32',
      message: '长度在 0 到 32 个字符',
      trigger: 'blur'
    }
    ],
    span: 12
  },
  {
    fixed: true,
    label: '待处理人',
    prop: 'unDoneUser',
    editDisplay: true,
    addDisplay: true,
    span: 12
  },
  {
    fixed: true,
    label: '提交时间',
    prop: 'submitTime',
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: true,
      trigger: 'blur',
      message: '请输入提交时间'
    }
    ],
    span: 12
  },
  {
    fixed: true,
    label: '完成时间',
    prop: 'finishTime',
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: true,
      trigger: 'blur',
      message: '请输入完成时间'
    }
    ],
    span: 12
  },
  {
    fixed: true,
    label: '审批意见',
    hide: true,
    prop: 'approveMsg',
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: true,
      trigger: 'blur',
      message: '请输入审批意见'
    },
    {
      min: '0',
      max: '150',
      message: '长度在 0 到 150 个字符',
      trigger: 'blur'
    }
    ],
    span: 12
  },
  {
    fixed: true,
    label: '状态',
    prop: 'status',
    type: 'select',
    dicUrl: '/dict/type/D000062',
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: true,
      trigger: 'blur',
      message: '请输入状态'
    }
    ],
    span: 12
  },
  {
    fixed: true,
    label: '待办URL',
    hide: true,
    prop: 'unDoneUrl',
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: true,
      trigger: 'blur',
      message: '请输入待办URL'
    },
    {
      min: '0',
      max: '200',
      message: '长度在 0 到 200 个字符',
      trigger: 'blur'
    }
    ],
    span: 12
  },
  {
    fixed: true,
    label: '已办URL',
    prop: 'doneUrl',
    hide: true,
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: true,
      trigger: 'blur',
      message: '请输入已办URL'
    },
    {
      min: '0',
      max: '200',
      message: '长度在 0 到 200 个字符',
      trigger: 'blur'
    }
    ],
    span: 12
  },
  {
    fixed: true,
    label: '业务ID',
    prop: 'bizId',
    hide: true,
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: true,
      trigger: 'blur',
      message: '请输入业务ID'
    },
    {
      min: '0',
      max: '32',
      message: '长度在 0 到 32 个字符',
      trigger: 'blur'
    }
    ],
    span: 12
  },
  {
    fixed: true,
    label: '创建时间',
    prop: 'createTime',
    editDisplay: false,
    addDisplay: false,
    span: 12
  },
  {
    fixed: true,
    label: '更新时间',
    hide: true,
    prop: 'updateTime',
    editDisplay: false,
    addDisplay: false,
    span: 12
  }
  ]
}

</script>
