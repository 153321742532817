<template>
  <div class="bk_white">
    <h2 class="mt30 ml50 mb30"><i class="el-icon-office-building mr10" />{{ loanDto.shopName }}--融资申请</h2>
    <el-form ref="form" :model="form" :rules="rules" :label-width="formLabelWidth" status-icon inline-message class="prbl40">
      <el-divider content-position="left" class="title-divider">店铺信息</el-divider>
      <el-row>
        <el-col :span="12">
          <el-form-item label="订单金额：" prop="cargoBusinessId">
            {{ loanDto.orderAmt }}元
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="最大可申请金额：" prop="cargoBusinessId">
            {{ loanDto.maxApplyAmt }}元
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="折扣率：" prop="cargoBusinessId">
            {{ loanDto.discountRate }} %
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="未来预计净回款：" prop="cargoBusinessId">
            {{ loanDto.orderAmt }} 元
          </el-form-item>
        </el-col>
      </el-row>

      <el-divider content-position="left" class="title-divider">订单明细</el-divider>
      <el-row>
        <el-col :span="24">
          <el-form-item>
            <avue-crud
              :data="OrderList"
              :option="orderOption"
              :page.sync="page"
              @search-change="searchChange"
              @on-load="getGoodsList"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider content-position="left" class="title-divider">收款银行卡</el-divider>
      <div style="padding-left: 90px">
        <el-row :gutter="20" class="bank-card-box">
          <el-col v-for="(item, index) in bankList" :key="index" :span="6" style="border-radius: 5px">
            <div :class="isActive === index ? 'isActive bank-card-item' : 'bank-card-item'" @click="changeAccount(item.id, index)">
              <div class="bcb-d">
                <div class="bcb-d-icon" />
                <div class="bcb-d-text">
                  <div class="f14">{{ item.bankName }}</div>
                  <div class="fb f22 mt10">{{ item.bankCard }}</div>
                </div>
              </div>
              <div class="bcb-subName f12 mt40">{{ item.bankSubName }}</div>
            </div>
          </el-col>
        </el-row>
      </div>

      <el-divider content-position="left" class="title-divider">贷款申请</el-divider>
      <el-row>
        <el-col :span="24">
          <el-form-item label="申请金额：" prop="applyAmt">
            <el-input
              v-model="form.applyAmt"
              type="number"
              @mousewheel.native.prevent
              @keyup.native="prevent($event)"
            >
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="附件列表：" prop="fileIds">
            <dict-upload v-model="form.fileIds" :limit="10" type-code="F0604" biz-id="123456" type="text" @change="onFileChange($event, 'fileIds')" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="申请信息：" prop="applyMsg">
            <el-input v-model="form.applyMsg" type="textarea" :autosize="{ minRows: 5}" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer tc">
      <el-button size="medium" class="mr20" @click="handleReset('voucherInfoForm')">重 置</el-button>
      <el-button size="medium" type="primary" :loading="btnLoading" @click="handleSave">提 交</el-button>
    </div>
  </div>
</template>

<script>
import DictUpload from '@/views/components/DictUpload';
import { doGet, doPost } from '@/router/axios';
import { getNewData, getYesterday } from '@/utils/date';
import DictSelect from '@/views/components/DictSelect';
import DictSelectMultiple from '@/views/components/DictSelectMultiple';
export default {
  name: 'VoucherInfo',
  components: { DictSelect, DictUpload, DictSelectMultiple },
  data() {
    return {
      query: { orderDate: getYesterday() }, // 设置查询的默认值
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条,
        isAsc: false // 是否倒序
      },
      isActive: 0,
      bankArray: [],
      bankList: [],
      selectedList: [],
      enterId: '',
      shopId: '',
      applyDate: '',
      orderOption: OrderOption,
      bankOption: bankOption,
      loanDto: {
      },
      formLabelWidth: '120px',
      form: {
        fileIds: []
      },
      OrderList: [],
      btnLoading: false,
      rules: {
        applyAmt: [
          { required: true, message: '请输入申请金额', trigger: 'blur' }
        ],
        applyMsg: [
          { required: true, message: '请输入申请信息', trigger: 'blur' }
        ],
        productId: [
          { required: true, message: '请选择产品', trigger: 'blur' }
        ]
      }
    };
  },
  computed: {

  },
  created() {
    this.enterId = this.$route.query.enterId;
    this.shopId = this.$route.query.shopId;
    this.applyDate = this.$route.query.applyDate;
    this.initEnterpise();
  },
  methods: {
    onLoad(page) {

    },
    numberChange(val, max) {
      this.$nextTick(() => {
        this.count = Math.min(parseInt(val), max);
      });
    },
    prevent(e) {
      var keynum = window.event ? e.keyCode : e.which; // 获取键盘码
      if (keynum == 189 || keynum == 190 || keynum == 109 || keynum == 110) {
        this.$message.warning('禁止输入小数以及负数');
        e.target.value = '';
      }
    },
    changeAccount(val, index) {
      this.isActive = index;
      this.form.accountId = val;
    },
    initEnterpise() {
      const that = this;
      doGet('/shop/info/getShopLoan/' + this.shopId + '/' + this.applyDate).then(res => {
        if (res.data.code == 200) {
          that.loanDto = res.data.data;
          that.bankList = res.data.data.bankList;
          this.form.accountId = that.bankList[0].id;
        }
      });
    },
    getGoodsList(page, params) {
      if (params == undefined || params == null || params == '') {
        params = {};
      }
      this.listLoading = true;
      params.current = page.currentPage - 1;
      params.size = page.pageSize;
      params.shopId = this.shopId;
      doPost('/shop/goodsOrder/applyPage', params).then(res => {
        this.OrderList = res.data.data.dataList;
        this.page.total = res.data.data.totalElements;
        this.listLoading = false;
      });
    },
    handleReset() {
      this.$refs.form.resetFields();
    },
    handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          const data = this.form;
          data.enterId = this.enterId;
          data.applyDate = this.applyDate;
          data.shopId = this.loanDto.shopId;
          data.accountId = this.form.accountId;
          doPost('/rent/order/apply', data).then(res => {
            if (res.data.code === 200) {
              this.$notify.success('申请成功');
              this.btnLoading = false;
              this.btnSave = '确 定';
              this.$router.push({ path: '/loan/index' });
            } else {
              this.btnLoading = false;
              this.$notify.error(res.data.msg);
              this.btnSave = '确 定';
            }
          }).catch(() => {
            this.btnSave = '确 定';
            this.btnLoading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    product_change(val) {
      this.form.productId = val;
    },
    selectionChange(list) {
      if (list.length) {
        if (list.length > 1) {
          const nowVal = list.shift();
          this.$refs.ProductCrud.toggleRowSelection(nowVal, false);
        } else if (list.length === 1) {
          this.selectedList = list;
        } else {
          this.$message.warning('只能选择一条数据进行操作!');
        }
      } else {
        this.$message.warning('请选择一条数据进行操作!');
      }
    },
    onFileChange(ids = [], key) {
      this.$refs.voucherInfoForm.validateField(key);
    },
    searchReset() {
      this.query = { orderDate: '2023-05-23' }; // 清空搜索条件时再查询，直接设置查询得默认值
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      done();
    }
  }
};

const bankOption = {
  border: false,
  align: 'left',
  menualign: 'left',
  menuWidth: '120px',
  editBtn: false,
  addBtn: false,
  selection: true,
  clear: false,
  header: false,
  indexLabel: '序号',
  menu: false,
 searchShowBtn:false,
 column: [
    {
      label: '户名',
      prop: 'bankUserName',
      width: '400'
    },
    {
      label: '卡号',
      prop: 'bankCard',
      width: '300'
    },
    {
      label: '所属支行',
      prop: 'bankSubName'
    }
  ]
};
const OrderOption = {
  border: false,
  align: 'left',
  menualign: 'left',
  menuWidth: '120px',
  editBtn: false,
  addBtn: false,
  clear: false,
  header: false,
  indexLabel: '序号',
  menu: false,
  searchMenuSpan: 6,
 searchShowBtn:false,
 column: [
    {
      label: '商品',
      prop: 'goodsName',
      width: '400'
    },
    {
      label: '实付金额',
      prop: 'payAmt',
      width: '300'
    },
    {
      label: '可贷金额',
      prop: 'loanAmt'
    },
    {
      label: '购买日期',
      prop: 'buyTime',
      type: 'date',
      format: 'yyyy-MM-dd',
      span: 6
    }
  ]
};
</script>

<style lang="scss" scoped>
.title_h3 {
  height: 35px;
  font: 600 16px/35px "Microsoft YaHei";
  color: #333;
  border-bottom: 1px solid #dedede;
}
.dialog-footer {
  margin: 20px auto;
}
.dict-upload.text .el-upload {
  width: auto;
}
.bank-card-box {
  padding: 20px 30px;
  cursor: pointer;

  .bank-card-item {
    padding: 20px;
    border-radius: 5px;
    background: #efefef;
    .bcb-d {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .bcb-d-icon {
        width: 60px;
        height: 60px;
        margin-right: 20px;
        background: url("~@/assets/icon/icon-bankcard.png") 0 0 no-repeat;
        background-size: 100%;
        filter:brightness(0);
        opacity: 10%;
      }
      .bcb-d-text {
        color: #aaa;
      }
    }
    .bcb-subName {
      color: #aaa;
    }
  }
  .isActive {
    background: #5A5D64 url("~@/assets/bg/bk-card.png") 0 0 no-repeat;
    background-size: 100%;
    .bcb-d {
      .bcb-d-icon {
        filter: none;
        opacity: 1;
      }
      .bcb-d-text {
        color: #fff;
      }
    }
    .bcb-subName {
      color: #1ebb2b;
    }
  }
}
</style>
