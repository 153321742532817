var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "selection-box",
        staticStyle: { "margin-top": "0", padding: "0 20px" },
      },
      [
        _c("el-alert", {
          attrs: {
            title:
              "附件仅支持 JPG、JPEG、PNG、PDF 四种格式，单个文件大小不能超过2.0 MB",
            closable: false,
            type: "warning",
            "show-icon": "",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "selection-box",
        staticStyle: { "margin-top": "0", padding: "0 20px" },
      },
      [
        _c(
          "el-form",
          {
            ref: "cForm",
            attrs: {
              model: _vm.cForm,
              rules: _vm.vRules,
              "label-position": "right",
              "label-width": "120px",
              size: "small",
            },
          },
          [
            _c("el-alert", {
              staticClass: "mt10",
              attrs: { title: "银行卡绑定", closable: false, type: "info" },
            }),
            _c(
              "div",
              { staticClass: "mt10 inner-box" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "账户类型", prop: "accType" } },
                  [
                    _c("dict-select", {
                      attrs: { no: "D000111", placeholder: "请选择账户类型" },
                      on: { change: _vm.accType_change },
                      model: {
                        value: _vm.cForm.accType,
                        callback: function ($$v) {
                          _vm.$set(_vm.cForm, "accType", $$v)
                        },
                        expression: "cForm.accType",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "所属银行", prop: "bankCode" } },
                  [
                    _c("dict-select", {
                      attrs: { no: "D000109", placeholder: "请选择所属银行" },
                      on: { change: _vm.bankCode_change },
                      model: {
                        value: _vm.cForm.bankCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.cForm, "bankCode", $$v)
                        },
                        expression: "cForm.bankCode",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "选择支行：", prop: "provinceCode" } },
                  [
                    _c(
                      "div",
                      { staticClass: "fl", staticStyle: { width: "120px" } },
                      [
                        _c("DictSelectSingle", {
                          attrs: {
                            clearable: false,
                            url: "/cap/capBankCustInfo/province",
                            placeholder: "请选择省份",
                          },
                          on: { change: _vm.province_change },
                          model: {
                            value: _vm.cForm.provinceCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.cForm, "provinceCode", $$v)
                            },
                            expression: "cForm.provinceCode",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "fl ml10 mr10",
                        staticStyle: { width: "120px" },
                      },
                      [
                        _vm.cForm.cityCode || !_vm.cityFlag
                          ? _c("DictSelectSingle", {
                              key: _vm.timer,
                              attrs: {
                                url: _vm.cForm.cityCode
                                  ? `/cap/capBankCustInfo/city/${_vm.cForm.provinceCode}`
                                  : _vm.cityUrl,
                                clearable: false,
                                placeholder: "请选择市",
                              },
                              on: { change: _vm.city_change },
                              model: {
                                value: _vm.cForm.cityCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.cForm, "cityCode", $$v)
                                },
                                expression: "cForm.cityCode",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "fl", staticStyle: { width: "260px" } },
                      [
                        _vm.cForm.bankSubNo || !_vm.bankFlag
                          ? _c("DictSelectSingle", {
                              key: _vm.timer2,
                              attrs: {
                                url: _vm.cForm.bankSubNo
                                  ? `/cap/capBankCustInfo/bank/${_vm.cForm.cityCode}/${_vm.cForm.bankCode}`
                                  : _vm.bankUrl,
                                placeholder: "请选择支行",
                                clearable: false,
                              },
                              on: { change: _vm.bank_change },
                              model: {
                                value: _vm.cForm.bankSubNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.cForm, "bankSubNo", $$v)
                                },
                                expression: "cForm.bankSubNo",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "户名", prop: "bankUserName" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入户名" },
                      model: {
                        value: _vm.cForm.bankUserName,
                        callback: function ($$v) {
                          _vm.$set(_vm.cForm, "bankUserName", $$v)
                        },
                        expression: "cForm.bankUserName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "银行卡号", prop: "bankCard" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入银行卡号" },
                      model: {
                        value: _vm.cForm.bankCard,
                        callback: function ($$v) {
                          _vm.$set(_vm.cForm, "bankCard", $$v)
                        },
                        expression: "cForm.bankCard",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "银行支行号", prop: "bankCard" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入银行支行号", readonly: "" },
                      model: {
                        value: _vm.cForm.bankSubNo,
                        callback: function ($$v) {
                          _vm.$set(_vm.cForm, "bankSubNo", $$v)
                        },
                        expression: "cForm.bankSubNo",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "银行手机号", prop: "mobilePhone" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入银行所留手机号" },
                      model: {
                        value: _vm.cForm.mobilePhone,
                        callback: function ($$v) {
                          _vm.$set(_vm.cForm, "mobilePhone", $$v)
                        },
                        expression: "cForm.mobilePhone",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "银行卡性质", prop: "cardNature" } },
                  [
                    _c("dict-select", {
                      attrs: { no: "D000110", placeholder: "请选择银行卡性质" },
                      on: { change: _vm.cardNature_change },
                      model: {
                        value: _vm.cForm.cardNature,
                        callback: function ($$v) {
                          _vm.$set(_vm.cForm, "cardNature", $$v)
                        },
                        expression: "cForm.cardNature",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "银行卡照片", prop: "fileUploadIds" } },
                  [
                    _c("dict-upload", {
                      attrs: {
                        type: "image",
                        limit: 5,
                        "type-code": "F0103",
                        "biz-id": "123456",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.onFileChange($event, "fileUploadIds")
                        },
                      },
                      model: {
                        value: _vm.cForm.fileUploadIds,
                        callback: function ($$v) {
                          _vm.$set(_vm.cForm, "fileUploadIds", $$v)
                        },
                        expression: "cForm.fileUploadIds",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "tc" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "medium",
                      loading: _vm.loading,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.saveCard()
                      },
                    },
                  },
                  [_vm._v("绑定")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }