<template>
  <avue-form-design style="height: 100vh;" ref="form" :options="option" :showGithubStar="false" :toolbar="toolbar">
    <template slot="toolbar-left">
      <el-button type="text"
                 icon="el-icon-download"
                 @click="handleGetData">保存</el-button>
    </template>
  </avue-form-design>
</template>

<script>
  export default {
    name: 'octopus-form-design',
    props: {
      formDesignId: String
    },
    watch: {
      formDesignId: {
        handler(newVal, oldVal) {
          this.option = {}
          this.init(newVal)
        },
        immediate: true
      }
    },
    data() {
      return {
        option: {},
        toolbar: ['preview', 'clear'],
      }
    },
    mounted() {
    },
    methods: {
      init() {
        // 根据id查询请求接口，拿到返回数据后，填充设计器
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        setTimeout(() => {
          const option = `{column:[{title:'警告',component:'el-alert',labelWidth:'0px',span:24,tips:'看我：自定义事件怎么用？',params:{title:'警告警告警告警告',type:'success'},event:{close:() => {console.log("alert关闭事件")}},prop:'1604994693264_45719'},{type:'input',label:'单行文本',span:24,display:true,prop:'1604994714440_81340'},{type:'textarea',label:'多行文本',span:24,display:true,prop:'1604994716622_2069'},{type:'checkbox',label:'多选框组',dicData:[{label:'选项一',value:'0'},{label:'选项二',value:'1'},{label:'选项三',value:'2'}],span:24,display:true,props:{label:'label',value:'value'},prop:'1604994720075_23876'}],labelPosition:'left',labelSuffix:'：',labelWidth:120,gutter:0,menuBtn:true,submitBtn:true,submitText:'提交',emptyBtn:true,emptyText:'清空',menuPosition:'center'}`
          this.option = eval("(" + option + ")")
          loading.close()
        }, 1500);
      },
      handleGetData() {
        this.$refs.form.getData('string').then(data => {
          this.$message.success("查看控制台")
          console.log(data)
          this.processSave(data)
        })
      },
      processSave(data) {
        this.$emit('processSave', data)
      },
    }
  }
</script>

<style lang="scss" scoped>

</style>
