

import request from '@/router/axios'

export function fetchDeptTree (query) {
  return request({
    url: '/dept/user-tree',
    method: 'get',
    params: query
  })
}

export function fetchTree (query) {
  return request({
    url: '/dept/tree',
    method: 'get',
    params: query
  })
}
export function selectTree(query) {
  return request({
    url: '/dept/selectTree',
    method: 'get',
    params: query
  })
}


export function addObj (obj) {
  return request({
    url: '/dept',
    method: 'post',
    data: obj
  })
}

export function getObj (id) {
  return request({
    url: '/dept/' + id,
    method: 'get'
  })
}

export function delObj (id) {
  return request({
    url: '/dept/' + id,
    method: 'delete'
  })
}

export function putObj (obj) {
  return request({
    url: '/dept',
    method: 'post',
    data: obj
  })
}

export function getdetails (obj) {
  return request({
    url: '/dept/details/' + obj,
    method: 'get'
  })
}

