var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dict-upload", class: _vm.type === "text" ? "text" : "" },
    [
      _c(
        "el-upload",
        {
          ref: "elUploadRef",
          class: {
            uoloadSty: _vm.showBtnImg,
            disUoloadSty: _vm.noneBtnImg || _vm.disabled,
          },
          attrs: {
            action: "http-request",
            limit: _vm.limit,
            multiple: _vm.multiple,
            "list-type": "image" === _vm.type ? "picture-card" : "text",
            "file-list": _vm.fileList,
            "on-change": _vm.onChange,
            "http-request": _vm.httpRequest,
            "on-success": _vm.onSuccess,
            "on-exceed": _vm.onExceed,
            "on-error": _vm.onError,
            "on-remove": _vm.onRemove,
            accept: _vm.limitFileType,
          },
        },
        [
          [
            _c("div", [
              _vm._v("\n        拍照识别 "),
              _c("i", { staticClass: "el-icon-upload f20" }),
            ]),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }