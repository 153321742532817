<template>
  <div class="user full-condition">
    <basic-container>
      <avue-crud
        ref="crud"
        v-model="form"
        :option="option"
        :page.sync="page"
        :table-loading="listLoading"
        :data="list"
        @on-load="getList"
        @size-change="sizeChange"
        @current-change="currentChange"
        @search-change="handleFilter"
        @search-reset="resetFilter"
        @refresh-change="handleRefreshChange"
        @row-update="update"
        @row-save="create"
      >
        <template slot="menuLeft">
          <el-button
            class="filter-item"
            type="primary"
            icon="el-icon-plus"
            @click="$refs.crud.rowAdd()"
            >新增
          </el-button>
        </template>

        <template slot="menu" slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-edit"
            @click="handleUpdate(scope.row, scope.index)"
            >修改</el-button
          >
          <el-button
            type="text"
            icon="el-icon-delete"
            style="color: #f00"
            @click="deletes(scope.row, scope.index)"
            >删除</el-button
          >
        </template>
      </avue-crud>
    </basic-container>
  </div>
</template>

<script>
import { doGet, doPost, doDelet, doPut } from "@/router/axios";
const tableOption = {
  searchMenuSpan: 24,
  searchLabelWidth: 80,
  searchMenuPosition: "center",
  border: false,
  selection: false,
  showSummary: false,
  sumColumnList: [],
  printBtn: false,
  index: false,
  indexLabel: "序号",
  stripe: true,
  menuAlign: "center",
  menuWidth: 160,
  labelWidth: 100,
  editBtn: false,
  delBtn: false,
  align: "center",
  addBtn: false,
 searchShowBtn:false,
 column: [
    {
      fixed: true,
      label: "id",
      prop: "id",
      span: 24,
      hide: true,
      editDisplay: false,
      addDisplay: false,
    },
    {
      label: "编码",
      prop: "code",
      editDisplay: true,
      addDisplay: true,
      rules: [
        {
          required: true,
          trigger: "blur",
          message: "请输入编码",
        },
      ],
      span: 12,
    },
    {
      label: "名称",
      prop: "name",
      editDisplay: true,
      addDisplay: true,
      rules: [
        {
          required: true,
          trigger: "blur",
          message: "请输入名称",
        },
      ],
      search: true,
      searchSpan: 8,
      span: 12,
    },
    {
      label: "状态",
      prop: "status",
      type: "select",
      dicUrl: "/dict/type/D000120",
      editDisplay: true,
      addDisplay: true,
      rules: [
        {
          required: true,
          trigger: "blur",
          message: "请输入状态",
        },
      ],
      search: true,
      searchSpan: 8,
      span: 12,
    },
    {
      label: "类型 ",
      prop: "type",
      type: "select",
      dicUrl: "/dict/type/D000121",
      editDisplay: true,
      addDisplay: true,
      rules: [
        {
          required: true,
          message: "请输入类型 ",
        },
      ],
      search: true,
      searchSpan: 8,
      span: 12,
    },
    {
      label: "备注",
      prop: "remark",
      type: "textarea",
      editDisplay: true,
      addDisplay: true,
      rules: [
        {
          required: true,
          trigger: "blur",
          message: "请输入备注",
        },
      ],
      span: 24,
    },
    {
      label: "创建时间",
      prop: "createTime",
      editDisplay: false,
      addDisplay: false,
      rules: [
        {
          required: false,
          message: "请输入创建时间",
        },
      ],
      hide: true,
      span: 12,
    },
    {
      label: "更新时间",
      prop: "updateTime",
      editDisplay: false,
      addDisplay: false,
      rules: [
        {
          required: false,
          message: "请输入更新时间",
        },
      ],
      hide: true,
      span: 12,
    },
  ],
};

export default {
  name: "TableUser",
  components: {},
  data() {
    return {
      option: tableOption,
      searchItmes: {},
      page: {
        total: 0, // 总页数
        currentPage: 1,
        pageSize: 10,
      },
      list: [],
      listLoading: true,
      form: {},
    };
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    getList(page, params) {
      params = params ? params : { ...this.searchItmes };
      params.current = page.currentPage - 1;
      params.size = page.pageSize;
      this.listLoading = true;
      doGet("/cap/accountCode/queryPage", params).then(({ data }) => {
        if (data) {
          const { dataList, totalElements } = data.data || {};
          this.list = dataList || [];
          this.page.total = totalElements || 0;
        }
        this.listLoading = false;
      });
    },

    sizeChange(size) {
      this.page.pageSize = size;
    },
    currentChange(current) {
      this.page.currentPage = current;
    },
    handleFilter(param, done) {
      this.page.currentPage = 1;
      this.searchItmes = param ? param : {};
      this.getList(this.page, param);
      done();
    },
    resetFilter() {
      this.page.currentPage = 1;
      this.searchItmes = {};
      this.getList(this.page, this.searchItmes);
    },
    handleRefreshChange() {
      this.getList(this.page, this.searchItmes);
    },

    handleUpdate(row, index) {
      this.$refs.crud.rowEdit(row, index);
    },
    deletes(row) {
      this.$confirm("此操作将永久删除选择数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.listLoading = true;
        doDelet("/cap/accountCode/delete/" + row.id)
          .then((res) => {
            if (res.data.code === 200) {
              this.getList(this.page);
              this.$notify.success("删除成功");
            }
            this.listLoading = false;
          })
          .catch(() => {
            this.listLoading = false;
          });
      });
    },

    create(row, done, loading) {
      this.listLoading = true;
      const params = {
        ...this.form,
      };
      doPost("/cap/accountCode/save", params)
        .then((res) => {
          if (res.data.code === 200) {
            this.getList(this.page);
            done();
            this.$notify.success("创建成功");
            return false;
          }
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    update(row, index, done, loading) {
      const params = {
        ...this.form,
      };
      doPut("/cap/accountCode/update", params)
        .then((res) => {
          if (res.data.code === 200) {
            this.getList(this.page);
            done();
            this.$notify.success("更新成功");
            return;
          }
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
  },
};
</script>
