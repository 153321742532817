<template>
  <div class="repay-section mt20">
    <avue-crud :data="billForm.deatilList" :option="tableOption"></avue-crud>
  </div>
</template>

<script>
const tableOption = {
  page: false,
  indexLabel: '序号',
  stripe: true,
  align: 'left',
  addBtn: false,
  editBtn: false,
  delBtn: false,
  menuWidth: 80,
  refreshBtn: false,
  header: false,
  menu: false,
  menuBtn: false,
  tip: false,
 searchShowBtn:false,
 column: [
    {
      fixed: true,
      label: 'id',
      prop: 'id',
      hide: true,
      editDisplay: false,
      addDisplay: false,
    },
    {
      label: '商品',
      width: 300,
      prop: 'goodsName',
    },
    {
      label: '起息日',
      format: 'yyyy-MM-dd',
      prop: 'startDate',
    },
    {
      label: '实还本金',
      prop: 'prcAmt',
    },
    {
      label: '实还利息',
      prop: 'fundAmt',
    },
    {
      label: '实还费用',
      prop: 'feeAmt',
    },
  ],
}

export default {
  name: 'RepaymentSchedule',
  props: {
    billForm: { type: Object },
  },
  data() {
    return {
      tableOption: tableOption,
    }
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.repay-section {
  overflow: hidden;
}
</style>
