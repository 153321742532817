<template>
<!-- -------start----待删除 -->
  <div>
    <div class="selection-box">
      <el-alert
        title="附件仅支持 JPG、JPEG、PNG、PDF 四种格式，单个文件大小不能超过2.0 MB"
        :closable="false"
        type="warning"
        show-icon
        class="mt10"
      />
    </div>

    <div class="selection-box">
      <el-form ref="cForm" :model="cForm" :rules="vRules" :label-position="'right'" label-width="160px" size="small">
        <el-alert title="基本信息" :closable="false" type="info" class="mt10" />
        <div class="mt10 inner-box">
          <el-form-item label="营业执照" prop="fileBusinessId">
            <dict-upload v-model="cForm.fileBusinessId"  type="image" :limit="1" type-code="F0202" biz-id="123456" @change="onFileChange($event, 'fileBusinessId')" />
            <el-link type="primary" :underline="false" :href="require('@/assets/auth/three_in_one.jpg')" target="_blank" class="ml10">
              统一信用证号照样图
            </el-link>
          </el-form-item>
          <el-form-item label="公司名称" prop="name">
            <el-input v-model="cForm.name" placeholder="请输入公司名称" />
          </el-form-item>
          <el-form-item label="营业执照号" prop="creditCode">
            <el-input v-model="cForm.creditCode" placeholder="一般为18/15位数字或字母组合" />
          </el-form-item>
          <el-form-item label="营业期限" prop="endupDate">
            <template >
              <el-date-picker
                v-model="cForm.expireDateAttr"
                value-format="yyyy-MM-dd"
                type="daterange"
                start-placeholder="起始日期"
                end-placeholder="结束日期"
              />
              <span style="margin-right: 30px;" />
            </template>
          </el-form-item>

        </div>

        <!--资质信息-->
        <el-alert title="法人信息" :closable="false" type="info" class="mt10" />
        <div class="mt10 inner-box">
          <el-form-item label="身份证（人像面）"  prop="legalFilePeopleId">
            <dict-upload v-model="cForm.legalFilePeopleId" type="image" :limit="1" type-code="F0204" biz-id="123456" @change="onFileChange($event, 'legalFilePeopleId')" />
          </el-form-item>
          <el-form-item label="身份证（国徽面）" prop="legalFileReverseId">
            <dict-upload v-model="cForm.legalFileReverseId" type="image" :limit="1" type-code="F0203" biz-id="123456" @change="onFileChange($event, 'legalFileReverseId')" />
          </el-form-item>
          <el-form-item label="法人姓名：" prop="legalName">
            <el-input v-model="cForm.legalName" placeholder="请输入法人姓名" />
          </el-form-item>
          <el-form-item label="法人身份证号：" prop="legalIdCard">
            <el-input v-model="cForm.legalIdCard" placeholder="一般为18/15位数字或字母组合" />
          </el-form-item>
          <el-form-item label="法人手机号：" prop="legalPhone">
            <el-input v-model="cForm.legalPhone" placeholder="请输入法人手机号" />
          </el-form-item>
        </div>

        <!--法人信息-->
        <el-alert title="授权人信息" :closable="false" type="info" class="mt10" />
        <div class="mt10 inner-box">
          <el-form-item label="身份证（人像面）" prop="authFilePeopleId">
            <dict-upload v-model="cForm.authFilePeopleId" type="image" :limit="1" type-code="F0206" biz-id="123456" @change="onFileChange($event, 'legalFilePeopleId')" />
          </el-form-item>
          <el-form-item label="身份证（国徽面）" prop="authFileReverseId">
            <dict-upload v-model="cForm.authFileReverseId" type="image" :limit="1" type-code="F0205" biz-id="123456" @change="onFileChange($event, 'legalFileReverseId')" />
          </el-form-item>
          <el-form-item label="授权人姓名：" prop="authName">
            <el-input v-model="cForm.authName" placeholder="请输入法人姓名" />
          </el-form-item>
          <el-form-item label="授权人身份证号：" prop="authIdCard">
            <el-input v-model="cForm.authIdCard" placeholder="一般为18/15位数字或字母组合" />
          </el-form-item>
          <el-form-item label="授权人手机号：" prop="authPhone">
            <el-input v-model="cForm.authPhone" placeholder="请输入法人手机号" />
          </el-form-item>
        </div>

        <!--联系信息-->
        <el-alert title="实控人信息" :closable="false" type="info" class="mt10" />
        <div class="mt10 inner-box">
          <el-form-item label="身份证（人像面）" prop="actualFilePeopleId">
            <dict-upload v-model="cForm.actualFilePeopleId" type="image" :limit="1" type-code="F0207" biz-id="123456" @change="onFileChange($event, 'legalFilePeopleId')" />
          </el-form-item>
          <el-form-item label="身份证（国徽面）" prop="actualFileReverseId">
            <dict-upload v-model="cForm.actualFileReverseId" type="image" :limit="1" type-code="F0208" biz-id="123456" @change="onFileChange($event, 'legalFileReverseId')" />
          </el-form-item>
          <el-form-item label="实控人姓名：" prop="actualName">
            <el-input v-model="cForm.actualName" placeholder="请输入法人姓名" />
          </el-form-item>
          <el-form-item label="实控人身份证号：" prop="actualIdCard">
            <el-input v-model="cForm.actualIdCard" placeholder="一般为18/15位数字或字母组合" />
          </el-form-item>
          <el-form-item label="实控人手机号：" prop="actualPhone">
            <el-input v-model="cForm.actualPhone" placeholder="请输入法人手机号" />
          </el-form-item>
        </div>

        <div class="tc">
          <el-button type="primary" size="medium" :loading="loading" @click="saveEnterprie()">保存</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import DictUpload from '@/views/components/DictUpload.vue';
import DictSelect from '@/views/components/DictSelect.vue';
import AddrSelect from '@/components/AddrSelect/index.vue';
import { doGet, doPost } from '@/router/axios';

export default {
  name: 'CompanyIndex',
  components: { DictUpload, DictSelect, AddrSelect },
  data() {
    return {
      loading: false,
      province: '',
      city: '',
      address: '',
      leagalDateFoverever: false, // 证件有效期是否长期有效
      cForm: {
        id: '',
        expireDateAttr: [], // 营业期限
        name: '',
        businessLicense: '',
        regitAddressPre: [],
        regitAddress: '',
        engageAddressPre: [],
        expireDate: [],
        leagalDate: [],
        legalCardExpire: '',
        legalName: '',
        legalIdCard: '',
        legalPhone: '',
        fileCardReverseIdStr: [],
        fileCardFrontIdStr: [],
        endupDateFoverever: Boolean // 永久有效，传'2099-12-31'
      },
      vRules: {
        fileBusinessId: [{ required: true, trigger: 'change', message: '请上传统一社会信用代码' }],
        name: [{ required: true, trigger: 'blur', message: '请填写企业名称' }],
        creditCode: [{ required: true, trigger: 'blur', message: '请填写统一信用编号' }],
        leagalDate: [{ required: true, trigger: 'blur', message: '请选择证件有效期' }],
        legalFilePeopleId: [{ required: true, trigger: 'blur', message: '请上传法人身份证人像页' }],
        legalFileReverseId: [{ required: true, trigger: 'blur', message: '请上传法人身份证国徽页' }],
        legalName: [{ required: true, trigger: 'blur', message: '请填写法人姓名' }],
        legalIdCard: [{ required: true, trigger: 'blur', message: '请填写法人身份证号' }],
        legalPhone: [{ required: true, trigger: 'blur', message: '请填写法人手机号' }],

        authFilePeopleId: [{ required: true, trigger: 'blur', message: '请上传授权人身份证人像页' }],
        authFileReverseId: [{ required: true, trigger: 'blur', message: '请上传授权人身份证国徽页' }],
        authName: [{ required: true, trigger: 'blur', message: '请填写授权人姓名' }],
        authIdCard: [{ required: true, trigger: 'blur', message: '请填写授权人身份证号' }],
        authPhone: [{ required: true, trigger: 'blur', message: '请填写授权人手机号' }],

        actualFilePeopleId: [{ required: true, trigger: 'blur', message: '请上传实控人身份证人像页' }],
        actualFileReverseId: [{ required: true, trigger: 'blur', message: '请上传实控人身份证国徽页' }],
        actualName: [{ required: true, trigger: 'blur', message: '请填写实控人姓名' }],
        actualIdCard: [{ required: true, trigger: 'blur', message: '请填写实控人身份证号' }],
        actualPhone: [{ required: true, trigger: 'blur', message: '请填写实控人手机号' }]
      }
    };
  },
  mounted() {
  },
  methods: {
    onFileChange(ids = [], key) {
      this.$refs.cForm.validateField(key);
    },
    onEndupDateFovereverChange(checked) {
      this.cForm.endupDate = checked ? '2099-12-31' : '';
      this.$refs.cForm.validateField('endupDate');
    },
    onLegalDateFovereverChange(checked) {
      if (checked) {
        this.cForm.leagalDate = ['2021-01-01', '2099-12-31'];
        this.leagalDateFoverever = true;
      } else {
        this.cForm.leagalDate = [];
        this.leagalDateFoverever = false;
      }
    },
    saveEnterprie() {
      this.$refs.cForm.validate(valid => {
        if (valid) {
          this.loading = true;
          const data = {
            ...this.cForm,
            ...{
              expireDate: this.cForm.expireDateAttr[1],
              regditDate: this.cForm.expireDateAttr[0]
            }
          };

          delete data.regitAddressPre;
          delete data.engageAddressPre;
          delete data.endupDateFoverever;
          console.log('提交的参数-->' + JSON.stringify(data));
          doPost('/cust/enter/saveEnterprise', data).then(res => {
            this.loading = false;
            if (res.data.code == 200) {
              this.$notify({
                title: '保存成功',
                type: 'success',
                duration: 2500
              });
              this.$router.push({ path: '/mineShop/companylist' });
            } else {
              this.$notify({
                title: res.data.msg,
                type: 'error',
                duration: 2500
              });
            }
          }
          ).catch(e => {
            this.$notify({
              title: e,
              type: 'error',
              duration: 2500
            });
            this.loading = false;
          });
        } else {
          return false;
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.tips {
  font-size: 13px;
  line-height: 1.2;
  color: #666;

  /deep/ .el-link {
    font-size: 13px;
  }
}

.selection-box {
  margin-top: 10px;
}

.inner-box {
  width: 720px;
  margin-left: auto;
  margin-right: auto;
}
</style>
