<template>
  <avue-crud
    ref="crud"
    :option="option"
    :page.sync="page"
    :table-loading="listLoading"
    :data="list"
    @on-load="getList"
    @size-change="sizeChange"
    @current-change="currentChange"
    @search-change="handleFilter"></avue-crud>
</template>

<script>
import { doPost } from '@/router/axios'
const tableOption = {
  border: false,
  index: false,
  stripe: true,
  indexLabel: '序号',
  align: 'left',
  menu: false,
  refreshBtn: false,
  columnBtn: false,
  editBtn: false,
  delBtn: false,
  addBtn: false,
 searchShowBtn:false,
 column: [
    {
      label: '商品',
      prop: 'goodsName',
      minWidth: 400,
    },
    {
      label: '实付金额',
      prop: 'payAmt',
      width: 200,
    },
    {
      label: '可贷金额',
      prop: 'loanAmt',
      width: 200,
    },
    {
      label: '购买日期',
      prop: 'buyTime',
      type: 'date',
      format: 'yyyy-MM-dd',
      width: 160,
    },
  ],
}

export default {
  name: 'ApplyShopGoods',
  components: {},
  props: {
    modalData: {
      type: Object,
    },
  },
  data() {
    return {
      shopId: '',
      option: tableOption,
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条,
      },
      list: [],
      listLoading: true,
    }
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    getList(page, params) {
      params = params || {}
      params.current = page.currentPage - 1
      params.size = page.pageSize
      params.shopId = this.modalData.shopId
      params.applyDate = this.modalData.applyDate
      this.listLoading = true
      doPost('/shop/goodsOrder/applyPage', params).then(({ data }) => {
        if (data && data.data) {
          const { dataList, totalElements } = data.data
          this.list = dataList || []
          this.page.total = totalElements || 0
        }
        this.listLoading = false
      })
    },

    sizeChange(size) {
      this.page.pageSize = size
    },
    currentChange(current) {
      this.page.currentPage = current
    },
    handleFilter(param, done) {
      this.page.currentPage = 1
      this.getList(this.page, param)
      done()
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/ .el-tabs__nav-scroll {
  padding-left: 10px;
}
/deep/ .el-divider {
  background-color: #ecedef;
}
/deep/ .el-divider--horizontal {
  margin: 12px 0 24px;
}
/deep/ .el-dialog__body {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
/deep/ .el-card__body {
  .avue-crud__menu {
    display: none !important;
  }
}
</style>
