var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mz-layout-section full-condition" },
    [
      _c("el-divider"),
      _c(
        "el-row",
        { attrs: { gutter: 30 } },
        [
          _c("el-col", { attrs: { span: 6 } }, [
            _c("div", { staticClass: "grid-card bg-blue" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v("今日下级渠道渠道收益"),
              ]),
              _c("div", { staticClass: "number" }, [_vm._v("45278.02元")]),
            ]),
          ]),
          _c("el-col", { attrs: { span: 6 } }, [
            _c("div", { staticClass: "grid-card bg-red" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v("下级渠道近30日放款"),
              ]),
              _c("div", { staticClass: "number" }, [_vm._v("278.02万元")]),
            ]),
          ]),
          _c("el-col", { attrs: { span: 6 } }, [
            _c("div", { staticClass: "grid-card bg-orange" }, [
              _c("div", { staticClass: "title" }, [_vm._v("下级渠道累计放款")]),
              _c("div", { staticClass: "number" }, [_vm._v("578.02万元")]),
            ]),
          ]),
          _c("el-col", { attrs: { span: 6 } }, [
            _c("div", { staticClass: "grid-card bg-orange" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v("下级渠道本月放款111"),
              ]),
              _c("div", { staticClass: "number" }, [_vm._v("578.02万元")]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "grid-content" },
        [
          _c(
            "avue-crud",
            {
              ref: "crud",
              attrs: {
                option: _vm.option,
                page: _vm.page,
                "table-loading": _vm.listLoading,
                data: _vm.list,
              },
              on: {
                "update:page": function ($event) {
                  _vm.page = $event
                },
                "on-load": _vm.getList,
                "size-change": _vm.sizeChange,
                "current-change": _vm.currentChange,
                "search-change": _vm.handleFilter,
                "search-reset": _vm.resetFilter,
              },
            },
            [
              _c(
                "template",
                { slot: "menuRight" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      attrs: { type: "primary", icon: "el-icon-download" },
                    },
                    [_vm._v("导出明细")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }