<template>
  <!--<el-select v-model="selectKey" :placeholder="placeholder" style="width: 100%;" @change="change" :disabled="disabled"  :readonly="readonly" >-->
  <el-radio-group v-if="type === 'radio'" v-model="selectKey" disabled>
    <el-radio v-for="item in zones" :key="item.id" :label="item.id">{{ item.label }}</el-radio>
  </el-radio-group>
  <el-checkbox-group v-else-if="type === 'checkbox'" v-model="selectKey" disabled>
    <template v-if="isSlot">
      <slot :zones="zones" />
    </template>
    <template v-else>
      <el-checkbox v-for="item in zones" :key="item.id" :label="item.label" />
    </template>
  </el-checkbox-group>
  <el-select v-else v-model="selectKey" disabled :multiple="multiple" filterable clearable :placeholder="placeholder" style="width: 100%;" :disabled="disabled" @change="change">
    <el-option v-for="item in zones" :key="item.id" :label="item.label" :value="String(item.id)" />
  </el-select>
</template>

<script>
import { doGet } from '@/router/axios';
export default {
  name: 'DictSelect',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    isSlot: {
      type: Boolean,
      default: false
    },
    type: String,
    multiple: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    no: {
      type: String,
      required: true
    },
    initVa: {
      type: String,
      required: false,
      default: ''
    },
    value: {
      type: [String, Number, Array],
      default: ''
    },
    placeholder: {
      type: String,
      default: '请选择类型'
    }
  },
  data() {
    return {
      zones: []
    };
  },
  computed: {
    selectKey: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('change', val);
      }
    }
  },
  created() {
    this.getTree();
  },
  methods: {
    getTree() {
      doGet(`/common/queryDictItem?no=${this.no}&va=${this.initVa}`).then(res => {
        this.zones = res.data.data;
      });
    },
    change(val) {
      this.$emit('change', val);
    }
  }
};
</script>
