<template>
  <div class="selection-box">
    <section v-loading="isApiCreating">
      <!-- <el-divider content-position="left" class="title-divider">我的账户</el-divider> -->
      <section class="acc-container" v-loading="isAccLoading">
        <el-divider content-position="left" class="title-divider">渠道监管账户</el-divider>
        <div class="jg-acc" v-if="!isAccLoading">
          <!--开户成功，有数据-->
          <div class="corporate-box" v-for="item of 2" :key="item">
            <div class="corporate-item" :style="{ paddingTop: !isTrue ? 0 : '' }">
              <div v-if="isTrue">
                <div class="a_account c_title">
                  <div>广州市番禺区南村军瑞食品店</div>
                  <div>企业户</div>
                </div>
                <div class="c_number fc_blue">036000002000029591019</div>
                <div class="c_account">
                  <div class="c_account_bank">苏宁银行</div>
                  <div class="c_account_bank chand" @click="isTrue = false">账户详情</div>
                </div>

              </div>
              <div v-else>
                <div class="a_account ">
                  <div class="c_account_bank fb">账号： <span>036000002000029591019</span></div>
                  <div><i class="el-icon-close chand" @click="isTrue = true"></i></div>
                </div>
                <div class="a_account">
                  <div class="c_account_bank fb">所属银行： <span>苏宁银行</span></div>
                </div>
                <div class="a_account">
                  <div class="c_account_bank">开户支行： <span>江苏苏宁银行股份有限公司</span></div>
                </div>
                <div class="a_account">
                  <div class="c_account_bank">开户支行号： <span>323301000019</span></div>
                </div>
                <div class="a_account">
                  <div class="c_account_bank">开户支行地址： <span>江苏省南京市建邺区金融城4号楼</span></div>
                </div>

              </div>
            </div>
            <div class="corporate-item" v-if="item == 1">
              <div class="corporate-item-line">
                <div>
                  <div class="fc_333 fb">PIng++账户总余额</div>
                  <div class="mt10">5000</div>
                </div>
                <div>
                  <div class="fc_333 fb">冻结金额</div>
                  <div class="mt10 fc-red fb">5000</div>
                </div>
              </div>
              <div class="corporate-item-line mt10">
                <div>
                  <div class="fc_333 fb">可提现余额</div>
                  <div class="mt10 fc-green fb">5000元</div>
                </div>
                <div>
                  <el-button @click="linkToCapFlow">明细</el-button>
                  <el-button @click="linkToWidthCash" type="primary">提现</el-button>
                </div>
              </div>
            </div>
          </div>
          <div class="corporate-box">
            <div class="corporate-item">
              <p class="corporate-item-p pt10">您的公司尚未开通苏商银行监管账户，请开通</p>
              <div class="c_btn">
                <el-button type="primary" @click="handleBindAcc()">开通PIng++监管户</el-button>
              </div>
            </div>

          </div>
          <div class="corporate-box">
            <div class="corporate-item">
              <p class="corporate-item-p pt10">您的公司尚开通富友支付监管账户，请开通</p>
              <div class="c_btn">
                <el-button type="primary" @click="handleBindAcc()">富友支付监管账户</el-button>
              </div>
            </div>

          </div>
          <!--未开户或开户中，无数据-->
          <div class="none-corporate-box" v-if="resultStatus === '1'">
            <!-- ***********提示语 -->
            <span class="iconfont icon-shenhe f24"></span>
            <!-- 开户失败情况 -->
            <el-tooltip effect="dark" placement="top" :content="failMsg" v-if="resultStatus == '9'">
              <span class="mt10 acc-tip" style="color: #ff0000">
                开户失败，请联系客服（13205810068）
                <i class="el-icon-info"></i>
              </span>
            </el-tooltip>
            <!-- 富友短信验证 -->
            <el-tooltip effect="dark" placement="top" :content="fuyou.detailMsg" v-else-if="resultStatus == '24'">
              <span class="mt10 acc-tip" style="color: #409eff">
                账户待激活
                <i class="el-icon-info"></i>
                <br />
                {{ jgBankDto.sendMobilePhone }}
              </span>
            </el-tooltip>

            <!-- *************操作按钮 -->

          </div>
        </div>

        <el-divider content-position="left" class="title-divider">提现账户</el-divider>

        <div class="tx-acc" v-if="!isAccLoading">
          <!--有数据-->
          <div v-if="skList.length > 0" class="corporate-box">
            <div v-for="(item, index) in skList" :key="index" class="corporate-item">
              <div class="a_account c_title">
                  <div>周瑞军</div>
                  <div>个人户</div>
                </div>
              <div class="c_number">036000002000029591019</div>
              <div class="c_account">
                <div class="c_account_bank">工商银行</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>

    <el-drawer title="打款验证" :visible.sync="isShowDKYZDrawer" :destroy-on-close="true" :wrapper-closable="false"
      :size="'500px'" :before-close="handleClose">
      <activate-account :accInfos="currAccItem" />
    </el-drawer>

    <el-drawer title="短信验证" :visible.sync="isShowDXYZDrawer" :destroy-on-close="true" :wrapper-closable="false"
      :size="'500px'" :before-close="handleClose">
      <sms-code :accInfos="currAccItem" />
    </el-drawer>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
// import { Component } from 'vue-property-decorator' //ts用法
import { enumeration } from '@/utils/entity/enumeration'
import { doGet, doPost } from '@/router/axios'
// 子组件
import SvgWaves from '@/components/SvgWaves/index'
import DictSelect from '@/views/components/DictSelect.vue'
import DictSelectMultiple from '@/views/components/DictSelectMultiple.vue'
import activateAccount from '@/views/account/activateAccount'
import smsCode from '@/views/account/smsCode'
// Component.registerHooks(['beforeRouteLeave']) //ts用法

export default defineComponent({
  name: 'companyAccountInfo',
  components: { DictSelect, DictSelectMultiple, activateAccount, SvgWaves, smsCode },
  data() {
    return {
      enterList: [{
        name: 1,
      }], // 企业列表
      isTrue: true,
      jgBankDto: {}, // 对应企业监管账户
      skList: [{
        name: ''
      }], // 对应企业提现银行账户
      currentIndex: null,

      /** 当前选择企业信息 */
      isCorporateFlag: true,
      isApiCreating: false, //轮训请求加载控制
      isAccLoading: true, //切换企业获取账户信息
      resultStatus: '1',
      failMsg: '',
      isTabAction: false,
      currAccItem: '', //当前企业账户信息
      enterId: '',
      accountChannel: '',
      bankNameMap: {
        acct_lanhai: '蓝海',
        acct_fuiou: '厦门国际',
        acct_pingxx: '苏宁',
      },

      timerCount: null,
      isRouteEnter: false, // 是否进入当前路由页面
      isShowDXYZDrawer: false,
      isShowDKYZDrawer: false,

      /** 按企业顺序分配计时 */
      enterTemptimers: [],
      /** 富友 */
      fuyou: {
        detailMsg: '富友支付已向您的手机发送了一条验证短信，请按照提示操作，操作成功后请等待银行审核（T+1日）。',
        isSentMsg: false,
        sendTimerCount: 60,
      },
      msgTimerTask: null,
      jianSheType: 1
    }
  },
  created() { },
  mounted() {
    this.isRouteEnter = true
    this.getQueryEnter(true)
  },
  // beforeRouteLeave(to, from, next) {
  //   this.isRouteEnter = false
  //   this.completaPolling()
  //   this.completaMsg()
  //   next()
  // },
  destroyed() {
    this.isRouteEnter = false
    // this.completaPolling()
    // this.completaMsg()
  },
  methods: {
    /** 获取当前商户企业列表 */
    getQueryEnter(isInit) {
      doGet('/cust/enter/queryEnter').then(({ data }) => {
        if (data.code === 200) {
          // this.enterList = data.data || []
          // // 给每个企业分配短信计数开始时间，初始值默认为null
          // this.enterList.forEach(() => {
          //   this.enterTemptimers.push(null)
          // })
          // 根据url参数定位当前选中的企业
          const { enterId } = this.$route.query
          let idx = 0
          if (enterId) {
            const findI = this.enterList.findIndex((item) => item.id === enterId)
            idx = findI > -1 ? findI : 0
          }
          isInit && this.handleChangeCompany(this.enterList[idx], idx)
        }
      })
    },
    /** 获取对应企业监管账户和提现账户 */
    getAccount() {
      // 初始化账户数据
      if (this.isTabAction) {
        this.isTabAction = false
        this.jgBankDto = {}
        // this.skList = []
        this.resultStatus = ''
        this.isAccLoading = true
      }
      // 调取接口
      doGet('/cap/account/queryEnterAcc/' + this.enterId)
        .then(({ data }) => {
          if (data.code === 200) {
            // const { jgList, skList } = data.data || {}
            this.jgBankDto = jgList ? jgList[0] || {} : {} //监管账户
            // this.skList = skList || [] //提现账户
            // this.jgBankDto.checkStatus = '24' //test
            const { checkStatus, checkMsg } = this.jgBankDto
            this.resultStatus = checkStatus //账户状态
            this.failMsg = checkMsg
            data.data.isCcb ? this.jianSheType = data.data.isCcb : this.jianSheType = null
            // 没有离开当前路由页面
            if (this.isRouteEnter) {
              // 轮询,10s
              this.timerCount = setTimeout(() => {
                // 2:协议签署中，请稍候；3:正在人工审批中，请稍候；24:富友短信验证
                if (checkStatus == '2' || checkStatus == '3' || checkStatus == '24') {
                  this.getAccount()
                } else {
                  this.completaPolling()
                  // this.getQueryEnter(false)
                }
              }, 10 * 1000)
            }
          }
          this.isAccLoading = false
        })
        .catch(() => {
          this.isAccLoading = false
        })
    },
    /** 结束轮训 */
    completaPolling() {
      if (this.timerCount) {
        clearTimeout(this.timerCount)
        this.timerCount = null
      }
    },

    /** 富友----发送手机短信 */
    toSendMsg() {
      if (!this.fuyou.isSentMsg) {
        // 调接口
        this.fuyou.isSentMsg = true
        const sendData = {
          accountChannel: this.accountChannel,
          accountIn: this.jgBankDto.accountIn || '',
        }
        doPost('/cust/enterpriseAccount/sendMessage', sendData)
          .then(({ data }) => {
            if (data.code == 200) {
              this.enterTemptimers[this.currentIndex] = Date.now()
              this.calcSendCount()
              this.$notify.success('验证短信已发送，请注意查收')
            }
          })
          .catch(() => {
            this.fuyou.isSentMsg = false
            this.enterTemptimers[this.currentIndex] = null
          })
      } else {
        this.$notify.error('正在发送...')
      }
    },
    /** 发送验证码计时 */
    calcSendCount() {
      if (this.fuyou.sendTimerCount > 0) {
        this.fuyou.sendTimerCount--
        this.msgTimerTask = setTimeout(this.calcSendCount, 1000)
      } else {
        this.fuyou.isSentMsg = false
        this.fuyou.sendTimerCount = 60
        this.enterTemptimers[this.currentIndex] = null
        this.completaMsg()
      }
    },
    /** 关闭短信计时任务 */
    completaMsg() {
      if (this.msgTimerTask) {
        clearTimeout(this.msgTimerTask)
        this.msgTimerTask = null
      }
    },

    /** 切换企业 */
    handleChangeCompany(item, index) {
      this.completaPolling() //立马结束上一个轮询任务
      this.completaMsg() //清除短信定时任务
      if (this.currentIndex !== index) {
        this.currentIndex = index
        if (item) {
          this.isTabAction = true
          this.enterId = item.id
          this.accountChannel = item.accountChannel || ''
          this.isCorporateFlag = item.accStatus !== 0
          this.getAccount()
        } else {
          this.isAccLoading = false
        }
        // 判断当前企业账号之前是否有启动短信计时，并比较时间间隔，判断是否需重启短信定时任务
        const prevStartTime = this.enterTemptimers[index] || 0
        const nowTime = Date.now()
        const intervalSecds = Math.floor((nowTime - prevStartTime) / 1000)
        if (intervalSecds < 60) {
          this.fuyou.isSentMsg = true
          this.fuyou.sendTimerCount = 60 - intervalSecds
          this.calcSendCount()
        } else {
          this.fuyou.isSentMsg = false
          this.fuyou.sendTimerCount = 60
        }
      }
    },
    /** 去绑定账户 */
    handleBindAcc(accType) {
      // if (this.enterId) {
      //   let pathName = ''
      //   switch (accType) {
      //     case : //绑定监管账户
      //       if (this.resultStatus == '22') {
      //         // 蓝海银行绑卡信息有误，打款失败
      //         const { bankBingInfo = {}, serialNum } = this.jgBankDto
      //         const bankInfoStr = bankBingInfo ? JSON.stringify(bankBingInfo) : '{}'
      //         sessionStorage.setItem('prevBankinfos', bankInfoStr)
      //         const queryParams = `serialNum=${serialNum}&enterId=${this.enterId}&thirdChannel=${this.accountChannel}&isWakeUpBind=1`
      //         pathName = `/cap/bindingAccJG?${queryParams}`
      //       } else {
      //         pathName = `/cap/bindingAccJG?enterId=${this.enterId}&thirdChannel=${this.accountChannel}&type=${this.jianSheType}`
      //       }
      //       break
      //     case '06': //绑定提现银行卡
      //       pathName = `/cap/bindingAccount?enterId=${this.enterId}&thirdChannel=${this.accountChannel}`
      //       break
      //     default:
      //   }
      //   pathName && this.$router.push(pathName)
      //   return
      this.$router.push('/cap/bindingAccJG')
      // }
    },
    /** 去激活 */
    toActiveAcc() {
      const sendData = {
        custId: this.enterId,
        bankCustId: this.jgBankDto.bankCustId,
        type: this.accountChannel,
      }
      this.isApiCreating = true
      doPost('/cust/enterpriseAccount/create', sendData)
        .then(({ data }) => {
          if (data.code == 200) {
            const errorMsgs = ['请签章监管协议', '请签章保理合同']
            if (errorMsgs.includes(data.msg)) {
              this.$alert(data.msg, '提示', {
                confirmButtonText: '去签署协议',
                callback: (action) => {
                  if (action === 'confirm') {
                    this.$router.push({
                      path: '/companyActiving/deatil',
                      query: {
                        enterId: this.enterId,
                        active: 3,
                      },
                    })
                  }
                },
              })
            } else {
              this.$notify.success('激活成功')
              this.getAccount()
            }
          }
          this.isApiCreating = false
        })
        .catch(() => {
          this.isApiCreating = false
        })
    },
    /** 去验证 */
    toVerifyAcc(type) {
      // 当前监管户信息
      this.currAccItem = {
        ...this.jgBankDto,
        accountChannel: this.accountChannel,
        enterId: this.enterId,
      }
      // 短信验证
      if (type === 'sms') {
        this.isShowDXYZDrawer = true
        return
      }
      // 打款验证
      if (type === 'pay') {
        this.isShowDKYZDrawer = true
        return
      }
      // 免密签约
      if (type === 'free') {
        this.currAccItem.isFreeSign = true
        this.isShowDXYZDrawer = true
      }
    },
    /** 跳转明细 */
    linkToCapFlow(accId) {
      this.$router.push({ path: '/chan/AccountList' })
    },
    /** 跳转提现 */
    linkToWidthCash(accId) {
      this.$router.push({ path: '/chan/width' })
    },
    /** 关闭弹窗 */
    handleClose() {
      this.isShowDXYZDrawer = false
      this.isShowDKYZDrawer = false
      this.getAccount()
    },
  },
})
</script>

<style scoped lang="scss">
.acc-container {
  height: auto;
  min-height: 60px;

  .acc-tip {
    text-align: center;
  }

  .dis-act {
    color: #909399;
    background-color: #eee;
    border-color: #eee;
    cursor: not-allowed;
    margin: 0;

    i {
      font-style: normal;
      color: #f00;
    }
  }

  /deep/.el-loading-mask {
    top: -8px;
  }
}

/deep/ .inner-box {
  margin: 20px;
  width: auto;
}

/deep/ .el-button--small .iconfont {
  font-size: 12px !important;
}

/deep/ .account-list .account-list-item .tag {
  left: 0;
  right: auto;
  border-top-right-radius: 20px;
  border-top-left-radius: 12px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 0;
  font-weight: normal;
  font-size: 12px;
}

/deep/ .account-list .account-list-item {
  /*min-width: 120px;*/
  height: 75px;
  text-align: center;
  padding-top: 32px;
}

/deep/ .svg-waves {
  display: none;
}

/deep/ .account-list .account-list-item.actived {
  background-image: radial-gradient(circle at center, #4a98f9, #5bacfa);
}

/deep/ .account-list .account-list-item .success {
  background-color: #66cc66;
}

/deep/.account-list .account-list-item .warn {
  background-color: #ffcc33;
}
</style>
