var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ml30 mr30" },
    [
      _c("avue-crud", {
        ref: "crud",
        attrs: {
          option: _vm.option,
          page: _vm.page,
          "table-loading": _vm.listLoading,
          "before-open": _vm.handleOpenBefore,
          data: _vm.list,
        },
        on: {
          "update:page": function ($event) {
            _vm.page = $event
          },
          "on-load": _vm.getList,
          "size-change": _vm.sizeChange,
          "current-change": _vm.currentChange,
          "search-change": _vm.handleFilter,
          "selection-change": _vm.selectionChange,
          "refresh-change": _vm.handleRefreshChange,
        },
        scopedSlots: _vm._u([
          {
            key: "menuLeft",
            fn: function (scope) {
              return [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      icon: "el-icon-plus",
                      size: "small",
                      plain: "",
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleAdd()
                      },
                    },
                  },
                  [_vm._v("新增凭证")]
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.form,
          callback: function ($$v) {
            _vm.form = $$v
          },
          expression: "form",
        },
      }),
      _c(
        "div",
        { staticClass: "mt20 tc" },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-arrow-left", size: "medium" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleGoPrepStep()
                },
              },
            },
            [_vm._v("上一步")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-mouse", size: "medium" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleGoStep()
                },
              },
            },
            [_vm._v("下一步")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }