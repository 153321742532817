<template>
  <div class="acc-add">
    <el-alert type="alert-blue" show-icon :closable="false">
      <div slot="title">温馨提示：</div>
      <div>
        您还未绑定您的提现银行卡！请绑定
        <span class="topUp-realName-tip">企业同名对公账户！</span>
        <span class="topUp-realName-tip">个体工商户</span>
        可绑定法人银行卡。
      </div>
    </el-alert>

    <!-- 表单 -->
    <div class="selection-box" v-loading="isLoading">
      <el-form
        ref="formRef"
        :model="dataForm.model"
        :rules="dataForm.rules"
        :label-position="'right'"
        label-width="120px"
        size="small">
        <el-alert title="提现账户绑定" :closable="false" type="info" />
        <div class="mt20 inner-box">
          <el-form-item label="选择账户类型:" prop="cardNature">
            <dict-select
              v-model="dataForm.model.cardNature"
              no="D000110"
              placeholder="选择账户类型"
              @change="changeCardNature" />
          </el-form-item>

          <!-- 账户具体信息 -->
          <div class="bk_gray pt20 pr20 pb20">
            <el-form-item label="账户名称" prop="bankUserName">
              <el-input v-model="dataForm.model.bankUserName" placeholder="请输入户名" readonly />
            </el-form-item>
            <el-form-item label="账户号" prop="bankCard">
              <el-input v-model="dataForm.model.bankCard" placeholder="请输入银行卡号" />
            </el-form-item>
            <el-form-item label="所属银行" prop="bankCode">
              <dict-select
                v-model="dataForm.model.bankCode"
                no="D000109"
                placeholder="请选择所属银行"
                @change="changeBankCode" />
            </el-form-item>
            <el-form-item label="选择支行" prop="provinceCode">
              <DictSelectSingle
                v-model="dataForm.model.provinceCode"
                :clearable="false"
                url="/cap/capBankCustInfo/province"
                placeholder="请选择省份"
                style="width: 120px"
                @change="changeProvince" />
              &nbsp;
              <DictSelectSingle
                v-if="dataForm.model.provinceCode"
                v-model="dataForm.model.cityCode"
                :clearable="false"
                :url="cityUrl"
                :key="refreshCityDom"
                placeholder="请选择城市"
                style="width: 120px"
                @change="changeCity" />
              &nbsp;
              <DictSelectSingle
                v-if="dataForm.model.bankCode && dataForm.model.cityCode"
                v-model="dataForm.model.bankSubNo"
                :clearable="false"
                :url="bankUrl"
                :key="refreshBankDom"
                placeholder="请选择支行"
                style="width: 200px"
                @change="changeBank" />
            </el-form-item>
           
          
          </div>

          <div class="mt20" style="padding-left: 120px">
            <el-button type="primary" size="medium" :loading="isSubmiting" @click="saveCard()">确认绑定</el-button>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { doGet, doPost } from '@/router/axios'
import { isMobile, isVerifCode } from '@/utils/validate'
// 子组件
import DictSelect from '@/views/components/DictSelect.vue'
import DictSelectSingle from '@/views/components/DictSelectSingle'

export default {
  name: 'CompanyIndex',
  components: {
    DictSelect,
    DictSelectSingle,
  },
  props: {
    eId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoading: true,
      isSubmiting: false,
      isSending: false,
      isSentCode: false,
      sendTimerCount: 60,
      enterId: '',
      enterName: '',
      legalName: '',
      // 表单数据
      dataForm: {
        model: {
          cardNature: '',
          bankUserName: '',
          bankCard: '',
          bankCode: '',
          bankName: '',
          provinceCode: '',
          cityCode: '',
          bankSubNo: '',
          mobilePhone: '',
        },
        rules: {
          cardNature: [{ required: true, trigger: 'change', message: '请选择账户类型' }],
          bankUserName: [{ required: true, trigger: 'change', message: '请填写户名' }],
          bankCard: [{ required: true, trigger: 'blur', message: '请填写银行卡号' }],
          bankCode: [{ required: true, trigger: 'change', message: '请选择所属银行' }],
          provinceCode: [{ required: true, trigger: 'change', message: '请选择省市' }],
          bankSubNo: [{ required: true, trigger: 'change', message: '请输入支行号' }],
          mobilePhone: [
            { required: true, trigger: 'blur', message: '请填写银行所留手机' },
            {
              trigger: 'blur',
              validator: (field, value, cb) => {
                if (value && !isMobile(value)) {
                  cb(new Error('请输入正确的手机号码'))
                  return
                }
                cb()
              },
            },
          ],
        },
      },
      refreshCityDom: 0,
      refreshBankDom: 0,
      cityUrl: '',
      bankUrl: '',
    }
  },
  created() {
    const { enterId } = this.$route.query
    this.enterId = enterId || ''
    this.initData()
  },
  mounted() {},
  methods: {
    /** 获取企业名称和法人名称 */
    initData() {
      doGet('/cust/enter/getEnterprise/' + this.enterId)
        .then(({ data }) => {
          if (data.code == 200) {
            const {
              data: { name, legalName },
            } = data
            this.enterName = name || ''
            this.legalName = legalName || ''
          }
          this.isLoading = false
        })
        .catch((e) => {
          this.isLoading = false
        })
    },

    /** 修改账户类型 */
    changeCardNature(val) {
      this.dataForm.model.cardNature = val
      // 1：私户，2：公户
      if (val == 1) {
        this.dataForm.model.bankUserName = this.legalName
      } else if (val == 2) {
        this.dataForm.model.bankUserName = this.enterName
      } else {
        this.dataForm.model.bankUserName = ''
      }
    },
    /** 修改所属银行 */
    changeBankCode(val, name) {
      this.dataForm.model.bankCode = val
      this.dataForm.model.bankName = name
      this.dataForm.model.bankSubNo = ''
      this.bankUrl = `/cap/capBankCustInfo/bank/${this.dataForm.model.cityCode}/${val}`
      this.refreshBankDom = new Date().getTime()
    },
    /** 修改省份 */
    changeProvince(val) {
      this.dataForm.model.provinceCode = val
      this.dataForm.model.cityCode = ''
      this.dataForm.model.bankSubNo = ''
      this.cityUrl = `/cap/capBankCustInfo/city/${val}`
      this.refreshCityDom = new Date().getTime()
    },
    /** 修改城市 */
    changeCity(val) {
      this.dataForm.model.cityCode = val
      this.dataForm.model.bankSubNo = ''
      this.bankUrl = `/cap/capBankCustInfo/bank/${val}/${this.dataForm.model.bankCode}`
      this.refreshBankDom = new Date().getTime()
    },
    /** 修改支行 */
    changeBank(val) {
      this.dataForm.model.bankSubNo = val
    },

    /** 提交保存 */
    saveCard() {
      if (!this.isSubmiting) {
        this.$refs.formRef.validate((valid) => {
          if (valid) {
            const sendData = {
              ...this.dataForm.model,
              enterId: this.enterId,
              accType: '06',
            }
            this.isSubmiting = true
            doPost('/cap/account/bindCard', sendData)
              .then(({ data }) => {
                if (data.code == 200) {
                  this.$notify.success('绑卡成功')
                  this.$router.push({
                    path: '/cap/index',
                    query: { enterId: this.enterId }
                  })
                }
                this.isSubmiting = false
              })
              .catch((e) => {
                this.isSubmiting = false
              })
          }
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.selection-box {
  margin-top: 10px;
  .inner-box {
    width: 720px;
  }
}
</style>
