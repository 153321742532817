<template>
  <div class="auth-step-container">
    <div v-if="enterAuthDto.msg" class="selection-box mb20">
      <el-alert :title="enterAuthDto.msg" :closable="false" type="warning" show-icon />
    </div>

    <div class="selection-box">
      <el-steps :active="active" finish-status="success" simple class="mb20">
        <el-step title="上传营业执照" icon="el-icon-edit" />
        <el-step title="法人信息登记" icon="el-icon-upload" />
        <el-step title="企业认证" icon="el-icon-upload" />
        <el-step title="签署协议" icon="el-icon-upload" />
      </el-steps>

      <!--上传营业执照-->
      <BusinessLicenseInfo v-if="active === 0" ref="businessLicenseInfo" :e-id="enterId" @change="getEnterId" />
      <!--法人信息登记-->
      <LegalPersonInfo v-if="active === 1" ref="legalPersonInfo" :e-id="enterId" />
      <!--企业认证-->
      <EnterpriseCert v-if="active === 2" ref="enterpriseCert" :e-id="enterId" />
      <!--签署协议-->
      <SignDocument v-if="active === 3 || active == 5" ref="signDocument" :e-id="enterId" />
    </div>

    <div class="tc mt30">
      <el-button v-if="active == 1|| active ==2 || active ==3 || active ==5 " type="primary" @click="handleStepPrev">上一步</el-button>
      <el-button v-if="active == 0 || active ==1 || active ==2 " type="primary" @click="handleStepNext">下一步</el-button>
      <el-button v-if="active == 3 && lastSign == 0 " type="primary" @click="toSignLastDoc">签署协议</el-button>
      <el-button type="primary" @click="backToenterList">企业列表</el-button>
    </div>
  </div>
</template>

<script>
import { doGet } from '@/router/axios';
// 子组件
import BusinessLicenseInfo from './componens/businessLicenseInfo';
import LegalPersonInfo from './componens/legalPersonInfo';
import EnterpriseCert from './componens/enterpriseCert';
import SignDocument from './componens/signDocument';

export default {
  name: 'AuthStepDetail',
  components: {
    BusinessLicenseInfo,
    LegalPersonInfo,
    EnterpriseCert,
    SignDocument
  },
  props: {},
  data() {
    return {
      enterAuthDto: { status: '0', msg: '' },
      enterId: '',
      loading: false,
      leagalDateFoverever: false, // 证件有效期是否长期有效
      active: 0,
      authDto: {},
      signetDto: [],
      flag: '0', // 0: 展示协议 1: 展示审核阶段 2: 通过审核
      controlBtnFlag: 0,
      singFlag: 0,
      lastSign: -1, // 最后一步还需签署资金监管服务协议和保理合同
    };
  },
  watch: {},
  created() {
    const {enterId, active } = this.$route.query
    this.enterId = enterId || ''
    this.active = active ? Number(active) : 0
    // this.initStatus(this.enterId);
  },
  mounted() {},
  methods: {
    initStatus() {
      doGet('/cust/enter/authDeatil/' + this.enterId).then(({ data }) => {
        this.loading = false;
        if (data.code == 200) {
          this.active = data.data.active;
          this.controlBtnFlag = data.data.isAuth;
        }
      }).catch(e => {
        this.loading = false;
      });
    },

    /** 下一步 */
    handleStepNext() {
      this.active += 1;
    },
    /** 上一步 */
    handleStepPrev() {
      this.active -= 1;
    },
    /** 签署协议和合同 */
    toSignLastDoc() {
      this.$refs.signDocument.signEnter('supervise');
    },
    /** 返回列表 */
    backToenterList() {
      this.$router.push({ path: '/mineShop/companylist' });
    },
    getEnterId(val) {
      this.enterId = val;
    },

    /** 保存营业执照信息 */
    handleSaveCompanyInfo() {
      this.$refs.businessLicenseInfo.saveCompanyInfo();
    },
    /** 保存法人信息 */
    handleSaveLegal() {
      this.$refs.legalPersonInfo.saveCompanyInfo();
    },
    /** 保存企业认证信息 */
    handleSaveAuth() {
      this.$refs.enterpriseCert.saveCompanyInfo();
    },
    /** 签署授权书 */
    handleStartSign() {
      this.$refs.signDocument.signEnter('loan');
    },
    /** 签署授权 */
    handleSignLetter() {
      this.handleStepNext();
    },
    /**
     * 保存店铺信息
     */
    handleSaveShopInfo() {
      this.$refs.shopInfoRef.saveShopInfo();
    },
    goEnter() {
      this.$router.push({ path: '/mineShop/companylist' });
    },
    /**
     * 添加企业授权人
     */
    handleAddAuthorizer() {
      this.$refs.enterpriseCert.addAuth();
    },
    /**
     * 我是法人，开始认证！
     */
    handleStartCerti() {
      this.$refs.enterpriseCert.authEnter();
    }
  }
};
</script>

<style lang="scss" scoped>
.auth-step-container {
  margin-bottom: 7px;
}
.selection-box {
  border: 1px solid #F2F4F7;
  /deep/ .el-alert__title {
    color: #333;
    font-weight: 700;
  }
  .cForm {
    margin: 0 30px;
  }
}
/deep/ .el-divider--horizontal {
  width: auto;
}
/deep/ .el-form--label-left .el-form-item__label {
  padding-left: 15px;
}
</style>
