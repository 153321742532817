var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user" },
    [
      _c(
        "basic-container",
        [
          _c(
            "avue-crud",
            {
              ref: "crud",
              attrs: {
                option: _vm.option,
                page: _vm.page,
                "table-loading": _vm.listLoading,
                "before-open": _vm.handleOpenBefore,
                data: _vm.list,
              },
              on: {
                "update:page": function ($event) {
                  _vm.page = $event
                },
                "on-load": _vm.getList,
                "size-change": _vm.sizeChange,
                "current-change": _vm.currentChange,
                "search-change": _vm.handleFilter,
                "refresh-change": _vm.handleRefreshChange,
                "row-update": _vm.update,
                "row-save": _vm.create,
                "selection-change": _vm.selectionChange,
              },
              scopedSlots: _vm._u([
                {
                  key: "transAmount",
                  fn: function (scope) {
                    return [
                      _c(
                        "span",
                        [
                          _c("el-tag", [
                            _vm._v(_vm._s(scope.row.transAmount) + " "),
                          ]),
                          _vm._v("  \n        "),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "menu",
                  fn: function (scope) {
                    return [
                      _c("el-button", {
                        attrs: { type: "primary", icon: "el-icon-edit" },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(scope.row, scope.index)
                          },
                        },
                      }),
                      _c("el-button", {
                        attrs: { type: "danger", icon: "el-icon-delete" },
                        on: {
                          click: function ($event) {
                            return _vm.deletes(scope.row, scope.index)
                          },
                        },
                      }),
                    ]
                  },
                },
                {
                  key: "inOut",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-tag",
                        {
                          attrs: {
                            effect: "dark",
                            type:
                              scope.row.inOut === "1" ? "success" : "danger",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(scope.row.inOut === "1" ? "收入" : "支出")
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.form,
                callback: function ($$v) {
                  _vm.form = $$v
                },
                expression: "form",
              },
            },
            [_c("template", { slot: "menuLeft" })],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }