<template>
  <div class="selection-box">
    <!--法人信息-->
    <el-form ref="cForm" :model="cForm" :rules="vRules" :label-position="'left'" label-width="150px" size="small" class="cForm mt20">
      <div class="mt10 inner-box">
        <el-alert title="请上传法人身份证正反面照片" :closable="false" type="warning" show-icon />
        <el-form-item label="身份证（人像面）" prop="legalFilePeopleId" class="mt20">
          <dict-upload-info
            v-model="cForm.legalFilePeopleId"
            :disabled="disabled"
            type="image"
            :limit="1"
            type-code="F0204"
            biz-id="123456"
            url="cust/enter/upload"
            @change="onFileChange($event, 'legalFilePeopleId')"
            @getOcrInfo="handleSetOcrInfo"
          />
        </el-form-item>
        <el-form-item label="身份证（国徽面）" prop="legalFileReverseId">
          <dict-upload-info
            v-model="cForm.legalFileReverseId"
            :disabled="disabled"
            type="image"
            :limit="1"
            type-code="F0203"
            biz-id="123456"
            url="cust/enter/upload"
            @change="onFileChange($event, 'legalFileReverseId')"
            @getOcrInfo="handleSetOcrOppositeInfo"
          />
        </el-form-item>
       <!-- <el-form-item label="法人手持身份证照片" prop="legalFileHoldingId">
          <dict-upload-info
            v-model="cForm.legalFileHoldingId"
            :disabled="disabled"
            type="image"
            :limit="1"
            type-code="F0211"
            biz-id="123456"
            url="cust/enter/upload"
            @change="onFileChange($event, 'legalFileHoldingId')"
            @getOcrInfo="handleSetOcrOppositeInfo"
          />
        </el-form-item> -->
        <el-alert title="法人身份信息确认" :closable="false" type="info" show-icon />
        <el-form-item label="法人姓名：" prop="legalName" class="mt20">
          <el-input v-model="cForm.legalName" maxlength="20" :disabled="disabled" placeholder="请输入法人姓名" style="width: 350px" />
        </el-form-item>
        <el-form-item label="法人身份证号：" prop="legalIdCard">
          <el-input v-model="cForm.legalIdCard" maxlength="18" :disabled="disabled" placeholder="一般为18/15位数字或字母组合" style="width: 350px" />
        </el-form-item>
        <el-form-item label="法人身份证有效期：" prop="leagalDateAttr">
          <template>
            <el-date-picker
              v-if="!cForm.leagalDateAttrFlag"
              v-model="cForm.leagalDateAttr"
              :disabled="disabled"
              value-format="yyyy-MM-dd"
              type="daterange"
              start-placeholder="起始日期"
              end-placeholder="结束日期"
            />
            <!-- <span style="margin-right: 30px;" /> -->
            <!-- <el-checkbox v-model="cForm.leagalDateAttrFlag" :disabled="disabled" @change="handleChangeLegalExpire">无固定期限</el-checkbox> -->
          </template>
        </el-form-item>
        <el-form-item label="法人民族：" prop="legalNation">
          <el-input v-model="cForm.legalNation" :disabled="disabled" placeholder="请输入法人民族" style="width: 350px" />
        </el-form-item>
        <el-form-item label="发证机关：" prop="legalIssueLicence">
          <el-input v-model="cForm.legalIssueLicence" :disabled="disabled" placeholder="请输入发证机关" style="width: 350px" />
        </el-form-item>
        <el-alert title="法人联系方式" :closable="false" type="info" show-icon />
        <el-form-item label="法人手机号：" prop="legalPhone" class="mt20">
          <el-input v-model="cForm.legalPhone" maxlength="11" :disabled="disabled" placeholder="请输入法人手机号" style="width: 350px" />
        </el-form-item>
        <el-form-item label="法人邮箱：" prop="legalEmail">
          <el-input v-model="cForm.legalEmail" maxlength="30" :disabled="disabled" placeholder="请输入法人邮箱" style="width: 350px" />
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import DictUploadInfo from '@/views/components/DictUploadInfo.vue';
import { doGet, doPost } from '@/router/axios';
import { regionData } from 'element-china-area-data';
import { setDateFormat } from '@/utils/date';
export default defineComponent({
  name: 'companyInfo',
  components: { DictUploadInfo },
  props: {
    eId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      options: regionData, // 选择格式
      disabled: false,
      cForm: {
        leagalDateAttrFlag: false,
        enterId: '',
        enterType: '',
        creditCode: '',
        leagalDateAttr: [],
        leagalDate: [],
        legalCardExpire: '',
        legalName: '',
        legalIdCard: '',
        nationality: '',
        legalIssueLicence: '',
        legalPhone: '',
        legalEmail: '',
        endupDateFoverever: Boolean // 永久有效，传'2099-12-31'
      },
      ocrInfo: {}, // 身份证正面信息
      ocrOppositeInfo: {}, // 身份证反面信息
      vRules: {
        leagalDate: [{ required: true, trigger: 'blur', message: '请选择证件有效期' }],
        legalFilePeopleId: [{ required: true, trigger: 'blur', message: '请上传法人身份证人像页' }],
        legalFileReverseId: [{ required: true, trigger: 'blur', message: '请上传法人身份证国徽页' }],
        // legalFileHoldingId: [{ required: true, trigger: 'blur', message: '请上传法人手持身份证照片' }],
        legalName: [{ required: true, trigger: 'blur', message: '请填写法人姓名' }],
        legalIdCard: [{ required: true, trigger: 'blur', message: '请填写法人身份证号' }],
        leagalDateAttr: [{ required: true, trigger: 'blur', message: '请选择法人身份证有效日期' }],
        legalNation: [{ required: true, trigger: 'blur', message: '请填写法人民族' }],
        legalIssueLicence: [{ required: true, trigger: 'blur', message: '请填写发证机关' }],
        legalPhone: [{ required: true, trigger: 'blur', message: '请填写法人手机号' }],
        legalEmail: [{ required: true, trigger: 'blur', message: '请填写法人邮箱' }]
      }
    };
  },
  created() {
    if (!this.validatenull(this.eId)) { // 判断enterId是否为空
      this.enterId = this.eId;
    }
    this.initData();
  },
  watch: {
    'cForm.legalFilePeopleId': function(newVal, oldVal) {
      // 法人姓名
      if (this.validatenull(this.cForm.legalName)) {
        this.cForm.legalName = this.ocrInfo.name;
        this.$refs['cForm'].clearValidate('legalName');
      }
      // 法人身份证号
      if (this.validatenull(this.cForm.legalIdCard)) {
        this.cForm.legalIdCard = this.ocrInfo.num;
        this.$refs['cForm'].clearValidate('legalIdCard');
      }
      // 法人民族
      if (this.validatenull(this.cForm.legalNation)) {
        this.cForm.legalNation = this.ocrInfo.nationality;
        this.$refs['cForm'].clearValidate('legalNation');
      }
    },
    'cForm.legalFileReverseId': function(newVal, oldVal) {
      const { legalIssueLicence, leagalDateAttr } = this.cForm
      // 发证机关
      if (this.validatenull(legalIssueLicence)) {
        this.cForm.legalIssueLicence = this.ocrOppositeInfo.issue;
        this.$refs['cForm'].clearValidate('legalIssueLicence');
      }
      // 法人身份证有效日期
      if (leagalDateAttr && leagalDateAttr.length) {
        const { start_date, end_date } = this.ocrOppositeInfo
        if (start_date && end_date) {
          const reg = /(\d{4})(\d{2})(\d{2})/
          const startDate = start_date.replace(reg, '$1-$2-$3')
          const endDate = end_date.replace(reg, '$1-$2-$3')
          this.cForm.leagalDateAttr = [startDate, endDate];
          this.$refs['cForm'].clearValidate('leagalDateAttr');
        }
      }
    },
  },
  methods: {
    initData() {
      const that = this;
      const id = this.enterId;
      if (id == undefined || id == null || id == '') {
        return;
      }
      doGet('/cust/enter/getEnterprise/' + id).then(res => {
        if (res.data.code === 200) {
          that.cForm = {
            ...res.data.data,
            leagalDateAttrFlag: res.data.data.legalIdcardEndDate === '2099-12-31',
            leagalDateAttr: ['', '']
          };
          if (that.cForm.legalIdcardStartDate != null && that.cForm.legalIdcardEndDate != null) {
            that.cForm.leagalDateAttr = [that.cForm.legalIdcardStartDate, that.cForm.legalIdcardEndDate];
          }
          if (that.cForm.isAuth === '1') {
            that.disabled = true;
          }
        }
      }
      ).catch(e => {
      });
    },

    handleChangeLegalExpire(flag) {
      if (flag) {
        this.cForm.leagalDateAttr = ['1900-01-01', '2099-12-31'];
      }
    },
    onFileChange(ids = [], key) {
      this.$refs.cForm.validateField(key);
    },
    handleSetOcrInfo(val) {
      this.ocrInfo = { ...val };
    },
    handleSetOcrOppositeInfo(val) {
      this.ocrOppositeInfo = { ...val };
    },
    saveCompanyInfo() {
      this.$refs.cForm.validate(valid => {
        if (valid) {
          this.loading = true;
          const data = {
            ...this.cForm,
            ...{
              legalIdcardStartDate: this.cForm.leagalDateAttr[0],
              legalIdcardEndDate: this.cForm.leagalDateAttr[1]
            },
            legalIdcardAddress: this.ocrInfo.address || '',
          };
          if (this.expireDateAttrFlag) {
            this.cForm.expireDate = '2099-12-31';
          }
          delete data.regitAddressPre;
          delete data.engageAddressPre;
          delete data.endupDateFoverever;
          data.id = this.enterId;
          doPost('/cust/enter/saveEnterprise', data).then(res => {
            this.loading = false;
            if (res.data.code == 200) {
              this.$emit('change', res.data.data.id);
              this.$parent.handleStepNext(); // 跳转到下一步
              this.$parent.controlBtnFlag = 0;
            }
          }).catch(e => {
            this.loading = false;
          });
        }
      });
    }
  }
});
</script>

<style scoped lang="scss">
.selection-box {
  border: 1px solid #F2F4F7;
  /deep/ .el-alert__title {
    color: #333;
    font-weight: 700;
  }
  .cForm {
    margin: 0 30px;
  }
}

.inner-box {
  //width: 720px;
  margin-left: auto;
  margin-right: auto;
}
.sub_title {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  &:before {
    content: '';
    margin-right: 10px;
    width: 4px;
    height: 18px;
    background: #2d8cf0;
    border-radius: 10px;
  }
}
/deep/ .el-divider--horizontal {
  width: auto;
}
/deep/ .el-form--label-left .el-form-item__label {
  padding-left: 15px;
}
</style>
