<template>
  <span>{{ label }}</span>
</template>

<script>
import { doGet } from '@/router/axios';
import { translateDict } from '@/utils/util';
export default {
  name: 'DictField',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    no: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number, Array],
      default: ''
    }
  },
  data() {
    return {
      label: ''
    };
  },
  created() {
    this.getDict();
  },
  methods: {
    getDict() {
      doGet(`/common/queryDictItem?no=${this.no}&va=${this.initVa}`).then(res => {
        this.label = translateDict(res.data.data, this.value);
      });
    }
  }
};
</script>
