var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("avue-crud", {
    ref: "crud",
    attrs: {
      option: _vm.option,
      page: _vm.page,
      "table-loading": _vm.listLoading,
      data: _vm.list,
    },
    on: {
      "update:page": function ($event) {
        _vm.page = $event
      },
      "on-load": _vm.getList,
      "size-change": _vm.sizeChange,
      "current-change": _vm.currentChange,
      "search-change": _vm.handleFilter,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }