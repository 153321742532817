<template>
  <div class="mz-layout-section full-condition flow-all">
    <div class="tableList_title">资金明细列表</div>
    <el-divider />

    <avue-crud
      ref="crud"
      :option="option"
      :page.sync="page"
      :table-loading="listLoading"
      :data="list"
      @on-load="getList"
      @size-change="sizeChange"
      @current-change="currentChange"
      @search-change="handleFilter"
      @search-reset="resetFilter">
      <template slot="inOut" slot-scope="scope">
        <el-tag effect="dark" :type="scope.row.inOut === '1' ? 'success' : 'danger'">
          {{ scope.row.inOut === '1' ? '收入' : '支出' }}
        </el-tag>
      </template>
       <template slot="transAmount" slot-scope="scope">
          <el-tag>{{ scope.row.transAmount }}</el-tag>
      </template>
    </avue-crud>
  </div>
</template>
<script>
import { doGet, doPost, doPut } from '@/router/axios'
const tableOption = {
  searchMenuSpan: 24,
  searchLabelWidth: 80,
  index: false,
  indexLabel: '序号',
  stripe: true,
  border: false,
  showSummary: false,
  sumColumnList: [],
  menualign: 'left',
  menuWidth: 160,
  searchIndex: 3,
  menu: false,
  header: false,
  searchIcon: true,
  searchShowBtn: false,
  labelWidth: 130,
  editBtn: false,
  delBtn: false,
  align: 'left',
  addBtn: false,
 searchShowBtn:false,
 column: [
    {
      label: '户名',
      prop: 'accName',
      minWidth: 200,
      fixed: true,
      editDisplay: true,
      addDisplay: true,
      search: true,
      searchPlaceholder: '请输入户名',
      span: 12,
      searchSpan: 8,
    },
    {
      label: '收支',
      prop: 'inOut',
      type: 'select',
      dicUrl: '/common/type/D000123',
      editDisplay: true,
      addDisplay: true,
      search: true,
      searchPlaceholder: '请选择收支',
      span: 12,
      searchSpan: 8,
    },
    {
      label: '科目',
      prop: 'ioName',
      width: 150,
      editDisplay: true,
      addDisplay: true,
      search: true,
      searchPlaceholder: '请输入科目',
      span: 12,
      searchSpan: 8,
    },
    {
      label: '交易金额',
      prop: 'transAmount',
      editDisplay: true,
      addDisplay: true,
      type: 'number',
      span: 12,
    },
    {
      label: '交易时间',
      prop: 'flowTime',
      width: 160,
      type: 'datetime',
      format: 'yyyy-MM-dd HH:mm:ss',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      editDisplay: true,
      addDisplay: true,
      span: 12,
    },
    {
      label: '摘要',
      prop: 'remark',
      minWidth: 180,
      type: 'textarea',
      editDisplay: true,
      addDisplay: true,
      rules: [
        {
          required: false,
          message: '请输入备注',
        },
      ],
      span: 24,
    },
    {
      label: '原金额',
      prop: 'bakTotleAmount',
      editDisplay: false,
      addDisplay: false,
      rules: [
        {
          required: false,
          message: '请输入原金额',
        },
      ],
      span: 12,
    },
    {
      label: '新金额',
      prop: 'newTotleAmount',
      editDisplay: false,
      addDisplay: false,
      rules: [
        {
          required: false,
          message: '请输入新金额',
        },
      ],
      span: 12,
    },
    {
      label: '是否检查',
      prop: 'isCheck',
      editDisplay: false,
      addDisplay: false,
      rules: [
        {
          required: false,
          message: '请输入是否检查',
        },
      ],
      hide: true,
      span: 12,
    },

    {
      label: '创建时间',
      prop: 'createTime',
      editDisplay: false,
      addDisplay: false,
      rules: [
        {
          required: false,
          message: '请输入创建时间',
        },
      ],
      hide: true,
      span: 12,
    },
    {
      label: '更新时间',
      prop: 'updateTime',
      editDisplay: false,
      addDisplay: false,
      rules: [
        {
          required: false,
          message: '请输入更新时间',
        },
      ],
      hide: true,
      span: 12,
    },
  ],
}

export default {
  name: 'FlowwAll',
  components: {},
  props: {
    accountId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      accId: '',
      option: tableOption,
      searchItmes: {},
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条,
      },
      list: [],
      listLoading: true,
    }
  },
  computed: {},
  watch: {},
  created() {
    this.accId = this.$route.query.accId
  },
  mounted() {},
  methods: {
    getList(page, params) {
      params = params ? params : { ...this.searchItmes }
      params.current = page.currentPage - 1
      params.size = page.pageSize
      params.accountId = this.accId
      this.listLoading = true
      doGet('/cap/accountFlow/queryPage', params).then(({ data }) => {
        if (data) {
          const { dataList, totalElements } = data.data || {}
          this.list = dataList || []
          this.page.total = totalElements || 0
        }
        this.listLoading = false
      })
    },

    sizeChange(size) {
      this.page.pageSize = size
    },
    currentChange(current) {
      this.page.currentPage = current
    },
    handleFilter(param, done) {
      this.page.currentPage = 1
      this.searchItmes = param ? param : {}
      this.getList(this.page, param)
      done()
    },
    resetFilter() {
      this.page.currentPage = 1
      this.searchItmes = {}
      this.getList(this.page, this.searchItmes)
    },
  },
}
</script>
<style lang="scss" scoped>
/deep/ .el-tabs__nav-scroll {
  padding-left: 10px;
}
/deep/ .el-divider {
  background-color: #ecedef;
}
</style>
