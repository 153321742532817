<template>
  <div class="pane-container">
	  <div class="title">{{ title }}</div>
	  <div class="pane-content">
      <slot></slot>
	  </div>
  </div>
</template>
<script>
export default {
  name: 'PaneContainer',
  props: {
    title: String
  }
}
</script>
<style lang="scss" scoped>
  .pane-container {
    border: 1px solid #E9E9E9;
    background: white;
    >.title {
      background: #FAFAFA;
      padding-left: 15px;
      height: 44px;
      line-height: 44px;
      border-bottom: 1px solid #E9E9E9;
      font-size: 14px;
    }
    .pane-content {
      padding: 20px 24px;
    }
  }
</style>