<template>
  <!--<el-select v-model="selectKey" :placeholder="placeholder" style="width: 100%;" @change="change" :disabled="disabled"  :readonly="readonly" >-->
  <el-select v-model="selectKey" filterable :clearable="clearable" :disabled="disabled" :placeholder="placeholder" style="width: 100%;" @change="change" @blur="blur">
    <el-option v-for="(item, index) in zones" :key="index" :label="item.label" :value="String(item.value)" :disabled="item.disabled" />
  </el-select>
</template>
<script>
import { doGet } from '@/router/axios';
export default {
  name: 'DictSelect',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    no: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: '/dict/type/'
    },
    initVa: {
      type: String,
      required: false,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    },
    placeholder: {
      type: String,
      default: '请选择类型'
    },
    isGetLabel: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectKey: String(this.value) || '',
      zones: [],
      zones_: []
    };
  },
  watch: {
    value(newValue, oldValue) {
      this.selectKey = String(newValue || '');
    },
    no(newValue, oldValue) {
      this.no = newValue;
      this.getTree();
    }
  },
  created() {
    this.getTree();
  },
  methods: {
    getTree() {
      const par = this.no;
      if (par.indexOf('D0') >= 0) {
        doGet(`${this.no}`).then(res => {
          this.zones = res.data.data;
        });
      } else {
        doGet(`${this.url}`).then(res => {
          this.zones = res.data.data;
        });
      }
    },
    change(val) {
      const selectedLabel = this.handleGetItemLabel(val);
      if (this.isGetLabel) {
        this.$emit('change', val, selectedLabel.label);
      } else {
        this.$emit('change', val);
      }
    },
    blur() {
      this.$emit('blur', this.selectKey);
    },
    handleGetItemLabel(val) {
      let selectedItem = {};
      selectedItem = this.zones.find((item) => {
        return item.value === val;
      });

      return selectedItem;
    },
    handleSetDisabled(val) {
      const that = this;
      that.zones = that.deepClone(that.zones_);
      that.zones.forEach(item => {
        if (item.value === val) {
          item.disabled = true;
        }
      });
    }
  }
};
</script>
