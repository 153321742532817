<template>
  <div>
    <div class="selection-box">
      <el-form ref="cForm" :model="cForm" :rules="vRules" :label-position="'right'" label-width="120px" size="small">
        <el-alert title="提现账户" :closable="false" type="info" class="mt10" />
        <el-form-item label="监管账户" class="mt20" prop="name">
          <el-select v-model="cForm.name">
            <el-option v-for="item in namelist" :key="item.value" :value="item.value" :label="item.label" />
          </el-select>
        </el-form-item>
        <el-alert title="账户详情" :closable="false" type="info" class="mt10" />
        <div class="corporate-box mt20">
          <div class="corporate-item">

            <div class="corporate-item-line mt10">
              <div>
                <div class="fc_333 fb">可提现余额</div>
                <div class=" mt15 mb15 f20 fc-green fb">5000元</div>
              </div>
            </div>
            <div class="corporate-item-line mt10">
              <div>
                <div class="fc_333 fb">到账银行账号</div>
                <div class="mt15  fb">招商银行 6725 6738 908 7625</div>
              </div>
            </div>
          </div>
        </div>

        <el-alert title="提现信息" :closable="false" type="info" class="mt10" />
        <el-form-item label="可提现金额:" prop="mm1" class="mt20">
          <span class="f16 fc-red fb">{{ balanceAmount }}</span>
          元
        </el-form-item>
        <el-form-item label="提现金额" prop="totalAmt">
          <el-input v-model="cForm.totalAmt" type="number" style="width: 50%" />
        </el-form-item>
        <div class="tc">
          <el-button type="primary" size="medium" :loading="isSubmiting" @click="handlerWithdraw()">提现</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
  import {
    doGet,
    doPost
  } from '@/router/axios'

  export default {
    name: 'CompanyIndex',
    components: {},
    data() {
      return {
        namelist: [{
          label: '1',
          value: 1
        }],
        isSubmiting: false,
        enterId: '',
        isActive: 0,
        bankArray: [],
        accArray: [],
        balanceAmount: 0,
        cForm: {
          widthCode: '1',
          name: '',
          totalAmt: '',
          bankMemo: '',
          reason: '',
        },
        vRules: {
          totalAmt: [{
            required: true,
            trigger: 'change',
            message: '提现金额不能为空'
          }],
          name: [{
            required: true,
            trigger: 'blur',
            message: '请选择监管账号'
          }],
        },
      }
    },
    created() {
      this.initBank()
    },
    mounted() {},
    methods: {
      initBank() {
        doGet('/cap/account/queryChanAcc').then(({
          data
        }) => {
          if (data && data.data) {
            this.bankArray = data.data.skList || []
            this.balanceAmount = data.data.balanceAmt || 0
          }
        })
      },

      changeAccount(val, index) {
        this.isActive = index
        this.cForm.bankCustId = val
      },
      handlerWithdraw() {
        doPost('/bium-channel/controller/fin-channel/va/withdraw', {
            "custAcctId": 1024,
            "amount": "202405"
          })
          .then((res) => {
            if (res.data.code == 200) {
              // this.$router.push({ path: '/chan/AccountList' })
            }
          })
          .catch((err) => {
            this.isSubmiting = false
          })
        return
        if (!this.isSubmiting) {
          if (!this.cForm.bankCustId) {
            this.$notify.error('请先选择监管账户')
            return
          }
          this.$refs.cForm.validate((valid) => {
            if (valid) {
              this.isSubmiting = true
              doPost('/order/widthdrawalInfo/apply', this.cForm)
                .then((res) => {
                  if (res.data.code == 200) {
                    this.$router.push({
                      path: '/chan/AccountList'
                    })
                  }
                })
                .catch((err) => {
                  this.isSubmiting = false
                })
            }
          })
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .bank-card-box {
    padding: 20px 30px;
    cursor: pointer;

    /*margin: 10px 30px 0;*/
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: flex-start;*/
    /*flex-wrap: wrap;*/
    .bank-card-item {
      /*width: 380px;*/
      /*height: 200px;*/
      padding: 20px;
      background: #5a5d64 url('~@/assets/bg/bk-card.png') 0 0 no-repeat;
      background-size: 100%;
      border-radius: 5px;
      border: 4px solid #fff;

      .bcb-d {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .bcb-d-icon {
          width: 60px;
          height: 60px;
          margin-right: 20px;
          background: url('~@/assets/icon/icon-bankcard.png') 0 0 no-repeat;
          background-size: 100%;
        }
      }
    }

    .isActive {
      border: 5px solid #d7a022;
    }
  }
</style>
