var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "selection-box" },
    [
      _c(
        "el-form",
        {
          ref: "cForm",
          staticClass: "cForm mt20",
          attrs: {
            model: _vm.cForm,
            rules: _vm.vRules,
            "label-position": "left",
            "label-width": "150px",
            size: "small",
          },
        },
        [
          _c(
            "div",
            { staticClass: "mt10 inner-box" },
            [
              _c("el-alert", {
                attrs: {
                  title: "请上传法人身份证正反面照片",
                  closable: false,
                  type: "warning",
                  "show-icon": "",
                },
              }),
              _c(
                "el-form-item",
                {
                  staticClass: "mt20",
                  attrs: {
                    label: "身份证（人像面）",
                    prop: "legalFilePeopleId",
                  },
                },
                [
                  _c("dict-upload-info", {
                    attrs: {
                      disabled: _vm.disabled,
                      type: "image",
                      limit: 1,
                      "type-code": "F0204",
                      "biz-id": "123456",
                      url: "cust/enter/upload",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.onFileChange($event, "legalFilePeopleId")
                      },
                      getOcrInfo: _vm.handleSetOcrInfo,
                    },
                    model: {
                      value: _vm.cForm.legalFilePeopleId,
                      callback: function ($$v) {
                        _vm.$set(_vm.cForm, "legalFilePeopleId", $$v)
                      },
                      expression: "cForm.legalFilePeopleId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "身份证（国徽面）",
                    prop: "legalFileReverseId",
                  },
                },
                [
                  _c("dict-upload-info", {
                    attrs: {
                      disabled: _vm.disabled,
                      type: "image",
                      limit: 1,
                      "type-code": "F0203",
                      "biz-id": "123456",
                      url: "cust/enter/upload",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.onFileChange($event, "legalFileReverseId")
                      },
                      getOcrInfo: _vm.handleSetOcrOppositeInfo,
                    },
                    model: {
                      value: _vm.cForm.legalFileReverseId,
                      callback: function ($$v) {
                        _vm.$set(_vm.cForm, "legalFileReverseId", $$v)
                      },
                      expression: "cForm.legalFileReverseId",
                    },
                  }),
                ],
                1
              ),
              _c("el-alert", {
                attrs: {
                  title: "法人身份信息确认",
                  closable: false,
                  type: "info",
                  "show-icon": "",
                },
              }),
              _c(
                "el-form-item",
                {
                  staticClass: "mt20",
                  attrs: { label: "法人姓名：", prop: "legalName" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "350px" },
                    attrs: {
                      maxlength: "20",
                      disabled: _vm.disabled,
                      placeholder: "请输入法人姓名",
                    },
                    model: {
                      value: _vm.cForm.legalName,
                      callback: function ($$v) {
                        _vm.$set(_vm.cForm, "legalName", $$v)
                      },
                      expression: "cForm.legalName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "法人身份证号：", prop: "legalIdCard" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "350px" },
                    attrs: {
                      maxlength: "18",
                      disabled: _vm.disabled,
                      placeholder: "一般为18/15位数字或字母组合",
                    },
                    model: {
                      value: _vm.cForm.legalIdCard,
                      callback: function ($$v) {
                        _vm.$set(_vm.cForm, "legalIdCard", $$v)
                      },
                      expression: "cForm.legalIdCard",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "法人身份证有效期：",
                    prop: "leagalDateAttr",
                  },
                },
                [
                  [
                    !_vm.cForm.leagalDateAttrFlag
                      ? _c("el-date-picker", {
                          attrs: {
                            disabled: _vm.disabled,
                            "value-format": "yyyy-MM-dd",
                            type: "daterange",
                            "start-placeholder": "起始日期",
                            "end-placeholder": "结束日期",
                          },
                          model: {
                            value: _vm.cForm.leagalDateAttr,
                            callback: function ($$v) {
                              _vm.$set(_vm.cForm, "leagalDateAttr", $$v)
                            },
                            expression: "cForm.leagalDateAttr",
                          },
                        })
                      : _vm._e(),
                  ],
                ],
                2
              ),
              _c(
                "el-form-item",
                { attrs: { label: "法人民族：", prop: "legalNation" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "350px" },
                    attrs: {
                      disabled: _vm.disabled,
                      placeholder: "请输入法人民族",
                    },
                    model: {
                      value: _vm.cForm.legalNation,
                      callback: function ($$v) {
                        _vm.$set(_vm.cForm, "legalNation", $$v)
                      },
                      expression: "cForm.legalNation",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "发证机关：", prop: "legalIssueLicence" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "350px" },
                    attrs: {
                      disabled: _vm.disabled,
                      placeholder: "请输入发证机关",
                    },
                    model: {
                      value: _vm.cForm.legalIssueLicence,
                      callback: function ($$v) {
                        _vm.$set(_vm.cForm, "legalIssueLicence", $$v)
                      },
                      expression: "cForm.legalIssueLicence",
                    },
                  }),
                ],
                1
              ),
              _c("el-alert", {
                attrs: {
                  title: "法人联系方式",
                  closable: false,
                  type: "info",
                  "show-icon": "",
                },
              }),
              _c(
                "el-form-item",
                {
                  staticClass: "mt20",
                  attrs: { label: "法人手机号：", prop: "legalPhone" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "350px" },
                    attrs: {
                      maxlength: "11",
                      disabled: _vm.disabled,
                      placeholder: "请输入法人手机号",
                    },
                    model: {
                      value: _vm.cForm.legalPhone,
                      callback: function ($$v) {
                        _vm.$set(_vm.cForm, "legalPhone", $$v)
                      },
                      expression: "cForm.legalPhone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "法人邮箱：", prop: "legalEmail" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "350px" },
                    attrs: {
                      maxlength: "30",
                      disabled: _vm.disabled,
                      placeholder: "请输入法人邮箱",
                    },
                    model: {
                      value: _vm.cForm.legalEmail,
                      callback: function ($$v) {
                        _vm.$set(_vm.cForm, "legalEmail", $$v)
                      },
                      expression: "cForm.legalEmail",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }