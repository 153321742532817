import { render, staticRenderFns } from "./company-rate.vue?vue&type=template&id=462d705a&scoped=true"
import script from "./company-rate.vue?vue&type=script&lang=js"
export * from "./company-rate.vue?vue&type=script&lang=js"
import style0 from "./company-rate.vue?vue&type=style&index=0&id=462d705a&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "462d705a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/bium-ops-web_2zId/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('462d705a')) {
      api.createRecord('462d705a', component.options)
    } else {
      api.reload('462d705a', component.options)
    }
    module.hot.accept("./company-rate.vue?vue&type=template&id=462d705a&scoped=true", function () {
      api.rerender('462d705a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/channel/components/company-rate.vue"
export default component.exports