var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "avue-form-design",
    {
      ref: "form",
      staticStyle: { height: "100vh" },
      attrs: {
        options: _vm.option,
        showGithubStar: false,
        toolbar: _vm.toolbar,
      },
    },
    [
      _c(
        "template",
        { slot: "toolbar-left" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "text", icon: "el-icon-download" },
              on: { click: _vm.handleGetData },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }