<template>
  <div class="selection-box">
    <el-form ref="form" :model="form" label-width="160px">
      <el-form-item label="公司信息：" style="margin: 20px 0 30px !important;">
        <el-select v-model="form.companyName" placeholder="请选择" @change="handleChangeCompany">
          <el-option
            v-for="item in companyNameOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-divider content-position="left" class="title-divider">付款信息</el-divider>
      <el-row>
        <el-col :span="12">
          <el-form-item label="付款方账号：" prop="cargoBusinessId">
            <el-input v-model="form.creditAmt" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="付款方户名：" prop="cargoBusinessId">
            <el-input v-model="form.orderAmt" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="银行名称：" prop="cargoBusinessId">
            <el-input v-model="form.maxApplyAmt" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开户行支行：" prop="cargoBusinessId">
            <el-input v-model="form.discountRate" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="银行名称：" prop="cargoBusinessId">
            <el-input v-model="form.maxApplyAmt" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开户行支行：" prop="cargoBusinessId">
            <el-input v-model="form.discountRate" autocomplete="off" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider content-position="left" class="title-divider">收款信息</el-divider>
      <el-row>
        <el-col :span="12">
          <el-form-item label="收款方账号：" prop="cargoBusinessId">
            <el-input v-model="form.creditAmt" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="收款方户名：" prop="cargoBusinessId">
            <el-input v-model="form.orderAmt" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="银行名称：" prop="cargoBusinessId">
            <el-input v-model="form.maxApplyAmt" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开户行支行：" prop="cargoBusinessId">
            <el-input v-model="form.discountRate" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="银行名称：" prop="cargoBusinessId">
            <el-input v-model="form.maxApplyAmt" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开户行支行：" prop="cargoBusinessId">
            <el-input v-model="form.discountRate" autocomplete="off" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="tc mt50">
      <el-button size="medium" class="mr20" @click="handleReset('form')">重 置</el-button>
      <el-button size="medium" type="primary" :loading="btnLoading" @click="handleSave">提 交</el-button>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { doPost } from '@/router/axios';

export default defineComponent({
  name: 'companyAccountInfo',
  data() {
    return {
      btnLoading: false,
      form: {
        companyName: '',
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      companyNameOptions: [{
        value: '1',
        label: '公司一'
      }, {
        value: '2',
        label: '公司二'
      }]
    };
  },
  methods: {
    handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          doPost('/', this.form).then(res => {
            if (res.data.code === 200) {
              this.$notify.success('申请成功');
              this.btnLoading = false;
              this.$router.push({ path: '/' });
            } else {
              this.$notify.error(res.data.msg);
              this.btnLoading = false;
            }
          }).catch(() => {
            this.btnLoading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleReset() {
      this.$refs.form.resetFields();
    },
    handleChangeCompany(val) {
      // val 选中项的值
      console.log(val);
    }
  }
});
</script>

<style scoped lang="scss">

</style>
