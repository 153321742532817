<template>
  <div class="mz-layout-section">
    <div class="tableList_title">客户列表</div>
    <el-divider />

    <avue-crud
      ref="crud"
      :option="option"
      :page.sync="page"
      :table-loading="listLoading"
      :data="dataList"
      @on-load="getList"
      @size-change="sizeChange"
      @current-change="currentChange"
      @search-change="handleFilter"
      @search-reset="resetFilter">
      <template slot="isAuth" slot-scope="scope">
        <el-tag effect="dark" :type="scope.row.isAuth === '1' ? 'success' : 'info'">
          {{ scope.row.isAuth === '1' ? '已认证' : '未认证' }}
        </el-tag>
      </template>
      <!-- 自定义按钮 -->
      <template #menu="{ row }">
        <el-button @click="showModal(row, 'edit')" icon="el-icon-edit" type="text" >
          查看费率
        </el-button>
      </template>
    </avue-crud>
  </div>
</template>

<script>
import { doGet } from '@/router/axios'
import CompanyRate from './components/company-rate.vue'
const tableOption = {
  searchMenuSpan: 4,
  searchLabelWidth: 80,
  searchMenuPosition: 'left',
  border: false,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menuWidth: 160,
  refreshBtn: false,
  menu: true,
  header: false,
  columnBtn: false,
  editBtn: false,
  delBtn: false,
  align: 'left',
  addBtn: false,
 searchShowBtn:false,
 column: [
    {
      label: '企业名称',
      prop: 'name',
      minWidth: 180,
      editDisplay: false,
      addDisplay: false,
      search: true,
      searchSpan:5,
      span: 12,
    }, {
      label: '企业编号',
      prop: 'code',
      width: 130,
      editDisplay: true,
      addDisplay: true,
      search: true,
      searchTags: true,
      searchSpan:5,
      span: 12,
    },
    {
      label: '手机号码',
      prop: 'shopName',
      minWidth: 180,
      editDisplay: false,
      addDisplay: false,
      searchSpan:5,
      span: 12,
    },
    {
      label: '所属渠道',
      prop: 'creditCode',
      search: true,
      type: 'select',
      dicData: [
        { label: " 企业名称1", value: '1' }, { label: "企业名称2", value: 2, }, { label: "企业名称3", value: 3, }],
      width: 160,
      editDisplay: true,
      addDisplay: true,
      searchSpan:5,
      span: 12,
    },
    {
      label: '店铺数',
      prop: 'legalName',
      editDisplay: true,
      addDisplay: true,
      width: 120,
      span: 12,
    },
    {
      label: '今日放款额',
      prop: 'legalPhone',
      editDisplay: true,
      addDisplay: true,
      width: 120,
      span: 12,
    },
    {
      label: '累计放款金额',
      prop: 'authName',
      editDisplay: true,
      addDisplay: true,
      searchSpan:5,
      width: 150,
      span: 12,
    },
    {
      label: '逾期待还金额',
      prop: 'actualName',
      editDisplay: true,
      addDisplay: true,
      width: 120,
      span: 12,
    },
    {
      label: '近30日结算收益',
      prop: 'statues',
      editDisplay: true,
      addDisplay: true,
      width: 120,
      span: 8,
    },
    {
      label: '企业状态',
      prop: 'status',
      editDisplay: true,
      addDisplay: true,
      search: true,
      type: 'select',
      dicData: [
        { label: " 企业名称1", value: '1' }, { label: "企业名称2", value: 2, }, { label: "企业名称3", value: 3, }],
      width: 120,
      searchSpan:5,
      span: 8,
    },
    {
      label: '注册时间',
      prop: 'createTime',
      hide: true,
      editDisplay: false,
      addDisplay: false,
      span: 12,
      width: 150,
    },
  ],
}

export default {
  name: 'ChanCompany',
  components: { CompanyRate },
  data() {
    return {
      option: tableOption,
      searchItmes: {},
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条,
      },
      dataList: [],
      listLoading: true,
    }
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    getList(page, params) {
      // 封装接口参数
      const { currentPage, pageSize } = page
      let sendData = {
        current: currentPage > 0 ? currentPage - 1 : 0,
        size: pageSize,
      }
      if (params instanceof Object) {
        Object.assign(sendData, params)
      } else {
        Object.assign(sendData, this.searchItmes)
      }
      this.listLoading = true
      doGet('/cust/enter/chanPage', sendData).then(({ data }) => {
        if (data && data.data) {
          const { dataList, totalElements } = data.data
          this.dataList = dataList || []
          this.page.total = totalElements || 0
        }
        this.listLoading = false
      })
    },

    /** 显示配置弹窗 */
    showModal(item, type) {
      const comp = () => import('./components/company-rate.vue')
      this.$modalDialog(comp, { modalType: type, modalData: item }).then(() => {
        this.page.currentPage = 1
        this.getList(this.page)
      })
    },
    sizeChange(size) {
      this.page.pageSize = size
    },
    currentChange(current) {
      this.page.currentPage = current
    },
    handleFilter(param, done) {
      this.page.currentPage = 1
      this.searchItmes = param ? param : {}
      this.getList(this.page, param)
      done()
    },
    resetFilter() {
      this.page.currentPage = 1
      this.searchItmes = {}
      this.getList(this.page, this.searchItmes)
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/ .el-tabs__nav-scroll {
  padding-left: 10px;
}
/deep/ .el-divider {
  background-color: #ecedef;
}
</style>
