var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mz-layout-section" },
    [
      _c("div", { staticClass: "tableList_title" }, [_vm._v("客户列表")]),
      _c("el-divider"),
      _c("avue-crud", {
        ref: "crud",
        attrs: {
          option: _vm.option,
          page: _vm.page,
          "table-loading": _vm.listLoading,
          data: _vm.dataList,
        },
        on: {
          "update:page": function ($event) {
            _vm.page = $event
          },
          "on-load": _vm.getList,
          "size-change": _vm.sizeChange,
          "current-change": _vm.currentChange,
          "search-change": _vm.handleFilter,
          "search-reset": _vm.resetFilter,
        },
        scopedSlots: _vm._u([
          {
            key: "isAuth",
            fn: function (scope) {
              return [
                _c(
                  "el-tag",
                  {
                    attrs: {
                      effect: "dark",
                      type: scope.row.isAuth === "1" ? "success" : "info",
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.isAuth === "1" ? "已认证" : "未认证") +
                        "\n      "
                    ),
                  ]
                ),
              ]
            },
          },
          {
            key: "menu",
            fn: function ({ row }) {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { icon: "el-icon-edit", type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.showModal(row, "edit")
                      },
                    },
                  },
                  [_vm._v("\n        查看费率\n      ")]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }