var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dict-upload-process" },
    [
      _c(
        "el-upload",
        {
          attrs: {
            action: "http-request",
            limit: 1,
            "file-list": _vm.fileList,
            "show-file-list": false,
            "list-type": "text",
            "on-change": _vm.changeData,
            "before-upload": _vm.beforeUpload,
            "on-success": _vm.handleSuccess,
            "on-progress": _vm.uploadProcess,
            "http-request": _vm.httpRequest,
            "on-error": _vm.handleError,
          },
        },
        [
          _c(
            "el-button",
            { attrs: { icon: "el-icon-upload", type: "primary" } },
            [_vm._v("上传")]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            width: "165px",
            "show-close": false,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "tc", attrs: { slot: "title" }, slot: "title" },
            [_vm._v("上传进度")]
          ),
          _c("el-progress", {
            attrs: {
              "stroke-width": 10,
              type: "circle",
              percentage: _vm.progressPercent,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }