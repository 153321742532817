<template>
  <!--旧版-->
  <div class="mz-layout-section full-condition bill-list">
    <div class="tableList_title">账单列表</div>
    <el-divider />

    <avue-crud
      ref="crud"
      :option="option"
      :page.sync="page"
      :table-loading="listLoading"
      :data="list"
      @on-load="getList"
      @size-change="sizeChange"
      @current-change="currentChange"
      @search-change="handleFilter"
      @search-reset="resetFilter"
      @selection-change="selectionChange">
      <template slot="menuLeft">
        <el-button class="filter-item toApply" type="primary" icon="el-icon-edit-outline" @click="toApply">融资申请</el-button>
        <el-button class="filter-item" type="primary" icon="el-icon-download" @click="handleExport">导出</el-button>
        <!--<router-link to="/loan/shoploan">融资申请</router-link>-->
      </template>
      <template slot="status" slot-scope="{ row }">
        <el-tag v-if="row.status === '00' || row.status === '01'">{{ row.$status }}</el-tag>
        <el-tag v-if="row.status === '02'" type="danger" effect="dark">{{ row.$status }}</el-tag>
        <el-tag v-if="row.status === '03' || row.status === '04' || row.status === '05'" type="success" effect="dark">
          {{ row.$status }}
        </el-tag>
      </template>
      <template slot="menu" slot-scope="scope">
        <el-button type="text" icon="el-icon-view" @click="handleDetails(scope.row, scope.index)">详情</el-button>
        <el-button class="filter-item" type="text" icon="el-icon-download" @click="handleExportSingle(scope.row.id)">
          导出
        </el-button>
        <el-button
          v-if="scope.row.status == '01' || scope.row.status == '02'"
          type="text"
          size="mini"
          icon="iconfont icon-huankuandaihuan"
          @click="repay(scope.row.id)">
          还款
        </el-button>
      </template>
    </avue-crud>

    <el-dialog title="详情" :visible.sync="dialogVisible" width="98%" top="30px"  custom-class="pub_dialog">
      <page-details v-if="fileId" :modal-data="fileId" />
    </el-dialog>
  </div>
</template>

<script>
import { doGet, downExcel, doExportPostExcel } from '@/router/axios'
import pageDetails from './commpoents/details'
const tableOption = {
  searchMenuSpan: 24,
  searchLabelWidth: 80,
  border: false,
  index: false,
  selection: true,
  indexLabel: '序号',
  stripe: true,
  menualign: 'left',
  menuWidth: 180,
  editBtn: false,
  delBtn: false,
  align: 'left',
  addBtn: false,
  refreshBtn: false,
 searchShowBtn:false,
 column: [
    {
      label: '编号',
      prop: 'billNo',
      width: 160,
      fixed: true,
      editDisplay: true,
      addDisplay: true,
      search: true,
      searchSpan: 8,
      searchPlaceholder: '请输入编号',
      span: 12,
    },
    {
      label: '企业',
      prop: 'enterName',
      minWidth: 200,
      editDisplay: true,
      addDisplay: true,
      search: true,
      searchSpan: 8,
      searchPlaceholder: '请输入企业名称',
      span: 12,
    },
    {
      label: '店铺',
      prop: 'shopName',
      minWidth: 200,
      editDisplay: true,
      addDisplay: true,
      search: true,
      searchSpan: 8,
      searchPlaceholder: '请输入店铺名称',
      span: 12,
    },
    {
      label: '融资单号',
      prop: 'orderNo',
      minWidth: 180,
      editDisplay: true,
      addDisplay: true,
      searchSpan: 8,
      searchPlaceholder: '请输入编号',
      span: 12,
    },
    {
      label: '计息方式',
      prop: 'rateType',
      editDisplay: true,
      addDisplay: true,
      type: 'select',
      dicUrl: '/common/type/D000082',
      searchSpan: 8,
      searchPlaceholder: '请输入编号',
      span: 12,
    },
    {
      label: '实际放款金额(元)',
      prop: 'billAmount',
      editDisplay: true,
      addDisplay: true,
      span: 12,
      width: 140,
    },
    {
      label: '利息(元)',
      prop: 'billInt',
      editDisplay: true,
      addDisplay: true,
      span: 12,
      width: 140,
    },
    {
      label: '服务费(元)',
      prop: 'billFee',
      editDisplay: true,
      addDisplay: true,
      span: 12,
      width: 140,
    },
    {
      label: '剩余本金(元)',
      prop: 'surpAmount',
      editDisplay: true,
      addDisplay: true,
      span: 12,
      width: 140,
    },
    {
      label: '状态',
      prop: 'status',
      type: 'select',
      dicUrl: '/common/type/D000051',
      editDisplay: false,
      addDisplay: false,
      span: 12,
      width: 140,
      slot: true,
    },
  ],
}

export default {
  name: 'BillList',
  components: { pageDetails },
  data() {
    return {
      headerIds: [],
      selectedList: [],
      option: tableOption,
      searchItmes: {},
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条,
      },
      list: [],
      listLoading: true,

      dialogVisible: false,
      fileId: '',
      bill: {
        repayAmt: 0,
        billCount: 0,
        payDate: null,
        days: 0,
        reapayCount: 0,
        overdueCount: 0,
        overPayCount: 0,
      },
    }
  },
  created() {
    this.initBillCount()
  },
  methods: {
    initBillCount() {
      const that = this
      doGet('/rent/bill/billCount').then((res) => {
        that.bill = res.data.data
      })
    },
    getList(page, params) {
      params = params ? params : { ...this.searchItmes }
      params.current = page.currentPage - 1
      params.size = page.pageSize
      this.listLoading = true
      doGet('/rent/bill/page', params).then(({ data }) => {
        if (data) {
          const { dataList, totalElements } = data.data || {}
          this.list = dataList || []
          this.page.total = totalElements || 0
        }
        this.listLoading = false
      })
    },

    sizeChange(size) {
      this.page.pageSize = size
    },
    currentChange(current) {
      this.page.currentPage = current
    },
    handleFilter(param, done) {
      this.page.currentPage = 1
      this.searchItmes = param ? param : {}
      this.getList(this.page, param)
      done()
    },
    resetFilter() {
      this.page.currentPage = 1
      this.searchItmes = {}
      this.getList(this.page, this.searchItmes)
    },
    selectionChange(list) {
      this.selectedList = list
    },

    repay(billId) {
      this.$router.push({ path: '/repayment/add?billId=' + billId })
    },
    handleDetails(row, index) {
      console.log('row.id' + row.id)
      this.fileId = row.id
      this.dialogVisible = true
    },
    handleExportSingle(id) {
      this.headerIds.push(id)
      this.listLoading = true
      doExportPostExcel('/rent/bill/export', this.headerIds).then((res) => {
        // 获取headers中的filename文件名
        downExcel(res)
        this.listLoading = false
      }).catch(() => {
        this.listLoading = false
      })
    },
    handleExport() {
      if (this.selectedList.length <= 0) {
        this.$message.error('请选择导出账单')
        return false
      }
      this.selectedList.forEach((da) => {
        this.headerIds.push(da.id)
      })
      this.listLoading = true
      doExportPostExcel('/rent/bill/export', this.headerIds).then((res) => {
        // 获取headers中的filename文件名
        downExcel(res)
        this.listLoading = false
      }).catch(() => {
        this.listLoading = false
      })
    },
    toApply() {
      this.$router.push('/loan/shoploan');
    }
  },
}
</script>

<style lang="scss" scoped>
/deep/ .el-tabs__nav-scroll {
  padding-left: 10px;
}
/deep/ .el-divider {
  background-color: #ecedef;
}
/deep/  .avue-crud__left{
    width: 100%;
    display: flex;
    justify-content: space-between;
  align-items: flex-start;
  }
/deep/  .avue-crud__right{
  display: flex;
    flex-wrap: nowrap;
  }
</style>
