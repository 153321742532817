<template>
  <div class="selection-box">
    <el-form ref="cForm" :model="cForm" :rules="vRules" :label-position="'right'" label-width="120px" size="small">
      <el-alert title="提现账户" :closable="false" type="info" />
      <el-form-item label="监管账户：" class="mt20" prop="accountId">
        <el-select
          v-model="cForm.accountId"
          value-key="accId"
          style="width: 50%"
          placeholder="请选择"
          @change="acc_change($event)">
          <el-option v-for="item in accArray" :key="item.custId" :label="item.bankUserName" :value="item" />
        </el-select>
      </el-form-item>

      <el-alert title="到账银行卡" :closable="false" type="info" class="mt10" />
      <el-row :gutter="20" class="bank-card-box">
        <el-col v-for="(item, index) in bankArray" :key="index" :span="12" style="border-radius: 5px">
          <div
            :class="isActive === index ? 'isActive bank-card-item' : 'bank-card-item'"
            @click="changeAccount(item.id, index)">
            <div class="bcb-d">
              <div class="bcb-d-icon" />
              <div class="bcb-d-text">
                <div class="f14">{{ item.bankName }}</div>
                <div class="fb f22 mt10">{{ item.bankCard }}</div>
              </div>
            </div>
            <div class="bcb-subName f12 mt40">{{ item.bankSubName }}</div>
          </div>
        </el-col>
      </el-row>

      <el-alert title="提现信息" :closable="false" type="info" />
      <el-form-item label="可提现金额:" prop="mm1" class="mt20">
        <span class="f16 fc-red fb">{{ balanceAmount }}</span>
        元
      </el-form-item>
      <el-form-item label="提现金额" prop="totalAmt">
        <el-input v-model="cForm.totalAmt" type="number" style="width: 50%" />
      </el-form-item>
      <el-form-item label="银行附言" prop="bankMemo">
        <el-input v-model="cForm.bankMemo" style="width: 50%" />
      </el-form-item>
      <el-form-item label="申请原因" prop="reason">
        <el-input v-model="cForm.reason" type="textarea" style="width: 50%" />
      </el-form-item>
      <div class="tc">
        <el-button type="primary" size="medium" :loading="isSubmiting" @click="handlerWithdraw()">提现</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { doGet, doPost } from '@/router/axios'

export default {
  name: 'CompanyIndex',
  components: {},
  props: {
    eId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isSubmiting: false,
      enterId: '',
      isActive: 0,
      bankArray: [],
      accArray: [],
      balanceAmount: 0,
      cForm: {
        accountId: {},
        bankCustId: '',
        totalAmt: '',
        bankMemo: '',
        reason: '',
        widthCode: '0',
      },
      vRules: {
        totalAmt: [{ required: true, trigger: 'change', message: '提现金额不能为空' }],
        bankMemo: [{ required: true, trigger: 'blur', message: '银行附言不能为空' }],
        reason: [{ required: true, trigger: 'blur', message: '申请理由不能为空' }],
      },
    }
  },
  created() {
    const { accId } = this.$route.query
    if (accId) {
      this.cForm.accountId.accId = accId
    }
    this.enterId = this.eId
  },
  mounted() {
    this.initAccList()
  },
  methods: {
    initAccList() {
      doGet('/cap/account/queryStaffAcc/05').then(({ data }) => {
        if (data) {
          this.accArray = data.data || []
          const { accId } = this.cForm.accountId
          if (accId) {
            this.accArray.forEach((item) => {
              if (item.accId === accId) {
                this.enterId = item.custId
                this.acc_change(item)
              }
            })
          }
        }
      })
    },

    changeAccount(val, index) {
      this.isActive = index
      this.cForm.bankCustId = val
    },
    acc_change(val) {
      this.enterId = val.custId
      this.balanceAmount = val.balanceAmount
      this.initBank()
    },
    initBank() {
      this.bankArray = []
      this.isActive = 0
      this.cForm.bankCustId = ''
      doGet('/cap/account/queryEnterAcc/' + this.enterId).then(({data}) => {
        if (data && data.data) {
          this.bankArray = data.data.skList || []
          this.cForm.bankCustId = this.bankArray[0].id
        }
      })
    },

    handlerWithdraw() {
      if (!this.isSubmiting) {
        if (!this.cForm.bankCustId) {
          this.$notify.error('请先选择提现银行卡')
          return
        }
        this.$refs.cForm.validate((valid) => {
          if (valid) {
            this.isSubmiting = true
            this.cForm.accId = this.cForm.accountId.accId
            doPost('/order/widthdrawalInfo/apply', this.cForm)
              .then(({ data }) => {
                if (data.code == 200) {
                  this.$router.push({ path: '/cap/widthdrawList' })
                }
              })
              .catch((err) => {
                this.isSubmiting = false
              })
          }
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.selection-box {
  width: auto;
  min-width: 920px;
}
.bank-card-box {
  padding: 20px 30px;
  cursor: pointer;

  .bank-card-item {
    width: 340px;
    padding: 20px;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
    background: #efefef;
    .bcb-d {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .bcb-d-icon {
        width: 60px;
        height: 60px;
        margin-right: 20px;
        background: url('~@/assets/icon/icon-bankcard.png') 0 0 no-repeat;
        background-size: 100%;
        filter: brightness(0);
        opacity: 10%;
      }
      .bcb-d-text {
        color: #aaa;
      }
    }
    .bcb-subName {
      color: #aaa;
    }
  }
  .isActive {
    background: #5a5d64 url('~@/assets/bg/bk-card.png') 0 0 no-repeat;
    background-size: 100%;
    .bcb-d {
      .bcb-d-icon {
        filter: none;
        opacity: 1;
      }
      .bcb-d-text {
        color: #fff;
      }
    }
    .bcb-subName {
      color: #1ebb2b;
    }
  }
}
</style>
