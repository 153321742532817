var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "repay-section mt20" },
    [
      _c("avue-crud", {
        attrs: { data: _vm.billLines, option: _vm.tableOption },
        on: { "selection-change": _vm.selectionChange },
        scopedSlots: _vm._u([
          {
            key: "status",
            fn: function ({ row }) {
              return [
                row.status === "09" ||
                row.status === "10" ||
                row.status === "02" ||
                row.status === "05"
                  ? _c(
                      "span",
                      [
                        _c(
                          "el-tag",
                          { attrs: { effect: "dark", type: "danger" } },
                          [_vm._v(_vm._s(row.statusName))]
                        ),
                      ],
                      1
                    )
                  : row.status === "06" ||
                    row.status === "08" ||
                    row.status === "00" ||
                    row.status === "01"
                  ? _c(
                      "span",
                      [
                        _c("el-tag", { attrs: { effect: "dark" } }, [
                          _vm._v(_vm._s(row.statusName)),
                        ]),
                      ],
                      1
                    )
                  : _c(
                      "span",
                      [
                        _c(
                          "el-tag",
                          { attrs: { effect: "dark", type: "success" } },
                          [_vm._v(_vm._s(row.statusName))]
                        ),
                      ],
                      1
                    ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }