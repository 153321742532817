<template>
  <div class="details-warp">
    <el-form ref="form" :model="form" label-width="120px" :rules="rules">
      <!--借据信息-->
      <div class="details-section ml10 mr20">
        <h3 class="details-section-h3"><span class="title">店铺订单表</span>
        </h3>
        <el-row :gutter="20" class="pl20 pr20">
          <el-col :span="12">
            <el-form-item label="店铺ID：">
              <el-input v-model="form.shopId" readonly="true" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="订单编号：">
              <el-input v-model="form.orderNo" readonly="true" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="订单状态：">
              <dict-select
                v-model="form.orderStatus"
                placeholder="订单状态"
                no="店铺订单-订单状态"
                style="width: 100%;"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="借款状态;：">
              <dict-select
                v-model="form.loanStatus"
                placeholder="借款状态;"
                no="D000104"
                style="width: 100%;"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="实付金额：">
              <el-input v-model="form.payAmt" readonly="true" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商品ID：">
              <el-input v-model="form.goodsId" readonly="true" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商品名称：">
              <el-input v-model="form.goodsName" readonly="true" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商品单价：">
              <el-input v-model="form.goodsPrice" readonly="true" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商品数量：">
              <el-input v-model="form.goodsNum" readonly="true" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="购买时间：">
              <el-date-picker v-model="form.buyTime" type="datetime" style="width: 100%;" placeholder="选择日期" value-format="yyyy-MM-dd HH:mm" format="yyyy-MM-dd HH:mm" readonly="true" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发货时间：">
              <el-date-picker v-model="form.shipmentsTime" type="datetime" style="width: 100%;" placeholder="选择日期" value-format="yyyy-MM-dd HH:mm" format="yyyy-MM-dd HH:mm" readonly="true" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="确认收款时间：">
              <el-date-picker v-model="form.receiptTime" type="datetime" style="width: 100%;" placeholder="选择日期" value-format="yyyy-MM-dd HH:mm" format="yyyy-MM-dd HH:mm" readonly="true" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注;：">
              <el-input v-model="form.remark" readonly="true" type="textarea" />

            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
  </div>
</template>

<script>
import { doGet, doPost, doPut } from '@/router/axios'

import DictSelect from '@/components/form-upload/DictSelect'
export default {
  name: 'PageDetails',
  components: {
    DictSelect
  },
  props: {
    modalData: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      bizId: '',
      form: {
        yzsumje: 0,
        id: ''
      },
      rules: {
      }
    }
  },
  watch: {
    modalData(val, oldVal) {
      console.log('modalData:' + val + '--oldVal' + oldVal)
      // 这里执行初始化方法
      this.initForm(val)
    }
  },
  mounted() {
    this.initForm(this.modalData)
  },
  methods: {
    initForm: function(id) {
      doGet('/shop/goodsOrder/' + id).then(res => {
        this.form = res.data.data
      })
    }
  }
}
</script>

<style scoped>
    .details-warp {
        height: calc(100vh - 200px);
        overflow: hidden;
        overflow-y: auto;
    }
    .reset-btn {
        margin-top: 3px;
        margin-right: 10px;
    }
</style>
