<template>
  <div class="ml30 mr30 pb50" style="margin-top: 80px">
    <div class="icon-success-box tc">
      <i class="el-icon-success" />
    </div>
    <p class="mt30 tc fb fc_333 f18">提交成功</p>
    <p class="pt20 pb20 f16 tc">融资申请已提交成功，预计将在<span class="fc-red">2个工作日</span>内有审核结果</p>
    <div class="success-info-box">
      <el-row>
        <el-col :span="24" class="title_h3 mb20">项目名称</el-col>
        <el-col :span="12">申请订单编号：202112120-001</el-col>
        <el-col :span="12">融资金额：200,000.00 元</el-col>
      </el-row>
    </div>

    <div class="mt40 tc">
      <el-button icon="el-icon-arrow-left" size="medium" @click.stop="$router.push({ path: '/'})">返回首页</el-button>
      <el-button icon="el-icon-circle-check" type="primary" size="medium" @click.stop="handleGoNextStep()">查看订单</el-button>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Step4',
  props: {
    proId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
  .icon-success-box {
    .el-icon-success {
      font-size: 70px;
      color: #67C23A;
    }
  }
  .title_h3 {
    height: 35px;
    font: 600 16px/100% "Microsoft YaHei";
    color: #333;
  }
  .success-info-box {
    margin: 50px auto 0;
    padding: 20px;
    box-sizing: border-box;
    width: 60%;
    background: #fafafa;
    border: 1px solid #dfdfdf;
  }
</style>
