<template>
  <div class="ml30 mr30">
    <el-form ref="ruleForm" :model="form" :rules="rules" class="pl20 pr20" :label-width="formLabelWidth" status-icon inline-message>
      <div class="title_h3 mb20">已选凭证</div>
      <el-form-item label="凭证总额：">
        {{ form.voucherAccount }} <span class="ml10">元</span>
      </el-form-item>
      <el-form-item label="凭证总数：">
        {{ form.voucherCount }} <span class="ml10">笔</span>
      </el-form-item>
      <el-form-item label="凭证到期日：">
        {{ form.voucherDateStr }}
      </el-form-item>

      <div class="title_h3 mb20 mt20">融资信息</div>
      <el-form-item label="申请金额：" prop="applyAmt">
        <el-input v-model="form.applyAmt" autocomplete="off" :placeholder="'最低金额不能小于' + minAmt + '元'" style="width: 40%" maxlength="7" @change="calInverest" />
        <span class="ml10">元</span>
      </el-form-item>

      <el-form-item v-if="repayType==0" label="融资期限：" prop="linesDay">
        <el-input v-model="form.linesDay" autocomplete="off" :placeholder="'可选范围' + minDay+'-'+maxDay+'天'" style="width: 40%" maxlength="3" @change="calInverest" />
        <span class="ml10">天</span>
      </el-form-item>
      <el-form-item v-if="repayType==1||repayType==2" label="融资期数：" prop="linesDay">
        <el-input v-model="form.linesDay" autocomplete="off" :placeholder="'可选范围' + minDay+'-'+maxDay+'月'" style="width: 40%" maxlength="3" @change="calInverest" />
        <span class="ml10">月</span>
      </el-form-item>
      <el-form-item label="收款方名称：" prop="reciveName">
        <el-input v-model="form.reciveName" autocomplete="off" placeholder="请输入收款方名称" style="width: 40%" />
      </el-form-item>
      <el-form-item label="收款方账户：" prop="reciveAcc">
        <el-input v-model="form.reciveAcc" autocomplete="off" placeholder="请输入收款方账户" style="width: 40%" />
      </el-form-item>
      <el-form-item label="开户行：" prop="reciveBankSub">
        <el-input v-model="form.reciveBankSub" autocomplete="off" placeholder="请输入开户行" style="width: 40%" />
      </el-form-item>
      <el-form-item label="备注：" prop="applyMsg">
        <el-input v-model="form.applyMsg" type="textarea" :rows="2" autocomplete="off" placeholder="请输入备注信息" style="width: 40%" />
      </el-form-item>

      <el-form-item label="融资年利率：">
        {{ form.yearRate | numberMul(100) }}%
      </el-form-item>
      <el-form-item label="融资日利率：">
        {{ form.intervestRate | numberMul(100) }}%
      </el-form-item>
      <el-form-item label="预计利息：">
        {{ inverest }} <span class="ml10">元</span>
      </el-form-item>
    </el-form>

    <div class="mt20 tc">
      <el-button icon="el-icon-arrow-left" size="medium" @click.stop="handleGoPrepStep()">上一步</el-button>
      <el-button type="primary" size="medium" :loading="loading" @click.stop="handleGoNextStep()">下一步<i class="el-icon-arrow-right el-icon--right" /></el-button>
    </div>
  </div>
</template>
<script>
import { doGet } from '@/router/axios';
import { compareDateStr } from '@/utils/date';

export default {
  name: 'Step2',
  props: {
    proId: {
      type: String,
      default: ''
    },
    custCreditDeatilId: {
      type: String,
      default: ''
    }
  },
  data() {
    var validateApplyAmt = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入申请金额'));
      }
      setTimeout(() => {
        if (this.form.voucherAccount < value) {
          callback(new Error('不可超过凭证总额'));
        } else if (this.form.applyAmt < this.minAmt) {
          callback(new Error(`申请金额不能小于${this.minAmt}元`));
        } else {
          callback();
        }
      }, 500);
    };
    var validateLinesDay = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入融资期限'));
      }
      setTimeout(() => {
        if (this.form.linesDay < this.minDay) {
          callback(new Error(`融资期限不能小于${this.minDay}天`));
        } else if (this.form.linesDay > this.maxDay) {
          callback(new Error(`融资期限不能大于${this.maxDay}天`));
        } else {
          callback();
        }
      }, 500);
    };
    return {
      formLabelWidth: '120px',
      minDay: 0,
      maxDay: 0,
      minAmt: 0,
      loading: false,
      repayType: '0',
      form: {
        voucherIds: [],
        repayType: '0',
        voucherAccount: 0, // 凭证总额
        voucherCount: 0, // 凭证总数
        voucherDateStr: '', // 凭证到期日
        payee: '南航货运',
        intervestRate: 0,
        custCreditDeatilId:'',
        yearRate: 0,
        inverest: 0, // 利息
        productId: '',
        applyAmt: null,
        linesDay: null
      },
      product: {
        intervestRate: null
      },
      rules: {
        applyAmt: [
          { required: true, validator: validateApplyAmt, trigger: 'blur' }
        ],
        linesDay: [
          { required: true, validator: validateLinesDay, trigger: 'blur' }
        ],
        reciveAcc: [
          { required: true, message: '请输入收款方账号', trigger: 'blur' }
        ],
        reciveName: [
          { required: true, message: '请输入收款方名称', trigger: 'blur' }
        ],
        reciveBankSub: [
          { required: true, message: '请输入开户行', trigger: 'blur' }
        ]
      }
    };
  },
  computed: {
    inverest() {
      const dayrote = this.form.intervestRate;
      const invest = (this.form.applyAmt * dayrote * this.form.linesDay).toFixed(2);
      this.form.inverest = invest;
      return invest;
    }
  },
  created() {
    this.initProduct();
    this.initVoucher();
    this.calInverest();
  },
  methods: {
    handleGoPrepStep() {
      this.$emit('handleGoToStep', 1);
    },
    handleGoNextStep() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          localStorage.setItem('MZdata', JSON.stringify(this.form));
          this.$emit('handleGoToStep', 3);
        } else {
          this.$message.error('数据未验证通过，请检查！');
          return false;
        }
      });
    },
    initVoucher() {
      const voucherObj = JSON.parse(localStorage.getItem('voucherSelectedObj'));
      let vcount = 0;
      const startDate_ = [];
      const endDate_ = [];
      voucherObj.forEach((item) => {
        vcount += item.tradeTotalAmt;
        // startDate_.push(item.startDate)
        endDate_.push(item.endDate);
        this.form.voucherIds.push(item.id);
      });
      this.form.voucherAccount = vcount;
      this.form.voucherCount = voucherObj.length;

      // const startDate__ = compareDateStr(startDate_, 'min')
      const endDateMin__ = compareDateStr(endDate_, 'min');
      const endDateMax__ = compareDateStr(endDate_, 'max');

      this.form.voucherDateStr = endDateMin__ + ' 至 ' + endDateMax__;
    },
    initProduct() {
      const that = this;
      doGet('/rent/order/getLoan/' + this.proId).then(res => {
        if (res.data.code == 200) {
          that.product = res.data.data;
          that.form.productId = res.data.data.proId;
          that.form.intervestRate = res.data.data.intervestRate;
          that.form.yearRate = res.data.data.yearRate;
          that.form.repayType = res.data.data.repayType;
          that.minDay = res.data.data.minDay;
          that.maxDay = res.data.data.maxDay;
          that.minAmt = res.data.data.minRentAmt;
          that.repayType = res.data.data.repayType;
        }
      });
    },
    calInverest() {
      const amt = this.form.applyAmt;
      const days = this.form.linesDay;
      const rate = this.product.intervestRate;
      let invest = 0;
      if (amt != null && amt != '' && day != null && day != '' && rate != null && rate != '') {
        invest = (amt * days * rate);
      }
      this.invest = invest.toFixed(2);
    }
  }
};
</script>

<style lang="scss" scoped>
.title_h3 {
  height: 35px;
  font: 600 16px/100% "Microsoft YaHei";
  color: #333;
  border-bottom: 1px solid #dedede;
  &:before {
    content: '';
    float: left;
    width: 5px;
    height: 16px;
    background: #0094ff;
    margin-right: 10px;
  }
}
</style>
