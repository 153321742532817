<template>
  <div class="details-warp">
    <el-form ref="form" :model="form" label-width="120px" label-position="left">
      <div class="details-section">
        <h3 class="details-section-h3"><span>授信机构信息</span></h3>
        <el-row :gutter="40" class="pl20  pr20">
          <el-col :span="24">
            <el-form-item label="授信机构：">
              <el-input v-model="form.creditInfo.name" disabled />
            </el-form-item>
          </el-col>
<!--          <el-col :span="12">-->
<!--            <el-form-item label="外部授信编号：">-->
<!--              <el-input v-model="form.creditNo" disabled />-->
<!--            </el-form-item>-->
<!--          </el-col>-->
        </el-row>
        <h3 class="details-section-h3"><span>额度详情</span></h3>
        <el-row :gutter="40" class="pl20  pr20">
          <el-col :span="12">
            <el-form-item label="授信编号：">
              <el-input v-model="form.creditNo" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="用户名称：">
              <el-input v-model="form.enterpriseName" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="企业Id：">
              <el-input v-model="form.enterpriseCode" disabled/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="额度类型：">
              <dict-select no="D000055" v-model="form.pro.creditType" disabled></dict-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="计息方式：">
              <dict-select no="D000047" v-model="form.pro.repayType" disabled></dict-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="信贷类型：">
              <dict-select no="D000054" v-model="form.pro.productType" disabled></dict-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="可用额度：">
              <el-input v-model="form.balanceAmt" disabled/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="授信金额：">
              <el-input v-model="form.totalAmt" disabled/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="额度期限：">
              <el-input v-model="form.expireDate" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="授信利率：">
              <el-input v-model="form.yearRate"  disabled >
                <template slot="suffix">%</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="额度说明：">
              <el-input v-model="form.remark" type="textarea" row="2" disabled >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="details-section mt15 ml10 mr20">
        <h3 class="details-section-h3"><span>额度项下借款</span></h3>
        <credit-order :billForm="form"></credit-order>
       </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { doGet, doPost, doDelet, doPut } from '@/router/axios'
import DictSelect from '@/components/form-upload/DictSelect'
import creditOrder from './credit-order'

export default {
  name: 'PageDetails',
  components: {
    DictSelect,creditOrder
  },
  props: {
    modalData: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      id: '',
      form: {}
    }
  },
  watch: {
    modalData(val, oldVal) {
      console.log('modalData:' + val + '--oldVal' + oldVal)
      // 这里执行初始化方法
      this.initForm(val)
    }
  },
  mounted() {
    this.initForm(this.modalData)
  },
  methods: {
    initForm: function(id) {
      const that = this
      doGet(`/credit/creditCustDeatil/` + id).then(res => {
        that.form = res.data.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.details-warp {
  height: calc(100vh - 300px);
  overflow: hidden;
  overflow-y: auto;
}
</style>
