<template>
  <div class="mz-layout-section">
    <div class="tableList_title">账户明细</div>
    <el-divider />

    <avue-crud ref="crud" :option="option" :page.sync="page" :table-loading="listLoading" :data="list" @on-load="getList"
      @size-change="sizeChange" v-model:search="search" @current-change="currentChange" @search-change="handleFilter"
      @search-reset="resetFilter">
      <template slot="menuRight">
        <el-button class="filter-item" type="primary" icon="el-icon-download">导出明细</el-button>
      </template>
    
      <template slot="status" slot-scope="scope">
        <el-tag v-if="scope.row.status == 0" effect="dark" type="success">收入</el-tag>
        <el-tag v-if="scope.row.status == 1" effect="dark" type="danger">支出</el-tag>
      </template>
    </avue-crud>
  </div>
</template>

<script>
import { doGet, doDelet, doPost } from '@/router/axios'
const tableOption = {
  searchMenuSpan: 8,
  searchLabelWidth: 80,
  searchMenuPosition: 'left',
  border: false,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menuWidth: 230,
  refreshBtn: false,
  searchShowBtn: false,
  columnBtn: false,
  editBtn: false,
  menu: false,
  delBtn: false,
  align: 'left',
  addBtn: false,
 searchShowBtn:false,
 column: [
    {
      label: '收支',
      prop: 'status',
      editDisplay: true,
      addDisplay: true,
      searchSpan: 8,
      span: 12,
    }, {
      label: '企业名称',
      prop: 'names',
      search: true,
      type: 'select',
      dicData: [
        { label: " 企业名称1", value: '1' }, { label: "企业名称2", value: 2, }, { label: "企业名称3", value: 3, }],
      editDisplay: true,
      addDisplay: true,
      rules: [{
        required: true,
        trigger: 'blur',
        message: '请输入节点类型'
      }
      ],
      hide: true,
    },
    {
      label: '科目',
      prop: 'subjects',
      editDisplay: true,
      addDisplay: true,
      span: 12,
    },
    {
      label: '交易金额',
      prop: 'price',
      editDisplay: true,
      addDisplay: true,
      span: 12,
    },
    {
      label: '交易时间',
      prop: 'time',
      type: "date",
      format: "yyyy-MM-dd",
      searchRange: true,
      valueFormat: "yyyy-MM-dd",
      editDisplay: true,
      addDisplay: true,
      search: true,
      searchSpan: 8,
      searchValue: new Date().toISOString().substr(0, 10),
      span: 12,
    },
    {
      label: '交易前金额',
      prop: 'Beforetrading',
      editDisplay: true,
      addDisplay: true,
      span: 12,
    },
    {
      label: '交易后金额',
      prop: 'PostTransaction',
      editDisplay: true,
      addDisplay: true,
      span: 12,
    },
  ],
}

export default {
  name: 'AccountList',
  components: {},
  data() {
    return {
     
      search: {},
      option: tableOption,
      searchItmes: {},
      page: {
        total: 10, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条,
      },
      list: [
        {
          status: 0,
          subjects: '渠道费',
          price: '1000.00',
          time: '2024-3-1 16:00',
          Beforetrading: '9000',
          PostTransaction: '10000'
        }, {
          status: 1,
          subjects: '渠道费',
          price: '1000.00',
          time: '2024-3-1 16:00',
          Beforetrading: '9000',
          PostTransaction: '10000'
        }, {
          status: 0,
          subjects: '渠道费',
          price: '1000.00',
          time: '2024-3-1 16:00',
          Beforetrading: '9000',
          PostTransaction: '10000'
        }, {
          status: 1,
          subjects: '渠道费',
          price: '1000.00',
          time: '2024-3-1 16:00',
          Beforetrading: '9000',
          PostTransaction: '10000'
        },
      ],
      listLoading: true,
    }
  },
  computed: {},
  watch: {},
  created() { },
  methods: {
    getList(page, params) {
      this.listLoading = false
      // params = params ? params : { ...this.searchItmes }
      // params.current = page.currentPage - 1
      // params.size = page.pageSize
      // this.listLoading = true
      // doPost('/order/widthdrawalInfo/queryChanPage', params).then(({ data }) => {
      //   if (data) {
      //     const { dataList, totalElements } = data.data || {}
      //     this.list = dataList || []
      //     this.page.total = totalElements || 0
      //   }
      //   this.listLoading = false
      // })
    },

    sizeChange(size) {
      this.page.pageSize = size
    },
    currentChange(current) {
      this.page.currentPage = current
    },
    handleFilter(param, done) {
      this.page.currentPage = 1
      this.searchItmes = param ? param : {}
      this.getList(this.page, param)
      done()
    },
    resetFilter() {
      this.page.currentPage = 1
      this.searchItmes = {}
      this.getList(this.page, this.searchItmes)
    },

  },
}
</script>

<style lang="scss" scoped>
/deep/ .el-tabs__nav-scroll {
  padding-left: 10px;
}

/deep/ .el-divider {
  background-color: #ecedef;
}

.my-invest-item {
  li {
    position: relative;
    float: left;
  }

  .ico {
    position: absolute;
    top: 10px;
    left: 12px;
    width: 24px;
    height: 24px;
    display: inline-block;
    background-image: url('/img/icon/centre-icon.png');
    vertical-align: middle;
  }

  div {
    border: 1px solid #efefef;
    padding: 10px 20px 10px 40px;
    border-radius: 5px;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
    margin-right: 20px;
  }
}

.invest-ico-1 {
  background-position: -624px -78px;
}

.invest-ico-2 {
  background-position: -624px -104px;
}

.invest-ico-3 {
  background-position: -624px -130px;
}

.invest-ico-4 {
  background-position: -558px -26px;
}
</style>
