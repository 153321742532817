var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      style: "height:" + _vm.height,
    },
    [
      _c("iframe", {
        staticStyle: { width: "100%", height: "100%" },
        attrs: { src: _vm.src, frameborder: "no", scrolling: "auto" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }