<template>
  <el-dialog
    :title="title"
    width="680px"
    :visible.sync="visible"
  >
    <el-form ref="form" :model="form" :rules="rules" class="dialog-form pl30 pr30" label-width="100px">
      <el-form-item prop="oldPass" label="旧密码">
        <el-input v-model="form.oldPass" type="password" auto-complete="off" maxlength="30" placeholder="请输入旧密码，初次设置可不填">
          <i slot="prefix" class="icon-mima" />
        </el-input>
      </el-form-item>
      <el-form-item prop="password" label="设置新密码">
        <el-input v-model="form.password" type="password" maxlength="20" auto-complete="off" placeholder="请设置新密码">
          <i slot="prefix" class="icon-mima" />
        </el-input>
      </el-form-item>
      <el-form-item prop="checkPass" label="确认新密码">
        <el-input v-model="form.checkPass" type="password" maxlength="20" auto-complete="off" placeholder="请确认新密码">
          <i slot="prefix" class="icon-mima" />
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button :loading="loading" type="primary" @click="onSave">修改</el-button>
      <el-button :disabled="loading" @click="visible = false">取消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { doGet, doPost } from '@/router/axios'
import { getCodeImg } from '@/api/login'
export default {
  name: 'ChangePwd',
  model: {
    event: 'change',
    prop: 'value'
  },
  props: {
    value: Boolean,
    title: String,
    api: String
  },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.form.checkPass !== '') {
          this.$refs.form.validateField('checkPass')
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    const validateOldPass = (rule, value, callback) => {
      if (value) {
        callback()
      } else {
        callback(new Error('请输入旧密码'))
      }
    }
    return {
      form: {
        oldPass: '',
        password: '',
        checkPass: ''
      },
      rules: {
        oldPass: [{ required: true, trigger: 'blur', message: '请输入原密码' }],
        password: [{ required: true, trigger: 'blur', validator: validatePass }],
        checkPass: [{ required: true, trigger: 'blur', validator: validatePass2 }]
      },
      loading: false
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('change', val)
      }
    }
  },
  watch: {
    value(visible) {
      this.$refs['form'] && this.$refs['form'].resetFields()
    }
  },
  methods: {
    onSave() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const data = { ...this.form }
          doPost('cust/set/updatePassword', data).then(res => {
            if (res.data.code == 200) {
              this.$notify({
                title: '修改成功',
                type: 'success',
                duration: 2500
              })
              this.loading = false
              this.visible = false
              this.$store.dispatch('LogOut').then(() => {
                location.reload() // 为了重新实例化vue-router对象 避免bug
              })
            } else {
              this.loading = false
              this.$notify({
                title: res.data.msg,
                type: 'error',
                duration: 2500
              })
            }
          }
          ).catch(e => {
            this.loading = false
            // this.$notify({
            //   title: '失败',
            //   type: 'error',
            //   duration: 2500
            // })
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
