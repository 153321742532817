<template>
  <div v-loading="isLoading" class="data-board">
    <basic-container>
      <el-form
        :label-position="'right'"
        :model="queryFrom"
        label-width="54px"
        size="small"
      >
        <div class="mt10 inner-box">
          <el-form-item label="产品号:" prop="liveStreamPlat">
            <el-select
              v-model="queryFrom.productType"
              placeholder="请选择"
              style="width: 350px;"
              @change="selectChange"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <el-row :gutter="10">
        <el-col :span="8">
          <div class="board bg-blue-1">
            <p>今日放款金额（元）</p>
            <p>{{ boardView.todayLoanAmount | numberToCurrency }}</p>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="board bg-blue-1">
            <p>累计放款金额（元）</p>
            <p>{{ boardView.accLoanAmount | numberToCurrency }}</p>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="board bg-blue-1">
            <p>今日回款本金（元）</p>
            <p>{{ boardView.todayPrincipalRepayment | numberToCurrency }}</p>
          </div>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="6">
          <div class="board bg-blue-2">
            <p>累计回款本金（元）</p>
            <p>{{ boardView.accPrincipalRepayment | numberToCurrency }}</p>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="board bg-blue-2">
            <p>今日实收息费总额（元）</p>
            <p>{{ boardView.todayReceiptInterestFeeAmount | numberToCurrency }}</p>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="board bg-blue-2">
            <p>累计实收息费总额（元）</p>
            <p>{{ boardView.accReceiptInterestFeeAmount | numberToCurrency }}</p>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="board bg-blue-2">
            <p>当前应收息费总额（元）</p>
            <p>{{ boardView.receivableInterestFeeAmount | numberToCurrency }}</p>
          </div>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="8">
          <div class="board bg-blue-3">
            <p>当前在贷余额（元）</p>
            <p>{{ boardView.inLoanAmount | numberToCurrency }}</p>
          </div>
        </el-col>
        <!-- <el-col :span="8">
          <div class="board bg-blue-3">
            <p>累计回款利息（元）</p>
            <p>{{ boardView.accPaymentInterest | numberToCurrency }}</p>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="board bg-blue-3">
            <p>累计平台留存服务费（元）</p>
            <p>{{ boardView.accRetainedServiceAmount | numberToCurrency }}</p>
          </div>
        </el-col> -->
      </el-row>

      <!-- <el-row :gutter="10">
        <el-col :span="12">
          <div class="board bg-blue-4">
            <p>总利息当前年化IRR（%）</p>
            <p v-if="boardView.interestAnnualizedIrr">
              {{ (boardView.interestAnnualizedIrr * 100) | numberToCurrency(2) }}
            </p>
            <p v-else>{{ boardView.interestAnnualizedIrr | dealNull }}</p>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="board bg-blue-4">
            <p>总息费当前年化IRR（%）</p>
            <p v-if="boardView.interestFeeAnnualizedIrr">
              {{ (boardView.interestFeeAnnualizedIrr * 100) | numberToCurrency(2) }}
            </p>
            <p v-else>{{ boardView.interestFeeAnnualizedIrr | dealNull }}</p>
          </div>
        </el-col>
      </el-row> -->

      <!-- <el-row :gutter="10">
        <el-col :span="12">
          <div class="board bg-blue-5 mb0">
            <p>累计进件店铺数（家）</p>
            <p>{{ boardView.storeNum | numberToCurrency(0) }}</p>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="board bg-blue-5 mb0">
            <p>当前在贷店铺数（家）</p>
            <p>{{ boardView.inLoanStoreNum | numberToCurrency(0) }}</p>
          </div>
        </el-col>
      </el-row> -->
    </basic-container>
  </div>
</template>

<script>

export default {
  name: 'DataBoard',
  props: {},
  data() {
    return {
      isLoading: false,
      boardView: {},
      queryFrom: {
        productType: 0
      },
      options: [{
        value: 0,
        label: '1A-极速收款'
      }, {
        value: 1,
        label: '2A-极速付款'
      }]
    };
  },
  watch: {},
  created() {
  },
  mounted() {
    this.getViewData();
  },
  methods: {
    getViewData() {
      this.isLoading = true;
      this.doPost('bium-portal/controller/fin-ops/databoard/databoard', { productType: this.queryFrom.productType }).then(({
        data
      }) => {
        this.boardView = data.result;
        this.isLoading = false;
      });
    },
    selectChange() {
      this.getViewData();
    }
  }
};
</script>

<style lang="scss" scoped>
.data-board {
  .board {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 130px;
    border-radius: 5px;
    margin-bottom: 16px;
    box-shadow: 0 0 5px #333;

    &.mb0 {
      margin: 0;
    }

    &:hover {
      box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.65);
    }

    p:nth-child(1) {
      color: #fafafa;
      font-size: 16px;
    }

    p:nth-child(2),
    .count-num {
      font-size: 28px;
      color: #fff;
      font-weight: 600;
    }
  }

  .bg-blue-1 {
    background-image: linear-gradient(180deg, #0081c9, #1f9cdf);
    background-image: -ms-linear-gradient(180deg, #0081c9, #1f9cdf);
    background-image: -webkit-linear-gradient(180deg, #0081c9, #1f9cdf);
    background-image: -o-linear-gradient(180deg, #0081c9, #1f9cdf);
  }

  .bg-blue-2 {
    background-image: linear-gradient(180deg, #379cd3, #54bae5);
    background-image: -ms-linear-gradient(180deg, #379cd3, #54bae5);
    background-image: -webkit-linear-gradient(180deg, #379cd3, #54bae5);
    background-image: -o-linear-gradient(180deg, #379cd3, #54bae5);
  }

  .bg-blue-3 {
    background-image: linear-gradient(180deg, #36acde, #269bef);
    background-image: -ms-linear-gradient(180deg, #36acde, #269bef);
    background-image: -webkit-linear-gradient(180deg, #36acde, #269bef);
    background-image: -o-linear-gradient(180deg, #36acde, #269bef);
  }

  .bg-blue-4 {
    background-image: linear-gradient(180deg, #2787e7, #2369e2);
    background-image: -ms-linear-gradient(180deg, #2787e7, #2369e2);
    background-image: -webkit-linear-gradient(180deg, #2787e7, #2369e2);
    background-image: -o-linear-gradient(180deg, #2787e7, #2369e2);
  }

  .bg-blue-5 {
    background-image: linear-gradient(180deg, #1763e7, #621fe9);
    background-image: -ms-linear-gradient(180deg, #1763e7, #621fe9);
    background-image: -webkit-linear-gradient(180deg, #1763e7, #621fe9);
    background-image: -o-linear-gradient(180deg, #1763e7, #621fe9);
  }
}
</style>
