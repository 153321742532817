var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "details-warp" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            "label-width": "80px",
            "label-position": "left",
          },
        },
        [
          _c(
            "div",
            { staticClass: "details-section" },
            [
              _c("h3", { staticClass: "details-section-h3" }, [
                _c("span", [_vm._v("订单详情")]),
              ]),
              _c(
                "el-row",
                { staticClass: "pl20 pr20", attrs: { gutter: 40 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "编号：" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.no,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "no", $$v)
                              },
                              expression: "form.no",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "账单号：" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.billNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "billNo", $$v)
                              },
                              expression: "form.billNo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "融资单号：" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.orderNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "orderNo", $$v)
                              },
                              expression: "form.orderNo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "还款金额：" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.form.applyAmt,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "applyAmt", $$v)
                                },
                                expression: "form.applyAmt",
                              },
                            },
                            [
                              _c("template", { slot: "suffix" }, [
                                _vm._v("元"),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "创建时间：" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.createTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "createTime", $$v)
                              },
                              expression: "form.createTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "details-section mt20" }, [
            _c("h3", { staticClass: "details-section-h3" }, [
              _c("span", [_vm._v("还款明细")]),
            ]),
            _c(
              "div",
              { staticClass: "pl20 pr20 pb20" },
              [_c("repayment-lines", { attrs: { "bill-form": _vm.form } })],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }