<template>
  <div class="mz-layout-section shop-list">
    <div class="tableList_title">店铺列表</div>
    <el-divider />

    <avue-crud
      ref="crud"
      :option="option"
      :page.sync="page"
      :table-loading="listLoading"
      :data="dataList"
      @on-load="getDataList"
      @size-change="sizeChange"
      @current-change="currentChange"
      @search-change="handleFilter"
      @search-reset="resetFilter">
      <template slot="menuLeft">
        <el-button class="filter-item" type="primary" icon="el-icon-plus" @click="createNew()">新增店铺</el-button>
      </template>
      <template #discountRate="{ row }">
        <span>{{ row.discountRate | numberToCurrency }}%</span>
      </template>
      <template slot="menu" slot-scope="scope">
        <el-button
          v-if="scope.row.status == '2' || scope.row.status == '0'"
          type="text"
          icon="el-icon-edit"
          @click="handleEdit(scope.row, scope.index)">
          修改
        </el-button>
      </template>
    </avue-crud>

    <!-- <el-dialog title="企业详情" :visible.sync="dialogVisible" width="60%" custom-class="pub_dialog">
      <page-details v-if="fileId" :modal-data="fileId" />
    </el-dialog>

    <el-dialog title="信息修改" :visible.sync="dialogVisible2" width="60%" custom-class="pub_dialog">
      <page-edit v-if="fileId" :modal-data="fileId" @change="handleCloseDialog" />
    </el-dialog> -->
  </div>
</template>

<script>
import { doGet, doDelet } from '@/router/axios'
import pageDetails from './components/details.vue'
import pageEdit from './components/edit.vue'
const tableOption = {
  searchMenuSpan: 12,
  searchLabelWidth: 80,
  searchMenuPosition: 'left',
  border: false,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menuWidth: 100,
  refreshBtn: false,
  columnBtn: false,
  editBtn: false,
  delBtn: false,
  align: 'left',
  addBtn: false,
 searchShowBtn:false,
 column: [
    {
      label: '企业',
      prop: 'enterName',
      minWidth: 200,
      fixed: true,
      editDisplay: false,
      addDisplay: false,
      span: 12,
    },
    {
      label: '店铺名称',
      prop: 'name',
      minWidth: 200,
      editDisplay: false,
      addDisplay: false,
      search: true,
      searchPlaceholder: '请输入店铺名称',
      searchSpan: 12,
      span: 12,
    },
    {
      label: '店铺编码',
      prop: 'shopCode',
      width: 120,
      editDisplay: true,
      addDisplay: true,
      span: 12,
    },
    {
      label: '店主账号',
      prop: 'shopkeeperId',
      width: 160,
      editDisplay: true,
      addDisplay: true,
      width: 120,
      span: 12,
    },
    {
      label: '店主手机号',
      prop: 'shopkeeperPhone',
      editDisplay: true,
      addDisplay: true,
      width: 120,
      span: 12,
    },
    {
      label: '店铺折扣率',
      prop: 'discountRate',
      editDisplay: true,
      addDisplay: true,
      width: 120,
      span: 12,
    },
    {
      label: '店铺退货率',
      prop: 'refundRate',
      editDisplay: true,
      addDisplay: true,
      width: 150,
      span: 12,
    },
    {
      label: '所属平台',
      prop: 'liveStreamPlat',
      type: 'select',
      dicUrl: '/common/type/D000101',
      editDisplay: true,
      addDisplay: true,
      width: 120,
      span: 12,
    },
    {
      label: '配置日期',
      prop: 'cofigDate',
      width: 160,
      type: 'date',
      format: 'yyyy-MM-dd',
      editDisplay: true,
      addDisplay: true,
      width: 120,
      span: 12,
    },
  ],
}

export default {
  name: 'ShopList',
  components: { pageDetails, pageEdit },
  data() {
    return {
      option: tableOption,
      searchItmes: {},
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条,
      },
      dataList: [],
      listLoading: true,
      fileId: '',
      // dialogVisible: false,
      // dialogVisible2: false,
    }
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    getDataList(page, params) {
      params = params ? params : { ...this.searchItmes }
      params.current = page.currentPage - 1
      params.size = page.pageSize
      this.listLoading = true
      doGet('/shop/info/queryPage', params).then(({ data }) => {
       if (data) {
          const { dataList, totalElements } = data.data || {}
          this.dataList = dataList || []
          this.page.total = totalElements || 0
        }
        this.listLoading = false
      })
    },

    sizeChange(size) {
      this.page.pageSize = size
    },
    currentChange(current) {
      this.page.currentPage = current
    },
    handleFilter(param, done) {
      this.page.currentPage = 1
      this.searchItmes = param ? param : {}
      this.getDataList(this.page, param)
      done()
    },
    resetFilter() {
      this.page.currentPage = 1
      this.searchItmes = {}
      this.getDataList(this.page, this.searchItmes)
    },

    createNew(row, done, loading) {
      this.$router.push({ path: '/mineShop/shopAdd' })
    },
    handleEdit(row, index) {
      console.log('row.id' + row.id)
      this.fileId = row.id
      this.$router.push({ path: '/mineShop/shopAdd?shopId=' + row.id })
    },

    handleCloseDialog() {
      this.dialogVisible2 = false
      this.dialogVisible = false
      this.getList(this.page, this.searchItmes)
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/ .el-tabs__nav-scroll {
  padding-left: 10px;
}
/deep/ .el-divider {
  background-color: #ecedef;
}
</style>
