<template>
  <div class="selection-box">
    <el-alert title="营业执照信息确认" :closable="false" type="info" />
    <el-form ref="cForm" :model="cForm" :rules="vRules" :label-position="'left'" label-width="150px" size="small" class="cForm">
      <div class="mt10 inner-box">
        <el-form-item label="营业执照" prop="fileBusinessId">
          <dict-upload-info
            v-model="cForm.fileBusinessId"
            type="image"
            :limit="1"
            type-code="F0202"
            biz-id="123456"
            url="cust/enter/upload"
            :tips="'提示：请上传营业执照原件或加盖公章的复印件'"
            :disabled="disabled"
            @change="onFileChange($event, 'fileBusinessId')"
            @getOcrInfo="handleSetOcrInfo"
          />
        </el-form-item>
        <el-form-item label="营业执照名称" prop="name">
          <el-input v-model="cForm.name" maxlength="40" placeholder="请输入公司名称" style="width: 350px" :disabled="disabled" />
        </el-form-item>
        <el-form-item label="营业执照地址" prop="regitAddress">
          <el-cascader v-model="cForm.provinces" :disabled="disabled" :options="options" placeholder="请选择省市区" class="fl" />
          <el-input v-model="cForm.regitAddress" maxlength="120" :disabled="disabled" aria-placeholder="请输入详细地址" class="ml10 fl" style="width: 60%" />
        </el-form-item>
        <el-form-item label="社会统一信用代码" prop="creditCode">
          <el-input v-model="cForm.creditCode" maxlength="20" placeholder="一般为18/15位数字或字母组合" style="width: 350px" :disabled="disabled" />
        </el-form-item>
        <el-form-item label="营业期限" prop="expireDateAttr">
          <template>
            <el-date-picker
              v-if="!cForm.expireDateAttrFlag"
              v-model="cForm.expireDateAttr"
              :disabled="disabled"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="起始日期"
              end-placeholder="结束日期"
              class="mr20"
            />
            <!-- <el-checkbox v-model="cForm.expireDateAttrFlag" :disabled="disabled" @change="handleChangeCompanyExpire">无固定期限</el-checkbox> -->
          </template>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import DictUploadInfo from '@/views/components/DictUploadInfo.vue';
import { doGet, doPost } from '@/router/axios';
import { regionData } from 'element-china-area-data';
import { setDateFormat } from '@/utils/date';
export default defineComponent({
  name: 'companyInfo',
  components: { DictUploadInfo },
  props: {
    eId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      options: regionData, // 选择格式
      disabled: false,
      cForm: {
        provinces: [],
        regitAddress: '',
        enterId: '',
        id: '',
        enterType: '',
        creditCode: '',
        expireDateAttr: [], // 营业期限
        companyType: '',
        name: '',
        businessLicense: ''
      },
      ocrInfo: {},
      vRules: {
        fileBusinessId: [{ required: true, trigger: 'change', message: '请上传营业执照' }],
        enterType: [{ required: true, trigger: 'blur', message: '请选择企业类型' }],
        name: [{ required: true, trigger: 'blur', message: '请填写营业执照名称' }],
        companyType: [{ required: true, message: '请选择企业类型', trigger: 'change' }],
        creditCode: [{ required: true, trigger: 'blur', message: '请填写营业执照号' }],
        expireDateAttr: [{ required: true, trigger: 'blur', message: '请选择营业期限' }],
        leagalDate: [{ required: true, trigger: 'blur', message: '请选择证件有效期' }],
        provinces: [{ required: true, trigger: 'blur', message: '请选择注册地址' }],
        regitAddress: [{ required: true, trigger: 'blur', message: '请填写注册地址' }]
      }
    };
  },
  created() {
    if (!this.validatenull(this.eId)) { // 判断enterId是否为空
      this.enterId = this.eId;
    }
    this.initData();
  },
  watch: {
    'cForm.fileBusinessId': function(newVal, oldVal) {
      // 企业类型
      if (this.validatenull(this.cForm.enterType)) {
        this.cForm.enterType = this.ocrInfo.enterType;
      }
      // 公司名称
      if (this.validatenull(this.cForm.name)) {
        this.cForm.name = this.ocrInfo.name;
      }
      // 营业执照号
      if (this.validatenull(this.cForm.creditCode)) {
        this.cForm.creditCode = this.ocrInfo.creditCode;
      }
      // 营业期限
      if (this.validatenull(this.cForm.expireDateAttr) || this.cForm.expireDateAttr[0] === '') {
        const startDate = setDateFormat(this.ocrInfo.estiblishTime);
        const endDate = setDateFormat(this.ocrInfo.validTime);
        this.cForm.expireDateAttr = [startDate, endDate];
      }
      // 注册地址
      if (this.validatenull(this.cForm.regitAddress)) {
        this.cForm.regitAddress = this.ocrInfo.regLocation;
      }
    }
  },
  methods: {
    initData() {
      if (this.enterId) {
        doGet('/cust/enter/getEnterprise/' + this.enterId).then(({ data }) => {
          if (data.code === 200) {
            this.cForm = {
              ...data.data,
              expireDateAttrFlag: data.data.expireDate === '2099-12-31',
              expireDateAttr: ['', '']
            };
            if (this.cForm.expireDate != null && this.cForm.regditDate != null) {
              this.cForm.expireDateAttr = [this.cForm.regditDate, this.cForm.expireDate];
            }
            if (this.cForm.isAuth === '1') {
              this.disabled = true;
            }
          }
        })
      }
    },

    onFileChange(ids = [], key) {
      this.$refs.cForm.validateField(key);
    },
    handleSetOcrInfo(val) {
      this.ocrInfo = { ...val };
    },
    saveCompanyInfo() {
      this.$refs.cForm.validate(valid => {
        if (valid) {
          this.loading = true;
          const { address, regCapital } = this.ocrInfo
          const data = {
            ...this.cForm,
            ...{
              expireDate: this.cForm.expireDateAttr[1],
              regditDate: this.cForm.expireDateAttr[0]
            },
            legalIdcardAddress: address || '',
            capital: regCapital !== '无' ? regCapital || '' : '',
          };

          if (this.expireDateAttrFlag) {
            this.cForm.expireDate = '2099-12-31';
          }
          delete data.regitAddressPre;
          delete data.engageAddressPre;
          delete data.endupDateFoverever;
          data.id = this.enterId;
          doPost('/cust/enter/saveEnterprise', data).then(res => {
            this.loading = false;
            if (res.data.code == 200) {
              this.$emit('change', res.data.data.id);
              this.$parent.handleStepNext(); // 跳转到下一步
            }
          }).catch(e => {
            this.loading = false;
          });
        }
      });
    },
    /**
     * 判断有无固定期限
     * false: 无固定期限， true:有期限
     */
    handleChangeCompanyExpire(flag) {
      if (flag) {
        this.cForm.expireDateAttr = ['1900-01-01', '2099-12-31'];
      }
    }
  }
});
</script>

<style scoped lang="scss">
.selection-box {
  border: 1px solid #F2F4F7;
  /deep/ .el-alert__title {
    color: #333;
    font-weight: 700;
  }
  .cForm {
    margin: 0 30px;
  }
}

.inner-box {
  margin-left: auto;
  margin-right: auto;
}
.sub_title {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  &:before {
    content: '';
    margin-right: 10px;
    width: 4px;
    height: 18px;
    background: #2d8cf0;
    border-radius: 10px;
  }
}
/deep/ .el-divider--horizontal {
  width: auto;
}
/deep/ .el-form--label-left .el-form-item__label {
  padding-left: 15px;
}
</style>
