<template>
  <div class="user">
    <basic-container>
      <avue-crud
        ref="crud"
        v-model="form"
        :option="option"
        :page.sync="page"
        :table-loading="listLoading"
        :before-open="handleOpenBefore"
        :data="list"
        @on-load="getList"
        @size-change="sizeChange"
        @current-change="currentChange"
        @search-change="handleFilter"
        @refresh-change="handleRefreshChange"
        @row-update="update"
        @row-save="create"
      >
        <template slot="menuLeft">
          <el-button
            class="filter-item"
            type="primary"
            icon="el-icon-plus"
            @click="$refs.crud.rowAdd()"
          >添加
          </el-button>
        </template>

        <template slot="menu" slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-edit"
            @click="handleUpdate(scope.row,scope.index)"
          >编辑
          </el-button>
          <el-button
            type="text"
            icon="el-icon-delete"
            style="color: #f00"
            @click="deletes(scope.row,scope.index)"
          >删除
          </el-button>
        </template>
      </avue-crud>
    </basic-container>
  </div>

</template>

<script>
import { doGet, doPost, doDelet, doPut } from '@/router/axios'
import { mapGetters } from 'vuex'

export default {
  name: 'TableUser',
  data() {
    return {
      option: tableOption,
      defaultProps: {
        label: 'name',
        value: 'id'
      },
      page: {
        total: 0, 		// 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, 	// 每页显示多少条,
        isAsc: false	// 是否倒序
      },
      list: [],
      listLoading: true,
      form: {}
    }
  },
  computed: {

  },
  watch: {

  },
  created() {

  },
  methods: {
    getList(page, params) {
      if (params == undefined || params == null || params == '') {
        params = {}
      }
      this.listLoading = true
      params.current = page.currentPage - 1
      params.size = page.pageSize
      doGet('/wf/wfProcess/page', params).then(res => {
        this.list = res.data.data.dataList
        this.page.total = res.data.data.totalElements
        this.listLoading = false
      })
    },
    sizeChange(size) {
      this.page.pageSize = size
    },
    currentChange(current) {
      this.page.currentPage = current
    },
    handleFilter(param, done) {
      this.page.currentPage = 1
      this.getList(this.page, param)
      done()
    },
    handleRefreshChange() {
      this.getList(this.page)
    },
    handleOpenBefore(show, type) {
      window.boxType = type
      // 若是编辑、查看回显名称
      if (['edit', 'views'].includes(type)) {

      } else if (type === 'add') {

      }
      show()
    },
    handleUpdate(row, index) {
      this.$refs.crud.rowEdit(row, index)
    },
    create(row, done, loading) {
      this.listLoading = true
      doPost('/wf/wfProcess/save', this.form).then(res => {
        if (res.data.code === 200) {
          this.getList(this.page)
          done()
          this.$notify.success('创建成功')
          return false
        } else {
          this.listLoading = false
          this.$notify.error(res.data.msg)
        }
        loading()
      }).catch(() => {
        loading()
      })
    },
    update(row, index, done, loading) {
      doPut('/wf/wfProcess/update', this.form).then(res => {
        if (res.data.code === 200) {
          this.getList(this.page)
          done()
          this.$notify.success('更新成功')
          return
        } else {
          this.$notify.error(res.data.msg)
          return
        }
        loading()
      }).catch(() => {
        loading()
      })
    },
    deletes(row) {
      this.$confirm('此操作将永久删除该条数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消', type: 'warning'
      }
      ).then(() => {
        doDelet('/wf/wfProcess/delete/' + row.id).then(res => {
          if (res.data.code === 200) {
            this.getList(this.page)
            this.$notify.success('删除成功')
          } else {
            this.$notify.error(res.data.msg)
          }
        }).catch(() => {
          this.$notify.error('服务异常')
        })
      })
    }
  }
}

export const tableOption = {
  border: false,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menualign: 'left',
  menuWidth: 150,
  searchMenuSpan: 6,
  editBtn: false,
  delBtn: false,
  align: 'left',
  addBtn: false,
 searchShowBtn:false,
 column: [{
    fixed: true,
    label: 'id',
    prop: 'id',
    span: 24,
    hide: true,
    editDisplay: false,
    addDisplay: false
  },
  {
    fixed: true,
    label: '流程名称',
    prop: 'procName',
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: true,
      trigger: 'blur',
      message: '请输入流程名称'
    },
    {
      min: '0',
      max: '32',
      message: '长度在 0 到 32 个字符',
      trigger: 'blur'
    }
    ],
    search: true,
    span: 12
  },
  {
    fixed: true,
    label: '流程编码',
    prop: 'procCode',
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: true,
      trigger: 'blur',
      message: '请输入流程编码'
    },
    {
      min: '0',
      max: '10',
      message: '长度在 0 到 10 个字符',
      trigger: 'blur'
    }
    ],
    search: true,
    span: 12
  },
  {
    fixed: true,
    label: '类型',
    prop: 'type',
    type: 'select',
    dicUrl: '/dict/type/D000058',
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: true,
      trigger: 'blur',
      message: '请输入类型'
    }
    ],
    span: 12
  },
  {
    fixed: true,
    label: '状态',
    prop: 'status',
    type: 'select',
    dicUrl: '/dict/type/D000059',
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: true,
      trigger: 'blur',
      message: '请输入状态'
    }
    ],
    span: 12
  },
  {
    fixed: true,
    label: '备注',
    prop: 'remark',
    type: 'textarea',
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: true,
      trigger: 'blur',
      message: '请输入备注'
    },
    {
      min: '0',
      max: '100',
      message: '长度在 0 到 100 个字符',
      trigger: 'blur'
    }
    ],
    span: 24
  },
  {
    fixed: true,
    label: '创建时间',
    prop: 'createTime',
    editDisplay: false,
    addDisplay: false,
    span: 12
  },
  {
    fixed: true,
    label: '更新时间',
    prop: 'updateTime',
    editDisplay: false,
    addDisplay: false,
    span: 12
  }
  ]
}

</script>
