var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dict-upload", class: _vm.type === "text" ? "text" : "" },
    [
      _c(
        "el-upload",
        {
          class: { uoloadSty: _vm.showBtnImg, disUoloadSty: _vm.noneBtnImg },
          attrs: {
            action: "http-request",
            limit: _vm.limit,
            multiple: _vm.multiple,
            "list-type": "image" == _vm.type ? "picture-card" : "text",
            "file-list": _vm.fileList,
            "on-change": _vm.onChange,
            "http-request": _vm.httpRequest,
            "on-success": _vm.onSuccess,
            "on-exceed": _vm.onExceed,
            "on-error": _vm.onError,
            "on-remove": _vm.onRemove,
          },
          scopedSlots: _vm._u([
            {
              key: "file",
              fn: function ({ file }) {
                return [
                  _c(
                    "div",
                    { staticClass: "file-item", class: [_vm.type] },
                    [
                      _vm.type === "image"
                        ? _c("el-image", {
                            staticStyle: { width: "100px", height: "100px" },
                            attrs: {
                              src: file.url,
                              fit: "cover",
                              "preview-src-list": [file.url],
                            },
                          })
                        : [
                            _c("div", { staticClass: "text-box" }, [
                              _c("i", { staticClass: "el-icon-document" }),
                              _c("span", [_vm._v(_vm._s(file.name))]),
                            ]),
                          ],
                      !_vm.disabled
                        ? _c("el-button", {
                            class: [`${_vm.type}-delete-btn`],
                            attrs: {
                              type: _vm.type === "image" ? "warning" : "text",
                              icon: "el-icon-delete",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.onRemove(file)
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              },
            },
          ]),
        },
        [
          "image" == _vm.type
            ? [_c("i", { staticClass: "el-icon-plus" })]
            : [
                _c("div", { staticClass: "el-icon-text" }, [
                  _c("i", { staticClass: "el-icon-upload2" }),
                  _vm._v("\n        立即上传\n      "),
                ]),
              ],
          _vm.tips
            ? _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [_vm._v(_vm._s(_vm.tips))]
              )
            : _vm._e(),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }