<template>
  <div>
    <div class="selection-box">
      <el-form ref="cForm" :model="cForm" :label-position="'right'" label-width="160px" size="small">
        <el-alert title="基本信息" :closable="false" type="info" class="mt10" />
        <div class="mt20 inner-box">
          <el-form-item label="营业执照" prop="fileBusinessId">
            <dict-upload v-model="cForm.fileBusinessId" type="image" :limit="1" disabled type-code="F0202" biz-id="123456" @change="onFileChange($event, 'fileBusinessId')" />
          </el-form-item>
          <el-form-item label="公司名称" prop="name">
            <el-input v-model="cForm.name" placeholder="请输入公司名称" disabled />
          </el-form-item>
          <el-form-item label="营业执照号" prop="creditCode">
            <el-input v-model="cForm.creditCode" placeholder="一般为18/15位数字或字母组合" disabled />
          </el-form-item>
          <el-form-item label="营业期限" prop="endupDate">
            <template>
              <el-date-picker
                v-model="cForm.expireDateAttr"
                value-format="yyyy-MM-dd"
                type="daterange"
                start-placeholder="起始日期"
                end-placeholder="结束日期"
                disabled
              />
              <span style="margin-right: 30px;" />
            </template>
          </el-form-item>

        </div>

        <!--资质信息-->
        <el-alert title="法人信息" :closable="false" type="info" class="mt10" />
        <div class="mt10 inner-box">
          <el-form-item label="身份证（人像面）" prop="legalFilePeopleId">
            <dict-upload v-model="cForm.legalFilePeopleId" disabled type="image" :limit="1" type-code="F0204" biz-id="123456" @change="onFileChange($event, 'legalFilePeopleId')" />
          </el-form-item>
          <el-form-item label="身份证（国徽面）" prop="legalFileReverseId">
            <dict-upload v-model="cForm.legalFileReverseId" disabled type="image" :limit="1" type-code="F0203" biz-id="123456" @change="onFileChange($event, 'legalFileReverseId')" />
          </el-form-item>
          <el-form-item label="法人姓名：" prop="legalName">
            <el-input v-model="cForm.legalName" disabled placeholder="请输入法人姓名" />
          </el-form-item>
          <el-form-item label="法人身份证号：" prop="legalIdCard">
            <el-input v-model="cForm.legalIdCard" disabled placeholder="一般为18/15位数字或字母组合" />
          </el-form-item>
          <el-form-item label="法人手机号：" prop="legalPhone">
            <el-input v-model="cForm.legalPhone" disabled placeholder="请输入法人手机号" />
          </el-form-item>
        </div>

        <!--法人信息-->
        <el-alert title="授权人信息" :closable="false" type="info" class="mt10" />
        <div class="mt10 inner-box">
          <el-form-item label="身份证（人像面）" prop="authFilePeopleId">
            <dict-upload v-model="cForm.authFilePeopleId" disabled type="image" :limit="1" type-code="F0206" biz-id="123456" @change="onFileChange($event, 'legalFilePeopleId')" />
          </el-form-item>
          <el-form-item label="身份证（国徽面）" prop="authFileReverseId">
            <dict-upload v-model="cForm.authFileReverseId" disabled type="image" :limit="1" type-code="F0205" biz-id="123456" @change="onFileChange($event, 'legalFileReverseId')" />
          </el-form-item>
          <el-form-item label="授权人姓名：" prop="authName">
            <el-input v-model="cForm.authName" disabled placeholder="请输入法人姓名" />
          </el-form-item>
          <el-form-item label="授权人身份证号：" prop="authIdCard">
            <el-input v-model="cForm.authIdCard" disabled placeholder="一般为18/15位数字或字母组合" />
          </el-form-item>
          <el-form-item label="授权人手机号：" prop="authPhone">
            <el-input v-model="cForm.authPhone" disabled placeholder="请输入法人手机号" />
          </el-form-item>
        </div>

        <!--联系信息-->
        <el-alert title="实控人信息" :closable="false" type="info" class="mt10" />
        <div class="mt10 inner-box">
          <el-form-item label="身份证（人像面）" prop="actualFilePeopleId">
            <dict-upload v-model="cForm.actualFilePeopleId" disabled type="image" :limit="1" type-code="F0207" biz-id="123456" @change="onFileChange($event, 'legalFilePeopleId')" />
          </el-form-item>
          <el-form-item label="身份证（国徽面）" prop="actualFileReverseId">
            <dict-upload v-model="cForm.actualFileReverseId" disabled type="image" :limit="1" type-code="F0208" biz-id="123456" @change="onFileChange($event, 'legalFileReverseId')" />
          </el-form-item>
          <el-form-item label="实控人姓名：" prop="actualName">
            <el-input v-model="cForm.actualName" disabled placeholder="请输入法人姓名" />
          </el-form-item>
          <el-form-item label="实控人身份证号：" prop="actualIdCard">
            <el-input v-model="cForm.actualIdCard" disabled placeholder="一般为18/15位数字或字母组合" />
          </el-form-item>
          <el-form-item label="实控人手机号：" prop="actualPhone">
            <el-input v-model="cForm.actualPhone" disabled placeholder="请输入法人手机号" />
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import DictUpload from '@/views/components/DictUpload.vue';
import DictSelect from '@/views/components/DictSelect.vue';
import AddrSelect from '@/components/AddrSelect/index.vue';
import { doGet, doPost } from '@/router/axios';

export default {
  name: 'CompanyIndex',
  components: { DictUpload, DictSelect, AddrSelect },
  props: {
    modalData: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      province: '',
      city: '',
      address: '',
      leagalDateFoverever: false, // 证件有效期是否长期有效
      disabled: true,
      cForm: {
        id: '',
        expireDateAttr: [], // 营业期限
        name: '',
        businessLicense: '',
        regitAddressPre: [],
        regitAddress: '',
        engageAddressPre: [],
        expireDate: [],
        leagalDate: [],
        legalCardExpire: '',
        legalName: '',
        legalIdCard: '',
        legalPhone: '',
        fileCardReverseIdStr: [],
        fileCardFrontIdStr: [],
        endupDateFoverever: Boolean // 永久有效，传'2099-12-31'
      }
    };
  },

  watch: {
    modalData(val, oldVal) {
      this.initData(val);
    }
  },
  mounted() {
    this.initData(this.modalData);
  },
  methods: {
    onFileChange(ids = [], key) {
      this.$refs.cForm.validateField(key);
    },
    onEndupDateFovereverChange(checked) {
      this.cForm.endupDate = checked ? '2099-12-31' : '';
      this.$refs.cForm.validateField('endupDate');
    },
    onLegalDateFovereverChange(checked) {
      if (checked) {
        this.cForm.leagalDate = ['2021-01-01', '2099-12-31'];
        this.leagalDateFoverever = true;
      } else {
        this.cForm.leagalDate = [];
        this.leagalDateFoverever = false;
      }
    },
    initData(id) {
      const that = this;
      if (id == undefined || id == null || id == '') {
        return;
      }
      doGet('/cust/enter/getEnterprise/' + id).then(res => {
        if (res.data.code === 200) {
          that.cForm = {
            ...res.data.data,
            endupDateFoverever: res.data.data.expireDate === '2099-12-31',
            expireDateAttr: ['', '']
          };
          if (that.cForm.expireDate != null && that.cForm.regditDate != null) {
            that.cForm.expireDateAttr = [that.cForm.regditDate, that.cForm.expireDate];
          }

          // 企业所在地
          if (that.cForm.locationAddress != null) {
            const adr = that.cForm.locationAddress.split(',');
            this.$set(this.cForm, 'regitAddressPre', [adr[0], adr[1]]); // 使用$set赋值，解决el-date-picker无法赋值的bug
            // that.cForm.regitAddressPre = [adr[0], adr[1]];
            that.cForm.regitAddress = adr[2];
          }

          // 证件有效期
          if (that.cForm.legalCardExpire != null && that.cForm.legalCardExpire != '') {
            const cardStr = that.cForm.legalCardExpire.split(',');
            if (cardStr.length > 1) {
              this.$set(this.cForm, 'leagalDate', cardStr); // 使用$set赋值，解决el-date-picker无法赋值的bug
              if (that.cForm.leagalDate[1] === '2099-12-31') {
                that.leagalDateFoverever = true;
              }
            }
          }
        } else {

        }
      }
      ).catch(e => {
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.tips {
  font-size: 13px;
  line-height: 1.2;
  color: #666;

  /deep/ .el-link {
    font-size: 13px;
  }
}

.selection-box {
  margin-top: 10px;
}

.inner-box {
  width: 720px;
  margin-left: auto;
  margin-right: auto;
}
</style>
