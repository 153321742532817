<template>
  <div class="mz-layout-section full-condition">
    <div class="tableList_title">预审列表</div>
    <el-divider />

    <avue-crud
      ref="crud"
      :option="option"
      :page.sync="page"
      :table-loading="listLoading"
      :data="list"
      @on-load="getList"
      @size-change="sizeChange"
      @current-change="currentChange"
      @search-change="handleFilter"
      @search-reset="resetFilter">
      <template slot="menuLeft">
        <el-button class="filter-item" type="primary" icon="el-icon-plus" @click="createNew()">新增预审店铺</el-button>
      </template>
      <template #creditAmt="{ row }">
        <span>{{ row.creditAmt | numberToCurrency }}</span>
      </template>
    </avue-crud>
  </div>
</template>

<script>
import { doGet, doDelet } from '@/router/axios'
const tableOption = {
  searchMenuSpan: 24,
  searchLabelWidth: 80,
  searchIndex: 3,
  searchIcon: true,
  searchShowBtn: false,
  border: false,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menuWidth: 230,
  refreshBtn: false,
  columnBtn: false,
  editBtn: false,
  delBtn: false,
  align: 'left',
  addBtn: false,
  menu: false,
 searchShowBtn:false,
 column: [
    {
      label: '店铺',
      prop: 'name',
      minWidth: 180,
      fixed: true,
      editDisplay: false,
      addDisplay: false,
      search: true,
      searchSpan: 8,
      span: 12,
    },
    {
      label: '店主账号',
      prop: 'shopkeeperId',
      editDisplay: true,
      addDisplay: true,
      search: true,
      searchSpan: 8,
      span: 12,
    },
    {
      label: '店主手机号',
      prop: 'shopkeeperPhone',
      width: 120,
      editDisplay: true,
      addDisplay: true,
      search: true,
      searchSpan: 8,
      span: 12,
    },
    {
      label: '所属平台',
      prop: 'liveStreamPlat',
      type: 'select',
      dicUrl: '/common/type/D000101',
      editDisplay: true,
      addDisplay: true,
      search: true,
      searchSpan: 8,
      span: 12,
    },
    {
      label: '配置日期',
      prop: 'cofigDate',
      minWidth: 120,
      editDisplay: true,
      addDisplay: true,
      span: 12,
    },
    {
      label: '店铺额度（元）',
      prop: 'creditAmt',
      width: 130,
      editDisplay: true,
      addDisplay: true,
      span: 12,
    },
    {
      label: '折扣率(%)',
      prop: 'discountRate',
      editDisplay: true,
      addDisplay: true,
      span: 12,
    },
    {
      label: '平均回款天数',
      prop: 'turnAvgeDays',
      width: 130,
      editDisplay: true,
      addDisplay: true,
      span: 12,
    },
    {
      label: '状态',
      prop: 'status',
      type: 'select',
      dicUrl: '/common/type/D000129',
      editDisplay: true,
      addDisplay: true,
      span: 12,
    },
    {
      label: '申请时间',
      prop: 'createTime',
      width: 160,
      editDisplay: false,
      addDisplay: false,
      span: 12,
    },
  ],
}

export default {
  name: 'PreShopList',
  components: {},
  data() {
    return {
      option: tableOption,
      searchItmes: {},
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条,
      },
      list: [],
      listLoading: true,
    }
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    getList(page, params) {
      params = params ? params : { ...this.searchItmes }
      params.current = page.currentPage - 1
      params.size = page.pageSize
      this.listLoading = true
      doGet('/chan/prea/queryPage', params).then(({ data }) => {
        if (data) {
          const { dataList, totalElements } = data.data || {}
          this.list = dataList || []
          this.page.total = totalElements || 0
        }
        this.listLoading = false
      })
    },

    sizeChange(size) {
      this.page.pageSize = size
    },
    currentChange(current) {
      this.page.currentPage = current
    },
    handleFilter(param, done) {
      this.page.currentPage = 1
      this.searchItmes = param ? param : {}
      this.getList(this.page, param)
      done()
    },
    resetFilter() {
      this.page.currentPage = 1
      this.searchItmes = {}
      this.getList(this.page, this.searchItmes)
    },

    createNew() {
      this.$router.push({ path: '/chan/addpre' })
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/ .el-tabs__nav-scroll {
  padding-left: 10px;
}
/deep/ .el-divider {
  background-color: #ecedef;
}
</style>
