import { render, staticRenderFns } from "./gerenxinxicaiji.vue?vue&type=template&id=367b92d9&scoped=true"
import script from "./gerenxinxicaiji.vue?vue&type=script&lang=js"
export * from "./gerenxinxicaiji.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "367b92d9",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/bium-ops-web_2zId/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('367b92d9')) {
      api.createRecord('367b92d9', component.options)
    } else {
      api.reload('367b92d9', component.options)
    }
    module.hot.accept("./gerenxinxicaiji.vue?vue&type=template&id=367b92d9&scoped=true", function () {
      api.rerender('367b92d9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/auth/componens/gerenxinxicaiji.vue"
export default component.exports