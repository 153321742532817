var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "welcome-container" }, [
    _vm._m(0),
    _c("div", { staticClass: "bannerHome" }, [
      _c(
        "h1",
        { staticClass: "master-title wow animate__animated animate__fadeInUp" },
        [_vm._v("专业的电商供应链融资平台")]
      ),
      _c(
        "h2",
        { staticClass: "sub-title wow animate__animated animate__fadeInUp" },
        [_vm._v("电商极速收款")]
      ),
      _c("div", { staticClass: "btn-box" }, [
        _c(
          "button",
          { staticClass: "btn-anniu", on: { click: _vm.handleGotoPage } },
          [
            _c("i", {
              staticClass: "iconfont icon-shoushimima",
              staticStyle: { "font-size": "28px !important" },
            }),
            _vm._v("\n        立即使用\n      "),
          ]
        ),
      ]),
    ]),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "header staticHeader" }, [
      _c("div", { staticClass: "welcome-logo-box" }, [
        _c("div", { staticClass: "logo" }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "bk_gray pt30 pb50" }, [
      _c("div", { staticClass: "wc-inner" }, [
        _c(
          "h3",
          { staticClass: "homeTitle wow animate__animated animate__fadeInUp" },
          [_vm._v("我们的优势")]
        ),
        _c(
          "p",
          { staticClass: "homeDesc wow animate__animated animate__fadeInUp" },
          [_vm._v("我们区别于其他产品的六大优势")]
        ),
        _c(
          "div",
          {
            staticClass: "advantage-ul wow animate__animated animate__fadeInUp",
          },
          [
            _c("div", { staticClass: "advantage-item" }, [
              _c("div", { staticClass: "advantage-item-icon" }, [
                _c("i", { staticClass: "iconfont icon-shuyi_qiye-jianzhu" }),
              ]),
              _c("div", { staticClass: "advantage-item-title" }, [
                _vm._v("平台产品优势"),
              ]),
              _c("div", { staticClass: "advantage-item-content" }, [
                _vm._v(
                  "跨平台综合管理:支持多平台店铺融资，覆盖抖音、快手、拼多多"
                ),
              ]),
            ]),
            _c("div", { staticClass: "advantage-item" }, [
              _c("div", { staticClass: "advantage-item-icon" }, [
                _c("i", { staticClass: "iconfont icon-renzhengbaogaoicon" }),
              ]),
              _c("div", { staticClass: "advantage-item-title" }, [
                _vm._v("门槛低"),
              ]),
              _c("div", { staticClass: "advantage-item-content" }, [
                _vm._v(
                  "企业成立满6个月，店铺成立满3个月即可融资，销售数据为王不依赖征信"
                ),
              ]),
            ]),
            _c("div", { staticClass: "advantage-item" }, [
              _c("div", { staticClass: "advantage-item-icon" }, [
                _c("i", { staticClass: "iconfont icon-jiangdichengben" }),
              ]),
              _c("div", { staticClass: "advantage-item-title" }, [
                _vm._v("融资费率低"),
              ]),
              _c("div", { staticClass: "advantage-item-content" }, [
                _vm._v(
                  "根据商户经营情况动态调整融资费率，及时降低店铺运营成本。"
                ),
              ]),
            ]),
            _c("div", { staticClass: "advantage-item" }, [
              _c("div", { staticClass: "advantage-item-icon" }, [
                _c("i", { staticClass: "iconfont icon-report" }),
              ]),
              _c("div", { staticClass: "advantage-item-title" }, [
                _vm._v("无抵押无担保"),
              ]),
              _c("div", { staticClass: "advantage-item-content" }, [
                _vm._v("单户最高可获1000万额度，无需质押及担保"),
              ]),
            ]),
            _c("div", { staticClass: "advantage-item" }, [
              _c("div", { staticClass: "advantage-item-icon" }, [
                _c("i", { staticClass: "iconfont icon-dianpu1" }),
              ]),
              _c("div", { staticClass: "advantage-item-title" }, [
                _vm._v("无需更换店铺"),
              ]),
              _c("div", { staticClass: "advantage-item-content" }, [
                _vm._v("日常销售在自有店铺内进行，商家运营后数据全留存"),
              ]),
            ]),
            _c("div", { staticClass: "advantage-item" }, [
              _c("div", { staticClass: "advantage-item-icon" }, [
                _c("i", {
                  staticClass: "iconfont icon-zhanghujiaoyimingxifenxi",
                }),
              ]),
              _c("div", { staticClass: "advantage-item-title" }, [
                _vm._v("全线上操作"),
              ]),
              _c("div", { staticClass: "advantage-item-content" }, [
                _vm._v("线上开户，线上提款，动动手指轻松融资"),
              ]),
            ]),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }