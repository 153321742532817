var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { width: "61.8%", visible: _vm.myVisible },
      on: {
        "update:visible": function ($event) {
          _vm.myVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "auth-tips-title",
          attrs: { slot: "title" },
          slot: "title",
        },
        [
          _vm._v("\n\t\t" + _vm._s(_vm.country) + "企业企业认证所需资料"),
          _c("span", [_vm._v("为保证认证顺利，请提前准备好以下资料")]),
        ]
      ),
      _c("div", { staticClass: "auth-tips-content" }, [
        _c("p", { staticClass: "tips" }, [
          _vm._v(
            "附件仅支持 JPG、JPEG、PNG、PDF 四种格式，单个文件大小不超过5M"
          ),
        ]),
        _c("h3", { staticClass: "sub-title" }, [
          _vm._v("\n\t\t\t一、企业证件\n\t\t"),
        ]),
        _vm.country === "中国大陆"
          ? _c(
              "div",
              { staticClass: "sub-content" },
              [
                _c("p", [
                  _vm._v(
                    "中国大陆：统一社会信用代码(三证合一无需准备其他企业证件)、组织机构代码证、税务登记证"
                  ),
                ]),
                _vm._l(_vm.pics1, function (p) {
                  return _c(
                    "el-link",
                    {
                      key: p.href,
                      attrs: {
                        href: p.href,
                        target: "_blank",
                        underline: false,
                      },
                    },
                    [
                      _c("el-button", { attrs: { size: "small" } }, [
                        _vm._v(_vm._s(p.label)),
                      ]),
                    ],
                    1
                  )
                }),
              ],
              2
            )
          : _vm.country === "香港"
          ? _c(
              "div",
              { staticClass: "sub-content" },
              [
                _c("p", [
                  _vm._v(
                    "香港：注册证书(CI)、商业登记证(BR)、团体成立表格(NC)、周年申报表(NAR1)"
                  ),
                ]),
                _vm._l(_vm.pics1_xg, function (p) {
                  return _c(
                    "el-link",
                    {
                      key: p.href,
                      attrs: {
                        href: p.href,
                        target: "_blank",
                        underline: false,
                      },
                    },
                    [
                      _c("el-button", { attrs: { size: "small" } }, [
                        _vm._v(_vm._s(p.label)),
                      ]),
                    ],
                    1
                  )
                }),
              ],
              2
            )
          : _c("div", { staticClass: "sub-content" }, [
              _c("p", [_vm._v("请提供企业注册及正常运营的相关证件")]),
              _c("p", [
                _vm._v(
                  "美国：注册证书(Certificate of Incorporation)、公司章程(Articles of Association)、年检证书(Annual Inspection Certificate)、董事名册(Register of Directors)、股东名册(Register of Members)"
                ),
              ]),
              _c("p", [
                _vm._v(
                  "澳大利亚：注册证书(Certificate of Incorporation)、公司章程(Articles of Association)、年检证书(Annual Inspection Certificate)、董事册 (Register of Directors)"
                ),
              ]),
              _c("p", [
                _vm._v(
                  "新西兰：注册证书(Certificate of Incorporation)、年检证书(Annual Inspection Certificate)、董事名册(Register of Directors)、股东名册(Register of Members)"
                ),
              ]),
            ]),
        _c("h3", { staticClass: "sub-title" }, [
          _vm._v("\n\t\t\t二、重要组成人员身份证明文件\n\t\t"),
        ]),
        _c(
          "div",
          { staticClass: "sub-content" },
          [
            ["中国大陆", "香港"].includes(_vm.country)
              ? _c(
                  "p",
                  [
                    _vm._v(
                      "法定代表人、企业董事的身份证正面持照照片、反面证件照片，实际控股人和持股10%及以上的股东的身份证正/反面照片"
                    ),
                    _vm.country === "香港"
                      ? [_vm._v("(或护照及持照照片)")]
                      : _vm._e(),
                    _vm._v("；"),
                  ],
                  2
                )
              : _c("p", [
                  _vm._v(
                    "公司董事及持股10%以上的股东身份证正反面照片、手持护照照片1张(美国国籍)；"
                  ),
                ]),
            _vm._l(_vm.pics2, function (p) {
              return _c(
                "el-link",
                {
                  key: p.href,
                  attrs: { href: p.href, target: "_blank", underline: false },
                },
                [
                  _c("el-button", { attrs: { size: "small" } }, [
                    _vm._v(_vm._s(p.label)),
                  ]),
                ],
                1
              )
            }),
          ],
          2
        ),
        _c("h3", { staticClass: "sub-title" }, [
          _vm._v("\n\t\t\t三、第三方证明文件\n\t\t"),
        ]),
        _c(
          "div",
          { staticClass: "sub-content" },
          [
            _c(
              "p",
              [
                _vm._v("若您是被授权人，请填写"),
                _c("el-link", { attrs: { type: "primary" } }, [
                  _vm._v("授权书(点击下载)"),
                ]),
                _vm._v("，加盖公司公章(个体工商户可按法人代表手印)"),
              ],
              1
            ),
            _vm._l(_vm.pics3, function (p) {
              return _c(
                "el-link",
                {
                  key: p.href,
                  attrs: { href: p.href, target: "_blank", underline: false },
                },
                [
                  _c("el-button", { attrs: { size: "small" } }, [
                    _vm._v(_vm._s(p.label)),
                  ]),
                ],
                1
              )
            }),
          ],
          2
        ),
        _c("h3", { staticClass: "sub-title" }, [
          _vm._v("\n\t\t\t四、开户申请表\n\t\t"),
        ]),
        _c(
          "div",
          { staticClass: "sub-content" },
          [
            _c(
              "p",
              [
                _vm._v("请填写开"),
                _c("el-link", { attrs: { type: "primary" } }, [
                  _vm._v("户申请表(点击下载)"),
                ]),
                _vm._v("后加盖公司公章(个体工商户可按法人代表手印)"),
              ],
              1
            ),
            _vm._l(_vm.pics4, function (p) {
              return _c(
                "el-link",
                {
                  key: p.href,
                  attrs: { href: p.href, target: "_blank", underline: false },
                },
                [
                  _c("el-button", { attrs: { size: "small" } }, [
                    _vm._v(_vm._s(p.label)),
                  ]),
                ],
                1
              )
            }),
          ],
          2
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.onStart },
            },
            [_vm._v("开始认证")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary", plain: "" },
              on: { click: _vm.onClose },
            },
            [_vm._v("还没装备好资料")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }