var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialog-standard channel-config-rate",
      attrs: {
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "show-close": false,
        fullscreen: _vm.isToFullScreen,
        width: "1000px",
        top: "100px",
        visible: "",
      },
    },
    [
      _c(
        "section",
        {
          staticClass: "dialog-header",
          attrs: { slot: "title" },
          slot: "title",
        },
        [
          _c("span", { staticClass: "dialog-title" }, [
            _vm._v(
              _vm._s(_vm.modalType === _vm.infoStatus[0] ? "配置" : "查看") +
                "费率"
            ),
          ]),
          _c("span", { staticClass: "right-icon" }, [
            _c("i", {
              class: [
                _vm.isToFullScreen ? "el-icon-news" : "el-icon-full-screen",
              ],
              on: { click: _vm.setFullScreen },
            }),
            _c("i", {
              staticClass: "el-icon-close",
              on: { click: _vm.handleCancel },
            }),
          ]),
        ]
      ),
      _c("section", { staticClass: "dialog-body" }, [
        _c("ul", { staticClass: "body-view-infos" }, [
          _c("li", { staticClass: "word-break" }, [
            _c("label", [_vm._v("渠道名称：")]),
            _vm._v(_vm._s(_vm.modalData.name) + "\n      "),
          ]),
          _c("li", { staticClass: "word-break" }, [
            _c("label", [_vm._v("渠道注册时间：")]),
            _vm._v(_vm._s(_vm.modalData.created) + "\n      "),
          ]),
          _c("li", { staticClass: "word-break" }, [
            _c("label", [_vm._v("渠道等级：")]),
            _vm._v(
              _vm._s(_vm.modalData.level == 2 ? "二级" : "三级") + " \n      "
            ),
          ]),
          _c(
            "li",
            { staticClass: "word-break" },
            [
              _c("label", [_vm._v("渠道状态：")]),
              _c(
                "el-select",
                {
                  on: { change: _vm.submitStatus },
                  model: {
                    value: _vm.status,
                    callback: function ($$v) {
                      _vm.status = $$v
                    },
                    expression: "status",
                  },
                },
                _vm._l(_vm.statusList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { value: item.value, label: item.label },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "table-list" },
          [
            _c("p", { staticClass: "tips" }, [
              _vm._v("\n        配置渠道费率方案\n      "),
            ]),
            _c("avue-crud", {
              ref: "crud",
              attrs: {
                "table-loading": _vm.isLoading,
                option: _vm.option,
                data: _vm.dataList,
              },
              scopedSlots: _vm._u([
                {
                  key: "channelRate",
                  fn: function ({ row }) {
                    return [
                      _vm.modalType === _vm.infoStatus[0]
                        ? _c("el-input-number", {
                            attrs: {
                              precision: 2,
                              step: 0.1,
                              min: row.minFundRate,
                            },
                            model: {
                              value: row.channelRate,
                              callback: function ($$v) {
                                _vm.$set(row, "channelRate", $$v)
                              },
                              expression: "row.channelRate",
                            },
                          })
                        : _c("span", [_vm._v(_vm._s(row.channelRate))]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c(
        "section",
        { staticClass: "dialog-footer" },
        [
          _vm.modalType === _vm.infoStatus[0]
            ? [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.isSubmiting,
                        expression: "isSubmiting",
                      },
                    ],
                    attrs: { type: "primary", icon: "el-icon-circle-check" },
                    on: { click: _vm.handleConfirm },
                  },
                  [_vm._v("保存")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "cancel",
                    attrs: { icon: "el-icon-circle-close" },
                    on: { click: _vm.handleCancel },
                  },
                  [_vm._v("取消")]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }