<template>
  <div class="selection-box">
    <div class="p30">
      <div class="selection-box">
        <el-alert title="实名认证信息" :closable="false" type="info" />
        <div class="smrz-section">
          <div class="result">
            <div class="result-left">
              <i :class="[ authDTO.status !== '2' ? 'el-icon-error fc-red' : 'el-icon-success fc-green', 'result-icon']" />
              <div class="result-text">{{ authDTO.retMsg }}</div>
              <div class="result-btns mt30">
                <div class="result-btns-item">
                  <el-button v-if="authDTO.status === '1'" type="primary" plain @click="queryAuthEnter">认证查询</el-button>
                  <el-button v-if="authDTO.status === '0'" type="primary" plain @click="authEnter">立刻认证</el-button>
                </div>
              </div>
            </div>
            <div class="result-right">
              <div class="result-list-item">
                <div class="result-list-item-label">企业名称</div>
                <div class="result-list-item-value">{{ authDTO.enterName }}</div>
              </div>
              <div class="result-list-item">
                <div class="result-list-item-label">认证链接</div>
                <div class="result-list-item-value">{{ authDTO.authUrl }}</div>
              </div>
              <div class="result-list-item">
                <div class="result-list-item-label">认证状态</div>
                <div class="result-list-item-value">{{ authDTO.statusName }}</div>
              </div>
              <div class="result-list-item">
                <div class="result-list-item-label">认证信息</div>
                <div class="result-list-item-value">{{ authDTO.retMsg }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="selection-box mt20">
        <el-alert title="授权书信息" :closable="false" type="info" />
        <div>
          <el-table
            :data="tableData"
            border
            style="width: 100%"
          >
            <el-table-column
              prop="docName"
              label="名称"
            />
            <el-table-column
              prop="statusName"
              label="状态"
              align="center"
            />
            <el-table-column
              fixed="right"
              label="操作"
            >
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.status==0 ||scope.row.status==1||scope.row.status==7||scope.row.status==8||scope.row.status==9"
                  type="text"
                  size="small"
                  class="mr10"
                  @click="signEnter(scope.row.docCode)"
                >签署</el-button>
                <!-- <el-button v-if="scope.row.status==2 ||scope.row.status==3||scope.row.status==4||scope.row.status==5 " type="text" size="small" class="mr10" @click="querySign(scope.row.docCode)">查询</el-button>-->
                <DownloadFile v-if="scope.row.status==6" :file-id="scope.row.fileId">
                  合同下载
                </DownloadFile>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <el-dialog title="验证手机" width="600px" :visible.sync="dialogFormVisible">
        <div class="mt20 mb20 fb" style="text-indent: 130px">授权码已发送至法人手机号: <span class="f18 fc-blue-t">{{ 13000000001 }}</span></div>
        <el-form ref="caForm" :model="caForm" :rules="caRules">
          <el-form-item label="填写授权码" :label-width="'120px'" prop="code">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-input v-model="caForm.code" placeholder="请输入验证码">
                  <i slot="prefix" class="el-icon-tickets" />
                </el-input>
              </el-col>
              <el-col :span="6">
                <el-button :disabled="disabled" @click="sendCode">{{ btnText }}</el-button>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleSignCA">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { doGet } from '@/router/axios';
import DictSelect from '@/views/components/DictSelect.vue';
import DictField from '@/views/components/DictField.vue';
import DownloadFile from '@/views/components/DownloadFile.vue';

export default {
  components: { DictSelect, DictField, DownloadFile },
  props: {
    eId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      enterId: '',
      loading: false,
      authDTO: { enterName: '', isAuth: '0' },
      signetDto: [],
      ststusFlag: 0,
      dialogFormVisible: false, // 签署协议弹框
      disabled: true, // 控制发送短信按钮状态
      caForm: {
        code: ''
      },
      btnText: '重新发送',
      time: 60,
      countDown: 60,
      tableData: [{
        no: 1,
        docName: '',
        status: '',
        retMsg: '',
        authUrl: ''
      }],
      caRules: {
        code: [
          { required: true, message: '验证码不能为空', trigger: 'blur' }
        ]
      }
    };
  },
  created() {
    const self = this;
    if (self.validatenull(self.$route.query['enterId'])) { // 判断enterId是否为空
      self.enterId = self.eId;
    } else {
      self.enterId = self.$route.query.enterId;
    }
    self.initData();
  },

  methods: {
    handleClick(row) {
      console.log(row);
    },

    /**
     * 签署文件
     */
    handleSignCA() {
      this.$refs.caForm.validateField('code', errorMessage => {
        if (errorMessage) {
          this.$message.error(errorMessage);
        } else {
          this.$parent.handleStepNext(); // 跳转到下一步
        }
      });
    },
    queryAuthEnter() {
      const that = this;
      this.loading = true;
      doGet('/esgin/queryAuthStatus/' + this.enterId).then(res => {
        if (res.data.code == 200) {
          this.loading = false;
          that.initData();
        } else {
          that.initData();
          this.loading = false;
          this.$message.error(res.data.msg);
        }
      });
    },
    authEnter() {
      const that = this;
      this.loading = true;
      doGet('/esgin/authEnter/' + this.enterId).then(res => {
        if (res.data.code == 200) {
          this.loading = false;
          that.initData();
        } else {
          this.loading = false;
          that.initData();
          this.$message.error(res.data.msg);
        }
      });
    },
    signEnter(tempCode) {
      const that = this;
      this.loading = true;
      doGet('/esgin/signEnter/' + this.enterId + '/' + tempCode).then(res => {
        if (res.data.code == 200) {
          this.loading = false;
          that.initData();
        } else {
          that.initData();
          this.loading = false;
          this.$message.error(res.data.msg);
        }
      });
    },
    querySign(tempCode) {
      const that = this;
      this.loading = true;
      doGet('/esgin/querySignStatus/' + this.enterId + '/' + tempCode).then(res => {
        if (res.data.code == 200) {
          this.loading = false;
          that.initData();
        } else {
          that.initData();
          this.loading = false;
          this.$message.error(res.data.msg);
        }
      });
    },
    initData() {
      const enterId = this.enterId;
      const that = this;
      doGet('/esgin/queryAuth/' + enterId).then(res => {
        if (res.data.code == 200) {
          that.authDTO = res.data.data.authDto;

          that.tableData = res.data.data.signetDto;
        }
      });
    },
    handleSendMsg() {
      this.dialogFormVisible = true;
      this.sendCode();
    },
    /**
     * 发送短信验证码
     */
    sendCode() {
      // 1.时间开始倒数
      // 2.按钮进入禁用状态
      // 3.如果倒计时结束 按钮恢复可用状态 按钮文字变为重新发送, 时间重置
      // 4.倒计时的过程中 按钮文字为 多少s后重新发送
      const timer = setInterval(() => {
        this.time--;
        this.btnText = `${this.time}s后重新发送`;
        this.disabled = true;
        if (this.time === 0) {
          this.disabled = false;
          this.btnText = '重新发送';
          this.time = this.countDown;
          clearInterval(timer);
        }
      }, 1000);
      this.$emit('send');
    }
  }
};
</script>

<style scoped>
.smrz-section {
  border-collapse: collapse;
  vertical-align: top;
  background-color: #fff;
  border-left: 1px solid #EBEEF5;
  border-right: 1px solid #EBEEF5;
  border-bottom: 1px solid #EBEEF5;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 20px;
  .result {
    padding: 30px 0 40px;
    display: flex;
    .result-left {
      width: 374px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px 0;
      .result-icon {
        font-size: 50px;
      }
      .result-text {
        font-size: 24px;
        line-height: 24px;
        color: #333;
        margin-top: 30px;
        font-weight: 500;
      }
    }
    .result-right {
      flex: 1;
      border-left: 1px dashed #e2e5ed;
      padding: 10px 0 10px 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .result-list-item {
        font-weight: 400;
        font-size: 12px;
        line-height: 30px;
        display: flex;
        .result-list-item-label {
          width: 125px;
          color: #666;
        }
        .result-list-item-value {
          color: #333;
          flex: 1;
        }
      }
    }
  }
}
.auth-box {
  .el-col {
    line-height: 32px;
  }
}
</style>
