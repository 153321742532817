var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "avue-sidebar" },
    [
      _c("logo"),
      _c(
        "el-scrollbar",
        { staticStyle: { height: "100%" } },
        [
          _vm.validatenull(_vm.menu)
            ? _c("div", { staticClass: "avue-sidebar--tip" }, [
                _vm._v("没有发现菜单"),
              ])
            : _vm._e(),
          _c(
            "el-menu",
            {
              attrs: {
                "unique-opened": "",
                "default-active": _vm.nowTagValue,
                mode: "vertical",
                "show-timeout": 200,
                collapse: _vm.keyCollapse,
              },
            },
            [
              _c("sidebar-item", {
                attrs: {
                  menu: _vm.menu,
                  screen: _vm.screen,
                  first: "",
                  collapse: _vm.keyCollapse,
                  props: _vm.website.menu.props,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }