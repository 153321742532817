<template>
  <!--<el-select v-model="selectKey" :placeholder="placeholder" style="width: 100%;" @change="change" :disabled="disabled"  :readonly="readonly" >-->
  <el-select v-model="selectKey" filterable :disabled="disabled" :placeholder="placeholder" style="width: 100%;" @change="change" >
    <el-option v-for="item in zones" :key="item.value" :label="item.label" :value="String(item.value)"/>
  </el-select>
</template>

<script>
import { doGet } from '@/router/axios'
import { dealNull } from '@/filters/index'

export default {
  name: 'DictSelect',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    no: {
      type: String,
      required: true
    },
    initVa: {
      type: String,
      required: false,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    },
    placeholder: {
      type: String,
      default: '请选择类型'
    }
  },
  data() {
    return {
      selectKey: dealNull(this.value) != '-' ? String(this.value) : '',
      zones: []
    }
  },
  watch: {
    value(newValue, oldValue) {
      this.selectKey = dealNull(newValue) != '-' ? String(newValue) : ''
    }
  },
  created() {
    this.getTree()
  },
  methods: {
    getTree() {
      doGet(`/dict/type/${this.no}`).then(res => {
        this.zones = res.data.data
      })
    },
    change(val) {
      this.$emit('change', val)
    }
  }
}
</script>
