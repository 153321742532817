<template>
	<div class="acc-add-jg">
		<el-alert type="alert-blue" show-icon :closable="false">
			<div slot="title">温馨提示：</div>
			
			<div class="fc-blue-t">
				您需要开通平台监管账户！请填写您的
				实体账户
				作为验证账户。
			</div>
		</el-alert>
		<!-- 表单 -->
		<div class="selection-box" v-loading="isLoading">
			<el-form ref="formRef" :model="dataForm.model" :rules="dataForm.rules" label-position="right"
				label-width="120px" size="small">
				<el-button  type="primary">使用提现账户作为验证账户</el-button>
				<el-alert class="mt10"  title="监管账户绑定" :closable="false" type="info" />
				<div class="mt20 inner-box">
					<el-form-item class="pr20" label="选择账户类型" prop="cardNature">
						<dict-select v-model="dataForm.model.cardNature" no="D000110" placeholder="选择账户类型"
							@change="changeCardNature" />
					</el-form-item>
					<!-- 账户具体信息 -->
					<div class="bk_gray pt20 pr20 pb10">
						<div class="flex-c-s">
							<div class="fc-blue-t  pl50 ">开户验证账户信息</div>
							<!-- <div class="pr10 ">拍照识别<i class="el-icon-upload f20"></i></div> -->
							<div>
							<dict-upload v-model="dataForm.fileIds" :limit="1" type-code="F0301" biz-id="123456" type="text" @change="onFileChange($event, 'fileIds')" />
							</div>
						</div>
					
					</div>
					<!-- 账户具体信息 -->
					<div class="bk_gray pt5 pr20 pb20">
						
						<el-form-item label="账户名称" prop="bankUserName">
							<el-input v-model="dataForm.model.bankUserName" placeholder="请输入户名"  />
						</el-form-item>
						<el-form-item label="账户号" prop="bankCard">
							<el-input v-model="dataForm.model.bankCard" placeholder="识别后自动填入可修改" />
						</el-form-item>
						<el-form-item label="所属银行" prop="bankCode">
							<!-- <div v-if="this.jianSheType != 1">
								<dict-select v-model="dataForm.model.bankCode" no="D000109" placeholder="请选择所属银行"
									@change="changeBankCode" />
							</div>
							<div v-else style="color: #409eff;font-size: 12px;">建设银行</div> -->
							<el-input v-model="dataForm.model.bankCode" placeholder="识别后自动填入可修改" />
						</el-form-item>
						<el-form-item label="选择支行" prop="provinceCode">
							<DictSelectSingle v-model="dataForm.model.provinceCode" :clearable="false"
								url="/cap/capBankCustInfo/province" placeholder="请选择省份" style="width: 120px"
								@change="changeProvince" />
							&nbsp;
							<DictSelectSingle v-if="dataForm.model.provinceCode" v-model="dataForm.model.cityCode"
								:clearable="false" :url="cityUrl" :key="refreshCityDom" placeholder="请选择城市"
								style="width: 120px" @change="changeCity" />
							&nbsp;
							<DictSelectSingle v-if="dataForm.model.bankCode && dataForm.model.cityCode"
								v-model="dataForm.model.bankSubNo" :clearable="false" :url="bankUrl"
								:key="refreshBankDom" placeholder="请选择支行" style="width: 200px" @change="changeBank" />
						</el-form-item>
						<!-- <el-form-item label="银行支行号" prop="bankSubNo">
							<el-input v-model="dataForm.model.bankSubNo" placeholder="请输入银行支行号" readonly />
						</el-form-item> -->
						<!-- 私户需填银行预留手机号
						<el-form-item v-if="isLanHai || dataForm.model.cardNature == 1" label="银行预留手机号"
							prop="mobilePhone">
							<el-input v-model="dataForm.model.mobilePhone" placeholder="请输入银行预留手机号" />
						</el-form-item> -->
					</div>

					<!-- 勾选协议 -->
					<span v-if="this.jianSheType == 1" style="font-size: 12px;color:red;">为了更优质的融资服务，请填写建设银行账户！</span>
					<el-checkbox class="agreement-check" v-model="isAgreement">
						本人已仔细阅读并完全理解、接受如下文件，接受该等文件的约束
						<a class="link-able" @click="jumpToUrl('F0304')">《资金监管服务协议》</a>
						<span v-if="this.jianSheType != 1">
							、
							<a class="link-able" @click="jumpToUrl('F0305')">《保理合同》</a>
						</span>
					</el-checkbox>

					<div class="mt20" style="text-align: center">
						<el-button type="primary" size="medium" :disabled="!isAgreement" :loading="isSubmiting"
							@click="saveCard()">
							确认提交
						</el-button>
					</div>
				</div>
			</el-form>
		</div>
	</div>
</template>

<script>
	import {
		doGet,
		doPost
	} from '@/router/axios'
	import {
		isMobile
	} from '@/utils/validate'
	import {
		toOpenAgreement
	} from '@/utils/util'
	// 子组件
	import DictUpload from '@/views/components/DictUpload';
	import DictSelect from '@/views/components/DictSelect.vue'
	import DictSelectSingle from '@/views/components/DictSelectSingle'

	export default {
		name: 'CompanyIndex',
		components: {
			DictSelect,
			DictUpload,
			DictSelectSingle,
		},
		props: {},
		data() {
			return {
				// url带入参数
				enterId: '',
				isLanHai: false,
				// 控制参数
				isLoading: true,
				isSubmiting: false,
				isSending: false,
				isSentCode: false,
				isAgreement: false,
				sendTimerCount: 60,
				// 其他
				enterName: '',
				legalName: '',
				// 表单数据
				dataForm: {
					model: {
						cardNature: '',
						bankUserName: '',
						bankCard: '',
						bankCode: '',
						bankName: '',
						provinceCode: '',
						cityCode: '',
						bankSubNo: '',
						mobilePhone: '',
					},
					rules: {
						cardNature: [{
							required: true,
							trigger: 'change',
							message: '请选择账户类型'
						}],
						bankUserName: [{
							required: true,
							trigger: 'change',
							message: '请填写户名'
						}],
						bankCard: [{
							required: true,
							trigger: 'blur',
							message: '请填写银行卡号'
						}],
						bankCode: [{
							required: true,
							trigger: 'change',
							message: '请选择所属银行'
						}],
						provinceCode: [{
							required: true,
							trigger: 'change',
							message: '请选择省市'
						}],
						bankSubNo: [{
							required: true,
							trigger: 'change',
							message: '请输入支行号'
						}],
						mobilePhone: [{
								required: true,
								trigger: 'blur',
								message: '请填写银行预留手机号'
							},
							{
								trigger: 'blur',
								validator: (field, value, cb) => {
									if (value && !isMobile(value)) {
										cb(new Error('请输入正确的手机号码'))
										return
									}
									cb()
								},
							},
						],
					},
				},
				refreshCityDom: 0,
				refreshBankDom: 0,
				cityUrl: '',
				bankUrl: '',
				jianSheType: null,
			}
		},
		created() {
			const {
				enterId,
				thirdChannel,
				type
			} = this.$route.query
			if (type == 1) {
				this.jianSheType = type
				this.dataForm.model.bankCode = '105100000017'
				this.dataForm.model.bankName = null
				this.dataForm.model.bankSubNo = ''
				this.refreshBankDom = new Date().getTime()
			} else {
				this.jianSheType = null
			}
			this.enterId = enterId || ''
			this.isLanHai = thirdChannel === 'acct_lanhai'
			this.initData()
		},
		mounted() {},
		methods: {
			/** 获取企业名称和法人名称 */
			initData() {
				doGet('/cust/enter/getEnterprise/' + this.enterId)
					.then(({
						data
					}) => {
						if (data.code == 200) {
							const {
								data: {
									name,
									legalName
								},
							} = data
							this.enterName = name || ''
							this.legalName = legalName || ''
						}
						this.isLoading = false
					})
					.catch((e) => {
						this.isLoading = false
					})
			},

			/** 修改账户类型 */
			changeCardNature(val) {
				this.dataForm.model.cardNature = val
				// 1：私户，2：公户
				if (val == 1) {
					this.dataForm.model.bankUserName = this.legalName
				} else if (val == 2) {
					this.dataForm.model.bankUserName = this.enterName
				} else {
					this.dataForm.model.bankUserName = ''
				}
			},
			/** 修改所属银行 */
			changeBankCode(val, name) {
				this.dataForm.model.bankCode = val
				this.dataForm.model.bankName = name
				this.dataForm.model.bankSubNo = ''
				this.bankUrl = `/cap/capBankCustInfo/bank/${this.dataForm.model.cityCode}/${val}`
				this.refreshBankDom = new Date().getTime()
			},
			/** 修改省份 */
			changeProvince(val) {
				this.dataForm.model.provinceCode = val
				this.dataForm.model.cityCode = ''
				this.dataForm.model.bankSubNo = ''
				this.cityUrl = `/cap/capBankCustInfo/city/${val}`
				this.refreshCityDom = new Date().getTime()
			},
			/** 修改城市 */
			changeCity(val) {
				this.dataForm.model.cityCode = val
				this.dataForm.model.bankSubNo = ''
				this.bankUrl = `/cap/capBankCustInfo/bank/${val}/${this.dataForm.model.bankCode}`
				this.refreshBankDom = new Date().getTime()
			},
			/** 修改支行 */
			changeBank(val) {
				this.dataForm.model.bankSubNo = val
			},

			/** 提交保存 */
			saveCard() {
				if (!this.isSubmiting && this.isAgreement) {
					this.$refs.formRef.validate((valid) => {
						console.log('===监管账户表单信息', this.dataForm.model)
						if (valid) {
							const {
								bankCard,
								cardNature
							} = this.dataForm.model
							const trimBankCard = bankCard ? bankCard.replace(/\s/gi, '') : '' //去空格
							let sendData = {}
							let apiPath = ''
							if (this.isWakeUpBind) {
								// 蓝海银行重新绑卡
								const {
									serialNum
								} = this.$route.query
								const bankInfos = JSON.parse(sessionStorage.getItem('prevBankinfos'))
								const {
									bankUserName,
									bankCode,
									bankSubNo,
									mobilePhone
								} = this.dataForm.model
								const newBankinfos = {
									...bankInfos,
									accountAttr: cardNature == 1 ? 'D' : 'YB',
									bankAccountName: bankUserName,
									bankAccountNo: trimBankCard,
									bankName: bankCode,
									netBankNo: bankSubNo,
									bankAccountMobile: mobilePhone,
								}
								sendData = {
									serialNum,
									param: JSON.stringify(newBankinfos),
								}
								apiPath = '/fundManage/flow/wakeUp'
							} else {
								// 正常开通绑银行卡
								sendData = {
									...this.dataForm.model,
									bankCard: trimBankCard,
									enterId: this.enterId,
									orgType: cardNature == 1 ? 'individual' :
									'enterprise', //individual-个体工商户, enterprise-企业, constomer-个人用户(暂无)
									accType: '05',
								}
								apiPath = '/cap/account/bindCard'
							}
							// 调取接口
							this.isSubmiting = true
							doPost(apiPath, sendData)
								.then(({
									data
								}) => {
									if (data.code == 200) {
										this.$notify.success('提交成功，请注意查收签约短信，完成协议签署')
										this.$router.push({
											path: '/cap/index',
											query: {
												enterId: this.enterId
											},
										})
									}
								})
								.catch((e) => {
									this.isSubmiting = false
								})
						}
					})
				}
			},
			/** 跳转查看协议 */
			jumpToUrl(name) {
				toOpenAgreement(name)
			}
		},
	}
</script>
<style lang="scss" scoped>
	.acc-add-jg {
		.selection-box {
			margin-top: 10px;

			.inner-box {
				width: 720px;
			}
		}

		.agreement-check {
			margin-left: 10px;
			margin-top: 8px;

			/deep/.el-checkbox__label {
				font-size: 12px;
				color: #333;
			}
		}
	}
</style>