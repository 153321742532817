<template>
  <div class="selection-box sign-docuemnt">
    <el-alert title="企业签约信息" :closable="false" type="info" />

    <div class="sign_container">
      <!--签约信息确认-->
      <template v-if="flag == 0">
        <div class="sign_img">
          <img src="@/assets/icon/icon_sign.png" alt="" />
        </div>
        <ul class="cert_ul">
          <li>法人姓名：{{ cForm.legalName }}</li>
          <li>法人身份证号码：{{ cForm.legalIdCard }}</li>
          <li>商户名称：{{ cForm.name }}</li>
          <li>社会统一信用代码：{{ cForm.creditCode }}</li>
          <li>
            <el-checkbox v-model="agreeChecked" @change="selectAuth" />
            本人已仔细阅读并完全理解、接受如下文件，接受该等文件的约束《企业信息授权书》、《服务协议及授权确认书》
          </li>
        </ul>
      </template>
      <!--签约信息审核中-->
      <template v-else-if="flag == 3">
        <el-result title="签约信息审核中" sub-title="签约信息审核中,审核期5-10工作日，请等待！">
          <template slot="icon">
            <i class="iconfont icon-clock_circle_fill f60 fc-blue-t" />
          </template>
        </el-result>
      </template>
      <!-- 签约成功 -->
      <template v-else-if="flag == 6">
        <el-result icon="success" sub-title="签约成功" />
      </template>
      <!-- 错误提示 -->
      <template v-else-if="flag == 9 || flag == 8 || flag == 7">
        <el-result icon="error" title="错误提示" sub-title="请根据提示进行操作" />
      </template>
    </div>

    <div v-if="tableData.length > 1" class="selection-box mt20" v-loading="isLoading">
      <el-alert title="授权书信息" :closable="false" type="info" />
      <div>
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column prop="docName" label="名称" />
          <el-table-column prop="statusName" label="状态" align="center" />
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <DownloadFile v-if="scope.row.status == 6" :file-id="scope.row.fileId">合同下载</DownloadFile>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { doGet } from '@/router/axios'
import DownloadFile from '@/views/components/DownloadFile.vue'

export default {
  name: 'SignDocument',
  components: { DownloadFile },
  props: {
    eId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoading: false,
      isSending: false,
      enterId: '',
      cForm: {},
      agreeChecked: false,
      tableData: [
        {
          no: 1,
          docName: '',
          status: '',
          retMsg: '',
          authUrl: '',
        },
      ],
      flag: 2, // 0: 展示协议 1: 展示审核阶段 2: 通过审核
    }
  },
  created() {
    const { enterId } = this.$route.query
    this.enterId = this.eId || enterId || ''
    this.initEnterpriseData()
    this.initAuth()
  },
  methods: {
    /** 获取企业信息 */
    initEnterpriseData() {
      doGet('/cust/enter/getEnterprise/' + this.enterId).then(({ data }) => {
        if (data.code === 200) {
          this.cForm = { ...data.data }
        }
      })
    },
    /** 获取认证信息 */
    initAuth() {
      this.isLoading = true
      doGet('/esgin/queryAuth/' + this.enterId).then(({ data }) => {
        if (data.code == 200) {
          const { signetDto = [] } = data.data || {}
          this.tableData = signetDto || []
          if (this.tableData.length) {
            this.flag = this.tableData[0].status
            this.$parent.singFlag = this.tableData[0].status
            const showLastSign1 = this.tableData[2] && this.tableData[2].status == 0
            const showLastSign2 = this.tableData[3] && this.tableData[3].status == 0
            this.$parent.lastSign = showLastSign1 || showLastSign2 ? 0 : '-1'
          }
        }
        this.isLoading = false
      })
    },

    selectAuth() {
      if (this.agreeChecked) {
        this.$parent.btSign = false
      } else {
        this.$parent.btSign = true
      }
    },
    /** 父组件引用-签署协议 */
    signEnter(tempCode) {
      if (!this.isSending) {
        this.isSending = true
        doGet(`/esgin/signEnter/${this.enterId}/${tempCode}`).then(({ data }) => {
          if (data.code == 200) {
            this.initAuth()
            this.$parent.singFlag = 1
            let tipText = data.msg
            if (tempCode === 'supervise') {
              tipText = '请注意查收签约短信，完成协议签署'
            }
            this.$message.success(tipText)
          }
          this.isSending = false
        }).catch(() => {
          this.isSending = false
        })
      }
    },
    querySign() {
      if (!this.isSending) {
        this.isSending = true
        doGet('/esgin/querySignStatus/' + this.enterId + '/loan').then(({ data }) => {
          if (data.code == 200) {
            this.initAuth()
          }
          this.isSending = false
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.sign_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .sign_img {
    margin: 0 20px;
    width: 200px;
    height: 200px;
  }
}
.cert_ul {
  margin: 40px;
  li {
    height: 48px;
    line-height: 48px;
  }
}
</style>
