<template>
  <div class="mz-layout-section company-auth-list">
    <div class="tableList_title">企业权限列表</div>
    <el-divider />

    <avue-crud
      ref="crud"
      v-model="form"
      :option="option"
      :page.sync="page"
      :table-loading="listLoading"
      :data="list"
      @on-load="getList"
      @size-change="sizeChange"
      @current-change="currentChange"
      @search-change="handleFilter"
      @search-reset="resetFilter"
      @row-update="updateForm">
      <template slot="menu" slot-scope="scope">
        <el-button type="text" icon="el-icon-edit" @click="handleUpdate(scope.row, scope.index)">操作员设置</el-button>
      </template>
    </avue-crud>
  </div>
</template>

<script>
import { doGet, doDelet, doPost } from '@/router/axios'
const tableOption = {
  searchMenuSpan: 8,
  searchLabelWidth: 80,
  searchMenuPosition: 'left',
  border: false,
  index: false,
  indexLabel: '序号',
  stripe: true,
  header: false,
  labelWidth: 100,
  menuWidth: 120,
  refreshBtn: false,
  columnBtn: false,
  editBtn: false,
  delBtn: false,
  align: 'left',
  addBtn: false,
 searchShowBtn:false,
 column: [
    {
      fixed: true,
      label: '企业编号',
      width: 120,
      prop: 'code',
      editDisplay: true,
      addDisplay: true,
      search: true,
      searchPlaceholder: '请输入企业编号',
      disabled: true,
      searchTags: true,
      searchSpan: 8,
      span: 24,
    },
    {
      label: '企业名称',
      prop: 'name',
      minWidth: 200,
      editDisplay: false,
      addDisplay: false,
      disabled: true,
      search: true,
      searchPlaceholder: '请输入企业名称',
      searchSpan: 8,
      span: 24,
    },
    {
      label: '店铺名称',
      prop: 'shopName',
      editDisplay: false,
      addDisplay: false,
      searchSpan: 8,
      span: 12,
    },
    {
      label: '统一社会信用码',
      prop: 'creditCode',
      width: 180,
      editDisplay: true,
      disabled: true,
      addDisplay: true,
      span: 24,
    },
    {
      label: '法人姓名',
      prop: 'legalName',
      editDisplay: true,
      addDisplay: true,
      disabled: true,
      width: 120,
      span: 24,
    },
    {
      label: '法人手机号',
      prop: 'legalPhone',
      editDisplay: false,
      addDisplay: false,
      width: 120,
      span: 12,
    },
    {
      label: '管理员',
      prop: 'staffIdList',
      type: 'select',
      dicUrl: '/common/type/D000138',
      multiple: true,
      editDisplay: true,
      addDisplay: true,
      searchSpan: 8,
      span: 24,
    },
    {
      label: '创建时间',
      prop: 'createTime',
      hide: true,
      editDisplay: false,
      addDisplay: false,
      span: 12,
      width: 150,
    },
  ],
}

export default {
  name: 'CompanyAuthList',
  components: {},
  data() {
    return {
      option: tableOption,
      searchItmes: {},
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条,
      },
      list: [],
      listLoading: true,
      form: {},
    }
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    getList(page, params) {
      params = params ? params : { ...this.searchItmes }
      params.current = page.currentPage - 1
      params.size = page.pageSize
      this.listLoading = true
      doGet('/cust/enter/staffPage', params).then(({ data }) => {
        if (data) {
          const { dataList, totalElements } = data.data || {}
          this.list = dataList || []
          this.page.total = totalElements || 0
        }
        this.listLoading = false
      })
    },
    updateForm(form, index, done) {
      const data = {}
      data.custId = form.staffIdList
      data.enterId = form.id
      console.log('提交的参数-->' + JSON.stringify(data))
      doPost('/cust/set/setStaff', data)
        .then((res) => {
          if (res.data.code == 200) {
            this.getList(this.page)
            this.$notify.success('设置成功')
            done()
          }
        })
    },

    sizeChange(size) {
      this.page.pageSize = size
    },
    currentChange(current) {
      this.page.currentPage = current
    },
    handleFilter(param, done) {
      this.page.currentPage = 1
      this.searchItmes = param ? param : {}
      this.getList(this.page, param)
      done()
    },
    resetFilter() {
      this.page.currentPage = 1
      this.searchItmes = {}
      this.getList(this.page, this.searchItmes)
    },
    handleUpdate(row, index) {
      this.$refs.crud.rowEdit(row, index)
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/ .el-tabs__nav-scroll {
  padding-left: 10px;
}
/deep/ .el-divider {
  background-color: #ecedef;
}
</style>
