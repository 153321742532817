var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "selection-box" },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, "label-width": "160px" } },
        [
          _c(
            "el-form-item",
            {
              staticStyle: { margin: "20px 0 30px !important" },
              attrs: { label: "公司信息：" },
            },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.handleChangeCompany },
                  model: {
                    value: _vm.form.companyName,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "companyName", $$v)
                    },
                    expression: "form.companyName",
                  },
                },
                _vm._l(_vm.companyNameOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-divider",
            {
              staticClass: "title-divider",
              attrs: { "content-position": "left" },
            },
            [_vm._v("付款信息")]
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "付款方账号：", prop: "cargoBusinessId" },
                    },
                    [
                      _c("el-input", {
                        attrs: { autocomplete: "off" },
                        model: {
                          value: _vm.form.creditAmt,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "creditAmt", $$v)
                          },
                          expression: "form.creditAmt",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "付款方户名：", prop: "cargoBusinessId" },
                    },
                    [
                      _c("el-input", {
                        attrs: { autocomplete: "off" },
                        model: {
                          value: _vm.form.orderAmt,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "orderAmt", $$v)
                          },
                          expression: "form.orderAmt",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "银行名称：", prop: "cargoBusinessId" } },
                    [
                      _c("el-input", {
                        attrs: { autocomplete: "off" },
                        model: {
                          value: _vm.form.maxApplyAmt,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "maxApplyAmt", $$v)
                          },
                          expression: "form.maxApplyAmt",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "开户行支行：", prop: "cargoBusinessId" },
                    },
                    [
                      _c("el-input", {
                        attrs: { autocomplete: "off" },
                        model: {
                          value: _vm.form.discountRate,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "discountRate", $$v)
                          },
                          expression: "form.discountRate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "银行名称：", prop: "cargoBusinessId" } },
                    [
                      _c("el-input", {
                        attrs: { autocomplete: "off" },
                        model: {
                          value: _vm.form.maxApplyAmt,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "maxApplyAmt", $$v)
                          },
                          expression: "form.maxApplyAmt",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "开户行支行：", prop: "cargoBusinessId" },
                    },
                    [
                      _c("el-input", {
                        attrs: { autocomplete: "off" },
                        model: {
                          value: _vm.form.discountRate,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "discountRate", $$v)
                          },
                          expression: "form.discountRate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-divider",
            {
              staticClass: "title-divider",
              attrs: { "content-position": "left" },
            },
            [_vm._v("收款信息")]
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "收款方账号：", prop: "cargoBusinessId" },
                    },
                    [
                      _c("el-input", {
                        attrs: { autocomplete: "off" },
                        model: {
                          value: _vm.form.creditAmt,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "creditAmt", $$v)
                          },
                          expression: "form.creditAmt",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "收款方户名：", prop: "cargoBusinessId" },
                    },
                    [
                      _c("el-input", {
                        attrs: { autocomplete: "off" },
                        model: {
                          value: _vm.form.orderAmt,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "orderAmt", $$v)
                          },
                          expression: "form.orderAmt",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "银行名称：", prop: "cargoBusinessId" } },
                    [
                      _c("el-input", {
                        attrs: { autocomplete: "off" },
                        model: {
                          value: _vm.form.maxApplyAmt,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "maxApplyAmt", $$v)
                          },
                          expression: "form.maxApplyAmt",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "开户行支行：", prop: "cargoBusinessId" },
                    },
                    [
                      _c("el-input", {
                        attrs: { autocomplete: "off" },
                        model: {
                          value: _vm.form.discountRate,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "discountRate", $$v)
                          },
                          expression: "form.discountRate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "银行名称：", prop: "cargoBusinessId" } },
                    [
                      _c("el-input", {
                        attrs: { autocomplete: "off" },
                        model: {
                          value: _vm.form.maxApplyAmt,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "maxApplyAmt", $$v)
                          },
                          expression: "form.maxApplyAmt",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "开户行支行：", prop: "cargoBusinessId" },
                    },
                    [
                      _c("el-input", {
                        attrs: { autocomplete: "off" },
                        model: {
                          value: _vm.form.discountRate,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "discountRate", $$v)
                          },
                          expression: "form.discountRate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tc mt50" },
        [
          _c(
            "el-button",
            {
              staticClass: "mr20",
              attrs: { size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.handleReset("form")
                },
              },
            },
            [_vm._v("重 置")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                size: "medium",
                type: "primary",
                loading: _vm.btnLoading,
              },
              on: { click: _vm.handleSave },
            },
            [_vm._v("提 交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }