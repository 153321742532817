var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "repay-section mt20" },
      [
        _c("avue-crud", {
          attrs: { data: _vm.billForm.dtoList, option: _vm.tableOption },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }