import request from '@/router/axios'

// 查询缓存详细
export function getCache() {
  return request({
    url: '/monitor/cache',
    method: 'get'
  })
}

// 清空所有缓存
export function cleanAll() {
  return request({
    url: '/monitor/cache/clean',
    method: 'post',
  })
}

// 查询缓存列表
export function cacheList(key) {
  return request({
    url: '/monitor/cache/list',
    method: 'get',
    params: key
  })
}

// 根据key值清空缓存
export function cleanCache(key) {
  return request({
    url: '/monitor/cache/cleanCache/' + key,
    method: 'delete'
  })
}