<template>
  <div class="details-warp">
    <el-form ref="form" :model="form" label-width="80px" label-position="left">
      <div class="details-section ml20 mr20">
        <h3 class="details-section-h3"><span>订单基本信息</span></h3>
        <el-row :gutter="40" class="pl20 pr20">
          <el-col :span="12">
            <el-form-item label="编号：">
              <el-input v-model="form.orderNo" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="产品：">
              <el-input v-model="form.productName" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="资方名称：">
              <el-input v-model="form.fundName" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="计费方式：">
              <dict-select v-model="form.rateType" no="D000082" disabled="true" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="申请金额：">
              <el-input v-model="form.applyAmt" readonly>
                <template slot="suffix">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="放款金额：">
              <el-input v-model="form.loanAmt" readonly>
                <template slot="suffix">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="状态：">
              <el-input v-model="form.statusName" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="折扣率：">
              <el-input v-model="form.discountRate" readonly>
                <template slot="suffix">%</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收款账号：">
              <el-input v-model="form.reciveAcc" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收款姓名：">
              <el-input v-model="form.reciveName" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收款支行：">
              <el-input v-model="form.reciveBankSub" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="放款时间：">
              <el-input v-model="form.loanTime" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="利率：">
              <el-input v-model="form.inverestRate" readonly>
                <template slot="suffix">%</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="回执单号：">
              <el-input v-model="form.receiptNo" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="申请信息：">
              <el-input v-model="form.applyMsg" type="textarea" :rows="4" readonly />
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div class="details-section mt20 ml20 mr20">
        <h3 class="details-section-h3"><span>订单详情</span></h3>
        <vocher-list :order="form" />
      </div>
    </el-form>
  </div>
</template>

<script>
import { doGet } from '@/router/axios';
import vocherList from './voucherList';
import DictSelect from "@/views/components/DictSelect.vue";
export default {
  name: 'PageDetails',
  components: {DictSelect, vocherList },
  props: {
    modalData: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      id: '',
      form: {}
    };
  },
  watch: {
    modalData(val, oldVal) {
      console.log('modalData:' + val + '--oldVal' + oldVal);
      // 这里执行初始化方法
      this.initForm(val);
    }
  },
  mounted() {
    this.initForm(this.modalData);
  },
  methods: {
    initForm: function(id) {
      const that = this;
      doGet(`/rent/order/` + id).then(res => {
        that.form = res.data.data;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.details-warp {
  height: calc(100vh - 300px);
  overflow: hidden;
  overflow-y: auto;
}
</style>
