var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "selection-box" }, [
    _c(
      "section",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.isApiCreating,
            expression: "isApiCreating",
          },
        ],
      },
      [
        _c(
          "el-divider",
          {
            staticClass: "title-divider",
            attrs: { "content-position": "left" },
          },
          [_vm._v("渠道企业认证")]
        ),
        !_vm.isAccLoading
          ? _c("div", { staticClass: "tx-acc" }, [
              _c("div", { staticClass: "corporate-box" }, [
                _c("div", { staticClass: "corporate-item" }, [
                  _c("p", { staticClass: "fc_blue corporate-item-p" }, [
                    _vm._v("为了保证必米供应链与您的结算和后续的开票"),
                  ]),
                  _c("p", { staticClass: "fc_blue corporate-item-p" }, [
                    _vm._v("请您登记认证您的渠道结算企业"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "c_btn" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.handleReprotNewCompany()
                            },
                          },
                        },
                        [_vm._v("开始认证您的企业")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "corporate-item" },
                  [
                    _c("el-result", {
                      attrs: {
                        icon: "success",
                        title: "",
                        "sub-title": "渠道企业认证成功",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e(),
        _c(
          "section",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.isAccLoading,
                expression: "isAccLoading",
              },
            ],
            staticClass: "acc-container",
          },
          [
            _c(
              "el-divider",
              {
                staticClass: "title-divider",
                attrs: { "content-position": "left" },
              },
              [_vm._v("结算账户开户")]
            ),
            !_vm.isAccLoading
              ? _c("div", { staticClass: "jg-acc" }, [
                  _c("div", { staticClass: "corporate-box" }, [
                    _c("div", { staticClass: "corporate-item" }, [
                      _c("p", { staticClass: "fc_blue corporate-item-p" }, [
                        _vm._v("请您开通苏商银行结算账户"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "c_btn" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleBindAcc()
                                },
                              },
                            },
                            [_vm._v("开始申请开通")]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "corporate-item" },
                      [
                        _c("el-result", {
                          attrs: {
                            icon: "success",
                            title: "",
                            "sub-title": "苏商银行结算户开户成功",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "corporate-box" }, [
                    _c("div", { staticClass: "corporate-item" }, [
                      _c("p", { staticClass: "fc_blue corporate-item-p" }, [
                        _vm._v("请您开通富友支付结算账户"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "c_btn" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleBindAcc()
                                },
                              },
                            },
                            [_vm._v("开始申请开通")]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "corporate-item" },
                      [
                        _c("el-result", {
                          attrs: {
                            icon: "success",
                            title: "",
                            "sub-title": "富友支付结算账户开户成功",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            _c(
              "el-divider",
              {
                staticClass: "title-divider",
                attrs: { "content-position": "left" },
              },
              [_vm._v("等待审核")]
            ),
            !_vm.isAccLoading
              ? _c("div", { staticClass: "corporate-box" }, [_vm._m(0)])
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "corporate-item flex-c-c" }, [
      _c("div", [
        _c("p", { staticClass: "fc_blue corporate-item-p" }, [
          _vm._v("完成以上步骤后，运营人员将会对您的渠道进行审核"),
        ]),
        _c("p", { staticClass: "fc_blue corporate-item-p" }, [
          _vm._v("您可以主动联系您对接的专属运营人员"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }