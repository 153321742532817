var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "selection-box" },
    [
      _c("el-alert", {
        attrs: { title: "添加预审批店铺", closable: false, type: "info" },
      }),
      _c(
        "el-form",
        {
          ref: "shopForm",
          staticClass: "cForm",
          attrs: {
            model: _vm.shopForm,
            rules: _vm.shopRules,
            "label-position": "right",
            "label-width": "160px",
            size: "small",
          },
        },
        [
          _c(
            "div",
            { staticClass: "mt10 inner-box" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "店铺名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入店铺名称",
                      disabled: _vm.disabled,
                    },
                    model: {
                      value: _vm.shopForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.shopForm, "name", $$v)
                      },
                      expression: "shopForm.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "店铺ID", prop: "shopId" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入店铺Id",
                      disabled: _vm.disabled,
                    },
                    model: {
                      value: _vm.shopForm.shopId,
                      callback: function ($$v) {
                        _vm.$set(_vm.shopForm, "shopId", $$v)
                      },
                      expression: "shopForm.shopId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "店主账户Id", prop: "shopkeeperId" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入店主账户Id",
                      disabled: _vm.disabled,
                    },
                    model: {
                      value: _vm.shopForm.shopkeeperId,
                      callback: function ($$v) {
                        _vm.$set(_vm.shopForm, "shopkeeperId", $$v)
                      },
                      expression: "shopForm.shopkeeperId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "店主手机号", prop: "shopkeeperPhone" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.disabled,
                      placeholder: "请输入店主手机号",
                    },
                    model: {
                      value: _vm.shopForm.shopkeeperPhone,
                      callback: function ($$v) {
                        _vm.$set(_vm.shopForm, "shopkeeperPhone", $$v)
                      },
                      expression: "shopForm.shopkeeperPhone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "所属平台", prop: "liveStreamPlat" } },
                [
                  _c("dict-select", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      disabled: _vm.disabled,
                      placeholder: "请选择店铺类型",
                      no: "D000101",
                    },
                    on: { change: _vm.liveStreamPlat_change },
                    model: {
                      value: _vm.shopForm.liveStreamPlat,
                      callback: function ($$v) {
                        _vm.$set(_vm.shopForm, "liveStreamPlat", $$v)
                      },
                      expression: "shopForm.liveStreamPlat",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "配置日期", prop: "cofigDate" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      disabled: _vm.disabled,
                      "value-format": "yyyy-MM-dd",
                      type: "date",
                      placeholder: "选择配置日期",
                    },
                    model: {
                      value: _vm.shopForm.cofigDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.shopForm, "cofigDate", $$v)
                      },
                      expression: "shopForm.cofigDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "订单数据", prop: "fileBoutId" } },
                [
                  _c("dict-upload", {
                    attrs: {
                      type: "text",
                      limit: 1,
                      "type-code": "F0109",
                      "biz-id": "123456",
                      tips: "提示：ZIP压缩包",
                      disabled: _vm.disabled,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.onFileChange($event, "fileBoutId")
                      },
                    },
                    model: {
                      value: _vm.shopForm.fileBoutId,
                      callback: function ($$v) {
                        _vm.$set(_vm.shopForm, "fileBoutId", $$v)
                      },
                      expression: "shopForm.fileBoutId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "申请意见", prop: "applyMsg" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", placeholder: "请输入审批意见" },
                    model: {
                      value: _vm.shopForm.applyMsg,
                      callback: function ($$v) {
                        _vm.$set(_vm.shopForm, "applyMsg", $$v)
                      },
                      expression: "shopForm.applyMsg",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "tc mt30" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.saveShopInfo },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }