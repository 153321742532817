<template>
  <pane-container class="fieldset-tips" :title="title">
    <div class="ct">
      <slot></slot>
    </div>
  </pane-container>
</template>
<script>
import PaneContainer from '../PaneContainer'
export default {
  name: 'FieldsetTip',
  components: {
    PaneContainer
  },
  props: {
    title: {
      type: String,
      default: '温馨提示'
    }
  }
}
</script>
<style lang="scss" scoped>
  .fieldset-tips {
    .ct {
      font-size: 12px;
      // font-weight: 300;
      line-height: 28px;
      color: #555;
    }
  }
</style>