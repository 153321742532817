<template>
  <div class="user">
    <basic-container>
      <avue-crud
        ref="crud"
        v-model="form"
        :option="option"
        :page.sync="page"
        :table-loading="loading"
        :before-open="handleOpenBefore"
        :data="list"
        @on-load="getList"
        @size-change="sizeChange"
        @search-change="handleFilter"
        @refresh-change="handleRefreshChange"
      >
        <template
          slot="menu"
          slot-scope="scope"

        >
          <el-button
            type="text"
            icon="el-icon-delete"
            @click="handleForceLogout(scope.row,scope.index)"
          >强退
          </el-button>
        </template>
      </avue-crud>
    </basic-container>
  </div>
</template>

<script>
import { list, forceLogout } from '@/api/monitor/online'

export default {
  name: 'Online',
  data() {
    return {
      option: logUserOption,
      // 遮罩层
      loading: true,
      // 总条数
      total: 0,
      // 表格数据
      list: [],
      pageNum: 1,
      pageSize: 10,



      // 查询参数
      queryParams: {
        ipaddr: '',
        userName: ''
      },
      page: {
        total: 0, // 总页数
        size: 10, // 每页显示多少条,
        isAsc: false,// 是否倒序
        currentPage: 1
      },
      form: {}
    }
  },
  created() {
    // this.getList()
  },
  methods: {
    /** 查询在线用户 */
    getList(page, params) {
      if (params === undefined || params == null || params === '') {
        params = {}
      }
      this.loading = true
      const obj = {
        ...params,
        'current': page.currentPage - 1,
        'size': page.pageSize
      }
      list(obj).then(response => {
        this.list = response.data.dataList
        // this.total = response.data.totalElements
        this.page.total = response.data.totalElements
        this.loading = false
      })
    },
    handleOpenBefore(show, type) {
      window.boxType = type
      // 若是编辑、查看回显名称
      if (['edit', 'views'].includes(type)) {

      } else if (type === 'add') {

      }
      show()
    },
    handleRefreshChange() {
      this.getList(this.page)
    },
    sizeChange(size) {
      this.page.size = size
    },
    /** 搜索按钮操作 */
    handleFilter(param, done) {
      this.page.currentPage = 1
      this.getList(this.page, param)
      done()
    },
    /** 强退按钮操作 */
    handleForceLogout(row) {
      this.$confirm('是否确认强退名称为"' + row.userName + '"的数据项?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function() {
        return forceLogout(row.tokenId)
      }).then((res) => {
        if(res.data.code == 200){
          this.getList(this.page);
          this.$notify.success(res.data.msg);
        }else if(res.data.code == 500){
          this.$notify.warning(res.data.msg);
        }else{
          this.$notify.error(res.data.msg);
        }

      })
    }
  }
}

export const logUserOption = {
  menuWidth:100,
  border: false,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menualign: 'left',
  searchMenuSpan: 6,
  editBtn: false,
  delBtn: false,
  align: 'left',
  addBtn: false,
 searchShowBtn:false,
 column: [{
    fixed: true,
    label: '会话编号',
    prop: 'tokenId',
    span: 12,
    hide: true,
    width: 130,
    editDisplay: false,
    addDisplay: false
  }, {
    fixed: true,
    label: '登录名称',
    prop: 'userName',
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: true,
      trigger: 'blur',
      message: '请输入登录名称'
    }],
    search: true,
    span: 12
  }, {
    fixed: true,
    label: '部门名称',
    prop: 'deptName',
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: true,
      trigger: 'blur',
      message: '请输入部门名称'
    }],
    span: 12
  }, {
    fixed: true,
    label: 'IP地址',
    prop: 'ipaddr',
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: true,
      trigger: 'blur',
      message: '请输入IP地址'
    }],
    search: true,
    span: 12
  }, {
    fixed: true,
    label: '登录地点',
    prop: 'loginLocation',
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: true,
      trigger: 'blur',
      message: '请输入登录地点'
    }],
    span: 12
  }, {
    fixed: true,
    label: '浏览器',
    prop: 'browser',
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: true,
      trigger: 'blur',
      message: '请输入浏览器'
    }],
    span: 12
  }, {
    fixed: true,
    label: '操作系统',
    prop: 'os',
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: true,
      trigger: 'blur',
      message: '请输入操作系统'
    }],
    span: 12
  }, {
    fixed: true,
    label: '登录时间',
    prop: 'loginTime',
    type: 'datetime',
    format: 'yyyy-MM-dd HH:mm:ss',
    valueFormat: 'timestamp',
    editDisplay: true,
    addDisplay: true,
    width: 150,
    rules: [{
      required: true,
      trigger: 'blur',
      message: '请输入登录时间'
    }],
    span: 12
  }, {
    fixed: true,
    label: '过期时间',
    prop: 'expireTime',
    type: 'datetime',
    format: 'yyyy-MM-dd HH:mm:ss',
    valueFormat: 'timestamp',
    editDisplay: true,
    addDisplay: true,
    width: 150,
    rules: [{
      required: true,
      trigger: 'blur',
      message: '请输入登录时间'
    }],
    span: 12
  }]
}
</script>

