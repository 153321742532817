<template>
  <div class="selection-box">
    <el-alert title="添加预审批店铺" :closable="false" type="info" />
    <el-form ref="shopForm" :model="shopForm" :rules="shopRules" :label-position="'right'" label-width="160px" size="small" class="cForm">
      <div class="mt10 inner-box">
        <el-form-item label="店主账户Id" prop="shopkeeperId">
          <el-input v-model="shopForm.shopkeeperId" placeholder="请输入店主账户Id" :disabled="disabled" />
        </el-form-item>
        <el-form-item label="店铺编号" prop="shopCode">
          <el-input v-model="shopForm.shopCode" placeholder="请输入店铺编码" :disabled="disabled" />
        </el-form-item>
        <el-form-item label="店铺名称" prop="name">
          <el-input v-model="shopForm.name" placeholder="请输入店铺名称" :disabled="disabled" />
        </el-form-item>
        <el-form-item label="所属平台" prop="liveStreamPlat">
          <dict-select v-model="shopForm.liveStreamPlat" :disabled="disabled" placeholder="请选择店铺类型" no="D000101" style="width: 100%;" @change="liveStreamPlat_change" />
        </el-form-item>
        <el-form-item label="店主手机号" prop="shopkeeperPhone">
          <el-input v-model="shopForm.shopkeeperPhone" :disabled="disabled" placeholder="请输入店主手机号" />
        </el-form-item>
        <el-form-item label="配置日期" prop="cofigDate">
          <el-date-picker v-model="shopForm.cofigDate" :disabled="disabled" value-format="yyyy-MM-dd" type="date" placeholder="选择配置日期" style="width: 100%" />
        </el-form-item>
        <el-form-item label="店铺数据" prop="fileShopId">
          <dict-upload
            v-model="shopForm.dataListId"
            :limit="2"
            type-code="F0106"
            biz-id="123456"
            :tips="'提示：Excel数据'"
            :disabled="disabled"
            @change="onFileChange($event, 'dataListId')"
          />
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import DictUpload from '@/views/components/DictUpload.vue';
import DictSelect from '@/views/components/DictSelect.vue';
import AddrSelect from '@/components/AddrSelect/index.vue';
import { doGet, doPost } from '@/router/axios';

export default {
  name: 'CompanyIndex',
  components: { DictUpload, DictSelect, AddrSelect },
  props: {
    modalData: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      disabled: false,
      enterId: '',
      shopForm: {
        id: '',
        shopkeeperId: '', // 营业期限
        name: '',
        shopCode: '',
        liveStreamPlat: '',
        shopkeeperPhone: '',
        fileShopId: []
      },
      shopRules: {
        enterId: [{ required: true, trigger: 'change', message: '所属企业不能为空' }],
        shopkeeperId: [{ required: true, trigger: 'change', message: '请输入店主账号Id' }],
        shopCode: [{ required: true, trigger: 'blur', message: '请填写店铺编号' }],
        name: [{ required: true, trigger: 'blur', message: '请填写店铺名称' }],
        liveStreamPlat: [{ required: true, trigger: 'blur', message: '请选择直播平台' }],
        shopkeeperPhone: [{ required: true, trigger: 'blur', message: '请填写店主手机号' }],
        cofigDate: [{ required: true, trigger: 'blur', message: '请填写配置日期' }],
        dataListId: [{ required: true, trigger: 'blur', message: '请上传店铺数据' }]
      }
    };
  },
  watch: {

  },
  methods: {
    liveStreamPlat_change(val) {
      this.shopForm.liveStreamPlat = val;
    },
    onFileChange(ids = [], key) {
      this.$refs.shopForm.validateField(key);
    },
    saveShopInfo() {
      this.$refs.shopForm.validate(valid => {
        if (valid) {
          const data = {
            ...this.shopForm
          };
          doPost('/channel/info/preApproval/', data).then(res => {
            this.loading = false;
            if (res.data.code === 200) {
              this.$notify({
                title: res.data.msg,
                type: 'success',
                duration: 2500
              });
            } else {
              this.$notify({
                title: res.data.msg,
                type: 'error',
                duration: 2500
              });
            }
          }).catch(e => {
            this.$notify({
              title: e,
              type: 'error',
              duration: 2500
            });
            this.loading = false;
          });
        } else {
          return false;
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.tips {
  font-size: 13px;
  line-height: 1.2;
  color: #666;

  /deep/ .el-link {
    font-size: 13px;
  }
}

.selection-box {
  margin-top: 10px;
}

.inner-box {
  width: 720px;
  margin-left: auto;
  margin-right: auto;
}
</style>
