<template>
  <div class="user">
    <basic-container>
      <avue-crud
        ref="crud"
        v-model="form"
        :option="option"
        :page.sync="page"
        :table-loading="listLoading"
        :before-open="handleOpenBefore"
        :data="list"
        @on-load="getList"
        @size-change="sizeChange"
        @current-change="currentChange"
        @search-change="handleFilter"
        @refresh-change="handleRefreshChange"
        @row-update="update"
        @row-save="create"
        @selection-change="selectionChange"
      >
        <template slot="menuLeft">
          <el-button
            class="filter-item"
            type="primary"
            icon="el-icon-edit"
            @click="$refs.crud.rowAdd()"
          >新增
          </el-button>
          <el-button
            class="filter-item"
            type="primary"
            icon="el-icon-plus"
            @click="copyAdd()"
          >复制新增
          </el-button>
          <el-button
            class="filter-item"
            type="success"
            icon="el-icon-message"
            @click="handleTemplate"
          >模板下载
          </el-button>
          <el-button
            class="filter-item"
            type="success"
            icon="el-icon-message"
            @click="handleExport"
          >导出
          </el-button>
          <el-button
            class="filter-item"
            type="danger"
            icon="el-icon-delete"
            @click="handleDelete"
          >删除
          </el-button>
        </template>

        <template
          slot="menu"
          slot-scope="scope"
        >
          <el-button
            type="primary"
            icon="el-icon-edit"
            @click="handleUpdate(scope.row,scope.index)"
          />
          <el-button
            type="danger"
            icon="el-icon-delete"
            @click="deletes(scope.row,scope.index)"
          />
        </template>

      </avue-crud>
    </basic-container>
  </div>
</template>
<script>
import { doGet, doPost, doDelet, doPut, doExportExcel, downExcel } from '@/router/axios';
import { mapGetters } from 'vuex';
export default {
  name: 'TableUser',
  components: {
  },
  data() {
    return {
      option: tableOption,
      defaultProps: {
        label: 'name',
        value: 'id'
      },
      dialogVisible: false,
      page: {
        total: 0, // 总页数
        current: 1, // 当前页数
        currentPage: 1,
        pageSize: 20,
        size: 20, // 每页显示多少条,
        isAsc: false// 是否倒序
      },
      list: [],
      selectedList: [],
      listLoading: true,
      form: {
      }
    };
  },
  computed: {

  },
  watch: {

  },
  created() {

  },
  methods: {
    selectionChange(list) {
      this.selectedList = list;
    },
    getNodeData(node) {
    },
    handleDelete() {
      if (this.selectedList.length == 0) {
        this.$notify.error('请选择删除数据');
        return;
      }
      this.$confirm('此操作将永久删除选择数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消', type: 'warning'
      }
      ).then(() => {
        this.listLoading = true;
        const ids = [];
        for (let i = 0; i < this.selectedList.length; i++) {
          ids.push(this.selectedList[i].id);
        }
        doDelet('/cap/bankInfo/delete/' + ids.join(',')).then(res => {
          if (res.data.code === 200) {
            this.getList(this.page);
            this.$notify.success('删除成功');
          } else {
            const retMs = res.data.msg;
            this.listLoading = false;
            this.$notify.error(retMs);
            return;
          }
        }).catch(() => {
          this.$notify.error('服务异常');
        });
      });
    },
    getList(page, params) {
      if (params == undefined || params == null || params == '') {
        params = {};
      }
      this.listLoading = true;
      params.current = page.currentPage - 1;
      params.size = page.pageSize;
      doGet('/cap/bankInfo/queryPage', params).then(res => {
        this.list = res.data.data.dataList;
        this.page.total = res.data.data.totalElements;
        this.listLoading = false;
      });
    },
    sizeChange(size) {
      this.page.size = size;
      this.page.pageSize = size;
    },
    currentChange(current) {
      this.page.current = current;
      this.page.currentPage = current;
    },
    handleFilter(param, done) {
      this.page.current = 1;
      this.page.currentPage = 1;
      this.getList(this.page, param);
      done();
    },
    copyAdd() {
      if (this.selectedList.length > 1 || this.selectedList.length == 0) {
        this.$notify.error('请选择复制数据');
        return;
      }
      this.form = this.selectedList[0];
      this.form.id = null;
      this.$refs.crud.rowAdd();
    },
    handleRefreshChange() {
      this.getList(this.page);
    },
    handleOpenBefore(show, type) {
      window.boxType = type;
      // 若是编辑、查看回显名称
      if (['edit', 'views'].includes(type)) {

      } else if (type === 'add') {

      }
      show();
    },
    handleUpdate(row, index) {
      this.$refs.crud.rowEdit(row, index);
    },
    handleExport() {
      this.listLoading = true;
      doExportExcel('/cap/bankInfo/export', this.form).then(res => {
      // 获取headers中的filename文件名

        downExcel(res);
        this.listLoading = false;
      });
    },
    handleTemplate() {
      this.listLoading = true;
      doExportExcel('/cap/bankInfo/exportTemplate', this.form).then(res => {
        downExcel(res);
        this.listLoading = false;
      });
    },
    create(row, done, loading) {
      this.listLoading = true;
      const params = {
        ...this.form
      };
      doPost('/cap/bankInfo/save', params).then(res => {
        if (res.data.code === 200) {
          this.getList(this.page);
          done();
          this.$notify.success('创建成功');
          return false;
        } else {
          const retMs = res.data.msg;
          this.listLoading = false;
          this.$notify.error(retMs);
          return;
        }
        loading();
      }).catch(() => {
        loading();
      });
    },
    update(row, index, done, loading) {
      const params = {
        ...this.form
      };
      doPut('/cap/bankInfo/update', params).then(res => {
        if (res.data.code === 200) {
          this.getList(this.page);
          done();
          this.$notify.success('更新成功');
          return;
        } else {
          const retMs = res.data.msg;
          this.listLoading = false;
          this.$notify.error(retMs);
          return;
        }
        loading();
      }).catch(() => {
        loading();
      });
    },
    deletes(row) {
      this.$confirm('此操作将永久删除选择数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消', type: 'warning'
      }
      ).then(() => {
        this.listLoading = true;
        doDelet('/cap/bankInfo/delete/' + row.id).then(res => {
          if (res.data.code === 200) {
            this.getList(this.page);
            this.$notify.success('删除成功');
          } else {
            const retMs = res.data.msg;
            this.listLoading = false;
            this.$notify.error(retMs);
            return;
          }
        }).catch(() => {
          this.$notify.error('服务异常');
        });
      });
    }
  }
};

export const tableOption = {
  border: false,
  selection: true,
  showSummary: false,
  sumColumnList: [
  ],
  printBtn: true,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menualign: 'left',
  menuWidth: 160,
  searchMenuSpan: 6,
  searchLabelWidth: 100,
  searchIndex: 3,
  searchIcon: true,
  searchShowBtn: false,
  labelWidth: 100,
  editBtn: false,
  delBtn: false,
  align: 'left',
  addBtn: false,
 searchShowBtn:false,
 column: [{
    fixed: true,
    label: 'id',
    prop: 'id',
    span: 24,
    hide: true,
    editDisplay: false,
    addDisplay: false
  },
  {
    label: '银行编号',
    prop: 'bankCode',
    dicUrl: '/dict/type/D000162',
    type: 'select',
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: true,
      message: '请输入选择银行'
    }
    ],
    span: 24
  },
  {
    label: '银行名称',
    prop: 'bankName',
    editDisplay: false,
    addDisplay: false,
    rules: [{
      required: false,
      message: '请输入银行名称'
    }
    ],
    search: true,
    span: 24
  },
  {
    label: '支行名称',
    prop: 'subName',
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: true,
      message: '请输入支行名称'
    }
    ],
    search: true,
    span: 24
  },
  {
    label: '支行名称',
    prop: 'remark',
    editDisplay: true,
    addDisplay: true,
    type: 'textarea',
    rules: [{
      required: false,
      message: '请输入支行名称'
    }
    ],
    span: 24
  },
  {
    label: '币种',
    prop: 'currencyType',
    type: 'select',
    dicUrl: '/dict/type/${column.dictType}',
    editDisplay: false,
    addDisplay: false,
    rules: [{
      required: false,
      message: '请输入币种'
    }
    ],
    hide: true,
    span: 12
  },
  {
    label: '银行类型',
    prop: 'type',
    type: 'select',
    dicUrl: '/dict/type/${column.dictType}',
    editDisplay: false,
    addDisplay: false,
    rules: [{
      required: false,
      message: '请输入银行类型'
    }
    ],
    hide: true,
    span: 12
  },
  {
    label: '创建时间',
    prop: 'createTime',
    editDisplay: false,
    addDisplay: false,
    rules: [{
      required: false,
      message: '请输入创建时间'
    }
    ],
    hide: true,
    span: 12
  },
  {
    label: '更新时间',
    prop: 'updateTime',
    editDisplay: false,
    addDisplay: false,
    rules: [{
      required: false,
      message: '请输入更新时间'
    }
    ],
    hide: true,
    span: 12
  }
  ]
};

</script>
