<template>
  <div id="registWrapper" class="regedit-container">
    <div class="r-header">
      <a href="/public" class="r-logo" />
    </div>
    <div class="t-title"><h1>注册和信数科账号</h1></div>
    <div class="r-wrap">
      <div class="r-content">
        <div class="regist-form-wrap">
          <el-form
            ref="regeditForm"
            class="regist-form"
            status-icon
            :rules="regeditRules"
            :model="regeditForm"
            size="medium">
            <el-form-item prop="custName">
              <el-input
                v-model="regeditForm.custName"
                class="qc-log-input-text"
                auto-complete="off"
                placeholder="请输入姓名"
                maxlength="40"
                @keyup.enter.native="handleRegedit" />
            </el-form-item>
            <div class="regist-form-tit">
              <span>登录信息</span>
            </div>
            <el-form-item prop="loginName">
              <el-input
                v-model="regeditForm.loginName"
                class="qc-log-input-text"
                auto-complete="off"
                placeholder="请输入手机号"
                maxlength="11"
                @keyup.enter.native="handleRegedit" />
            </el-form-item>

            <el-form-item prop="vcode">
              <el-input
                v-model="regeditForm.vcode"
                maxlength="4"
                class="qc-log-input-text"
                auto-complete="off"
                placeholder="请输入图形验证码">
                <div slot="suffix" class="input-suffix img-vcode">
                  <img :src="codeUrl" width="100%" height="100%" @click="getCode" />
                </div>
              </el-input>
            </el-form-item>
            <el-form-item prop="pcode" class="pcodeItem">
              <el-input
                v-model="regeditForm.pcode"
                maxlength="6"
                class="qc-log-input-text"
                auto-complete="off"
                placeholder="手机验证码">
                <div slot="suffix" class="input-suffix phone-code">
                  <el-button
                    type="primary"
                    :disabled="pCode.disabled"
                    size="medium"
                    style="margin-top: 1px;"
                    @click="onPhoneCode">
                    {{ pCode.text }}
                  </el-button>
                </div>
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                v-model="regeditForm.password"
                class="qc-log-input-text"
                :type="passwordType"
                auto-complete="off"
                placeholder="请输入密码"
                maxlength="20"
                @keyup.enter.native="handleRegedit">
                <i slot="suffix" class="el-icon-view el-input__icon" @click="showPassword" />
              </el-input>
            </el-form-item>

            <el-form-item prop="confirmPasswordInput">
              <el-input
                v-model="regeditForm.confirmPasswordInput"
                class="qc-log-input-text"
                :type="passwordType"
                auto-complete="off"
                maxlength="20"
                placeholder="请再次输入密码"
                @keyup.enter.native="handleRegedit">
                <i slot="suffix" class="el-icon-view el-input__icon" @click="showPassword" />
              </el-input>
            </el-form-item>
            <div class="form-ctrl-label-wrap">
              <label class="form-ctrl-label">
                <el-checkbox v-model="checked_protocol" />
                <span>
                  我已阅读并同意
                  <router-link to="/document/1220" tag="a" target="_blank" :append="true">
                    《平台用户注册协议》
                  </router-link>
                  、
                  <router-link to="/document/1221" tag="a" target="_blank" :append="true">《隐私政策》</router-link>
                </span>
              </label>
              <p v-show="valid_checked_flag" class="J-agreement-error">请勾选同意协议内容</p>
            </div>
            <el-form-item class="signup">
              <el-button type="primary" class="regedit-submit" :loading="loading" @click.native.prevent="handleRegedit">
                立即注册
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>

    <div class="r-wrap-after mb10">
      <a href="/login">已有和信数科账号，去登录 &gt;&gt;</a>
    </div>

    <div class="lg-footer">
      <div class="copyright">
        <p class="tag-line">
          Copyright © 2023-2023 Hexin Dig. All Rights Reserved.
          <span>和信数科&nbsp;版权所有</span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import '@/styles/login.scss'
import Cookies from 'js-cookie'
import { doPost } from '@/router/axios'
import { getCodeImg } from '@/api/login'
export default {
  name: 'Regedit',
  props: [],
  data() {
    const ConfirmPassword = (rule, value, callback) => {
      if (value !== this.regeditForm.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      pCode: {
        disabled: false,
        text: '获取验证码',
      },
      codeUrl: '',
      btMsg: '获取验证码',
      loading: false,
      checked_protocol: false,
      valid_checked_flag: false,
      regeditForm: {
        custName: '',
        loginName: '',
        password: '',
        confirmPasswordInput: '',
      },
      regeditRules: {
        custName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        loginName: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        confirmPasswordInput: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { trigger: 'blur', validator: ConfirmPassword },
        ],
        vcode: [{ required: true, message: '请输入图形验证码', trigger: 'blur' }],
        pcode: [{ required: true, message: '请输入手机验证码', trigger: 'blur' }],
      },
      passwordType: 'password',
    }
  },
  watch: {
    checked_protocol(newVal) {
      this.valid_checked_flag = !newVal
    },
  },
  mounted() {
    this.getCode()
  },
  methods: {
    getCode() {
      getCodeImg().then((res) => {
        this.codeUrl = 'data:image/gif;base64,' + res.data.data.img
        this.regeditForm.redomStr = res.data.data.uuid
      })
    },
    handleRegedit() {
      const that = this
      if (!this.checked_protocol) {
        this.valid_checked_flag = true
        return false
      }
      this.$refs.regeditForm.validate((valid) => {
        if (valid) {
          that.loading = true
          doPost('/cust/info/regCust', that.regeditForm)
            .then((res) => {
              if (res.data.code === 200) {
                that.$notify.success('注册成功')
                setTimeout(() => {
                  that.$router.push({ path: '/login' })
                }, 1000)
                return false
              } else {
                that.listLoading = false
                that.$notify.error(res.data.msg)
              }
            })
            .catch(() => {})
        }
        that.loading = false
      })
    },
    onPhoneCode() {
      const user = {
        loginName: this.regeditForm.loginName,
        vcode: this.regeditForm.vcode,
        uuid: this.regeditForm.redomStr,
        type: 1,
      }
      if (user.loginName == null || user.loginName == '') {
        this.$notify.error('请填下手机号码')
        return false
      }
      if (user.vcode == null || user.vcode == '') {
        this.$notify.error('请填写图形验证码')
        return false
      }
      this.pCode.disabled = true
      doPost('/cust/reg/sendCode', user)
        .then((res) => {
          this.loading = false
          if (res.data.code == 200) {
            this.setTimer()
          } else {
            this.getCode()
            this.$notify({
              title: res.msg,
              type: 'error',
              duration: 2500,
            })
            this.pCode.disabled = false
          }
        })
        .catch((e) => {
          this.getCode()
          this.loading = false
          this.pCode.disabled = false
        })
    },
    setTimer() {
      this.timer && clearInterval(this.timer)
      let count = 119
      this.timer = setInterval(() => {
        count--
        this.pCode.text = count + 's'
        if (count === 0) {
          this.pCode.text = '获取验证码'
          this.pCode.disabled = false
          clearInterval(this.timer)
        }
      }, 1000)
    },
    showPassword() {
      this.passwordType === '' ? (this.passwordType = 'password') : (this.passwordType = '')
    },
  },
}
</script>

<style lang="scss" scoped>
.r-header {
  height: 60px;
  box-sizing: border-box;
  background-color: #fff;
  border-bottom: #e5e5e5 1px solid;
  padding: 12px 0 0 20px;
}
.r-logo {
  width: 121px;
  height: 36px;
  // background-image: url('~@/assets/sprite/regist-cn-back.png');//xinghe
  // background-image: url('~@/assets/bg/hsrlogo-report.png');//hsr
  // background-image: url('~@/assets/bg/logo-reportBimi.png');//Hexin
   background-image: url('~@/assets/bg/hxlogo-report.png');//hexin
  display: block;
  text-indent: -10000px;
  overflow: hidden;
  outline: 0;
  background-size: contain;
  background-repeat: no-repeat;
}
a.go_to_login {
  text-decoration: none;
  cursor: pointer;
  color: #005980;
}
.J-agreement-error {
  text-align: left;
  color: rgb(225, 80, 74);
  font-size: 14px;
  margin-top: 5px;
}
.img-vcode,
.phone-code {
  margin-top: 4px;
  image {
    border-radius: 5px;
  }
}
/deep/.el-form-item .el-input__validateIcon {
  display: none;
}
</style>
