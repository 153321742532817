var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "regedit-container", attrs: { id: "registWrapper" } },
    [
      _vm._m(0),
      _vm._m(1),
      _c("div", { staticClass: "r-wrap" }, [
        _c("div", { staticClass: "r-content" }, [
          _c(
            "div",
            { staticClass: "regist-form-wrap" },
            [
              _c(
                "el-form",
                {
                  ref: "regeditForm",
                  staticClass: "regist-form",
                  attrs: {
                    "status-icon": "",
                    rules: _vm.regeditRules,
                    model: _vm.regeditForm,
                    size: "medium",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "custName" } },
                    [
                      _c("el-input", {
                        staticClass: "qc-log-input-text",
                        attrs: {
                          "auto-complete": "off",
                          placeholder: "请输入姓名",
                          maxlength: "40",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.handleRegedit.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.regeditForm.custName,
                          callback: function ($$v) {
                            _vm.$set(_vm.regeditForm, "custName", $$v)
                          },
                          expression: "regeditForm.custName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "regist-form-tit" }, [
                    _c("span", [_vm._v("登录信息")]),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "loginName" } },
                    [
                      _c("el-input", {
                        staticClass: "qc-log-input-text",
                        attrs: {
                          "auto-complete": "off",
                          placeholder: "请输入手机号",
                          maxlength: "11",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.handleRegedit.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.regeditForm.loginName,
                          callback: function ($$v) {
                            _vm.$set(_vm.regeditForm, "loginName", $$v)
                          },
                          expression: "regeditForm.loginName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "vcode" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "qc-log-input-text",
                          attrs: {
                            maxlength: "4",
                            "auto-complete": "off",
                            placeholder: "请输入图形验证码",
                          },
                          model: {
                            value: _vm.regeditForm.vcode,
                            callback: function ($$v) {
                              _vm.$set(_vm.regeditForm, "vcode", $$v)
                            },
                            expression: "regeditForm.vcode",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "input-suffix img-vcode",
                              attrs: { slot: "suffix" },
                              slot: "suffix",
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: _vm.codeUrl,
                                  width: "100%",
                                  height: "100%",
                                },
                                on: { click: _vm.getCode },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { staticClass: "pcodeItem", attrs: { prop: "pcode" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "qc-log-input-text",
                          attrs: {
                            maxlength: "6",
                            "auto-complete": "off",
                            placeholder: "手机验证码",
                          },
                          model: {
                            value: _vm.regeditForm.pcode,
                            callback: function ($$v) {
                              _vm.$set(_vm.regeditForm, "pcode", $$v)
                            },
                            expression: "regeditForm.pcode",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "input-suffix phone-code",
                              attrs: { slot: "suffix" },
                              slot: "suffix",
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-top": "1px" },
                                  attrs: {
                                    type: "primary",
                                    disabled: _vm.pCode.disabled,
                                    size: "medium",
                                  },
                                  on: { click: _vm.onPhoneCode },
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.pCode.text) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "password" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "qc-log-input-text",
                          attrs: {
                            type: _vm.passwordType,
                            "auto-complete": "off",
                            placeholder: "请输入密码",
                            maxlength: "20",
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.handleRegedit.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.regeditForm.password,
                            callback: function ($$v) {
                              _vm.$set(_vm.regeditForm, "password", $$v)
                            },
                            expression: "regeditForm.password",
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-view el-input__icon",
                            attrs: { slot: "suffix" },
                            on: { click: _vm.showPassword },
                            slot: "suffix",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "confirmPasswordInput" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "qc-log-input-text",
                          attrs: {
                            type: _vm.passwordType,
                            "auto-complete": "off",
                            maxlength: "20",
                            placeholder: "请再次输入密码",
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.handleRegedit.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.regeditForm.confirmPasswordInput,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.regeditForm,
                                "confirmPasswordInput",
                                $$v
                              )
                            },
                            expression: "regeditForm.confirmPasswordInput",
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-view el-input__icon",
                            attrs: { slot: "suffix" },
                            on: { click: _vm.showPassword },
                            slot: "suffix",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "form-ctrl-label-wrap" }, [
                    _c(
                      "label",
                      { staticClass: "form-ctrl-label" },
                      [
                        _c("el-checkbox", {
                          model: {
                            value: _vm.checked_protocol,
                            callback: function ($$v) {
                              _vm.checked_protocol = $$v
                            },
                            expression: "checked_protocol",
                          },
                        }),
                        _c(
                          "span",
                          [
                            _vm._v(
                              "\n                我已阅读并同意\n                "
                            ),
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: "/document/1220",
                                  tag: "a",
                                  target: "_blank",
                                  append: true,
                                },
                              },
                              [
                                _vm._v(
                                  "\n                  《平台用户注册协议》\n                "
                                ),
                              ]
                            ),
                            _vm._v("\n                、\n                "),
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: "/document/1221",
                                  tag: "a",
                                  target: "_blank",
                                  append: true,
                                },
                              },
                              [_vm._v("《隐私政策》")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.valid_checked_flag,
                            expression: "valid_checked_flag",
                          },
                        ],
                        staticClass: "J-agreement-error",
                      },
                      [_vm._v("请勾选同意协议内容")]
                    ),
                  ]),
                  _c(
                    "el-form-item",
                    { staticClass: "signup" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "regedit-submit",
                          attrs: { type: "primary", loading: _vm.loading },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.handleRegedit.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("\n              立即注册\n            ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._m(2),
      _vm._m(3),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "r-header" }, [
      _c("a", { staticClass: "r-logo", attrs: { href: "/public" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "t-title" }, [
      _c("h1", [_vm._v("注册和信数科账号")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "r-wrap-after mb10" }, [
      _c("a", { attrs: { href: "/login" } }, [
        _vm._v("已有和信数科账号，去登录 >>"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "lg-footer" }, [
      _c("div", { staticClass: "copyright" }, [
        _c("p", { staticClass: "tag-line" }, [
          _vm._v(
            "\n        Copyright © 2023-2023 Hexin Dig. All Rights Reserved.\n        "
          ),
          _c("span", [_vm._v("和信数科 版权所有")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }