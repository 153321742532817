<template>
	<el-dialog
		width="61.8%"
		:visible.sync="myVisible"
	>
		<div class="auth-tips-title" slot="title">
			{{country}}企业企业认证所需资料<span>为保证认证顺利，请提前准备好以下资料</span>
		</div>
		<div class="auth-tips-content">
			<p class="tips">附件仅支持 JPG、JPEG、PNG、PDF 四种格式，单个文件大小不超过5M</p>
			<h3 class="sub-title">
				一、企业证件
			</h3>
			<div class="sub-content" v-if="country === '中国大陆'">
				<p>中国大陆：统一社会信用代码(三证合一无需准备其他企业证件)、组织机构代码证、税务登记证</p>
				<el-link v-for="p in pics1" :key="p.href" :href="p.href" target="_blank" :underline="false">
					<el-button size="small">{{ p.label }}</el-button>
				</el-link>
			</div>
			<div class="sub-content" v-else-if="country === '香港'">
				<p>香港：注册证书(CI)、商业登记证(BR)、团体成立表格(NC)、周年申报表(NAR1)</p>
				<el-link v-for="p in pics1_xg" :key="p.href" :href="p.href" target="_blank" :underline="false">
					<el-button size="small">{{ p.label }}</el-button>
				</el-link>
			</div>
			<div class="sub-content" v-else>
				<p>请提供企业注册及正常运营的相关证件</p>
				<p>美国：注册证书(Certificate of Incorporation)、公司章程(Articles of Association)、年检证书(Annual Inspection Certificate)、董事名册(Register of Directors)、股东名册(Register of Members)</p>
				<p>澳大利亚：注册证书(Certificate of Incorporation)、公司章程(Articles of Association)、年检证书(Annual Inspection Certificate)、董事册 (Register of Directors)</p>
				<p>新西兰：注册证书(Certificate of Incorporation)、年检证书(Annual Inspection Certificate)、董事名册(Register of Directors)、股东名册(Register of Members)</p>

			</div>
			<h3 class="sub-title">
				二、重要组成人员身份证明文件
			</h3>
			<div class="sub-content">
				<p v-if="['中国大陆', '香港'].includes(country)">法定代表人、企业董事的身份证正面持照照片、反面证件照片，实际控股人和持股10%及以上的股东的身份证正/反面照片<template v-if="country === '香港'">(或护照及持照照片)</template>；</p>
				<p v-else>公司董事及持股10%以上的股东身份证正反面照片、手持护照照片1张(美国国籍)；</p>
				<el-link v-for="p in pics2" :key="p.href" :href="p.href" target="_blank" :underline="false">
					<el-button size="small">{{ p.label }}</el-button>
				</el-link>
			</div>
			<h3 class="sub-title">
				三、第三方证明文件
			</h3>
			<div class="sub-content">
				<p>若您是被授权人，请填写<el-link type="primary">授权书(点击下载)</el-link>，加盖公司公章(个体工商户可按法人代表手印)</p>
				<el-link v-for="p in pics3" :key="p.href" :href="p.href" target="_blank" :underline="false">
					<el-button size="small">{{ p.label }}</el-button>
				</el-link>
			</div>
			<h3 class="sub-title">
				四、开户申请表
			</h3>
			<div class="sub-content">
				<p>请填写开<el-link type="primary">户申请表(点击下载)</el-link>后加盖公司公章(个体工商户可按法人代表手印)</p>
				<el-link v-for="p in pics4" :key="p.href" :href="p.href" target="_blank" :underline="false">
					<el-button size="small">{{ p.label }}</el-button>
				</el-link>
			</div>
		</div>
		<div class="dialog-footer" slot="footer">
			<el-button size="small" type="primary" @click="onStart">开始认证</el-button>
			<el-button size="small" type="primary" plain @click="onClose">还没装备好资料</el-button>
		</div>
	</el-dialog>
</template>
<script>
export default {
	name: 'AuthTips',
	props: {
		visible: Boolean,
		country: {
			type: String,
			default: '中国大陆'
		}
	},
	model: {
		event: 'change',
		prop: 'visible'
	},
	data() {
		return {
			pics1: [
				{ label: '三证合一样图', href: require('@/assets/auth/three_in_one.jpg') },
				{ label: '统一社会信用代码样图', href: require('@/assets/auth/old_business_licence.jpg') },
				{ label: '组织机构代码样图', href: require('@/assets/auth/org_code_certificate.jpg') },
				{ label: '税务登记证样图', href: require('@/assets/auth/tax_register_certificate.jpg') },
			],
			pics1_xg: [
				{ label: '注册证书(CI)样图', href: require('@/assets/auth/guide_cr.jpg') },
				{ label: '商业登记证(BR)样图', href: '' },
				{ label: '团体成立表格(NC)样图', href: '' },
				{ label: '周年申报表(NAR1)样图', href: '' },
			],
			pics2: [
				{ label: '手持身份证正面样图', href: require('@/assets/auth/idcard_with_hand.jpg') },
				{ label: '身份证正面样图', href: require('@/assets/auth/idcard_with_hand_next.jpg') },
				{ label: '身份证反面样图', href: require('@/assets/auth/idcard_emblem.jpg') },
			],
			pics3: [
				{ label: '授权书样图', href: require('@/assets/auth/firm_power_demo.jpg') },
			],
			pics4: [
				{ label: '开户申请样图', href: require('@/assets/auth/account_apply_form1.jpg') },
			]
		}
	},
	computed: {
		myVisible: {
			get() {
				return this.visible
			},
			set(visible) {
				this.$emit('change', visible)
			}
		}
	},
	methods: {
		onStart() {
			this.$emit('start')
			this.onClose()
		},
		onClose() {
			this.myVisible = false
		}
	}
}
</script>
<style lang="scss" scoped>
	.auth-tips-title {
		span {
			font-size: 14px;
			color: #666;
			margin-left: 5px;
		}
	}
	.auth-tips-content /deep/ {
		.el-link {
			&:not(:first-child) {
				margin-left: 10px;
			}
		}
		.tips {
			font-size: 13px;
	    color: #a1a1a1;
	    font-weight: 400;
	    padding-bottom: 10px;
		}
		.sub-title {
			font-size: 14px;
		}
		.sub-content {
			padding-left: 15px;
			> p {
				font-size: 13px;
				color: #555;
			}
		}
		.el-dialog__footer {
			// text-align: center;
		}
	}
</style>
