var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "selection-box" },
      [
        _c("el-alert", {
          staticClass: "mt10",
          attrs: {
            title:
              "附件仅支持 JPG、JPEG、PNG、PDF 四种格式，单个文件大小不能超过2.0 MB",
            closable: false,
            type: "warning",
            "show-icon": "",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "selection-box" },
      [
        _c(
          "el-form",
          {
            ref: "cForm",
            attrs: {
              model: _vm.cForm,
              rules: _vm.vRules,
              "label-position": "right",
              "label-width": "160px",
              size: "small",
            },
          },
          [
            _c("el-alert", {
              staticClass: "mt10",
              attrs: { title: "基本信息", closable: false, type: "info" },
            }),
            _c(
              "div",
              { staticClass: "mt10 inner-box" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "营业执照", prop: "fileBusinessId" } },
                  [
                    _c("dict-upload", {
                      attrs: {
                        type: "image",
                        limit: 1,
                        "type-code": "F0202",
                        "biz-id": "123456",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.onFileChange($event, "fileBusinessId")
                        },
                      },
                      model: {
                        value: _vm.cForm.fileBusinessId,
                        callback: function ($$v) {
                          _vm.$set(_vm.cForm, "fileBusinessId", $$v)
                        },
                        expression: "cForm.fileBusinessId",
                      },
                    }),
                    _c(
                      "el-link",
                      {
                        staticClass: "ml10",
                        attrs: {
                          type: "primary",
                          underline: false,
                          href: require("@/assets/auth/three_in_one.jpg"),
                          target: "_blank",
                        },
                      },
                      [
                        _vm._v(
                          "\n              统一信用证号照样图\n            "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "公司名称", prop: "name" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入公司名称" },
                      model: {
                        value: _vm.cForm.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.cForm, "name", $$v)
                        },
                        expression: "cForm.name",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "营业执照号", prop: "creditCode" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "一般为18/15位数字或字母组合" },
                      model: {
                        value: _vm.cForm.creditCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.cForm, "creditCode", $$v)
                        },
                        expression: "cForm.creditCode",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "营业期限", prop: "endupDate" } },
                  [
                    [
                      _c("el-date-picker", {
                        attrs: {
                          "value-format": "yyyy-MM-dd",
                          type: "daterange",
                          "start-placeholder": "起始日期",
                          "end-placeholder": "结束日期",
                        },
                        model: {
                          value: _vm.cForm.expireDateAttr,
                          callback: function ($$v) {
                            _vm.$set(_vm.cForm, "expireDateAttr", $$v)
                          },
                          expression: "cForm.expireDateAttr",
                        },
                      }),
                      _c("span", { staticStyle: { "margin-right": "30px" } }),
                    ],
                  ],
                  2
                ),
              ],
              1
            ),
            _c("el-alert", {
              staticClass: "mt10",
              attrs: { title: "法人信息", closable: false, type: "info" },
            }),
            _c(
              "div",
              { staticClass: "mt10 inner-box" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "身份证（人像面）",
                      prop: "legalFilePeopleId",
                    },
                  },
                  [
                    _c("dict-upload", {
                      attrs: {
                        type: "image",
                        limit: 1,
                        "type-code": "F0204",
                        "biz-id": "123456",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.onFileChange($event, "legalFilePeopleId")
                        },
                      },
                      model: {
                        value: _vm.cForm.legalFilePeopleId,
                        callback: function ($$v) {
                          _vm.$set(_vm.cForm, "legalFilePeopleId", $$v)
                        },
                        expression: "cForm.legalFilePeopleId",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "身份证（国徽面）",
                      prop: "legalFileReverseId",
                    },
                  },
                  [
                    _c("dict-upload", {
                      attrs: {
                        type: "image",
                        limit: 1,
                        "type-code": "F0203",
                        "biz-id": "123456",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.onFileChange($event, "legalFileReverseId")
                        },
                      },
                      model: {
                        value: _vm.cForm.legalFileReverseId,
                        callback: function ($$v) {
                          _vm.$set(_vm.cForm, "legalFileReverseId", $$v)
                        },
                        expression: "cForm.legalFileReverseId",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "法人姓名：", prop: "legalName" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入法人姓名" },
                      model: {
                        value: _vm.cForm.legalName,
                        callback: function ($$v) {
                          _vm.$set(_vm.cForm, "legalName", $$v)
                        },
                        expression: "cForm.legalName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "法人身份证号：", prop: "legalIdCard" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "一般为18/15位数字或字母组合" },
                      model: {
                        value: _vm.cForm.legalIdCard,
                        callback: function ($$v) {
                          _vm.$set(_vm.cForm, "legalIdCard", $$v)
                        },
                        expression: "cForm.legalIdCard",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "法人手机号：", prop: "legalPhone" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入法人手机号" },
                      model: {
                        value: _vm.cForm.legalPhone,
                        callback: function ($$v) {
                          _vm.$set(_vm.cForm, "legalPhone", $$v)
                        },
                        expression: "cForm.legalPhone",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("el-alert", {
              staticClass: "mt10",
              attrs: { title: "授权人信息", closable: false, type: "info" },
            }),
            _c(
              "div",
              { staticClass: "mt10 inner-box" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "身份证（人像面）",
                      prop: "authFilePeopleId",
                    },
                  },
                  [
                    _c("dict-upload", {
                      attrs: {
                        type: "image",
                        limit: 1,
                        "type-code": "F0206",
                        "biz-id": "123456",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.onFileChange($event, "legalFilePeopleId")
                        },
                      },
                      model: {
                        value: _vm.cForm.authFilePeopleId,
                        callback: function ($$v) {
                          _vm.$set(_vm.cForm, "authFilePeopleId", $$v)
                        },
                        expression: "cForm.authFilePeopleId",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "身份证（国徽面）",
                      prop: "authFileReverseId",
                    },
                  },
                  [
                    _c("dict-upload", {
                      attrs: {
                        type: "image",
                        limit: 1,
                        "type-code": "F0205",
                        "biz-id": "123456",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.onFileChange($event, "legalFileReverseId")
                        },
                      },
                      model: {
                        value: _vm.cForm.authFileReverseId,
                        callback: function ($$v) {
                          _vm.$set(_vm.cForm, "authFileReverseId", $$v)
                        },
                        expression: "cForm.authFileReverseId",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "授权人姓名：", prop: "authName" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入法人姓名" },
                      model: {
                        value: _vm.cForm.authName,
                        callback: function ($$v) {
                          _vm.$set(_vm.cForm, "authName", $$v)
                        },
                        expression: "cForm.authName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "授权人身份证号：", prop: "authIdCard" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "一般为18/15位数字或字母组合" },
                      model: {
                        value: _vm.cForm.authIdCard,
                        callback: function ($$v) {
                          _vm.$set(_vm.cForm, "authIdCard", $$v)
                        },
                        expression: "cForm.authIdCard",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "授权人手机号：", prop: "authPhone" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入法人手机号" },
                      model: {
                        value: _vm.cForm.authPhone,
                        callback: function ($$v) {
                          _vm.$set(_vm.cForm, "authPhone", $$v)
                        },
                        expression: "cForm.authPhone",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("el-alert", {
              staticClass: "mt10",
              attrs: { title: "实控人信息", closable: false, type: "info" },
            }),
            _c(
              "div",
              { staticClass: "mt10 inner-box" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "身份证（人像面）",
                      prop: "actualFilePeopleId",
                    },
                  },
                  [
                    _c("dict-upload", {
                      attrs: {
                        type: "image",
                        limit: 1,
                        "type-code": "F0207",
                        "biz-id": "123456",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.onFileChange($event, "legalFilePeopleId")
                        },
                      },
                      model: {
                        value: _vm.cForm.actualFilePeopleId,
                        callback: function ($$v) {
                          _vm.$set(_vm.cForm, "actualFilePeopleId", $$v)
                        },
                        expression: "cForm.actualFilePeopleId",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "身份证（国徽面）",
                      prop: "actualFileReverseId",
                    },
                  },
                  [
                    _c("dict-upload", {
                      attrs: {
                        type: "image",
                        limit: 1,
                        "type-code": "F0208",
                        "biz-id": "123456",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.onFileChange($event, "legalFileReverseId")
                        },
                      },
                      model: {
                        value: _vm.cForm.actualFileReverseId,
                        callback: function ($$v) {
                          _vm.$set(_vm.cForm, "actualFileReverseId", $$v)
                        },
                        expression: "cForm.actualFileReverseId",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "实控人姓名：", prop: "actualName" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入法人姓名" },
                      model: {
                        value: _vm.cForm.actualName,
                        callback: function ($$v) {
                          _vm.$set(_vm.cForm, "actualName", $$v)
                        },
                        expression: "cForm.actualName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: { label: "实控人身份证号：", prop: "actualIdCard" },
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "一般为18/15位数字或字母组合" },
                      model: {
                        value: _vm.cForm.actualIdCard,
                        callback: function ($$v) {
                          _vm.$set(_vm.cForm, "actualIdCard", $$v)
                        },
                        expression: "cForm.actualIdCard",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "实控人手机号：", prop: "actualPhone" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入法人手机号" },
                      model: {
                        value: _vm.cForm.actualPhone,
                        callback: function ($$v) {
                          _vm.$set(_vm.cForm, "actualPhone", $$v)
                        },
                        expression: "cForm.actualPhone",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "tc" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "medium",
                      loading: _vm.loading,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.saveEnterprie()
                      },
                    },
                  },
                  [_vm._v("保存")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }