var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "auth-step-container" }, [
    _vm.enterAuthDto.msg != null && _vm.enterAuthDto.msg !== ""
      ? _c(
          "div",
          { staticClass: "selection-box mb20" },
          [
            _c("el-alert", {
              attrs: {
                title: _vm.enterAuthDto.msg,
                closable: false,
                type: "warning",
                "show-icon": "",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "selection-box" },
      [
        _c(
          "el-steps",
          {
            staticClass: "mb20",
            attrs: {
              active: _vm.active,
              "finish-status": "success",
              simple: "",
            },
          },
          [
            _c("el-step", {
              attrs: { title: "上传营业执照", icon: "el-icon-edit" },
            }),
            _c("el-step", {
              attrs: { title: "法人信息登记", icon: "el-icon-upload" },
            }),
            _c("el-step", {
              attrs: { title: "企业认证", icon: "el-icon-upload" },
            }),
          ],
          1
        ),
        _vm.active === 0
          ? _c("BusinessLicenseInfo", {
              ref: "businessLicenseInfo",
              attrs: { "e-id": _vm.enterId },
              on: { change: _vm.getEnterId },
            })
          : _vm._e(),
        _vm.active === 1
          ? _c("LegalPersonInfo", {
              ref: "legalPersonInfo",
              attrs: { "e-id": _vm.enterId },
            })
          : _vm._e(),
        _vm.active === 2
          ? _c("EnterpriseCert", {
              ref: "enterpriseCert",
              attrs: { "e-id": _vm.enterId },
            })
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "tc mt30" },
      [
        _vm.active == 1 || (_vm.active == 2 && _vm.controlBtnFlag == 1)
          ? _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.handleStepPrev } },
              [_vm._v("上一步")]
            )
          : _vm._e(),
        _vm.active === 0
          ? _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: { click: _vm.handleSaveCompanyInfo },
              },
              [_vm._v("下一步")]
            )
          : _vm._e(),
        _vm.active === 1
          ? _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: { click: _vm.handleSaveLegal },
              },
              [_vm._v("下一步")]
            )
          : _vm._e(),
        _vm.active === 2 && _vm.controlBtnFlag == -1
          ? _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.handleSaveAuth } },
              [_vm._v("开始企业认证")]
            )
          : _vm._e(),
        _vm.active === 2 && _vm.controlBtnFlag == 0
          ? _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: { click: _vm.handleAddAuthorizer },
              },
              [_vm._v("添加企业授权人")]
            )
          : _vm._e(),
        _vm.active === 2 && _vm.controlBtnFlag == 0
          ? _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: { click: _vm.handleStartCerti },
              },
              [_vm._v("我是法人，开始认证！")]
            )
          : _vm._e(),
        _vm.active === 2 && _vm.controlBtnFlag == 1
          ? _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: { click: _vm.handleSaveLegal },
              },
              [_vm._v("下一步")]
            )
          : _vm._e(),
        _vm.active === 3 && _vm.singFlag == 0
          ? _c(
              "el-button",
              {
                attrs: { type: "primary", disabled: _vm.btSign },
                on: { click: _vm.handleStartSign },
              },
              [_vm._v("开始签署")]
            )
          : _vm._e(),
        _vm.active === 3 && _vm.lastSign == 0
          ? _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.toSignLastDoc } },
              [_vm._v("签署协议")]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }