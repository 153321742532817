<template>
  <div class="welcome-container">
    <!--头部-->
    <div class="header staticHeader">
      <div class="welcome-logo-box">
        <div class="logo"></div>
      </div>
    </div>

    <!--第一屏-->
    <div class="bannerHome">
      <h1 class="master-title wow animate__animated animate__fadeInUp">专业的电商供应链融资平台</h1>
      <h2 class="sub-title wow animate__animated animate__fadeInUp">电商极速收款</h2>

      <div class="btn-box">
        <button class="btn-anniu" @click="handleGotoPage">
          <i class="iconfont icon-shoushimima" style="font-size: 28px !important;" />
          立即使用
        </button>
      </div>
    </div>

    <div class="bk_gray pt30 pb50">
      <div class="wc-inner">
        <h3 class="homeTitle wow animate__animated animate__fadeInUp">我们的优势</h3>
        <p class="homeDesc wow animate__animated animate__fadeInUp">我们区别于其他产品的六大优势</p>
        <div class="advantage-ul wow animate__animated animate__fadeInUp">
          <div class="advantage-item">
            <div class="advantage-item-icon"><i class="iconfont icon-shuyi_qiye-jianzhu"></i></div>
            <div class="advantage-item-title">平台产品优势</div>
            <div class="advantage-item-content">跨平台综合管理:支持多平台店铺融资，覆盖抖音、快手、拼多多</div>
          </div>
          <div class="advantage-item">
            <div class="advantage-item-icon"><i class="iconfont icon-renzhengbaogaoicon"></i></div>
            <div class="advantage-item-title">门槛低</div>
            <div class="advantage-item-content">企业成立满6个月，店铺成立满3个月即可融资，销售数据为王不依赖征信</div>
          </div>
          <div class="advantage-item">
            <div class="advantage-item-icon"><i class="iconfont icon-jiangdichengben"></i></div>
            <div class="advantage-item-title">融资费率低</div>
            <div class="advantage-item-content">根据商户经营情况动态调整融资费率，及时降低店铺运营成本。</div>
          </div>
          <div class="advantage-item">
            <div class="advantage-item-icon"><i class="iconfont icon-report"></i></div>
            <div class="advantage-item-title">无抵押无担保</div>
            <div class="advantage-item-content">单户最高可获1000万额度，无需质押及担保</div>
          </div>
          <div class="advantage-item">
            <div class="advantage-item-icon"><i class="iconfont icon-dianpu1"></i></div>
            <div class="advantage-item-title">无需更换店铺</div>
            <div class="advantage-item-content">日常销售在自有店铺内进行，商家运营后数据全留存</div>
          </div>
          <div class="advantage-item">
            <div class="advantage-item-icon"><i class="iconfont icon-zhanghujiaoyimingxifenxi"></i></div>
            <div class="advantage-item-title">全线上操作</div>
            <div class="advantage-item-content">线上开户，线上提款，动动手指轻松融资</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import 'animate.css';
import { WOW } from 'wowjs';

export default defineComponent({
  name: 'index',
  mounted() {
    new WOW().init();
  },
  methods: {
    handleGotoPage() {
      this.$router.push('/login');
    }
  }
});
</script>

<style scoped lang="scss">
.welcome-container {
  width: 100%;
  height: 100%;
  background-image: url("~@/assets/bg/bk_welcome.jpg");
  background-size: 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
}
.staticHeader {
  //position: fixed;
  background: rgba(0, 0, 0, 0);
  height: 44px;
  line-height: 44px;
  padding: 16px 0;
  border-bottom: solid 1px rgba(0, 0, 0, 0);
  width: 100%;
  top: 0;
  z-index: 99;
  -webkit-transform: translateZ(0);
  box-sizing: content-box !important;
  font-family: Arial, 'Hiragino Sans GB', 'md-hsgb', 'Microsoft YaHei',
  'WenQuanYi Micro Hei', sans-serif;
}
.staticHeaderScroll {
  background: #fff !important;
  box-shadow: 0px 0px 24px 1px rgba(0,0,0,0.1600);
  position: fixed !important;
  top: 0 !important;
}
.welcome-logo-box {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 0 70px;
  box-sizing: border-box;

  .logo {
    margin: 60px auto 0;
    width: 190px;
    height: 80px;
    //height: 40px;
    // background-image: url("~@/assets/bg/logo.png");old
    background-image: url("~@/assets/bg/logo-report.png");//new
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .companyName {
    margin-left: 20px;
    font-family: PingFangSC-Regular, PingFang SC, "Microsoft YaHei",serif ;
    font-size: 18px;
    font-weight: 600;
    color: #5681fa;
  }
}

.bannerHome {
  //padding-top: 77px;
  margin: 150px 0 0 0;
  width: 100%;
  height: calc( 100vh - 229px);
  text-align: center;
  font-family: PingFang SC-Semibold, PingFang SC;
  color: #333333;
  position: relative;
  box-sizing: border-box;
}

.wc-inner {
  margin: 0 auto;
  width: 1024px;
  box-sizing: border-box;

  & > * {
    visibility: visible !important;
    animation-name: fadeInUp !important;
  }

  .advantage-ul {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 60px;
    .advantage-item {
      position: relative;
      width: 30%;
      border-radius: 10px;
      box-sizing: border-box;
      padding: 10px 20px;
      height: 260px;
      .advantage-item-icon {
        border-radius: 60px;
        text-align: center;
        color: #333;
        line-height: 60px;
        .iconfont {
          width: 60px;
          height: 60px;
          font-size: 48px !important;
        }
      }
      .advantage-item-title {
        height: 30px;
        font-size: 22px;
        font-weight: 600;
        color: #333333;
        line-height: 30px;
        margin-top: 12px;
        margin-bottom: 6px;
        text-align: center;
      }
      .advantage-item-content {
        margin-top: 10px;
        height: 25px;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 36px;
        text-align: center;
      }
    }
  }
}

.master-title {
  margin: 0 auto;
  text-align: center;
  font-size: 90px;
  font-family: PingFang SC-Semibold, PingFang SC;
}
.sub-title {
  margin-top: 20px;
  text-align: center;
  font-size: 40px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 400;
}

.homeTitle {
  margin-top: 50px;
  font-size: 36px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 50px;
  text-align: center;
}
.homeDesc {
  font-size: 18px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 56px;
  display: block;
}

.btn-box {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 220px;
  margin: 50px auto;
}
.btn-anniu {
  text-decoration: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  background: linear-gradient(90deg, #03a9f4, #2a77fb, #005eff, #03a9f4);
  background-size: 400%;
  width: 360px;
  height: 80px;
  line-height: 56px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  border-radius: 50px;
  z-index: 1;
}
.btn-anniu:hover::before,
.btn-anniu:hover {
  animation: sun 8s infinite;
}
.btn-anniu::before {
  content: '';
  position: absolute;
  left: -5px;
  right: -5px;
  top: -5px;
  bottom: -5px;
  background: linear-gradient(90deg, #03a9f4, #2a77fb, #ffeb3b, #03a9f4);
  background-size: 400%;
  border-radius: 50px;
  filter: blur(10px);
  z-index: -1;
}
@keyframes sun {
  100%{
    background-position: -400% 0;
  }
}
</style>
