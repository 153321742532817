<template>
  <div class="details-warp">
    <el-form ref="form" v-loading="listLoading" :model="form" label-width="160px">
      <div class="details-section ml10 mr20">
        <h3 class="details-section-h3">
          <span>还款详情</span>
          <el-button type="danger" size="mini" icon="iconfont icon-huankuandaihuan" class="fr" plain @click="repay(form.id)">还款</el-button>
          <el-button type="primary" size="mini" icon="el-icon-download" class="fr" plain @click="exportExcel">导出</el-button>
        </h3>
        <el-row :gutter="20" class="pl20 pr20">
          <el-col :span="12">
            <el-form-item label="企业名称：">
              <el-input v-model="form.enterName" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="店铺名称：">
              <el-input v-model="form.shopName" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="单号：">
              <el-input v-model="form.billNo" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="融资单号：">
              <el-input v-model="form.orderNo" readonly />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="订单金额：">
              <el-input v-model="form.orderAmt" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="实际请款金额：">
              <el-input v-model="form.billAmount" readonly>
                <template slot="suffix">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="起息日：">
              <el-input v-model="form.rentStartDate" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="打包服务费率：">
              <el-input v-model="form.interestRate" readonly>
                <template slot="suffix">%</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="计费方式：">
              <dict-select v-model="form.rateType" no="D000082" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="利息：">
              <el-input v-model="form.billInt" readonly>
                <template slot="suffix">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="服务费：">
              <el-input v-model="form.billFee" readonly>
                <template slot="suffix">元</template>
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="逾期罚息：">
              <el-input v-model="form.billFine" readonly>
                <template slot="suffix">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="已还本金：">
              <el-input v-model="form.repayAmount" readonly>
                <template slot="suffix">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <!--          <el-col :span="12">-->
          <!--            <el-form-item label="实收利息：">-->
          <!--              <el-input v-model="form.repayInt" readonly>-->
          <!--                <template slot="suffix">元</template>-->
          <!--              </el-input>-->
          <!--            </el-form-item>-->
          <!--          </el-col>-->
          <!--          <el-col :span="12">-->
          <!--            <el-form-item label="实收费用：">-->
          <!--              <el-input v-model="form.repayFee" readonly>-->
          <!--                <template slot="suffix">元</template>-->
          <!--              </el-input>-->
          <!--            </el-form-item>-->
          <!--          </el-col>-->
          <!--          <el-col :span="12">-->
          <!--            <el-form-item label="已还罚息：">-->
          <!--              <el-input v-model="form.repayFineAmount" readonly>-->
          <!--                <template slot="suffix">元</template>-->
          <!--              </el-input>-->
          <!--            </el-form-item>-->
          <!--          </el-col>-->
          <el-col :span="12">
            <el-form-item label="剩余本金：">
              <el-input v-model="form.surpAmount" readonly>
                <template slot="suffix">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <!--          <el-col :span="12">-->
          <!--            <el-form-item label="剩余利息：">-->
          <!--              <el-input v-model="form.surpInt" readonly>-->
          <!--                <template slot="suffix">元</template>-->
          <!--              </el-input>-->
          <!--            </el-form-item>-->
          <!--          </el-col>-->
          <!--          <el-col :span="12">-->
          <!--            <el-form-item label="剩余费用：">-->
          <!--              <el-input v-model="form.surpFee" readonly>-->
          <!--                <template slot="suffix">元</template>-->
          <!--              </el-input>-->
          <!--            </el-form-item>-->
          <!--          </el-col>-->
          <!--          <el-col :span="12">-->
          <!--            <el-form-item label="剩余罚息：">-->
          <!--              <el-input v-model="form.surpFineAmount" readonly>-->
          <!--                <template slot="suffix">元</template>-->
          <!--              </el-input>-->
          <!--            </el-form-item>-->
          <!--          </el-col>-->
          <el-col :span="24">
            <el-form-item label="状态：">
              <el-input v-model="form.statusName" readonly />
            </el-form-item>
          </el-col>
          <!--          <el-col :span="24">-->
          <!--            <el-form-item label="备注：">-->
          <!--              <el-input v-model="form.remark" type="textarea" :rows="4" readonly />-->
          <!--            </el-form-item>-->
          <!--          </el-col>-->
        </el-row>
      </div>

      <div class="details-section mt15 ml10 mr20">
        <h3 class="details-section-h3"><span>还款计划表</span></h3>
        <repayment-schedule :bill-form="form" />
      </div>

      <!--<el-form-item>-->
      <!--<el-button type="primary" @click="onSubmit">立即创建</el-button>-->
      <!--<el-button>取消</el-button>-->
      <!--</el-form-item>-->
    </el-form>
  </div>
</template>

<script>
import { doGet, doExportPostExcel, downExcel } from '@/router/axios';
import repaymentSchedule from './repayment-schedule';
import DictSelect from '@/views/components/DictSelect.vue';

export default {
  name: 'PageDetails',
  components: { DictSelect, repaymentSchedule },
  props: {
    modalData: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      listLoading: false,
      headerIds: [],
      id: '',
      form: {}
    };
  },
  watch: {
    modalData(val, oldVal) {
      console.log('modalData:' + val + '--oldVal' + oldVal);
      // 这里执行初始化方法
      this.initForm(val);
    }
  },
  mounted() {
    this.initForm(this.modalData);
  },
  methods: {
    initForm: function(id) {
      const that = this;
      doGet(`/rent/bill/` + id).then(res => {
        that.form = res.data.data;
      });
    },

    repay(billId) {
      this.$router.push({ path: '/repayment/add?billId=' + billId });
    },
    exportExcel() {
      this.headerIds.push(this.form.id);
      this.listLoading = true;
      doExportPostExcel('/rent/bill/export', this.headerIds).then(res => {
        // 获取headers中的filename文件名
        downExcel(res);
        this.listLoading = false;
      }).catch(() => {
        this.listLoading = false;
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.details-warp {
  height: calc(100vh - 200px);
  overflow: hidden;
  overflow-y: auto;
}
/deep/ .details-section-h3 {
  .iconfont {
    font-size: 12px;
  }
  .el-button {
    margin: 3px 5px 0 0;
    span:before {
      content: none;
    }
  }
}
</style>
