<template>
  <div class="mz-layout-section width-drawn-list">
    <div class="tableList_title">账户明细</div>
    <el-divider />

    <avue-crud
      ref="crud"
      :option="option"
      :page.sync="page"
      :table-loading="listLoading"
      :data="list"
      @on-load="getList"
      @size-change="sizeChange"
      @current-change="currentChange"
      @search-change="handleFilter"
      @search-reset="resetFilter">
      <template slot="menuLeft">
        <el-button class="filter-item" type="primary" icon="iconfont icon-tixian" @click="toWithdrawal()">
          提现
        </el-button>
      </template>
      <template slot="status" slot-scope="scope">
        <el-tag v-if="scope.row.status == 0" effect="dark" type="info">申请中</el-tag>
        <el-tag v-if="scope.row.status == 1" effect="dark" type="success">提现成功</el-tag>
        <el-tag v-if="scope.row.status == 2" effect="dark" type="info">处理中</el-tag>
        <el-tag v-if="scope.row.status == 8" effect="dark" type="error">提现失败</el-tag>
        <el-tag v-if="scope.row.status == 9" effect="dark" type="error">提现失败</el-tag>
      </template>
    </avue-crud>
  </div>
</template>

<script>
import { doGet, doDelet, doPost } from '@/router/axios'
const tableOption = {
  searchMenuSpan: 8,
  searchLabelWidth: 80,
  searchMenuPosition: 'left',
  border: false,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menuWidth: 100,
  refreshBtn: false,
  columnBtn: false,
  editBtn: false,
  menu: false,
  delBtn: false,
  align: 'left',
  addBtn: false,
 searchShowBtn:false,
 column: [
    {
      label: '企业',
      prop: 'custName',
      minWidth: 200,
      fixed: true,
      editDisplay: false,
      addDisplay: false,
      search: true,
      searchPlaceholder: '请输入企业名称',
      searchSpan: 8,
      span: 12,
    },
    {
      label: '编码',
      prop: 'no',
      width: 160,
      editDisplay: false,
      addDisplay: false,
      searchSpan: 8,
      span: 12,
    },
    {
      label: '金额(元)',
      prop: 'totalAmt',
      editDisplay: true,
      addDisplay: true,
      span: 12,
    },
    {
      label: '到账银行卡',
      prop: 'bankCardNo',
      width: 180,
      editDisplay: true,
      addDisplay: true,
      span: 12,
    },
    {
      label: '户名',
      prop: 'bankUserName',
      minWidth: 200,
      editDisplay: true,
      addDisplay: true,
      span: 12,
    },
    {
      label: '支行',
      prop: 'bankSubName',
      minWidth: 200,
      editDisplay: true,
      addDisplay: true,
      span: 12,
    },
    {
      label: '银行附言',
      prop: 'bankMemo',
      editDisplay: true,
      addDisplay: true,
      span: 12,
    },
    {
      label: '状态',
      prop: 'status',
      width: 120,
      editDisplay: true,
      addDisplay: true,
      dicUrl: '/common/type/D000132',
      type: 'select',
      search: true,
      searchPlaceholder: '请选择状态',
      searchSpan: 8,
      span: 12,
    },
    {
      label: '申请时间',
      prop: 'createTime',
      width: 160,
      editDisplay: false,
      addDisplay: false,
      span: 12,
    },
  ],
}

export default {
  name: 'WidthDrawnList',
  components: {},
  data() {
    return {
      option: tableOption,
      searchItmes: {},
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条,
      },
      list: [],
      listLoading: true,
    }
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    getList(page, params) {
      params = params ? params : { ...this.searchItmes }
      params.current = page.currentPage - 1
      params.size = page.pageSize
      this.listLoading = true
      doPost('/order/widthdrawalInfo/queryPage', params).then(({ data }) => {
        if (data) {
          const { dataList, totalElements } = data.data || {}
          this.list = dataList || []
          this.page.total = totalElements || 0
        }
        this.listLoading = false
      })
    },

    sizeChange(size) {
      this.page.pageSize = size
    },
    currentChange(current) {
      this.page.currentPage = current
    },
    handleFilter(param, done) {
      this.page.currentPage = 1
      this.searchItmes = param ? param : {}
      this.getList(this.page, param)
      done()
    },
    resetFilter() {
      this.page.currentPage = 1
      this.searchItmes = {}
      this.getList(this.page, this.searchItmes)
    },

    toWithdrawal() {
      this.$router.push({ path: '/cap/widthdraw' })
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/ .el-tabs__nav-scroll {
  padding-left: 10px;
}
/deep/ .el-divider {
  background-color: #ecedef;
}
</style>
