var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isquanxian
      ? _c("div", [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.isLoading,
                  expression: "isLoading",
                },
              ],
              staticClass: "data-board",
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-form",
                        {
                          attrs: {
                            "label-position": "right",
                            model: _vm.queryFrom,
                            "label-width": "100px",
                            size: "small",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "mt10 inner-box" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "平台产品:",
                                    prop: "liveStreamPlat",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "350px" },
                                      attrs: { placeholder: "请选择" },
                                      on: { change: _vm.selectChange },
                                      model: {
                                        value: _vm.queryFrom.productType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryFrom,
                                            "productType",
                                            $$v
                                          )
                                        },
                                        expression: "queryFrom.productType",
                                      },
                                    },
                                    _vm._l(_vm.options, function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.hostname == "pre.fin-ops.biumdigits.com" ||
                  _vm.hostname == "fin-ops.biumdigits.com"
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { "text-align": "right" },
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.goToScreen },
                            },
                            [_vm._v("数据看板")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _c("div", { staticClass: "container" }, [
                _c(
                  "div",
                  { staticClass: "mb20" },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticClass: "itemBox" }, [
                            _c("img", {
                              staticClass: "itemBox_icon",
                              attrs: {
                                src: "https://bium-prod.oss-cn-hangzhou.aliyuncs.com/front_file/backEnd/icon1.png",
                              },
                            }),
                            _c("div", { staticClass: "itemBox_info" }, [
                              _c("p", { staticClass: "itemBox_info_title" }, [
                                _vm._v("平台企业数量"),
                              ]),
                              _c("p", { staticClass: "itemBox_info_tip" }, [
                                _vm._v(_vm._s(_vm.detail.enterpriseCount || 0)),
                              ]),
                            ]),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticClass: "itemBox" }, [
                            _c("img", {
                              staticClass: "itemBox_icon",
                              attrs: {
                                src: "https://bium-prod.oss-cn-hangzhou.aliyuncs.com/front_file/backEnd/icon2.png",
                              },
                            }),
                            _c("div", { staticClass: "itemBox_info" }, [
                              _c("p", { staticClass: "itemBox_info_title" }, [
                                _vm._v("一般企业"),
                              ]),
                              _c("p", { staticClass: "itemBox_info_tip" }, [
                                _vm._v(
                                  _vm._s(_vm.detail.generalEnterpriseCount || 0)
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticClass: "itemBox" }, [
                            _c("img", {
                              staticClass: "itemBox_icon",
                              attrs: {
                                src: "https://bium-prod.oss-cn-hangzhou.aliyuncs.com/front_file/backEnd/icon3.png",
                              },
                            }),
                            _c("div", { staticClass: "itemBox_info" }, [
                              _c("p", { staticClass: "itemBox_info_title" }, [
                                _vm._v("个体工商户"),
                              ]),
                              _c("p", { staticClass: "itemBox_info_tip" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.detail.individualEnterpriseCount || 0
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mb20" },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c(
                            "div",
                            { staticClass: "box platform_shop" },
                            [
                              _c("div", { staticClass: "title" }, [
                                _vm._v("平台店铺数"),
                              ]),
                              _vm._l(_vm.platformStoreCount, function (item) {
                                return _c(
                                  "div",
                                  { key: item.id, staticClass: "platform" },
                                  [
                                    _c("img", {
                                      staticClass: "platformIcon",
                                      attrs: { src: _vm.platformIcon[item.id] },
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.platformText[item.id]) +
                                        "\n              "
                                    ),
                                    _c("label", [
                                      _vm._v(_vm._s(item.value || 0)),
                                    ]),
                                  ]
                                )
                              }),
                            ],
                            2
                          ),
                        ]),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticClass: "box" }, [
                            _c("div", { staticClass: "title" }, [
                              _vm._v("店铺各平台占比"),
                            ]),
                            _c("div", {
                              ref: "shopPlatformRef",
                              staticClass: "canvas-container",
                            }),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c("div", { staticClass: "box platform_num" }, [
                            _c("div", { staticClass: "title" }, [
                              _vm._v("平台店铺数"),
                            ]),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "margin-bottom": "32px",
                                  "margin-top": "18px",
                                },
                              },
                              [
                                _c("p", { staticClass: "subtitle" }, [
                                  _vm._v("平台已服务店铺数"),
                                ]),
                                _c("p", { staticClass: "value" }, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.detail.servicedStoresCount || 0
                                      )
                                    ),
                                  ]),
                                  _vm._v(
                                    "\n                家\n              "
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              { staticStyle: { "margin-bottom": "32px" } },
                              [
                                _c("p", { staticClass: "subtitle" }, [
                                  _vm._v("当前融资店铺数"),
                                ]),
                                _c("p", { staticClass: "value" }, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.detail.repaymentStoresCount || 0
                                      )
                                    ),
                                  ]),
                                  _vm._v(
                                    "\n                家\n              "
                                  ),
                                ]),
                              ]
                            ),
                            _c("div", [
                              _c("p", { staticClass: "subtitle" }, [
                                _vm._v("当前店铺平均在贷"),
                              ]),
                              _c("p", { staticClass: "value" }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatNumber(_vm.detail.avgAmount)
                                    )
                                  ),
                                ]),
                                _vm._v("\n                万\n              "),
                              ]),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mb20" },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c("el-col", { attrs: { span: 12 } }, [
                          _c("div", { staticClass: "box" }, [
                            _c(
                              "div",
                              {
                                staticClass: "title",
                                staticStyle: { height: "30px" },
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    "\n                平台每日贷款余额\n                "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "14px",
                                        "margin-left": "6px",
                                      },
                                    },
                                    [_vm._v("(万元)")]
                                  ),
                                ]),
                                _c("div", { staticClass: "title-action" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title-action-item",
                                      class:
                                        _vm.loanAmountType == 7 ? "active" : "",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onChangeLoanAmount(7)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  7天\n                "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title-action-item",
                                      class:
                                        _vm.loanAmountType == 30
                                          ? "active"
                                          : "",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onChangeLoanAmount(30)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  30天\n                "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            _c("div", {
                              ref: "loanAmountRef",
                              staticClass: "canvas-container",
                            }),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 12 } }, [
                          _c("div", { staticClass: "box" }, [
                            _c(
                              "div",
                              {
                                staticClass: "title",
                                staticStyle: { height: "30px" },
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    "\n                平台每日放款金额\n                "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "14px",
                                        "margin-left": "6px",
                                      },
                                    },
                                    [_vm._v("(元)")]
                                  ),
                                ]),
                                _c("div", { staticClass: "title-action" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title-action-item",
                                      class:
                                        _vm.paymentAmountType == 7
                                          ? "active"
                                          : "",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onChangePaymentAmount(7)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  7天\n                "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title-action-item",
                                      class:
                                        _vm.paymentAmountType == 30
                                          ? "active"
                                          : "",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onChangePaymentAmount(30)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  30天\n                "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            _c("div", {
                              ref: "paymentAmountRef",
                              staticClass: "canvas-container",
                            }),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mb20" },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c("el-col", { attrs: { span: 12 } }, [
                          _c(
                            "div",
                            { staticStyle: { background: "#ffffff" } },
                            [
                              _c("div", { staticClass: "boxInfo" }, [
                                _c("p", { staticClass: "subtitle" }, [
                                  _vm._v("平台累计信用总额"),
                                ]),
                                _c("p", { staticClass: "values" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.detail.creditAmount || 0) +
                                      "\n                "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "14px",
                                        "margin-left": "6px",
                                      },
                                    },
                                    [_vm._v("元")]
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                        _c("el-col", { attrs: { span: 12 } }, [
                          _c(
                            "div",
                            { staticStyle: { background: "#ffffff" } },
                            [
                              _c("div", { staticClass: "boxInfoPrice" }, [
                                _c("p", { staticClass: "subtitle" }, [
                                  _vm._v("平台当前在贷"),
                                ]),
                                _c("p", { staticClass: "values" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.formatNumber(
                                          _vm.detail.repaymentAmount
                                        ) || 0
                                      ) +
                                      "\n                "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "14px",
                                        "margin-left": "6px",
                                      },
                                    },
                                    [_vm._v("万元")]
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mb20" },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c(
                            "div",
                            {
                              staticClass: "bg1",
                              staticStyle: { "margin-bottom": "14px" },
                            },
                            [
                              _c("p", { staticClass: "subtitle" }, [
                                _vm._v("今日放款金额"),
                              ]),
                              _c("p", { staticClass: "values" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.detail.todayAmount || 0) +
                                    "\n              "
                                ),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-size": "14px",
                                      "margin-left": "6px",
                                    },
                                  },
                                  [_vm._v("元")]
                                ),
                              ]),
                            ]
                          ),
                          _c("div", { staticClass: "bg2" }, [
                            _c("p", { staticClass: "subtitle" }, [
                              _vm._v("平台累计放款金额"),
                            ]),
                            _c("p", { staticClass: "values" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.formatNumber(_vm.detail.totalAmount)
                                  ) +
                                  "\n              "
                              ),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-size": "14px",
                                    "margin-left": "6px",
                                  },
                                },
                                [_vm._v("万元")]
                              ),
                            ]),
                          ]),
                        ]),
                        _c("el-col", { attrs: { span: 16 } }, [
                          _c("div", { staticClass: "box" }, [
                            _c(
                              "div",
                              {
                                staticClass: "title",
                                staticStyle: { height: "30px" },
                              },
                              [
                                _vm._v(
                                  "\n              平台收入\n              "
                                ),
                                _c("div", { staticClass: "title-action" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title-action-item",
                                      class: _vm.type == "all" ? "active" : "",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onChangeType("all")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  全部\n                "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title-action-item",
                                      class: _vm.type == 0 ? "active" : "",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onChangeType(0)
                                        },
                                      },
                                    },
                                    [_vm._v("今天")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title-action-item",
                                      class: _vm.type == 7 ? "active" : "",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onChangeType(7)
                                        },
                                      },
                                    },
                                    [_vm._v("7天")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title-action-item",
                                      class: _vm.type == 30 ? "active" : "",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onChangeType(30)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  30天\n                "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            _c("div", { staticStyle: { display: "flex" } }, [
                              _c("div", {
                                ref: "shopPlateRef",
                                staticClass: "canvas-container",
                                staticStyle: { width: "50%" },
                              }),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "50%",
                                    "padding-left": "20px",
                                    "box-sizing": "border-box",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        padding: "35px 0",
                                        "border-bottom": "1px solid #eeeeee",
                                      },
                                    },
                                    [
                                      _c("p", { staticClass: "full_title" }, [
                                        _vm._v("息费总收入"),
                                      ]),
                                      _c("p", { staticClass: "full_desc" }, [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.statisticsFeeInfo.totalFee ||
                                                "0"
                                            ) +
                                            "\n                    "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-size": "14px",
                                              "margin-left": "6px",
                                            },
                                          },
                                          [_vm._v("元")]
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c("div", { staticClass: "flexBox" }, [
                                    _c("div", { staticClass: "flexBoxItem" }, [
                                      _c("p", { staticClass: "full_title" }, [
                                        _vm._v("服务费收入留存"),
                                      ]),
                                      _c("p", { staticClass: "full_desc" }, [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              _vm.statisticsFeeInfo
                                                .serviceFee || "0"
                                            ) +
                                            "\n                      "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-size": "14px",
                                              "margin-left": "6px",
                                            },
                                          },
                                          [_vm._v("元")]
                                        ),
                                      ]),
                                    ]),
                                    _c("div", { staticClass: "flexBoxItem" }, [
                                      _c("p", { staticClass: "full_title" }, [
                                        _vm._v("利息收入"),
                                      ]),
                                      _c("p", { staticClass: "full_desc" }, [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              _vm.statisticsFeeInfo
                                                .interestFee || "0"
                                            ) +
                                            "\n                      "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-size": "14px",
                                              "margin-left": "6px",
                                            },
                                          },
                                          [_vm._v("元")]
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mb20 infos" },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c(
                            "div",
                            {
                              staticClass: "full",
                              staticStyle: { "padding-left": "18px" },
                            },
                            [
                              _c("p", { staticClass: "full_title" }, [
                                _vm._v("当前总息费IRR"),
                              ]),
                              _c("p", { staticClass: "full_desc" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.databoard.interestFeeAnnualizedIrr || 0
                                  ) + "%"
                                ),
                              ]),
                            ]
                          ),
                        ]),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c(
                            "div",
                            {
                              staticClass: "full",
                              staticStyle: { "padding-left": "18px" },
                            },
                            [
                              _c("p", { staticClass: "full_title" }, [
                                _vm._v("当前服务费IRR"),
                              ]),
                              _c("p", { staticClass: "full_desc" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.databoard.serviceFeeAnnualizedIrr || 0
                                  ) + "%"
                                ),
                              ]),
                            ]
                          ),
                        ]),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c(
                            "div",
                            {
                              staticClass: "full",
                              staticStyle: { "padding-left": "18px" },
                            },
                            [
                              _c("p", { staticClass: "full_title" }, [
                                _vm._v("当前利息IRR"),
                              ]),
                              _c("p", { staticClass: "full_desc" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.databoard.interestAnnualizedIrr || 0
                                  ) + "%"
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ])
      : _c("div", [_c("h1", [_vm._v("抱歉！您没有任何权限")])]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }