<template>
  <div class="selection-box">
    <el-form ref="form" :model="form" label-width="160px">
      <el-divider content-position="left" class="title-divider" @click="handleSave">渠道银行卡</el-divider>
      <el-form-item label="">
        <div class="btn-add-bankcard" @click="handleSave">
          <div><i class="el-icon-plus"></i></div>
          <div>新增账户2</div>
        </div>
      </el-form-item>
      <el-divider content-position="left" class="title-divider">收款卡号</el-divider>
      <el-empty v-if="skList.length === 0" :image-size="100" description="暂无收款账户" />
      <el-row v-for="(item, index) in skList" :key="'_sk' + index" class="sub-box">
        <div class="tag blue">{{ item.bankUserName }}</div>
        <div class="tr mb10">
          <el-button type="success" size="small" icon="el-icon-edit" @click="handleEdit(item.id)">编辑</el-button>
        </div>
        <el-col :span="12">
          <el-form-item label="收款方账号：" prop="cargoBusinessId">
            <el-input v-model="item.bankCard" autocomplete="off" readonly />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="收款方户名：" prop="cargoBusinessId">
            <el-input v-model="item.bankUserName" autocomplete="off" readonly />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="银行名称：" prop="cargoBusinessId">
            <el-input v-model="item.bankName" autocomplete="off" readonly />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开户行支行：" prop="cargoBusinessId">
            <el-input v-model="item.bankSubName" autocomplete="off" readonly />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-drawer
      title="新增账户"
      class="left-drawer"
      :visible.sync="drawer"
      :destroy-on-close="true"
      :wrapper-closable="false"
      :size="'38%'"
      :min-width="600"
      :before-close="handleClose"
    >
      <accAdd :e-id="enterId" />
    </el-drawer>

    <el-drawer
      title="修改账户"
      class="left-drawer"
      :visible.sync="drawer2"
      :destroy-on-close="true"
      :wrapper-closable="false"
      :size="'38%'"
      :before-close="handleClose"
    >
      <accEdit :e-id="bankCustId" />
    </el-drawer>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { doGet, doPost } from '@/router/axios';
import DictSelect from '@/views/components/DictSelect.vue';
import DictSelectMultiple from '@/views/components/DictSelectMultiple.vue';
import accAdd from '@/views/channel/components/bankAdd';
import accEdit from '@/views/channel/components/bankEdit';
export default defineComponent({
  name: 'companyAccountInfo',
  components: { DictSelect, DictSelectMultiple, accAdd, accEdit },
  data() {
    return {
      btnLoading: false,
      enterId: '',
      bankCustId: '',
      jgList: [{
        bankDto: {}
      }],
      skList: [],
      form: {
        companyName: '',
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      drawer: false,
      drawer2: false
    };
  },
  created() {
    this.getAccount();
  },
  methods: {
    getAccount() {
      doGet('/cap/account/queryChanAcc').then(res => {
        if (res.data.code === 200) {
          this.skList = res.data.data.skList;
        }
      }).catch(() => {
      });
    },
    handleSave() {
      this.drawer = true;
    },
    handleEdit(id) {
      this.bankCustId = id;
      this.drawer2 = true;
    },
    handleReset() {
      this.$refs.form.resetFields();
    },
    handleChangeCompany(val) {
      this.enterId = val;
      this.getAccount(val);
    },
    handleClose(done) {
      this.drawer = false;
      this.drawer2 = false;
      this.handleChangeCompany(this.enterId);
    }
  }
});
</script>

<style scoped lang="scss">
.sub-box {
  position: relative;
  //margin-bottom: 20px;
  padding: 30px 20px 0 0;
  margin: 0 80px 20px;
  background: #fafafa;
  &:last-child {
    margin-bottom: 0;
  }
  .tag{
    position: absolute;
    left: -4px;
    top: 0;
    border-radius: 4px 4px 4px 0px;
    color: #fff;
    padding: 2px 6px;
    font-size: 10px;
    line-height: 16px;
    background: #F56C6C;
    &::before{
      content: '';
      position: absolute;
      width: 3px;
      height: 3px;
      left: 0;
      bottom: -3px;
      background: #BB2A39;
      clip-path: polygon(0 0, 100% 0, 100% 100%); /*三角*/
    }
  }
  .tag.blue{
    background: #409EFF;
    &::before{
      background: #0b62f3;
    }
  }
}
.left-drawer {
  /deep/.el-drawer.rtl {
    min-width: 605px;
  }
}
/deep/ .inner-box {
  margin: 20px;
  width: auto;
}
/deep/ .icon-yiji-anquanjianguan {
  font-size: 12px !important;
}
/deep/ .sendMoney-box {
  .el-col {
    margin-top: 8px;
    margin-bottom: 0;
    border-radius: 5px;
  }
  .el-form-item {
    margin-bottom: 0;
  }
  .sendMoney-item div {
    background: #fff;
    line-height: 42px;
    font-size: 14px !important;
  }
  .sendMoney-item label {
    font-size: 14px !important;
    line-height: 42px;
  }
  .el-form-item__content {
    font-weight: 600;
  }
}
</style>
