<template>
  <div class="shop-loan">
    <div class="mz-layout-content">
      <div class="bg-grey">
        <el-row :gutter="30">
        <el-col :span="12">
          <div class="grid-card">
            <div class="title">总订单金额 (元)：</div>
            <div class="number">{{ bill.totalGoodsAmt | numberToCurrency }}</div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-card">
            <div class="title">总可申请最大金额 (元)：</div>
            <div class="number">{{ bill.totalApplyAmt | numberToCurrency }}</div>
          </div>
        </el-col>
        <!--<el-col :span="8">-->
          <!--<div class="grid-card">-->
            <!--<div class="title">总笔数：</div>-->
            <!--<div class="number">{{ bill.totalNum | numberToCurrency(0) }}</div>-->
          <!--</div>-->
        <!--</el-col>-->
      </el-row>
      </div>

      <el-row :gutter="30" class="mt20" v-loading="listLoading">
        <el-table
          :data="dataList"
          stripe
          header-row-class-name="header_row_className"
          :header-cell-style="{
           'background-color': '#FAFAFA'
          }">
          <!--<el-table-column-->
            <!--prop="shopName"-->
            <!--label="shopName"-->
            <!--align="center"-->
          <!--/>-->
          <el-table-column prop="item" label="名称" fixed width="120px" align="center">
            <template #default="scope">
              {{ scope.row.shopName || '-' }}
            </template>
          </el-table-column>
          <el-table-column prop="item" label="今日可申请金额(元)" align="center">
            <template #default="scope">
              {{ scope.row.maxApplyAmt | numberToCurrency }}
              <el-tag size="mini" class="grid-title-tag">{{ scope.row.applyDate || '-' }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="item" label="待还金额(元)" align="center">
            <template #default="scope">
              <div v-if="scope.row.status === '02'" class="grid-title red">
                <div>
                  <div class="g-t-key">待还金额(元)</div>
                  <div class="g-t-value">{{ scope.row.unpayAmount | numberToCurrency }}</div>
                </div>
                <el-tag size="mini" type="danger" class="grid-title-tag" @click="handleDetails(scope.row, index)">
                  已逾期
                </el-tag>
              </div>
              <div v-else>0</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="enterName"
            label="所属企业名称"
            align="center"
          />
          <el-table-column
            prop="liveStreamPlatName"
            label="所属平台"
            align="center"
          />
          <el-table-column prop="item" label="费率" align="center" show-overflow-tooltip>
            <template slot="header">费率
              <el-tooltip placement="top" effect="dark">
                <div slot="content" class="w300">
                  <p>小帮手:</p>
                  <p>
                    可支用金额与店铺待结算金额的比值，折扣率会根据您的经营情况变化，店铺经营情况越好折扣率越高。
                  </p>
                </div>
                <i class="iconfont icon-a-yiwenwenhaobangzhu f12 fc_888" />
              </el-tooltip>
            </template>
            <template #default="scope">
              <div class="grid-item">
                <div v-if="scope.row.feeRate > 0">{{ scope.row.feeRate | numberToCurrency }}%/单笔</div>
                <div v-else>N/A</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="item" label="用款周期" align="center" show-overflow-tooltip>
            <template slot="header">用款周期
              <el-tooltip placement="top" effect="dark">
                <div slot="content" class="w300">
                  <p>小帮手:</p>
                  <p>
                    用款周期会随着您店铺经营情况变化，您的店铺回款周期越长，用款周期越长，超期未回款视作逾期，您可以主动还款
                  </p>
                </div>
                <i class="iconfont icon-a-yiwenwenhaobangzhu f12 fc_888" />
              </el-tooltip>
            </template>
            <template #default="scope">
              <div class="grid-item">
                <div v-if="scope.row.maxDays > 0">{{ scope.row.maxDays }}天</div>
                <div v-else>N/A</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="item" label="折扣率" align="center" show-overflow-tooltip>
            <template slot="header">折扣率
              <el-tooltip placement="top" effect="dark">
                <div slot="content" class="w300">
                  <p>小帮手:</p>
                  <p>
                    可支用金额与店铺待结算金额的比值，折扣率会根据您的经营情况变化，店铺经营情况越好折扣率越高。
                  </p>
                </div>
                <i class="iconfont icon-a-yiwenwenhaobangzhu f12 fc_888" />
              </el-tooltip>
            </template>
            <template #default="scope">
              <div class="grid-item">
                <div v-if="scope.row.discountRate > 0">{{ scope.row.discountRate }}%</div>
                <div v-else>N/A</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="item" label="订单回溯周期" align="center" show-overflow-tooltip>
            <template slot="header">订单回溯周期
              <el-tooltip placement="top" effect="dark">
                <div slot="content" class="w300">
                  <p>小帮手:</p>
                  <p>
                    平台会以该周期内的待结算订单为标准，每日计算您的可支用金额，店铺经营状态越好天数越长。
                  </p>
                </div>
                <i class="iconfont icon-a-yiwenwenhaobangzhu f12 fc_888" />
              </el-tooltip>
            </template>
            <template #default="scope">
              <div class="grid-item">
                <div>{{ scope.row.shopDays }}天</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="item" label="待结算订单金额" align="center">
            <template #default="scope">
              ¥ {{ scope.row.orderAmt | numberToCurrency }}
            </template>
          </el-table-column>
          <el-table-column prop="item" label="待结算订单笔数" align="center">
            <template #default="scope">
                  {{ scope.row.orderNum | numberToCurrency(0) }}笔
              <el-link type="primary" @click="showOrderList(scope.row)" v-if="scope.row.shopId">详情</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="item" label="申请" align="center" fixed="right" width="120px">
            <template #default="scope">
              <div v-if="scope.row.status == 0" class="grid-btn btn-blue mt10 chand" @click="handleDetails(scope.row, index)">
                申请融资
              </div>
              <div v-else-if="scope.row.status == 2" class="grid-btn btn-gray mt10 chand">签约中</div>
              <div v-else-if="scope.row.status == 1" class="grid-btn btn-gray mt10 chand">已申请</div>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <!--<el-row :gutter="30" class="mt20" v-loading="listLoading">-->
        <!--<el-col v-for="(item, index) in dataList" :key="index" :span="8">-->
          <!--<div class="grid-content">-->
            <!--<div class="shop-title">{{ item.shopName || '-' }}</div>-->
            <!--<div class="grid-title blue">-->
              <!--<div>-->
                <!--<div class="g-t-key">今日可申请金额(元)</div>-->
                <!--<div class="g-t-value">{{ item.maxApplyAmt | numberToCurrency }}</div>-->
              <!--</div>-->
              <!--<el-tag size="mini" class="grid-title-tag">{{ item.applyDate || '-' }}</el-tag>-->
            <!--</div>-->
            <!--<div v-if="item.status === '02'" class="grid-title red">-->
              <!--<div>-->
                <!--<div class="g-t-key">待还金额(元)</div>-->
                <!--<div class="g-t-value">{{ item.unpayAmount | numberToCurrency }}</div>-->
              <!--</div>-->
              <!--<el-tag size="mini" type="danger" class="grid-title-tag" @click="handleDetails(item, index)">-->
                <!--已逾期-->
              <!--</el-tag>-->
            <!--</div>-->
            <!--<div class="grid-item-group">-->
              <!--<div class="grid-item">-->
                <!--<div>所属企业名称</div>-->
                <!--<div>{{ item.enterName }}</div>-->
              <!--</div>-->
              <!--<div class="grid-item">-->
                <!--<div>所属平台</div>-->
                <!--<div>{{ item.liveStreamPlatName }}</div>-->
              <!--</div>-->
              <!--<div class="grid-item">-->
                <!--<div>-->
                  <!--费率-->
                  <!--<el-tooltip placement="top" effect="light">-->
                    <!--<div slot="content" class="w300">-->
                      <!--<p>小帮手:</p>-->
                      <!--<p>费率会随着您的店铺经营情况变化，您的店铺回款周期越短，费率越低。</p>-->
                    <!--</div>-->
                    <!--<i class="iconfont icon-a-yiwenwenhaobangzhu f12 fc_888" />-->
                  <!--</el-tooltip>-->
                <!--</div>-->
                <!--<div v-if="item.feeRate > 0">{{ item.feeRate | numberToCurrency }}%/单笔</div>-->
                <!--<div v-else>N/A</div>-->
              <!--</div>-->
              <!--<div class="grid-item">-->
                <!--<div>-->
                  <!--用款周期-->
                  <!--<el-tooltip placement="top" effect="dark">-->
                    <!--<div slot="content" class="w300">-->
                      <!--<p>小帮手:</p>-->
                      <!--<p>-->
                        <!--用款周期会随着您店铺经营情况变化，您的店铺回款周期越长，用款周期越长，超期未回款视作逾期，您可以主动还款-->
                      <!--</p>-->
                    <!--</div>-->
                    <!--<i class="iconfont icon-a-yiwenwenhaobangzhu f12 fc_888" />-->
                  <!--</el-tooltip>-->
                <!--</div>-->
                <!--<div v-if="item.maxDays > 0">{{ item.maxDays }}天</div>-->
                <!--<div v-else>N/A</div>-->
              <!--</div>-->
              <!--<div class="grid-item">-->
                <!--<div>-->
                  <!--折扣率-->
                  <!--<el-tooltip placement="top" effect="dark">-->
                    <!--<div slot="content" class="w300">-->
                      <!--<p>小帮手:</p>-->
                      <!--<p>-->
                        <!--可支用金额与店铺待结算金额的比值，折扣率会根据您的经营情况变化，店铺经营情况越好折扣率越高。-->
                      <!--</p>-->
                    <!--</div>-->
                    <!--<i class="iconfont icon-a-yiwenwenhaobangzhu f12 fc_888" />-->
                  <!--</el-tooltip>-->
                <!--</div>-->
                <!--<div v-if="item.discountRate > 0">{{ item.discountRate }}%</div>-->
                <!--<div v-else>N/A</div>-->
              <!--</div>-->
              <!--<el-divider />-->
              <!--<div class="grid-item">-->
                <!--<div>-->
                  <!--订单回溯周期-->
                  <!--<el-tooltip placement="top" effect="dark">-->
                    <!--<div slot="content" class="w300">-->
                      <!--<p>小帮手:</p>-->
                      <!--<p>平台会以该周期内的待结算订单为标准，每日计算您的可支用金额，店铺经营状态越好天数越长。</p>-->
                    <!--</div>-->
                    <!--<i class="iconfont icon-a-yiwenwenhaobangzhu f12 fc_888" />-->
                  <!--</el-tooltip>-->
                <!--</div>-->
                <!--<div>{{ item.shopDays }}天</div>-->
              <!--</div>-->
              <!--<div class="grid-item">-->
                <!--<div>待结算订单金额</div>-->
                <!--<div>¥ {{ item.orderAmt | numberToCurrency }}</div>-->
              <!--</div>-->
              <!--<div class="grid-item">-->
                <!--<div>待结算订单笔数</div>-->
                <!--<div>-->
                  <!--{{ item.orderNum | numberToCurrency(0) }}笔-->
                  <!--<el-link type="primary" @click="showOrderList(item)" v-if="item.shopId">详情</el-link>-->
                <!--</div>-->
              <!--</div>-->
            <!--</div>-->
            <!--<div class="grid-split" />-->

            <!--<div v-if="item.status == 0" class="grid-btn btn-blue mt10 chand" @click="handleDetails(item, index)">-->
              <!--立即申请-->
            <!--</div>-->
            <!--<div v-else-if="item.status == 2" class="grid-btn btn-gray mt10 chand">签约中</div>-->
            <!--<div v-else-if="item.status == 1" class="grid-btn btn-gray mt10 chand">已申请</div>-->
          <!--</div>-->
        <!--</el-col>-->
      <!--</el-row>-->
    </div>

    <el-dialog
      title="支用信息确认"
      :visible.sync="dialogVisibleSure"
      :close-on-click-modal="false"
      :show-close="false"
      custom-class="pub_dialog"
      width="35%"
      top="50px"
      center>
      <el-form ref="dFormRef" :model="dForm" :label-position="'left'" label-width="120px">
        <el-form-item label="本次融资金额:">
          <el-input
            v-model="dForm.money"
            placeholder="请填写融资金额"
            class="rongziInput"
            size="medium"
            style="width: 90%" />
          元
        </el-form-item>
        <el-form-item label="店铺名称:">
          {{ shopLoan.shopName }}
        </el-form-item>
        <el-form-item label="企业名称:">
          {{ shopLoan.enterName }}
        </el-form-item>
        <el-form-item label="所属平台:">
          {{ shopLoan.liveStreamPlatName }}
        </el-form-item>
        <el-form-item label="本次费率:">
          <template v-if="shopLoan.feeRate > 0">{{ shopLoan.feeRate | numberToCurrency }}%/单笔</template>
          <template v-else>N/A</template>
        </el-form-item>
        <el-form-item label="用款周期:">
          <template v-if="shopLoan.maxDays > 0">{{ shopLoan.maxDays }}天</template>
          <template v-else>N/A</template>
        </el-form-item>
        <el-form-item label="折扣率:">
          <template v-if="shopLoan.discountRate > 0">{{ shopLoan.discountRate }}%</template>
          <template v-else>N/A</template>
        </el-form-item>
        <el-form-item label="收款银行卡账户:">
          <DictSelectSingle
            v-model="dForm.accountId"
            :url="'/cap/account/querySkBank/' + shopLoan.enterId"
            placeholder="请选择收款银行卡账户"
            @change="handleChangeCompany" />
        </el-form-item>
        <el-divider />
        <el-form-item label="待结算订单金额:">{{ shopLoan.orderAmt | numberToCurrency }}元</el-form-item>
        <el-form-item label="待结算订单笔数:">{{ shopLoan.orderNum | numberToCurrency(0) }}笔</el-form-item>
        <el-form-item>
          <el-checkbox v-model="agreeChecked" />
          本人已仔细阅读并完全理解、接受如下文件，接受该等文件的约束
          <a class="link-able" @click="jumpToUrl">《应收账款转让通知书》</a>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleSureClose" v-show="!btnLoading">取 消</el-button>
        <el-button type="primary" :disabled="!agreeChecked" v-loading="btnLoading" @click="applyOrder()">
          {{ btnMsg }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogVisibleSure2"
      title="订单明细"
      width="95%"
      :before-close="handleSureClose"
      :close-on-click-modal="false">
      <shop-goods v-if="dialogVisibleSure2" :modal-data="shopLoan" />
    </el-dialog>
  </div>
</template>

<script>
import { doGet, doPost } from '@/router/axios'
import { toOpenAgreement } from '@/utils/util'
// 子组件
import DictSelect from '@/views/components/DictSelectSingle'
import DictSelectSingle from '@/views/components/DictSelectSingle'
import shopGoods from './applyShopGoods'

export default {
  name: 'ShopLoan',
  components: { shopGoods, DictSelectSingle, DictSelect },
  data() {
    return {
      // 总揽信息
      bill: {},
      // 列表卡片
      dataList: [],
      listLoading: true,
      // 其他
      btnMsg: '确 定',
      btnLoading: false,
      dialogVisibleSure: false,
      dialogVisibleSure2: false,
      shopLoan: {},
      dForm: {
        money: null,
      },
      agreeChecked: false,
      isRouteEnter: false, // 是否进入当前路由页面
      taskTimer: null,
      initSigning: 'not',
    }
  },
  created() {
    this.isRouteEnter = true
    // this.initBillCount()
    this.getDataList(true)
  },
  destroyed() {
    this.isRouteEnter = false
    this.clearTaskTimer()
  },
  methods: {
    renderfeeRate(h, { column, $index }) {

    // <div>
    //   <el-tooltip placement="top" effect="light">
    //     <div slot="content" class="w300">
    //     <p>小帮手:</p>
    //   <p>费率会随着您的店铺经营情况变化，您的店铺回款周期越短，费率越低。</p>
    //   </div>
    //   <i class="iconfont icon-a-yiwenwenhaobangzhu f12 fc_888" />
    //     </el-tooltip>
    //     </div>
      return [
        column.label,
        h(
          'el-tooltip',
          {
            props: {
              content: '小帮手:费率会随着您的店铺经营情况变化，您的店铺回款周期越短，费率越低。', // 鼠标悬停时要展示的文字提示
              placement: 'top' // 悬停内容展示的位置
            }
          },
          [h('i', { class: { 'iconfont icon-a-yiwenwenhaobangzhu f12 fc_888': true }})] // 图标
        )
      ]
    },
    /** 获取总揽信息 */
    initBillCount() {
      doGet('/shop/info/reportShop').then(({ data }) => {
        if (data.code == 200) {
          this.bill = data.data || {}
        }
      })
    },
    /** 获取申请列表信息 */
    getDataList(isInit) {
      this.listLoading = isInit
      doGet('/shop/info/loanShopPage', {})
        .then(({ data }) => {
          if (data.code == 200) {
            this.dataList = data.data || []
            this.calcViewBillData()
            const isSigning = this.dataList.some((item) => {
              return item.status == 2
            })
            console.log('===isSigning', isSigning)
            // 初始和刷新列表时
            if (isInit) {
              // 判断有无签约中的数据
              this.initSigning = isSigning ? 'has' : 'not'
            } else {
              // 轮询任务结束，所有列表中的签署中状态都已更新
              if (this.initSigning === 'has' && !isSigning) {
                this.initSigning === 'no'
                this.$notify.success({ title: '签约成功，您的申请已提交，请等待审核', duration: 5000 })
              }
            }
            // 没有离开当前路由页面
            if (this.isRouteEnter) {
              // 轮询,3s
              this.taskTimer = setTimeout(() => {
                isSigning ? this.getDataList(false) : this.clearTaskTimer()
              }, 3 * 1000)
            }
          }
          this.listLoading = false
        })
        .catch((err) => {
          this.listLoading = false
        })
    },
    /** 计算顶部栏数据 */
    calcViewBillData() {
      let [orderAmtTotal, applyAmtTotal, orderNumTotal] = [0, 0, 0]
      this.dataList.forEach((loanItem) => {
        if (loanItem) {
          orderAmtTotal += Number(loanItem.orderAmt || 0)
          applyAmtTotal += Number(loanItem.maxApplyAmt || 0)
          orderNumTotal += Number(loanItem.orderNum || 0)
        }
      })
      this.bill.totalGoodsAmt = orderAmtTotal
      this.bill.totalApplyAmt = applyAmtTotal
      this.bill.totalNum = orderNumTotal
    },
    /** 结束轮训 */
    clearTaskTimer() {
      if (this.taskTimer) {
        clearTimeout(this.taskTimer)
        this.taskTimer = null
      }
    },

    /** 融资申请 */
    handleDetails(row, index) {
      if (row.accStatus == 1) {
        this.shopLoan = row
        this.dForm.money = row.maxApplyAmt || ''
        this.dForm.accountId = ''
        this.agreeChecked = false
        this.btnLoading = false
        this.btnMsg = '确 定'
        this.dialogVisibleSure = true
      } else {
        this.$alert('当前企业尚未完成账户准备，无法申请，请完成您的账户准备。', '账户准备提示', {
          confirmButtonText: '去准备账户',
          callback: (action) => {
            if (action === 'confirm') {
              this.$router.push({
                path: '/cap/index',
                query: { enterId: row.enterId },
              })
            }
          },
        })
      }
    },
    /** 查看订单详情 */
    showOrderList(row) {
      this.shopLoan = row
      this.dialogVisibleSure2 = true
    },

    /***************************** 融资订单表单（支用信息确认） ***/
    handleChangeCompany(val) {
      this.dForm.accountId = val
    },
    applyOrder() {
      if (this.agreeChecked && !this.btnLoading) {
        const { money, accountId } = this.dForm
        if (!money) {
          this.$notify.error('请填写融资申请金额')
          return
        }
        if (!accountId) {
          this.$notify.error('请选择收款银行卡账户')
          return
        }
        const params = {}
        params.enterId = this.shopLoan.enterId
        params.shopId = this.shopLoan.shopId
        params.useDay = this.shopLoan.maxDays
        params.discountRate = this.shopLoan.discountRate
        params.applyAmt = this.dForm.money
        params.accountId = this.dForm.accountId
        // 调接口
        this.btnLoading = true
        this.btnMsg = '提交中...'
        doPost('/rent/order/apply', params)
          .then(({ data }) => {
            if (data.code == 200) {
              this.dialogVisibleSure = false
              this.$notify.success({ title: '您的申请已提交，请查收短信完成签约', duration: 5000 })
              this.getDataList(true)
            }
          })
          .catch((e) => {
            this.btnLoading = false
            this.btnMsg = '确 定'
          })
      }
    },
    handleSureClose() {
      this.dialogVisibleSure = false
      this.dialogVisibleSure2 = false
    },
    jumpToUrl() {
      toOpenAgreement('F0306')
    },
  },
}
</script>

<style lang="scss">
.shop-loan {
  .mz-layout-content {
    width: 100%;
    padding-bottom: 20px;
    box-sizing: border-box;
  }
  .bg-blue {
    background: url('~@/assets/bg/blue_bg2@2x.png') 50% 50% no-repeat;
    background-size: 100%;
  }
  .bg-red {
    background: url('~@/assets/bg/red_bg2@2x.png') 50% 50% no-repeat;
    background-size: 100%;
  }
  .bg-orange {
    background: url('~@/assets/bg/orange_bg2@2x.png') 50% 50% no-repeat;
    background-size: 100%;
  }
  .grid-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 6px;
    min-height: 150px;
    color: #ffffff;
    position: relative;
    .title {
      height: 35px;
      font: 400 18px/35px 'PingFangSC-Regular, PingFang SC, Microsoft Yahei';
      font-weight: 400;
    }
    .number {
      height: 70px;
      font: 400 42px/70px 'tahoma,arial,Microsoft YaHei,Hiragino Sans GB,sans-serif';
      font-weight: 400;
    }
  }

  .grid-content {
    margin-bottom: 15px;
    padding: 15px 0 15px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    background-color: #fff;
    .shop-title {
      margin: 0 20px 20px;
      font: 600 18px/48px 'Microsoft YaHei';
      color: #2d8cf0;
      border-bottom: 1px dashed #efefef;
    }
    .grid-title {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      height: 64px;
      overflow: hidden;
      &:before {
        content: '';
        display: inline-block;
        margin-right: 16px;
        width: 8px;
        height: 64px;
        border-radius: 0px 4px 4px 0px;
      }
      &.blue {
        &:before {
          background: linear-gradient(360deg, #0298ff 0%, #3bdeff 100%);
        }
      }
      &.red {
        .val-red {
          color: #fd5d68;
        }
        &:before {
          background: linear-gradient(180deg, #faa14e 0%, #ff4a60 100%);
        }
      }

      .g-t-key {
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #364064;
        line-height: 22px;
      }
      .g-t-value {
        height: 32px;
        font-size: 32px;
        font-family: DINMittelschriftStd;
        color: #0093ff;
        line-height: 38px;
      }
      .grid-title-tag {
        position: absolute;
        right: 20px;
      }
    }
    .grid-item-group {
      margin: 40px 18px 0;
      .grid-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;
        & > div:first-child {
          height: 18px;
          font: 400 12px/18px PingFangSC-Regular, PingFang SC;
          color: #1b2749;
        }
        & > div:last-child {
          height: 18px;
          font: 400 12px/18px PingFangSC-Regular, PingFang SC;
          color: #1b2749;
        }
      }
    }
    .grid-split {
      height: 24px;
      background: url('~@/assets/illustration/grid-split.png') 0 0 no-repeat;
      background-size: 100%;
    }

    .grid-btn {
      margin-left: auto;
      margin-right: auto;
      width: 80%;
      height: 48px;
      color: #fff;
      border-radius: 5px;
      text-align: center;
      line-height: 48px;
      &.btn-blue {
        background: linear-gradient(360deg, #0298ff 0%, #3bdeff 100%);
      }
      &.btn-gray {
        color: #fff;
        background: linear-gradient(360deg, #a9a9a9 0%, #c1c1c1 100%);
      }
    }
  }

  .grid-btn {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    height: 48px;
    color: #fff;
    border-radius: 5px;
    text-align: center;
    line-height: 48px;
    &.btn-blue {
      background: linear-gradient(360deg, #0298ff 0%, #3bdeff 100%);
    }
    &.btn-gray {
      color: #fff;
      background: linear-gradient(360deg, #a9a9a9 0%, #c1c1c1 100%);
    }
  }
  .rongziInput {
    & /deep/ .el-input__inner {
      font-size: 24px !important;
      font-weight: 600;
      color: #206af3 !important;
      font-family: 'Microsoft YaHei', serif;
    }
  }
  .el-tooltip__popper.is-dark {
    background: #464c59;
    border: none;
  }
  .grid-btn{ height: 34px; line-height: 34px;}
  .grid-card{ color: #0093FF;}
  /*.grid-card .title{ width: 200px; text-align: right; padding-right: 25px;}*/
  .bg-grey{ background-color: rgb(250, 250, 250); padding: 0 90px;}
  /*.grid-card .number{width: 100px;}*/
  .grid-card .number{height: 60px }
}
</style>
