<template>
  <div class="user">
    <basic-container>
      <avue-crud
        ref="crud"
        v-model="form"
        :option="option"
        :page.sync="page"
        :table-loading="listLoading"
        :before-open="handleOpenBefore"
        :data="list"
        @on-load="getList"
        @size-change="sizeChange"
        @current-change="currentChange"
        @search-change="handleFilter"
        @refresh-change="handleRefreshChange"
        @row-update="update"
        @row-save="create"
      >
        <template slot="menuLeft" />
        <template
          slot="menu"
          slot-scope="scope"
        />
      </avue-crud>
    </basic-container>
  </div>

</template>

<script>
import { doGet, doPost, doDelet, doPut } from '@/router/axios'
import { mapGetters } from 'vuex'

export default {
  name: 'TableUser',
  data() {
    return {
      option: tableOption,
      defaultProps: {
        label: 'name',
        value: 'id'
      },
      page: {
        total: 0, 		// 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, 	// 每页显示多少条,
        isAsc: false	// 是否倒序
      },
      list: [],
      listLoading: true,
      form: {}
    }
  },
  computed: {

  },
  watch: {

  },
  created() {

  },
  methods: {
    getList(page, params) {
      if (params == undefined || params == null || params == '') {
        params = {}
      }
      this.listLoading = true
      params.current = page.currentPage - 1
      params.size = page.pageSize
      doGet('/wf/wfOrderInfo/page', params).then(res => {
        this.list = res.data.data.dataList
        this.page.total = res.data.data.totalElements
        this.listLoading = false
      })
    },
    sizeChange(size) {
      this.page.pageSize = size
    },
    currentChange(current) {
      this.page.currentPage = current
    },
    handleFilter(param, done) {
      this.page.currentPage = 1
      this.getList(this.page, param)
      done()
    },
    handleRefreshChange() {
      this.getList(this.page)
    },
    handleOpenBefore(show, type) {
      window.boxType = type
      // 若是编辑、查看回显名称
      if (['edit', 'views'].includes(type)) {

      } else if (type === 'add') {

      }
      show()
    },
    handleUpdate(row, index) {
      this.$refs.crud.rowEdit(row, index)
    },
    create(row, done, loading) {
      this.listLoading = true
      doPost('/wf/wfOrderInfo/save', this.form).then(res => {
        if (res.data.code === 200) {
          this.getList(this.page)
          done()
          this.$notify.success('创建成功')
          return false
        } else {
          this.listLoading = false
          this.$notify.error(res.data.msg)
        }
        loading()
      }).catch(() => {
        loading()
      })
    },
    update(row, index, done, loading) {
      doPut('/wf/wfOrderInfo/update', this.form).then(res => {
        if (res.data.code === 200) {
          this.getList(this.page)
          done()
          this.$notify.success('更新成功')
          return
        } else {
          this.$notify.error(res.data.msg)
          return
        }
        loading()
      }).catch(() => {
        loading()
      })
    },
    deletes(row) {
      this.$confirm('此操作将永久删除该条数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消', type: 'warning'
      }
      ).then(() => {
        doDelet('/wf/wfOrderInfo/delete/' + row.id).then(res => {
          if (res.data.code === 200) {
            this.getList(this.page)
            this.$notify.success('删除成功')
          } else {
            this.$notify.error(res.data.msg)
          }
        }).catch(() => {
          this.$notify.error('服务异常')
        })
      })
    }
  }
}

export const tableOption = {
  border: false,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menualign: 'left',
  menuWidth: 120,
  searchMenuSpan: 6,
  editBtn: false,
  viewBtn: true,
  delBtn: false,
  align: 'left',
  addBtn: false,
 searchShowBtn:false,
 column: [{

    label: 'id',
    prop: 'id',
    span: 24,
    hide: true,
    viewDisplay: false,
    editDisplay: false,
    addDisplay: false
  },
  {

    label: '流程',
    prop: 'procId',
    type: 'select',
    dicUrl: '/dict/type/D000060',
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: true,
      trigger: 'blur',
      message: '请输入流程'
    }
    ],
    search: true,
    span: 12
  },
  {

    label: '标题',
    prop: 'title',
    editDisplay: true,
    addDisplay: true,
    search: true,
    span: 12
  },
  {

    label: '编码',
    prop: 'orderNo',
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: true,
      trigger: 'blur',
      message: '请输入编码'
    },
    {
      min: '0',
      max: '30',
      message: '长度在 0 到 30 个字符',
      trigger: 'blur'
    }
    ],
    search: true,
    span: 12
  },
  {

    label: '创建人',
    prop: 'createUserName',
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: true,
      trigger: 'blur',
      message: '请输入创建人'
    },
    {
      min: '0',
      max: '32',
      message: '长度在 0 到 32 个字符',
      trigger: 'blur'
    }
    ],
    span: 12
  },
  {

    label: '业务ID',
    prop: 'bizId',
    hide: true,
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: true,
      trigger: 'blur',
      message: '请输入业务ID'
    },
    {
      min: '0',
      max: '32',
      message: '长度在 0 到 32 个字符',
      trigger: 'blur'
    }
    ],
    span: 12
  },
  {

    label: '状态值',
    prop: 'bizStatus',
    hide: true,
    viewDisplay: false,
    editDisplay: true,
    addDisplay: true,
    span: 12
  },
  {
    label: ' 状态',
    prop: 'status',
    type: 'select',
    dicUrl: '/dict/type/D000061',
    editDisplay: true,
    addDisplay: true,
    span: 12
  },
  {

    label: '创建时间',
    prop: 'createTime',
    editDisplay: false,
    addDisplay: false,
    span: 12
  },
  {

    label: '更新时间',
    prop: 'updateTime',
    editDisplay: false,
    addDisplay: false,
    span: 12
  },
  {
    label: '备注',
    prop: 'remark',
    type: 'textarea',
    editDisplay: true,
    addDisplay: true,
    span: 24
  }
  ]
}

</script>
