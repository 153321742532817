<template>
  <!--<el-select v-model="selectKey" :placeholder="placeholder" style="width: 100%;" @change="change" :disabled="disabled"  :readonly="readonly" >-->
  <el-select v-model="selectKey" filterable clearable :disabled="disabled" :multiple="multiple" :placeholder="placeholder" style="width: 100%;" @change="change" @blur="blur">
    <el-option v-for="item in zones" :key="item.value" :label="item.label" :value="String(item.value)" />
  </el-select>
</template>

<script>
import { doGet } from '@/router/axios';
export default {
  name: 'DictSelect',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    no: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: '/dict/type/'
    },
    initVa: {
      type: String,
      required: false,
      default: ''
    },
    value: {
      type: [Array],
      default: () => {
        return [];
      }
    },
    placeholder: {
      type: String,
      default: '请选择类型'
    }
  },
  data() {
    return {
      selectKey: [],
      zones: []
    };
  },
  watch: {
    value(newValue, oldValue) {
      this.selectKey = newValue;
    }
  },
  created() {
    this.getTree();
  },
  methods: {
    getTree() {
      const par = this.no;
      if (par.indexOf('D0') >= 0) {
        doGet(`${this.no}`).then(res => {
          this.zones = res.data.data;
        });
      } else {
        doGet(`${this.url}`).then(res => {
          this.zones = res.data.data;
        });
      }
    },
    change(val) {
      this.$emit('change', val);
    },
    blur() {
      this.$emit('blur', this.selectKey);
    }
  }
};
</script>
