<template>
  <div class="country-select-wrap">
    <div class="selection-box">
      <el-alert
        :title="title"
        :closable="false"
        type="warning"
      />
    </div>
    <div class="selection-box" style="margin-top: 10px;">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-radio v-model="myCountry" label="中国大陆">
            中国大陆
          </el-radio>
        </el-col>
        <el-col :span="6">
          <el-radio v-model="myCountry" label="香港">
            香港
          </el-radio>
        </el-col>
        <el-col :span="6">
          <div class="other-btn" @click="onToggle">
            其他国家或地区
            <i :class="`el-icon-arrow-${visible ? 'up' : 'down'}`" />
          </div>
        </el-col>
      </el-row>
      <div v-show="visible" style="padding-top: 30px;">
        <el-row v-for="(os, i) in otherCountrys" :key="i">
          <el-col v-for="c in os" :key="c" :span="6">
            <el-radio
              v-model="myCountry"
              :label="c"
            />
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="footer">
      <el-button type="primary" @click="onStart">
        开始认证
      </el-button>
    </div>

    <auth-tips v-model="dialogVisible" :country="country" @start="onAuth" />
  </div>
</template>
<script>
import AuthTips from './dialogs/AuthTips.vue'
import countrys from './countrys'

export default {
  name: 'CountrySelect',
  components: { AuthTips },
  model: {
    event: 'radioChange',
    prop: 'country'
  },
  props: {
    country: {
      type: String,
      default: '中国大陆'
    }
  },
  data() {
    return {
      visible: true,
      dialogVisible: false
    }
  },
  computed: {
    myCountry: {
      get() {
        return this.country
      },
      set(val) {
        this.$emit('radioChange', val)
      }
    },
    title() {
      return `仅中国大陆企业和个体工商户可以使用PayTrades结汇提现服务(不占用个人结汇5万美金额度)，若有疑问请联系 ${this.$constants.PHONE_NUM}；(什么是结汇提现？)`
    },
    otherCountrys() {
      const otherCountrys = []
      let s = 0
      getCountrys(countrys)
      return otherCountrys

      function getCountrys(countrys) {
        if (countrys.length >= s + 4) {
          const cs = countrys.slice(s, s + 4)
          otherCountrys.push(cs)
          s += 4
          getCountrys(countrys)
        }
      }
    }
  },
  methods: {
    onToggle() {
      this.visible = !this.visible;
    },
    onStart() {
      this.dialogVisible = true;
    },
    onAuth() {
      this.$emit('change', 0);
    }
  }
}
</script>
<style lang="scss" scoped>
	.country-select-wrap /deep/ {
		.el-row {
			margin-bottom: 15px;
		}
		.other-btn {
			cursor: pointer;
			font-size: 14px;
			color: #666;
			line-height: 1.5;
		}
		.footer {
			position: fixed;
			left: 0;
			right: 0;
			bottom: 0;
			text-align: center;
			z-index: 1;
			padding: 20px 0;
			border-top: 1px solid #ddd;
			background: white;
			.el-button {
				// margin-right: -180px;
			}
		}
	}
	@media screen and (max-width: 1042px) {
		.country-select-wrap /deep/ {
			.footer {
				.el-button {
					margin-right: 0;
				}
			}
		}
	}
</style>
