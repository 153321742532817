<template>
  <div>
    <svg v-if="current === 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
      <path fill="#f3f4f5" fill-opacity="1" d="M0,128L30,117.3C60,107,120,85,180,101.3C240,117,300,171,360,170.7C420,171,480,117,540,80C600,43,660,21,720,42.7C780,64,840,128,900,149.3C960,171,1020,149,1080,122.7C1140,96,1200,64,1260,58.7C1320,53,1380,75,1410,85.3L1440,96L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z" />
    </svg>
    <svg v-if="current === 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
      <path fill="#f3f4f5" fill-opacity="1" d="M0,32L16,74.7C32,117,64,203,96,240C128,277,160,267,192,256C224,245,256,235,288,192C320,149,352,75,384,69.3C416,64,448,128,480,154.7C512,181,544,171,576,186.7C608,203,640,245,672,261.3C704,277,736,267,768,240C800,213,832,171,864,144C896,117,928,107,960,133.3C992,160,1024,224,1056,245.3C1088,267,1120,245,1152,234.7C1184,224,1216,224,1248,202.7C1280,181,1312,139,1344,133.3C1376,128,1408,160,1424,176L1440,192L1440,320L1424,320C1408,320,1376,320,1344,320C1312,320,1280,320,1248,320C1216,320,1184,320,1152,320C1120,320,1088,320,1056,320C1024,320,992,320,960,320C928,320,896,320,864,320C832,320,800,320,768,320C736,320,704,320,672,320C640,320,608,320,576,320C544,320,512,320,480,320C448,320,416,320,384,320C352,320,320,320,288,320C256,320,224,320,192,320C160,320,128,320,96,320C64,320,32,320,16,320L0,320Z"/>
    </svg>
    <svg v-if="current === 3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
      <path fill="#f3f4f5" fill-opacity="1" d="M0,256L16,234.7C32,213,64,171,96,149.3C128,128,160,128,192,133.3C224,139,256,149,288,165.3C320,181,352,203,384,186.7C416,171,448,117,480,122.7C512,128,544,192,576,202.7C608,213,640,171,672,144C704,117,736,107,768,133.3C800,160,832,224,864,250.7C896,277,928,267,960,256C992,245,1024,235,1056,208C1088,181,1120,139,1152,128C1184,117,1216,139,1248,138.7C1280,139,1312,117,1344,128C1376,139,1408,181,1424,202.7L1440,224L1440,320L1424,320C1408,320,1376,320,1344,320C1312,320,1280,320,1248,320C1216,320,1184,320,1152,320C1120,320,1088,320,1056,320C1024,320,992,320,960,320C928,320,896,320,864,320C832,320,800,320,768,320C736,320,704,320,672,320C640,320,608,320,576,320C544,320,512,320,480,320C448,320,416,320,384,320C352,320,320,320,288,320C256,320,224,320,192,320C160,320,128,320,96,320C64,320,32,320,16,320L0,320Z"/>
    </svg>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { number } from 'echarts/lib/export';

export default defineComponent({
  name: 'index',
  props: {
    current: number
  }
});
</script>

<style scoped lang="scss">

</style>
