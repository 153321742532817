var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user" },
    [
      _c(
        "basic-container",
        [
          _c(
            "avue-crud",
            {
              ref: "crud",
              attrs: {
                option: _vm.option,
                page: _vm.page,
                search: _vm.search,
                "table-loading": _vm.listLoading,
                "before-open": _vm.handleOpenBefore,
                data: _vm.list,
              },
              on: {
                "update:page": function ($event) {
                  _vm.page = $event
                },
                "update:search": function ($event) {
                  _vm.search = $event
                },
                "on-load": _vm.getList,
                "size-change": _vm.sizeChange,
                "current-change": _vm.currentChange,
                "search-change": _vm.handleFilter,
                "refresh-change": _vm.handleRefreshChange,
                "row-update": _vm.update,
                "row-save": _vm.create,
                "selection-change": _vm.selectionChange,
              },
              scopedSlots: _vm._u([
                {
                  key: "menu",
                  fn: function (scope) {
                    return [
                      _c("el-button", {
                        attrs: { type: "primary", icon: "el-icon-view" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDetails(scope.row, scope.index)
                          },
                        },
                      }),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.form,
                callback: function ($$v) {
                  _vm.form = $$v
                },
                expression: "form",
              },
            },
            [_c("template", { slot: "menuLeft" })],
            2
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            width: "98%",
            "custom-class": "pub_dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.objId
            ? _c("page-dea", { attrs: { "modal-data": _vm.objId } })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }