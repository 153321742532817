import { render, staticRenderFns } from "./details.vue?vue&type=template&id=b531457c&scoped=true"
import script from "./details.vue?vue&type=script&lang=js"
export * from "./details.vue?vue&type=script&lang=js"
import style0 from "./details.vue?vue&type=style&index=0&id=b531457c&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b531457c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/bium-ops-web_2zId/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b531457c')) {
      api.createRecord('b531457c', component.options)
    } else {
      api.reload('b531457c', component.options)
    }
    module.hot.accept("./details.vue?vue&type=template&id=b531457c&scoped=true", function () {
      api.rerender('b531457c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/credit/creditCustInfo/components/details.vue"
export default component.exports