<template>
  <div>
    <div @click="onPreview()" class="cursor">
      <slot />
    </div>
    <el-image-viewer
            v-if="showViewer"
            :on-close="closeViewer"
            :url-list="[srcList]"
    />
  </div>
</template>

<script>
  import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
  import { getImageUrl } from '@/api/upload'
  export default {
    name: "ImageView",
    components: { ElImageViewer },
    props: {
      fileId: String
    },
    computed: {
      srcList() {
        return getImageUrl(this.fileId)
      }
    },
    data() {
      return {
        showViewer: false,
      }
    },
    methods: {
      onPreview(img) {
        this.showViewer = true
      },
      closeViewer() {
        this.showViewer = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .cursor {
    cursor: pointer;
  }
  .el-image-viewer__wrapper {
    z-index: 2005 !important;
  }
</style>
