<template>
  <div class="mz-layout-section">
    <div class="tableList_title">渠道收益列表</div>
    <el-divider />

    <avue-crud
      ref="crud"
      :option="option"
      :page.sync="page"
      :table-loading="listLoading"
      :data="list"
      @on-load="getList"
      @size-change="sizeChange"
      @current-change="currentChange"
      @search-change="handleFilter"
      @search-reset="resetFilter">
    </avue-crud>
  </div>
</template>

<script>
import { doGet } from '@/router/axios'
const tableOption = {
  searchMenuSpan: 24,
  searchLabelWidth: 80,
  border: false,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menuWidth: 230,
  header: false,
  refreshBtn: false,
  columnBtn: false,
  menu: false,
  editBtn: false,
  delBtn: false,
  align: 'left',
  addBtn: false,
 searchShowBtn:false,
 column: [
    {
      fixed: true,
      label: '企业',
      prop: 'enterName',
      minWidth: 180,
      fixed: true,
      editDisplay: true,
      addDisplay: true,
      search: true,
      searchTags: true,
      searchSpan: 8,
      span: 12,
    },
    {
      label: '订单号',
      prop: 'orderNo',
      width: 160,
      editDisplay: false,
      addDisplay: false,
      search: true,
      searchSpan: 8,
      span: 12,
    },
    {
      label: '商品',
      prop: 'goodsName',
      width: 180,
      editDisplay: false,
      addDisplay: false,
      searchSpan: 8,
      span: 12,
    },
    {
      label: '收益金额',
      prop: 'incomeAmt',
      editDisplay: true,
      addDisplay: true,
      span: 12,
    },
    {
      label: '收益时间',
      prop: 'incomeTime',
      width: 160,
      editDisplay: true,
      addDisplay: true,
      span: 12,
    },
    {
      label: '发放时间',
      prop: 'giveTime',
      width: 160,
      editDisplay: true,
      addDisplay: true,
      span: 12,
    },
    {
      label: '状态',
      prop: 'status',
      editDisplay: true,
      addDisplay: true,
      type: 'select',
      search: true,
      searchSpan: 8,
      width: 150,
      span: 12,
    },
  ],
}

export default {
  name: 'IncomeList',
  components: {},
  data() {
    return {
      option: tableOption,
      searchItmes: {},
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条,
      },
      list: [],
      listLoading: true,
    }
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    getList(page, params) {
      params = params ? params : { ...this.searchItmes }
      params.current = page.currentPage - 1
      params.size = page.pageSize
      this.listLoading = true
      doGet('/rent/inc/queryPage', params).then(({ data }) => {
        if (data) {
          const { dataList, totalElements } = data.data || {}
          this.list = dataList || []
          this.page.total = totalElements || 0
        }
        this.listLoading = false
      })
    },

    sizeChange(size) {
      this.page.pageSize = size
    },
    currentChange(current) {
      this.page.currentPage = current
    },
    handleFilter(param, done) {
      this.page.currentPage = 1
      this.searchItmes = param ? param : {}
      this.getList(this.page, param)
      done()
    },
    resetFilter() {
      this.page.currentPage = 1
      this.searchItmes = {}
      this.getList(this.page, this.searchItmes)
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/ .el-tabs__nav-scroll {
  padding-left: 10px;
}
/deep/ .el-divider {
  background-color: #ecedef;
}
.my-invest-item {
  li {
    position: relative;
    float: left;
  }

  .ico {
    position: absolute;
    top: 10px;
    left: 12px;
    width: 24px;
    height: 24px;
    display: inline-block;
    background-image: url('/img/icon/centre-icon.png');
    vertical-align: middle;
  }

  div {
    border: 1px solid #efefef;
    padding: 10px 20px 10px 40px;
    border-radius: 5px;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
    margin-right: 20px;
  }
}

.invest-ico-1 {
  background-position: -624px -78px;
}

.invest-ico-2 {
  background-position: -624px -104px;
}

.invest-ico-3 {
  background-position: -624px -130px;
}

.invest-ico-4 {
  background-position: -558px -26px;
}
</style>
