<template>
  <div>
    <div class="repay-section mt20 ">
      <avue-crud :data="billForm.dtoList" :option="tableOption">
      </avue-crud>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RepaymentSchedule',
  props: {
    billForm: { type: Object }
  },
  data() {
    return {
      repayData: [],
      tableOption: tableOption
    }
  }
}
const tableOption = {
  page: false,
  indexLabel: '序号',
  stripe: true,
  align: 'left',
  addBtn: false,
  refreshBtn: false,
  header: false,
  menu: false,
  menuBtn: false,
 searchShowBtn:false,
 column: [{
    fixed: true,
    label: 'id',
    prop: 'id',
    hide: true,
    editDisplay: false,
    addDisplay: false
  },
  {
    label: '借款编号',
    prop: 'orderNo'
  }, {
    label: '借款金额',
    prop: 'applyAmt'
  },
  {
    label: '借款期限',
    prop: 'linesDay'
  }, {
    label: '借款时间',
    prop: 'createTime'
  }
  ]
}
</script>

<style lang="scss" scoped>
.repay-section {
  overflow: hidden;
  .repay-box {
    padding: 20px;
    box-sizing: border-box;
    border: 1px solid #dfdfdf;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
    min-height: 218px;
    overflow: hidden;
    .repay-ul {
      overflow: hidden;
      .repay-li {
        float: left;
        padding: 5px 0;
        width: 50%;
      }
    }
  }
}
</style>
