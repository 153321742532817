<template>
  <div class="user">
    <basic-container>
      <avue-crud
        ref="crud"
        v-model="form"
        :option="option"
        :page.sync="page"
        :table-loading="listLoading"
        :before-open="handleOpenBefore"
        :data="list"
        @on-load="getList"
        @size-change="sizeChange"
        @current-change="currentChange"
        @search-change="handleFilter"
        @refresh-change="handleRefreshChange"
        @row-update="update"
        @row-save="create"
      >
        <template slot="menuLeft" />

        <template
          slot="menu"
          slot-scope="scope"
        >
          <el-button
            type="text"
            icon="el-icon-view"
            @click="handleDetails(scope.row,scope.index)"
          >详情
          </el-button>
        </template>
      </avue-crud>
    </basic-container>

    <el-dialog
      title="额度详情"
      :visible.sync="dialogVisible"
      width="98%"
      custom-class="pub_dialog"
    >
      <page-details v-if="fileId" :modal-data="fileId" />
    </el-dialog>
  </div>
</template>

<script>
import { doGet, doPost, doDelet, doPut } from '@/router/axios'
import { mapGetters } from 'vuex'
import pageDetails from './components/details'
export default {
  name: 'TableUser',
  components: { pageDetails},
  data() {
    return {
      id: '',
      option: tableOption,
      visibleDialog: false,
      defaultProps: {
        label: 'name',
        value: 'id'
      },
      page: {
        total: 0, 		// 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, 	// 每页显示多少条,
        isAsc: false	// 是否倒序
      },
      list: [],
      listLoading: true,
      dialogVisible: false,
      fileId: '',
      form: {}
    }
  },
  computed: {

  },
  watch: {

  },
  created() {

  },
  methods: {
    getList(page, params) {
      if (params == undefined || params == null || params == '') {
        params = {}
      }
      this.listLoading = true
      params.current = page.currentPage - 1
      params.size = page.pageSize
      doGet('/credit/creditCustDeatil/page', params).then(res => {
        this.list = res.data.data.dataList
        this.page.total = res.data.data.totalElements
        this.listLoading = false
      })
    },
    sizeChange(size) {
      this.page.pageSize = size
    },
    currentChange(current) {
      this.page.currentPage = current
    },
    handleFilter(param, done) {
      this.page.currentPage = 1
      this.getList(this.page, param)
      done()
    },
    handleDetails(row, index) {
      console.log('row.id' + row.id)
      this.fileId = row.id
      this.dialogVisible = true
    },
    handleRefreshChange() {
      this.getList(this.page)
    },
    handleOpenBefore(show, type) {
      window.boxType = type
      // 若是编辑、查看回显名称
      if (['edit', 'views'].includes(type)) {

      } else if (type === 'add') {

      }
      show()
    },
    handleUpdate(row, index) {
      this.id = row.id
      this.visibleDialog = true
    },
    update(row, index, done, loading) {
      doPut('/credit/creditCustInfo/update', this.form).then(res => {
        if (res.data.code === 200) {
          this.getList(this.page)
          done()
          this.$notify.success('更新成功')
          return
        } else {
          this.$notify.error(res.data.msg)
          return
        }
        loading()
      }).catch(() => {
        loading()
      })
    },
    deletes(row) {
      this.$confirm('此操作将永久删除该条数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消', type: 'warning'
      }
      ).then(() => {
        doDelet('/credit/creditCustInfo/delete/' + row.id).then(res => {
          if (res.data.code === 200) {
            this.getList(this.page)
            this.$notify.success('删除成功')
          } else {
            this.$notify.error(res.data.msg)
          }
        }).catch(() => {
          this.$notify.error('服务异常')
        })
      })
    },
    create() {}
  }
}

export const tableOption = {
  border: false,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menualign: 'left',
  menuWidth: 120,
  searchMenuSpan: 6,
  editBtn: false,
  delBtn: false,
  align: 'left',
  addBtn: false,
 searchShowBtn:false,
 column: [{
    fixed: true,
    label: 'id',
    prop: 'id',
    span: 24,
    hide: true,
    editDisplay: false,
    addDisplay: false
  },
  {
    fixed: true,
    label: '授信编号',
    prop: 'creditNo',
    editDisplay: true,
    addDisplay: true,
    search: true,
    span: 12
  },
  {
    fixed: true,
    label: '企业ID',
    prop: 'enterpriseCode',
    display: false,
    search: true,
    span: 12
  },
  {
    fixed: true,
    label: '企业名称',
    prop: 'enterpriseName',
    editDisplay: true,
    addDisplay: true,
    search: true,
    span: 12
  },
  {
    fixed: true,
    label: '状态',
    prop: 'status',
    type: 'select',
    search: true,
    dicUrl: '/dict/type/D000079',
    editDisplay: true,
    addDisplay: true,
    span: 12
  },
  {
    fixed: true,
    label: '额度类型',
    prop: 'creditType',
    type: 'select',
    dicUrl: '/dict/type/D000055',
    editDisplay: true,
    addDisplay: true,
    span: 12
  },
  {
    fixed: true,
    label: '产品名称',
    prop: 'proName',
    editDisplay: true,
    addDisplay: true,
    search: true,
    span: 12
  },
  {
    fixed: true,
    label: '授信机构',
    prop: 'creditName',
    editDisplay: true,
    addDisplay: true,
    search: true,
    span: 12
  },
  {
    fixed: true,
    label: '授信总额',
    prop: 'totalAmt',
    editDisplay: true,
    addDisplay: true,
    span: 12
  },

  {
    fixed: true,
    label: '已用总额',
    prop: 'usedAmt',
    editDisplay: true,
    addDisplay: true,
    span: 12
  },
  {
    fixed: true,
    label: '可用额度',
    prop: 'balanceAmt',
    editDisplay: true,
    addDisplay: true,
    span: 12
  },
  {
    label: '额度生效日',
    prop: 'createTime',
    editDisplay: false,
    addDisplay: false,
    span: 12
  },
  {
    label: '额度到期日',
    prop: 'expireDate',
    editDisplay: false,
    addDisplay: false,
    span: 12
  },
  {
    label: '更新时间',
    prop: 'updateTime',
    editDisplay: false,
    addDisplay: false,
    span: 12
  }
  ]
}

</script>
