<template>
  <div class="step2-form-wrap">
    <el-form ref="vForm" :model="vForm" :rules="vRules" :label-position="'right'" label-width="180px" size="small">
      <el-form-item label="姓名" prop="legalPerson">
        <el-input v-model="vForm.legalPerson" placeholder="以身份证姓名为准" />
      </el-form-item>
      <el-form-item v-if="type !== 2" label="身份" prop="personType">
        <dict-select v-model="vForm.personType" no="Y00131" type="checkbox" is-slot>
          <template v-slot="{ zones }">
            <el-checkbox
              v-for="(item, i) in getPersonList(zones)"
              :key="item.id"
              disabled
              :label="item.id"
            >
              {{ item.label }}
            </el-checkbox>
          </template>
        </dict-select>
      </el-form-item>
      <el-form-item label="成员所属地区" prop="location" hidden>
        {{ vForm.location }}
      </el-form-item>
      <el-form-item label="主体类型" prop="cardType">
        <dict-select v-model="vForm.cardType" type="radio" no="Y00145" />
      </el-form-item>
      <el-form-item :label="`手持${cardLabel}(个人信息页)`" prop="fileCardFrontId">
        <dict-upload v-model="vForm.fileCardFrontId" type="text" :limit="1" type-code="F001003" bizId="123456" @change="onFileChange($event, 'fileCardFrontId')" />
        <el-link v-show="vForm.cardType == 1" :href="require('@/assets/auth/idcard_with_hand.jpg')" target="_blank">
          手持身份证样图
        </el-link>
        <div class="form-item-tips" style="line-height: 1.6; padding-top: 5px;">
          请手持{{cardLabel}}正面并拍照上传，请注意以下事项： <br>
          ① 手臂完整可见 <br>
          ② 信息清晰可见 <br>
          ③ 尽量把人拍大
        </div>
      </el-form-item>
      <el-form-item :label="`${cardLabel}${vForm.cardType == 1 ? '(头像页)' : '(首页)'}`" prop="fileCardPeopleId">
        <dict-upload v-model="vForm.fileCardPeopleId" type="text" :limit="1" type-code="F001008" bizId="123456" @change="onFileChange($event, 'fileCardPeopleId')" />
        <el-link v-show="vForm.cardType == 1" :href="require('@/assets/auth/idcard_with_hand_next.jpg')" target="_blank">
          身份证正面样图
        </el-link>
        <div class="form-item-tips">
          仅需上传证件照片
        </div>
      </el-form-item>
      <el-form-item label="身份证背面(国徽页)" prop="fileCardReverseId" v-if="vForm.cardType == 1">
        <dict-upload v-model="vForm.fileCardReverseId" type="text" :limit="1" type-code="F001004" bizId="123456" @change="onFileChange($event, 'fileCardReverseId')" />
        <el-link :href="require('@/assets/auth/idcard_emblem.jpg')" target="_blank">
          身份证背面样图
        </el-link>
        <div class="form-item-tips">
          仅需上传证件照片
        </div>
      </el-form-item>
      <el-form-item :label="`${cardLabel}号码`" prop="idCard">
        <el-input v-model="vForm.idCard" :placeholder="`请输入${cardLabel}号码`" maxlength="18" />
      </el-form-item>
      <el-form-item :label="`${cardLabel}派发日期`" prop="cardStartTime">
        <el-date-picker v-model="vForm.cardStartTime" value-format="yyyy-MM-dd" :placeholder="`请输入${cardLabel}派发日期`" />
      </el-form-item>
      <el-form-item :label="`${cardLabel}到期日期`" prop="cardEndTime">
        <template v-if="!vForm.cardEndTimeForever">
          <el-date-picker v-model="vForm.cardEndTime" value-format="yyyy-MM-dd" :placeholder="`请输入${cardLabel}到期日期`" />
          <span style="margin-right: 10px;" />
        </template>
        <el-checkbox v-model="vForm.cardEndTimeForever" @change="oncardEndTimeForeverChange" style="display: inline-block;">
          证件长期有效
        </el-checkbox>
      </el-form-item>
      <el-form-item label="持股比例" prop="shareHolding">
        <el-input-number v-model="vForm.shareHolding" placeholder="请输入持股比例百分数" maxlength="3" />
      </el-form-item>

      <el-form-item label="实际居住地址" prop="liveAddress">
        <addr-select v-model="vForm.liveAddressPre" internation />
        <div style="margin-bottom: 10px;" />
        <el-input v-model="vForm.liveAddress" placeholder="请输入详细区县街道门牌号地址" maxlength="200" />
      </el-form-item>
      <el-form-item label="居住地址证明(选填)" prop="fileLiveProveId">
        <dict-upload v-model="vForm.fileLiveProveId" type="text" :limit="1" type-code="F001007" bizId="123456" @change="onFileChange($event, 'fileLiveProveId')" />
        <div class="form-item-tips">
          近3个月包含实际居住地址与姓名的水电煤单据、信用卡账单等资料
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import DictUpload from '@/views/components/DictUpload.vue'
import DictSelect from '@/views/components/DictSelect.vue'
import DownloadFile from '@/views/components/DownloadFile.vue'
import AddrSelect from '@/components/AddrSelect/index.vue'
// import { validateIdCard } from '@/utils/validate'

export default {
  name: 'Step2Form',
  components: { DictUpload, DictSelect, DownloadFile, AddrSelect },
  props: {
  	type: {
  		type: Number,
  		default: 0 // 0: 法定代表人, 1: 企业董事/实际控制人/股东信息, 2: 被授权人
  	},
  	data: {
  		type: Object,
  		default: () => ({})
  	},
    enterpriseType: [String, Number]
  },
  data() {
    const personType = this.type === 0 ? ['1'] : ['2']
    return {
      vForm: {
        legalPerson: '',
        personType,
        location: '中国大陆',
        idCard: '',
        cardType: '1',
        cardEndTime: '',
        cardEndTimeForever: false,
        liveAddressPre: [], // 分解成 liveProvice liveCity
        liveAddress: '',
        fileCardFrontId: [],
        fileCardReverseId: [],
        fileCardPeopleId: [],
        fileLiveProveId: [],
        shareHolding: null,
        fileAuthId: [],
        cardStartTime: ''
      },
      vRules: {
        legalPerson: [{ required: true, trigger: 'blur', message: '请填写姓名，以身份证姓名为准' }],
        personType: [{ required: true, trigger: 'blur', message: '请选择身份' }],
        fileCardFrontId: [{ required: true, trigger: 'blur', message: `请上传${this.type !== 1 ? '手持身份证' : '身份证正面'}` }],
        fileCardReverseId: [{ required: true, trigger: 'blur', message: '请上传国徽页身份证' }],
        fileCardPeopleId: [{ required: true, trigger: 'blur', message: '请上传头像页身份证' }],
        idCard: [
          { required: true, trigger: 'blur', message: '请填写身份证号码' },
          { trigger: 'blur', validator: validateIdCard }
        ],
        cardStartTime: [{ required: true, trigger: 'blur', message: '请填写身份证派发日期' }],
        cardEndTime: [{ required: true, trigger: 'blur', message: '请填写身份证有效期' }],
        shareHolding: [{ required: true, trigger: 'blur', message: '请填写持股比率' }],
        cardEndTimeForever: [{ required: true, trigger: 'blur', message: '请填写身份证有效期' }],
        liveAddress: [{ required: true, trigger: 'blur', message: '请填写实际居住地址' }]
      }
    }
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      handler(val, oldVal) {
        Object.keys(val).forEach(key => {
          this.vForm[key] = val[key]
        })
      }
    }
  },
  computed: {
    cardLabel() {
      return this.vForm.cardType == 1 ? '身份证' : '护照'
    }
  },
  methods: {
  	onFileChange(ids = [], key) {
      this.$refs.vForm.validateField(key)
    },
    validate(callback) {
    	return this.$refs.vForm.validate(callback)
    },
    getPersonList(zones) {
      return this.type === 0 ? zones.slice(0, 1) : zones.slice(-1)
    },
    oncardEndTimeForeverChange(checked) {
      this.vForm.cardEndTime = checked ? '2099-12-31' : '';
      this.$refs.vForm.validateField('cardEndTime');
    }
  }
}
</script>
<style lang="scss" scoped>
	.step2-form-wrap {
		/deep/ .el-checkbox {
			display: block;

		}
	}
</style>
