<template>
  <div class="fund-fees">
    <basic-container v-loading="isLoading">
      <el-form
        ref="formRef"
        :model="dataForm.model"
        :rules="dataForm.rules"
        label-position="right"
        label-width="120px"
        size="small"
      >
        <el-alert title="资金方账户提现" :closable="false" type="info" />

        <section class="form-sec">
          <el-row :gutter="2">
            <el-col :span="24">
              <el-form-item label="资金方" prop="currFundId">
                <el-select
                  style="width: 100%"
                  v-model="dataForm.model.currFundId"
                  placeholder="请选择"
                  @change="changeAcc()"
                >
                  <el-option
                    v-for="item in fundOptions"
                    :key="item.upyztAccId"
                    :label="item.upyztAccName"
                    :value="item.upyztAccId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="2" type="flex" justify="space-between">
            <el-col :span="11">
              <el-form-item label="账户名称">
                <el-input
                  v-model="dataForm.model.accName"
                  placeholder="账户名称"
                  readonly
                />
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="钱包账号">
                <el-input
                  v-model="dataForm.model.upyztBookNo"
                  placeholder="钱包账号"
                  readonly
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="2" type="flex" justify="space-between">
            <el-col :span="11">
              <el-form-item label="银行名称">
                <el-input
                  v-model="dataForm.model.bankName"
                  placeholder="银行名称"
                  readonly
                />
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="银行卡号">
                <el-input
                  v-model="dataForm.model.bankCard"
                  placeholder="银行卡号"
                  readonly
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="2">
            <el-col :span="24">
              <el-form-item label="可提现金额" class="cash">
                <el-input
                  v-model="dataForm.model.balanceAmount"
                  placeholder="可提现金额"
                  readonly
                >
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="提现金额" prop="totalAmt">
                <el-input-number
                  v-model="dataForm.model.totalAmt"
                  placeholder="请输入"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="申请原因" prop="reason">
                <el-input
                  v-model="dataForm.model.reason"
                  type="textarea"
                  rows="3"
                  maxlength="500"
                  show-word-limit
                  placeholder="请输入"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </section>

        <section class="form-btn">
          <el-button
            type="primary"
            v-loading="isSubmiting"
            @click="submitCash()"
            >提现</el-button
          >
        </section>
      </el-form>
    </basic-container>
  </div>
</template>

<script>
import { dealNull } from "@/filters/index";
import { doGet, doPost } from "@/router/axios";

export default {
  name: "FundFees",
  props: {},
  data() {
    return {
      isLoading: false,
      isSubmiting: false,
      fundOptions: [],
      dataForm: {
        model: {},
        rules: {
          currFundId: [
            { required: true, trigger: "blur", message: "请选择资金方账户" },
          ],
          totalAmt: [
            { required: true, trigger: "blur", message: "请输入提现金额" },
            {
              trigger: "blur",
              validator: (field, value, cb) => {
                if (dealNull(value) != "-" && value == 0) {
                  cb(new Error("提现金额不能为0"));
                  return;
                }
                cb();
              },
            },
          ],
          reason: [
            { required: true, trigger: "blur", message: "请输入申请提现原因" },
          ],
        },
      },
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.getPlatformInfos();
  },
  methods: {
    /** 获取平台账户信息 */
    getPlatformInfos() {
      const sendData = {
        accMold: "02",
        current: 0,
        size: 10,
      };
      this.isLoading = true;
      doGet("/cap/account/queryPage", sendData)
        .then(({ data }) => {
          if (data && data.data && data.data.dataList) {
            this.fundOptions = data.data.dataList;
            if (this.fundOptions.length) {
              this.dataForm.model.currFundId = this.fundOptions[0].upyztAccId;
              this.changeAcc();
            }
          }
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    /** 修改资金方*/
    changeAcc() {
      const { currFundId } = this.dataForm.model;
      if (currFundId) {
        const currFundItem =
          this.fundOptions.filter(
            (item) => item.upyztAccId === currFundId
          )[0] || {};
        this.dataForm.model = {
          currFundId: currFundItem.upyztAccId,
          accName: currFundItem.accName,
          upyztBookNo: currFundItem.upyztBookNo,
          bankName: currFundItem.bankName,
          bankCard: currFundItem.bankCard,
          balanceAmount: currFundItem.balanceAmount,
          totalAmt: undefined,
          reason: "",
          accountId: currFundItem.id,
          bankCustId: currFundItem.bankCustId,
        };
      } else {
        this.dataForm.model = {};
        this.$notify.error("未获取当前账户信息，请联系后台管理员。");
      }
    },
    /** 提现 */
    submitCash() {
      if (!this.isSubmiting) {
        this.$refs.formRef.validate((valid) => {
          if (valid) {
            this.$confirm("确认提现?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).then(() => {
              const { accountId, bankCustId, totalAmt, reason } =
                this.dataForm.model;
              const sendData = {
                accountId,
                bankCustId,
                totalAmt,
                reason,
              };
              this.isSubmiting = true;
              doPost("/order/widthdrawalInfo/fundWithdraw", sendData)
                .then(({ data }) => {
                  if (data.code == 200) {
                    this.$notify.success(data.msg);
                    setTimeout(() => {
                      this.$router.push("/cap/account");
                    }, 1500);
                  }
                })
                .catch(() => {
                  this.isSubmiting = false;
                });
            });
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fund-fees {
  .el-alert {
    margin-bottom: 30px;
  }
  .form-sec {
    width: 80%;
    margin: 0 auto;
  }
  .form-btn {
    text-align: center;
  }

  /deep/.el-form {
    height: calc(100vh - 170px);
  }
  /deep/.el-input-number {
    width: 100%;
  }
  /deep/.cash .el-input__inner {
    color: #f00;
    font-weight: 600;
  }
}
</style>
