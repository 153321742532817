<template>
  <div class="user">
    <basic-container>
      <avue-crud
        ref="crud"
        v-model="form"
        :option="option"
        :page.sync="page"
        :table-loading="loading"
        :before-open="handleOpenBefore"
        :data="list.slice((page.currentPage-1) * page.pageSize,page.currentPage * page.pageSize)"
        @on-load="getList"
        @size-change="sizeChange"
        @search-change="handleFilter"
        @refresh-change="handleRefreshChange"
      >
        <template
          slot="menu"
          slot-scope="scope"

        >
          <el-button
            type="text"
            icon="el-icon-delete"
            @click="handleClean(scope.row,scope.index)"
          >清除
          </el-button>
        </template>
      </avue-crud>
    </basic-container>
  </div>
</template>

<script>
import { cacheList,cleanCache } from '@/api/monitor/cache'

export default {
  name: 'cacheList',
  data() {
    return {
      option: cacheOption,
      // 遮罩层
      loading: true,
      // 表格数据
      list: [],
      page: {
        total: 0, // 总页数
        currentPage: 1,
        pageSize: 10, // 每页显示多少条
      },
      form: {}
    }
  },
  created() {
    // this.getList()
  },
  methods: {
    /** 查询在线用户 */
    getList(page, params) {
      if (params === undefined || params == null || params === '') {
        params = {}
      }
      this.loading = true
      const obj = {
        ...params,
        // 'current': page.currentPage - 1,
        // 'size': page.pageSize
      }
      cacheList(obj).then(response => {
        this.list = response.data.data
        this.page.total = response.data.data.length
        this.loading = false
      })
    },
    handleOpenBefore(show, type) {
      window.boxType = type
      // 若是编辑、查看回显名称
      if (['edit', 'views'].includes(type)) {

      } else if (type === 'add') {

      }
      show()
    },
    handleRefreshChange() {
      this.getList(this.page)
    },
    sizeChange(size) {
      this.page.pageSize = size
    },
    /** 搜索按钮操作 */
    handleFilter(param, done) {
      this.page.currentPage = 1
      this.getList(this.page, param)
      done()
    },
    /** 强退按钮操作 */
    handleClean(row) {
      this.$confirm('是否确认清除Key为"' + row.key + '"的数据项?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function() {
        return cleanCache(row.key)
      }).then(() => {
        this.getList(this.page)
        this.$notify.success("清除成功");
      })
    }
  }
}

export const cacheOption = {
  menuWidth:100,
  border: false,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menualign: 'left',
  searchMenuSpan: 6,
  editBtn: false,
  delBtn: false,
  align: 'left',
  addBtn: false,
 searchShowBtn:false,
 column: [{
    fixed: true,
    label: 'Key',
    prop: 'key',
    span: 12,
    search: true,
    width: 130,
  }, {
    fixed: true,
    label: 'Value',
    prop: 'value',
    search: false,
    // overHidden: true,
    span: 12
  }]
}
</script>

