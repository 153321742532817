var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "mz-layout-section" },
        [
          _c("div", { staticClass: "tableList_title" }, [
            _vm._v("主动还款列表"),
          ]),
          _c("el-divider"),
          _c("avue-crud", {
            ref: "crud",
            attrs: {
              option: _vm.option,
              page: _vm.page,
              "table-loading": _vm.listLoading,
              data: _vm.list,
            },
            on: {
              "update:page": function ($event) {
                _vm.page = $event
              },
              "on-load": _vm.getList,
              "size-change": _vm.sizeChange,
              "current-change": _vm.currentChange,
              "search-change": _vm.handleFilter,
              "search-reset": _vm.resetFilter,
            },
            scopedSlots: _vm._u([
              {
                key: "menu",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", icon: "el-icon-view" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDetails(scope.row, scope.index)
                          },
                        },
                      },
                      [_vm._v("详情")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "还款单",
            visible: _vm.dialogVisible,
            width: "80%",
            top: "100px",
            "custom-class": "pub_dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.fileId
            ? _c("page-details", { attrs: { "modal-data": _vm.fileId } })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }