<template>
  <div class="sms-code pl20 pr20">
    <el-form
      ref="cFormRef"
      :model="dataForm.model"
      :rules="dataForm.rules"
      :label-position="'right'"
      label-width="120px"
      size="small">
      <el-alert title="您的监管账户已就绪，正在等待账户激活" :closable="false" type="success" />
      <div class="mt20 inner-box">
        <el-form-item class="verif-code" label="手机验证码" prop="smsCode">
          <el-input v-model="dataForm.model.smsCode" placeholder="请输入验证码">
            <template slot="append">
              <span class="append-text" v-show="isSentCode">
                <i>{{ sendTimerCount }}s</i>
                后重新发送
              </span>
              <span class="append-text send-btn" v-show="!isSentCode" @click="toSendCode">发送验证码</span>
            </template>
          </el-input>
        </el-form-item>
      </div>
      <el-alert
        :title="`为了您的账户激活，需对您的手机(${accInfos.sendMobilePhone})发送短信验证码验证，请注意查收短信`"
        :closable="false"
        class="mt20"
        type="info"
        show-icon />
      <el-divider />
      <div class="tc">
        <el-button type="primary" size="medium" :loading="isSubmiting" @click="openAcc()">我已收到短信，去验证</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { doGet, doPost } from '@/router/axios'
import { isVerifCode } from '@/utils/validate'
/** 资金方 */
const FunderTypes = ['acct_pingxx', 'acct_lanhai']

export default {
  name: 'SmsCode',
  components: {},
  props: {
    /** 账户信息，必须包含: 账户类型，ping++/蓝海, enterId */
    accInfos: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      isSubmiting: false,
      isSending: false,
      isSentCode: false,
      sendTimerCount: 60,
      dataForm: {
        model: {
          smsCode: '',
        },
        rules: {
          smsCode: [
            { required: true, trigger: 'blur', message: '请输入短信验证码' },
            {
              trigger: 'blur',
              validator: (field, value, cb) => {
                if (value && !isVerifCode(value)) {
                  cb(new Error('格式不正确，请输入6位数字短信验证码'))
                  return
                }
                cb()
              },
            },
          ],
        },
      },
    }
  },
  created() {},
  mounted() {},
  methods: {
    /** 发送手机验证码 */
    toSendCode() {
      if (!this.isSending) {
        // '/upyzt/cusapp/smscode/' + custId  // 愿接口
        const { enterId, accountChannel, isFreeSign } = this.accInfos
        let api = ''
        switch (accountChannel) {
          case FunderTypes[0]: //ping++
            api = `/cust/enterpriseAccount/receivePingxxSms/${enterId}/merchant_apply`
            break
          case FunderTypes[1]: //蓝海
            let smsType = isFreeSign ? 11 : 0 // 11-免密签约, 0-企业开户
            api = `/cust/enterpriseAccount/receiveLanHaiSms/${enterId}/${smsType}`
            break
          default:
            return
        }
        // 调接口
        this.isSending = true
        doGet(api)
          .then(({ data }) => {
            if (data.code == 200) {
              this.calcSendCount()
              this.$notify.success('短信验证码已发送，请注意查收')
            }
            this.isSending = false
          })
          .catch(() => {
            this.isSending = false
          })
      } else {
        this.$notify.error('正在发送...')
      }
    },
    /** 发送验证码计时 */
    calcSendCount() {
      if (this.sendTimerCount > 0) {
        this.sendTimerCount--
        this.isSentCode = true
        setTimeout(this.calcSendCount, 1000)
      } else {
        this.isSentCode = false
        this.sendTimerCount = 60
      }
    },

    /** 提交验证 */
    openAcc() {
      if (!this.isSubmiting) {
        this.$refs['cFormRef'].validate((valid) => {
          if (valid) {
            const sendData = {
              serialNum: this.accInfos.serialNum,
              param: this.dataForm.model.smsCode,
            }
            // /upyzt/cusapp/enterAccount //原接口
            this.isSubmiting = true
            doPost('/fundManage/flow/wakeUp', sendData)
              .then(({ data }) => {
                if (data.code === 200) {
                  this.$notify.success('短信验证码已验证成功')
                  this.$parent.handleClose()
                }
              })
              .catch((e) => {
                this.isSubmiting = false
              })
          }
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped></style>
