<template>
  <div class="dict-upload-process">
    <el-upload
      action="http-request"
      ref="upload"
      :limit="1"
      :file-list="fileList"
      :show-file-list="false"
      list-type="text"
      :on-change="changeData"
      :before-upload="beforeUpload"
      :on-success="handleSuccess"
      :on-progress="uploadProcess"
      :http-request="httpRequest"
      :on-error="handleError"
    >
      <el-button icon="el-icon-upload" type="primary">批量导入凭证</el-button>
    </el-upload>
    <el-dialog
      :visible.sync="dialogVisible"
      width="165px"
      :show-close="false"
      :before-close="handleClose"
    >
      <div slot="title" class="tc">上传进度</div>
      <el-progress :stroke-width="10" type="circle" :percentage="progressPercent" />
    </el-dialog>
  </div>
</template>

<script>
import { doPostMulti } from '@/router/axios'

export default {
  name: 'DictUploadProcess',
  props: {
    bizId: '',
    typeCode: ''
  },
  data() {
    return {
      fileList: [],
      progressPercent: 80,
      dialogVisible: false
    }
  },
  methods: {
    changeData() {},
    httpRequest(req) {
      // 调用接口方法
      const param = new FormData()
      Object.keys(req).forEach(key => {
        param.append(key, req[key]);
      })
      param.append('typeCode', 'F0105')
      param.append('bizId', '1234569870')
      this.dialogVisible = true
      doPostMulti('/voucher/upload', param).then(res => {
        if (res.data.code === 200) {
          this.dialogVisible = false
          this.$notify.success('上传成功')
          this.$emit('change')
        } else {
          this.dialogVisible = false
          // this.$notify.error(res.data.msg)
          this.$emit('change')
        }
      }).catch(() => {
        this.$refs.upload.clearFiles();
        this.dialogVisible = false
      })
    },
    beforeUpload(file) {
      const hz = file.name.split('.')[1]
      if (hz != 'xlsx' && hz != 'xls') {
        this.$message.error('文件类型错误，只能上传excel文件!')
        return false
      }
    },
    /**
       * 上传成功callback
       * */
    handleSuccess(res, file) {
      this.dialogVisible = false
      this.$refs.upload.clearFiles(); // 上传成功之后清除历史记录
      this.$message.info('文件上传成功!');
    },
    /**
       * 上传成功回掉callback
       * */
    uploadProcess(event, file, fileList) {
      this.dialogVisible = true
      this.progressPercent = Math.floor(event.progressPercent)
    },
    handleClose() {},
    handleError({ msg }) {
      if (msg) {
        this.$notify({
          title: msg,
          type: 'error',
          duration: 2500
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dict-upload-process {
    display: inline-block;
}
/deep/ .el-dialog {
    box-shadow: none;
    background-color: transparent;
}
/deep/ .el-progress__text {
    color: #fff;
}
</style>
