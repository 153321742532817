<template>
  <div class="bk_white">
    <div class="ml30 mr30 pb50" style="margin-top: 80px;">
      <div class="icon-success-box tc">
        <i class="el-icon-success" />
      </div>
      <p class="mt30 tc fb fc_333 f18">提交成功</p>
      <p class="pt20 pb20 f16 tc">{{ msg }}</p>
      <div class="success-info-box">
        <el-row>
          <el-col :span="24" class="title_h3 mb20">项目信息</el-col>
          <el-col :span="12">申请订单编号：{{ orderNo }}</el-col>
          <el-col :span="12">借款金额：{{ applyAmt }}元</el-col>
        </el-row>
      </div>
      <div class="mt40 tc">
        <el-button size="medium" type="primary" @click.stop="$router.push({ path: '/'})">返回首页</el-button>
        <el-button size="medium" @click.stop="$router.push({ path: '/loan/index'})">查看订单</el-button>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Success',
  data() {
    return {
      msg: '借款申请已提交成功，预计将在1个工作日内有审核结果',
      orderNo: '',
      rentAmt: 0
    }
  },
  created() {
    this.orderNo = this.$route.query.orderNo
    this.applyAmt = this.$route.query.applyAmt
  }
}
</script>

<style lang="scss" scoped>
  .icon-success-box {
    .el-icon-success {
      font-size: 70px;
      color: #67C23A;
    }
  }
  .title_h3 {
    height: 35px;
    font: 600 16px/100% "Microsoft YaHei";
    color: #333;
  }
  .success-info-box {
    margin: 50px auto 0;
    padding: 20px;
    box-sizing: border-box;
    width: 60%;
    background: #fafafa;
    border: 1px solid #dfdfdf;
  }
</style>
