
function Utils() {
  this.STATUS = Object.freeze({
    'APPLYING': '未启用',
    'DISABLE': '禁用',
    'ENABLE': '启用'
  });
  this.MSG_TYPE = Object.freeze({
    'LINK': '链接',
    'MARKDOWN': 'markdown',
    'TEXT': '文本'
  });

  this.MESSAGE_TYPE = Object.freeze({
    'INFO': '信息',
    'WARNING': '警告'
  });
  this.PROCESS_STATUS = Object.freeze({
    'FINISH': '完成',
    'INVALID': '无效',
    'PROCESSING': '已处理',
    'UNTREATED': '未处理'
  });

  this.OPERATION_STATUS = Object.freeze({
    'PROCESSING': '处理',
    'INVALID': '无效',
    'SUCCEED': '成功'
  });

  this.APPROVAL_STATUS = Object.freeze({
    'REJECT': '拒绝',
    'PENDING': '审批中',
    'APPROVED': '通过'
  });

  this.SUPERVISE_STATUS = Object.freeze({
    '0': '您的公司尚未绑定监管账户',
    // '2': '您有一个监管账户待激活',
    '2': '协议签署中，请稍候', //后台自行去调接口激活
    '3': '正在人工审批中，请稍候',
    '4': '账户打款验证中', //私户，短信验证后，再打款验证
    '9': '开户失败',
    '20': '您有一个监管账户待短信验证',
    '21': '账户打款验证中', //无需短信验证，直接打款
    '22': '您的银行卡信息有误，请重新绑卡', //蓝海银行流程中出现
    '23': '请完成免密签约的短信验证', //蓝海银行最后需免密签约
  });

  /**
   * 转译枚举
   * @param {String} value
   * @param {Array|String} entity
   * @returns {String}
   */
  this.translation = function(value, entity) {
    let entityObj;
    if ((typeof entity) === 'string') {
      entityObj = this[entity.toUpperCase()];
    } else {
      entityObj = entity;
    }
    return entityObj[value];
  };
  /**
   * 获得枚举下拉数组
   * @param {Array|String} entity
   * @returns {String}
   */
  this.select = function(entity) {
    let entityObj;
    if ((typeof entity) === 'string') {
      entityObj = this[entity.toUpperCase()];
    } else {
      entityObj = entity;
    }
    const array = [];
    for (const key in entityObj) {
      const itme = entityObj[key];
      array.push({
        'name': itme,
        'value': key
      });
    }
    return array;
  };

  /** 对应SUPERVISE_STATUS状态颜色  */
  this.color = ['#409eff', '#e6a23c', '#e6a23c', '#67c23a', '#ff0000', '#67c23a', '#67c23a', '#ff0000', '#67c23a'];
  /**
   * 获得枚举色标
   * 从0开始计数， 0：浅蓝 1:绿色 2：红色 5：蓝色
   * @param {String} value
   * @param {Array|String} entity
   * @returns {String} 颜色色标
   */
  this.obtainColor = function(value, entity) {
    let entityObj;
    if ((typeof entity) === 'string') {
      entityObj = this[entity.toUpperCase()];
    } else {
      entityObj = entity;
    }
    let i = 0;
    for (const key in entityObj) {
      if (key === value) {
        return this.color[i];
      }
      i++;
    }
    return this.color[i];
  };
}
const EntityUtils = new Utils();
export default EntityUtils;
