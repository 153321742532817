<template>
  <div class="user">
    <basic-container>
      <avue-crud
        ref="crud"
        v-model="form"
        :option="option"
        :page.sync="page"
        :table-loading="listLoading"
        :before-open="handleOpenBefore"
        :data="list"
        @on-load="getList"
        @size-change="sizeChange"
        @current-change="currentChange"
        @search-change="handleFilter"
        @refresh-change="handleRefreshChange"
        @row-update="update"
        @row-save="create"
        @selection-change="selectionChange"
      >
        <template slot="menuLeft">
          <!--          <el-button-->
          <!--            class="filter-item"-->
          <!--            type="primary"-->
          <!--            icon="el-icon-edit"-->
          <!--            @click="$refs.crud.rowAdd()"-->
          <!--          >录入流水-->
          <!--          </el-button>-->
        </template>

        <template
          slot="transAmount"
          slot-scope="scope"
        >
          <span>
            <el-tag>{{ scope.row.transAmount }} </el-tag>&nbsp;&nbsp;
          </span>
        </template>
        <template
          slot="menu"
          slot-scope="scope"
        >
          <el-button
            type="primary"
            icon="el-icon-edit"
            @click="handleUpdate(scope.row,scope.index)"
          />
          <el-button
            type="danger"
            icon="el-icon-delete"
            @click="deletes(scope.row,scope.index)"
          />
        </template>
        <template slot="inOut" slot-scope="scope">
          <el-tag effect="dark" :type="scope.row.inOut === '1' ? 'success' : 'danger'">{{ scope.row.inOut === '1' ? '收入' : '支出' }}</el-tag>
        </template>
      </avue-crud>
    </basic-container>
  </div>
</template>
<script>
import { doGet, doPost, doDelet, doPut, doExportExcel, downExcel } from '@/router/axios';
import { mapGetters } from 'vuex';
export default {
  name: 'TableUser',
  components: {
  },
  props: {
    accountId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      option: tableOption,
      defaultProps: {
        label: 'name',
        value: 'id'
      },
      dialogVisible: false,
      page: {
        total: 0, // 总页数
        current: 1, // 当前页数
        currentPage: 1,
        pageSize: 20,
        size: 20, // 每页显示多少条,
        isAsc: false// 是否倒序
      },
      list: [],
      selectedList: [],
      listLoading: true,
      form: {
      }
    };
  },
  computed: {

  },
  watch: {

  },
  mounted() {
  },
  methods: {
    selectionChange(list) {
      this.selectedList = list;
    },
    getNodeData(node) {
    },
    getList(page, params) {
      if (params == undefined || params == null || params == '') {
        params = {};
      }
      this.listLoading = true;
      params.current = this.page.currentPage - 1;
      params.size = this.page.pageSize;
      params.accountId = this.accountId;
      doGet('/cap/accountFlow/queryPage', params).then(res => {
        this.list = res.data.data.dataList;
        this.page.total = res.data.data.totalElements;
        this.listLoading = false;
      });
    },
    sizeChange(size) {
      this.page.size = size;
      this.page.pageSize = size;
    },
    currentChange(current) {
      this.page.current = current;
      this.page.currentPage = current;
    },
    handleFilter(param, done) {
      this.page.current = 1;
      this.page.currentPage = 1;
      this.getList(this.page, param);
      done();
    },
    copyAdd() {
      if (this.selectedList.length > 1 || this.selectedList.length == 0) {
        this.$notify.error('请选择复制数据');
        return;
      }
      this.form = this.selectedList[0];
      this.form.id = null;
      this.$refs.crud.rowAdd();
    },
    handleRefreshChange() {
      this.getList(this.page);
    },
    handleOpenBefore(show, type) {
      window.boxType = type;
      // 若是编辑、查看回显名称
      if (['edit', 'views'].includes(type)) {

      } else if (type === 'add') {

      }
      show();
    },
    handleUpdate(row, index) {
      this.$refs.crud.rowEdit(row, index);
    },
    handleExport() {
      this.listLoading = true;
      doExportExcel('/cap/accountFlow/export', this.form).then(res => {
      // 获取headers中的filename文件名

        downExcel(res);
        this.listLoading = false;
      });
    },
    handleTemplate() {
      this.listLoading = true;
      doExportExcel('/cap/accountFlow/exportTemplate', this.form).then(res => {
        downExcel(res);
        this.listLoading = false;
      });
    },
    create(row, done, loading) {
      this.listLoading = true;
      const params = {
        ...this.form
      };
      doPost('/cap/accountFlow/save', params).then(res => {
        if (res.data.code === 200) {
          this.getList(this.page);
          done();
          this.$notify.success('创建成功');
          return false;
        } else {
          const retMs = res.data.msg;
          this.listLoading = false;
          this.$notify.error(retMs);
          return;
        }
        loading();
      }).catch(() => {
        loading();
      });
    },
    update(row, index, done, loading) {
      const params = {
        ...this.form
      };
      doPut('/cap/accountFlow/update', params).then(res => {
        if (res.data.code === 200) {
          this.getList(this.page);
          done();
          this.$notify.success('更新成功');
          return;
        } else {
          const retMs = res.data.msg;
          this.listLoading = false;
          this.$notify.error(retMs);
          return;
        }
        loading();
      }).catch(() => {
        loading();
      });
    },
    deletes(row) {
      this.$confirm('此操作将永久删除选择数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消', type: 'warning'
      }
      ).then(() => {
        this.listLoading = true;
        doDelet('/cap/accountFlow/delete/' + row.id).then(res => {
          if (res.data.code === 200) {
            this.getList(this.page);
            this.$notify.success('删除成功');
          } else {
            const retMs = res.data.msg;
            this.listLoading = false;
            this.$notify.error(retMs);
            return;
          }
        }).catch(() => {
          this.$notify.error('服务异常');
        });
      });
    }
  }
};

export const tableOption = {
  border: false,
  showSummary: false,
  sumColumnList: [
  ],
  index: false,
  indexLabel: '序号',
  stripe: true,
  menualign: 'left',
  menuWidth: 160,
  searchMenuSpan: 6,
  searchLabelWidth: 100,
  searchIndex: 3,
  menu: false,
  searchIcon: true,
  searchShowBtn: false,
  labelWidth: 130,
  editBtn: false,
  delBtn: false,
  align: 'left',
  addBtn: false,
 searchShowBtn:false,
 column: [{
    fixed: true,
    label: 'id',
    prop: 'id',
    span: 24,
    hide: true,
    editDisplay: false,
    addDisplay: false
  },
  {
    label: '收支',
    prop: 'inOut',
    type: 'select',
    dicUrl: '/common/type/D000123',
    editDisplay: true,
    addDisplay: true,
    search: true,
    span: 12
  },
  {
    label: '科目',
    prop: 'ioName',
    editDisplay: true,
    addDisplay: true,
    search: true,
    span: 12
  },
  {
    label: '交易金额',
    prop: 'transAmount',
    editDisplay: true,
    addDisplay: true,
    type: 'number',
    span: 12
  },
  {
    label: '交易时间',
    prop: 'flowTime',
    type: 'datetime',
    format: 'yyyy-MM-dd HH:mm:ss',
    valueFormat: 'yyyy-MM-dd HH:mm:ss',
    editDisplay: true,
    addDisplay: true,
    span: 12
  },
  {
    label: '摘要',
    prop: 'remark',
    type: 'textarea',
    editDisplay: true,
    addDisplay: true,
    rules: [{
      required: false,
      message: '请输入备注'
    }
    ],
    span: 24
  },
  {
    label: '原金额',
    prop: 'bakTotleAmount',
    editDisplay: false,
    addDisplay: false,
    rules: [{
      required: false,
      message: '请输入原金额'
    }
    ],
    span: 12
  },
  {
    label: '新金额',
    prop: 'newTotleAmount',
    editDisplay: false,
    addDisplay: false,
    rules: [{
      required: false,
      message: '请输入新金额'
    }
    ],
    span: 12
  },
  {
    label: '是否检查',
    prop: 'isCheck',
    editDisplay: false,
    addDisplay: false,
    rules: [{
      required: false,
      message: '请输入是否检查'
    }
    ],
    hide: true,
    span: 12
  },

  {
    label: '创建时间',
    prop: 'createTime',
    editDisplay: false,
    addDisplay: false,
    rules: [{
      required: false,
      message: '请输入创建时间'
    }
    ],
    hide: true,
    span: 12
  },
  {
    label: '更新时间',
    prop: 'updateTime',
    editDisplay: false,
    addDisplay: false,
    rules: [{
      required: false,
      message: '请输入更新时间'
    }
    ],
    hide: true,
    span: 12
  }

  ]
};

</script>
