<template>
  <div class="auth-step-container">
    <div v-if="enterAuthDto.msg!=null&&enterAuthDto.msg!==''" class="selection-box mb20">
      <el-alert :title="enterAuthDto.msg" :closable="false" type="warning" show-icon />
    </div>

    <div class="selection-box">
      <el-steps :active="active" finish-status="success" simple class="mb20">
        <el-step title="上传营业执照" icon="el-icon-edit" />
        <el-step title="法人信息登记" icon="el-icon-upload" />
        <el-step title="企业认证" icon="el-icon-upload" />
      </el-steps>

      <!--上传营业执照-->
      <BusinessLicenseInfo v-if="active === 0" ref="businessLicenseInfo" :e-id="enterId" @change="getEnterId" />
      <!--法人信息登记-->
      <LegalPersonInfo v-if="active === 1" ref="legalPersonInfo" :e-id="enterId" />
      <!--企业认证-->
      <EnterpriseCert v-if="active === 2" ref="enterpriseCert" :e-id="enterId" />

    </div>

    <div class="tc mt30">
      <el-button v-if="active == 1|| ( active ==2 && controlBtnFlag == 1)" type="primary" @click="handleStepPrev">上一步</el-button>
      <el-button v-if="active === 0" type="primary" @click="handleSaveCompanyInfo">下一步</el-button>
      <el-button v-if="active === 1" type="primary" @click="handleSaveLegal">下一步</el-button>
      <el-button v-if="active === 2 && controlBtnFlag == -1" type="primary" @click="handleSaveAuth">开始企业认证</el-button>
      <el-button v-if="active === 2 && controlBtnFlag == 0" type="primary" @click="handleAddAuthorizer">添加企业授权人</el-button>
      <el-button v-if="active === 2 && controlBtnFlag == 0" type="primary" @click="handleStartCerti">我是法人，开始认证！</el-button>
      <el-button v-if="active === 2 && controlBtnFlag == 1 " type="primary" @click="handleSaveLegal">下一步</el-button>
      <el-button v-if="active === 3 && singFlag == 0" type="primary" :disabled="btSign" @click="handleStartSign">开始签署</el-button>
      <el-button v-if="active === 3 && lastSign == 0 " type="primary" @click="toSignLastDoc">签署协议</el-button>
    </div>
  </div>
</template>

<script>
import { doGet } from '@/router/axios';
import BusinessLicenseInfo from './componens/businessLicenseInfo';
import LegalPersonInfo from './componens/legalPersonInfo';
import EnterpriseCert from './componens/enterpriseCert';
import SignDocument from './componens/signDocument';

export default {
  name: 'AuthStep',
  components: {
    BusinessLicenseInfo,
    LegalPersonInfo,
    EnterpriseCert,
    SignDocument
  },
  props: {},
  data() {
    return {
      btSign: true,
      enterAuthDto: {
        status: '0',
        msg: ''
      },
      enterId: '',
      loading: false,
      leagalDateFoverever: false, // 证件有效期是否长期有效
      active: 0,
      authDto: {},
      signetDto: [],
      flag: '0', // 0: 展示协议 1: 展示审核阶段 2: 通过审核
      controlBtnFlag: 0,
      singFlag: 0,
      lastSign: -1, // 最后一步还需签署资金监管服务协议和保理合同
    };
  },
  watch: {},
  created() {
    const {enterId, active } = this.$route.query
    this.enterId = enterId || ''
    this.active = active ? Number(active) : 0
    this.initStatus(this.enterId);
  },
  mounted() {},
  methods: {
    initStatus() {
      doGet('/cust/enter/authDeatil/' + this.enterId).then(({ data }) => {
        this.loading = false;
        if (data.code == 200) {
          this.active = data.data.active;
          this.controlBtnFlag = data.data.isAuth;
        }
      }).catch(e => {
        this.loading = false;
      });
    },

    /**
     * 保存营业执照信息
     */
    handleSaveCompanyInfo() {
      // this.$refs.businessLicenseInfo.saveCompanyInfo();
      this.handleStepNext()
    },
    /**
     * 保存法人信息
     */
    handleSaveLegal() {
      // this.$refs.legalPersonInfo.saveCompanyInfo();
      this.handleStepNext()
    },

    handleSaveAuth() {
      this.$refs.enterpriseCert.saveCompanyInfo();
    },
    /**
     * 签署授权
     */
    handleSignLetter() {
      this.handleStepNext();
    },
    /** 签署授权书 */
    handleStartSign() {
      this.$refs.signDocument.signEnter('loan');
    },
    /** 签署协议和合同 */
    toSignLastDoc() {
      this.$refs.signDocument.signEnter('supervise');
    },
    enterList() {
      this.$router.push({ path: '/mineShop/companylist' });
    },
    /**
     * 保存店铺信息
     */
    handleSaveShopInfo() {
      this.$refs.shopInfoRef.saveShopInfo();
    },
    getEnterId(val) {
      this.enterId = val;
    },
    handleStepNext() {
      this.active += 1;
    },
    handleStepPrev() {
      this.active -= 1;
    },
    goEnter() {
      this.$router.push({ path: '/mineShop/companylist' });
    },
    /**
     * 添加企业授权人
     */
    handleAddAuthorizer() {
      this.$refs.enterpriseCert.addAuth();
    },
    /**
     * 我是法人，开始认证！
     */
    handleStartCerti() {
      this.$refs.enterpriseCert.authEnter();
    }
  }
};
</script>
<style lang="scss" scoped>
.auth-step-container {
  margin-bottom: 7px;
}
.custom-disabled-button {
  /* 在这里添加您希望应用于禁用按钮的样式 */
  opacity: 0.5; /* 示例：降低按钮透明度以表示禁用状态 */
  cursor: not-allowed; /* 示例：更改鼠标样式为禁用样式 */
}
.selection-box {
  border: 1px solid #F2F4F7;
  /deep/ .el-alert__title {
    color: #333;
    font-weight: 700;
  }
  .cForm {
    margin: 0 30px;
  }
}
/deep/ .el-divider--horizontal {
  width: auto;
}
/deep/ .el-form--label-left .el-form-item__label {
  padding-left: 15px;
}
</style>
