<template>
  <div class="activate-account pl20 pr20">
    <el-form
      ref="cFormRef"
      :model="dataForm.model"
      :rules="dataForm.rules"
      :label-position="'right'"
      label-width="120px"
      size="small">
      <el-alert title="您的监管账户已就绪，正在等待账户激活" :closable="false" type="success" />
      <div class="mt10 inner-box">
        <el-form-item label="打款验证金额" prop="money">
          <el-input v-model="dataForm.model.money" placeholder="请输入打款验证金额" type="number">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-alert
          title="为了您的账户激活，已对您的验证银行卡进行了一笔小额打款，请检查您的验证账户流水，填写最近的一次金额完成账户激活"
          :closable="false"
          type="info"
          show-icon />
        <el-divider />
        <el-descriptions title="收款账户信息" class="mt20">
          <el-descriptions-item label="账户名称">{{ bankDto.destAcctName }}</el-descriptions-item>
          <el-descriptions-item label="账户号">{{ bankDto.destAcctNo }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions title="付款账户信息" class="mt20">
          <el-descriptions-item label="账户名称">{{ bankDto.acctName }}</el-descriptions-item>
          <el-descriptions-item label="账户号">{{ bankDto.acctNo }}</el-descriptions-item>
        </el-descriptions>
      </div>
      <div class="tc">
        <el-button type="primary" size="medium" :loading="isSubmiting" @click="saveCard()">我已收到打款，去验证</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { doGet, doPost } from '@/router/axios'

export default {
  name: 'ActivateAccount',
  components: {},
  props: {
    /** 账户信息，必须包含: 账户类型，ping++/蓝海, enterId */
    accInfos: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      bankDto: {},
      isSubmiting: false,
      dataForm: {
        model: {
          money: '',
        },
        rules: {
          money: [{ required: true, trigger: 'change', message: '请输入打款验证金额' }],
        },
      },
    }
  },
  created() {
    this.bankDto = this.accInfos.bankDto || {}
  },
  mounted() {},
  methods: {
    saveCard() {
      if (!this.isSubmiting) {
        this.$refs['cFormRef'].validate((valid) => {
          if (valid) {
            const sendData = {
              serialNum: this.accInfos.serialNum,
              param: this.dataForm.model.money,
            }
            // /upyzt/cusapp/validateAcc //原接口
            this.isSubmiting = true
            doPost('/fundManage/flow/wakeUp', sendData)
              .then(({ data }) => {
                if (data.code == 200) {
                  this.$notify.success('打款验证成功，稍后会有运营人员联系您，请保持联系方式畅通')
                  this.$parent.handleClose()
                }
              })
              .catch((e) => {
                this.isSubmiting = false
              })
          }
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.activate-account {
  /deep/.el-descriptions-item__container {
    padding-left: 5px;
  }
  /deep/.el-descriptions--mini,
  /deep/.el-descriptions--small {
    font-size: 14px;
  }
  /deep/.el-descriptions-item__label {
    font-size: 12px;
    color: #999;
  }
  /deep/.el-descriptions-row {
    td:nth-child(2) {
      width: 230px;
    }
  }
}
</style>
