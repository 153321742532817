<template>
  <div class="bk_white">
    <el-form ref="voucherInfoForm" :model="form" :rules="rules" :label-width="formLabelWidth" status-icon inline-message class="p40">
      <div class="title_h3 mb20">货代企业信息</div>
      <el-form-item label="企业名称">
        <el-input v-model="userInfo.enterpriseName" autocomplete="off" disabled style="width: 50%" />
      </el-form-item>
      <el-form-item label="营业执照号">
        <el-input v-model="userInfo.businessLicense" autocomplete="off" disabled style="width: 50%" />
      </el-form-item>

      <div class="title_h3 mb20">货主企业信息</div>
      <el-form-item label="企业名称" prop="cargoEnterpriseName">
        <el-input v-model="form.cargoEnterpriseName" autocomplete="off" style="width: 50%" />
      </el-form-item>
      <el-form-item label="企业统一信用编号" prop="cargoBusinessId">
        <el-input v-model="form.cargoBusinessId" autocomplete="off" style="width: 50%" />
      </el-form-item>

      <div class="title_h3 mb20">交易信息</div>
      <el-form-item label="应收账款编号" prop="tradeNo">
        <el-input v-model="form.tradeNo" autocomplete="off" style="width: 50%" />
      </el-form-item>
      <el-form-item label="发票金额" prop="invoiceAmt">
        <el-input v-model="form.invoiceAmt" autocomplete="off" style="width: 50%" type="number" />
        <span class="ml10 fc_666">元</span>
      </el-form-item>
      <el-form-item label="合同名称" prop="contractName">
        <el-input v-model="form.contractName" autocomplete="off" style="width: 50%" />
      </el-form-item>
      <el-form-item label="合同编号" prop="contractNo">
        <el-input v-model="form.contractNo" autocomplete="off" style="width: 50%" />
      </el-form-item>
      <el-form-item label="发票编号" prop="invoiceNo">
        <el-input v-model="form.invoiceNo" autocomplete="off" style="width: 50%" />
      </el-form-item>
      <el-form-item label="应收账款" prop="tradeTotalAmt">
        <el-input v-model="form.tradeTotalAmt" autocomplete="off" type="number" style="width: 50%" />
        <span class="ml10 fc_666">元</span>
      </el-form-item>
      <el-form-item label="交易时间" prop="startDate">
        <el-date-picker
          v-model="form.startDate"
          value-format="yyyy-MM-dd"
          type="date"
          placeholder="选择日期"
          style="width: 50%"
        />
      </el-form-item>
      <el-form-item label="结算周期" prop="dealine">
        <el-input v-model="form.dealine" autocomplete="off" type="number" style="width: 50%" />
        <span class="ml10 fc_666">天</span>
      </el-form-item>
      <el-form-item label="结算时间" prop="endDate">
        <el-input v-model="calcEndDate" autocomplete="off" style="width: 50%" readonly />
        <span class="ml10 fc_666" />
      </el-form-item>
      <div class="title_h3 mb20">其它</div>
      <el-form-item label="凭证附件" prop="fileIds">
        <dict-upload v-model="form.fileIds" :limit="10" type-code="F0104" biz-id="123456" type="text" @change="onFileChange($event, 'fileIds')" />

      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="form.remark" type="textarea" :autosize="{ minRows: 5}" style="width: 95%" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer tc">
      <el-button size="medium" class="mr20" @click="handleReset('voucherInfoForm')">重 置</el-button>
      <el-button size="medium" type="primary" :loading="btnLoading" @click="handleSave('voucherInfoForm')">{{ btnSave }}</el-button>
    </div>
  </div>
</template>

<script>
import DictUpload from '@/views/components/DictUpload.vue'
import { doGet, doPost } from '@/router/axios'
import { getNewData } from '@/utils/date'

export default {
  name: 'VoucherInfo',
  components: { DictUpload },
  data() {
    return {
      userInfo: {
        custName: '',
        custId: '',
        loginName: '',
        businessLicense: '',
        phone: '',
        isAuth: 0,
        enterpriseId: '',
        enterpriseName: '',
        lastLoginTime: ''
      },
      formLabelWidth: '120px',
      form: {
        fileIds: []
      },
      btnLoading: false,
      btnSave: '确 定',
      rules: {
        cargoEnterpriseName: [
          { required: true, message: '请输入企业名称', trigger: 'blur' }
        ],
        invoiceNo: [
          { required: true, message: '请输入发票编号', trigger: 'blur' }
        ],
        invoiceAmt: [
          { required: true, message: '请输入发票金额', trigger: 'blur' }
        ],
        contractName: [
          { required: true, message: '请输入合同名称', trigger: 'blur' }
        ],
        contractNo: [
          { required: true, message: '请输入合同编号', trigger: 'blur' }
        ],
        cargoBusinessId: [
          { required: true, message: '请输入企业统一信用编号', trigger: 'blur' }
        ],

        tradeNo: [
          { required: true, message: '请输入应收账款编号', trigger: 'blur' }
        ],
        tradeTotalAmt: [
          { required: true, message: '请输入应收账款', trigger: 'blur' }
        ],
        startDate: [
          { required: true, message: '请输入交易时间', trigger: 'blur' }
        ],
        dealine: [
          { required: true, message: '请输入结算周期', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
  	calcEndDate() {
  		if (this.form.startDate === '' || this.form.startDate === undefined) {
  			return ''
      } else {
  			return getNewData(this.form.startDate, this.form.dealine)
      }
    }
  },
  created() {
    this.initEnterpise()
  },
  methods: {
    initEnterpise() {
      const that = this
      // doGet('/user/info').then(res => {
      //   if (res.data.code == 200) {
      //     that.userInfo = res.data.data
      //   }
      // })
    },
    handleReset(formName) {
      // this.$emit('handleDialogClosed', param)
      this.$refs[formName].resetFields()
    },
    handleSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.btnLoading = true
          this.btnSave = '提交...'
          doPost('/voucher/apply', this.form).then(res => {
            if (res.data.code === 200) {
              this.$notify.success('添加成功')
              this.btnLoading = false
              this.btnSave = '确 定'
              this.$router.push({ path: '/voucher/index' })
            } else {
              this.$notify.error(res.data.msg)
              this.btnSave = '确 定'
              this.btnLoading = false
            }
          }).catch(() => {
            this.btnLoading = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })

      // this.$emit('handleDialogClosed')
      // this.listLoading = true
      // doPost('/voucher/apply', this.form).then(res => {
      //   if (res.data.code === 200) {
      //     this.$notify.success('创建成功')
      //     return false
      //   } else {
      //     this.listLoading = false
      //     this.$notify.error(res.data.msg)
      //   }
      // }).catch(() => {
      //
      // })
    },
    onFileChange(ids = [], key) {
      this.$refs.voucherInfoForm.validateField(key)
    }
  }
}
</script>

<style lang="scss" scoped>
.title_h3 {
  height: 35px;
  font: 600 16px/35px "Microsoft YaHei";
  color: #333;
  border-bottom: 1px solid #dedede;
}
.dialog-footer {
  margin: 20px auto;
}
.dict-upload.text .el-upload {
  width: auto;
}
</style>
