var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "activate-account pl20 pr20" },
    [
      _c(
        "el-form",
        {
          ref: "cFormRef",
          attrs: {
            model: _vm.dataForm.model,
            rules: _vm.dataForm.rules,
            "label-position": "right",
            "label-width": "120px",
            size: "small",
          },
        },
        [
          _c("el-alert", {
            attrs: {
              title: "您的监管账户已就绪，正在等待账户激活",
              closable: false,
              type: "success",
            },
          }),
          _c(
            "div",
            { staticClass: "mt10 inner-box" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "打款验证金额", prop: "money" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: {
                        placeholder: "请输入打款验证金额",
                        type: "number",
                      },
                      model: {
                        value: _vm.dataForm.model.money,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataForm.model, "money", $$v)
                        },
                        expression: "dataForm.model.money",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("元")])],
                    2
                  ),
                ],
                1
              ),
              _c("el-alert", {
                attrs: {
                  title:
                    "为了您的账户激活，已对您的验证银行卡进行了一笔小额打款，请检查您的验证账户流水，填写最近的一次金额完成账户激活",
                  closable: false,
                  type: "info",
                  "show-icon": "",
                },
              }),
              _c("el-divider"),
              _c(
                "el-descriptions",
                { staticClass: "mt20", attrs: { title: "收款账户信息" } },
                [
                  _c("el-descriptions-item", { attrs: { label: "账户名称" } }, [
                    _vm._v(_vm._s(_vm.bankDto.destAcctName)),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "账户号" } }, [
                    _vm._v(_vm._s(_vm.bankDto.destAcctNo)),
                  ]),
                ],
                1
              ),
              _c(
                "el-descriptions",
                { staticClass: "mt20", attrs: { title: "付款账户信息" } },
                [
                  _c("el-descriptions-item", { attrs: { label: "账户名称" } }, [
                    _vm._v(_vm._s(_vm.bankDto.acctName)),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "账户号" } }, [
                    _vm._v(_vm._s(_vm.bankDto.acctNo)),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "tc" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "medium",
                    loading: _vm.isSubmiting,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.saveCard()
                    },
                  },
                },
                [_vm._v("我已收到打款，去验证")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }