<template>
    <el-dialog
      class="dialog-standard channel-config-rate"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :fullscreen="isToFullScreen"
      width="1000px"
      top="100px"
      visible
    >
      <section class="dialog-header" slot="title">
        <span class="dialog-title"
          >{{ modalType === infoStatus[0] ? "配置" : "查看" }}费率</span
        >
        <span class="right-icon">
          <i
            :class="[isToFullScreen ? 'el-icon-news' : 'el-icon-full-screen']"
            @click="setFullScreen"
          ></i>
          <i class="el-icon-close" @click="handleCancel"></i>
        </span>
      </section>
      <section class="dialog-body">
        <ul class="body-view-infos">
          <li class="word-break">
            <label>企业名称：</label>善道供应链管理公司
          </li>
        
          <li class="word-break">
            <label>企业认证时间：</label>2021-07-21 16:00
          </li>
          <li class="word-break">
            <el-button class="filter-item" type="primary">
                上传征信报告
          </el-button>
          </li>
        </ul>
        <div class="table-list">
          <p class="tips">
            默认建议对客费率，请自行调整
          </p>
          <avue-crud
            ref="crud"
            :table-loading="isLoading"
            :option="option"
            :data="dataList"
          >
            <template #fundRate="{ row }">
              <el-input-number
                v-model="row.fundRate"
                :precision="2"
                :step="0.1"
                :min="row.minFundRate"
                v-if="modalType === infoStatus[0]"
              ></el-input-number>
              <span v-else>{{ row.fundRate }}</span>
            </template>
          </avue-crud>
        </div>
      </section>
      <section class="dialog-footer">
        <template v-if="modalType === infoStatus[0]">
          <el-button
            type="primary"
            v-loading="isSubmiting"
            @click="handleConfirm"
            >保存对客费率</el-button
          >
          <el-button
            class="cancel"
            icon="el-icon-circle-close"
            @click="handleCancel"
            >取消</el-button
          >
        </template>
      </section>
      <el-dialog
      width="30%"
      :visible.sync="outerVisible"
      append-to-body center>
      <div>
        <div class="dialogTitle">提示！</div>
        <div class="dialogContent">
            对客费率提交保存后不可修改，请仔细确认!!!
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
      <el-button  type="primary" @click="saveRateData">确认并提交</el-button>
      <el-button @click="outerVisible = false">再检查一下</el-button>
    </div>
    </el-dialog>
    </el-dialog>

  </template>
  
  <script>
  import { doGet, doPost } from "@/router/axios";
  import { dealNull } from "@/filters/index";
  
  export default {
    name: "CompanyRate",
    props: {
      modalType: {
        type: String,
        default: "",
      },
      modalData: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    data() {
      return {
        outerVisible:false,
        isToFullScreen: false,
        isLoading: true,
        isSubmiting: false, //防重复提交
        infoStatus: ["edit", "view"], //状态，配置/查看
        dataList: [],
        option: {
          addBtn: false,
          refreshBtn: false,
          columnBtn: false,
          header: false,
          menu: false,
          border: false,
          stripe: true,
          align: "center",
         searchShowBtn:false,
 column: [
            {
              label: "对客利率(单笔%）",
              prop: "fundRate",
              width: 300,
            },
            {
              label: "平均回款天数",
              prop: "avePayDays",
              width: 300,
            },
            {
              label: "我的费率",
              prop: "channelRatePer",
            },
          ],
        },
      };
    },
    watch: {},
    created() {
      console.log(this.modalType, this.modalData);
      this.isLoading = true;
      // 判断配置还是查看
      if (this.modalType === this.infoStatus[0]) {
        // 配置
        this.getInitChannelRates();
      } else {
        // 查看
        this.getViewChannelRates();
      }
    },
    mounted() {},
    methods: {
      /** 获取初始模版渠道数据 */
      getInitChannelRates() {
        const api = "/cust/enterpriseShop/getChannelFundRate"; //一级渠道模版ID：1
        doPost(api, { channelId: "1" }).then(({ data }) => {
          if (data) {
            const { data: listData } = data;
            this.dataList = this.dealResData(listData);
          }
          this.isLoading = false;
        });
      },
      /** 查看已配置的费率 */
      getViewChannelRates() {
        const api = "/cust/enterpriseShop/getCustFundRate";
        const { custId } = this.modalData;
        doPost(api, { enterId: custId }).then(({ data }) => {
          if (data) {
             const { data: listData } = data;
            this.dataList = this.dealResData(listData);
          }
          this.isLoading = false;
        });
      },
      /** 预处理接口返回数据 */
      dealResData(resData) {
        if (resData && resData.length) {
          return resData.map((item, idx) => {
            const { turnoverStartDay, turnoverEndDay, channelRate } = item;
            return {
              ...item,
              avePayDays: `${dealNull(turnoverStartDay)}~${dealNull(
                turnoverEndDay
              )}天`,
              channelRatePer: dealNull(channelRate) + "%",
              minFundRate: Number(dealNull(channelRate, 0)),
            };
          });
        }
        return [];
      },
      /** 保存提交数据 */
      saveRateData(rateList) {
        this.outerVisible = false
        this.$options.confirm && this.$options.confirm();
        // this.isSubmiting = true;
        // this.outerVisible = false
        // const { custId } = this.modalData;
        // const api = "/cust/enterpriseShop/addCustFundRate";
        // const sendDate = {
        //   enterId: custId,
        //   shopFundRateList: rateList,
        // };
        // doPost(api, sendDate)
        //   .then(({ data }) => {
        //     if (data) {
        //       this.$notify.success("配置成功。");
        //       this.$options.confirm && this.$options.confirm();
        //     } else {
        //       this.isSubmiting = false;
        //     }
        //   })
        //   .catch(() => {
        //     this.isSubmiting = false;
        //   });
      },
  
      /** 确认 */
      handleConfirm() {
        if (!this.isSubmiting) {
          const { dataList } = this;
          // 先校验是否全部填写完
          const noValue = dataList.find((item) => !item.fundRate);
          if (noValue) {
            this.$notify.error("还有未填写完的信息，请检查后再提交。");
            return;
          }
          // 再校验“对客利率下一档价格大于等于上一档的”规则
          const maxIdx = dataList.length - 1; //最大下标索引
          const compareValid = dataList.every((item, index, arr) => {
            if (index < maxIdx) {
              const nextFundRate = arr[index + 1].fundRate; //下一档的对客利率
              return nextFundRate >= item.fundRate;
            }
            return true;
          });
          // 校验全通过
          if (compareValid) {
            // this.saveRateData(dataList);
            this.outerVisible = true
          } else {
            this.$notify.error(
              "请检查数据是否满足“对客利率上一档价格小于等于下一档”的规则。"
            );
          }
        }
      },
      /** 关闭 */
      handleCancel() {
        this.$options.cancel && this.$options.cancel();
      },
      /** 全屏设置 */
      setFullScreen() {
        this.isToFullScreen = !this.isToFullScreen;
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .channel-config-rate {
    .body-view-infos {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 10px;
      font-size: 16px;
      label {
        color: #999;
      }
    }
    .table-list {
      .tips {
      color: #D9001B;
      padding: 10px 65px ;
      font-size: 12px;
      }
      /deep/.el-input-number .el-input input {
        text-align: center;
      }
    }
  }
  .dialogTitle{
    color: #D9001B;
    font-size: 16px;
    text-align: center;
}
.dialogContent{
    text-align: center;
    padding-top: 20px;
}
  </style>
  