<template>
  <div lang="ZH-CN" style="text-justify-trim:punctuation">

    <div class="WordSection1" style="layout-grid:15.6pt">

      <p class="MsoNormal" align="center" style="text-align:center;background:white"><b><span
        style="font-size:18.0pt;font-family:宋体;color:black"
      >必米供应链金融服务平台</span></b></p>

      <p class="MsoNormal" align="center" style="text-align:center;background:white"><b><span
        style="font-size:18.0pt;font-family:宋体;color:black"
      >隐私政策</span></b></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >必米供应链金融服务平台（以下统称</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >“</span><span style="font-size:9.5pt;font-family:宋体;color:black">本平台</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >”</span><span style="font-size:9.5pt;font-family:宋体;color:black">）非常重视保护您的隐私。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >为方便您登录、使用相关服务，以及为您提供更个性化的用户体验和服务，您在使用我们的服务时，我们可能会收集和使用您的相关信息。我们希望通过本隐私政策向您说明，在使用</span><span
        style="font-size:9.5pt;font-family:宋体;color:red"
      >本平台</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >电脑端、移动端及后续更新版本或</span><span
        style="font-size:9.5pt;font-family:宋体"
      >本平台服务<span style="color:black">时，我们如何收集、使用、储存和披露您的信息，以及我们为您提供的访问、更新、控制和保护这些信息的方式。本隐私声明与您所使用</span>本<span
        style="color:black"
      >平台服务信息相关，希望您仔细阅读。</span></span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >您使用我们的服务，即意味着您已经同意我们按照本隐私声明收集、使用、储存和披露您的相关信息，以及向您提供的控制和保护措施。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><b><span
        style="font-size:12.0pt;font-family:宋体;color:black"
      >一、声明适用范围</span></b></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >1.1</span><span style="font-size:9.5pt;font-family:宋体;color:black">本隐私权政策适用于本平台所有服务。服务包括向您提供页面浏览、网站登录服务，以及通过本平台向您提供的金融服务。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >1.2</span><span style="font-size:9.5pt;font-family:宋体;color:black">需要特别说明的是，作为本平台的用户，若您利用本平台的金融服务，为您的用户再行提供服务，因您的业务数据属于您所有，您应当另行与您的用户约定隐私权政策。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >1.3</span><span style="font-size:9.5pt;font-family:宋体;color:black">本隐私声明中的</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >“</span><span style="font-size:9.5pt;font-family:宋体;color:black">信息</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >”</span><span style="font-size:9.5pt;font-family:宋体;color:black">特指第二条所收集的信息，本隐私政策不适用于您存储在本平台系统中的数据。您可通过《必米供应链金融服务平台用户服务协议》知悉更多关于您存储在本平台系统中数据的相关政策。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><b><span
        style="font-size:12.0pt;font-family:宋体;color:black"
      >二、信息收集范围</span></b></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >2.1 </span><span style="font-size:9.5pt;font-family:宋体;color:black">为完成本平台账户的注册、管理、实名认证等必要活动，您需要提交真实、合法、有效的信息，包括但不限于您的姓名、身份证号码、联系电话、、银行账户信息、工商登记信息等。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >2.2 </span><span style="font-size:9.5pt;font-family:宋体;color:black">您使用本服务时，可能收集已经经过您授权获取或主动填写的如下信息：</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >2.2.1</span><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >日志信息，指您使用我们的服务时，系统可能通过自动采集的技术信息，包括：</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&#8226; </span><span style="font-size:9.5pt;font-family:宋体;color:black">设备或软件信息，例如您的移动设备、网页浏览器或用于接入我们服务的其他程序所提供的配置信息、您的</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >IP</span><span style="font-size:9.5pt;font-family:宋体;color:black">地址和移动设备所用的版本和设备识别码。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&#8226; </span><span style="font-size:9.5pt;font-family:宋体;color:black">有关您曾使用的移动应用和其他软件的信息，以及您曾经使用该等移动应用和软件的信息。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&#8226; </span><span style="font-size:9.5pt;font-family:宋体;color:black">您通过我们的服务进行通讯的信息，例如曾通讯的账户。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&#8226; </span><span style="font-size:9.5pt;font-family:宋体;color:black">您通过我们的服务分享的内容所包含的信息（元数据），例如拍摄或上传的等。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >2.2.2</span><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >位置信息，指您开启设备定位功能并使用我们基于位置提供的相关服务时，收集的有关您位置的信息，包括：</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&#8226; </span><span style="font-size:9.5pt;font-family:宋体;color:black">您通过具有定位功能的移动设备使用我们的服务时，通过</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >GPS</span><span style="font-size:9.5pt;font-family:宋体;color:black">或</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >WiFi</span><span style="font-size:9.5pt;font-family:宋体;color:black">等方式收集的您的地理位置信息。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&#8226; </span><span style="font-size:9.5pt;font-family:宋体;color:black">您或其他用户提供的包含您所处地理位置的实时信息，例如您提供的账户信息中包含的您所在地区信息，您上传的显示您当前或曾经所处地理位置的共享信息</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&#8226; </span><span style="font-size:9.5pt;font-family:宋体;color:black">您可以通过关闭定位功能，停止对您的地理位置信息的收集。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >2.3</span><span style="font-size:9.5pt;font-family:宋体;color:black">如果您需要在线上申请线下金融业务，为履行所必需，您应提供联系人、联系方式、地址等必要信息。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><b><span
        style="font-size:12.0pt;font-family:宋体;color:black"
      >三、如何收集信息</span></b></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >3.1 </span><span style="font-size:9.5pt;font-family:宋体;color:black">我们通过您主动提交，以及在您使用我们的服务过程中产生相应的记录等方式收集您的信息。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >3.2 </span><span style="font-size:9.5pt;font-family:宋体;color:black">我们可能会通过</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      > Cookies </span><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >和其他相关技术收集和使用您的信息。我们使用</span><span
        lang="EN-US"
        style="font-size:
9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;color:black"
      > Cookies </span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >的具体用途包括：</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&#8226; </span><span style="font-size:9.5pt;font-family:宋体;color:black">记住您的身份。例如：</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >Cookies </span><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >有助于我们辨认您作为我们的注册用户的身份。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&#8226; </span><span style="font-size:9.5pt;font-family:宋体;color:black">分析您使用我们服务的情况，以便为您提供更加周到的个性化服务，包括定制化页面、推荐等服务。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >3.3 </span><span style="font-size:9.5pt;font-family:宋体;color:black">您可以通过浏览器设置拒绝或管理</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      > Cookies</span><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >。但请注意，如果停用</span><span
        lang="EN-US"
        style="font-size:9.5pt;
font-family:&quot;PingFangSC-Regular&quot;,serif;color:black"
      > Cookies</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >，您有可能无法享受最佳的服务体验，某些功能的可用性可能会受到影响。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >3.4 </span><span style="font-size:9.5pt;font-family:宋体;color:black">通过本平台</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      > Cookies </span><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >记录的有关信息，将适用本隐私政策。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><b><span
        style="font-size:12.0pt;font-family:宋体;color:black"
      >四、信息使用</span></b></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >为了向您提供更加优质、便捷、安全的服务，在符合相关法律法规的前提下，我们可能将收集的信息用作以下用途：</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&#8226; </span><span style="font-size:9.5pt;font-family:宋体;color:black">向您提供服务（如实名认证信息、用来接受验证码的手机号，是继续获得服务的前提）；</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&#8226; </span><span style="font-size:9.5pt;font-family:宋体;color:black">满足您的个性化需求。例如，语言设定、位置设定、个性化的帮助服务和指示，或对您和其他用户作出其他方面的回应。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&#8226; </span><span style="font-size:9.5pt;font-family:宋体;color:black">服务优化和开发。例如，我们会根据本平台系统响应您的需求时产生的信息，优化我们的服务。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&#8226; </span><span style="font-size:9.5pt;font-family:宋体;color:black">保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或本平台相关协议、规则的情况，我们可能使用您的账户信息、并整合设备信息、有关网络日志以及我们关联公司、合作伙伴分享的信息，来判断您账户风险、进行身份验证、安全事件的检测及防范，并依法采取必要的记录、审计、分析、处置措施。例如，我们会将您的信息用于身份验证、安全防范、投诉处理、纠纷协调、诈骗监测等用途。您在使用安全功能或其他类似服务时，我们会对恶意程序或病毒进行检测，或为您识别诈骗信息。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&#8226; </span><span style="font-size:9.5pt;font-family:宋体;color:black">向您提供与您更加相关的服务。例如，向您提供您可能感兴趣的类似功能或服务等。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&#8226; </span><span style="font-size:9.5pt;font-family:宋体;color:black">邀请您参与有关我们产品和服务的调查。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&#8226; </span><span style="font-size:9.5pt;font-family:宋体;color:black">其他可能需要使用收集的信息的相关场景，如果使用场景与初始场景无合理的关联性，我们会在使用信息前重新征得您的同意。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><b><span
        style="font-size:12.0pt;font-family:宋体;color:black"
      >五、信息共享、转让、披露</span></b></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><b><span
        lang="EN-US"
        style="font-size:12.0pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >5.1 </span></b><b><span
        style="font-size:12.0pt;font-family:宋体;
color:black"
      >共享</span></b></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >我们不会与其他组织和个人共享您的用户信息，但以下情况除外：</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >5.1.1 </span><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的用户信息；</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >5.1.2 </span><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >在法定情形下的共享：我们可能会根据法律法规规定、诉讼、仲裁解决需要，或按行政、司法机关依法提出的要求，对外共享您的用户信息；</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >5.1.3 </span><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >为了促成办理服务或协助解决争议，某些情况下只有共享您的用户信息，才能促成办理或处理您与他人的纠纷或争议。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >5.1.4 </span><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >与授权合作伙伴共享：我们可能委托受信赖的合作伙伴来提供服务，因此我们可能会与合作伙伴共享您的某些用户信息，以提供更好的客户服务和优化用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的用户信息，并且只会共享提供服务所必要的用户信息。我们的合作伙伴无权将共享的用户信息用于任何其他用途。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >目前，我们的授权合作伙伴包括如下类型：融资机构、担保机构和其他合作伙伴。我们将信息发送给支持我们业务的融资机构、担保机构和其他合作伙伴，这些支持包括提供金融服务、咨询、分析等专业服务。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >对我们与之共享用户信息的公司、组织和个人，我们会与其签署严格的保密协议以及信息保护约定，要求他们按照我们的说明、本隐私权政策以及其他任何相关的保密和安全措施来处理用户信息。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><b><span
        lang="EN-US"
        style="font-size:12.0pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >5.2 </span></b><b><span
        style="font-size:12.0pt;font-family:宋体;
color:black"
      >转让</span></b></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >我们不会将您的用户信息转让给任何公司、组织和个人，但以下情况除外：</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >5.2.1</span><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的用户信息。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><b><span
        lang="EN-US"
        style="font-size:12.0pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >5.3 </span></b><b><span
        style="font-size:12.0pt;font-family:宋体;
color:black"
      >披露</span></b></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >未经您同意，我们不会与任何无关第三方分享您的信息，以下情形除外：</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >5.3.1. </span><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >我们可能将您的信息与我们的关联公司、第三方服务提供商、承包商及代理分享，仅用作下列用途：</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&#8226; </span><span style="font-size:9.5pt;font-family:宋体;color:black">提供</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >“</span><span style="font-size:9.5pt;font-family:宋体;color:black">二、信息收集范围</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >”</span><span style="font-size:9.5pt;font-family:宋体;color:black">中的相应功能或服务所必需，以及出于</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >“</span><span style="font-size:9.5pt;font-family:宋体;color:black">四、信息使用</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >”</span><span style="font-size:9.5pt;font-family:宋体;color:black">中的部分所述目的所必需。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&#8226; </span><span style="font-size:9.5pt;font-family:宋体;color:black">履行我们在《必米供应链金融服务平台用户服务协议》或本隐私政策中的义务和行使我们的权利。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >如我们与任何上述第三方分享您的信息，我们将努力确保第三方在使用您的信息时遵守本声明及我们要求其遵守的其他适当的保密和安全措施。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >5.3.2. </span><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >随着我们业务的持续发展，我们以及我们的关联公司有可能进行合并、收购、资产转让或类似的交易，您的信息有可能作为此类交易的一部分而被转移。我们将遵守相关法律法规的要求，在转移前通知您，确保信息在转移时的机密性，以及变更后继续履行相应责任和义务。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >5.3.3. </span><span
        style="font-size:9.5pt;font-family:宋体;
color:black"
      >我们还可能因以下原因而披露您的信息：</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&#8226; </span><span style="font-size:9.5pt;font-family:宋体;color:black">遵守适用的法律法规等有关规定。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&#8226; </span><span style="font-size:9.5pt;font-family:宋体;color:black">遵守法院判决、裁定或其他法律程序的规定。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&#8226; </span><span style="font-size:9.5pt;font-family:宋体;color:black">遵守相关政府机关或其他有权机关的要求。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&#8226; </span><span style="font-size:9.5pt;font-family:宋体;color:black">我们有理由确信需遵守法律法规等有关规定。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&#8226; </span><span style="font-size:9.5pt;font-family:宋体;color:black">为执行相关服务协议或本隐私声明、维护社会公共利益、处理投诉</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >/</span><span style="font-size:9.5pt;font-family:宋体;color:black">纠纷，保护我们的客户、我们或我们的关联公司、其他用户或雇员的人身和财产安全或合法权益所合理必需的用途。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&#8226; </span><span style="font-size:9.5pt;font-family:宋体;color:black">经过您合法授权的情形。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >如我们因上述原因而披露您的信息，我们将在遵守法律法规相关规定及本声明的基础上及时告知您。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><b><span
        style="font-size:12.0pt;font-family:宋体;color:black"
      >六、信息存储</span></b></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >6.1 </span><span style="font-size:9.5pt;font-family:宋体;color:black">本平台收集的有关您的信息保存在本平台位于中国大陆的服务器。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >6.2 </span><span style="font-size:9.5pt;font-family:宋体;color:black">通常，我们仅在为您提供服务期间保留您的信息，保留时间不会超过满足相关使用目的所必须的时间。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >但在下列情况下，且仅出于下列情况相关的目的，我们有可能需要较长时间保留您的信息或部分信息：</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&#8226; </span><span style="font-size:9.5pt;font-family:宋体;color:black">遵守适用的法律法规等有关规定。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&#8226; </span><span style="font-size:9.5pt;font-family:宋体;color:black">遵守法院判决、裁定或其他法律程序的要求。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&#8226; </span><span style="font-size:9.5pt;font-family:宋体;color:black">遵守相关政府机关或其他有权机关的要求。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&#8226; </span><span style="font-size:9.5pt;font-family:宋体;color:black">我们有理由确信需遵守法律法规等有关规定。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&#8226; </span><span style="font-size:9.5pt;font-family:宋体;color:black">为执行相关服务协议或本隐私声明、维护社会公共利益、处理投诉</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >/</span><span style="font-size:9.5pt;font-family:宋体;color:black">纠纷，保护我们的客户、我们或我们的关联公司、其他用户或雇员的人身和财产安全或合法权益所合理必需的用途。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><b><span
        style="font-size:12.0pt;font-family:宋体;color:black"
      >七、信息安全</span></b></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >7.1 </span><span style="font-size:9.5pt;font-family:宋体;color:black">如果您拥有本平台其他帐号，在特定场景下，您的本平台帐号的个人信息可能会与您在本平台拥有的其他账户信息相关联。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >7.2 </span><span style="font-size:9.5pt;font-family:宋体;color:black">我们努力保障信息安全，以防信息的丢失、不当使用、未经授权阅览或披露。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&#8226; </span><span style="font-size:9.5pt;font-family:宋体;color:black">我们使用各种安全技术以保障信息的安全。例如，我们将通过服务器多备份、密码加密等安全措施，防止信息泄露、毁损、丢失。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&#8226; </span><span style="font-size:9.5pt;font-family:宋体;color:black">我们建立严格的管理制度和流程以保障信息的安全。例如，我们严格限制访问信息的人员范围，并进行审计，要求他们遵守保密义务。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&#8226; </span><span style="font-size:9.5pt;font-family:宋体;color:black">我们重视信息安全合规工作，并通过众多国际和国内的安全认证，如网络安全等级保护认证等，以业界先进的解决方案充分保障您的信息安全。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网环境下，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >若发生个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大，按照《国家网络安全事件应急预案》等有关规定及时上报，并以发送邮件、推送通知、公告等形式告知您相关情况，并向您给出安全建议。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >7.3 </span><span style="font-size:9.5pt;font-family:宋体;color:black">为更有效的保障您的信息安全，我们也希望您能够加强自我保护意识。我们仅在本平台直接导致您个人信息泄露的范围内承担直接经济责任，因此，请您妥善保管您的账户及密码信息，避免您的个人信息泄露。本平台账户及子账户均有安全保护功能，但您也需要妥善保护自己的个人信息，除非您判断认为必要的情形下，不向任何第三人提供您的账户密码等个人信息。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><b><span
        style="font-size:12.0pt;font-family:宋体;color:black"
      >八、访问与控制</span></b></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >8.1 </span><span style="font-size:9.5pt;font-family:宋体;color:black">您可以在使用我们服务的过程中，访问、修改和删除您的相关信息。您访问、修改和删除信息的方式将取决于您使用的具体服务。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >8.2 </span><span style="font-size:9.5pt;font-family:宋体;color:black">如您发现我们违反法律法规的规定或者双方的约定收集、使用您的信息，您可以要求我们删除。如您发现我们收集、存储的您的信息有错误的，且无法自行更正的，您也可以要求我们更正。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >8.3 </span><span style="font-size:9.5pt;font-family:宋体;color:black">在访问、修改和删除相关信息时，我们可能会要求您进行身份验证，以保障账户安全。请您理解，由于技术所限、基于法律法规要求，您的某些请求可能无法进行响应。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >8.4 </span><span style="font-size:9.5pt;font-family:宋体;color:black">基于法律法规要求、保障信息安全等正当事由，您的部分信息可能无法访问、修改和删除。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >8.5 </span><span style="font-size:9.5pt;font-family:宋体;color:black">您可以自行选择撤回对某些非基本功能或服务对您信息处理的同意，并通过在线方式或通过线下方式进行人工处理的方式注销账户；在您主动注销账户之后，我们将停止为您提供产品或服务，根据适用法律的要求删除您的个人信息，或使其匿名化处理。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >8.6 </span><span style="font-size:9.5pt;font-family:宋体;color:black">如您对上述权利实现存在疑问，可以根据</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >“</span><span style="font-size:9.5pt;font-family:宋体;color:black">十、与我们联系</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >”</span><span style="font-size:9.5pt;font-family:宋体;color:black">中的相关联系方式与我们取得联系。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><b><span
        style="font-size:12.0pt;font-family:宋体;color:black"
      >九、隐私政策的更新</span></b></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >我们可能适时修订本隐私声明的条款，该等修订构成本《隐私政策》的一部分。如可能造成您在本隐私声明下权利的实质减少或扩大收集、使用信息的范围等重要规则变更时，我们将在修订生效前通过在主页上显著位置提示或向您发送电子邮件或以其他方式通知您。在该种情况下，若您继续使用我们的服务，即表示同意受经修订的本隐私声明的约束。</span></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><b><span
        style="font-size:12.0pt;font-family:宋体;color:black"
      >十、与我们联系</span></b></p>

      <p class="MsoNormal" align="left" style="text-align:left;background:white"><span
        style="font-size:9.5pt;font-family:宋体;color:black"
      >如您对本政策或其他相关事宜有疑问，您可以通过电话</span><span
        lang="EN-US"
        style="font-size:9.5pt;font-family:&quot;PingFangSC-Regular&quot;,serif;
color:black"
      >/</span><span style="font-size:9.5pt;font-family:宋体;color:black">邮件方式进行咨询或根据我们提供的指引，填写意见反馈。我们将尽快审核所涉问题，并在验证您的用户身份后予以回复。</span></p>

      <p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>

    </div>

  </div>
</template>

<script>
export default {
  name: 'Yinsizhengce'
};
</script>

<style scoped>

</style>
