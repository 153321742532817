<template>
  <div class="mz-layout-section full-condition">
    <!-- <div class="tableList_title">下级收益</div> -->
    <el-divider />
    <el-row :gutter="30">
      <el-col :span="6">
        <div class="grid-card bg-blue">
          <div class="title">今日下级渠道渠道收益</div>
          <div class="number">45278.02元</div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-card bg-red">
          <div class="title">下级渠道近30日放款</div>
          <div class="number">278.02万元</div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-card bg-orange">
          <div class="title">下级渠道累计放款</div>
          <div class="number">578.02万元</div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-card bg-orange">
          <div class="title">下级渠道本月放款111</div>
          <div class="number">578.02万元</div>
        </div>
      </el-col>
    </el-row>
    <div class="grid-content">
    <avue-crud
      ref="crud"
      :option="option"
      :page.sync="page"
      :table-loading="listLoading"
      :data="list"
      @on-load="getList"
      @size-change="sizeChange"
      @current-change="currentChange"
      @search-change="handleFilter"
      @search-reset="resetFilter">
              <template slot="menuRight">
          <el-button
            class="filter-item"
            type="primary"
            icon="el-icon-download"
            >导出明细</el-button
          >
        </template>
    </avue-crud>
    </div>
  </div>
</template>

<script>
import { doGet, doDelet } from '@/router/axios'
const tableOption =  {
  searchMenuSpan: 8,
  searchLabelWidth: 80,
  searchMenuPosition: 'left',
  border: false,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menuWidth: 230,
  searchIcon: true,
  searchShowBtn:false,
  header: true,
  refreshBtn: false,
  columnBtn: false,
  menu: false,
  editBtn: false,
  delBtn: false,
  align: 'left',
  addBtn: false,
 searchShowBtn:false,
 column: [
    {
      fixed: true,
      label: '渠道名称',
      prop: 'name',
      width: 180,
      editDisplay: true,
      addDisplay: true,
      search: true,
      searchTags: true,
      searchSpan: 8,
      span: 12,
    },
    {
      label: '渠道等级',
      prop: 'code',
      editDisplay: false,
      addDisplay: false,
      searchSpan: 8,
      span: 12,
    },
    {
      label: '企业名称',
      prop: 'investCode',
      editDisplay: false,
      addDisplay: false,
      searchSpan: 8,
      span: 12,
    },
    {
      label: '订单号',
      prop: 'totalAmt',
      editDisplay: true,
      addDisplay: true,
      span: 12,
    },
    {
      label: '订单状态',
      prop: 'rateType',
      type: 'select',
      dicUrl: '/common/type/D000082',
      editDisplay: true,
      addDisplay: true,
      width: 120,
      span: 12,
    },
    {
      label: '放款金额',
      prop: 'turnoverType',
      type: 'select',
      dicUrl: '/common/type/D000136',
      editDisplay: true,
      addDisplay: true,
      width: 120,
      span: 12,
    },
    {
      label: '当前收益金额',
      prop: 'baseRate',
      editDisplay: true,
      addDisplay: true,
      width: 120,
      span: 12,
    },{
      label: '本渠道净收益',
      prop: 'Rate',
      editDisplay: true,
      addDisplay: true,
      width: 120,
      span: 12,
    },{
      label: '放款时间',
      prop: 'giveTime',
      type: "date",
      format: "yyyy-MM-dd",
      searchRange: true,
      valueFormat: "yyyy-MM-dd",
      editDisplay: true,
      addDisplay: true,
      search: true,
      searchSpan: 8,
      searchValue: new Date().toISOString().substr(0, 10),
      span: 12,
    },
  ],
}

export default {
  name: 'SonIncList',
  components: {},
  data() {
    return {
      option: tableOption,
      searchItmes: {},
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条,
      },
      list: [],
      listLoading: true,
    }
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    getList(page, params) {
      params = params ? params : { ...this.searchItmes }
      params.current = page.currentPage - 1
      params.size = page.pageSize
      params.type = 'son'
      this.listLoading = true
      doGet('/rent/inc/queryPage', params).then(({ data }) => {
        if (data) {
          const { dataList, totalElements } = data.data || {}
          this.list = dataList || []
          this.page.total = totalElements || 0
        }
        this.listLoading = false
      })
    },

    sizeChange(size) {
      this.page.pageSize = size
    },
    currentChange(current) {
      this.page.currentPage = current
    },
    handleFilter(param, done) {
      this.page.currentPage = 1
      this.searchItmes = param ? param : {}
      this.getList(this.page, param)
      done()
    },
    resetFilter() {
      this.page.currentPage = 1
      this.searchItmes = {}
      this.getList(this.page, this.searchItmes)
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/ .el-tabs__nav-scroll {
  padding-left: 10px;
}
/deep/ .el-divider {
  background-color: #ecedef;
}
.bg-blue {
  background: url('~@/assets/bg/blue_bg@2x.png') 50% 50% no-repeat;
  background-size: 100%;
}
.bg-red {
  background: url('~@/assets/bg/green_bg@2x.png') 50% 50% no-repeat;
  background-size: 100%;
}
.bg-orange {
  background: url('~@/assets/bg/orange_bg@2x.png') 50% 50% no-repeat;
  background-size: 100%;
}
.grid-card {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 6px;
  min-height: 215px;
  color: #ffffff;

  .title {
    height: 35px;
    font: 400 18px/35px 'PingFangSC-Regular, PingFang SC, Microsoft Yahei';
    font-weight: 400;
  }
  .number {
    height: 50px;
    font: 400 42px/70px 'tahoma,arial,Microsoft YaHei,Hiragino Sans GB,sans-serif';
    font-weight: 400;
  }

}
.grid-content {
  margin-top: 20px;
  padding: 20px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #fff;
}
</style>
