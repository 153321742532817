var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "data-board",
    },
    [
      _c(
        "basic-container",
        [
          _c(
            "el-form",
            {
              attrs: {
                "label-position": "right",
                model: _vm.queryFrom,
                "label-width": "54px",
                size: "small",
              },
            },
            [
              _c(
                "div",
                { staticClass: "mt10 inner-box" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品号:", prop: "liveStreamPlat" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "350px" },
                          attrs: { placeholder: "请选择" },
                          on: { change: _vm.selectChange },
                          model: {
                            value: _vm.queryFrom.productType,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryFrom, "productType", $$v)
                            },
                            expression: "queryFrom.productType",
                          },
                        },
                        _vm._l(_vm.options, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "board bg-blue-1" }, [
                  _c("p", [_vm._v("今日放款金额（元）")]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm._f("numberToCurrency")(
                          _vm.boardView.todayLoanAmount
                        )
                      )
                    ),
                  ]),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "board bg-blue-1" }, [
                  _c("p", [_vm._v("累计放款金额（元）")]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm._f("numberToCurrency")(_vm.boardView.accLoanAmount)
                      )
                    ),
                  ]),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "board bg-blue-1" }, [
                  _c("p", [_vm._v("今日回款本金（元）")]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm._f("numberToCurrency")(
                          _vm.boardView.todayPrincipalRepayment
                        )
                      )
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "board bg-blue-2" }, [
                  _c("p", [_vm._v("累计回款本金（元）")]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm._f("numberToCurrency")(
                          _vm.boardView.accPrincipalRepayment
                        )
                      )
                    ),
                  ]),
                ]),
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "board bg-blue-2" }, [
                  _c("p", [_vm._v("今日实收息费总额（元）")]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm._f("numberToCurrency")(
                          _vm.boardView.todayReceiptInterestFeeAmount
                        )
                      )
                    ),
                  ]),
                ]),
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "board bg-blue-2" }, [
                  _c("p", [_vm._v("累计实收息费总额（元）")]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm._f("numberToCurrency")(
                          _vm.boardView.accReceiptInterestFeeAmount
                        )
                      )
                    ),
                  ]),
                ]),
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "board bg-blue-2" }, [
                  _c("p", [_vm._v("当前应收息费总额（元）")]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm._f("numberToCurrency")(
                          _vm.boardView.receivableInterestFeeAmount
                        )
                      )
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "board bg-blue-3" }, [
                  _c("p", [_vm._v("当前在贷余额（元）")]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm._f("numberToCurrency")(_vm.boardView.inLoanAmount)
                      )
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }