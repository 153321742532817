<template>
  <div class="mz-layout-section full-condition company-list">
    <div class="tableList_title">经营企业列表</div>
    <el-divider />

    <avue-crud
      ref="crud"
      :option="option"
      :page.sync="page"
      :table-loading="listLoading"
      :data="dataList"
      @on-load="getDataList"
      @size-change="sizeChange"
      @current-change="currentChange"
      @search-change="handleFilter"
      @search-reset="resetFilter">
      <template slot="menuLeft">
        <el-button class="filter-item" type="primary" icon="el-icon-plus" @click="createNew">新增企业</el-button>
      </template>
      <template slot="isAuth" slot-scope="scope">
        <el-tag effect="dark" :type="scope.row.isAuth === '1' ? 'success' : 'info'">
          {{ scope.row.isAuth === '1' ? '已认证' : '未认证' }}
        </el-tag>
      </template>
      <template slot="menu" slot-scope="scope">
        <el-button
          v-if="scope.row.isAuthAll == '1'"
          type="text"
          icon="el-icon-view"
          @click="handleDetails(scope.row, scope.index)">
          详情
        </el-button>
        <!--            <el-button-->
        <!--              v-if="scope.row.isAuthAll=='1'&&scope.row.custAuth!=1"-->
        <!--              type="text"-->
        <!--              icon="el-icon-view"-->
        <!--              @click="authEnterprie(scope.row,scope.index)"-->
        <!--            >银联认证-->
        <!--            </el-button>-->
        <el-button
          v-if="scope.row.isAuthAll != '1'"
          type="text"
          icon="el-icon-s-check"
          @click="handleCert(scope.row, scope.index)">
          企业认证
        </el-button>
      </template>
    </avue-crud>

    <!-- <el-dialog title="企业详情" :visible.sync="dialogVisible" width="60%" custom-class="pub_dialog">
      <page-details v-if="fileId" :modal-data="fileId" />
    </el-dialog>

    <el-dialog title="信息修改" :visible.sync="dialogVisible2" width="60%" custom-class="pub_dialog">
      <page-edit v-if="fileId" :modal-data="fileId" @change="handleCloseDialog" />
    </el-dialog> -->
  </div>
</template>

<script>
import { doGet, doDelet, doPost } from '@/router/axios'
// 子组件
import pageDetails from './components/details.vue'
import pageEdit from './components/edit.vue'
const tableOption = {
  searchMenuSpan: 24,
  searchLabelWidth: 80,
  border: false,
  index: false,
  indexLabel: '序号',
  stripe: true,
  searchIndex: 3,
  searchIcon: true,
  searchShowBtn: false,
  refreshBtn: false,
  menuWidth: 120, //操作栏宽度
  columnBtn: false,
  editBtn: false,
  delBtn: false,
  align: 'left',
  addBtn: false,
 searchShowBtn:false,
 column: [
    {
      fixed: true,
      label: '企业编号',
      prop: 'code',
      width: 120,
      editDisplay: true,
      addDisplay: true,
      search: true,
      searchPlaceholder: '请输入企业编号',
      searchTags: true,
      searchSpan: 8,
      span: 12,
    },
    {
      label: '企业名称',
      prop: 'name',
      minWidth: 200,
      editDisplay: false,
      addDisplay: false,
      search: true,
      searchPlaceholder: '请输入企业名称',
      searchSpan: 8,
      span: 12,
    },
    {
      label: '统一社会信用码',
      prop: 'creditCode',
      width: 180,
      editDisplay: true,
      addDisplay: true,
      span: 12,
    },
    {
      label: '法人姓名',
      prop: 'legalName',
      width: 120,
      editDisplay: true,
      addDisplay: true,
      span: 12,
    },
    {
      label: '法人手机号',
      prop: 'legalPhone',
      editDisplay: true,
      addDisplay: true,
      width: 120,
      span: 12,
    },
    {
      label: '授权人姓名',
      prop: 'authName',
      width: 150,
      editDisplay: true,
      addDisplay: true,
      searchSpan: 8,
      span: 12,
    },
    {
      label: '实控人姓名',
      prop: 'actualName',
      width: 120,
      editDisplay: true,
      addDisplay: true,
      span: 12,
    },
    {
      label: '状态',
      prop: 'status',
      width: 150,
      editDisplay: true,
      addDisplay: true,
      dicUrl: '/common/type/D000029',
      type: 'select',
      search: true,
      searchPlaceholder: '请选择状态',
      searchSpan: 8,
      span: 12,
    },
    {
      label: '认证状态',
      prop: 'isAuth',
      width: 120,
      dicUrl: '/common/type/D000030',
      type: 'select',
      search: true,
      searchPlaceholder: '请选择认证状态',
      editDisplay: true,
      addDisplay: true,
      searchSpan: 8,
      span: 12,
    },
  ],
}

export default {
  name: 'CompanyList',
  components: { pageDetails, pageEdit },
  data() {
    return {
      option: tableOption,
      searchItmes: {},
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条,
      },
      dataList: [],
      listLoading: true,
      // fileId: '',
      // dialogVisible: false,
      // dialogVisible2: false,
    }
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    getDataList(page, params) {
      params = params ? params : { ...this.searchItmes }
      params.current = page.currentPage - 1
      params.size = page.pageSize
      this.listLoading = true
      doGet('/cust/enter/page', params).then(({ data }) => {
        if (data) {
          const { dataList, totalElements } = data.data || {}
          this.dataList = dataList || []
          this.page.total = totalElements || 0
        }
        this.listLoading = false
      })
    },

    sizeChange(size) {
      this.page.pageSize = size
    },
    currentChange(current) {
      this.page.currentPage = current
    },
    handleFilter(param, done) {
      this.page.currentPage = 1
      this.searchItmes = param ? param : {}
      this.getDataList(this.page, param)
      done()
    },
    resetFilter() {
      this.page.currentPage = 1
      this.searchItmes = {}
      this.getDataList(this.page, this.searchItmes)
    },

    handleDetails(row, index) {
      // this.fileId = row.id
      // this.dialogVisible2 = true
      this.$router.push({ path: '/companyActiving/deatil?enterId=' + row.id })
    },
    handleCert(row, index) {
      // this.fileId = row.id
      // this.dialogVisible2 = true
      this.$router.push({ path: '/companyActiving?enterId=' + row.id })
    },
    createNew(row, done, loading) {
      this.$router.push({ path: '/companyActiving', query: { type: 'add' } })
    },

    authEnterprie() {
      this.loading = true
      const data = {}
      data.custId = this.enterId
      data.bankCustId = this.bankCustId
      console.log('提交的参数-->' + JSON.stringify(data))
      doPost('/upyzt/cusapp/enterAccount', data)
        .then((res) => {
          this.loading = false
          if (res.data.code == 200) {
            this.$emit('change', res.data.data.id)
            this.$parent.handleStepNext() // 跳转到下一步
          } else {
            this.$notify({
              title: res.data.msg,
              type: 'error',
              duration: 2500,
            })
          }
        })
        .catch((e) => {
          this.$notify.error(e)
          this.loading = false
        })
    },
    handleCloseDialog() {
      this.dialogVisible2 = false
      this.dialogVisible = false
      this.handleRefreshChange()
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/ .el-tabs__nav-scroll {
  padding-left: 10px;
}
/deep/ .el-divider {
  background-color: #ecedef;
}
</style>
