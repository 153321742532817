<template>
  <div class="selection-box">
    <el-form ref="form" :model="wForm" label-width="160px">
      <el-divider content-position="left" class="title-divider">绑定提现账户</el-divider>
      <el-alert
        title="您还未绑定您的提现银行卡! 请绑定企业同名对公账户! 个体工商户可绑定法人银行卡"
        type="info"
        :closable="false"
      />
      <el-form-item label="账户类型" class="mt20">
        <el-select v-model="wForm.accountType" placeholder="请选择账户类型" @change="handleChangeAccountType">
          <el-option label="对公户" value="0" />
          <el-option label="个人户" value="1" />
        </el-select>
      </el-form-item>
      <el-card class="box-card w600">
        <div slot="header" class="clearfix">
          <b>{{ wForm.accountType === "0" ? '请填写提现对公账户信息' : '填写提现个人账户信息' }}</b>
        </div>
        <el-form-item label="账户名称：" class="mt20">
          李查杰
        </el-form-item>
        <el-form-item label="账户号：">
          <el-input v-model="wForm.accountNo" />
        </el-form-item>
        <el-form-item label="所属银行：">
          <el-input v-model="wForm.bank" />
        </el-form-item>
        <el-form-item label="所属支行：">
          <el-input v-model="wForm.subbank" />
        </el-form-item>
        <el-form-item v-if="wForm.accountType === '1'" label="银行预留手机号：">
          <el-input v-model="wForm.mobilePhone" maxlength="11" />
        </el-form-item>
        <div class="tc mt50">
          <el-button type="primary">确认并提交</el-button>
        </div>
      </el-card>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'Withdraw',
  data() {
    return {
      wForm: {
        enterId: '',
        accountType: null,
        accountNo: '',
        bank: '',
        subbank: '',
        mobilePhone: ''
      }
    };
  },
  created() {
    this.enterId = this.$route.query.enterId;
  },
  methods: {
    handleChangeAccountType(e) {
      console.info('🚀 ~ file:bindingAccount method:handleChangeAccountType line:59 -----', this.wForm.accountType);
    }
  }
};
</script>

<style scoped lang="scss">

</style>
