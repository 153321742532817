<template>
  <div class="mz-layout-section">
    <div class="tableList_title">账户列表</div>
    <el-divider />
    <div class="tableList_crud">
      <avue-crud
        ref="crud"
        v-model="form"
        :option="option"
        :page.sync="page"
        :table-loading="listLoading"
        :before-open="handleOpenBefore"
        :data="list"
        @on-load="getList"
        @size-change="sizeChange"
        @current-change="currentChange"
        @search-change="handleFilter"
        @refresh-change="handleRefreshChange"
        @row-update="update"
        @row-save="create"
      >
        <template slot="menuLeft">
          <el-button
            class="filter-item"
            type="primary"
            icon="iconfont icon-tixian"
            @click="createNew()"
          > 提现
          </el-button>
        </template>
        <template
          slot="balanceAmount"
          slot-scope="scope"
        >
          <span>
            <el-tag effect="dark" type="success">{{ scope.row.balanceAmount }} </el-tag>&nbsp;&nbsp;
          </span>
        </template>

        <template
          slot="totleAmount"
          slot-scope="scope"
        >
          <span>
            <el-tag>{{ scope.row.totleAmount }} </el-tag>&nbsp;&nbsp;
          </span>
        </template>

        <template
          slot="frozenAmount"
          slot-scope="scope"
        >
          <span>
            <el-tag effect="dark" type="danger">{{ scope.row.frozenAmount }} </el-tag>&nbsp;&nbsp;
          </span>
        </template>
        <template
          slot="menu"
          slot-scope="scope"
        >
          <el-button
            type="text"
            icon="el-icon-view"
            @click="widthdrawn(scope.row,scope.index)"
          >提现
          </el-button>
          <el-button
            type="text"
            icon="el-icon-view"
            @click="handleDetails(scope.row,scope.index)"
          >资金明细
          </el-button>
        </template>
      </avue-crud>
    </div>

    <!--详情-->
    <el-dialog
      :visible.sync="dialogVisible"
      title="资金流水"
      width="98%"
      custom-class="pub_dialog"
      @close="handleCloseDialog"
    >
      <account-flow v-if="accountId" :account-id="accountId" @change="handleCloseDialog" />
    </el-dialog>
  </div>

</template>

<script>
import { doGet, doPost, doDelet, doPut } from '@/router/axios';
import accountFlow from './accountFlow';
import { mapGetters } from 'vuex';
export default {
  name: 'TableUser',
  components: { accountFlow },
  data() {
    return {
      option: tableOption,
      defaultProps: {
        label: 'name',
        value: 'id'
      },
      accountId: '',
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条,
        isAsc: false // 是否倒序
      },
      list: [],
      listLoading: true,
      form: {},
      dialogVisible: false,
      fileId: ''
    };
  },
  computed: {

  },
  watch: {

  },
  created() {

  },
  methods: {
    handleCloseDialog() {
      this.dialogVisible = false;
      this.accountId = '';
    },
    getList(page, params) {
      if (params == undefined || params == null || params == '') {
        params = {};
      }
      this.listLoading = true;
      params.current = page.currentPage - 1;
      params.size = page.pageSize;
      doGet('/cap/account/queryPage', params).then(res => {
        this.list = res.data.data.dataList;
        this.page.total = res.data.data.totalElements;
        this.listLoading = false;
      });
    },
    sizeChange(size) {
      this.page.pageSize = size;
    },
    createNew(row, done, loading) {
      this.$router.push({ path: '/cap/widthdraw' });
    },
    currentChange(current) {
      this.page.currentPage = current;
    },
    handleFilter(param, done) {
      this.page.currentPage = 1;
      this.getList(this.page, param);
      done();
    },
    handleRefreshChange() {
      this.getList(this.page);
    },
    handleOpenBefore(show, type) {
      window.boxType = type;
      // 若是编辑、查看回显名称
      if (['edit', 'views'].includes(type)) {

      } else if (type === 'add') {

      }
      show();
    },
    handleUpdate(row, index) {
      this.$refs.crud.rowEdit(row, index);
    },
    widthdrawn(row, index) {
      this.$router.push({ path: '/cap/widthdraw?accId=' + row.id });
    },
    handleDetails(row, index) {
      this.accountId = row.id;
      this.dialogVisible = true;
    },
    create() {
      this.$router.push({ path: '/loan/shoploan' });
    },
    update() {}
  }
};

export const tableOption = {
  border: false,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menualign: 'left',
  menuWidth: 150,
  searchMenuSpan: 6,
  refreshBtn: false,
  columnBtn: false,
  editBtn: false,
  header: true,
  delBtn: false,
  align: 'left',
  addBtn: false,
 searchShowBtn:false,
 column: [
    {
      fixed: true,
      label: "id",
      prop: "id",
      span: 24,
      hide: true,
      editDisplay: false,
      addDisplay: false,
    },
    {
      label: "账户名称",
      prop: "accName",
      width: 180,
      editDisplay: false,
      addDisplay: false,
      search: true,
      searchSpan: 8,
      span: 24,
    },
    {
      label: "账户类型",
      prop: "accMold",
      width: 150,
      type: "select",
     
      dicUrl: "/dict/type/D000122",
      editDisplay: true,
      addDisplay: false,
      search: true,
      searchSpan: 8,
      span: 12,
    },
    {
      label: "支付渠道",
      prop: "upyztBookNo",
      width: 150,
      editDisplay: false,
      addDisplay: false,
      span: 24,
    },
    {
      label: "钱包账号",
      prop: "upyztBookNo",
      width: 190,
      search: true,
      editDisplay: false,
      addDisplay: false,
      span: 24,
    },
    {
      label: "绑定银行卡号",
      prop: "bankCard",
      width: 180,
      editDisplay: false,
      addDisplay: false,
      search: true,
      searchSpan: 8,
      span: 24,
    },
    {
      label: "银行卡所属银行",
      prop: "bankName",
      width: 180,
      editDisplay: false,
      addDisplay: false,
      search: true,
      searchSpan: 8,
      span: 24,
    },
    {
      label: "总余额",
      prop: "totleAmount",
      editDisplay: true,
      addDisplay: true,
      span: 12,
    },
    {
      label: "可用余额",
      prop: "balanceAmount",
      editDisplay: false,
      addDisplay: false,
      span: 12,
    },
    {
      label: "冻结金额",
      prop: "frozenAmount",
      editDisplay: false,
      addDisplay: false,
      span: 12,
    },
    // {
    //   label: "银行卡账户余额金额",
    //   prop: "upyztTotalAmount",
    //   width: 130,
    //   editDisplay: false,
    //   addDisplay: false,
    //   span: 12,
    // },
    // {
    //   label: "银行冻结金额",
    //   prop: "upyztFrozenAmount",
    //   editDisplay: false,
    //   addDisplay: false,
    //   hide: true,
    //   span: 12,
    // },
    // {
    //   label: "在途金额",
    //   prop: "pendingAmount",
    //   editDisplay: false,
    //   addDisplay: false,
    //   hide: true,
    //   span: 12,
    // },
    // {
    //   label: "不可用余额",
    //   prop: "expensingAmount",
    //   editDisplay: false,
    //   addDisplay: false,
    //   hide: true,
    //   span: 12,
    // },
    {
      label: "用户ID",
      prop: "custId",
      editDisplay: false,
      addDisplay: false,
      hide: true,
      span: 12,
    },

    {
      label: "账户类型",
      prop: "accType",
      type: "select",
      dicUrl: "/dict/type/D000035",
      editDisplay: true,
      addDisplay: false,
      hide: true,
      span: 12,
    },
    {
      label: "状态",
      prop: "status",
      type: "select",
      dicUrl: "/dict/type/D000033",
      editDisplay: true,
      addDisplay: false,
      search: true,
      searchSpan: 8,
      span: 12,
    },
    {
      label: "错误信息",
      prop: "errorMsg",
      editDisplay: false,
      addDisplay: false,
      hide: true,
      rules: [
        {
          required: false,
          message: "请输入错误信息",
        },
      ],
      span: 12,
    },
    {
      label: "错误时间",
      prop: "errorTime",
      type: "datetime",
      format: "yyyy-MM-dd HH:mm:ss",
      valueFormat: "yyyy-MM-dd HH:mm:ss",
      editDisplay: false,
      addDisplay: false,
      hide: true,
      rules: [
        {
          required: false,
          message: "请输入错误时间",
        },
      ],
      span: 12,
    },
    {
      label: "是否核对",
      prop: "isCheck",
      type: "select",
      dicUrl: "/dict/type/D000123",
      editDisplay: false,
      addDisplay: false,
      hide: true,
      rules: [
        {
          required: false,
          message: "请输入是否核对",
        },
      ],
      search: false,
      span: 12,
    },
    {
      label: "备注",
      prop: "remark",
      type: "textarea",
      editDisplay: true,
      hide: true,
      addDisplay: true,
      rules: [
        {
          required: false,
          message: "请输入备注",
        },
      ],
      span: 24,
    },
    {
      label: "",
      prop: "updateTime",
      editDisplay: false,
      addDisplay: false,
      rules: [
        {
          required: false,
          message: "请输入",
        },
      ],
      hide: true,
      span: 12,
    },
    {
      label: "",
      prop: "createTime",
      editDisplay: false,
      addDisplay: false,
      rules: [
        {
          required: false,
          message: "请输入",
        },
      ],
      hide: true,
      span: 12,
    },
  ],
};

</script>
<style lang="scss" scoped>
/deep/ .el-tabs__nav-scroll {
  padding-left: 10px;
}

/deep/ .el-divider {
  background-color: #ecedef;
}
/deep/ .el-divider--horizontal {
  margin: 12px 0 24px;
}
.my-invest-item {
  li {
    float: left;
  }

  .ico {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    display: inline-block;
    background-image: url('/img/icon/centre-icon.png');
    vertical-align: middle;
  }

  div {
    border: 1px solid #efefef;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 0 1px rgba(0, 0, 0, .1);
    margin-right: 20px;
  }
}

.invest-ico-1 {
  background-position: -624px -78px;
}

.invest-ico-2 {
  background-position: -624px -104px;
}

.invest-ico-3 {
  background-position: -624px -130px;
}

.invest-ico-4 {
  background-position: -558px -26px;
}
/deep/ .el-dialog__body {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
</style>
