var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "details-warp" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, "label-width": "120px", rules: _vm.rules },
        },
        [
          _c(
            "div",
            { staticClass: "details-section ml10 mr20" },
            [
              _c("h3", { staticClass: "details-section-h3" }, [
                _c("span", { staticClass: "title" }, [_vm._v("店铺表")]),
              ]),
              _c(
                "el-row",
                { staticClass: "pl20 pr20", attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "店铺名称：" } },
                        [
                          _c("el-input", {
                            attrs: { readonly: "" },
                            model: {
                              value: _vm.form.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "店铺编码：" } },
                        [
                          _c("el-input", {
                            attrs: { readonly: "" },
                            model: {
                              value: _vm.form.shopCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "shopCode", $$v)
                              },
                              expression: "form.shopCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "折扣率：" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { readonly: "" },
                              model: {
                                value: _vm.form.discountRate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "discountRate", $$v)
                                },
                                expression: "form.discountRate",
                              },
                            },
                            [_c("template", { slot: "append" }, [_vm._v("%")])],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "退货率：" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { readonly: "" },
                              model: {
                                value: _vm.form.refundRate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "refundRate", $$v)
                                },
                                expression: "form.refundRate",
                              },
                            },
                            [_c("template", { slot: "append" }, [_vm._v("%")])],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "日钧结算余额：" } },
                        [
                          _c("el-input", {
                            attrs: { readonly: "" },
                            model: {
                              value: _vm.form.daySettleAmt,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "daySettleAmt", $$v)
                              },
                              expression: "form.daySettleAmt",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "费用计算方式：" } },
                        [
                          _c("dict-select", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: "费用计算方式",
                              no: "D000082",
                              disabled: "",
                            },
                            model: {
                              value: _vm.form.rateType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "rateType", $$v)
                              },
                              expression: "form.rateType",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "周转天数：" } },
                        [
                          _c("dict-select", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: "周转天数",
                              no: "D000136",
                              disabled: "",
                            },
                            model: {
                              value: _vm.form.turnoverDays,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "turnoverDays", $$v)
                              },
                              expression: "form.turnoverDays",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "直播平台：" } },
                        [
                          _c("dict-select", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: "直播平台",
                              no: "D000101",
                              disabled: "",
                            },
                            model: {
                              value: _vm.form.liveStreamPlat,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "liveStreamPlat", $$v)
                              },
                              expression: "form.liveStreamPlat",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "店主账号：" } },
                        [
                          _c("el-input", {
                            attrs: { readonly: "" },
                            model: {
                              value: _vm.form.shopkeeperId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "shopkeeperId", $$v)
                              },
                              expression: "form.shopkeeperId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "店主手机号：" } },
                        [
                          _c("el-input", {
                            attrs: { readonly: "" },
                            model: {
                              value: _vm.form.shopkeeperPhone,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "shopkeeperPhone", $$v)
                              },
                              expression: "form.shopkeeperPhone",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "配置日期：" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "datetime",
                              placeholder: "选择日期",
                              "value-format": "yyyy-MM-dd HH:mm",
                              format: "yyyy-MM-dd HH:mm",
                              readonly: "",
                            },
                            model: {
                              value: _vm.form.cofigDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "cofigDate", $$v)
                              },
                              expression: "form.cofigDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "details-section ml10 mr20 mt20" },
        [
          _vm._m(0),
          _c(
            "el-row",
            { staticClass: "pl20 pr20", attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [_c("shopGoodsOrder", { attrs: { "shop-id": _vm.modalData } })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", { staticClass: "details-section-h3" }, [
      _c("span", { staticClass: "title" }, [_vm._v("列表")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }