<template>
  <div class="addr-select" :class="plain ? 'plain' : ''">
    <template v-if="plain">
      {{ proniceLabel }} {{ cityLabel }}
    </template>
    <template v-else>
      <el-select v-model="pronice">
        <el-option
          v-for="item in provinces"
          :key="item.value"
          :value="item.value"
          :label="item.label"
        />
      </el-select>
      <span v-if="showCity" style="margin-right: 10px;" />
      <el-select v-if="showCity" v-model="city">
        <el-option
          v-for="item in cities"
          :key="item.value"
          :value="item.value"
          :label="item.label"
        />
      </el-select>
    </template>
  </div>
</template>
<script>
// import { pca } from 'area-data'
import pca from 'area-data/pca';

export default {
  name: 'AddrSelect',
  model: {
    event: 'change',
    prop: 'selected'
  },
  props: {
    selected: {
      type: Array,
      default: () => []
    },
    internation: Boolean,
    plain: Boolean
  },
  data() {
    return {
    };
  },
  computed: {
    pronice: {
      get() {
        return this.selected[0];
      },
      set(val) {
        this.$emit('change', [val, this.selected[1]]);
      }
    },
    city: {
      get() {
        return this.selected[1];
      },
      set(val) {
        this.$emit('change', [this.selected[0], val]);
      }
    },
    proniceLabel() {
      const p = this.provinces.find(v => v.value === this.pronice);
      return p ? p.label : '';
    },
    cityLabel() {
      const c = this.cities.find(v => v.value === this.city);
      return c ? c.label : '';
    },
    provinces() {
      const provinces = Object.keys(pca['86']).map(key => {
        return {
          value: key,
          label: pca['86'][key]
        };
      });
      if (this.internation) {
        provinces.unshift({
          value: 99999,
          label: '非中国大陆地区'
        });
      }

      return provinces;
    },
    cities() {
      if (!pca[this.pronice]) {
        return [];
      }
      return Object.keys(pca[this.pronice]).map(key => {
        return {
          value: key,
          label: pca[this.pronice][key]
        };
      });
    },
    showCity() {
      return !this.internation || this.pronice !== 99999;
    }
  }
};
</script>
<style lang="scss" scoped>
	.addr-select {
		width: 100%;
		max-width: 476px;
		display: flex;
    /deep/ .el-select,
    /deep/ .el-input {
      width: 275px !important;
    }
    &.plain {
      width: auto;
      display: inline-block;
    }
	}
</style>
