var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sms-code pl20 pr20" },
    [
      _c(
        "el-form",
        {
          ref: "cFormRef",
          attrs: {
            model: _vm.dataForm.model,
            rules: _vm.dataForm.rules,
            "label-position": "right",
            "label-width": "120px",
            size: "small",
          },
        },
        [
          _c("el-alert", {
            attrs: {
              title: "您的监管账户已就绪，正在等待账户激活",
              closable: false,
              type: "success",
            },
          }),
          _c(
            "div",
            { staticClass: "mt20 inner-box" },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "verif-code",
                  attrs: { label: "手机验证码", prop: "smsCode" },
                },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { placeholder: "请输入验证码" },
                      model: {
                        value: _vm.dataForm.model.smsCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataForm.model, "smsCode", $$v)
                        },
                        expression: "dataForm.model.smsCode",
                      },
                    },
                    [
                      _c("template", { slot: "append" }, [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isSentCode,
                                expression: "isSentCode",
                              },
                            ],
                            staticClass: "append-text",
                          },
                          [
                            _c("i", [_vm._v(_vm._s(_vm.sendTimerCount) + "s")]),
                            _vm._v("\n              后重新发送\n            "),
                          ]
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.isSentCode,
                                expression: "!isSentCode",
                              },
                            ],
                            staticClass: "append-text send-btn",
                            on: { click: _vm.toSendCode },
                          },
                          [_vm._v("发送验证码")]
                        ),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-alert", {
            staticClass: "mt20",
            attrs: {
              title: `为了您的账户激活，需对您的手机(${_vm.accInfos.sendMobilePhone})发送短信验证码验证，请注意查收短信`,
              closable: false,
              type: "info",
              "show-icon": "",
            },
          }),
          _c("el-divider"),
          _c(
            "div",
            { staticClass: "tc" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "medium",
                    loading: _vm.isSubmiting,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.openAcc()
                    },
                  },
                },
                [_vm._v("我已收到短信，去验证")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }