<template>
  <el-dialog
    class="dialog-standard channel-config-rate"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    :fullscreen="isToFullScreen"
    width="1000px"
    top="100px"
    visible
  >
    <section class="dialog-header" slot="title">
      <span class="dialog-title"
        >{{ modalType === infoStatus[0] ? "配置" : "查看" }}费率</span
      >
      <span class="right-icon">
        <i
          :class="[isToFullScreen ? 'el-icon-news' : 'el-icon-full-screen']"
          @click="setFullScreen"
        ></i>
        <i class="el-icon-close" @click="handleCancel"></i>
      </span>
    </section>
    <section class="dialog-body">
      <ul class="body-view-infos">
        <li class="word-break">
          <label>渠道名称：</label>{{modalData.name}}
        </li>
        <li class="word-break">
          <label>渠道注册时间：</label>{{modalData.created}}
        </li>
        <li class="word-break">
          <label>渠道等级：</label>{{ modalData.level == 2 ? '二级' : '三级' }} 
        </li>
        <li class="word-break">
          <label>渠道状态：</label>
          <el-select  @change="submitStatus" v-model="status">
              <el-option
                v-for="item in statusList"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              />
            </el-select>
        </li>
      </ul>
      <div class="table-list">
        <p class="tips">
          配置渠道费率方案
        </p>
        <avue-crud
          ref="crud"
          :table-loading="isLoading"
          :option="option"
          :data="dataList"
        >
          <template #channelRate="{ row }">
            <el-input-number
              v-model="row.channelRate"
              :precision="2"
              :step="0.1"
              :min="row.minFundRate"
              v-if="modalType === infoStatus[0]"
            ></el-input-number>
            <span v-else>{{ row.channelRate }}</span>
          </template>
        </avue-crud>
      </div>
    </section>
    <section class="dialog-footer">
      <template v-if="modalType === infoStatus[0]">
        <el-button
          type="primary"
          icon="el-icon-circle-check"
          v-loading="isSubmiting"
          @click="handleConfirm"
          >保存</el-button
        >
        <el-button
          class="cancel"
          icon="el-icon-circle-close"
          @click="handleCancel"
          >取消</el-button
        >
      </template>
    </section>
  </el-dialog>
</template>

<script>
import { doGet, doPost } from "@/router/axios";
import { dealNull } from "@/filters/index";

export default {
  name: "ChannelConfigRate",
  props: {
    modalType: {
      type: String,
      default: "",
    },
    modalData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      statusList:[
        {
          label:'启用',
          value:1
        },{
          label:'停用',
          value: 0
        }
      ],
      dDayList:[
        {
          turnoverStartDay:'1',
          turnoverEndDay:'4'
        },{
          turnoverStartDay:'5',
          turnoverEndDay:'6'
        },{
          turnoverStartDay:'7',
          turnoverEndDay:'10'
        },{
          turnoverStartDay:'11',
          turnoverEndDay:'15'
        },{
          turnoverStartDay:'16',
          turnoverEndDay:'20'
        },{
          turnoverStartDay:'21',
          turnoverEndDay:'26'
        },{
          turnoverStartDay:'27',
          turnoverEndDay:'30'
        },{
          turnoverStartDay:'31',
          turnoverEndDay:'35'
        },{
          turnoverStartDay:'36',
          turnoverEndDay:'40'
        },{
          turnoverStartDay:'41',
          turnoverEndDay:'45'
        },
        
      ],
      isToFullScreen: false,
      isLoading: true,
      isSubmiting: false, //防重复提交
      infoStatus: ["edit", "view"], //状态，配置/查看
      dataList: [],
      status:0,
      option: {
        addBtn: false,
        refreshBtn: false,
        columnBtn: false,
        header: false,
        menu: false,
        border: false,
        stripe: true,
        align: "center",
       searchShowBtn:false,
 column: [
          {
            label: "渠道费率(单笔%）",
            prop: "channelRate",
            width: 300,
          },
          {
            label: "平均回款天数",
            prop: "avePayDays",
            width: 300,
          },
          {
            label: "我的渠道费率",
            prop: "channelRatePer",
          },
        ],
      },
    };
  },
  watch: {},
  created() {
    this.isLoading = true;
    this.status = this.modalData.status
    // 判断配置还是查看
    if (this.modalType === this.infoStatus[0]) {
      // 配置
      this.getInitChannelRates();
    } else {
      // 查看
      this.getViewChannelRates();
    }
  },
  mounted() {},
  methods: {
    //提交状态修改
    submitStatus(){
      let form = {
          channelId:this.modalData.id,
          status:this.status,
      }
      console.log(this.modalData)
      this.doPost('bium-portal/controller/fin-ops/channel/changeStatus', form).then(({ data }) => {
          if (data) {
            this.$notify.success("配置成功。");
            this.modalData.status = this.status
            this.$options.confirm && this.$options.confirm();
          } 
        })
    },
    /** 获取初始模版渠道数据 */
    getInitChannelRates() {
      const api = "/bium-portal/controller/fin-ops/ratePlan/queryDirectList"; //一级渠道模版ID：1
      this.doPost(api, ).then(({ data }) => {
        if (data) {
          this.dataList = this.dealResData(data.result);
        }
        this.isLoading = false;
      });
    },
    /** 查看已配置的费率 */
    getViewChannelRates() {
      const api = "bium-portal/controller/fin-ops/ratePlan/queryChannelRate";
      const { id } = this.modalData;
      doPost(api, { channelId: id }).then(({ data }) => {
        if (data) {
           const { data: listData } = data;
          this.dataList = this.dealResData(listData);
        }
        this.isLoading = false;
      });
    },
    /** 预处理接口返回数据 */
    dealResData(resData) {
      if (resData && resData.length) {
        return resData.map((item, idx) => {
          const { channelRate } = item;
          return {
            ...item,
            avePayDays: `${dealNull( this.dDayList[idx].turnoverStartDay)}~${dealNull(
              this.dDayList[idx].turnoverEndDay
            )}天`,
            channelRatePer: dealNull(Number(channelRate) * 100).toFixed(2) + "%",
            minFundRate: Number(dealNull((Number(channelRate) * 100).toFixed(2), 0)),
          };
        });
      }
      return [];
    },
    /** 保存提交数据 */
    saveRateData(rateList) {
      this.isSubmiting = true;
      const { id } = this.modalData;
      const api = "/bium-portal/controller/fin-ops/ratePlan/saveChannelRate";
      let ratearr =  [] 
      rateList.forEach( item =>{
        ratearr.push({
          order:item.order,
          channelRate:Number((item.channelRate / 100).toFixed(4))
        })
      })
      const sendDate = {
        channelId: id,
        rateDetailList: ratearr,
      };
      this.doPost(api, sendDate).then(({ data }) => {
          if (data) {
            this.$notify.success("配置成功。");
            this.$options.confirm && this.$options.confirm();
          } else {
            this.isSubmiting = false;
          }
        })
        .catch(() => {
          this.isSubmiting = false;
        });
    },

    /** 确认 */
    handleConfirm() {
      if (!this.isSubmiting) {
        const { dataList } = this;
        // 先校验是否全部填写完
        const noValue = dataList.find((item) => !item.channelRate);
        if (noValue) {
          this.$notify.error("还有未填写完的信息，请检查后再提交。");
          return;
        }
        // 再校验“上一档价格小于等于下一档”规则
        const maxIdx = dataList.length - 1; //最大下标索引
        const compareValid = dataList.every((item, index, arr) => {
          if (index < maxIdx) {
            const nextFundRate = arr[index + 1].channelRate; //下一档的渠道费率
            return nextFundRate >= item.channelRate;
          }
     
          return true;
        });
        // 校验全通过
        if (compareValid) {
          this.saveRateData(dataList);
        } else {
          this.$notify.error(
            "请检查数据是否满足“上一档价格小于等于下一档，且不能低于我的渠道费率”的规则。"
          );
        }
      }
    },
    /** 关闭 */
    handleCancel() {
      this.$options.cancel && this.$options.cancel();
    },
    /** 全屏设置 */
    setFullScreen() {
      this.isToFullScreen = !this.isToFullScreen;
    },
  },
};
</script>

<style lang="scss" scoped>
.channel-config-rate {
  .body-view-infos {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px;
    label {
      color: #999;
    }
  }
  .table-list {
    .tips {
      font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif;
    font-weight: 650;
    font-style: normal;
    color: #027DB4;
    text-align: center;
    padding: 10px 0;
    font-size: 30px;
    }
    /deep/.el-input-number .el-input input {
      text-align: center;
    }
  }
}
</style>
