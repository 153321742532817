var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "details-warp" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, "label-width": "120px", rules: _vm.rules },
        },
        [
          _c(
            "div",
            { staticClass: "details-section ml10 mr20" },
            [
              _c("h3", { staticClass: "details-section-h3" }, [
                _c("span", { staticClass: "title" }, [_vm._v("店铺订单表")]),
              ]),
              _c(
                "el-row",
                { staticClass: "pl20 pr20", attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "店铺ID：" } },
                        [
                          _c("el-input", {
                            attrs: { readonly: "true" },
                            model: {
                              value: _vm.form.shopId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "shopId", $$v)
                              },
                              expression: "form.shopId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "订单编号：" } },
                        [
                          _c("el-input", {
                            attrs: { readonly: "true" },
                            model: {
                              value: _vm.form.orderNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "orderNo", $$v)
                              },
                              expression: "form.orderNo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "订单状态：" } },
                        [
                          _c("dict-select", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: "订单状态",
                              no: "店铺订单-订单状态",
                              disabled: "",
                            },
                            model: {
                              value: _vm.form.orderStatus,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "orderStatus", $$v)
                              },
                              expression: "form.orderStatus",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "借款状态;：" } },
                        [
                          _c("dict-select", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: "借款状态;",
                              no: "D000104",
                              disabled: "",
                            },
                            model: {
                              value: _vm.form.loanStatus,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "loanStatus", $$v)
                              },
                              expression: "form.loanStatus",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "实付金额：" } },
                        [
                          _c("el-input", {
                            attrs: { readonly: "true" },
                            model: {
                              value: _vm.form.payAmt,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "payAmt", $$v)
                              },
                              expression: "form.payAmt",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品ID：" } },
                        [
                          _c("el-input", {
                            attrs: { readonly: "true" },
                            model: {
                              value: _vm.form.goodsId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "goodsId", $$v)
                              },
                              expression: "form.goodsId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品名称：" } },
                        [
                          _c("el-input", {
                            attrs: { readonly: "true" },
                            model: {
                              value: _vm.form.goodsName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "goodsName", $$v)
                              },
                              expression: "form.goodsName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品单价：" } },
                        [
                          _c("el-input", {
                            attrs: { readonly: "true" },
                            model: {
                              value: _vm.form.goodsPrice,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "goodsPrice", $$v)
                              },
                              expression: "form.goodsPrice",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品数量：" } },
                        [
                          _c("el-input", {
                            attrs: { readonly: "true" },
                            model: {
                              value: _vm.form.goodsNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "goodsNum", $$v)
                              },
                              expression: "form.goodsNum",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "购买时间：" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "datetime",
                              placeholder: "选择日期",
                              "value-format": "yyyy-MM-dd HH:mm",
                              format: "yyyy-MM-dd HH:mm",
                              readonly: "true",
                            },
                            model: {
                              value: _vm.form.buyTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "buyTime", $$v)
                              },
                              expression: "form.buyTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "发货时间：" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "datetime",
                              placeholder: "选择日期",
                              "value-format": "yyyy-MM-dd HH:mm",
                              format: "yyyy-MM-dd HH:mm",
                              readonly: "true",
                            },
                            model: {
                              value: _vm.form.shipmentsTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "shipmentsTime", $$v)
                              },
                              expression: "form.shipmentsTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "确认收款时间：" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "datetime",
                              placeholder: "选择日期",
                              "value-format": "yyyy-MM-dd HH:mm",
                              format: "yyyy-MM-dd HH:mm",
                              readonly: "true",
                            },
                            model: {
                              value: _vm.form.receiptTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "receiptTime", $$v)
                              },
                              expression: "form.receiptTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注;：" } },
                        [
                          _c("el-input", {
                            attrs: { readonly: "true", type: "textarea" },
                            model: {
                              value: _vm.form.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "remark", $$v)
                              },
                              expression: "form.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }