var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "file-view-wrap" }, [
    _c(
      "ul",
      { staticClass: "file-view-list" },
      _vm._l(_vm.myList, function (item, i) {
        return _c(
          "li",
          { key: i, class: !item.isImage ? "file-item" : "" },
          [
            item.isImage
              ? [
                  _c("el-image", {
                    style: { width: _vm.size, height: _vm.size },
                    attrs: {
                      src: item.url,
                      fit: _vm.fit,
                      "preview-src-list": [item.url],
                    },
                  }),
                ]
              : [
                  _c(
                    "a",
                    {
                      staticClass: "download-file",
                      attrs: { href: item.url, download: "", target: "_blank" },
                    },
                    [
                      _c("i", { staticClass: "el-icon-document" }),
                      _vm._v(
                        "\n          " +
                          _vm._s(`附件-${i + 1}`) +
                          "." +
                          _vm._s(item.fileType) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
          ],
          2
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }