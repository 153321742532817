<template>
  <div class="file-view-wrap">
    <ul class="file-view-list">
      <li v-for="(item, i) in myList" :key="i" :class="!item.isImage ? 'file-item' : ''">
        <template v-if="item.isImage">
          <el-image
            :style="{width: size, height: size}"
            :src="item.url"
            :fit="fit"
            :preview-src-list="[item.url]"
          />
        </template>
        <template v-else>
          <a class="download-file" :href="item.url" download target="_blank">
            <i class="el-icon-document" />
            {{ `附件-${i + 1}` }}.{{ item.fileType }}
          </a>
        </template>
      </li>
    </ul>
  </div>
</template>
<script>
import { getImageUrl } from '@/api/upload';
export default {
  name: 'FileView',
  props: {
    list: {
      type: Array,
      default: () => {}
    },
    size: {
      type: String,
      default: '100px'
    },
    fit: {
      type: String,
      default: 'cover'
    }
  },
  computed: {
    myList() {
      console.log(this.list, 'lll');
      return this.list.map(item => ({
        ...item,
        url: getImageUrl(item.fileId),
        isImage: /(png|jpe?g|gif)$/.test(item.fileType)
      }));
    }
  }
};
</script>
<style lang="scss" scoped>
	.file-view-wrap {
		// display: inline-block;
	}
	.file-view-list {
    list-style: none;
		display: flex;
    padding: 0;
    margin: 0;
		> li {
      padding: 0;
      margin: 0;
			width: 100px;
      height: 100px;
      text-align: center;
      img {
        vertical-align: middle;
      }
			&.file-item {
        padding: 5px;
        box-sizing: border-box;
				background: #f5f7fa;
        display: flex;
        justify-content: center;
        align-items: center;
			}
			& + li {
				margin-left: 10px;
			}
      a {
        color: #909399;
        cursor: pointer;
        font-size: 14px;
      }
      .el-icon-document {
        font-size: 26px;
        display: block;
      }
		}
	}
</style>
