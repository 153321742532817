var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ml30 mr30" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "pl20 pr20",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-width": _vm.formLabelWidth,
            "status-icon": "",
            "inline-message": "",
          },
        },
        [
          _c("div", { staticClass: "title_h3 mb20" }, [_vm._v("融资信息")]),
          _c(
            "el-form-item",
            {
              staticClass: "ml20 mr20",
              attrs: { label: "申请金额：", prop: "applyAmt" },
            },
            [
              _vm._v("\n      " + _vm._s(_vm.voucherData.applyAmt)),
              _c("span", { staticClass: "ml10" }, [_vm._v("元")]),
            ]
          ),
          _vm.voucherData.repayType == 0
            ? _c(
                "el-form-item",
                {
                  staticClass: "ml20 mr20",
                  attrs: { label: "融资期限：", prop: "linesDay" },
                },
                [
                  _vm._v("\n      " + _vm._s(_vm.voucherData.linesDay)),
                  _c("span", { staticClass: "ml10" }, [_vm._v("天")]),
                ]
              )
            : _vm._e(),
          _vm.voucherData.repayType == 1 || _vm.voucherData.repayType == 2
            ? _c(
                "el-form-item",
                {
                  staticClass: "ml20 mr20",
                  attrs: { label: "融资期限：", prop: "linesDay" },
                },
                [
                  _vm._v("\n      " + _vm._s(_vm.voucherData.linesDay)),
                  _c("span", { staticClass: "ml10" }, [_vm._v("月")]),
                ]
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              staticClass: "ml20 mr20",
              attrs: { label: "收款方名称：", prop: "linesDay" },
            },
            [_vm._v("\n      " + _vm._s(_vm.voucherData.reciveName) + "\n    ")]
          ),
          _c(
            "el-form-item",
            {
              staticClass: "ml20 mr20",
              attrs: { label: "收款方账户：", prop: "linesDay" },
            },
            [_vm._v("\n      " + _vm._s(_vm.voucherData.reciveAcc) + "\n    ")]
          ),
          _c(
            "el-form-item",
            {
              staticClass: "ml20 mr20",
              attrs: { label: "开户行：", prop: "linesDay" },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.voucherData.reciveBankSub) + "\n    "
              ),
            ]
          ),
          _c(
            "el-form-item",
            { staticClass: "ml20 mr20", attrs: { label: "融资年利率：" } },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm._f("numberMul")(_vm.voucherData.yearRate, 100)) +
                  "%\n    "
              ),
            ]
          ),
          _c(
            "el-form-item",
            { staticClass: "ml20 mr20", attrs: { label: "融资日利率：" } },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm._f("numberMul")(_vm.voucherData.intervestRate, 100)
                  ) +
                  "%\n    "
              ),
            ]
          ),
          _c(
            "el-form-item",
            { staticClass: "ml20 mr20", attrs: { label: "预计利息：" } },
            [
              _vm._v("\n      " + _vm._s(_vm.voucherData.inverest) + " "),
              _c("span", { staticClass: "ml10" }, [_vm._v("元")]),
            ]
          ),
          _c(
            "el-form-item",
            {
              staticClass: "ml20 mr20",
              attrs: { label: "备注：", prop: "applyMsg" },
            },
            [_vm._v("\n      " + _vm._s(_vm.voucherData.applyMsg) + "\n    ")]
          ),
          _c("div", { staticClass: "title_h3 mb20 mt20" }, [
            _vm._v("凭证信息"),
          ]),
          _c(
            "el-form-item",
            {
              staticClass: "ml20 mr20",
              attrs: { label: "发票：", prop: "fileInvoiceIds" },
            },
            [
              _c("dict-upload", {
                attrs: {
                  limit: 10,
                  "type-code": "F0402",
                  "biz-id": "123456",
                  type: "text",
                },
                on: {
                  change: function ($event) {
                    return _vm.onFileChange($event, "fileInvoiceIds")
                  },
                },
                model: {
                  value: _vm.form.fileInvoiceIds,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "fileInvoiceIds", $$v)
                  },
                  expression: "form.fileInvoiceIds",
                },
              }),
              _c("div", { staticClass: "form-item-tips" }, [
                _vm._v("\n        仅支持jpeg、png格式\n      "),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "ml20 mr20",
              attrs: { label: "业务协议：", prop: "fileContractIds" },
            },
            [
              _c("dict-upload", {
                attrs: {
                  limit: 10,
                  "type-code": "F0401",
                  "biz-id": "123456",
                  type: "text",
                },
                on: {
                  change: function ($event) {
                    return _vm.onFileChange($event, "fileContractIds")
                  },
                },
                model: {
                  value: _vm.form.fileContractIds,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "fileContractIds", $$v)
                  },
                  expression: "form.fileContractIds",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticClass: "ml20 mr20", attrs: { label: "凭证总额：" } },
            [
              _vm._v("\n      " + _vm._s(_vm.voucherData.voucherAccount)),
              _c("span", { staticClass: "ml10" }, [_vm._v("元")]),
            ]
          ),
          _c(
            "el-form-item",
            { staticClass: "ml20 mr20", attrs: { label: "凭证总数：" } },
            [
              _vm._v("\n      " + _vm._s(_vm.voucherData.voucherCount)),
              _c("span", { staticClass: "ml10" }, [_vm._v("笔")]),
            ]
          ),
          _c(
            "el-form-item",
            { staticClass: "ml20 mr20", attrs: { label: "凭证到期日：" } },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.voucherData.voucherDateStr) + "\n    "
              ),
            ]
          ),
          _c("div", { staticClass: "title_h3 mb20" }, [_vm._v("其它")]),
          _c(
            "el-form-item",
            {
              staticClass: "ml20 mr20",
              attrs: { label: "补充附件:", prop: "fileSupplyIds" },
            },
            [
              _c("dict-upload", {
                attrs: {
                  limit: 10,
                  "type-code": "F0404",
                  "biz-id": "123456",
                  type: "text",
                },
                on: {
                  change: function ($event) {
                    return _vm.onFileChange($event, "fileSupplyIds")
                  },
                },
                model: {
                  value: _vm.form.fileSupplyIds,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "fileSupplyIds", $$v)
                  },
                  expression: "form.fileSupplyIds",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt40 tc" },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-arrow-left", size: "medium" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleGoPrepStep()
                },
              },
            },
            [_vm._v("上一步")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-circle-check",
                type: "primary",
                size: "medium",
                loading: _vm.btnLoading,
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleGoNextStep("ruleForm")
                },
              },
            },
            [_vm._v(_vm._s(_vm.btnSave))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }