var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "selection-box sign-docuemnt" },
    [
      _c("el-alert", {
        attrs: { title: "企业签约信息", closable: false, type: "info" },
      }),
      _c(
        "div",
        { staticClass: "sign_container" },
        [
          _vm.flag == 0
            ? [
                _vm._m(0),
                _c("ul", { staticClass: "cert_ul" }, [
                  _c("li", [
                    _vm._v("法人姓名：" + _vm._s(_vm.cForm.legalName)),
                  ]),
                  _c("li", [
                    _vm._v("法人身份证号码：" + _vm._s(_vm.cForm.legalIdCard)),
                  ]),
                  _c("li", [_vm._v("商户名称：" + _vm._s(_vm.cForm.name))]),
                  _c("li", [
                    _vm._v("社会统一信用代码：" + _vm._s(_vm.cForm.creditCode)),
                  ]),
                  _c(
                    "li",
                    [
                      _c("el-checkbox", {
                        on: { change: _vm.selectAuth },
                        model: {
                          value: _vm.agreeChecked,
                          callback: function ($$v) {
                            _vm.agreeChecked = $$v
                          },
                          expression: "agreeChecked",
                        },
                      }),
                      _vm._v(
                        "\n          本人已仔细阅读并完全理解、接受如下文件，接受该等文件的约束《企业信息授权书》、《服务协议及授权确认书》\n        "
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            : _vm.flag == 3
            ? [
                _c(
                  "el-result",
                  {
                    attrs: {
                      title: "签约信息审核中",
                      "sub-title": "签约信息审核中,审核期5-10工作日，请等待！",
                    },
                  },
                  [
                    _c("template", { slot: "icon" }, [
                      _c("i", {
                        staticClass:
                          "iconfont icon-clock_circle_fill f60 fc-blue-t",
                      }),
                    ]),
                  ],
                  2
                ),
              ]
            : _vm.flag == 6
            ? [
                _c("el-result", {
                  attrs: { icon: "success", "sub-title": "签约成功" },
                }),
              ]
            : _vm.flag == 9 || _vm.flag == 8 || _vm.flag == 7
            ? [
                _c("el-result", {
                  attrs: {
                    icon: "error",
                    title: "错误提示",
                    "sub-title": "请根据提示进行操作",
                  },
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm.tableData.length > 1
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.isLoading,
                  expression: "isLoading",
                },
              ],
              staticClass: "selection-box mt20",
            },
            [
              _c("el-alert", {
                attrs: { title: "授权书信息", closable: false, type: "info" },
              }),
              _c(
                "div",
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.tableData, border: "" },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "docName", label: "名称" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "statusName",
                          label: "状态",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { fixed: "right", label: "操作" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.status == 6
                                    ? _c(
                                        "DownloadFile",
                                        {
                                          attrs: {
                                            "file-id": scope.row.fileId,
                                          },
                                        },
                                        [_vm._v("合同下载")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2739811931
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "sign_img" }, [
      _c("img", {
        attrs: { src: require("@/assets/icon/icon_sign.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }