var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user" },
    [
      _c(
        "basic-container",
        [
          _c(
            "avue-crud",
            {
              ref: "crud",
              attrs: {
                option: _vm.option,
                page: _vm.page,
                "table-loading": _vm.listLoading,
                "before-open": _vm.handleOpenBefore,
                data: _vm.list,
                "cell-class-name": _vm.handleCellClassName,
                search: _vm.search,
              },
              on: {
                "update:page": function ($event) {
                  _vm.page = $event
                },
                "update:search": function ($event) {
                  _vm.search = $event
                },
                "on-load": _vm.getList,
                "size-change": _vm.sizeChange,
                "current-change": _vm.currentChange,
                "search-change": _vm.handleFilter,
                "refresh-change": _vm.handleRefreshChange,
                "row-update": _vm.update,
                "row-save": _vm.create,
                "selection-change": _vm.selectionChange,
              },
              scopedSlots: _vm._u([
                {
                  key: "flowTimeSearch",
                  fn: function (scope) {
                    return [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          "value-format": "yyyy-MM-dd",
                        },
                        model: {
                          value: scope.search.flowTime,
                          callback: function ($$v) {
                            _vm.$set(scope.search, "flowTime", $$v)
                          },
                          expression: "scope.search.flowTime",
                        },
                      }),
                    ]
                  },
                },
                {
                  key: "menu",
                  fn: function (scope) {
                    return [
                      scope.row.status == 1
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary", icon: "el-icon-edit" },
                              on: {
                                click: function ($event) {
                                  return _vm.resetTrade(scope.row, scope.index)
                                },
                              },
                            },
                            [_vm._v("重置")]
                          )
                        : _vm._e(),
                      scope.row.status == 0
                        ? _c("el-button", {
                            attrs: { type: "primary", icon: "el-icon-edit" },
                            on: {
                              click: function ($event) {
                                return _vm.handleUpdate(scope.row, scope.index)
                              },
                            },
                          })
                        : _vm._e(),
                      scope.row.status == 0
                        ? _c("el-button", {
                            attrs: { type: "danger", icon: "el-icon-delete" },
                            on: {
                              click: function ($event) {
                                return _vm.deletes(scope.row, scope.index)
                              },
                            },
                          })
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "inOut",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-tag",
                        {
                          attrs: {
                            effect: "dark",
                            type:
                              scope.row.inOut === "1" ? "success" : "danger",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(scope.row.inOut === "1" ? "收入" : "支出")
                          ),
                        ]
                      ),
                    ]
                  },
                },
                {
                  key: "status",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-tag",
                        {
                          attrs: {
                            effect: scope.row.status === "1" ? "dark" : "plain",
                            type: "warning",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(scope.row.status === "1" ? "确认" : "未确认")
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.form,
                callback: function ($$v) {
                  _vm.form = $$v
                },
                expression: "form",
              },
            },
            [
              _c(
                "template",
                { slot: "menuLeft" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      attrs: { type: "primary", icon: "el-icon-add" },
                      on: {
                        click: function ($event) {
                          return _vm.$refs.crud.rowAdd()
                        },
                      },
                    },
                    [_vm._v("手工录入\n        ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-upload" },
                      on: { click: _vm.handleExport },
                    },
                    [_vm._v("导出")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      attrs: { type: "success", icon: "el-icon-message" },
                      on: { click: _vm.handleTemplate },
                    },
                    [_vm._v("模板下载\n        ")]
                  ),
                  _c("dict-upload-process", {
                    attrs: {
                      "type-code": "F0403",
                      "biz-id": "123456",
                      "btn-name": "上传流水",
                      "req-url": "/cap/accountFlow/upload",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.onFileChange($event, "fileIds")
                      },
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      staticClass: "light-blue",
                      attrs: { type: "success", icon: "el-icon-postcard" },
                      on: {
                        click: function ($event) {
                          return _vm.approve("AL001")
                        },
                      },
                    },
                    [_vm._v("项目投放")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "light-blue",
                      attrs: { type: "success", icon: "el-icon-postcard" },
                      on: {
                        click: function ($event) {
                          return _vm.approve("AL002")
                        },
                      },
                    },
                    [_vm._v("项目回款")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "light-yellow",
                      attrs: { type: "success", icon: "el-icon-bank-card" },
                      on: {
                        click: function ($event) {
                          return _vm.approve("AL003")
                        },
                      },
                    },
                    [_vm._v("银行借款")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "light-yellow",
                      attrs: { type: "success", icon: "el-icon-bank-card" },
                      on: {
                        click: function ($event) {
                          return _vm.approve("AL004")
                        },
                      },
                    },
                    [_vm._v("银行借款还本付息")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger", icon: "el-icon-edit" },
                      on: {
                        click: function ($event) {
                          return _vm.approve("AL005")
                        },
                      },
                    },
                    [_vm._v("废止")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger", icon: "el-icon-edit" },
                      on: {
                        click: function ($event) {
                          return _vm.synTrade()
                        },
                      },
                    },
                    [_vm._v("同步流水")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "light-blue",
                      attrs: { type: "success", icon: "el-icon-postcard" },
                      on: {
                        click: function ($event) {
                          return _vm.mergeTrade()
                        },
                      },
                    },
                    [_vm._v("合并流水")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }