var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "shop-add-container" }, [
    _c(
      "div",
      { staticClass: "selection-box" },
      [
        _c(
          "el-steps",
          {
            staticClass: "mb20",
            attrs: {
              active: _vm.active,
              "finish-status": "success",
              simple: "",
            },
          },
          [
            _c("el-step", {
              attrs: { title: _vm.shopFormName, icon: "el-icon-picture" },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "selection-box" },
          [
            _c(
              "el-form",
              {
                ref: "shopFormRef",
                staticClass: "cForm",
                attrs: {
                  model: _vm.shopForm,
                  rules: _vm.shopRules,
                  "label-position": "right",
                  "label-width": "160px",
                  size: "small",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "mt10 inner-box" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "所属企业", prop: "enterId" } },
                      [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              effect: "dark",
                              content: "若所属企业未登记，请登记您的企业",
                              placement: "right",
                            },
                          },
                          [
                            _c("DictSelectSingle", {
                              staticStyle: { width: "80%" },
                              attrs: {
                                url: "cust/enter/queryEnterDict",
                                disabled: _vm.disabled,
                                placeholder: "请选择企业",
                              },
                              on: { change: _vm.handleChangeCompany },
                              model: {
                                value: _vm.shopForm.enterId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.shopForm, "enterId", $$v)
                                },
                                expression: "shopForm.enterId",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "ml20",
                            attrs: { type: "primary" },
                            on: { click: _vm.handleReprotNewCompany },
                          },
                          [_vm._v("登记新企业")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "所属平台", prop: "liveStreamPlat" } },
                      [
                        _c("DictSelect", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            disabled: _vm.disabled,
                            placeholder: "请选择店铺类型",
                            no: "D000101",
                          },
                          on: { change: _vm.changeLiveStreamPlat },
                          model: {
                            value: _vm.shopForm.liveStreamPlat,
                            callback: function ($$v) {
                              _vm.$set(_vm.shopForm, "liveStreamPlat", $$v)
                            },
                            expression: "shopForm.liveStreamPlat",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "店铺名称", prop: "name" } },
                      [
                        _c("el-input", {
                          attrs: {
                            maxlength: "40",
                            placeholder: "请输入店铺名称",
                            disabled: _vm.disabled,
                          },
                          model: {
                            value: _vm.shopForm.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.shopForm, "name", $$v)
                            },
                            expression: "shopForm.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "店主账户ID(16位)",
                          prop: "shopkeeperId",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            maxlength: "40",
                            placeholder: "请输入店主账户Id",
                            disabled: _vm.disabled,
                          },
                          model: {
                            value: _vm.shopForm.shopkeeperId,
                            callback: function ($$v) {
                              _vm.$set(_vm.shopForm, "shopkeeperId", $$v)
                            },
                            expression: "shopForm.shopkeeperId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "店铺ID", prop: "shopCode" } },
                      [
                        _c("el-input", {
                          attrs: {
                            maxlength: "30",
                            placeholder: "请输入店铺编码",
                            disabled: _vm.disabled,
                          },
                          model: {
                            value: _vm.shopForm.shopCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.shopForm, "shopCode", $$v)
                            },
                            expression: "shopForm.shopCode",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "店主手机号", prop: "shopkeeperPhone" },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            maxlength: "11",
                            disabled: _vm.disabled,
                            placeholder: "请输入店主手机号",
                          },
                          model: {
                            value: _vm.shopForm.shopkeeperPhone,
                            callback: function ($$v) {
                              _vm.$set(_vm.shopForm, "shopkeeperPhone", $$v)
                            },
                            expression: "shopForm.shopkeeperPhone",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "店铺后台截图", prop: "fileShopId" } },
                      [
                        _c("DictUpload", {
                          attrs: {
                            type: "image",
                            limit: 2,
                            "type-code": "F0102",
                            "biz-id": "123456",
                            tips: "提示：请上传.jpg,jpeg,.png,.webp格式图片，大小不超过2M",
                            disabled: _vm.disabled,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onFileChange($event, "fileShopId")
                            },
                          },
                          model: {
                            value: _vm.shopForm.fileShopId,
                            callback: function ($$v) {
                              _vm.$set(_vm.shopForm, "fileShopId", $$v)
                            },
                            expression: "shopForm.fileShopId",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "tc mt30" },
      [
        _c(
          "el-button",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.submiting,
                expression: "submiting",
              },
            ],
            attrs: { type: "primary" },
            on: { click: _vm.saveShopInfo },
          },
          [_vm._v("\n      保 存\n    ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }