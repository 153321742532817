<template>
  <div class="mz-layout-section">
    <div class="tableList_title">订单明细</div>
    <el-divider />
    <div class="tableList_crud">
      <avue-crud
        ref="crud"
        v-model="form"
        :option="option"
        :page.sync="page"
        :table-loading="listLoading"
        :before-open="handleOpenBefore"
        :data="list"
        @on-load="getList"
        @size-change="sizeChange"
        @current-change="currentChange"
        @search-change="handleFilter"
        @refresh-change="handleRefreshChange"
        @row-update="update"
        @row-save="create"
      >
        <template slot="menuLeft" />

        <template
          slot="menu"
          slot-scope="scope"
        />
      </avue-crud>
    </div>
    <!--详情-->
    <el-dialog
      title="融资单"
      :visible.sync="dialogVisible"
      width="95%"
      custom-class="pub_dialog"
    >
      <page-details v-if="fileId" :modal-data="fileId" />
    </el-dialog>
  </div>

</template>

<script>
import { doGet, doPost, doDelet, doPut } from '@/router/axios';
import { mapGetters } from 'vuex';
import pageDetails from './components/details';

export default {
  name: 'TableUser',
  components: { pageDetails },
  data() {
    return {
      shopId: '',
      option: tableOption,
      defaultProps: {
        label: 'name',
        value: 'id'
      },
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条,
        isAsc: false // 是否倒序
      },
      list: [],
      listLoading: true,
      form: {},
      dialogVisible: false,
      fileId: ''
    };
  },
  computed: {

  },
  watch: {

  },
  created() {
    this.shopId = this.$route.query.shopId;
    this.applyDate = this.$route.query.applyDate;
  },
  methods: {
    getList(page, params) {
      if (params == undefined || params == null || params == '') {
        params = {};
      }
      this.listLoading = true;
      params.current = page.currentPage - 1;
      params.size = page.pageSize;
      params.shopId = this.shopId;
      doPost('/shop/goodsOrder/applyPage', params).then(res => {
        this.list = res.data.data.dataList;
        this.page.total = res.data.data.totalElements;
        this.listLoading = false;
      });
    },
    sizeChange(size) {
      this.page.pageSize = size;
    },
    currentChange(current) {
      this.page.currentPage = current;
    },
    handleFilter(param, done) {
      this.page.currentPage = 1;
      this.getList(this.page, param);
      done();
    },
    handleRefreshChange() {
      this.getList(this.page);
    },
    handleOpenBefore(show, type) {
      window.boxType = type;
      // 若是编辑、查看回显名称
      if (['edit', 'views'].includes(type)) {

      } else if (type === 'add') {

      }
      show();
    },
    handleUpdate(row, index) {
      this.$refs.crud.rowEdit(row, index);
    },
    handleDetails(row, index) {
      console.log('row.id' + row.id);
      this.fileId = row.id;
      this.dialogVisible = true;
    },
    create() {
      this.$router.push({ path: '/loan/shoploan' });
    },
    update() {}
  }
};

export const tableOption = {
  border: false,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menu: false,
  menualign: 'left',
  menuWidth: 150,
  searchMenuSpan: 6,
  refreshBtn: false,
  columnBtn: false,
  editBtn: false,
  delBtn: false,
  align: 'left',
  addBtn: false,
 searchShowBtn:false,
 column: [

    {
      label: '商品',
      prop: 'goodsName',
      width: '400'
    },
    {
      label: '实付金额',
      prop: 'payAmt',
      width: '300'
    },
    {
      label: '可贷金额',
      prop: 'loanAmt'
    },
    {
      label: '购买日期',
      prop: 'buyTime',
      type: 'date',
      format: 'yyyy-MM-dd',
      span: 6
    }
  ]
};

</script>
<style lang="scss" scoped>
/deep/ .el-tabs__nav-scroll {
  padding-left: 10px;
}

/deep/ .el-divider {
  background-color: #ecedef;
}
/deep/ .el-divider--horizontal {
  margin: 12px 0 24px;
}
.my-invest-item {
  li {
    float: left;
  }

  .ico {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    display: inline-block;
    background-image: url('/img/icon/centre-icon.png');
    vertical-align: middle;
  }

  div {
    border: 1px solid #efefef;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 0 1px rgba(0, 0, 0, .1);
    margin-right: 20px;
  }
}

.invest-ico-1 {
  background-position: -624px -78px;
}

.invest-ico-2 {
  background-position: -624px -104px;
}

.invest-ico-3 {
  background-position: -624px -130px;
}

.invest-ico-4 {
  background-position: -558px -26px;
}
/deep/ .el-dialog__body {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
</style>
