<template>
  <div>
    <div class="selection-box">
      <el-form ref="cForm" :model="cForm" :rules="vRules" :label-position="'right'" label-width="120px" size="small">
        <el-alert title="监管账户绑定" :closable="false" type="info" class="mt10" />
        <div class="mt10 inner-box">
          <el-form-item label="银行卡性质" prop="cardNature">
            <dict-select v-model="cForm.cardNature" no="D000110" placeholder="请选择银行卡性质" @change="cardNature_change" />
          </el-form-item>
          <el-form-item label="户名" prop="bankUserName">
            <el-input v-model="cForm.bankUserName" placeholder="请输入户名" readonly />
          </el-form-item>

          <el-form-item label="所属银行" prop="bankCode">
            <dict-select v-model="cForm.bankCode" no="D000109" placeholder="请选择所属银行" @change="bankCode_change" />
          </el-form-item>
          <el-form-item label="选择支行：" prop="provinceCode">
            <DictSelectSingle v-model="cForm.provinceCode" :clearable="false" url="/cap/capBankCustInfo/province" placeholder="请选择省份" style="width: 90px" @change="province_change" /> &nbsp;
            <DictSelectSingle v-if="cForm.cityCode || !cityFlag" :key="timer" v-model="cForm.cityCode" :url="cForm.cityCode ? `/cap/capBankCustInfo/city/${cForm.provinceCode}` : cityUrl" :clearable="false" placeholder="请选择市" style="width: 90px" @change="city_change" /> &nbsp;
            <DictSelectSingle v-if="cForm.bankSubNo || !bankFlag" :key="timer2" v-model="cForm.bankSubNo" :url="cForm.bankSubNo ? `/cap/capBankCustInfo/bank/${cForm.cityCode}/${cForm.bankCode}` : bankUrl" placeholder="请选择支行" :clearable="false" style="width: 200px" @change="bank_change" />
          </el-form-item>
          <el-form-item label="银行卡号" prop="bankCard">
            <el-input v-model="cForm.bankCard" placeholder="请输入银行卡号" />
          </el-form-item>
          <el-form-item label="银行支行号" prop="bankCard">
            <el-input v-model="cForm.bankSubNo" placeholder="请输入银行支行号" readonly />
          </el-form-item>
          <el-form-item label="银行手机号" prop="mobilePhone">
            <el-input v-model="cForm.mobilePhone" placeholder="请输入银行所留手机号" />
          </el-form-item>
          <el-form-item prop="agreementChecked">
            <el-checkbox v-model="cForm.agreementChecked" />本人已仔细阅读并完全理解、接受如下文件，接受该等文件的约束《资金监管服务协议》、《保理合同》
          </el-form-item>
        </div>
        <div class="tc">
          <el-button type="primary" size="medium" :loading="loading" :disabled="!cForm.agreementChecked" @click="saveCard()">绑定</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import DictSelect from '@/views/components/DictSelect.vue';
import DictSelectSingle from '@/views/components/DictSelectSingle';
import { doGet, doPost } from '@/router/axios';

export default {
  name: 'CompanyIndex',
  components: {
    DictSelect,
    DictSelectSingle
  },
  props: {
    eId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      enterId: '',
      enterName: '',
      legalName: '',
      accType: '',
      cForm: {
        id: '',
        shopkeeperId: '', // 营业期限
        name: '',
        shopCode: '',
        liveStreamPlat: '',
        shopkeeperPhone: '',
        fileUploadIds: [],
        agreementChecked: false
      },
      cityFlag: true,
      cityUrl: '',
      bankUrl: '',
      bankFlag: true,
      timer: '',
      timer2: '',
      vRules: {
        accType: [{ required: true, trigger: 'change', message: '请选择账户类型' }],
        bankCode: [{ required: true, trigger: 'blur', message: '请选择所属银行' }],
        bankUserName: [{ required: true, trigger: 'blur', message: '请填写户名' }],
        bankCard: [{ required: true, trigger: 'blur', message: '请填写银行卡号' }],
        mobilePhone: [{ required: true, trigger: 'blur', message: '请填写银行所留手机' }],
        cardNature: [{ required: true, trigger: 'blur', message: '请选择银行卡性质' }],
        bankSubNo: [{ required: true, trigger: 'blur', message: '请输入支行号' }],
        provinceCode: [{ required: true, trigger: 'change', message: '请选择省份' }],
        fileUploadIds: [{ required: true, trigger: 'blur', message: '请上传银行卡正反面' }],
        agreementChecked: [{ required: true, message: '请勾选协议', trigger: 'change' }]
      }
    };
  },
  mounted() {
  },
  created() {
    this.enterId = this.$route.query.enterId;
    this.accType = this.$route.query.accType;
    this.initData();
  },
  methods: {
    accType_change(val) {
      this.cForm.accType = val;
    },
    cardNature_change(val) {
      this.cForm.cardNature = val;
      if (val == 1) {
        this.cForm.bankUserName = this.legalName;
      } else {
        this.cForm.bankUserName = this.enterName;
      }
    },
    bankCode_change(val, name) {
      this.cForm.bankCode = val;
      this.cForm.bankName = name;
    },
    onFileChange(ids = [], key) {
      this.$refs.cForm.validateField(key);
    },
    onEndupDateFovereverChange(checked) {
      this.cForm.endupDate = checked ? '2099-12-31' : '';
      this.$refs.cForm.validateField('endupDate');
    },
    initData() {
      doGet('/cust/enter/getEnterprise/' + this.enterId).then(res => {
        this.loading = false;
        if (res.data.code == 200) {
          this.enterName = res.data.data.name;
          this.legalName = res.data.data.legalName;
        }
      }
      ).catch(e => {
        this.loading = false;
      });
    },
    province_change(val) {
      this.cForm.provinceCode = val;
      this.cForm.cityCode = '';
      this.cForm.bankSubNo = '';
      this.cityFlag = false;
      this.cityUrl = `/cap/capBankCustInfo/city/${val}`;
      this.timer = new Date().getTime();
      this.timer2 = new Date().getTime();
    },
    city_change(val) {
      this.cForm.cityCode = val;
      this.cForm.bankSubNo = '';
      this.bankFlag = false;
      this.bankUrl = `/cap/capBankCustInfo/bank/${val}/${this.cForm.bankCode}`;
      this.timer2 = new Date().getTime();
    },
    bank_change(val) {
      this.cForm.bankSubNo = val;
    },
    saveCard() {
      this.$refs.cForm.validate(valid => {
        if (valid) {
          this.loading = true;
          const data = {
            ...this.cForm,
            ...{
              enterId: this.enterId,
              accType: this.accType
            }
          };
          doPost('/cap/account/bindCard', data).then(res => {
            this.loading = false;
            if (res.data.code == 200) {
              this.$notify({
                title: '新增成功',
                type: 'success',
                duration: 2500
              });
              this.$router.push({ path: '/cap/index?enterId=' + this.enterId });
            } else {
              this.$notify({
                title: res.data.msg,
                type: 'error',
                duration: 2500
              });
            }
          }
          ).catch(e => {
            this.loading = false;
          });
        } else {
          return false;
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.selection-box {
  margin-top: 10px;
  .inner-box {
    width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
