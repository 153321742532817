var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticStyle: { "text-align": "center", "padding-top": "100px" } },
      [_c("h1", [_vm._v("抱歉！您没有任何权限")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }