<template>
  <div class="details-warp">
    <el-form ref="form" :model="form" label-width="80px" label-position="left">
      <div class="details-section">
        <h3 class="details-section-h3"><span>订单详情</span></h3>
        <el-row :gutter="40" class=" pl20 pr20">
          <el-col :span="8">
            <el-form-item label="编号：">
              <el-input v-model="form.no" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="账单号：">
              <el-input v-model="form.billNo" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="融资单号：">
              <el-input v-model="form.orderNo" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="还款金额：">
              <el-input v-model="form.applyAmt" disabled>
                <template slot="suffix">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="创建时间：">
              <el-input v-model="form.createTime" disabled />
            </el-form-item>
          </el-col>
        </el-row>
      </div>

      <div class="details-section mt20">
        <h3 class="details-section-h3"><span>还款明细</span></h3>
        <div class="pl20 pr20 pb20">
          <repayment-lines :bill-form="form" />
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { doGet, doPost, doDelet, doPut } from '@/router/axios';
import repaymentLines from './repaymentLines.vue';

export default {
  name: 'PageDetails',
  components: { repaymentLines },
  props: {
    modalData: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      id: '',
      form: {}
    };
  },
  watch: {
    modalData(val, oldVal) {
      console.log('modalData:' + val + '--oldVal' + oldVal);
      // 这里执行初始化方法
      this.initForm(val);
    }
  },
  mounted() {
    this.initForm(this.modalData);
  },
  methods: {
    initForm(id) {
      doGet(`/repay/` + id).then(res => {
        this.form = res.data.data;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.details-warp {
  height: calc(100vh - 300px);
  overflow: hidden;
  overflow-y: auto;
}
</style>
