var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.type === "radio"
    ? _c(
        "el-radio-group",
        {
          attrs: { disabled: "" },
          model: {
            value: _vm.selectKey,
            callback: function ($$v) {
              _vm.selectKey = $$v
            },
            expression: "selectKey",
          },
        },
        _vm._l(_vm.zones, function (item) {
          return _c("el-radio", { key: item.id, attrs: { label: item.id } }, [
            _vm._v(_vm._s(item.label)),
          ])
        }),
        1
      )
    : _vm.type === "checkbox"
    ? _c(
        "el-checkbox-group",
        {
          attrs: { disabled: "" },
          model: {
            value: _vm.selectKey,
            callback: function ($$v) {
              _vm.selectKey = $$v
            },
            expression: "selectKey",
          },
        },
        [
          _vm.isSlot
            ? [_vm._t("default", null, { zones: _vm.zones })]
            : _vm._l(_vm.zones, function (item) {
                return _c("el-checkbox", {
                  key: item.id,
                  attrs: { label: item.label },
                })
              }),
        ],
        2
      )
    : _c(
        "el-select",
        {
          staticStyle: { width: "100%" },
          attrs: {
            disabled: "",
            multiple: _vm.multiple,
            filterable: "",
            clearable: "",
            placeholder: _vm.placeholder,
            disabled: _vm.disabled,
          },
          on: { change: _vm.change },
          model: {
            value: _vm.selectKey,
            callback: function ($$v) {
              _vm.selectKey = $$v
            },
            expression: "selectKey",
          },
        },
        _vm._l(_vm.zones, function (item) {
          return _c("el-option", {
            key: item.id,
            attrs: { label: item.label, value: String(item.id) },
          })
        }),
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }