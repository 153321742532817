var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("el-divider"),
      _c(
        "el-row",
        { attrs: { gutter: 30 } },
        [
          _c("el-col", { attrs: { span: 6 } }, [
            _c("div", { staticClass: "grid-card bg-blue" }, [
              _c("div", { staticClass: "title" }, [_vm._v("今日收益")]),
              _c("div", { staticClass: "number" }, [_vm._v("45278.02元")]),
            ]),
          ]),
          _c("el-col", { attrs: { span: 6 } }, [
            _c("div", { staticClass: "grid-card bg-red" }, [
              _c("div", { staticClass: "title" }, [_vm._v("近30日放款")]),
              _c("div", { staticClass: "number" }, [_vm._v("278.02万元")]),
            ]),
          ]),
          _c("el-col", { attrs: { span: 6 } }, [
            _c("div", { staticClass: "grid-card bg-orange" }, [
              _c("div", { staticClass: "title" }, [_vm._v("累计放款")]),
              _c("div", { staticClass: "number" }, [_vm._v("578.02万元")]),
            ]),
          ]),
          _c("el-col", { attrs: { span: 6 } }, [
            _c("div", { staticClass: "grid-card bg-orange" }, [
              _c("div", { staticClass: "title" }, [_vm._v("本月放款")]),
              _c("div", { staticClass: "number" }, [_vm._v("578.02万元")]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "grid-content" },
        [
          _c(
            "avue-crud",
            {
              ref: "crud",
              attrs: {
                option: _vm.option,
                page: _vm.page,
                "table-loading": _vm.listLoading,
                data: _vm.list,
              },
              on: {
                "update:page": function ($event) {
                  _vm.page = $event
                },
                "on-load": _vm.getList,
                "size-change": _vm.sizeChange,
                "current-change": _vm.currentChange,
                "search-change": _vm.handleFilter,
                "search-reset": _vm.resetFilter,
              },
              scopedSlots: _vm._u([
                {
                  key: "menu",
                  fn: function ({ row }) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-edit", type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.showModal(row, "edit")
                            },
                          },
                        },
                        [_vm._v("\n          配置费率\n        ")]
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "menuRight" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      attrs: { type: "primary", icon: "el-icon-plus" },
                      on: {
                        click: function ($event) {
                          return _vm.handleSave()
                        },
                      },
                    },
                    [_vm._v("添加新渠道")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-standard channel-config-rate",
          attrs: {
            title: "新增渠道",
            width: "1000px",
            top: "100px",
            visible: _vm.isvisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isvisible = $event
            },
          },
        },
        [_c("channelAdd")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }