<template>
  <div class="selection-box">
    <el-alert title="企业信息" :closable="false" type="info" />
    <el-form ref="cForm" :model="cForm" :rules="vRules" :label-position="'left'" label-width="150px" size="small" class="cForm">
      <div class="mt10 inner-box">
        <el-form-item label="营业执照" prop="fileBusinessId">
          <dict-upload-info
            v-model="cForm.fileBusinessId"
            type="image"
            :limit="1"
            type-code="F0202"
            biz-id="123456"
            url="cust/enter/upload"
            :tips="'提示：请上传.jpg,jpeg,.png,.webp格式图片，大小不超过2M'"
            :disabled="disabled"
            @change="onFileChange($event, 'fileBusinessId')"
            @getOcrInfo="handleSetOcrInfo"
          />
        </el-form-item>
        <el-form-item label="企业类型" prop="enterType">
          <el-radio-group v-model="cForm.enterType" :disabled="disabled">
            <el-radio label="1">普通企业</el-radio>
            <el-radio label="2">个体工商户</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="公司名称" prop="name">
          <el-input v-model="cForm.name" maxlength="40" placeholder="请输入公司名称" style="width: 350px" :disabled="disabled" />
        </el-form-item>
        <el-form-item label="营业执照号" prop="creditCode">
          <el-input v-model="cForm.creditCode" maxlength="20" placeholder="一般为18/15位数字或字母组合" style="width: 350px" :disabled="disabled" />
        </el-form-item>
        <el-form-item label="营业期限" prop="expireDateAttr">
          <template>
            <el-date-picker
              v-if="!cForm.expireDateAttrFlag"
              v-model="cForm.expireDateAttr"
              :disabled="disabled"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="起始日期"
              end-placeholder="结束日期"
              class="mr20"
            />
            <el-checkbox v-model="cForm.expireDateAttrFlag" :disabled="disabled" @change="handleChangeCompanyExpire">无固定期限</el-checkbox>
          </template>
        </el-form-item>
        <el-form-item label="注册地址" prop="regitAddress">
          <el-cascader v-model="cForm.provinces" :disabled="disabled" :options="options" placeholder="请选择省市区" class="fl" />
          <el-input v-model="cForm.regitAddress" maxlength="120" :disabled="disabled" aria-placeholder="请输入详细地址" class="ml10 fl" style="width: 60%" />
        </el-form-item>
      </div>

      <el-divider class="ml15 mr15" />

      <!--法人信息-->
      <div class="sub_title">法人信息</div>
      <div class="ml15 mb25"><i class="el-icon-warning fc-blue-t f16" /> 请正确填写法人信息</div>
      <div class="mt10 inner-box">
        <el-form-item label="身份证（人像面）" prop="legalFilePeopleId">
          <dict-upload-info
            v-model="cForm.legalFilePeopleId"
            :disabled="disabled"
            type="image"
            :limit="1"
            type-code="F0204"
            biz-id="123456"
            url="cust/enter/upload"
            @change="onFileChange($event, 'legalFilePeopleId')"
            @getOcrInfo="handleSetOcrInfo"
          />
        </el-form-item>
        <el-form-item label="身份证（国徽面）" prop="legalFileReverseId">
          <dict-upload-info
            v-model="cForm.legalFileReverseId"
            :disabled="disabled"
            type="image"
            :limit="1"
            type-code="F0203"
            biz-id="123456"
            url="cust/enter/upload"
            @change="onFileChange($event, 'legalFileReverseId')"
          />
        </el-form-item>
        <el-form-item label="法人姓名：" prop="legalName">
          <el-input v-model="cForm.legalName" maxlength="20" :disabled="disabled" placeholder="请输入法人姓名" style="width: 350px" />
        </el-form-item>
        <el-form-item label="法人身份证号：" prop="legalIdCard">
          <el-input v-model="cForm.legalIdCard" maxlength="18" :disabled="disabled" placeholder="一般为18/15位数字或字母组合" style="width: 350px" />
        </el-form-item>
        <el-form-item label="法人手机号：" prop="legalPhone">
          <el-input v-model="cForm.legalPhone" maxlength="11" :disabled="disabled" placeholder="请输入法人手机号" style="width: 350px" />
        </el-form-item>
        <el-form-item label="法人邮箱：" prop="legalEmail">
          <el-input v-model="cForm.legalEmail" maxlength="30" :disabled="disabled" placeholder="请输入法人邮箱" style="width: 350px" />
        </el-form-item>
        <el-form-item label="法人身份证有效日期" prop="leagalDateAttr">
          <template>
            <el-date-picker
              v-model="cForm.leagalDateAttr"
              :disabled="disabled"
              value-format="yyyy-MM-dd"
              type="daterange"
              start-placeholder="起始日期"
              end-placeholder="结束日期"
            />
            <span style="margin-right: 30px;" />
          </template>
        </el-form-item>
      </div>

      <el-divider class="ml15 mr15" />
      <!--授权人信息-->
      <div class="sub_title">授权人信息 <el-checkbox v-model="checkedSameAuth" :disabled="disabled" label="(和法人为同一人)" class="ml10" @change="handleChangeSamePerson(1)" /></div>
      <div v-if="!checkedSameAuth">
        <div class="ml15 mb25"><i class="el-icon-warning fc-blue-t f16" /> 请正确填写授权人信息</div>
        <div class="mt10 inner-box">
          <el-form-item label="身份证（人像面）" prop="authFilePeopleId">
            <dict-upload-info
              v-model="cForm.authFilePeopleId"
              :disabled="disabled"
              type="image"
              :limit="1"
              type-code="F0206"
              biz-id="123456"
              url="cust/enter/upload"
              @change="onFileChange($event, 'authFilePeopleId')"
              @getOcrInfo="handleSetOcrInfo"
            />
          </el-form-item>
          <el-form-item label="身份证（国徽面）" prop="authFileReverseId">
            <dict-upload-info
              v-model="cForm.authFileReverseId"
              :disabled="disabled"
              type="image"
              :limit="1"
              type-code="F0205"
              biz-id="123456"
              url="cust/enter/upload"
              @change="onFileChange($event, 'authFileReverseId')"
            />
          </el-form-item>
          <el-form-item label="授权人姓名：" prop="authName">
            <el-input v-model="cForm.authName" maxlength="30" :disabled="disabled" placeholder="请输入授权人姓名" style="width: 350px" />
          </el-form-item>
          <el-form-item label="授权人身份证号：" prop="authIdCard">
            <el-input v-model="cForm.authIdCard" maxlength="18" :disabled="disabled" placeholder="一般为18/15位数字或字母组合" style="width: 350px" />
          </el-form-item>
          <el-form-item label="授权人手机号：" prop="authPhone">
            <el-input v-model="cForm.authPhone" maxlength="11" :disabled="disabled" placeholder="请输入授权人手机号" style="width: 350px" />
          </el-form-item>
          <el-form-item label="授权人邮箱：" prop="authEmail">
            <el-input v-model="cForm.authEmail" maxlength="30" :disabled="disabled" placeholder="请输入授权人邮箱" style="width: 350px" />
          </el-form-item>
        </div>
      </div>

      <el-divider class="ml15 mr15" />
      <!--实控人信息-->
      <div class="sub_title">实控人信息 <el-checkbox v-model="checkedSame" :disabled="disabled" label="(和法人为同一人)" class="ml10" @change="handleChangeSamePerson(2)" /></div>
      <div v-if="!checkedSame">
        <div class="ml15 mb25"><i class="el-icon-warning fc-blue-t f16" /> 请正确填写实控人信息</div>
        <div class="mt10 inner-box">
          <el-form-item label="身份证（人像面）" prop="actualFilePeopleId">
            <dict-upload-info
              v-model="cForm.actualFilePeopleId"
              :disabled="disabled"
              type="image"
              :limit="1"
              type-code="F0207"
              biz-id="123456"
              url="cust/enter/upload"
              @change="onFileChange($event, 'actualFilePeopleId')"
              @getOcrInfo="handleSetOcrInfo"
            />
          </el-form-item>
          <el-form-item label="身份证（国徽面）" prop="actualFileReverseId">
            <dict-upload-info
              v-model="cForm.actualFileReverseId"
              :disabled="disabled"
              type="image"
              :limit="1"
              type-code="F0208"
              biz-id="123456"
              url="cust/enter/upload"
              @change="onFileChange($event, 'actualFileReverseId')"
            />
          </el-form-item>
          <el-form-item label="实控人姓名：" prop="actualName">
            <el-input v-model="cForm.actualName" maxlength="20" :disabled="disabled" placeholder="请输入法人姓名" style="width: 350px" />
          </el-form-item>
          <el-form-item label="实控人身份证号：" prop="actualIdCard">
            <el-input v-model="cForm.actualIdCard" maxlength="18" :disabled="disabled" placeholder="一般为18/15位数字或字母组合" style="width: 350px" />
          </el-form-item>
          <el-form-item label="实控人手机号：" prop="actualPhone">
            <el-input v-model="cForm.actualPhone" maxlength="11" :disabled="disabled" placeholder="请输入法人手机号" style="width: 350px" />
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import DictUploadInfo from '@/views/components/DictUploadInfo.vue';
import { doGet, doPost } from '@/router/axios';
import { regionData } from 'element-china-area-data';
import { setDateFormat } from '@/utils/date';
export default defineComponent({
  name: 'companyInfo',
  components: { DictUploadInfo },
  props: {
    eId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      options: regionData, // 选择格式
      disabled: false,
      checkedSame: false,
      checkedSameAuth: false, // 授权人和法人信息相同标志
      cForm: {
        provinces: [],
        enterId: '',
        id: '',
        enterType: '',
        creditCode: '',
        leagalDateAttr: [],
        expireDateAttr: [], // 营业期限
        companyType: '',
        name: '',
        businessLicense: '',
        regitAddressPre: [],
        regitAddress: '',
        engageAddressPre: [],
        expireDate: [],
        leagalDate: [],
        legalCardExpire: '',
        legalName: '',
        legalIdCard: '',
        legalPhone: '',
        legalEmail: '',
        fileCardReverseIdStr: [],
        fileCardFrontIdStr: [],
        endupDateFoverever: Boolean, // 永久有效，传'2099-12-31'
        expireDateAttrFlag: false, // 公司期限，true为有固定期限
        authFilePeopleId: [],
        authFileReverseId: [],
        authName: '',
        authIdCard: '',
        authPhone: '',
        authEmail: '',
        actualFilePeopleId: [],
        actualFileReverseId: [],
        actualName: '',
        actualIdCard: '',
        actualPhone: ''
      },
      ocrInfo: {},
      vRules: {
        fileBusinessId: [{ required: true, trigger: 'change', message: '请上传营业执照' }],
        enterType: [{ required: true, trigger: 'blur', message: '请选择企业类型' }],
        name: [{ required: true, trigger: 'blur', message: '请填写企业名称' }],
        companyType: [{ required: true, message: '请选择企业类型', trigger: 'change' }],
        creditCode: [{ required: true, trigger: 'blur', message: '请填写营业执照号' }],
        expireDateAttr: [{ required: true, trigger: 'blur', message: '请选择营业期限' }],
        leagalDate: [{ required: true, trigger: 'blur', message: '请选择证件有效期' }],
        provinces: [{ required: true, trigger: 'blur', message: '请选择注册地址' }],
        regitAddress: [{ required: true, trigger: 'blur', message: '请填写注册地址' }],
        legalFilePeopleId: [{ required: true, trigger: 'blur', message: '请上传法人身份证人像页' }],
        legalFileReverseId: [{ required: true, trigger: 'blur', message: '请上传法人身份证国徽页' }],
        legalName: [{ required: true, trigger: 'blur', message: '请填写法人姓名' }],
        legalIdCard: [{ required: true, trigger: 'blur', message: '请填写法人身份证号' }],
        legalPhone: [{ required: true, trigger: 'blur', message: '请填写法人手机号' }],
        legalEmail: [{ required: true, trigger: 'blur', message: '请填写法人邮箱' }],
        leagalDateAttr: [{ required: true, trigger: 'blur', message: '请选择法人身份证有效日期' }],
        authFilePeopleId: [{ required: true, trigger: 'blur', message: '请上传授权人身份证人像页' }],
        authFileReverseId: [{ required: true, trigger: 'blur', message: '请上传授权人身份证国徽页' }],
        authName: [{ required: true, trigger: 'blur', message: '请填写授权人姓名' }],
        authIdCard: [{ required: true, trigger: 'blur', message: '请填写授权人身份证号' }],
        authPhone: [{ required: true, trigger: 'blur', message: '请填写授权人手机号' }],
        authEmail: [{ required: true, trigger: 'blur', message: '请填写授权人邮箱' }],
        actualFilePeopleId: [{ required: true, trigger: 'blur', message: '请上传实控人身份证人像页' }],
        actualFileReverseId: [{ required: true, trigger: 'blur', message: '请上传实控人身份证国徽页' }],
        actualName: [{ required: true, trigger: 'blur', message: '请填写实控人姓名' }],
        actualIdCard: [{ required: true, trigger: 'blur', message: '请填写实控人身份证号' }],
        actualPhone: [{ required: true, trigger: 'blur', message: '请填写实控人手机号' }]
      }
    };
  },
  created() {
    if (!this.validatenull(this.eId)) { // 判断enterId是否为空
      this.enterId = this.eId;
    }
    this.initData();
  },
  watch: {
    'cForm.fileBusinessId': function(newVal, oldVal) {
      // 企业类型
      if (this.validatenull(this.cForm.enterType)) {
        this.cForm.enterType = this.ocrInfo.enterType;
      }
      // 公司名称
      if (this.validatenull(this.cForm.name)) {
        this.cForm.name = this.ocrInfo.name;
      }
      // 营业执照号
      if (this.validatenull(this.cForm.creditCode)) {
        this.cForm.creditCode = this.ocrInfo.creditCode;
      }
      // 营业期限
      if (this.validatenull(this.cForm.expireDateAttr) || this.cForm.expireDateAttr[0] === '') {
        const startDate = setDateFormat(this.ocrInfo.estiblishTime);
        const endDate = setDateFormat(this.ocrInfo.validTime);
        this.cForm.expireDateAttr = [startDate, endDate];
      }
      // 注册地址
      if (this.validatenull(this.cForm.regitAddress)) {
        this.cForm.regitAddress = this.ocrInfo.regLocation;
      }
    },
    'cForm.legalFilePeopleId': function(newVal, oldVal) {
      // 法人姓名
      if (this.validatenull(this.cForm.legalName)) {
        this.cForm.legalName = this.ocrInfo.name;
        this.$refs['cForm'].clearValidate('legalName');
      }
      // 法人身份证号
      if (this.validatenull(this.cForm.legalIdCard)) {
        this.cForm.legalIdCard = this.ocrInfo.num;
        this.$refs['cForm'].clearValidate('legalIdCard');
      }
      this.ocrInfo = [];
    },
    'cForm.legalFileReverseId': function(newVal, oldVal) {
      // 法人身份证有效日期
      if (this.validatenull(this.cForm.leagalDateAttr) || this.cForm.leagalDateAttr[0] === '') {
        const startDate = setDateFormat(this.ocrInfo.start_date);
        const endDate = setDateFormat(this.ocrInfo.end_date);
        this.cForm.leagalDateAttr = [startDate, endDate];
      }
    },
    'cForm.authFilePeopleId': function(newVal, oldVal) {
      if (newVal === oldVal) return;
      // 授权人姓名
      if (this.validatenull(this.cForm.authName)) {
        this.cForm.authName = this.ocrInfo.name;
        this.$refs['cForm'].clearValidate('authName');
      }
      // 授权人身份证号
      if (this.validatenull(this.cForm.authIdCard)) {
        this.cForm.authIdCard = this.ocrInfo.num;
        this.$refs['cForm'].clearValidate('authIdCard');
      }
      this.ocrInfo = [];
    },
    'cForm.actualFilePeopleId': function(newVal, oldVal) {
      if (newVal === oldVal) return;
      // 授权人姓名
      if (this.validatenull(this.cForm.actualName)) {
        this.cForm.actualName = this.ocrInfo.name;
        this.$refs['cForm'].clearValidate('actualName');
      }
      // 授权人身份证号
      if (this.validatenull(this.cForm.actualIdCard)) {
        this.cForm.actualIdCard = this.ocrInfo.num;
        this.$refs['cForm'].clearValidate('actualIdCard');
      }
      this.ocrInfo = [];
    }
  },
  methods: {
    initData() {
      const that = this;
      const id = this.enterId;
      if (id == undefined || id == null || id == '') {
        return;
      }
      doGet('/cust/enter/getEnterprise/' + id).then(res => {
        if (res.data.code === 200) {
          that.cForm = {
            ...res.data.data,
            expireDateAttrFlag: res.data.data.expireDate === '2099-12-31',
            expireDateAttr: ['', '']
          };
          if (that.cForm.expireDate != null && that.cForm.regditDate != null) {
            that.cForm.expireDateAttr = [that.cForm.regditDate, that.cForm.expireDate];
          }
          if (that.cForm.legalIdcardStartDate != null && that.cForm.legalIdcardEndDate != null) {
            that.cForm.leagalDateAttr = [that.cForm.legalIdcardStartDate, that.cForm.legalIdcardEndDate];
          }
          if (that.cForm.isAuth === '1') {
            that.disabled = true;
          }

          // 判断授权人和法人是否一样
          if ((that.cForm.authName === that.cForm.legalName) && (that.cForm.authFilePeopleId = that.cForm.legalFilePeopleId)) {
            that.checkedSameAuth = true;
          }

          // 判断实控人和法人是否一样
          if ((that.cForm.actualName === that.cForm.legalName) && (that.cForm.actualFilePeopleId = that.cForm.legalFilePeopleId)) {
            that.checkedSame = true;
          }
        }
      }
      ).catch(e => {
      });
    },

    onFileChange(ids = [], key) {
      this.$refs.cForm.validateField(key);
    },
    handleSetOcrInfo(val) {
      this.ocrInfo = { ...val };
    },
    saveCompanyInfo() {
      if (this.checkedSameAuth) { // 授权人同法人
        this.cForm.authFilePeopleId = this.deepClone(this.cForm.legalFilePeopleId); // 身份证正面
        this.cForm.authFileReverseId = this.deepClone(this.cForm.legalFileReverseId); // 身份证反面
        this.cForm.authName = this.cForm.legalName; // 法人姓名
        this.cForm.authIdCard = this.cForm.legalIdCard; // 法人身份证号
        this.cForm.authPhone = this.cForm.legalPhone; // 法人手机号
        this.cForm.authEmail = this.cForm.legalEmail; // 法人邮箱
      }
      if (this.checkedSame) { // 实控人同法人
        this.cForm.actualFilePeopleId = this.deepClone(this.cForm.legalFilePeopleId);
        this.cForm.actualFileReverseId = this.deepClone(this.cForm.legalFileReverseId);
        this.cForm.actualName = this.cForm.legalName;
        this.cForm.actualIdCard = this.cForm.legalIdCard;
        this.cForm.actualPhone = this.cForm.legalPhone;
      }
      this.$refs.cForm.validate(valid => {
        if (valid) {
          this.loading = true;
          const data = {
            ...this.cForm,
            ...{
              expireDate: this.cForm.expireDateAttr[1],
              regditDate: this.cForm.expireDateAttr[0],
              legalIdcardStartDate: this.cForm.leagalDateAttr[0],
              legalIdcardEndDate: this.cForm.leagalDateAttr[1]
            },
            legalIdcardAddress: this.ocrInfo.address || '',
          };

          if (this.expireDateAttrFlag) {
            this.cForm.expireDate = '2099-12-31';
          }

          delete data.regitAddressPre;
          delete data.engageAddressPre;
          delete data.endupDateFoverever;
          data.id = this.enterId;
          doPost('/cust/enter/saveEnterprise', data).then(res => {
            this.loading = false;
            if (res.data.code == 200) {
              this.$emit('change', res.data.data.id);
              this.$parent.handleStepNext(); // 跳转到下一步
            } else {
              this.$notify({
                title: res.data.msg,
                type: 'error',
                duration: 2500
              });
            }
          }).catch(e => {
            this.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    /**
     * 是否为同一人
     * checkedSameAuth: 授权人是否同法人信息
     * checkedSame:     实控人是否同法人信息
     */
    handleChangeSamePerson(val) {
      if (!this.checkedSameAuth && val === 1) { // 授权人同法人
        this.cForm.authFilePeopleId = [];
        this.cForm.authFileReverseId = [];
        this.cForm.authName = '';
        this.cForm.authIdCard = '';
        this.cForm.authPhone = '';
        this.cForm.authEmail = '';
      }

      if (!this.checkedSame && val === 2) { // 实控人同法人
        this.cForm.actualFilePeopleId = [];
        this.cForm.actualFileReverseId = [];
        this.cForm.actualName = '';
        this.cForm.actualIdCard = '';
        this.cForm.actualPhone = '';
      }
    },
    /**
     * 判断有无固定期限
     * false: 无固定期限， true:有期限
     */
    handleChangeCompanyExpire(flag) {
      if (flag) {
        this.cForm.expireDateAttr = ['1900-01-01', '2099-12-31'];
      }
    }
  }
});
</script>

<style scoped lang="scss">
.selection-box {
  border: 1px solid #F2F4F7;
  /deep/ .el-alert__title {
    color: #333;
    font-weight: 700;
  }
  .cForm {
    margin: 0 30px;
  }
}

.inner-box {
  margin-left: auto;
  margin-right: auto;
}
.sub_title {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  &:before {
    content: '';
    margin-right: 10px;
    width: 4px;
    height: 18px;
    background: #2d8cf0;
    border-radius: 10px;
  }
}
/deep/ .el-divider--horizontal {
  width: auto;
}
/deep/ .el-form--label-left .el-form-item__label {
  padding-left: 15px;
}
</style>
