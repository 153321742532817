<template>
  <section class="vormela-basicLayout-content">
    <div class="rs-section">
      <div :class="statusFlag ? 'img-result img-success' : 'img-result img-fail'" />
      <div class="vormela-result-title">{{ statusFlag ? '提交成功' : '提交失败' }}</div>
      <div class="vormela-result-subtitle">{{ content }}</div>
      <div class="vormela-result-extra">
        <el-button type="primary" @click="handleClose('ruleForm')">返回</el-button>
      </div>
      <!--      <div class="vormela-result-content">-->
      <!--        <div class="title___1mmDs">-->
      <!--          <span>您提交的内容有如下错误：</span>-->
      <!--        </div>-->
      <!--        <div class="mb15">-->
      <!--          <i class="el-icon-circle-close f16 fc-red mr10"></i><span>您的账户已被冻结</span>-->
      <!--        </div>-->
      <!--        <div class="mb15">-->
      <!--          <i class="el-icon-circle-close f16 fc-red mr10"></i><span>您的账户已被冻结</span>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </section>
</template>

<script>
export default {
  name: 'ResultPage',
  data() {
    return {
      statusFlag: true,
      content: '请核对并修改以下信息后，再重新提交。'
    };
  },
  created() {
    this.statusFlag = true; // this.$route.params.status;
    this.content = '';// this.$route.params.content;
  },
  methods: {
    handleClose() {
      this.$router.$avueRouter.closeTag();
      this.$router.go(-2);
    }
  }
};
</script>

<style lang="scss" scoped>
  div.rs-container {
    display: flex;
    height: 100%;
    background-color: white;
    justify-content: center;
    align-items: center;
  }
  .rs-section {
    padding: 48px 32px;
    /*height: calc(60vh);*/
    background-color: white;
    .vormela-result-title {
      color: rgba(0,0,0,.85);
      font-size: 24px;
      line-height: 1.8;
      text-align: center;
    }
    .vormela-result-subtitle {
      color: rgba(0,0,0,.45);
      font-size: 14px;
      line-height: 1.6;
      text-align: center;
    }
    .vormela-result-extra {
      margin: 24px 0 0;
      text-align: center;
    }
    .vormela-result-content {
      margin-top: 24px;
      padding: 24px 40px;
      background-color: #fafafa;
      .title___1mmDs {
        margin-bottom: 16px;
        color: rgba(0,0,0,.85);
        font-weight: 500;
        font-size: 16px;
      }
    }
  }
</style>
