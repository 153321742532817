var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mz-layout-section shop-list" },
    [
      _c("div", { staticClass: "tableList_title" }, [_vm._v("店铺列表")]),
      _c("el-divider"),
      _c(
        "avue-crud",
        {
          ref: "crud",
          attrs: {
            option: _vm.option,
            page: _vm.page,
            "table-loading": _vm.listLoading,
            data: _vm.dataList,
          },
          on: {
            "update:page": function ($event) {
              _vm.page = $event
            },
            "on-load": _vm.getDataList,
            "size-change": _vm.sizeChange,
            "current-change": _vm.currentChange,
            "search-change": _vm.handleFilter,
            "search-reset": _vm.resetFilter,
          },
          scopedSlots: _vm._u([
            {
              key: "discountRate",
              fn: function ({ row }) {
                return [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm._f("numberToCurrency")(row.discountRate)) + "%"
                    ),
                  ]),
                ]
              },
            },
            {
              key: "menu",
              fn: function (scope) {
                return [
                  scope.row.status == "2" || scope.row.status == "0"
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text", icon: "el-icon-edit" },
                          on: {
                            click: function ($event) {
                              return _vm.handleEdit(scope.row, scope.index)
                            },
                          },
                        },
                        [_vm._v("\n        修改\n      ")]
                      )
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "menuLeft" },
            [
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: {
                    click: function ($event) {
                      return _vm.createNew()
                    },
                  },
                },
                [_vm._v("新增店铺")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }