<template>
  <div class="selection-box">
    <el-alert title="添加预审批店铺" :closable="false" type="info" />
    <el-form ref="shopForm" :model="shopForm" :rules="shopRules" :label-position="'right'" label-width="160px" size="small" class="cForm">
      <div class="mt10 inner-box">
        <el-form-item label="店铺名称" prop="name">
          <el-input v-model="shopForm.name" placeholder="请输入店铺名称" :disabled="disabled" />
        </el-form-item>
        <el-form-item label="店铺ID" prop="shopId">
          <el-input v-model="shopForm.shopId" placeholder="请输入店铺Id" :disabled="disabled" />
        </el-form-item>
        <el-form-item label="店主账户Id" prop="shopkeeperId">
          <el-input v-model="shopForm.shopkeeperId" placeholder="请输入店主账户Id" :disabled="disabled" />
        </el-form-item>
        <el-form-item label="店主手机号" prop="shopkeeperPhone">
          <el-input v-model="shopForm.shopkeeperPhone" :disabled="disabled" placeholder="请输入店主手机号" />
        </el-form-item>

        <el-form-item label="所属平台" prop="liveStreamPlat">
          <dict-select v-model="shopForm.liveStreamPlat" :disabled="disabled" placeholder="请选择店铺类型" no="D000101" style="width: 100%;" @change="liveStreamPlat_change" />
        </el-form-item>

        <el-form-item label="配置日期" prop="cofigDate">
          <el-date-picker v-model="shopForm.cofigDate" :disabled="disabled" value-format="yyyy-MM-dd" type="date" placeholder="选择配置日期" style="width: 100%" />
        </el-form-item>
        <el-form-item label="订单数据" prop="fileBoutId">
          <dict-upload
            v-model="shopForm.fileBoutId"
            type="text"
            :limit="1"
            type-code="F0109"
            biz-id="123456"
            :tips="'提示：ZIP压缩包'"
            :disabled="disabled"
            @change="onFileChange($event, 'fileBoutId')"
          />
        </el-form-item>
        <el-form-item label="申请意见" prop="applyMsg">
          <el-input v-model="shopForm.applyMsg" type="textarea" placeholder="请输入审批意见" />
        </el-form-item>
        <div class="tc mt30">
          <el-button type="primary" @click="saveShopInfo">提交</el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import DictSelect from '@/views/components/DictSelect.vue';
import DictUpload from '@/views/components/DictUpload.vue';
import { doPost, doGet } from '@/router/axios';
import DictSelectMultiple from '@/views/components/DictSelectMultiple';
import DictSelectSingle from '@/views/components/DictSelectSingle';

export default defineComponent({
  name: 'shopInfo',
  components: { DictUpload, DictSelect, DictSelectSingle, DictSelectMultiple },
  props: {
    eId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      disabled: false,
      enterId: '',
      shopForm: {
        id: '',
        shopkeeperId: '', // 营业期限
        name: '',
        shopId: '',
        liveStreamPlat: '',
        shopkeeperPhone: '',
        fileShopId: []
      },
      shopRules: {
        enterId: [{ required: true, trigger: 'change', message: '所属企业不能为空' }],
        shopkeeperId: [{ required: true, trigger: 'change', message: '请输入店主账号Id' }],
        shopId: [{ required: true, trigger: 'blur', message: '请填写店铺ID' }],
        name: [{ required: true, trigger: 'blur', message: '请填写店铺名称' }],
        liveStreamPlat: [{ required: true, trigger: 'blur', message: '请选择直播平台' }],
        shopkeeperPhone: [{ required: true, trigger: 'blur', message: '请填写店主手机号' }],
        cofigDate: [{ required: true, trigger: 'blur', message: '请填写配置日期' }],
        fileSettleId: [{ required: true, trigger: 'blur', message: '客户准入流水号_结算单' }],
        fileOrderId: [{ required: true, trigger: 'blur', message: '客户准入流水号_订单' }],
        fileBoutId: [{ required: true, trigger: 'blur', message: '客户准入流水号_精品联盟订单' }]
      }
    };
  },
  created() {
  },
  methods: {
    liveStreamPlat_change(val) {
      this.$set(this.shopForm, 'liveStreamPlat', val);
    },
    onFileChange(ids = [], key) {
      this.$refs.shopForm.validateField(key);
    },
    handleChangeCompany(val) {
      this.enterId = val;
      this.$set(this.shopForm, 'enterId', val);
      this.initData();
    },
    saveShopInfo() {
      this.$refs.shopForm.validate(valid => {
        if (valid) {
          const data = {
            ...this.shopForm
          };
          doPost('/chan/prea/preApproval', data).then(res => {
            this.loading = false;
            if (res.data.code === 200) {
              this.$notify({
                title: res.data.msg,
                type: 'success',
                duration: 2500
              });
              this.$router.push({ path: '/chan/shopre' });
            } else {
              this.$notify({
                title: res.data.msg,
                type: 'error',
                duration: 2500
              });
            }
          }).catch(e => {
            this.$notify({
              title: e,
              type: 'error',
              duration: 2500
            });
            this.loading = false;
          });
        } else {
          return false;
        }
      });
    }
  }
});
</script>

<style scoped lang="scss">

</style>
