<template>
  <div class="user">
    <basic-container class="full-condition">
      <avue-crud
        ref="crud"
        :option="option"
        :page.sync="page"
        :table-loading="listLoading"
        :data="list"
        :cell-class-name="handleCellClassName"
        @on-load="getList"
        @size-change="sizeChange"
        @current-change="currentChange"
        @search-change="handleFilter"
        @search-reset="resetFilter"
        @refresh-change="handleRefreshChange"
      >
        <template slot="menuLeft">
          <!-- <el-button
            class="filter-item"
            type="primary"
            icon="icon-YUAN"
            @click="handleWidthdrawal"
            >提现
          </el-button>
          <el-button
            class="filter-item"
            type="primary"
            icon="icon-YUAN"
            @click="checkFlow"
            >流水核查
          </el-button> -->
        </template>

        <template slot="menu" slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-view"
            @click="accountFlow(scope.row, scope.index)"
            >流水</el-button
          >
          <el-button
            v-if="syncPermissions && scope.row.status == 1"
            type="text"
            icon="el-icon-refresh"
            @click="queryBook(scope.row, scope.index)"
            >账户同步</el-button
          >
          <!-- <el-button
            v-if="scope.row.status == 1"
            type="text"
            icon="el-icon-view"
            @click="frozenBook(scope.row, scope.index)"
            >冻结</el-button
          >
          <el-button
            v-if="scope.row.status == 2"
            type="text"
            icon="el-icon-view"
            @click="frozenBook(scope.row, scope.index)"
            >解冻</el-button
          > -->
        </template>
      </avue-crud>
    </basic-container>

    <el-dialog
      :visible.sync="dialogVisible"
      title="资金流水"
      width="98%"
      top="50px"
      custom-class="pub_dialog"
      @close="handleCloseDialog"
    >
      <account-flow
        v-if="accountId"
        :account-id="accountId"
        :account-name="accountName"
        @change="handleCloseDialog"
      />
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { doGet, doPost, doPut } from "@/router/axios";
import DictUploadProcess from "@/components/form-upload/DictUploadProcess";
import accountFlow from "./accountFlow";
const tableOption = {
  searchMenuSpan: 24,
  searchLabelWidth: 80,
  searchMenuPosition: "center",
  searchIndex: 3,
  searchIcon: true,
  searchShowBtn: false,
  border: false,
  showSummary: false,
  printBtn: false,
  index: false,
  indexLabel: "序号",
  stripe: true,
  menuAlign: "center",
  menuWidth: 200,
  searchIndex: 3,
  searchIcon: true,
  searchShowBtn: false,
  labelWidth: 100,
  editBtn: false,
  delBtn: false,
  align: "center",
  addBtn: false,
 searchShowBtn:false,
 column: [
    {
      fixed: true,
      label: "id",
      prop: "id",
      span: 24,
      hide: true,
      editDisplay: false,
      addDisplay: false,
    },
    {
      label: "户名",
      prop: "accName",
      width: 180,
      editDisplay: false,
      addDisplay: false,
      search: true,
      searchSpan: 8,
      span: 24,
    },
    {
      label: "账户类型",
      prop: "accMold",
      width: 150,
      type: "select",
      dicUrl: "/dict/type/D000122",
      editDisplay: true,
      addDisplay: false,
      search: true,
      searchSpan: 8,
      span: 12,
    },
    {
      label: "钱包账号",
      prop: "upyztBookNo",
      width: 190,
      editDisplay: false,
      addDisplay: false,
      span: 24,
    },
    {
      label: "银行卡号",
      prop: "bankCard",
      width: 180,
      editDisplay: false,
      addDisplay: false,
      search: true,
      searchSpan: 8,
      span: 24,
    },
    {
      label: "银行名称",
      prop: "bankName",
      width: 180,
      editDisplay: false,
      addDisplay: false,
      search: true,
      searchSpan: 8,
      span: 24,
    },
    {
      label: "总余额",
      prop: "totleAmount",
      editDisplay: true,
      addDisplay: true,
      span: 12,
    },
    {
      label: "可用余额",
      prop: "balanceAmount",
      editDisplay: false,
      addDisplay: false,
      span: 12,
    },
    {
      label: "冻结金额",
      prop: "frozenAmount",
      editDisplay: false,
      addDisplay: false,
      span: 12,
    },
    // {
    //   label: "银行卡账户余额金额",
    //   prop: "upyztTotalAmount",
    //   width: 130,
    //   editDisplay: false,
    //   addDisplay: false,
    //   span: 12,
    // },
    {
      label: "银行冻结金额",
      prop: "upyztFrozenAmount",
      editDisplay: false,
      addDisplay: false,
      hide: true,
      span: 12,
    },
    {
      label: "在途金额",
      prop: "pendingAmount",
      editDisplay: false,
      addDisplay: false,
      hide: true,
      span: 12,
    },
    {
      label: "不可用余额",
      prop: "expensingAmount",
      editDisplay: false,
      addDisplay: false,
      hide: true,
      span: 12,
    },
    {
      label: "用户ID",
      prop: "custId",
      editDisplay: false,
      addDisplay: false,
      hide: true,
      span: 12,
    },

    {
      label: "账户类型",
      prop: "accType",
      type: "select",
      dicUrl: "/dict/type/D000035",
      editDisplay: true,
      addDisplay: false,
      hide: true,
      span: 12,
    },
    {
      label: "状态",
      prop: "status",
      type: "select",
      dicUrl: "/dict/type/D000033",
      editDisplay: true,
      addDisplay: false,
      search: true,
      searchSpan: 8,
      span: 12,
    },
    {
      label: "错误信息",
      prop: "errorMsg",
      editDisplay: false,
      addDisplay: false,
      hide: true,
      rules: [
        {
          required: false,
          message: "请输入错误信息",
        },
      ],
      span: 12,
    },
    {
      label: "错误时间",
      prop: "errorTime",
      type: "datetime",
      format: "yyyy-MM-dd HH:mm:ss",
      valueFormat: "yyyy-MM-dd HH:mm:ss",
      editDisplay: false,
      addDisplay: false,
      hide: true,
      rules: [
        {
          required: false,
          message: "请输入错误时间",
        },
      ],
      span: 12,
    },
    {
      label: "是否核对",
      prop: "isCheck",
      type: "select",
      dicUrl: "/dict/type/D000123",
      editDisplay: false,
      addDisplay: false,
      hide: true,
      rules: [
        {
          required: false,
          message: "请输入是否核对",
        },
      ],
      search: false,
      span: 12,
    },
    {
      label: "备注",
      prop: "remark",
      type: "textarea",
      editDisplay: true,
      hide: true,
      addDisplay: true,
      rules: [
        {
          required: false,
          message: "请输入备注",
        },
      ],
      span: 24,
    },
    {
      label: "",
      prop: "updateTime",
      editDisplay: false,
      addDisplay: false,
      rules: [
        {
          required: false,
          message: "请输入",
        },
      ],
      hide: true,
      span: 12,
    },
    {
      label: "",
      prop: "createTime",
      editDisplay: false,
      addDisplay: false,
      rules: [
        {
          required: false,
          message: "请输入",
        },
      ],
      hide: true,
      span: 12,
    },
  ],
};

export default {
  name: "TableUser",
  components: {
    accountFlow,
    DictUploadProcess,
  },
  data() {
    return {
      option: tableOption,
      searchItmes: {},
      dialogVisible: false,
      page: {
        total: 0, // 总页数
        currentPage: 1,
        pageSize: 10,
      },
      list: [],
      listLoading: true,
      accountId: "",
      accountName: "",
    };
  },
  computed: {
    ...mapGetters(["permissions"]),
    syncPermissions() {
      return this.permissions["biz_account_sync"];
    },
  },
  watch: {},
  created() {},
  methods: {
    getList(page, params) {
      const { currentPage, pageSize } = page;
      params = params ? params : { ...this.searchItmes };
      params.current = currentPage > 0 ? currentPage - 1 : 0;
      params.size = pageSize;
      this.listLoading = true;
      doGet("/cap/account/queryPage", params).then(({ data }) => {
        if (data && data.data) {
          this.list = data.data.dataList || [];
          this.page.total = data.data.totalElements || 0;
        }
        this.listLoading = false;
      });
    },

    sizeChange(size) {
      this.page.pageSize = size;
    },
    currentChange(current) {
      this.page.currentPage = current;
    },
    handleFilter(param, done) {
      this.page.currentPage = 1;
      this.searchItmes = param ? param : {};
      this.getList(this.page, param);
      done();
    },
    resetFilter() {
      this.page.currentPage = 1;
      this.searchItmes = {};
      this.getList(this.page, this.searchItmes);
    },
    handleRefreshChange() {
      this.getList(this.page, this.searchItmes);
    },

    handleCloseDialog() {
      this.dialogVisible = false;
      this.widthdrawDialogVisible = false;
      this.accountId = "";
      this.accountName = "";
      this.handleRefreshChange();
    },
    handleCellClassName({ row, column, rowIndex, columnIndex }) {
      if ([7].includes(columnIndex)) {
        return "yellow-cell";
      }
      return "";
    },

    accountFlow(row, index) {
      // this.$router.push({ path: '/cap/flowAll?accountId=' + row.id });
      this.accountId = row.id;
      this.accountName = row.accName;
      this.dialogVisible = true;
    },
    queryBook(row, index) {
      this.listLoading = true;
      doGet("/upyzt/balacc/queryBook/" + row.id).then((res) => {
        this.listLoading = false;
        this.handleRefreshChange();
      });
    },
    checkFlow() {
      doGet("/cap/account/checkFlow").then((res) => {
        this.handleRefreshChange();
      });
    },
    frozenBook(row, index) {
      this.listLoading = true;
      doGet("/upyzt/balacc/frozenBook/" + row.id).then((res) => {
        this.listLoading = false;
        this.handleRefreshChange();
      });
    },
    handleWidthdrawal() {
      this.$router.push({
        path: "/account/withdraw",
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
