<template>
  <div class="details-warp">
    <el-form ref="form" :model="form" label-width="120px" :rules="rules">
      <!--借据信息-->
      <div class="details-section ml10 mr20">
        <h3 class="details-section-h3"><span class="title">店铺表</span>
        </h3>
        <el-row :gutter="20" class="pl20 pr20">
          <el-col :span="12">
            <el-form-item label="店铺名称：">
              <el-input v-model="form.name" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="店铺编码：">
              <el-input v-model="form.shopCode" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="折扣率：">
              <el-input v-model="form.discountRate" readonly >
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="退货率：">
              <el-input v-model="form.refundRate" readonly > <template slot="append">%</template></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="日钧结算余额：">
              <el-input v-model="form.daySettleAmt" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="费用计算方式：">
              <dict-select
                v-model="form.rateType"
                placeholder="费用计算方式"
                no="D000082"
                style="width: 100%;"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="周转天数：">
              <dict-select
                v-model="form.turnoverDays"
                placeholder="周转天数"
                no="D000136"
                style="width: 100%;"
                disabled
              />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="直播平台：">
              <dict-select
                v-model="form.liveStreamPlat"
                placeholder="直播平台"
                no="D000101"
                style="width: 100%;"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="店主账号：">
              <el-input v-model="form.shopkeeperId" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="店主手机号：">
              <el-input v-model="form.shopkeeperPhone" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="配置日期：">
              <el-date-picker v-model="form.cofigDate" type="datetime" style="width: 100%;" placeholder="选择日期" value-format="yyyy-MM-dd HH:mm" format="yyyy-MM-dd HH:mm" readonly />
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <!--列表模块-->
    <div class="details-section ml10 mr20 mt20">
      <h3 class="details-section-h3"><span class="title">列表</span></h3>
      <el-row :gutter="20" class="pl20 pr20">
        <el-col :span="24">
          <shopGoodsOrder :shop-id="modalData" />
        </el-col>
      </el-row>
    </div>

  </div>
</template>

<script>
import { doGet, doPost, doPut } from '@/router/axios'

import DictSelect from '@/components/form-upload/DictSelect'
import shopGoodsOrder from './shopGoodsOrder.vue'
export default {
  name: 'PageDetails',
  components: {
    DictSelect, shopGoodsOrder
  },
  props: {
    modalData: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      bizId: '',
      form: {
        yzsumje: 0,
        id: ''
      },
      rules: {
      }
    }
  },
  watch: {
    modalData(val, oldVal) {
      console.log('modalData:' + val + '--oldVal' + oldVal)
      // 这里执行初始化方法
      this.initForm(val)
    }
  },
  mounted() {
    this.initForm(this.modalData)
  },
  methods: {
    initForm: function(id) {
      doGet('/shop/info/' + id).then(res => {
        this.form = res.data.data
      })
    }
  }
}
</script>

<style scoped>
    .details-warp {
        height: calc(100vh - 200px);
        overflow: hidden;
        overflow-y: auto;
    }
    .reset-btn {
        margin-top: 3px;
        margin-right: 10px;
    }
</style>
