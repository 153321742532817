<template>
  <div class="shop-add-container">
    <div class="selection-box">
      <el-steps :active="active" finish-status="success" simple class="mb20">
        <el-step :title="shopFormName" icon="el-icon-picture" />
      </el-steps>

      <!--添加店铺表单-->
      <div class="selection-box">
        <el-form
          ref="shopFormRef"
          :model="shopForm"
          :rules="shopRules"
          :label-position="'right'"
          label-width="160px"
          size="small"
          class="cForm">
          <div class="mt10 inner-box">
            <el-form-item label="所属企业" prop="enterId">
              <el-tooltip effect="dark" content="若所属企业未登记，请登记您的企业" placement="right">
                <DictSelectSingle
                  v-model="shopForm.enterId"
                  :url="'cust/enter/queryEnterDict'"
                  :disabled="disabled"
                  placeholder="请选择企业"
                  style="width: 80%"
                  @change="handleChangeCompany" />
              </el-tooltip>
              <el-button type="primary" class="ml20" @click="handleReprotNewCompany">登记新企业</el-button>
            </el-form-item>
            <el-form-item label="所属平台" prop="liveStreamPlat">
              <DictSelect
                v-model="shopForm.liveStreamPlat"
                :disabled="disabled"
                placeholder="请选择店铺类型"
                no="D000101"
                style="width: 100%"
                @change="changeLiveStreamPlat" />
            </el-form-item>
            <el-form-item label="店铺名称" prop="name">
              <el-input v-model="shopForm.name" maxlength="40" placeholder="请输入店铺名称" :disabled="disabled" />
            </el-form-item>
            <el-form-item label="店主账户ID(16位)" prop="shopkeeperId">
              <el-input
                v-model="shopForm.shopkeeperId"
                maxlength="40"
                placeholder="请输入店主账户Id"
                :disabled="disabled" />
            </el-form-item>
            <el-form-item label="店铺ID" prop="shopCode">
              <el-input v-model="shopForm.shopCode" maxlength="30" placeholder="请输入店铺编码" :disabled="disabled" />
            </el-form-item>
            <el-form-item label="店主手机号" prop="shopkeeperPhone">
              <el-input
                v-model="shopForm.shopkeeperPhone"
                maxlength="11"
                :disabled="disabled"
                placeholder="请输入店主手机号" />
            </el-form-item>
            <el-form-item label="店铺后台截图" prop="fileShopId">
              <DictUpload
                v-model="shopForm.fileShopId"
                type="image"
                :limit="2"
                type-code="F0102"
                biz-id="123456"
                :tips="'提示：请上传.jpg,jpeg,.png,.webp格式图片，大小不超过2M'"
                :disabled="disabled"
                @change="onFileChange($event, 'fileShopId')" />
            </el-form-item>
          </div>
        </el-form>
      </div>
    </div>

    <div class="tc mt30">
      <el-button type="primary" v-loading="submiting" @click="saveShopInfo">
        保 存
      </el-button>
    </div>
  </div>
</template>

<script>
import { doPost, doGet } from '@/router/axios'
import DictSelect from '@/views/components/DictSelect.vue'
import DictUpload from '@/views/components/DictUpload.vue'
import DictSelectSingle from '@/views/components/DictSelectSingle'

export default {
  name: 'shopAdd',
  components: { DictUpload, DictSelect, DictSelectSingle },
  props: {},
  data() {
    return {
      active: 0,
      disabled: false,
      submiting: false,
      shopFormName: '',
      shopId: '',
      shopForm: {
        id: '',
        enterId: '',
        shopId: '',
        shopkeeperId: '', // 营业期限
        name: '',
        shopCode: '',
        liveStreamPlat: '',
        shopkeeperPhone: '',
        fileShopId: [],
      },
      shopRules: {
        enterId: [{ required: true, trigger: 'change', message: '所属企业不能为空' }],
        shopkeeperId: [{ required: true, trigger: 'change', message: '请输入店主账号Id' }],
        shopCode: [{ required: true, trigger: 'blur', message: '请填写店铺编号' }],
        name: [{ required: true, trigger: 'blur', message: '请填写店铺名称' }],
        liveStreamPlat: [{ required: true, trigger: 'blur', message: '请选择直播平台' }],
        shopkeeperPhone: [{ required: true, trigger: 'blur', message: '请填写店主手机号' }],
      },
    }
  },
  watch: {},
  created() {
    const { shopId } = this.$route.query
    this.shopFormName = shopId ? '修改店铺' : '新增店铺'
    this.shopId = shopId || ''
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      if (this.shopId) {
        doGet('/shop/info/getShop/' + this.shopId)
          .then(({ data }) => {
            this.loading = false
            if (data.code === 200) {
              this.shopForm = data.data
            }
          })
          .catch(() => {
            this.loading = false
          })
      }
    },

    handleReprotNewCompany() {
      this.$router.push({ path: '/companyActiving/authInfoIndex?type=add' })
    },
    changeLiveStreamPlat(val) {
      this.$set(this.shopForm, 'liveStreamPlat', val)
      if (val) {
        this.$refs.shopFormRef.clearValidate('liveStreamPlat')
      }
    },
    onFileChange(ids = [], key) {
      this.$refs.shopFormRef.validateField(key)
    },
    handleChangeCompany(val) {
      this.$set(this.shopForm, 'enterId', val)
    },
    saveShopInfo() {
      if (!this.submiting) {
        this.$refs.shopFormRef.validate((valid) => {
          if (valid) {
            const sendData = {
              ...this.shopForm,
              custId: this.shopForm.enterId,
            }
            this.submiting = true
            doPost('/shop/info/save/', sendData)
              .then(({ data }) => {
                if (data.code === 200) {
                  const tips = this.shopId ? '修改店铺信息成功' : '新增店铺成功'
                  this.$notify.success(tips)
                  this.$router.push({ path: '/mineShop/shoplist' })
                }
              })
              .catch(() => {
                this.submiting = false
              })
          }
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.shop-add-container {
  margin-bottom: 7px;
  .inner-box {
  width: 720px;
}
}
.selection-box {
  border: 1px solid #f2f4f7;
  /deep/ .el-alert__title {
    color: #333;
    font-weight: 700;
  }
  .cForm {
    margin: 0 30px;
  }
}
/deep/ .el-divider--horizontal {
  width: auto;
}
/deep/ .el-form--label-left .el-form-item__label {
  padding-left: 15px;
}
.el-alert-other {
  display: flex;
}
</style>
