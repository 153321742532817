<template>
  <div class="mz-layout-content">
    <el-row :gutter="30">
      <el-col :span="8">
        <div class="grid-card bg-blue">
          <div class="tag">累计放款</div>
          <div class="title">累计放款金额 (万元)</div>
          <div class="number">{{ bill.totalAmt | numberToCurrency }}</div>
          <div class="tip"></div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="grid-card bg-orange">
          <div class="tag">累计回款</div>
          <div class="title">累计回款金额 (万元)</div>
          <div class="number">{{ bill.totalRepayAmt | numberToCurrency }}</div>
          <div class="tip"></div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="grid-card bg-red">
          <div class="tag">已请款</div>
          <div class="title">当日请款金额(元)</div>
          <div class="number">{{ bill.dayOrderAmt | numberToCurrency }}</div>
          <div class="tip"></div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="grid-card bg-blue">
          <div class="tag">已放款</div>
          <div class="title">当日放款金额(元)</div>
          <div class="number">{{ bill.dayLoanAmt | numberToCurrency }}</div>
          <div class="tip"></div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="grid-card bg-orange">
          <div class="tag">已回款</div>
          <div class="title">当日回款本金(元)</div>
          <div class="number">{{ bill.dayRepayAmt | numberToCurrency }}</div>
          <div class="tip"></div>
        </div>
      </el-col>
    </el-row>

    <el-row :gutter="30" class="mt20">
      <el-col v-for="(item, index) in list" :key="index" :span="8">
        <div class="grid-content">
          <div v-if="item.status == '01' || item.status == '00'" class="grid-title blue">
            <div>
              <div class="g-t-key">待还金额(元)</div>
              <div class="g-t-value">{{ item.surpTotalAmount | numberToCurrency }}</div>
            </div>
            <el-tag v-if="item.status == '01' || item.status == '00'" size="mini" class="grid-title-tag">还款中</el-tag>
          </div>
          <div v-if="item.status == '02'" class="grid-title red">
            <div>
              <div class="g-t-key">待还金额(元)</div>
              <div class="g-t-value">{{ item.surpAmount | numberToCurrency }}</div>
            </div>
            <el-tag size="mini" type="danger" class="grid-title-tag" @click="handleDetails(item, index)">已逾期</el-tag>
          </div>
          <div class="grid-item-group" @click="handleDetails(item, index)">
            <div class="grid-item">
              <div>企业</div>
              <div>{{ item.enterName }}</div>
            </div>
            <div class="grid-item">
              <div>店铺</div>
              <div>{{ item.shopName }}</div>
            </div>
            <div class="grid-item">
              <div>平台</div>
              <div>{{ item.liveStreamPlat }}</div>
            </div>
            <div class="grid-item">
              <div>融资单号</div>
              <div>{{ item.billNo }}</div>
            </div>
            <div class="grid-item">
              <div>总笔数</div>
              <div>{{ item.totalNum | numberToCurrency(0) }}</div>
            </div>
            <div class="grid-item">
              <div>实际请款金额</div>
              <div>¥ {{ item.billAmount | numberToCurrency }}</div>
            </div>
            <div class="grid-item">
              <div>综合服务费（息费打包）</div>
              <div>¥ {{ item.billInterest | numberToCurrency }}</div>
            </div>
            <div class="grid-item">
              <div>订单最长等待天数</div>
              <div>{{ item.maxDay }}</div>
            </div>
            <el-divider />
            <div class="grid-item">
              <div>剩余待还本金</div>
              <div>¥ {{ item.surpAmount | numberToCurrency }}</div>
            </div>
          </div>
          <div class="grid-split" />
          <div class="grid-btn btn-blue mt10" @click="handleDetails(item, index)">还款详情</div>
        </div>
      </el-col>
    </el-row>

    <el-dialog title="还款详情" :visible.sync="dialogVisible" width="98%" top="30px" custom-class="pub_dialog">
      <page-details v-if="fileId" :modal-data="fileId" />
    </el-dialog>
  </div>
</template>

<script>
import { doGet } from '@/router/axios'
import pageDetails from './commpoents/details'

export default {
  name: 'Index',
  components: { pageDetails },
  data() {
    return {
      activeName: '0',
      option: tableOption,
      search: {},
      dialogVisible: false,
      fileId: '',
      page: {
        total: 0, // 总页数
        current: 1, // 当前页数
        size: 10, // 每页显示多少条,
        isAsc: false, // 是否倒序
      },
      bill: {
        unpayAmt: '0,00',
        unpayDate: null,
        repayAmt: '0.00',
        repayInvest: '0.00',
        creditDTO: { balanceCredit: '0.00' },
      },
      list: [],
      listLoading: true,
      form: {},
    }
  },
  created() {
    this.initBillCount()
    this.getList()
  },
  methods: {
    getList() {
      var params = {}
      doGet('/rent/bill/billList', params).then((res) => {
        this.list = res.data.data
        this.listLoading = false
      })
    },
    handleDetails(row, index) {
      console.log('row.id' + row.id)
      this.fileId = row.id
      this.dialogVisible = true
    },
    repay(row, index) {
      this.$router.push({ path: '/repayment/add?billId=' + row.id })
    },
    billList() {
      this.$router.push({ path: '/loan/orderList' })
    },
    initBillCount() {
      const that = this
      doGet('/rent/bill/billCount').then((res) => {
        that.bill = res.data.data
      })
    },
  },
}
export const tableOption = {
  border: false,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menualign: 'left',
  searchMenuSpan: 6,
  searchLabelPosition: 'left',
  searchLabelWidth: 60,
  menuWidth: 120,
  editBtn: false,
  delBtn: false,
  align: 'left',
  addBtn: false,
  refreshBtn: false,
 searchShowBtn:false,
 column: [
    {
      fixed: true,
      label: 'id',
      prop: 'id',
      span: 24,
      hide: true,
      editDisplay: false,
      addDisplay: false,
    },
    {
      label: '编号',
      prop: 'billNo',
      editDisplay: true,
      addDisplay: true,
      search: true,
      searchSpan: 8,
      searchPlaceholder: '请输入编号',
      span: 12,
    },
    {
      label: '融资单号',
      prop: 'orderNo',
      editDisplay: true,
      addDisplay: true,
      searchSpan: 8,
      searchPlaceholder: '请输入编号',
      span: 12,
    },
    {
      label: '融资期限',
      prop: 'periodsDays',
      editDisplay: true,
      addDisplay: true,
      span: 12,
      width: 140,
    },
    {
      label: '还款日',
      prop: 'rentEndDate',
      editDisplay: true,
      addDisplay: true,
      span: 12,
      width: 140,
    },
    {
      label: '融资本金(元)',
      prop: 'billAmount',
      editDisplay: true,
      addDisplay: true,
      span: 12,
      width: 140,
    },
    {
      label: '融资利息',
      prop: 'billInterest',
      editDisplay: true,
      addDisplay: true,
      span: 12,
      width: 140,
    },
    {
      label: '逾期罚息',
      prop: 'billFine',
      editDisplay: false,
      addDisplay: false,
      span: 12,
      width: 140,
    },
    {
      label: '状态',
      prop: 'status',
      type: 'select',
      dicUrl: '/common/type/D000051',
      editDisplay: false,
      addDisplay: false,
      span: 12,
      width: 140,
      slot: true,
    },
  ],
}
</script>

<style lang="scss" scoped>
.mz-layout-content {
  width: 100%;
  padding-bottom: 20px;
  box-sizing: border-box;
  .mz-layout-section {
    padding: 20px;
    background: #fff;
    width: 100%;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    .model {
      height: 105px;
      .model1 {
        width: 48%;
        height: 105px;
        float: left;
      }
      .model2 {
        width: 48%;
        height: 105px;
        float: left;
        border-left: 1px solid #d2d2d2;
      }
    }
  }
}
.model_divTitle {
  font-size: 14px;
  color: #252b3a;
  font-weight: 700;
}
.tableList_title {
  color: #252b3a;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
}
.bg-blue {
  background: url('~@/assets/bg/blue_bg@2x.png') 50% 50% no-repeat;
  background-size: 100%;
}
.bg-red {
  background: url('~@/assets/bg/green_bg@2x.png') 50% 50% no-repeat;
  background-size: 100%;
}
.bg-orange {
  background: url('~@/assets/bg/orange_bg@2x.png') 50% 50% no-repeat;
  background-size: 100%;
}
.grid-card {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 6px;
  min-height: 215px;
  color: #ffffff;
  .tag {
    position: absolute;
    display: flex;
    align-items: center;
    top: 24px;
    right: 8px;
    &:before {
      content: '';
      display: inline-block;
      width: 5px;
      height: 5px;
      border-radius: 10px;
      background-color: #fff;
      margin-right: 5px;
    }
  }
  .title {
    height: 35px;
    font: 400 18px/35px 'PingFangSC-Regular, PingFang SC, Microsoft Yahei';
    font-weight: 400;
  }
  .number {
    height: 70px;
    font: 400 42px/70px 'tahoma,arial,Microsoft YaHei,Hiragino Sans GB,sans-serif';
    font-weight: 400;
  }
  .tip {
    height: 35px;
    line-height: 35px;
    font-size: 14px;
  }
}
.grid-content {
  margin-bottom: 15px;
  padding: 24px 0 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background-color: #fff;
  .grid-title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    height: 64px;
    overflow: hidden;
    &:before {
      content: '';
      display: inline-block;
      margin-right: 16px;
      width: 8px;
      height: 64px;
      border-radius: 0px 4px 4px 0px;
    }
    &.blue {
      &:before {
        background: linear-gradient(360deg, #0298ff 0%, #3bdeff 100%);
      }
    }
    &.red {
      .val-red {
        color: #fd5d68;
      }
      &:before {
        background: linear-gradient(180deg, #faa14e 0%, #ff4a60 100%);
      }
    }

    .g-t-key {
      height: 22px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #364064;
      line-height: 22px;
    }
    .g-t-value {
      height: 32px;
      font-size: 32px;
      font-family: DINMittelschriftStd;
      color: #0093ff;
      line-height: 38px;
    }
    .grid-title-tag {
      position: absolute;
      right: 20px;
    }
  }

  .grid-item-group {
    margin: 40px 18px 0;
    .grid-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 15px;
      & > div:first-child {
        height: 18px;
        font: 400 12px/18px PingFangSC-Regular, PingFang SC;
        color: #1b2749;
      }
      & > div:last-child {
        height: 18px;
        font: 400 12px/18px PingFangSC-Regular, PingFang SC;
        color: #1b2749;
      }
    }
  }
  .grid-split {
    height: 24px;
    background: url('~@/assets/illustration/grid-split.png') 0 0 no-repeat;
    background-size: 100%;
  }

  .grid-btn {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    height: 48px;
    color: #fff;
    border-radius: 5px;
    text-align: center;
    line-height: 48px;
    cursor: pointer;
    &.btn-blue {
      background: linear-gradient(360deg, #0298ff 0%, #3bdeff 100%);
    }
  }
}
</style>
