var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "pane-container",
    { staticClass: "fieldset-tips", attrs: { title: _vm.title } },
    [_c("div", { staticClass: "ct" }, [_vm._t("default")], 2)]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }