var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "country-select-wrap" },
    [
      _c(
        "div",
        { staticClass: "selection-box" },
        [
          _c("el-alert", {
            attrs: { title: _vm.title, closable: false, type: "warning" },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "selection-box", staticStyle: { "margin-top": "10px" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "中国大陆" },
                      model: {
                        value: _vm.myCountry,
                        callback: function ($$v) {
                          _vm.myCountry = $$v
                        },
                        expression: "myCountry",
                      },
                    },
                    [_vm._v("\n          中国大陆\n        ")]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "香港" },
                      model: {
                        value: _vm.myCountry,
                        callback: function ($$v) {
                          _vm.myCountry = $$v
                        },
                        expression: "myCountry",
                      },
                    },
                    [_vm._v("\n          香港\n        ")]
                  ),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 6 } }, [
                _c(
                  "div",
                  { staticClass: "other-btn", on: { click: _vm.onToggle } },
                  [
                    _vm._v("\n          其他国家或地区\n          "),
                    _c("i", {
                      class: `el-icon-arrow-${_vm.visible ? "up" : "down"}`,
                    }),
                  ]
                ),
              ]),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.visible,
                  expression: "visible",
                },
              ],
              staticStyle: { "padding-top": "30px" },
            },
            _vm._l(_vm.otherCountrys, function (os, i) {
              return _c(
                "el-row",
                { key: i },
                _vm._l(os, function (c) {
                  return _c(
                    "el-col",
                    { key: c, attrs: { span: 6 } },
                    [
                      _c("el-radio", {
                        attrs: { label: c },
                        model: {
                          value: _vm.myCountry,
                          callback: function ($$v) {
                            _vm.myCountry = $$v
                          },
                          expression: "myCountry",
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onStart } },
            [_vm._v("\n      开始认证\n    ")]
          ),
        ],
        1
      ),
      _c("auth-tips", {
        attrs: { country: _vm.country },
        on: { start: _vm.onAuth },
        model: {
          value: _vm.dialogVisible,
          callback: function ($$v) {
            _vm.dialogVisible = $$v
          },
          expression: "dialogVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }