var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "details-warp" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            "label-width": "80px",
            "label-position": "left",
          },
        },
        [
          _c(
            "div",
            { staticClass: "details-section ml20 mr20" },
            [
              _c("h3", { staticClass: "details-section-h3" }, [
                _c("span", [_vm._v("订单基本信息")]),
              ]),
              _c(
                "el-row",
                { staticClass: "pl20 pr20", attrs: { gutter: 40 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "编号：" } },
                        [
                          _c("el-input", {
                            attrs: { readonly: "" },
                            model: {
                              value: _vm.form.orderNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "orderNo", $$v)
                              },
                              expression: "form.orderNo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品：" } },
                        [
                          _c("el-input", {
                            attrs: { readonly: "" },
                            model: {
                              value: _vm.form.productName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "productName", $$v)
                              },
                              expression: "form.productName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "资方名称：" } },
                        [
                          _c("el-input", {
                            attrs: { readonly: "" },
                            model: {
                              value: _vm.form.fundName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "fundName", $$v)
                              },
                              expression: "form.fundName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "计费方式：" } },
                        [
                          _c("dict-select", {
                            attrs: { no: "D000082", disabled: "true" },
                            model: {
                              value: _vm.form.rateType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "rateType", $$v)
                              },
                              expression: "form.rateType",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "申请金额：" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { readonly: "" },
                              model: {
                                value: _vm.form.applyAmt,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "applyAmt", $$v)
                                },
                                expression: "form.applyAmt",
                              },
                            },
                            [
                              _c("template", { slot: "suffix" }, [
                                _vm._v("元"),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "放款金额：" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { readonly: "" },
                              model: {
                                value: _vm.form.loanAmt,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "loanAmt", $$v)
                                },
                                expression: "form.loanAmt",
                              },
                            },
                            [
                              _c("template", { slot: "suffix" }, [
                                _vm._v("元"),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "状态：" } },
                        [
                          _c("el-input", {
                            attrs: { readonly: "" },
                            model: {
                              value: _vm.form.statusName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "statusName", $$v)
                              },
                              expression: "form.statusName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "折扣率：" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { readonly: "" },
                              model: {
                                value: _vm.form.discountRate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "discountRate", $$v)
                                },
                                expression: "form.discountRate",
                              },
                            },
                            [_c("template", { slot: "suffix" }, [_vm._v("%")])],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "收款账号：" } },
                        [
                          _c("el-input", {
                            attrs: { readonly: "" },
                            model: {
                              value: _vm.form.reciveAcc,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "reciveAcc", $$v)
                              },
                              expression: "form.reciveAcc",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "收款姓名：" } },
                        [
                          _c("el-input", {
                            attrs: { readonly: "" },
                            model: {
                              value: _vm.form.reciveName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "reciveName", $$v)
                              },
                              expression: "form.reciveName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "收款支行：" } },
                        [
                          _c("el-input", {
                            attrs: { readonly: "" },
                            model: {
                              value: _vm.form.reciveBankSub,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "reciveBankSub", $$v)
                              },
                              expression: "form.reciveBankSub",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "放款时间：" } },
                        [
                          _c("el-input", {
                            attrs: { readonly: "" },
                            model: {
                              value: _vm.form.loanTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "loanTime", $$v)
                              },
                              expression: "form.loanTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "利率：" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { readonly: "" },
                              model: {
                                value: _vm.form.inverestRate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "inverestRate", $$v)
                                },
                                expression: "form.inverestRate",
                              },
                            },
                            [_c("template", { slot: "suffix" }, [_vm._v("%")])],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "回执单号：" } },
                        [
                          _c("el-input", {
                            attrs: { readonly: "" },
                            model: {
                              value: _vm.form.receiptNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "receiptNo", $$v)
                              },
                              expression: "form.receiptNo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "申请信息：" } },
                        [
                          _c("el-input", {
                            attrs: { type: "textarea", rows: 4, readonly: "" },
                            model: {
                              value: _vm.form.applyMsg,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "applyMsg", $$v)
                              },
                              expression: "form.applyMsg",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "details-section mt20 ml20 mr20" },
            [
              _c("h3", { staticClass: "details-section-h3" }, [
                _c("span", [_vm._v("订单详情")]),
              ]),
              _c("vocher-list", { attrs: { order: _vm.form } }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }