<template>
  <div class="mz-layout-section full-condition goods-order-list">
    <div class="tableList_title">订单列表</div>
    <el-divider />

    <avue-crud
      ref="crud"
      :option="option"
      :page.sync="page"
      :table-loading="listLoading"
      :data="list"
      @on-load="getList"
      @size-change="sizeChange"
      @current-change="currentChange"
      @search-change="handleFilter"
      @search-reset="resetFilter">
    </avue-crud>
  </div>
</template>

<script>
import { doGet, doPost, doDelet } from '@/router/axios'
const tableOption = {
  searchMenuSpan: 24,
  searchLabelWidth: 80,
  printBtn: true,
  index: false,
  indexLabel: '序号',
  stripe: true,
  border: false,
  showSummary: false,
  sumColumnList: [],
  menu: false,
  menualign: 'left',
  header: false,
  searchIndex: 3,
  searchIcon: true,
  searchShowBtn: false,
  labelWidth: 100,
  editBtn: false,
  delBtn: false,
  align: 'left',
  addBtn: false,
 searchShowBtn:false,
 column: [
    {
      label: '订单编号',
      prop: 'orderNo',
      fixed: true,
      width: 180,
      editDisplay: true,
      addDisplay: true,
      search: true,
      searchPlaceholder: '请输入订单编号',
      span: 12,
      searchSpan: 8,
    },
    {
      label: '店铺',
      prop: 'shopName',
      minWidth: 200,
      editDisplay: true,
      addDisplay: true,
      search: true,
      searchPlaceholder: '请输入店铺名称',
      searchSpan: 8,
      span: 12,
    },
    {
      label: '订单状态',
      prop: 'orderStatus',
      type: 'select',
      dicUrl: '/common/type/D000103',
      editDisplay: true,
      addDisplay: true,
      search: true,
      searchPlaceholder: '请选择订单状态',
      span: 12,
      searchSpan: 8,
    },
    {
      label: '借款状态',
      prop: 'loanStatus',
      type: 'select',
      dicUrl: '/common/type/D000104',
      editDisplay: true,
      addDisplay: true,
      search: true,
      searchPlaceholder: '请选择借款状态',
      span: 12,
      searchSpan: 8,
    },
    {
      label: '实付金额',
      prop: 'payAmt',
      editDisplay: true,
      addDisplay: true,
      span: 12,
    },
    {
      label: '商品ID',
      prop: 'goodsId',
      minWidth: 200,
      editDisplay: true,
      addDisplay: true,
      span: 12,
    },
    {
      label: '商品名称',
      prop: 'goodsName',
      minWidth: 200,
      editDisplay: true,
      addDisplay: true,
      search: true,
      searchPlaceholder: '请输入商品名称',
      span: 12,
      searchSpan: 8,
    },
    {
      label: '商品单价',
      prop: 'goodsPrice',
      editDisplay: true,
      addDisplay: true,
      span: 12,
    },
    {
      label: '商品数量',
      prop: 'goodsNum',
      editDisplay: true,
      addDisplay: true,
      span: 12,
    },
    {
      label: '购买时间',
      prop: 'buyTime',
      width: 160,
      type: 'datetime',
      search: true,
      searchRange: true,
      startPlaceholder: '开始日期',
      endPlaceholder: '结束日期',
      format: 'yyyy-MM-dd HH:mm:ss',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      editDisplay: true,
      addDisplay: true,
      span: 12,
      searchSpan: 8,
    },
    {
      label: '发货时间',
      prop: 'shipmentsTime',
      width: 160,
      type: 'datetime',
      format: 'yyyy-MM-dd HH:mm:ss',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      editDisplay: true,
      addDisplay: true,
      span: 12,
    },
    {
      label: '确认收款时间',
      prop: 'receiptTime',
      width: 160,
      type: 'datetime',
      format: 'yyyy-MM-dd HH:mm:ss',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      editDisplay: true,
      addDisplay: true,
      span: 12,
    },
    {
      label: '创建时间',
      prop: 'createTime',
      width: 160,
      editDisplay: false,
      addDisplay: false,
      span: 12,
    },
  ],
}

export default {
  name: 'GoodsOrderList',
  components: {},
  data() {
    return {
      option: tableOption,
      searchItmes: {},
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条,
      },
      list: [],
      selectedList: [],
      listLoading: true,
    }
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    getList(page, params) {
      params = params ? params : { ...this.searchItmes }
      params.current = page.currentPage - 1
      params.size = page.pageSize
      this.listLoading = true
      doPost('/shop/goodsOrder/queryPage', params).then(({ data }) => {
        if (data) {
          const { dataList, totalElements } = data.data || {}
          this.list = dataList || []
          this.page.total = totalElements || 0
        }
        this.listLoading = false
      })
    },

    sizeChange(size) {
      this.page.pageSize = size
    },
    currentChange(current) {
      this.page.currentPage = current
    },
    handleFilter(param, done) {
      this.page.currentPage = 1
      this.searchItmes = param ? param : {}
      this.getList(this.page, param)
      done()
    },
    resetFilter() {
      this.page.currentPage = 1
      this.searchItmes = {}
      this.getList(this.page, this.searchItmes)
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/ .el-tabs__nav-scroll {
  padding-left: 10px;
}

/deep/ .el-divider {
  background-color: #ecedef;
}
</style>
