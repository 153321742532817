var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "vormela-basicLayout-content" }, [
    _c("div", { staticClass: "rs-section" }, [
      _c("div", {
        class: _vm.statusFlag
          ? "img-result img-success"
          : "img-result img-fail",
      }),
      _c("div", { staticClass: "vormela-result-title" }, [
        _vm._v(_vm._s(_vm.statusFlag ? "提交成功" : "提交失败")),
      ]),
      _c("div", { staticClass: "vormela-result-subtitle" }, [
        _vm._v(_vm._s(_vm.content)),
      ]),
      _c(
        "div",
        { staticClass: "vormela-result-extra" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handleClose("ruleForm")
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }