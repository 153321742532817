<template>
  <div class="ml30 mr30">
    <el-form ref="ruleForm" :model="form" :rules="rules" :label-width="formLabelWidth" status-icon inline-message class="pl20 pr20">
      <div class="title_h3 mb20">融资信息</div>
      <el-form-item label="申请金额：" prop="applyAmt" class="ml20 mr20">
        {{ voucherData.applyAmt }}<span class="ml10">元</span>
      </el-form-item>
      <el-form-item v-if="voucherData.repayType==0" label="融资期限：" prop="linesDay" class="ml20 mr20">
        {{ voucherData.linesDay }}<span class="ml10">天</span>
      </el-form-item>
      <el-form-item v-if="voucherData.repayType==1||voucherData.repayType==2" label="融资期限：" prop="linesDay" class="ml20 mr20">
        {{ voucherData.linesDay }}<span class="ml10">月</span>
      </el-form-item>
      <el-form-item label="收款方名称：" prop="linesDay" class="ml20 mr20">
        {{ voucherData.reciveName }}
      </el-form-item>
      <el-form-item label="收款方账户：" prop="linesDay" class="ml20 mr20">
        {{ voucherData.reciveAcc }}
      </el-form-item>
      <el-form-item label="开户行：" prop="linesDay" class="ml20 mr20">
        {{ voucherData.reciveBankSub }}
      </el-form-item>
      <el-form-item label="融资年利率：" class="ml20 mr20">
        {{ voucherData.yearRate | numberMul(100) }}%
      </el-form-item>
      <el-form-item label="融资日利率：" class="ml20 mr20">
        {{ voucherData.intervestRate | numberMul(100) }}%
      </el-form-item>
      <el-form-item label="预计利息：" class="ml20 mr20">
        {{ voucherData.inverest }} <span class="ml10">元</span>
      </el-form-item>
      <el-form-item label="备注：" prop="applyMsg" class="ml20 mr20">
        {{ voucherData.applyMsg }}
      </el-form-item>
      <div class="title_h3 mb20 mt20">凭证信息</div>
      <el-form-item label="发票：" prop="fileInvoiceIds" class="ml20 mr20">
        <dict-upload v-model="form.fileInvoiceIds" :limit="10" type-code="F0402" biz-id="123456" type="text" @change="onFileChange($event, 'fileInvoiceIds')" />
        <div class="form-item-tips">
          仅支持jpeg、png格式
        </div>
      </el-form-item>
      <el-form-item label="业务协议：" prop="fileContractIds" class="ml20 mr20">
        <dict-upload v-model="form.fileContractIds" :limit="10" type-code="F0401" biz-id="123456" type="text" @change="onFileChange($event, 'fileContractIds')" />
      </el-form-item>
      <el-form-item label="凭证总额：" class="ml20 mr20">
        {{ voucherData.voucherAccount }}<span class="ml10">元</span>
      </el-form-item>
      <el-form-item label="凭证总数：" class="ml20 mr20">
        {{ voucherData.voucherCount }}<span class="ml10">笔</span>
      </el-form-item>
      <el-form-item label="凭证到期日：" class="ml20 mr20">
        {{ voucherData.voucherDateStr }}
      </el-form-item>
      <div class="title_h3 mb20">其它</div>
      <el-form-item label="补充附件:" prop="fileSupplyIds" class="ml20 mr20">
        <dict-upload v-model="form.fileSupplyIds" :limit="10" type-code="F0404" biz-id="123456" type="text" @change="onFileChange($event, 'fileSupplyIds')" />
      </el-form-item>
    </el-form>

    <div class="mt40 tc">
      <el-button icon="el-icon-arrow-left" size="medium" @click.stop="handleGoPrepStep()">上一步</el-button>
      <el-button icon="el-icon-circle-check" type="primary" size="medium" :loading="btnLoading" @click.stop="handleGoNextStep('ruleForm')">{{ btnSave }}</el-button>
    </div>
  </div>
</template>

<script>
import DictUpload from '@/views/components/DictUpload'
import FileView from '@/views/components/FileView'
import { doPost } from '@/router/axios'
export default {
  name: 'Step3',
  components: { DictUpload, FileView },
  props: {
    proId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      formLabelWidth: '120px',
      form: {
        fileInvoiceIds: [],
        fileContractIds: [],
        fileSupplyIds: []
      },
      rules: {
        fileInvoiceIds: [
          { required: true, message: '请上传发票', trigger: 'blur' }
        ],
        fileContractIds: [
          { required: true, message: '请上传业务协议', trigger: 'blur' }
        ]
      },
      voucherData: {},
      btnLoading: false,
      btnSave: '提 交'
    }
  },
  created() {
    this.initPage()
  },
  methods: {
    onFileChange(ids = [], key) {
      this.$refs.ruleForm.validateField(key)
    },
    initPage() {
      this.voucherData = JSON.parse(localStorage.getItem('MZdata'))
    },
    handleGoPrepStep() {
      this.$emit('handleGoToStep', 2)
    },
    handleGoNextStep(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.btnLoading = true
          this.btnSave = '提交...'
          // 请求接口,需要提交的数据是 this.voucherData
          this.form.proId = this.proId
          Object.assign(this.voucherData, this.form)

          doPost('/rent/order/apply', this.voucherData).then(res => {
            if (res.data.code === 200) {
              this.btnLoading = false
              this.$notify.success('申请成功')
              this.btnSave = '提交'
              const resData = res.data.data
              this.$router.push({ path: '/success/index?orderNo=' + resData.orderNo + '&applyAmt=' + resData.applyAmt })
              return false
            } else {
              this.btnLoading = false
              this.$notify.error(res.data.msg)
              this.btnSave = '提交'
            }
          }).catch(() => {
            this.btnLoading = false
            this.btnSave = '提交'
          })
          // 提交后要清空值
          localStorage.remove('voucherSelectedObj')
          localStorage.remove('MZdata')
          this.$emit('handleGoToStep', 4)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .title_h3 {
    height: 35px;
    font: 600 16px/100% "Microsoft YaHei";
    color: #333;
    border-bottom: 1px solid #dedede;
    &:before {
      content: '';
      float: left;
      width: 5px;
      height: 16px;
      background: #0094ff;
      margin-right: 10px;
    }
  }
  .dict-upload.text {
    width: auto;
  }
</style>
