var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "section",
      {
        staticStyle: { "text-justify-trim": "punctuation" },
        attrs: { lang: "ZH-CN" },
      },
      [
        _c(
          "div",
          {
            staticClass: "WordSection1",
            staticStyle: { "layout-grid": "15.6pt" },
          },
          [
            _c("h2", [_vm._v("企业信息采集及使用授权协议")]),
            _c("p", { staticClass: "MsoNormal" }, [_vm._v("重要提示：")]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _vm._v(
                "为了保障授权方的合法权益，请贵司务必审慎阅读、充分理解本授权书条款内容，特别是免除或者减轻北京联动世纪科技发展有限公司"
              ),
              _c("b", [_vm._v("（以下简称“必米供应链”）")]),
              _vm._v(
                "等被授权主体责任，或限制授权方权利的条款，其中免除或者减轻责任条款可能以加粗等形式提示授权方注意。"
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _vm._v(
                "如授权方不同意本授权书所有条款，请不要进行下一步操作，否则即视为授权方已阅读并同意受本授权书条款的约束。"
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _vm._v(
                "必米供应链金融服务平台（以下简称“金融服务平台”）注册用户"
              ),
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("(")]),
              _vm._v("指实际注册使用金融服务平台服务的企业，以下简称"),
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("“")]),
              _vm._v("授权方"),
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("”)")]),
              _vm._v(
                "在此不可撤销地授权并同意金融服务平台以及技术的提供者北京联动世纪科技发展有限公司等相关主体（以下统称"
              ),
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("“")]),
              _vm._v("被授权方"),
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("”")]),
              _vm._v(
                "）采集、存储、使用其信息，主要用于市场运营、风险环境评估及改进、丰富企业服务等功能，具体授权内容如下："
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [_vm._v("第一条 采集授权")]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("1.1 ")]),
              _vm._v(
                "授权方理解并同意为了正常使用金融服务平台的产品及服务，使得被授权方可以更全面、准确地记录、评估授权方情况，授权方授权被授权方在现行法律法规许可范围内直接采集或通过征信机构等第三方（包括但不限于政府机构、运营商及其代理商、关联公司等）采集、查询或核实与授权方有关的信息，并予以存储。"
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("1.2 ")]),
              _vm._v("上述信息从内容上包括："),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("1.2.1 ")]),
              _vm._v("工商信息，包括但不限于工商登记注册、变更等信息等；"),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("1.2.2 ")]),
              _vm._v(
                "税务信息，包括但不限于纳税信息、增值税发票信息等，例如纳税主体信息、税额、税率等；"
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("1.2.3 ")]),
              _vm._v(
                "司法信息，包括但不限于法院判决、失信被执行人信息、行政处罚、刑事责任等；"
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("1.2.4 ")]),
              _vm._v("经营信息，包括但不限于"),
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("ERP")]),
              _vm._v("系统中的采购、销售等信息，例如金额、数量、产品名称等；"),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("1.2.5 ")]),
              _vm._v("财务信息，包括但不限于财务报表、对外投资等；"),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("1.2.6 ")]),
              _vm._v(
                "资产信息，包括有形资产和无形资产，例如存款、动产、不动产、知识产权、债权等；"
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("1.2.7 ")]),
              _vm._v("与授权方有关的其他信息。"),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("1.3 ")]),
              _vm._v("上述信息从形式上包括但不限于："),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("1.3.1 ")]),
              _vm._v(
                "授权方因办理各类业务而留存在被授权方及其关联方、合作伙伴处的信息；"
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("1.3.2 ")]),
              _vm._v("授权方向被授权方及其关联方、合作伙伴主动提供的信息；"),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("1.3.3 ")]),
              _vm._v(
                "储存在授权方自身硬件或软件系统需被授权方根据授权主动采集的信息；"
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("1.3.4 ")]),
              _vm._v("授权方已经向社会公开的或第三方合法拥有的授权方信息；"),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("1.3.5 ")]),
              _vm._v(
                "被授权方基于法律法规规定或信息主体授权所获得的其他信息。"
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("1.4 ")]),
              _vm._v(
                "授权方同意注册及使用被授权方提供的平台网页及客户端，并授权被授权方直接采集或通过第三方协助的方式采集授权方自身硬件或软件系统中存储的授权方的企业经营信息，此类信息包括发票信息及"
              ),
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("ERP")]),
              _vm._v("系统中的信息等。"),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("1.5 ")]),
              _vm._v(
                "授权方知晓并同意：信息采集过程中，被授权方可聘请第三方（包括自然人、法人或法律规定的其他组织）为其提供技术服务，以便获得授权方授权采集的信息。但此情形下，该第三方仅提供技术服务，被授权方并未将本授权书中采集信息的授权转委托给该方，被授权方将明确要求该第三方不得存储、使用授权方的信息。\n    如该第三方侵犯授权方关于信息保护的合法权益的，被授权方将积极配合授权方向第三方主张权利。"
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [_vm._v("第二条 使用授权")]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("2.1 ")]),
              _vm._v(
                "授权方同意并不可撤销地授权被授权方基于为授权方提供更优质服务和产品的目的，向被授权方因服务必要开展合作的伙伴（包括但不限于征信机构）提供因采集授权所得到的授权方的相关信息，法律另有规定的除外。为确保授权方信息的安全，被授权方及其合作伙伴对上述信息负有保密义务。"
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("2.2 ")]),
              _vm._v(
                "授权方同意并不可撤销地授权征信机构在保证授权方信息安全的前提下，保存、整理、加工前述通过合法途径采集的授权方信息，并向经授权方授权同意的中国境内的其他信息使用者提供，用于评价授权方信用情况或核实授权方信息的真实性，但是法律规定可以不经同意的除外。"
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("2.3 ")]),
              _vm._v(
                "授权方同意并不可撤销地授权被授权方、征信机构将因上述采集授权所得到的信息，用于被授权方及其因服务必要委托的合作伙伴为授权方提供服务、推荐产品、开展市场调查与信息数据分析。"
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _vm._v("第三条 信息分享授权"),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("3.1 ")]),
              _vm._v(
                "授权方同意，授权被授权方的关联方，除法律另有规定之外，将授权方提供给被授权方关联方的信息、享受被授权方关联方服务产生的信息（包括本协议签署之前提供和产生的信息）以及被授权方关联方根据本授权书约定查询、收集的信息，用于被授权方关联方及其因服务必要委托的合作伙伴为授权方提供服务、推荐产品、开展市场调查与信息数据分析。"
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("3.2 ")]),
              _vm._v(
                "授权方授权被授权方的关联方，除法律另有规定之外，基于为授权方提供更优质服务和产品的目的，向被授权方的关联方因服务必要开展合作的伙伴提供、查询、收集授权方的信息。"
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("3.3 ")]),
              _vm._v(
                "授权方授权被授权方的关联方，除法律另有规定之外，基于为授权方提供更优质服务和产品的目的，从被授权方的关联方因服务必要开展合作的伙伴处获取、查询、收集授权方的信息。为确保授权方信息的安全，被授权方的关联方及其合作伙伴对上述信息负有保密义务，并采取各种措施保证信息安全。"
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("3.4 ")]),
              _vm._v("以上所称"),
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("“")]),
              _vm._v("被授权方的关联方"),
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v("”")]),
              _vm._v(
                "是指被授权方直接或间接控股的公司，被授权方直接或间接作为其单一最大股东的公司，直接或间接控制被授权方的公司，以及被授权方以其他方式直接或间接控制或有重大影响的主体。"
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _vm._v(
                "第四条 授权方充分理解并知晓第一条所述信息对于授权方的重要性，以及对外提供或授权采集、存储、使用上述信息可能产生的风险（这些风险包括但不限于信息泄漏、被授权主体依法依约提供给第三方后被他人不当利用等），授权方同意授权被授权方采集、存储、使用关于授权方的该类信息，并授权被授权方及相关主体按照本授权书约定进行使用。"
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _vm._v(
                "第五条 本授权书自作出之日起生效，至授权方在被授权方处所有业务终结之日止。本授权系授权方对被授权方作出的单方承诺，效力具有独立性，不因其他合同的任何条款无效而失效。"
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _vm._v(
                "第六条 若授权方与被授权主体发生任何纠纷或争议，首先应友好协商解决；协商不成的，授权方同意将纠纷或争议提交广州仲裁院仲裁解决，适用中华人民共和国大陆地区法律（不包括冲突法）。"
              ),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _c("span", { attrs: { lang: "EN-US" } }, [_vm._v(" ")]),
            ]),
            _c("p", { staticClass: "MsoNormal" }, [
              _vm._v(
                "第七条 授权方已知悉本授权书所有内容的意义以及由此产生的法律效力，自愿做出上述授权，本授权书是授权方真实的意思表示，授权方同意承担由此带来的一切法律后果。"
              ),
            ]),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }