<template>
	<basic-container transparent>

		<div class="setting-container">
			<div class="setting-title">
				账号设置
			</div>
			<ul class="setting-content">
				<!--          <li>-->
				<!--            <div class="left">-->
				<!--              <img :src="require('@/assets/icon/icon-phone.png')" alt="">-->
				<!--              <div>-->
				<!--                <p class="name fc_666 f15">绑定手机</p>-->
				<!--                <p class="desc fc_999 f15">{{ custInfo.phone }}</p>-->
				<!--              </div>-->
				<!--            </div>-->
				<!--            <div class="right">-->
				<!--              <el-button type="text" @click="phoneVisible = true"> 修改 > </el-button>-->
				<!--            </div>-->
				<!--          </li>-->
			</ul>
		</div>

		<div class="setting-container mt20">
			<div class="setting-title">
				安全设置
			</div>
			<ul class="setting-content">
				<li>
					<div class="left">
						<img :src="require('@/assets/icon/icon-pwd.png')" alt="">
						<div>
							<p class="name fc_666 f15">登录密码</p>
							<p class="desc fc_999 f15">已设置</p>
						</div>
					</div>
					<div class="right">
						<el-button type="text" @click="loginPwaVisible = true"> 修改 > </el-button>
					</div>
				</li>
				<!--<li>-->
				<!--<div class="left">-->
				<!--<img :src="require('@/assets/icon/icon-pwd-2.png')" alt="">-->
				<!--<div>-->
				<!--<p class="name fc_666 f15">交易密码</p>-->
				<!--<p class="desc fc_999 f15" v-if="custInfo.isPayPass==1">已设置</p>-->
				<!--<p class="desc fc_999 f15" v-if="custInfo.isPayPass!=1">未设置</p>-->
				<!--</div>-->
				<!--</div>-->
				<!--<div class="right">-->
				<!--<el-button type="text" @click="dealPwaVisible = true"> 修改 > </el-button>-->
				<!--</div>-->
				<!--</li>-->
			</ul>
		</div>

		<change-phone v-model="phoneVisible"></change-phone>
		<change-pwd title="修改登录密码" api="/cust/set/updatePassword" v-model="loginPwaVisible"></change-pwd>
		<change-pwd title="修改交易密码" api="/cust/set/updatePayPassword" v-model="dealPwaVisible"></change-pwd>

	</basic-container>
</template>

<script>
	import {
		doDictItem,
		doGet,
		doPost
	} from '@/router/axios'
	import FieldsetTip from '@/components/FieldsetTip'
	import DictSelect from '@/views/components/DictSelect'
	import ChangePhone from './dialogs/ChangePhone'
	import ChangePwd from './dialogs/ChangePwd'
	export default {
		name: 'Index',
		components: {
			FieldsetTip,
			DictSelect,
			ChangePhone,
			ChangePwd
		},
		data() {
			return {
				custInfo: {
					phone: '',
					custName: '',
					loginName: '',
					isPayPass: 0
				},
				title: '',
				phoneVisible: false,
				loginPwaVisible: false,
				dealPwaVisible: false,

			}
		},
		created() {
			this.userInfo();
		},
		methods: {
			userInfo() {
				const _that = this
				// doGet('/user/info').then(res => {
				// 	if (res.code == 200) {
				// 		_that.custInfo = res.data

				// 	}
				// }).catch(e => {
				// 	this.loading = false
				// })
			}

		}
	}
</script>

<style lang="scss" scoped>
	.setting-container {
		background: white;
	}

	.setting-title {
		padding: 15px 30px;
		border-bottom: 1px solid #E9E9E9;
	}

	.setting-content {
		padding: 10px 30px 30px;

		>li {
			border-bottom: 1px solid #E9E9E9;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 15px 20px;

			.left {
				display: flex;
				align-items: center;

				img {
					width: 48px;
					margin-right: 20px;
				}
			}
		}
	}
</style>