<template>
  <div class="user">
    <basic-container>
      <avue-crud
        ref="crud"
        v-model="form"
        :option="option"
        :page.sync="page"
        :table-loading="listLoading"
        :before-open="handleOpenBefore"
        :data="list"
        @on-load="getList"
        @size-change="sizeChange"
        @current-change="currentChange"
        @search-change="handleFilter"
        @refresh-change="handleRefreshChange"
        @row-update="update"
        @row-save="create"
      >
        <template slot="menuLeft" />

        <template
          slot="menu"
          slot-scope="scope"
        />
      </avue-crud>
    </basic-container>
  </div>

</template>

<script>
import { doGet, doPost, doDelet, doPut } from '@/router/axios'
import { mapGetters } from 'vuex'

export default {
  name: 'TableUser',
  data() {
    return {
      option: tableOption,
      defaultProps: {
        label: 'name',
        value: 'id'
      },
      page: {
        total: 0, 		// 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, 	// 每页显示多少条,
        isAsc: false	// 是否倒序
      },
      list: [],
      listLoading: true,
      form: {}
    }
  },
  computed: {

  },
  watch: {

  },
  created() {

  },
  methods: {
    getList(page, params) {
      if (params == undefined || params == null || params == '') {
        params = {}
      }
      this.listLoading = true
      params.current = page.currentPage - 1
      params.size = page.pageSize
      doGet('/wf/historyOrderInfo/page', params).then(res => {
        this.list = res.data.data.dataList
        this.page.total = res.data.data.totalElements
        this.listLoading = false
      })
    },
    sizeChange(size) {
      this.page.pageSize = size
    },
    currentChange(current) {
      this.page.currentPage = current
    },
    handleFilter(param, done) {
      this.page.currentPage = 1
      this.getList(this.page, param)
      done()
    },
    handleRefreshChange() {
      this.getList(this.page)
    },
    handleOpenBefore(show, type) {
      window.boxType = type
      // 若是编辑、查看回显名称
      if (['edit', 'views'].includes(type)) {

      } else if (type === 'add') {

      }
      show()
    },
    handleUpdate(row, index) {
      this.$refs.crud.rowEdit(row, index)
    }
  }
}

export const tableOption = {
  border: false,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menualign: 'left',
  menuWidth: 120,
  searchMenuSpan: 6,
  editBtn: false,
  delBtn: false,
  viewBtn: true,
  align: 'left',
  addBtn: false,
 searchShowBtn:false,
 column: [{
    fixed: true,
    label: 'id',
    prop: 'id',
    span: 24,
    hide: true,
    viewDisplay: false,
    editDisplay: false,
    addDisplay: false
  },
  {
    fixed: true,
    label: '流程',
    prop: 'procId',
    type: 'select',
    dicUrl: '/dict/type/D000060',
    editDisplay: true,
    addDisplay: true,
    search: true,
    span: 12
  },
  {
    fixed: true,
    label: '标题',
    prop: 'title',
    editDisplay: true,
    addDisplay: true,
    search: true,
    span: 12
  },
  {
    fixed: true,
    label: '编码',
    prop: 'orderNo',
    editDisplay: true,
    addDisplay: true,
    search: true,
    span: 12
  },
  {
    fixed: true,
    label: '创建人',
    prop: 'createUser',
    editDisplay: true,
    addDisplay: true,
    hide: true,
    span: 12
  },
  {
    fixed: true,
    label: '创建人',
    prop: 'createUserName',
    editDisplay: true,
    addDisplay: true,
    search: true,
    span: 12
  },
  {
    fixed: true,
    label: '业务ID',
    prop: 'bizId',
    editDisplay: true,
    addDisplay: true,
    hide: true,
    span: 12
  },
  {
    fixed: true,
    label: '状态值',
    prop: 'bizStatus',
    viewDisplay: false,
    editDisplay: true,
    addDisplay: true,
    hide: true,
    span: 12
  },
  {
    fixed: true,
    label: ' 状态',
    prop: 'status',
    type: 'select',
    dicUrl: '/dict/type/D000061',
    editDisplay: true,
    addDisplay: true,
    span: 12
  },
  {
    fixed: true,
    label: '备注',
    prop: 'remark',
    editDisplay: true,
    addDisplay: true,
    span: 12
  },
  {
    fixed: true,
    label: '创建时间',
    prop: 'createTime',
    editDisplay: false,
    addDisplay: false,
    span: 12
  },
  {
    fixed: true,
    label: '更新时间',
    prop: 'updateTime',
    hide: true,
    editDisplay: false,
    addDisplay: false,
    span: 12
  }
  ]
}

</script>
