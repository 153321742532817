var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user" },
    [
      _c(
        "basic-container",
        [
          _c("avue-crud", {
            ref: "crud",
            attrs: {
              option: _vm.option,
              page: _vm.page,
              "table-loading": _vm.loading,
              "before-open": _vm.handleOpenBefore,
              data: _vm.list.slice(
                (_vm.page.currentPage - 1) * _vm.page.pageSize,
                _vm.page.currentPage * _vm.page.pageSize
              ),
            },
            on: {
              "update:page": function ($event) {
                _vm.page = $event
              },
              "on-load": _vm.getList,
              "size-change": _vm.sizeChange,
              "search-change": _vm.handleFilter,
              "refresh-change": _vm.handleRefreshChange,
            },
            scopedSlots: _vm._u([
              {
                key: "menu",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", icon: "el-icon-delete" },
                        on: {
                          click: function ($event) {
                            return _vm.handleClean(scope.row, scope.index)
                          },
                        },
                      },
                      [_vm._v("清除\n        ")]
                    ),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.form,
              callback: function ($$v) {
                _vm.form = $$v
              },
              expression: "form",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }