var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bk_white" },
    [
      _c(
        "el-form",
        {
          ref: "voucherInfoForm",
          staticClass: "p40",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-width": _vm.formLabelWidth,
            "status-icon": "",
            "inline-message": "",
          },
        },
        [
          _c("div", { staticClass: "title_h3 mb20" }, [_vm._v("货代企业信息")]),
          _c(
            "el-form-item",
            { attrs: { label: "企业名称" } },
            [
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: { autocomplete: "off", disabled: "" },
                model: {
                  value: _vm.userInfo.enterpriseName,
                  callback: function ($$v) {
                    _vm.$set(_vm.userInfo, "enterpriseName", $$v)
                  },
                  expression: "userInfo.enterpriseName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "营业执照号" } },
            [
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: { autocomplete: "off", disabled: "" },
                model: {
                  value: _vm.userInfo.businessLicense,
                  callback: function ($$v) {
                    _vm.$set(_vm.userInfo, "businessLicense", $$v)
                  },
                  expression: "userInfo.businessLicense",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "title_h3 mb20" }, [_vm._v("货主企业信息")]),
          _c(
            "el-form-item",
            { attrs: { label: "企业名称", prop: "cargoEnterpriseName" } },
            [
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: { autocomplete: "off" },
                model: {
                  value: _vm.form.cargoEnterpriseName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "cargoEnterpriseName", $$v)
                  },
                  expression: "form.cargoEnterpriseName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "企业统一信用编号", prop: "cargoBusinessId" } },
            [
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: { autocomplete: "off" },
                model: {
                  value: _vm.form.cargoBusinessId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "cargoBusinessId", $$v)
                  },
                  expression: "form.cargoBusinessId",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "title_h3 mb20" }, [_vm._v("交易信息")]),
          _c(
            "el-form-item",
            { attrs: { label: "应收账款编号", prop: "tradeNo" } },
            [
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: { autocomplete: "off" },
                model: {
                  value: _vm.form.tradeNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "tradeNo", $$v)
                  },
                  expression: "form.tradeNo",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "发票金额", prop: "invoiceAmt" } },
            [
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: { autocomplete: "off", type: "number" },
                model: {
                  value: _vm.form.invoiceAmt,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "invoiceAmt", $$v)
                  },
                  expression: "form.invoiceAmt",
                },
              }),
              _c("span", { staticClass: "ml10 fc_666" }, [_vm._v("元")]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "合同名称", prop: "contractName" } },
            [
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: { autocomplete: "off" },
                model: {
                  value: _vm.form.contractName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "contractName", $$v)
                  },
                  expression: "form.contractName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "合同编号", prop: "contractNo" } },
            [
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: { autocomplete: "off" },
                model: {
                  value: _vm.form.contractNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "contractNo", $$v)
                  },
                  expression: "form.contractNo",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "发票编号", prop: "invoiceNo" } },
            [
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: { autocomplete: "off" },
                model: {
                  value: _vm.form.invoiceNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "invoiceNo", $$v)
                  },
                  expression: "form.invoiceNo",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "应收账款", prop: "tradeTotalAmt" } },
            [
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: { autocomplete: "off", type: "number" },
                model: {
                  value: _vm.form.tradeTotalAmt,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "tradeTotalAmt", $$v)
                  },
                  expression: "form.tradeTotalAmt",
                },
              }),
              _c("span", { staticClass: "ml10 fc_666" }, [_vm._v("元")]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "交易时间", prop: "startDate" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "50%" },
                attrs: {
                  "value-format": "yyyy-MM-dd",
                  type: "date",
                  placeholder: "选择日期",
                },
                model: {
                  value: _vm.form.startDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "startDate", $$v)
                  },
                  expression: "form.startDate",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "结算周期", prop: "dealine" } },
            [
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: { autocomplete: "off", type: "number" },
                model: {
                  value: _vm.form.dealine,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "dealine", $$v)
                  },
                  expression: "form.dealine",
                },
              }),
              _c("span", { staticClass: "ml10 fc_666" }, [_vm._v("天")]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "结算时间", prop: "endDate" } },
            [
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: { autocomplete: "off", readonly: "" },
                model: {
                  value: _vm.calcEndDate,
                  callback: function ($$v) {
                    _vm.calcEndDate = $$v
                  },
                  expression: "calcEndDate",
                },
              }),
              _c("span", { staticClass: "ml10 fc_666" }),
            ],
            1
          ),
          _c("div", { staticClass: "title_h3 mb20" }, [_vm._v("其它")]),
          _c(
            "el-form-item",
            { attrs: { label: "凭证附件", prop: "fileIds" } },
            [
              _c("dict-upload", {
                attrs: {
                  limit: 10,
                  "type-code": "F0104",
                  "biz-id": "123456",
                  type: "text",
                },
                on: {
                  change: function ($event) {
                    return _vm.onFileChange($event, "fileIds")
                  },
                },
                model: {
                  value: _vm.form.fileIds,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "fileIds", $$v)
                  },
                  expression: "form.fileIds",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "remark" } },
            [
              _c("el-input", {
                staticStyle: { width: "95%" },
                attrs: { type: "textarea", autosize: { minRows: 5 } },
                model: {
                  value: _vm.form.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer tc",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              staticClass: "mr20",
              attrs: { size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.handleReset("voucherInfoForm")
                },
              },
            },
            [_vm._v("重 置")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                size: "medium",
                type: "primary",
                loading: _vm.btnLoading,
              },
              on: {
                click: function ($event) {
                  return _vm.handleSave("voucherInfoForm")
                },
              },
            },
            [_vm._v(_vm._s(_vm.btnSave))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }