var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "download-file",
      attrs: { href: _vm.href, download: "", target: "_blank" },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }